import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIconStyled,
  FormLabelStyled,
  GridAddressContainerStyled,
  GridContactContainerStyled,
  GridItemStyled,
  LinkDeleteStyled,
  TextFieldStyled,
} from './CreateSubEmployerForm.styles';

const CreateSubEmployerForm = props => {
  // props
  const { employer, subEmployer } = props;

  // uses
  const intl = useIntl();

  // data
  const [employerErrorField, setEmployerErrorField] = useState(null);
  const [employerErrorMessage, setEmployerErrorMessage] = useState(null);
  const [sameAddressAsHolding, setSameAddressAsHolding] = useState(true);
  const [sameContactAsHolding, setSameContactAsHolding] = useState(true);

  const validationSchema = Yup.object().shape({
    employerName: Yup.string().trim().strict().max(255).required(),
    employerEmail: Yup.string().trim().strict().max(255).email(),
    employerPhone: Yup.string().trim().strict().max(255),
    contactName: Yup.string().trim().strict().max(255),
    contactEmail: Yup.string().trim().strict().max(255).email(),
    contactPhone: Yup.string().trim().strict().max(255),
    chamberOfCommerce: Yup.string().trim().strict().max(50),
    sectorCode: Yup.string().trim().strict().max(255),
    payrollTaxNumber: Yup.string().trim().strict().max(50),
    address: Yup.string().trim().strict(),
    zipCode: Yup.string().trim().strict().max(255),
    city: Yup.string().trim().strict().max(255),
  });

  // effects
  useEffect(() => {
    subEmployer.parentId = employer.employerId;

    subEmployer.address = employer.address;
    subEmployer.zipCode = employer.zipCode;
    subEmployer.city = employer.city;
    subEmployer.contactName = employer.contactName;
    subEmployer.contactEmail = employer.contactEmail;
    subEmployer.contactPhone = employer.contactPhone;
    subEmployer.ownerId = employer.ownerId;
    subEmployer.owner = employer.owner;
  }, [employer, subEmployer]);

  // events
  const handleChange = field => event => {
    subEmployer[field] = event.target.value;

    Yup.reach(validationSchema, field)
      .validate(subEmployer[field])
      .then(() => {
        setEmployerErrorField(null);
        setEmployerErrorMessage(null);
      })
      .catch(e => {
        setEmployerErrorField(field);
        setEmployerErrorMessage(e.message);
      });
  };

  const handleSameAddressAsHoldingChange = event => {
    setSameAddressAsHolding(event.target.checked);

    if (!event.target.checked) {
      subEmployer.address = '';
      subEmployer.zipCode = '';
      subEmployer.city = '';
    } else {
      subEmployer.address = employer.address;
      subEmployer.zipCode = employer.zipCode;
      subEmployer.city = employer.city;
    }
  };

  const handleSameContactAsHoldingChange = event => {
    setSameContactAsHolding(event.target.checked);

    if (!event.target.checked) {
      subEmployer.contactName = '';
      subEmployer.contactEmail = '';
      subEmployer.contactPhone = '';
    } else {
      subEmployer.contactName = employer.contactName;
      subEmployer.contactEmail = employer.contactEmail;
      subEmployer.contactPhone = employer.contactPhone;
    }
  };

  const handleDelete = id => event => {
    props.onDelete(id, event);
  };

  // template
  return (
    <Grid sx={{ width: '100%' }} container spacing={2}>
      {/* name */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.subEmployerName" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          error={employerErrorField === 'employerName'}
          fullWidth
          helperText={employerErrorField === 'employerName' ? employerErrorMessage : null}
          name="employerName"
          placeholder={intl.formatMessage({ id: 'employers.subEmployerName' }).toUpperCase()}
          required
          variant="outlined"
          onChange={handleChange('employerName')}
        />
      </GridItemStyled>

      {/* chamberOfCommerce */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.chamberOfCommerce" />
        </FormLabelStyled>

        <LinkDeleteStyled
          disabled={employer.subEmployers.length <= 1}
          href="#"
          variant="body1"
          onClick={handleDelete(subEmployer.employerId)}
        >
          <FontAwesomeIconStyled icon={faTrash} />
          <FormattedMessage id="employers.delete" />
        </LinkDeleteStyled>

        <TextFieldStyled
          color="secondary"
          fullWidth
          name="chamberOfCommerce"
          placeholder={intl.formatMessage({ id: 'employers.chamberOfCommerce' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('chamberOfCommerce')}
        />
      </GridItemStyled>

      {/* sectorCode */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.sectorCode" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="sectorCode"
          placeholder={intl.formatMessage({ id: 'employers.sectorCode' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('sectorCode')}
        />
      </GridItemStyled>

      {/* payrollTaxNumber */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.payrollTaxNumber" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="payrollTaxNumber"
          placeholder={intl.formatMessage({ id: 'employers.payrollTaxNumber' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('payrollTaxNumber')}
        />
      </GridItemStyled>

      {/* same address / contact */}
      <GridItemStyled item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              color={'secondary'}
              sx={{ backgroundColor: 'transparent' }}
              checked={sameAddressAsHolding}
              onChange={handleSameAddressAsHoldingChange}
            />
          }
          label={<FormattedMessage id="employers.add.subEmployers.sameAddress" />}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              color={'secondary'}
              sx={{ backgroundColor: 'transparent' }}
              checked={sameContactAsHolding}
              onChange={handleSameContactAsHoldingChange}
            />
          }
          label={<FormattedMessage id="employers.add.subEmployers.sameContact" />}
        />
      </GridItemStyled>

      {/* address/city/zipCode */}
      {!sameAddressAsHolding && (
        <GridAddressContainerStyled container>
          <GridItemStyled item xs={12}>
            <FormLabelStyled>
              <FormattedMessage id="employers.address" />
            </FormLabelStyled>
            <TextFieldStyled
              color="secondary"
              fullWidth
              name="address"
              placeholder={intl.formatMessage({ id: 'employers.address' }).toUpperCase()}
              variant="outlined"
              onChange={handleChange('address')}
            />
          </GridItemStyled>

          <Grid container spacing={2}>
            <GridItemStyled item xs={6}>
              <FormLabelStyled>
                <FormattedMessage id="employers.zipCode" />
              </FormLabelStyled>
              <TextFieldStyled
                color="secondary"
                fullWidth
                name="zipCode"
                placeholder={intl.formatMessage({ id: 'employers.zipCode' }).toUpperCase()}
                variant="outlined"
                onChange={handleChange('zipCode')}
              />
            </GridItemStyled>
            <GridItemStyled item xs={6}>
              <FormLabelStyled>
                <FormattedMessage id="employers.city" />
              </FormLabelStyled>
              <TextFieldStyled
                color="secondary"
                fullWidth
                name="city"
                placeholder={intl.formatMessage({ id: 'employers.city' }).toUpperCase()}
                variant="outlined"
                onChange={handleChange('city')}
              />
            </GridItemStyled>
          </Grid>
        </GridAddressContainerStyled>
      )}

      {!sameContactAsHolding && (
        <GridContactContainerStyled container spacing={2}>
          <GridItemStyled item xs={4}>
            <FormLabelStyled>
              <FormattedMessage id="employers.contactName" />
            </FormLabelStyled>
            <TextFieldStyled
              color="secondary"
              fullWidth
              name="contactName"
              placeholder={intl.formatMessage({ id: 'employers.contactName' }).toUpperCase()}
              variant="outlined"
              onChange={handleChange('contactName')}
            />
          </GridItemStyled>
          <GridItemStyled item xs={4}>
            <FormLabelStyled>
              <FormattedMessage id="employers.contactEmail" />
            </FormLabelStyled>
            <TextFieldStyled
              color="secondary"
              fullWidth
              name="contactEmail"
              placeholder={intl.formatMessage({ id: 'employers.contactEmail' }).toUpperCase()}
              type="email"
              variant="outlined"
              onChange={handleChange('contactEmail')}
            />
          </GridItemStyled>
          <GridItemStyled item xs={4}>
            <FormLabelStyled>
              <FormattedMessage id="employers.contactPhone" />
            </FormLabelStyled>
            <TextFieldStyled
              color="secondary"
              fullWidth
              name="contactPhone"
              placeholder={intl.formatMessage({ id: 'employers.contactPhone' }).toUpperCase()}
              variant="outlined"
              onChange={handleChange('contactPhone')}
            />
          </GridItemStyled>
        </GridContactContainerStyled>
      )}
    </Grid>
  );
};

CreateSubEmployerForm.propTypes = {
  employer: PropTypes.object,
  subEmployer: PropTypes.object,
  onDelete: PropTypes.func,
};

export default CreateSubEmployerForm;
