import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UseQueryWrapper } from '../../../types/useQuery.types';

import type { GetRegulationsYearsParams } from '../../../Domain/Regulations/Service/RegulationsService';
import RegulationsService from '../../../Domain/Regulations/Service/RegulationsService';

import { useSnakeBar } from '../../useSnakeBar';

export type RegulationsYear = {
  year: number;
};

type GetRegulationsYearsQueryResultData = {
  data: Array<RegulationsYear>;
  totalCount: number;
};

const queryFn =
  ({ ownerId, values }: GetRegulationsYearsParams) =>
  async () => {
    const response = await RegulationsService.getRegulationsYears(ownerId, values);
    return response.data;
  };

const QUERY_KEY = 'getRegulationsYears';

export const useRegulationsGetYearsQuery: UseQueryWrapper<
  GetRegulationsYearsQueryResultData,
  GetRegulationsYearsParams
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetRegulationsYearsQueryResultData>([QUERY_KEY], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
