import type { FuncOverrideComponent } from '../theme.types';

export const MuiInputBase: FuncOverrideComponent<'MuiInputBase'> = theme => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.h6.fontSize,
      lineHeight: '1em',
    },
    input: {
      height: '1em',
    },
    sizeSmall: {
      height: '24px',
    },
    inputSizeSmall: {
      lineHeight: '1em',
    },
  },
});
