import { alpha, styled } from '@mui/material/styles';
import { Grid, gridClasses, Typography } from '@mui/material';
import PaperBackground from '../PaperBackground';

export const DETAILS_PANEL_BODY_ID = 'details_panel_body_id';

export const GridColumnStyled = styled(Grid, {
  name: 'GridColumnStyled',
  shouldForwardProp: (propName: string) =>
    !['hasDividerLeft', 'hasDividerRight', 'hasDividerTop', 'hasDividerBottom'].includes(propName),
})<{ hasDividerLeft: boolean; hasDividerRight: boolean; hasDividerTop: boolean; hasDividerBottom: boolean }>(
  ({ theme, hasDividerLeft, hasDividerRight, hasDividerTop, hasDividerBottom }) => ({
    ...(hasDividerLeft
      ? {
          borderLeftColor: theme.palette.secondary.main,
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          [`&.${gridClasses.item}`]: {
            paddingLeft: theme.spacing(3),
          },
        }
      : {}),
    ...(hasDividerRight
      ? {
          [`&.${gridClasses.item}`]: {
            paddingRight: theme.spacing(3),
          },
        }
      : {}),
    ...(hasDividerTop
      ? {
          borderTopColor: theme.palette.secondary.main,
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          [`&.${gridClasses.item}`]: {
            paddingTop: theme.spacing(3),
          },
        }
      : {}),
    ...(hasDividerBottom
      ? {
          [`&.${gridClasses.item}`]: {
            paddingBottom: theme.spacing(3),
          },
        }
      : {}),
  }),
);

export const TypographyColumnTitleStyled = styled(Typography, {
  name: 'TypographyColumnTitleStyled',
  shouldForwardProp: (propName: string) => !['withMarginTop', 'withMarginBottom'].includes(propName),
})<{ withMarginTop: boolean; withMarginBottom: boolean }>(({ theme, withMarginTop, withMarginBottom }) => ({
  ...(withMarginTop ? { marginTop: theme.spacing(1.25) } : {}),
  ...(withMarginBottom ? { marginBottom: theme.spacing(1.25) } : {}),
}));

export const TypographyColumnSubTitleStyled = styled(Typography, {
  name: 'TypographyColumnSubTitleStyled',
  shouldForwardProp: (propName: string) => !['withMarginTop'].includes(propName),
})<{ withMarginTop: boolean }>(({ theme, withMarginTop }) => ({
  marginBottom: theme.spacing(1.25),
  ...(withMarginTop ? { marginTop: theme.spacing(1.25) } : {}),
}));

export const GridHeaderStyled = styled(Grid, {
  name: 'GridHeaderStyled',
})(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
  margin: `0 -${theme.spacing(1)}px 0 -${theme.spacing(1)}px`,
  width: `calc(100% + ${theme.spacing(2)}px)`,
}));

export const DivHeaderContentStyled = styled('div', {
  name: 'DivHeaderContentStyled',
  shouldForwardProp: (propName: string) => !['withIcon'].includes(propName),
})<{ withIcon: boolean }>(({ theme, withIcon }) => ({
  paddingLeft: theme.spacing(1),
  ...(withIcon
    ? {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& svg': {
          fontSize: '1rem',
        },
      }
    : {}),
}));

export const PaperBackgroundStyled = styled(PaperBackground, {
  name: 'PaperBackgroundStyled',
  shouldForwardProp: (propName: string) => !['withScrollBody'].includes(propName),
})<{ withScrollBody: boolean }>(({ withScrollBody }) => {
  if (withScrollBody) {
    return {
      [`& #${DETAILS_PANEL_BODY_ID}`]: {
        paddingTop: 0,
      },
    };
  }
  return {};
});

export const GridBodyStyled = styled(Grid, {
  name: 'GridBodyStyled',
  shouldForwardProp: (propName: string) => !['withScrollBody'].includes(propName),
})<{ withScrollBody: boolean }>(({ theme, withScrollBody }) => ({
  ...(withScrollBody
    ? {
        [theme.breakpoints.up('md')]: {
          overflow: 'auto',
        },
      }
    : {}),
}));

export const GridActionsStyled = styled(Grid, {
  name: 'GridActionsStyled',
})(({ theme }) => ({
  borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
  display: 'flex',
  justifyContent: 'flex-end',
  margin: `0 -${theme.spacing(1)}px -${theme.spacing(1)}px -${theme.spacing(1)}px`,
}));
