import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { TranslationKeys } from '../../../../../translations';

import { DivRootStyled } from './ReportNoData.styles';

type ReportNoDataProps = {
  searchQuery?: string;
  innerRow?: boolean;
};

export const ReportNoData = ({ searchQuery, innerRow = false }: ReportNoDataProps) => {
  return (
    <DivRootStyled innerRow={innerRow}>
      <Typography variant={'h6'}>
        {!searchQuery && <FormattedMessage id={TranslationKeys.global_noData} />}
        {!!searchQuery && (
          <FormattedMessage id={TranslationKeys.global_searchNotFound_searchQuery} values={{ searchQuery }} />
        )}
      </Typography>
    </DivRootStyled>
  );
};
