import { appService } from 'app/Domain/App/Services/AppServices';
import axios from 'axios';
import { actions as AppActions } from '../Domain/App/Ducks/App.duck';

export const backgroundAxios = axios.create();

const setupAxiosStoreInterceptor = (instance, store, setAppLoading) => {
  const { dispatch } = store;

  instance.defaults.baseURL = process.env.REACT_APP_API_URL;
  instance.defaults.headers['Content-Type'] = 'application/ld+json';

  instance.interceptors.request.use(
    config => {
      setAppLoading && dispatch(AppActions.setLoading(true));

      const {
        AppReducer: { authToken },
      } = store.getState();

      if (authToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => {
      setAppLoading && dispatch(AppActions.setLoading(true));
      return Promise.reject(err);
    },
  );

  instance.interceptors.response.use(
    response => {
      setAppLoading && dispatch(AppActions.setLoading(false));
      return response;
    },
    async error => {
      const originalRequest = error.config;
      const { response } = error;
      setAppLoading && dispatch(AppActions.setLoading(false));
      if (response && response.status === 401 && response.data.message === 'Expired JWT Token') {
        const {
          AppReducer: { refreshToken },
        } = store.getState();
        const response = await appService.tokenRefresh({ refresh_token: refreshToken });
        dispatch(AppActions.refreshToken(response.data.token));
        originalRequest.headers.Authorization = `Bearer ${response.data.token}`;
        return axios(originalRequest);
      }
      if (response && response.status === 401) {
        dispatch(AppActions.logout());
        // prevent response from being thrown as exception further
        return new Promise(() => {});
      }

      return Promise.reject(error);
    },
  );
};

export const setupAxios = store => {
  setupAxiosStoreInterceptor(backgroundAxios, store, false);
  setupAxiosStoreInterceptor(axios, store, true);
};
