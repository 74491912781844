import { saveAs } from 'file-saver';

import type { AxiosResponse } from 'axios';

export const saveFileFromResponse = (response: AxiosResponse): void => {
  if (response.headers['content-type'] === 'application/zip') {
    const element = document.createElement('a');
    element.href = response.data;

    document.body.appendChild(element);
    element.click();
  } else {
    saveAs(new Blob([response.data], { type: 'application/octet-stream' }), response.headers['x-filename']);
  }
};

export const saveFile = (blobPart: BlobPart, filename: string): void => {
  // { type: 'application/octet-stream' }
  saveAs(new Blob([blobPart], { type: 'application/octet-stream' }), filename);
};
