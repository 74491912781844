import React from 'react';
import type { ConnectionEvent } from 'app/types';
import { EVENT_TYPE_MAPPER } from 'app/types';
import { ActionButton, Dialog } from 'app/components';
import { TranslationKeys } from 'app/translations';
import EventContentTable from '../EventContentTable';
import { FormattedMessage } from 'react-intl';

type ShowEventDetailsDialogProps = {
  open: boolean;
  event: ConnectionEvent;
  onClose: () => void;
};

export const ShowEventDetailsDialog = ({ open, event, onClose }: ShowEventDetailsDialogProps) => {
  const DialogActions = React.useMemo(() => {
    return <ActionButton messageId={TranslationKeys.close} onClick={onClose} variant={'contained'} />;
  }, [onClose]);

  const dialogTitle = React.useMemo(() => {
    return <FormattedMessage id={'events.type.' + EVENT_TYPE_MAPPER[event.eventType]} />;
  }, [event.eventType]);

  return (
    <Dialog open={open} maxWidth="lg" title={dialogTitle} onClose={onClose} actions={DialogActions}>
      <EventContentTable key={event.mongoId} event={event} />
    </Dialog>
  );
};
