import React from 'react';

import { Box } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import type { TableSelectedState } from 'app/store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import { Uuid } from 'app/utils';

import { useDashboardGetConnectionErrorsDetailsQuery, useProductPensionSchemeSelectItems } from 'app/hooks';

import { ActionButton, InputFieldFactory, SearchInput, useBreadcrumb } from 'app/components';
import { ConnectionErrorsDetailsTable } from './components';
import { UNPROCESSED_TABLE_FILTERS_FIELD_NAMES } from '../../Components';
import { useIntl } from 'react-intl';
import { UnprocessedTableFiltersContainerStyled } from '../../Components/UnprocessedTableFilters/UnprocessedTableFilters.styles';
import { useSearchParams } from 'react-router-dom';

export const DashboardConnectionErrorsDetailsPage = () => {
  const [searchParams] = useSearchParams();

  const searchParamsStatus: string | null = React.useMemo(() => {
    return searchParams.has('status') ? searchParams.get('status') : null;
  }, [searchParams]);

  const intl = useIntl();
  const { productPensionSchemeSelectItems } = useProductPensionSchemeSelectItems();
  const dispatch = useStoreDispatch();
  const [key, setKey] = React.useState(Uuid.newV4);

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const { searchQuery, page, pageSize, filters, sortBy } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.DashboardSelector.selectConnectionErrorsDetails(state.DashboardReducer),
  );

  const {
    data: connectionErrorsDetails,
    isLoading: isLoadingConnectionErrorsDetailsQuery,
    isFetching: isFetchingConnectionErrorsDetailsQuery,
  } = useDashboardGetConnectionErrorsDetailsQuery({
    variables: {
      userId,
      page,
      pageSize,
      searchQuery,
      filters,
      sortBy,
      status: searchParamsStatus,
    },
    options: {
      enabled: !!userId,
    },
  });

  const isLoading = isLoadingConnectionErrorsDetailsQuery || isFetchingConnectionErrorsDetailsQuery;

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.DashboardAction.connectionErrorsDetails.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.DashboardAction.connectionErrorsDetails.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.DashboardAction.connectionErrorsDetails.changePage(page));
      }
    },
    [dispatch],
  );

  const productPensionSchemeSelectItemsWithAllOption = React.useMemo(() => {
    return [
      {
        value: '',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...productPensionSchemeSelectItems,
    ];
  }, [intl, productPensionSchemeSelectItems]);

  const onSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(StoreActions.DashboardAction.connectionErrorsDetails.search(event.target.value));
    },
    [dispatch],
  );

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme && value === '') {
        dispatch(
          StoreActions.DashboardAction.connectionErrorsDetails.removeFilter(
            UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
          ),
        );
      } else {
        dispatch(StoreActions.DashboardAction.connectionErrorsDetails.applyFilter(name, value));
      }
    },
    [dispatch],
  );

  const onClickResetButtonHandler = React.useCallback(() => {
    dispatch(
      StoreActions.DashboardAction.connectionErrorsDetails.removeFilter(
        UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
      ),
    );
    dispatch(StoreActions.DashboardAction.connectionErrorsDetails.search(''));
    setKey(Uuid.newV4());
  }, [dispatch]);

  useBreadcrumb(TranslationKeys.dashboard_category_dataProcessing, {
    customPathName: '/dashboard/data-processing',
  });
  useBreadcrumb(TranslationKeys.dashboard_subCategory_connectionErrors);

  return (
    <Box marginTop={1}>
      <UnprocessedTableFiltersContainerStyled>
        <InputFieldFactory
          key={`product-pension-scheme-${key}`}
          disabled={isLoading}
          onChange={onFilterChange}
          field={{
            type: 'select',
            name: UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
            label: intl.formatMessage({ id: TranslationKeys.global_pensionScheme }),
            fullWidth: false,
            value: filters.productPensionScheme || null,
            items: productPensionSchemeSelectItemsWithAllOption,
          }}
        />

        <SearchInput
          key={`search-${key}`}
          disabled={isLoading}
          initialSearchQuery={searchQuery}
          onChange={onSearchChange}
          debounceTime={1000}
        />

        <ActionButton
          disabled={isLoading}
          messageId={TranslationKeys.global_reset}
          onClick={onClickResetButtonHandler}
          variant={'text'}
        />
      </UnprocessedTableFiltersContainerStyled>

      <ConnectionErrorsDetailsTable
        isLoading={isLoading}
        connectionErrorsDetails={connectionErrorsDetails?.data || []}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        pagination={{
          page,
          pageSize,
          totalCount: connectionErrorsDetails?.totalCount ?? 0,
        }}
      />
    </Box>
  );
};
