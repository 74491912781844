import { styled } from '@mui/material/styles';
import PaperBackground from '../../../../../../components/PaperBackground';
import { Typography } from '@mui/material';

export const InfoContainerStyled = styled('div', {
  name: 'InfoContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  marginTop: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));

export const GreetingsContainerStyled = styled(PaperBackground, {
  name: 'GreetingsContainerStyled',
})(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: theme.spacing(3),
  borderTopLeftRadius: 'unset',
  borderBottomLeftRadius: 'unset',
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(5, 3),
  '& h2, h5': {
    color: theme.palette.primary.dark,
    fontWeight: 400,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const GreetingsOwnerLogoStyled = styled('img', {
  name: 'GreetingsOwnerLogoStyled',
})(({ theme }) => ({
  outline: `1px solid transparent`,
  height: theme.spacing(10),
  minWidth: theme.spacing(10),
  objectFit: 'contain',
  background: 'white',
  borderRadius: 4,
  padding: 10,
  boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.2)`,
}));

export const GreetingsTextContainerStyled = styled('div', {
  name: 'GreetingsTextContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const GreetingsOwnerNameStyled = styled(Typography, {
  name: 'GreetingsOwnerNameStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  '& span': {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
}));

export const StatusContainerStyled = styled('div', {
  name: 'StatusContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));
