import { isObject } from '../../../utils';
import { getActualValue } from '../Shared/formikFieldHelpers';
import type { GetActualValueParams } from '../Shared/formikFieldHelpers';

export const getSelectActualValue = ({ normalizedOptions, defaultValue, formikValue, value }: GetActualValueParams) => {
  const actualValue = getActualValue({ normalizedOptions, defaultValue, formikValue, value });

  if (isObject(actualValue)) {
    return actualValue.value;
  }

  return actualValue;
};
