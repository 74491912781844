import React from 'react';

import { EventTypeEnum } from '../types/eventType.type';
import { useIntl } from 'react-intl';
import { TranslationKeys } from '../translations';

export const useEventTypeSelectItems = () => {
  const intl = useIntl();

  const eventTypeSelectItems = React.useMemo(() => {
    return [
      {
        value: EventTypeEnum.ContractUpdated,
        element: intl.formatMessage({ id: TranslationKeys.events_type_contractUpdated }),
      },
      {
        value: EventTypeEnum.NewEmployee,
        element: intl.formatMessage({ id: TranslationKeys.events_type_newEmployee }),
      },
      {
        value: EventTypeEnum.EmployeeInformationUpdated,
        element: intl.formatMessage({ id: TranslationKeys.events_type_employeeInformationUpdated }),
      },
      {
        value: EventTypeEnum.EmployeeAddressUpdated,
        element: intl.formatMessage({ id: TranslationKeys.events_type_employeeAddressUpdated }),
      },
      {
        value: EventTypeEnum.EmployeePartTimePercentageUpdated,
        element: intl.formatMessage({ id: TranslationKeys.events_type_employeePartTimePercentageUpdated }),
      },
      {
        value: EventTypeEnum.EmployeePartnerUpdated,
        element: intl.formatMessage({ id: TranslationKeys.events_type_employeePartnerUpdated }),
      },
      {
        value: EventTypeEnum.GrossWageUpdated,
        element: intl.formatMessage({ id: TranslationKeys.events_type_grossWageUpdated }),
      },
      {
        value: EventTypeEnum.EmployeeLeftCompany,
        element: intl.formatMessage({ id: TranslationKeys.events_type_employeeLeftCompany }),
      },
      {
        value: EventTypeEnum.NewPeriodicWage,
        element: intl.formatMessage({ id: TranslationKeys.events_type_newPeriodicWage }),
      },
      {
        value: EventTypeEnum.ReEmployed,
        element: intl.formatMessage({ id: TranslationKeys.events_type_reEmployed }),
      },
    ];
  }, [intl]);

  const getEventTypeDisplayNameByValue = React.useCallback(
    (value: EventTypeEnum) => {
      return eventTypeSelectItems.find(eventType => eventType.value === value)?.element;
    },
    [eventTypeSelectItems],
  );

  return React.useMemo(
    () => ({ eventTypeSelectItems, getEventTypeDisplayNameByValue }),
    [eventTypeSelectItems, getEventTypeDisplayNameByValue],
  );
};
