import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import type { DrawerProps } from '@mui/material';
import { Button, Link } from '@mui/material';

import {
  faBriefcase,
  faBuilding,
  faChartColumn,
  faChartPie,
  faCog,
  faLink,
  faScrewdriverWrench,
  faShield,
  faShuffle,
  faUnlock,
  faUnlockAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import {
  CONNECTION,
  CREDENTIAL,
  EMPLOYER,
  OWNER,
  ROLE,
  SETTINGS,
  SUBOWNER,
  INSURER,
} from 'app/common/Authorization/entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isLocalEnvironment } from 'app/utils';

import { TranslationKeys } from 'app/translations';

import { EDIT, VIEW } from 'app/common/Authorization/permissions';

import { IsOwnerAdmin, IsSuperAdmin } from 'app/components';

import { SidebarNav } from './components';

import { DivRootStyled, DrawerStyled } from './Sidebar.styles';
import type { Page } from './Sidebar.types';

type SidebarProps = {
  onClose: () => void;
  open: boolean;
  variant: DrawerProps['variant'];
};

export const Sidebar = ({ open, variant, onClose }: SidebarProps) => {
  const shouldRender = React.useCallback(item => {
    if (item.onlyOwner) {
      return IsOwnerAdmin();
    }

    if (item.onlySuperAdmin) {
      return IsSuperAdmin();
    }

    return true;
  }, []);

  const pages: Page[] = [
    {
      entity: OWNER,
      permission: VIEW,
      collapsible: false,
      href: '/dashboard',
      iconName: faChartPie,
      title: <FormattedMessage id={TranslationKeys.menu_dashboard} />,
    },
    {
      entity: OWNER,
      permission: VIEW,
      collapsible: true,
      href: '/owners',
      iconName: faBuilding,
      title: <FormattedMessage id={TranslationKeys.menu_owners} />,
      children: [
        {
          entity: SUBOWNER,
          permission: VIEW,
          collapsible: false,
          href: '/owners/sub-owners',
          title: <FormattedMessage id={TranslationKeys.menu_subOwners} />,
        },
      ],
    },
    {
      entity: EMPLOYER,
      permission: VIEW,
      collapsible: false,
      href: '/employers',
      iconName: faBriefcase,
      title: <FormattedMessage id={TranslationKeys.menu_employers} />,
    },
    {
      entity: CONNECTION,
      permission: VIEW,
      onlySuperAdmin: false,
      collapsible: false,
      href: '/connection',
      iconName: faLink,
      title: <FormattedMessage id={TranslationKeys.menu_connections} />,
    },
    {
      entity: INSURER,
      permission: VIEW,
      collapsible: false,
      href: '/insurers',
      iconName: faShield,
      title: <FormattedMessage id={TranslationKeys.menu_insurers} />,
    },
    {
      entity: CREDENTIAL,
      permission: VIEW,
      collapsible: false,
      href: '/credential',
      iconName: faUnlock,
      title: <FormattedMessage id={TranslationKeys.menu_credentials} />,
      children: [
        {
          entity: CREDENTIAL,
          permission: VIEW,
          collapsible: false,
          href: '/credential/requests',
          iconName: faUnlockAlt,
          title: <FormattedMessage id={TranslationKeys.menu_credentialsRequests} />,
        },
      ],
    },
    {
      entity: OWNER,
      permission: VIEW,
      onlySuperAdmin: true,
      onlyOwner: true,
      collapsible: true,
      href: '/users',
      iconName: faUser,
      title: <FormattedMessage id={TranslationKeys.menu_users} />,
      children: [
        {
          entity: ROLE,
          permission: VIEW,
          collapsible: false,
          href: '/users/roles',
          title: <FormattedMessage id={TranslationKeys.menu_roles} />,
        },
      ],
    },
    {
      entity: SETTINGS,
      permission: VIEW,
      collapsible: true,
      iconName: faCog,
      title: <FormattedMessage id={TranslationKeys.menu_settings} />,
      children: [
        {
          entity: SETTINGS,
          permission: VIEW,
          onlyOwner: false,
          collapsible: false,
          href: '/owners/settings/employee-types',
          title: <FormattedMessage id={TranslationKeys.menu_settings_employeeTypes} />,
        },
        {
          entity: SETTINGS,
          permission: VIEW,
          onlyOwner: false,
          collapsible: false,
          href: 'settings/rate-tables',
          title: <FormattedMessage id={TranslationKeys.menu_settings_rateTables} />,
        },
        {
          entity: SETTINGS,
          permission: VIEW,
          onlyOwner: false,
          collapsible: false,
          href: 'settings/summary-scales',
          title: <FormattedMessage id={TranslationKeys.menu_settings_summaryScales} />,
        },
      ],
    },
    {
      entity: OWNER,
      permission: VIEW,
      collapsible: true,
      href: '/reports',
      iconName: faChartColumn,
      title: <FormattedMessage id={TranslationKeys.menu_reports} />,
      children: [
        {
          entity: OWNER,
          permission: VIEW,
          onlySuperAdmin: true,
          collapsible: false,
          href: '/reports/invoicing',
          title: <FormattedMessage id={TranslationKeys.menu_reports_invoicing} />,
        },
      ],
    },
    {
      entity: OWNER,
      permission: EDIT,
      onlySuperAdmin: false,
      collapsible: true,
      href: '/tools',
      iconName: faScrewdriverWrench,
      title: <FormattedMessage id={TranslationKeys.menu_tools} />,
      children: [
        {
          onlyOwner: true,
          entity: OWNER,
          permission: EDIT,
          collapsible: false,
          href: '/tools/splitter',
          title: <FormattedMessage id={TranslationKeys.menu_tools_upaXmlSplitter} />,
        },
      ],
    },
  ]
    .filter(item => shouldRender(item))
    .map(item => ({ ...item, children: (item.children || []).filter(item => shouldRender(item)) }));

  const extra = useMemo(() => {
    if (isLocalEnvironment()) {
      return (
        <Button variant={'text'}>
          <FontAwesomeIcon icon={faShuffle} />
          <Link href={'/storybook/'}>{'Storybook'}</Link>
        </Button>
      );
    }
    return <></>;
  }, []);

  return (
    <DrawerStyled zIndex={variant === 'temporary'} anchor="left" onClose={onClose} open={open} variant={variant}>
      <DivRootStyled>
        <SidebarNav pages={pages} />
        {extra}
      </DivRootStyled>
    </DrawerStyled>
  );
};
