import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Grid } from '@mui/material';

import { normalizeError } from '../../../../../../utils';

import { TranslationKeys } from '../../../../../../translations';

import type { Employer } from '../../../../../../types/employer.types';

import { useSnakeBar } from '../../../../../../hooks/useSnakeBar';

import { StoreActions, useStoreDispatch } from '../../../../../../store';

import connectionService from '../../../../../Connections/Services/ConnectionService';

import FormikModal from '../../../../../../components/FormikModal';

import { EmployeeCSCFileUpload } from '../EmployeeCSCFileUpload';

type EmployeeUploadFormDialogProps = {
  show: boolean;
  onClose: () => void;
  afterSubmit: () => void;
  employerId: string;
  employerConnections: Employer['connections'] | null;
};

export const EmployeeCSCUploadFormDialog = ({
  show,
  onClose,
  afterSubmit,
  employerId,
  employerConnections,
}: EmployeeUploadFormDialogProps) => {
  const intl = useIntl();
  const dispatch = useStoreDispatch();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const [progressMsg, setProgressMsg] = React.useState<string | null>(null);
  const connectionId = employerConnections?.length === 1 ? employerConnections[0].connectionId : null;

  const formikInitialValues = React.useMemo(
    () => ({
      connectionId,
      employees: [],
      hasErrors: false,
    }),
    [connectionId],
  );

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      connectionId: Yup.string().trim().strict(true).min(2).max(50).required(),
      employees: Yup.array().min(1).max(1000).required(),
      hasErrors: Yup.boolean().oneOf([false]),
    });
  }, []);

  const formikOnSubmit = React.useCallback(
    async values => {
      const method = 'CollectiveSalaryChange';
      setProgressMsg(TranslationKeys.employees_add_fileSending);
      await connectionService
        .collectiveSalaryChange(values.connectionId, values.employees)
        .then(() => {
          // @ts-ignore
          dispatch(StoreActions.EmployerActions.employees.setEmployerId(employerId));
          showSuccessSnakeBar({ method });
          afterSubmit();
        })
        .catch((err: any) => {
          const errors = normalizeError(err);
          showErrorSnakeBar({ method, message: errors.message });
        })
        .finally(() => {
          setProgressMsg(null);
        });
    },
    [afterSubmit, dispatch, employerId, showErrorSnakeBar, showSuccessSnakeBar],
  );

  return (
    <Grid item xs={12}>
      <Formik
        enableReinitialize
        initialValues={formikInitialValues}
        validationSchema={formikValidationSchema}
        onSubmit={formikOnSubmit}
      >
        <FormikModal
          maxWidth="md"
          show={show}
          onHide={onClose}
          title={intl.formatMessage({ id: TranslationKeys.employees_collectiveSalaryChange })}
        >
          <EmployeeCSCFileUpload
            employerConnections={employerConnections}
            progressMsg={progressMsg}
            setProgressMsg={setProgressMsg}
          />
        </FormikModal>
      </Formik>
    </Grid>
  );
};
