/* eslint-disable react/display-name */

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { actions as RolesActions } from '../../Ducks/Roles.duck';
import CreateRoleForm from '../../Forms/CreateRoleForm';
import { RolesToolbar } from '../../Components';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { Formik } from 'formik';
import FormikModal from 'app/components/FormikModal';
import { normalizeFormError } from 'app/utils';
import { MESSAGE_SEVERITY_ERROR } from '../../../../common';
import { actions as AppActions, selectors as appSelectors } from '../../../App/Ducks/App.duck';
import { roleService } from '../../Services';
import { actions as OwnersActions } from '../../../Owners/Ducks/Owners.duck';
import { ROLE } from '../../../../common/Authorization/entities';
import { EDIT } from '../../../../common/Authorization/permissions';
import { HasAccessTo } from 'app/components/Authorization';
import {
  ButtonStyled,
  CardContentStyled,
  CardStyled,
  DivContentStyled,
  TypographyCardDescriptionStyled,
  TypographyCardTitleStyled,
} from './RoleManagementPage.styles';

const RoleManagementPage = () => {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.RolesReducer);
  const { items: owners } = useSelector(state => state.OwnersReducer);
  const ownerId = useSelector(state => appSelectors.selectOwnerId(state.AppReducer));

  const [createRoleModalOpen, setCreateRoleModalOpen] = useState(false);
  const [editRoleModalOpen, setEditRoleModalOpen] = useState(false);
  const [editRoleInitialValues, setEditRoleInitialValues] = useState({});

  const validationSchema = Yup.object().shape({
    name: Yup.string().strict().min(2).max(255).required(),
    description: Yup.string().strict().min(2).max(255).required(),
    permissions: Yup.array().required(),
  });

  const [permissions, setPermissions] = useState(null);
  const disabledIfNoAccessToEdit = !HasAccessTo(ROLE, EDIT);

  const loadPermissions = useCallback(async () => {
    try {
      const response = await roleService.getPermissions();
      setPermissions(response.data['hydra:member'][0]);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getPermissions',
        severity,
      };
      dispatch(AppActions.displayMessage(details));
    }
  }, [dispatch]);

  useEffect(() => {
    loadPermissions();
    dispatch(RolesActions.initializeRolesState());
    dispatch(RolesActions.requestData());
    dispatch(OwnersActions.requestData());
  }, [dispatch, loadPermissions]);

  const handleClickEdit = useCallback(roleTemp => {
    const [, , , ownerId] = roleTemp.owner.split('/');
    setEditRoleInitialValues({
      name: roleTemp.name,
      description: roleTemp.description,
      permissions: roleTemp.permissions,
      ownerId: ownerId,
      roleId: roleTemp.roleId,
    });

    setEditRoleModalOpen(true);
  }, []);

  const handleCreateRole = async (values, form) => {
    try {
      dispatch(RolesActions.createRole(values));
      setCreateRoleModalOpen(false);
    } catch (e) {
      const errors = normalizeFormError(e, form);
      if (!errors.violations) {
        throw errors.error;
      }
    }
    form.resetForm();
  };

  const handleEditRole = async (values, form) => {
    try {
      dispatch(RolesActions.editRole(values));
      setEditRoleModalOpen(false);
    } catch (e) {
      const errors = normalizeFormError(e, form);
      if (errors.violations) {
        return;
      }
    }
    form.resetForm();
  };

  useBreadcrumb('menu.roles', {
    hideTrail: true,
    hideTitle: true,
    otherElements: <RolesToolbar handleClickAdd={() => setCreateRoleModalOpen(true)} totalCount={items.length} />,
  });

  return (
    <DivContentStyled>
      <Grid container spacing={2}>
        {!!items.length &&
          items.map(role => (
            <Grid item key={role.roleId} xs={2}>
              <CardStyled variant="outlined">
                <CardContentStyled>
                  <div>
                    <TypographyCardTitleStyled variant="h5">{role.name}</TypographyCardTitleStyled>
                    <TypographyCardDescriptionStyled variant="body2">
                      {role.description}
                    </TypographyCardDescriptionStyled>
                  </div>
                  {role.owner !== null && (
                    <ButtonStyled color="primary" variant="outlined" onClick={() => handleClickEdit(role)}>
                      <FormattedMessage id="roles.show" />
                    </ButtonStyled>
                  )}
                </CardContentStyled>
              </CardStyled>
            </Grid>
          ))}
      </Grid>
      <Formik
        initialValues={{
          name: '',
          description: '',
          permissions: [],
          ownerId: ownerId !== null ? ownerId : '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleCreateRole}
      >
        {({ resetForm }) => (
          <FormikModal
            show={createRoleModalOpen}
            onHide={() => {
              setCreateRoleModalOpen(false);
              resetForm();
            }}
            title={<FormattedMessage id="roles.add" />}
            buttonDisabled={disabledIfNoAccessToEdit}
          >
            <CreateRoleForm owners={owners} resources={permissions} />
          </FormikModal>
        )}
      </Formik>
      {editRoleModalOpen && (
        <Formik initialValues={editRoleInitialValues} validationSchema={validationSchema} onSubmit={handleEditRole}>
          <FormikModal
            show={editRoleModalOpen}
            title={<FormattedMessage id="roles.update" />}
            onHide={() => setEditRoleModalOpen(false)}
            buttonDisabled={disabledIfNoAccessToEdit}
          >
            <CreateRoleForm initialValues={editRoleInitialValues} owners={owners} resources={permissions} />
          </FormikModal>
        </Formik>
      )}
    </DivContentStyled>
  );
};

export default RoleManagementPage;
