import type { IntlShape } from 'react-intl/src/types';
import { TranslationKeys } from 'app/translations';
import type { ListFileStrategyInterface } from 'app/components/FileUploadAreaWithTemplate/ListFileStrategyInterface';
import { CompareBatchFileReadException } from './CompareBatchFileReadException';
import { bsnValidator } from '../../../../../../../../../utils';
import { EmployeeListFileReadException } from '../../../../../../../../Employers/Components/EmployerConnectionWizard/EmployeesListing/EmployeeListFileReadException';

const throwError = (errorMsg: string) => {
  throw new CompareBatchFileReadException(errorMsg);
};

export class CompareBatchFileReadStrategy implements ListFileStrategyInterface {
  constructor(private readonly intl: IntlShape) {}

  public execute(data: Array<any>): Array<any> {
    const rows = data.filter((row: any, index: number) => {
      const isHeadersRow = index === 0;

      if (isHeadersRow) {
        return false;
      }

      const validRowColumns = Object.keys(row).filter(key => {
        const value = row[key];

        const isBSNRow = key === '6';

        if (isBSNRow) {
          const BSN = row[key];

          if (!bsnValidator({ value: BSN })) {
            throw new EmployeeListFileReadException(
              this.intl.formatMessage(
                { id: TranslationKeys.onboarding_list_upload_error_invalidBSN },
                { index: index + 1 },
              ),
            );
          }
        }

        return value.trim() !== '';
      });

      return validRowColumns.length > 0;
    });

    if (rows.length === 0) {
      throwError(this.intl.formatMessage({ id: TranslationKeys.global_error_fileEmpty }));
    }

    return rows;
  }
}
