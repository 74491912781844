import React from 'react';

import { Box } from '@mui/material';

import { TypographyStyled } from './InsurersContactCard.styles';

const InsurersContactCard = ({ contactPerson }) => {
  return (
    <>
      <TypographyStyled variant="body1">{contactPerson.name}</TypographyStyled>
      <TypographyStyled variant="body1">{contactPerson.phone}</TypographyStyled>
      <TypographyStyled variant="body1">{contactPerson.email}</TypographyStyled>

      <Box m={2}></Box>
    </>
  );
};

export default InsurersContactCard;
