import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';

import type { Employee, EmployeePartner, UseQueryRefetch } from 'app/types';

import { useDialog, useEmployeeDeletePartnerMutation, useGenderSelectItems, useSnakeBar } from 'app/hooks';

import { ActionButton, ConfirmDialog, DetailsPanelRow, HasAccessTo } from 'app/components';
import DetailsPanel from 'app/components/DetailsPanel';

import { EmployeeDetailsPanelHeaderWithSyncToggle } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeDetailsPanelHeaderWithSyncToggle';

import {
  DivNoDataDetailsBodyStyled,
  DivPanelBodyDetailsStyled,
  SpanConfirmDialogBodyContainerStyled,
} from '../../EmployeeInfoTab.styles';

import { PartnerFormDialog } from './components';
import { PartnerDetailsPanelFields } from './PartnerDetailsPanel.types';

import { EMPLOYER, CONNECTION } from 'app/common/Authorization/entities';
import { EDIT, RUN } from 'app/common/Authorization/permissions';

type PartnerDetailsPanelProps = {
  synchronizationStatus: boolean | undefined;
  refetchGetEmployeeSynchronizationQuery: UseQueryRefetch;
  refetchGetEmployeeQuery: UseQueryRefetch;
  disabledActionButtonsWhileLoading: boolean;
  loading: boolean;
  employee: Employee | undefined;
};

export const PartnerDetailsPanel = ({
  synchronizationStatus,
  refetchGetEmployeeSynchronizationQuery,
  refetchGetEmployeeQuery,
  disabledActionButtonsWhileLoading,
  employee,
  loading,
}: PartnerDetailsPanelProps) => {
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const intl = useIntl();
  const { employerId } = useParams();
  const { getGenderDisplayNameByValue } = useGenderSelectItems();
  const { mutate: deleteEmployeePartner } = useEmployeeDeletePartnerMutation();

  const {
    dialogState: partnerFormDialogState,
    openDialog: openPartnerFormDialog,
    closeDialog: closePartnerFormDialog,
  } = useDialog();

  const partners = React.useMemo(() => employee?.partners ?? [], [employee?.partners]);
  const [expanded, setExpanded] = React.useState(partners.map(() => false));
  const [partnerToDelete, setPartnerToDelete] = React.useState<EmployeePartner | null>(null);
  const [partnerToEdit, setPartnerToEdit] = React.useState<EmployeePartner | null>(null);
  const hasPartners = partners.length > 0;
  const hasActiveSynchronization = synchronizationStatus !== undefined ? synchronizationStatus : false;
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);

  const togglePartnerDetailsPanel = React.useCallback(
    ({ id, expanded: newExpanded }) => {
      const newState = new Array(expanded.length).fill(false);
      newState[id] = !newExpanded;
      setExpanded(newState);
    },
    [expanded],
  );

  const deletePartnerHandler = React.useCallback(
    (partnerId: string) => {
      if (employee?.connectionId && employee?.employeeId) {
        const method = 'deleteEmployeePartner';
        deleteEmployeePartner(
          {
            connectionId: employee?.connectionId,
            employeeId: employee?.employeeId,
            partnerId,
          },
          {
            onSuccess: () => {
              refetchGetEmployeeQuery();
              showSuccessSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_successMessage }),
              });
            },
            onError: () => {
              showErrorSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_errorMessage }),
              });
            },
          },
        );
      }
    },
    [
      deleteEmployeePartner,
      employee?.connectionId,
      employee?.employeeId,
      intl,
      refetchGetEmployeeQuery,
      showErrorSnakeBar,
      showSuccessSnakeBar,
    ],
  );

  const onOpenPartnerFormDialog = React.useCallback(
    (partner?: EmployeePartner) => {
      setPartnerToEdit(partner || null);
      openPartnerFormDialog();
    },
    [openPartnerFormDialog],
  );

  const onClosePartnerFormDialog = React.useCallback(() => {
    setPartnerToEdit(null);
    closePartnerFormDialog();
  }, [closePartnerFormDialog]);

  const onClickAddButton = React.useCallback(() => {
    onOpenPartnerFormDialog();
  }, [onOpenPartnerFormDialog]);

  const onClickEditButton = React.useCallback(
    (partner: EmployeePartner) => () => {
      onOpenPartnerFormDialog(partner);
    },
    [onOpenPartnerFormDialog],
  );

  const onClickDeleteButton = React.useCallback(
    (partner: EmployeePartner) => () => {
      setPartnerToDelete(partner);
    },
    [setPartnerToDelete],
  );

  const onCloseConfirmationDialog = React.useCallback(() => {
    setPartnerToDelete(null);
  }, []);

  const onClickConfirmDelete = React.useCallback(() => {
    if (partnerToDelete) {
      deletePartnerHandler(partnerToDelete.partnerId);
    }
  }, [partnerToDelete, deletePartnerHandler]);

  const LoadingDetailsBody = React.useMemo(() => {
    return (
      <>
        {new Array(5).fill(null).map((_, idx) => (
          <Skeleton key={idx} height={24} />
        ))}
      </>
    );
  }, []);

  const NoChildrenDetailsBody = React.useMemo(() => {
    return (
      <DivNoDataDetailsBodyStyled>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKeys.employees_detail_partner_noPartners} />
        </Typography>
      </DivNoDataDetailsBodyStyled>
    );
  }, []);

  const PartnersDetailsBody = React.useMemo(() => {
    return (partners || []).map((partner, idx) => {
      const expandedIfOnlyOnePartner = partners.length === 1;
      const isExpanded = typeof expanded[idx] !== 'undefined' ? expanded[idx] : false;

      const rows = {
        [PartnerDetailsPanelFields.PersonName.Props.Initials.Label]: partner?.personName?.initials,
        [PartnerDetailsPanelFields.PersonName.Props.FirstName.Label]: partner?.personName?.firstName,
        [PartnerDetailsPanelFields.PersonName.Props.LastName.Label]: partner?.personName?.lastName,
        [PartnerDetailsPanelFields.PersonName.Props.LastNamePrefix.Label]: partner.personName?.lastNamePrefix || '-',
        [PartnerDetailsPanelFields.SocialSecurityNumber.Label]: partner?.socialSecurityNumber || '-',
        [PartnerDetailsPanelFields.Gender.Label]: getGenderDisplayNameByValue(partner?.gender),
        [PartnerDetailsPanelFields.DateOfBirth.Label]: partner?.dateOfBirth ? format.date(partner?.dateOfBirth) : '-',
        [PartnerDetailsPanelFields.StartOfRelationship.Label]: format.date(partner?.startOfRelationship),
        [PartnerDetailsPanelFields.EndOfRelationship.Label]: partner?.endOfRelationship
          ? format.date(partner?.endOfRelationship)
          : '-',
        [PartnerDetailsPanelFields.ExtraPartnerPensionByStart.Label]: partner?.extraPartnerPensionByStart,
      };

      return (
        <div key={idx}>
          <DetailsPanelRow
            title={<Typography variant="h6">{format.name(partner.personName)}</Typography>}
            expanded={expandedIfOnlyOnePartner || isExpanded}
            rows={rows}
            loading={loading}
            id={idx}
            onExpand={togglePartnerDetailsPanel}
            onDeleteButtonClick={onClickDeleteButton(partner)}
            onEditButtonClick={onClickEditButton(partner)}
            disabledDeleteButton={
              disabledActionButtonsWhileLoading ||
              hasActiveSynchronization ||
              disabledIfNoAccessToEmployerEdit ||
              disabledIfNoAccessToConnectionRun
            }
            disabledEditButton={
              disabledActionButtonsWhileLoading ||
              hasActiveSynchronization ||
              disabledIfNoAccessToEmployerEdit ||
              disabledIfNoAccessToConnectionRun
            }
          />
        </div>
      );
    });
  }, [
    expanded,
    getGenderDisplayNameByValue,
    hasActiveSynchronization,
    disabledActionButtonsWhileLoading,
    loading,
    onClickDeleteButton,
    onClickEditButton,
    partners,
    togglePartnerDetailsPanel,
    disabledIfNoAccessToEmployerEdit,
    disabledIfNoAccessToConnectionRun,
  ]);

  const DetailsPanelBody = React.useMemo(() => {
    const finishedLoadingAndHasPartners = !loading && hasPartners;
    const finishedLoadingAndHasNoPartners = !loading && !hasPartners;

    return (
      <DivPanelBodyDetailsStyled>
        <>
          {loading && LoadingDetailsBody}
          {finishedLoadingAndHasPartners && PartnersDetailsBody}
          {finishedLoadingAndHasNoPartners && NoChildrenDetailsBody}
        </>
      </DivPanelBodyDetailsStyled>
    );
  }, [loading, hasPartners, LoadingDetailsBody, PartnersDetailsBody, NoChildrenDetailsBody]);

  const DetailsPanelActions = React.useMemo(() => {
    return (
      <>
        <ActionButton
          messageId={TranslationKeys.button_add}
          variant="text"
          icon={faPlus}
          onClick={onClickAddButton}
          disabled={
            disabledActionButtonsWhileLoading ||
            hasActiveSynchronization ||
            disabledIfNoAccessToEmployerEdit ||
            disabledIfNoAccessToConnectionRun
          }
        />
      </>
    );
  }, [
    disabledActionButtonsWhileLoading,
    disabledIfNoAccessToConnectionRun,
    disabledIfNoAccessToEmployerEdit,
    hasActiveSynchronization,
    onClickAddButton,
  ]);

  const DetailsPanelHeader = React.useMemo(() => {
    return (
      <EmployeeDetailsPanelHeaderWithSyncToggle
        field={'partner'}
        loading={loading}
        synchronizationStatus={synchronizationStatus}
        refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
        disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
        messageId={TranslationKeys.employees_detail_partner_title}
        employeeId={employee?.employeeId}
        connectionId={employee?.connectionId}
      />
    );
  }, [
    employee?.connectionId,
    employee?.employeeId,
    disabledActionButtonsWhileLoading,
    loading,
    refetchGetEmployeeSynchronizationQuery,
    synchronizationStatus,
  ]);

  return (
    <>
      <ConfirmDialog
        title={<FormattedMessage id={TranslationKeys.employees_detail_partner_confirmDeleteTitle} />}
        open={partnerToDelete !== null}
        onClose={onCloseConfirmationDialog}
        onConfirm={onClickConfirmDelete}
      >
        <SpanConfirmDialogBodyContainerStyled>
          <span>{format.name(partnerToDelete?.personName)}</span>
          <FormattedMessage id={TranslationKeys.employees_detail_partner_confirmDeleteMessage} />
        </SpanConfirmDialogBodyContainerStyled>
      </ConfirmDialog>

      {employerId && partnerFormDialogState && (
        <PartnerFormDialog
          open={partnerFormDialogState}
          closeDialog={onClosePartnerFormDialog}
          employee={employee}
          partner={partnerToEdit}
          refetchGetEmployeeQuery={refetchGetEmployeeQuery}
        />
      )}

      <DetailsPanel
        header={DetailsPanelHeader}
        body={DetailsPanelBody}
        actions={DetailsPanelActions}
        scrollBody={true}
      />
    </>
  );
};
