import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { faCheckCircle, faList, faTasks } from '@fortawesome/free-solid-svg-icons';

import type { SelectedAppState } from '../../../../store';
import { useStoreSelector } from '../../../../store';

import { IsSuperAdmin } from '../../../../components';

import { DashboardCategoriesContainerStyled, DashboardCategoryCard } from '../../Components';

import { DashboardOverviewInfo } from '../DashboardOverviewPage/components/DashboardOverviewInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslationKeys } from '../../../../translations';

export const DASHBOARD_OVERVIEW_PAGE_CATEGORY_ICON_DATA_TEST_ID = 'dashboard-overview-page-category-icon';
export const DASHBOARD_OVERVIEW_PAGE_CATEGORY_DESCRIPTION_DATA_TEST_ID = 'dashboard-overview-page-category-description';

export const DASHBOARD_CATEGORIES = [
  {
    icon: faList,
    label: TranslationKeys.eventflow_category_todo,
    description: TranslationKeys.eventflow_category_todo_description,
    link: '/dashboard/data-processing/event-flow-details?status=to-do',
  },
  {
    icon: faTasks,
    label: TranslationKeys['eventflow_category_in-progress'],
    description: TranslationKeys['eventflow_category_in-progress_description'],
    link: '/dashboard/data-processing/event-flow-details?status=in-progress',
  },
  {
    icon: faCheckCircle,
    label: TranslationKeys.eventflow_category_completed,
    description: TranslationKeys.eventflow_category_completed_description,
    link: '/dashboard/data-processing/event-flow-details?status=done',
  },
];

export const EventFlowOverviewPage = () => {
  const navigate = useNavigate();

  const [isSuperAdmin] = React.useState(IsSuperAdmin());

  const { user } = useStoreSelector<SelectedAppState>(state => state.AppReducer);

  const onCategoryCardClick = React.useCallback(
    (link: string) => () => {
      navigate(link);
    },
    [navigate],
  );

  return (
    <>
      <DashboardOverviewInfo user={user} />

      <DashboardCategoriesContainerStyled>
        {DASHBOARD_CATEGORIES.map(category => (
          <DashboardCategoryCard
            key={category.link}
            onClick={onCategoryCardClick(category.link)}
            disabled={isSuperAdmin}
          >
            <FontAwesomeIcon icon={category.icon} />
            <Typography variant="h4">
              <FormattedMessage id={category.label} />
            </Typography>
            <Typography>
              <FormattedMessage id={category.description} />
            </Typography>
          </DashboardCategoryCard>
        ))}
      </DashboardCategoriesContainerStyled>
    </>
  );
};
