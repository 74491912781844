// Extracted from https://github.com/auth0/jwt-decode/tree/master/lib
const b64DecodeUnicode = str =>
  decodeURIComponent(
    atob(str).replace(/(.)/g, function (m, p) {
      let code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = '0' + code;
      }

      return '%' + code;
    }),
  );

const base64_url_decode = str => {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');

  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw new Error('Illegal base64url string!');
  }

  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return atob(output);
  }
};

export function InvalidTokenError(message) {
  this.message = message;
}

InvalidTokenError.prototype = new Error();
InvalidTokenError.prototype.name = 'InvalidTokenError';

const decode = token => {
  if (typeof token !== 'string') {
    throw new InvalidTokenError('Invalid token specified');
  }
  try {
    const payload = token.split('.')[1] || null;
    const decoded = base64_url_decode(payload);
    return JSON.parse(decoded);
  } catch (e) {
    throw new InvalidTokenError('Invalid token specified: ' + e.message);
  }
};

export default decode;
