import { styled } from '@mui/material/styles';
import { Card, CardActionArea } from '@mui/material';

export const CategoryCardStyles = styled(Card, {
  name: 'CategoryCardStyles',
  shouldForwardProp: (propName: string) => !['disabled'].includes(propName),
})<{ disabled: boolean }>(({ theme, disabled }) => ({
  width: '380px',
  height: '180px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#01A0FF',
  ...(disabled ? { opacity: 0.4 } : {}),
  '&:hover': {
    boxShadow: '0 0 11px rgba(33,33,33,.2)',
    backgroundColor: '#0279D4',
  },
  '& h4, p': {
    color: theme.palette.common.white,
    lineHeight: theme.spacing(4),
  },
  '& h4': {
    fontWeight: 700,
  },
  '& svg': {
    color: theme.palette.common.white,
    opacity: 0.7,
    fontSize: '2rem',
    marginBottom: theme.spacing(1),
  },
}));

export const CategoryCardActionAreaStyled = styled(CardActionArea, {
  name: 'CategoryCardActionAreaStyled',
})(() => ({
  width: '100%',
  height: '100%',
  textAlign: 'center',
}));
