import React, { useCallback, useMemo } from 'react';

import type { SxProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

import type {
  TableCellClickEvent,
  TableCellHeaderArrowPosition,
  TableCellHeaderOnChangeFuc,
  TableCellHeaderSortOptions,
  TableCellHeaderTextAlign,
} from './TableCellHeader.types';

import {
  ArrowDropDownStyled,
  ArrowDropUpStyled,
  DivLabelContainerStyled,
  DivLabelStyled,
  SpanArrowsStyled,
  TableCellStyled,
} from './TableCellHeader.styles';

export type TableCellHeaderProps = {
  children: React.ReactNode;
  name: string;
  arrowPosition?: TableCellHeaderArrowPosition;
  className?: string;
  direction?: TableCellHeaderSortOptions;
  textAlign?: TableCellHeaderTextAlign;
  disabled?: boolean;
  onChange?: TableCellHeaderOnChangeFuc;
  sx?: SxProps<Theme>;
};

export const TableCellHeader = React.forwardRef<unknown, TableCellHeaderProps>((props, ref) => {
  const {
    arrowPosition,
    children,
    className,
    sx,
    direction = null,
    textAlign = 'left',
    disabled = false,
    name,
    onChange,
  } = props;

  const onTableCellClick = useCallback(
    (event: TableCellClickEvent) => {
      if (disabled || !onChange) {
        event.preventDefault();
      } else {
        onChange({ event: event, name, direction });
      }
    },
    [disabled, onChange, name, direction],
  );

  const sortable = !!arrowPosition && !!onChange;

  const arrows = useMemo(() => {
    if (sortable) {
      const ascending = direction === 'asc';
      const descending = direction === 'desc';
      return (
        <>
          {(ascending || direction === null) && <ArrowDropUpStyled ascending={ascending} />}
          {(descending || direction === null) && <ArrowDropDownStyled descending={descending} />}
        </>
      );
    }
    return null;
  }, [direction, sortable]);

  return (
    <TableCellStyled
      ref={ref}
      sx={sx}
      className={className}
      sortable={sortable}
      disabled={disabled}
      onClick={onTableCellClick}
    >
      <DivLabelContainerStyled sortable={sortable} active={!!direction && !!arrowPosition} textAlign={textAlign}>
        {arrowPosition === 'left' && <SpanArrowsStyled>{arrows}</SpanArrowsStyled>}
        <DivLabelStyled>{children}</DivLabelStyled>
        {arrowPosition === 'right' && <SpanArrowsStyled>{arrows}</SpanArrowsStyled>}
      </DivLabelContainerStyled>
    </TableCellStyled>
  );
});

TableCellHeader.displayName = 'TableCellHeader';
