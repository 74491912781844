import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import type { ConnectionErrorsDetail } from 'app/types';

import { TranslationKeys } from 'app/translations';

import formatter from 'app/utils/formatter';

import type {
  FilterableTableColumnType,
  FilterableTableOnSortChangeFunc,
  FilterableTablePaginationType,
  FilterableTableRowMapperFunc,
  FilterTableOnPaginationChangeFunc,
} from 'app/components';
import { ActionLink, FilterableTable } from 'app/components';

import { useIntl } from 'react-intl';

export const CONNECTION_ERRORS_DETAILS_TABLE_DATA_TEST_ID = 'connection-errors-details-table-component';

enum TableColumns {
  Connection = 'connectionName',
  PackageType = 'packageType',
  ModuleType = 'moduleType',
  LatestRun = 'latestRun',
}

type ConnectionErrorsDetailsTableProps = {
  isLoading?: boolean;
  connectionErrorsDetails: ConnectionErrorsDetail[];
  pagination: FilterableTablePaginationType;
  onPaginationChange: FilterTableOnPaginationChangeFunc;
  onSortChange: FilterableTableOnSortChangeFunc;
};

export const ConnectionErrorsDetailsTable = ({
  connectionErrorsDetails,
  pagination,
  onPaginationChange,
  onSortChange,
  isLoading = false,
}: ConnectionErrorsDetailsTableProps) => {
  const intl = useIntl();

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.Connection,
        title: <FormattedMessage id={TranslationKeys.global_connection} />,
      },
      {
        name: TableColumns.ModuleType,
        title: <FormattedMessage id={TranslationKeys.connections_tasks_module} />,
      },
      {
        name: TableColumns.PackageType,
        title: <FormattedMessage id={TranslationKeys.credentials_packageType} />,
      },
      {
        name: TableColumns.LatestRun,
        title: <FormattedMessage id={TranslationKeys.connection_latestRun} />,
      },
    ];
  }, []);

  const getPackageName = useCallback(
    packageType => {
      const exists = !!intl.messages[`packages.type.${packageType}`];
      const type = exists ? packageType : 'insurance-brydge';
      return <FormattedMessage id={`packages.type.${type}`} />;
    },
    [intl],
  );

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<ConnectionErrorsDetail>>(
    data => {
      return {
        data: {
          [TableColumns.Connection]: (
            <ActionLink to={`/employers/${data.employerId}/connections/${data.connectionId}`}>
              {data.connectionName}
            </ActionLink>
          ),
          [TableColumns.ModuleType]: <FormattedMessage id={`${data.moduleType}`} />,
          [TableColumns.PackageType]: getPackageName(data.packageType),
          [TableColumns.LatestRun]: formatter.date(data.startedAt),
        },
      };
    },
    [getPackageName],
  );

  return (
    <div data-testid={CONNECTION_ERRORS_DETAILS_TABLE_DATA_TEST_ID}>
      <FilterableTable<ConnectionErrorsDetail>
        loading={isLoading}
        columns={tableHeaders}
        rows={connectionErrorsDetails}
        rowMapper={tableRowMapper}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
      />
    </div>
  );
};
