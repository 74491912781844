import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { UseQueryWrapper, User } from '../../../types';

import type { GetOwnerUsersParams } from '../../../Domain/Users/Services';
import { userService } from '../../../Domain/Users/Services';

import { useSnakeBar } from '../../useSnakeBar';

export type GetEventFlowUsersQueryVariables = GetOwnerUsersParams;

export type GetEventFlowUsersQueryResultData = {
  data: Array<User>;
  totalCount: number;
};

const queryFn = (variables: GetEventFlowUsersQueryVariables) => async () => {
  const response = await userService.getEventFlowUsers(variables);
  return (response as AxiosResponse).data;
};

const QUERY_KEY = 'getEventFlowUsers';

export const useGetEventFlowUsersQuery: UseQueryWrapper<
  GetEventFlowUsersQueryResultData,
  GetEventFlowUsersQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetEventFlowUsersQueryResultData>(
    [QUERY_KEY, ...Object.values(variables || {})],
    queryFn(variables),
    {
      select,
      onError,
      ...(options || {}),
    },
  );
};
