import axios from 'axios';

import type { InsurerProduct, Owner, SummaryScales, RequestFiltersParams, RateTable } from 'app/types';
import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload } from 'app/utils';

export type GetRateTablesArgs = Omit<RequestFiltersParams, 'filters'> & {
  ownerId: Owner['ownerId'];
};

export type GetRateTableArgs = {
  ownerId: Owner['ownerId'];
  rateTableId: RateTable['rateTableId'];
};

export type GetRateTableInterpolationArgs = {
  ownerId: Owner['ownerId'];
  productId: RateTable['product']['productId'] | null;
  tableInput: string | null;
  discount: number;
  unitRate: number;
  rateTaleType: string;
};

export type CreateRateTableArgs = Omit<RateTable, '@id' | '@type' | 'createdAt' | 'rateTableId' | 'product'> & {
  ownerId: Owner['ownerId'];
  productId: InsurerProduct['productId'];
};

export type UpdateRateTableArgs = {
  ownerId: Owner['ownerId'];
}; // TODO: add the payload props

export type DeleteRateTableArgs = {
  ownerId: Owner['ownerId'];
  rateTableId: RateTable['rateTableId'];
};

export type GetSummaryScalesArgs = Omit<RequestFiltersParams, 'filters'> & {
  ownerId: Owner['ownerId'];
};

export type GetSummaryScaleArgs = {
  ownerId: Owner['ownerId'];
  summaryScaleId: SummaryScales['summaryOfRegulationScaleId'];
};

export type CreateSummaryScaleArgs = Omit<
  SummaryScales,
  '@id' | '@type' | 'table' | 'createdAt' | 'summaryOfRegulationScaleId' | 'product' | 'agePercentages'
> & {
  ownerId: Owner['ownerId'];
  agePercentages: SummaryScales['agePercentages'][0];
};

export type UpdateSummaryScaleArgs = {
  ownerId: Owner['ownerId'];
};

export type DeleteSummaryScaleArgs = {
  ownerId: Owner['ownerId'];
  summaryScaleId: SummaryScales['summaryOfRegulationScaleId'];
};

export const settingsServices = {
  async getRateTables({ ownerId, ...args }: GetRateTablesArgs) {
    const requestParams = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(args || {});

    return axios.get(`/owners/${ownerId}/settings/rate-tables`, { params: requestParams });
  },

  async getRateTableTypes({ ownerId }: GetRateTablesArgs) {
    return axios.get(`/owners/${ownerId}/settings/rate-table-types`);
  },

  async getRateTable({ ownerId, rateTableId }: GetRateTableArgs) {
    return axios.get(`/owner/${ownerId}/settings/rate-tables/${rateTableId}`);
  },

  async getRateTableInterpolation({ ownerId, ...args }: GetRateTableInterpolationArgs) {
    return axios.post(`/owners/${ownerId}/settings/rate-tables/interpolation`, { ...args });
  },

  async createRateTable({ ownerId, ...args }: CreateRateTableArgs) {
    return axios.post(`/owners/${ownerId}/settings/rate-tables`, { ...args });
  },

  async updateRateTable({ ownerId, ...args }: UpdateRateTableArgs) {
    return axios.put(`/owners/${ownerId}/settings/rate-tables`, { ...args });
  },

  async deleteRateTable({ ownerId, rateTableId }: DeleteRateTableArgs) {
    return axios.delete(`/owners/${ownerId}/settings/rate-tables/${rateTableId}`);
  },

  async getSummaryScales({ ownerId, ...args }: GetSummaryScalesArgs) {
    const requestParams = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(args || {});

    return axios.get(`/owners/${ownerId}/settings/scales`, { params: requestParams });
  },

  async createSummaryScale({ ownerId, ...args }: CreateSummaryScaleArgs) {
    return axios.post(`/owners/${ownerId}/settings/scales`, { ...args });
  },

  async deleteSummaryScale({ ownerId, summaryScaleId }: DeleteSummaryScaleArgs) {
    return axios.delete(`/owners/${ownerId}/settings/scales/${summaryScaleId}`);
  },
};
