import React, { useCallback, useState } from 'react';

import type { EventFlow } from '../../../../../../types';
import { ActionButton } from '../../../../../../components';
import { EventFlowCommentFieldStyled, EventFlowCommentSubmitWrapper } from './EventFlowCommentForm.styles';
import { useIntl } from 'react-intl';
import { TranslationKeys } from '../../../../../../translations';

type EventFlowCommentFormType = {
  eventFlow: EventFlow | null;
  onUpdateRowComment: (eventFlow: EventFlow, comment: string) => void;
  isUpdatingEventFlowLoading: boolean;
};

export const EventFlowCommentForm = ({
  eventFlow,
  onUpdateRowComment,
  isUpdatingEventFlowLoading,
}: EventFlowCommentFormType) => {
  const intl = useIntl();

  const [commentState, setCommentState] = useState<{ comment: string }>({
    comment: eventFlow?.comment || '',
  });

  const onChangeComment = useCallback(({ value }) => {
    setCommentState(prev => ({ comment: value }));
  }, []);

  const onSubmit = useCallback(() => {
    if (eventFlow) {
      onUpdateRowComment(eventFlow, commentState.comment);
    }
  }, [commentState.comment, eventFlow, onUpdateRowComment]);

  return (
    <div>
      <EventFlowCommentFieldStyled
        onChange={onChangeComment}
        disabled={isUpdatingEventFlowLoading}
        field={{
          type: 'text',
          placeholder: intl.formatMessage({ id: TranslationKeys.eventflow_comment_placeholder }),
          name: 'event-flow-comment',
          value: commentState.comment,
          multiline: true,
        }}
      />
      <EventFlowCommentSubmitWrapper>
        <ActionButton disabled={isUpdatingEventFlowLoading} messageId={'button.submit'} onClick={onSubmit} />
      </EventFlowCommentSubmitWrapper>
    </div>
  );
};
