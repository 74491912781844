import React from 'react';
import { Typography } from '@mui/material';
import { IntlMessage } from '../Intl';
import { GridContainerStyled, GridItemStyled } from './ErrorPanel.styles';

const ErrorPanel = ({ header, body }) => {
  return (
    <GridContainerStyled container spacing={2}>
      {!!header && (
        <GridItemStyled item xs={12}>
          <Typography variant="h4">
            <IntlMessage value={header} />
          </Typography>
        </GridItemStyled>
      )}
      {!!body && (
        <GridItemStyled item xs={12}>
          <Typography variant="body1">
            <IntlMessage value={body} />
          </Typography>
        </GridItemStyled>
      )}
    </GridContainerStyled>
  );
};

export default ErrorPanel;
