import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { InputFieldFactory } from 'app/components';
import { useEmployeeGetRelatedQuery } from 'app/hooks';
import { DivStyled } from './EmployeeConnectionSelector.styles';

type EmployeeConnectionSelectorProps = {
  employerId: string | undefined;
  employeeId: string | undefined;
};

export const EmployeeConnectionSelector = ({ employerId, employeeId }: EmployeeConnectionSelectorProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data, isLoading } = useEmployeeGetRelatedQuery({
    variables: { employerId, employeeId },
    options: {
      enabled: !!employerId && !!employeeId,
    },
  });

  const onChangeConnection = React.useCallback(
    ({ value }) => {
      const tab = pathname.match(/\/([\w-]+)$/)?.[1] || 'info';
      navigate(`/employers/${employerId}/employees/${value}/${tab}`);
    },
    [employerId, navigate, pathname],
  );

  const connectionsSelectItems = React.useMemo(() => {
    return (data?.relatedDTO || []).map(related => {
      const productName = related.productName ? `[${related.productName}]` : '';

      return {
        value: related.relatedId,
        element: `${related.connectionName} ${productName}`,
        default: related.relatedId === employeeId,
      };
    });
  }, [data?.relatedDTO, employeeId]);

  return (
    <DivStyled>
      <InputFieldFactory
        field={{
          type: 'select',
          name: 'connection',
          loading: isLoading,
          disabled: isLoading,
          variant: 'slim',
          items: connectionsSelectItems,
        }}
        onChange={onChangeConnection}
      />
    </DivStyled>
  );
};
