import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Card, CardContent } from '@mui/material';

import type { Insurer } from '../../../../types/insurer.types';

import { TranslationKeys } from '../../../../translations';

import type { TableCellHeaderSortOptions } from '../../../../components/TableCellHeader/TableCellHeader.types';

import { ActionLink } from '../../../../components/ActionLink';
import { FilterableTable } from '../../../../components/FilterableTable';
import type { KebabMenuItem } from '../../../../components/KebabMenu';
import KebabMenu from '../../../../components/KebabMenu';

import { columnActionsStyles } from './InsurersTable.styles';

enum TableColumns {
  Name = 'insurerName',
  Type = 'type',
  AgencyNumber = 'agencyNumber',
  Products = 'products',
  ColumnActions = 'col_actions',
}

type InsurersTableProps = {
  className?: string;
  insurers: Array<Insurer>;
  page: number;
  rowsPerPage: number;
  totalCount: number;
  onPaginationChange: (args: { page?: number; rowSize?: number }) => void;
  onSortChange?: (sortBy: Record<TableColumns, TableCellHeaderSortOptions>) => void;
  loading?: boolean;
};

export const InsurersTable = ({
  className,
  insurers,
  onPaginationChange,
  onSortChange,
  page,
  rowsPerPage,
  totalCount,
  loading = false,
}: InsurersTableProps) => {
  const pagination = React.useMemo(() => {
    return {
      pageSize: rowsPerPage,
      totalCount,
      page,
    };
  }, [rowsPerPage, totalCount, page]);

  const columns = React.useMemo(
    () => [
      {
        name: TableColumns.Name,
        title: <FormattedMessage id={TranslationKeys.insurers_title} />,
        sortable: !!onSortChange,
      },
      {
        name: TableColumns.Type,
        title: <FormattedMessage id={TranslationKeys.insurers_type} />,
      },
      {
        name: TableColumns.AgencyNumber,
        title: <FormattedMessage id={TranslationKeys.insurers_number} />,
      },
      {
        name: TableColumns.Products,
        title: <FormattedMessage id={TranslationKeys.insurers_products} />,
      },
      {
        name: TableColumns.ColumnActions,
        styles: columnActionsStyles,
      },
    ],
    [onSortChange],
  );

  const buildRowActions = React.useCallback((insurer: Insurer) => {
    const actions: Array<KebabMenuItem> = [
      {
        to: '/insurers/' + insurer.insurerId,
        element: <FormattedMessage id={TranslationKeys.insurers_menu_view} />,
      },
    ];

    return <KebabMenu items={actions} />;
  }, []);

  const rowMapper = React.useCallback(
    insurer => {
      return {
        data: {
          [TableColumns.Name]: (
            <ActionLink to={`/insurers/${insurer.insurerId}/info`}>{insurer.insurerName}</ActionLink>
          ),
          [TableColumns.Type]: insurer?.type ? <FormattedMessage id={`products.type.${insurer?.type}`} /> : '-',
          [TableColumns.AgencyNumber]: insurer.agencyNumber ?? '-',
          [TableColumns.Products]: insurer?.products?.length ?? 0,
          [TableColumns.ColumnActions]: buildRowActions(insurer),
        },
      };
    },
    [buildRowActions],
  );

  return (
    <Card sx={{ borderRadius: '10px' }} className={className}>
      <CardContent sx={{ padding: 0 }}>
        <FilterableTable
          columns={columns}
          loading={loading}
          rows={insurers || []}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          onSortChange={onSortChange}
          rowMapper={rowMapper}
        />
      </CardContent>
    </Card>
  );
};
