import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ConnectionsActions } from '../../Ducks/Connections.duck';
import { ConnectionsTable, ConnectionsToolbar } from '../../Components';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { DivStyled } from './ConnectionsListPage.styles';

const ConnectionsListPage = () => {
  const dispatch = useDispatch();
  const { loading, items, page, pageSize, totalCount, logsCount, eventsCount, searchQuery } = useSelector(
    state => state.ConnectionsReducer,
  );

  useEffect(() => {
    dispatch(ConnectionsActions.requestData());
  }, [dispatch]);

  const handleSearch = event => {
    dispatch(ConnectionsActions.search(event.target.value));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(ConnectionsActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(ConnectionsActions.setPageSize(event.target.value));
  };

  useBreadcrumb('menu.connections', {
    hideTrail: true,
    hideTitle: true,
    otherElements: (
      <ConnectionsToolbar
        handleSearch={handleSearch}
        totalCount={loading ? undefined : totalCount}
        initialSearchQuery={searchQuery}
      />
    ),
  });

  return (
    <DivStyled>
      <ConnectionsTable
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        connections={items}
        page={page - 1}
        rowsPerPage={pageSize}
        totalCount={totalCount}
        loading={loading}
        logsCount={logsCount}
        eventsCount={eventsCount}
      />
    </DivStyled>
  );
};

export default ConnectionsListPage;
