import React, { useEffect, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ConnectionActions } from '../../Ducks/Connection.duck';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';
import { NotFoundView } from '../../../../views';
import TabOutlet from 'app/components/TabOutlet';
import { HasAccessTo } from 'app/components/Authorization';
import { BATCH, CONNECTION, EVENT } from '../../../../common/Authorization/entities';
import { DOWNLOAD, VIEW } from '../../../../common/Authorization/permissions';
import { isNil } from 'app/utils';

const ConnectionDetailsPage = () => {
  const dispatch = useDispatch();
  const { connectionId } = useParams();
  const { item: connection, loaded, loading } = useSelector(state => state.ConnectionReducer);

  useEffect(() => {
    if (!isNil(connectionId) && (!connection || connection.connectionId !== connectionId)) {
      dispatch(ConnectionActions.requestData(connectionId));
    }
  }, [connection, dispatch, connectionId]);

  const disabledIfNoAccessToConnectionDownload = !HasAccessTo(CONNECTION, DOWNLOAD);
  const disabledIfNoAccessToEventView = !HasAccessTo(EVENT, VIEW);
  const disabledIfNoAccessToBatchView = !HasAccessTo(BATCH, VIEW);

  const tabData = useMemo(() => {
    return [
      {
        path: 'info',
        label: <FormattedMessage id="connections.tab.general" />,
      },
      {
        path: 'events',
        label: <FormattedMessage id="connections.tab.events" />,
        disabled: disabledIfNoAccessToEventView,
      },
      {
        path: 'batches',
        label: <FormattedMessage id="connections.tab.batches" />,
        disabled: disabledIfNoAccessToBatchView,
      },
      {
        path: 'downloads',
        label: <FormattedMessage id="connections.tab.downloads" />,
        disabled: disabledIfNoAccessToConnectionDownload,
      },
    ];
  }, [disabledIfNoAccessToBatchView, disabledIfNoAccessToConnectionDownload, disabledIfNoAccessToEventView]);

  const title = useMemo(() => {
    if (connection?.connectionName) {
      return <Typography variant="h5">{connection.connectionName}</Typography>;
    }
    return (
      <Typography variant="h5">
        <Skeleton variant={'text'} width={200} />
      </Typography>
    );
  }, [connection]);

  const breadcrumb = useMemo(() => {
    return {
      title: connection?.connectionName,
      data: {
        titleElement: title,
      },
    };
  }, [connection, title]);

  const exists = loading || (loaded && !!connection);

  return exists ? (
    <TabOutlet tabs={tabData} breadcrumb={breadcrumb} />
  ) : (
    <Routes>
      <Route element={<NotFoundView />} path="*" />
    </Routes>
  );
};

export default ConnectionDetailsPage;
