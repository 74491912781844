import React from 'react';
import { Formik } from 'formik';

import { TranslationKeys } from 'app/translations';

import { useBreadcrumb } from 'app/components';

import { ScalesTableForm, useScalesTableForm } from 'app/Domain/Settings/Components';

import { SettingsSummaryScalesCreateToolbar } from './components/SettingsSummaryScalesCreateToolbar';

import type { SummaryScalesFormValues } from '../../Components/ScalesTableForm/ScalesTableForm.types';

export const SettingsSummaryScalesCreatePage = () => {
  const { isSubmitting, formikInitialValues, formikValidationSchema, formikOnSubmitCreate } = useScalesTableForm();

  const isLoading = isSubmitting;

  useBreadcrumb(TranslationKeys.menu_settings_rateTables, {
    hideTrail: true,
    hideTitle: true,
    otherElements: <SettingsSummaryScalesCreateToolbar isLoading={isLoading} />,
  });

  return (
    <div>
      <Formik<SummaryScalesFormValues>
        initialValues={formikInitialValues}
        validationSchema={formikValidationSchema}
        onSubmit={formikOnSubmitCreate}
      >
        <ScalesTableForm isLoading={isLoading} />
      </Formik>
    </div>
  );
};
