import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { TranslationKeys } from '../../../../../../translations';

export const RESET_PASSWORD_VIEW_INVALID_LINK_MSG_DATA_TEST_ID = 'reset-password-invalid-link-msg-component';

export const ResetPasswordViewInvalidLinkMsg = () => {
  return (
    <Box
      data-testid={RESET_PASSWORD_VIEW_INVALID_LINK_MSG_DATA_TEST_ID}
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      <Typography variant="h4" color={'error'}>
        <FormattedMessage id={TranslationKeys.resetPassword_invalidLinkMessage} />
      </Typography>
      <Typography variant="h5" sx={theme => ({ color: theme.palette.primary.main })}>
        <FormattedMessage id={TranslationKeys.resetPassword_invalidLinkSolution} />
      </Typography>
    </Box>
  );
};
