import { styled } from '@mui/material/styles';
import { InputAdornment, inputBaseClasses } from '@mui/material';

export const InputAdornmentStyled = styled(InputAdornment, {
  name: 'InputAdornmentStyled',
  shouldForwardProp: (propName: string) => propName !== 'multiline',
})<{ multiline: boolean }>(({ theme, multiline }) => {
  if (multiline) {
    return {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(2),
    };
  }
  return {};
});

export const SpanStyled = styled('span', {
  name: 'SpanStyled',
  shouldForwardProp: (propName: string) => !['isOptionSizeSmall', 'textAlign'].includes(propName),
})<{ isOptionSizeSmall: boolean; textAlign?: string }>(({ isOptionSizeSmall, textAlign }) => ({
  ...(isOptionSizeSmall
    ? {
        [`& .${inputBaseClasses.root}`]: {
          minWidth: 'unset',
        },
        [`& .${inputBaseClasses.input}`]: {
          width: '60px',
        },
      }
    : {}),
  ...(textAlign
    ? {
        [`& .${inputBaseClasses.input}`]: {
          textAlign: textAlign || 'left',
        },
      }
    : {}),
}));
