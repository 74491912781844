import { put, select, takeLatest, debounce } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { checkDownload, clearConnectionLogs, getConnectionLogs } from '../Services/ConnectionService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  RequestData: '[LogEntries] Request',
  FulfilledTable: '[LogEntries] Fulfilled',
  SearchTable: '[LogEntries] Search',
  ChangePage: '[LogEntries] change page',
  SetPageSize: '[LogEntries] set page size',
  SortTable: '[LogEntries] Sort',
  ApplyFilter: '[LogEntries] Apply filter',
  RemoveFilter: '[LogEntries] Remove filter',
  SetConnectionId: '[LogEntries] Set connection Id',
  CheckCanDownloadMissingDetails: '[LogEntries] Can download missing details',
  SetCanDownload: '[LogEntries] Set can download',
  ClearLogs: '[LogEntries] Clear logs',
};

const initialState = {
  ...tableInitialState,
  connectionId: null,
  canDownload: false,
  checkingDownload: true,
  clearingLogs: false,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.SetConnectionId: {
      return {
        ...state,
        connectionId: action.connectionId,
      };
    }
    case actionTypes.SetCanDownload: {
      return {
        ...state,
        canDownload: action.canDownload,
        checkingDownload: false,
      };
    }
    case actionTypes.ClearLogs: {
      return {
        ...state,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
  checkCanDownloadMissingDetails: () => ({
    type: actionTypes.CheckCanDownloadMissingDetails,
  }),
  setCanDownload: canDownload => ({
    type: actionTypes.SetCanDownload,
    canDownload,
  }),
  clearLogs: (connectionId, old) => ({
    type: actionTypes.ClearLogs,
    connectionId,
    old,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.ClearLogs, function* clear(action) {
    const currentState = yield select(state => {
      return state.LogEntriesReducer;
    });
    currentState.clearingLogs = true;

    try {
      yield clearConnectionLogs(action.connectionId, action.old);
      yield put(actions.requestData(action.connectionId));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'clearLogs',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RequestData, function* request() {
    try {
      const currentState = yield select(state => {
        return state.LogEntriesReducer;
      });
      currentState.clearingLogs = false;

      const response = yield getConnectionLogs(
        currentState.connectionId,
        currentState.page,
        currentState.pageSize,
        currentState.sortBy,
        currentState.filters,
        currentState.searchQuery,
      );

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getLogs',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.CheckCanDownloadMissingDetails, function* checkCanDownloadMissingDetails() {
    try {
      const currentState = yield select(state => {
        return state.LogEntriesReducer;
      });
      const response = yield checkDownload(currentState.connectionId);
      yield put(actions.setCanDownload(response.data));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'checkDownload',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });
}
