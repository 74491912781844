import { TranslationKeys } from 'app/translations';

import type { GenderStatusEnum } from 'app/types';

import { normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc } from 'app/components';

export const PartnerDetailsPanelFields = {
  SocialSecurityNumber: {
    Label: TranslationKeys.employees_detail_socialSecurityNumber,
    InputName: 'socialSecurityNumber',
    Options: {
      style: 'text',
    },
  },
  DateOfBirth: {
    Label: TranslationKeys.employees_detail_dateOfBirth,
    InputName: 'dateOfBirth',
    Options: {
      style: 'date',
    },
  },
  StartOfRelationship: {
    Label: TranslationKeys.employees_detail_partner_startOfRelationship,
    InputName: 'startOfRelationship',
    Options: {
      style: 'date',
    },
  },
  EndOfRelationship: {
    Label: TranslationKeys.employees_detail_partner_endOfRelationship,
    InputName: 'endOfRelationship',
    Options: {
      style: 'date',
    },
  },
  Gender: {
    Label: TranslationKeys.global_gender,
    InputName: 'gender',
    Options: {
      style: 'select',
    },
  },
  PersonName: {
    FieldTypeName: 'personName',
    Props: {
      Initials: {
        Label: TranslationKeys.global_initials,
        FieldTypeName: 'initials',
        InputName: 'personName.initials',
        Options: {
          style: 'text',
        },
      },
      FirstName: {
        Label: TranslationKeys.global_firstName,
        FieldTypeName: 'firstName',
        InputName: 'personName.firstName',
        Options: {
          style: 'text',
        },
      },
      LastName: {
        Label: TranslationKeys.global_lastName,
        FieldTypeName: 'lastName',
        InputName: 'personName.lastName',
        Options: {
          style: 'text',
        },
      },
      LastNamePrefix: {
        Label: TranslationKeys.global_lastNamePrefix,
        FieldTypeName: 'lastNamePrefix',
        InputName: 'personName.lastNamePrefix',
        Options: {
          style: 'text',
        },
      },
    },
  },
  ExtraPartnerPensionByStart: {
    Label: TranslationKeys.employees_detail_extraPartnerPensionByStart,
    InputName: 'extraPartnerPensionByStart',
    Options: {
      style: 'text',
    },
  },
} as const;

export const PartnerDetailsPanelFieldOptionsAndLabel =
  normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc(PartnerDetailsPanelFields);

export type PartnerDetailsPanelFormValues = {
  [PartnerDetailsPanelFields.SocialSecurityNumber.InputName]: string;
  [PartnerDetailsPanelFields.DateOfBirth.InputName]: string | null;
  [PartnerDetailsPanelFields.StartOfRelationship.InputName]: string;
  [PartnerDetailsPanelFields.EndOfRelationship.InputName]: string | null;
  [PartnerDetailsPanelFields.Gender.InputName]: GenderStatusEnum;
  [PartnerDetailsPanelFields.PersonName.FieldTypeName]: {
    [PartnerDetailsPanelFields.PersonName.Props.Initials.FieldTypeName]: string;
    [PartnerDetailsPanelFields.PersonName.Props.FirstName.FieldTypeName]: string;
    [PartnerDetailsPanelFields.PersonName.Props.LastName.FieldTypeName]: string;
    [PartnerDetailsPanelFields.PersonName.Props.LastNamePrefix.FieldTypeName]: string;
  };
};
