import React from 'react';
import { Formik } from 'formik';

import type { Owner } from '../../../../types/owner.types';

import FormikModal from '../../../../components/FormikModal';

import type { OwnerFormValues } from '../../Forms/OwnerForm';
import { OwnerForm, useOwnerForm } from '../../Forms/OwnerForm';
import type { UseQueryRefetch } from '../../../../types/useQuery.types';

type EditOwnerDialogProps = {
  open: boolean;
  onClose: () => void;
  owner: Owner;
  refetchGetOwnerQuery: UseQueryRefetch;
};

export const EditOwnerDialog = ({ open, onClose, owner, refetchGetOwnerQuery }: EditOwnerDialogProps) => {
  const { formikValidationSchema, formikInitialValues, formikOnSubmitEdit } = useOwnerForm({
    owner,
    afterSubmit: () => {
      refetchGetOwnerQuery();
      onClose();
    },
  });

  return (
    <Formik<OwnerFormValues>
      initialValues={formikInitialValues}
      validationSchema={formikValidationSchema}
      onSubmit={formikOnSubmitEdit}
    >
      <FormikModal maxWidth="md" show={open} title={owner.ownerName} onHide={onClose}>
        <OwnerForm />
      </FormikModal>
    </Formik>
  );
};
