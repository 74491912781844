import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(-2),
}));

export const TypographyStyled = styled(Typography, {
  name: 'TypographyStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(-1),
}));
