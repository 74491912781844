import React, { memo } from 'react';
import FormikWrapper from '../FormikWrapper';
import InternalAutocomplete from './InternalAutocomplete';
import type { InputFactoryOnChangeFunc, InputFieldFactoryProps } from '../Factory';
import type { useFormikContext } from 'formik';
import type { AutocompleteInputChangeReason } from '@mui/material';

export type AutocompleteProps = Pick<InputFieldFactoryProps, 'className' | 'field' | 'hideErrorText'> & {
  onChange?: InputFactoryOnChangeFunc;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => void;
  variant?: 'default' | 'slim' | string;
  noOptionsText?: string;
};

const Autocomplete = (props: AutocompleteProps) => {
  return (
    <FormikWrapper>
      {(formik: ReturnType<typeof useFormikContext>) => <InternalAutocomplete formik={formik} {...props} />}
    </FormikWrapper>
  );
};

export default memo(Autocomplete);
