import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';

import { faDownload, faFileArrowDown, faListCheck, faToolbox } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from '../../../../translations';

import type { SelectedAppState } from '../../../../store';
import { useStoreSelector } from '../../../../store';

import { IsSuperAdmin } from '../../../../components';

import { DashboardCategoriesContainerStyled, DashboardCategoryCard } from '../../Components';

import { DashboardOverviewInfo } from './components/DashboardOverviewInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DASHBOARD_OVERVIEW_PAGE_CATEGORY_ICON_DATA_TEST_ID = 'dashboard-overview-page-category-icon';
export const DASHBOARD_OVERVIEW_PAGE_CATEGORY_DESCRIPTION_DATA_TEST_ID = 'dashboard-overview-page-category-description';

export const DASHBOARD_CATEGORIES = [
  {
    icon: faDownload,
    label: TranslationKeys.dashboard_category_dataProcessing,
    description: TranslationKeys.dashboard_category_dataProcessing_description,
    link: '/dashboard/data-processing',
  },
  {
    icon: faFileArrowDown,
    label: TranslationKeys.dashboard_category_downloads,
    description: TranslationKeys.dashboard_category_downloads_description,
    link: '/dashboard/downloads',
    disabled: true,
    style: { display: 'none' },
  },
  {
    icon: faListCheck,
    label: TranslationKeys.dashboard_category_tasks,
    description: TranslationKeys.dashboard_category_tasks_description,
    link: '/dashboard/tasks',
    disabled: true,
    style: { display: 'none' },
  },
  {
    icon: faToolbox,
    label: TranslationKeys.dashboard_category_tools,
    description: TranslationKeys.dashboard_category_tools_description,
    link: '/dashboard/tools',
    disabled: true,
    style: { display: 'none' },
  },
];

export const DashboardOverviewPage = () => {
  const navigate = useNavigate();

  const [isSuperAdmin] = React.useState(IsSuperAdmin());

  const { user } = useStoreSelector<SelectedAppState>(state => state.AppReducer);

  const onCategoryCardClick = React.useCallback(
    (link: string) => () => {
      navigate(link);
    },
    [navigate],
  );

  return (
    <>
      <DashboardOverviewInfo user={user} />

      <DashboardCategoriesContainerStyled>
        {DASHBOARD_CATEGORIES.map(category => (
          <DashboardCategoryCard
            key={category.link}
            onClick={onCategoryCardClick(category.link)}
            disabled={isSuperAdmin || category?.disabled}
            style={category.style}
          >
            <FontAwesomeIcon icon={category.icon} data-testid={DASHBOARD_OVERVIEW_PAGE_CATEGORY_ICON_DATA_TEST_ID} />
            <Typography variant="h4">
              <FormattedMessage id={category.label} />
            </Typography>
            <Typography data-testid={DASHBOARD_OVERVIEW_PAGE_CATEGORY_DESCRIPTION_DATA_TEST_ID}>
              <FormattedMessage id={category.description} />
            </Typography>
          </DashboardCategoryCard>
        ))}
      </DashboardCategoriesContainerStyled>
    </>
  );
};
