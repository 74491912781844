import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography } from '@mui/material';

import type { User } from '../../../../../../types';

import { TranslationKeys } from '../../../../../../translations';

import { DashboardOverviewInfoStatusItem } from './components';

import {
  GreetingsContainerStyled,
  GreetingsOwnerLogoStyled,
  GreetingsOwnerNameStyled,
  GreetingsTextContainerStyled,
  InfoContainerStyled,
  StatusContainerStyled,
} from './DashboardOverviewInfo.styles';

type DashboardOverviewInfoProps = {
  user: User;
};

export const DashboardOverviewInfo = ({ user }: DashboardOverviewInfoProps) => {
  const intl = useIntl();

  const greetingText = React.useMemo(() => {
    const currentHour = new Date().getHours();

    let textId: string;

    if (currentHour < 12) {
      textId = TranslationKeys.dashboard_greeting_morning;
    } else if (currentHour < 18) {
      textId = TranslationKeys.dashboard_greeting_afternoon;
    } else {
      textId = TranslationKeys.dashboard_greeting_evening;
    }

    return <FormattedMessage id={textId} />;
  }, []);

  return (
    <InfoContainerStyled>
      <GreetingsContainerStyled>
        {!!user?.ownerLogo && (
          <GreetingsOwnerLogoStyled
            src={user?.ownerLogo}
            alt={intl.formatMessage({ id: TranslationKeys.global_logo })}
          />
        )}

        <GreetingsTextContainerStyled>
          <Typography variant={'h2'}>
            {greetingText}
            {', '}
            {user.personName.fullName}
          </Typography>

          {!!user?.ownerName && (
            <GreetingsOwnerNameStyled variant={'h5'}>
              <FormattedMessage id={TranslationKeys.owners_title} />
              <span>{user?.ownerName}</span>
            </GreetingsOwnerNameStyled>
          )}
        </GreetingsTextContainerStyled>
      </GreetingsContainerStyled>

      <StatusContainerStyled style={{ display: 'none' }}>
        <DashboardOverviewInfoStatusItem
          type={'warning'}
          messageId={TranslationKeys.dashboard_count_warnings}
          count={0}
        />
        <DashboardOverviewInfoStatusItem
          type={'critical'}
          messageId={TranslationKeys.dashboard_count_criticalProblems}
          count={0}
        />
      </StatusContainerStyled>
    </InfoContainerStyled>
  );
};
