import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UseQueryWrapper } from '../../../types/useQuery.types';
import type { Employee } from '../../../types/employee.types';

import type { GetEmployeeSynchronizationArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

import { useSnakeBar } from '../../useSnakeBar';

export type GetEmployeeSynchronizationQueryResultData = Employee['synchronization'];

export type GetEmployeeSynchronizationQueryVariables = {
  [Key in keyof GetEmployeeSynchronizationArgs]: GetEmployeeSynchronizationArgs[Key] | undefined;
};

const queryFn =
  ({ employeeId, connectionId }: GetEmployeeSynchronizationQueryVariables) =>
  async () => {
    if (employeeId && connectionId) {
      const response = await employeeService.getEmployeeSynchronization({ connectionId, employeeId });
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployeeSynchronization';

export const useEmployeeGetEmployeeSynchronizationQuery: UseQueryWrapper<
  GetEmployeeSynchronizationQueryResultData,
  GetEmployeeSynchronizationQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetEmployeeSynchronizationQueryResultData>(
    [QUERY_KEY, variables.connectionId, variables.employeeId],
    queryFn(variables),
    {
      onError,
      ...(options || {}),
    },
  );
};
