type TriggerFileDownloadArgs = {
  file: BlobPart;
  fileName: string;
};

export const triggerFileDownload = ({ file, fileName }: TriggerFileDownloadArgs) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
