import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import type { UseQueryRefetch, User, UserOwner } from '../../../../../../types';

import { useDialog, useSnakeBar, useUserOwnerUpdateMutation } from '../../../../../../hooks';

import { TranslationKeys } from '../../../../../../translations';

import { ActionButton, IsSuperAdmin } from '../../../../../../components';

import { UserEnvironmentsListConfirmDialog } from './components/UserEnvironmentsListConfirmDialog';

import type {
  UserEnvironmentsListSelectedData,
  UserEnvironmentsListOnChangeSelected,
} from './UserEnvironmentsList.types';

import { useUserGetEnvironmentsQuery } from '../../../../../../hooks/api/user/useUserGetEnvironmentsQuery';
import { UserEnvironmentsListOwners } from './components';

type UserEnvironmentsListProps = {
  user: User | undefined;
  refetchGetUserQuery: UseQueryRefetch;
};

export const UserEnvironmentsList = ({ user, refetchGetUserQuery }: UserEnvironmentsListProps) => {
  const { mutate: userOwnerUpdateMutation } = useUserOwnerUpdateMutation();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const {
    dialogState: showConfirmDialog,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialog();

  const isSuperAdmin = IsSuperAdmin();
  const [userDefaultSelectedData, setUserDefaultSelectedData] = React.useState<UserEnvironmentsListSelectedData>();
  const [selectedData, setSelectedData] = React.useState<UserEnvironmentsListSelectedData>();
  const [, setConfirmationDialog] = React.useState(false);

  const { data: dataFilterForSuperAdmin, isLoading: isLoadingGetDataFilterForSuperAdminQuery } =
    useUserGetEnvironmentsQuery({ variables: { userId: user?.userId ?? '' } });

  const isLoading = isLoadingGetDataFilterForSuperAdminQuery;

  const dataFilter = React.useMemo(() => {
    return dataFilterForSuperAdmin;
  }, [dataFilterForSuperAdmin]);

  React.useEffect(() => {
    const owners: UserEnvironmentsListSelectedData = {
      owners:
        dataFilter
          ?.filter(({ active }) => active === 1)
          .map(owner => {
            if (owner.ownerId !== null && owner.ownerId !== undefined) {
              return owner as UserOwner;
            }
            return null;
          })
          .filter((owner): owner is UserOwner => owner !== null) ?? [],
      areAllOwnersSelected: false,
    };

    setSelectedData(owners);
    setUserDefaultSelectedData(owners);

    return () => {};
  }, [dataFilter, user?.ownerId]);

  const hasChangedAccesses = React.useMemo(() => {
    const { areAllOwnersSelected: defaultAreAllOwnersSelected, ...defaultData } = userDefaultSelectedData || {};

    const { areAllOwnersSelected: selectedAreAllOwnersSelected, ...currentSelectedData } = selectedData || {};

    const userOwners = JSON.stringify(defaultData || {});
    const selectedOwners = JSON.stringify(currentSelectedData || {});

    return userOwners !== selectedOwners;
  }, [userDefaultSelectedData, selectedData]);

  const onClickCancelAccessesChanges = React.useCallback(() => {
    setSelectedData(userDefaultSelectedData);
  }, [userDefaultSelectedData]);

  const onChangeSelected = React.useCallback<UserEnvironmentsListOnChangeSelected>(newSelectedData => {
    setSelectedData(newSelectedData);
  }, []);

  const updateUserOwners = React.useCallback(() => {
    if (user && selectedData) {
      const method = 'updateUserOwner';

      const payload = {
        userId: user.userId,
        ownerIds: selectedData.owners.map(owner => owner.ownerId),
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      userOwnerUpdateMutation(payload, {
        onSuccess: () => {
          closeConfirmDialog();
          refetchGetUserQuery();
          showSuccessSnakeBar({ method });
        },
        onError: (error: any) => {
          showErrorSnakeBar({ method, message: error?.message });
        },
      });
    }
  }, [
    closeConfirmDialog,
    refetchGetUserQuery,
    selectedData,
    showErrorSnakeBar,
    showSuccessSnakeBar,
    user,
    userOwnerUpdateMutation,
  ]);

  return isSuperAdmin ? (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant={'h6'}>
          <FormattedMessage id={TranslationKeys.users_environmentsList_headerText} />
        </Typography>
      </Box>
      <UserEnvironmentsListOwners
        isLoading={isLoading}
        onChangeSelected={onChangeSelected}
        selectedData={selectedData}
        owners={dataFilter}
        openConfirmationDialog={setConfirmationDialog}
      ></UserEnvironmentsListOwners>

      {hasChangedAccesses && (
        <Box marginTop={3} marginBottom={3} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={2}>
          <ActionButton
            variant={'outlined'}
            messageId={TranslationKeys.button_cancel}
            onClick={onClickCancelAccessesChanges}
            disabled={isLoading}
          />
          <ActionButton messageId={TranslationKeys.button_save} onClick={openConfirmDialog} disabled={isLoading} />
        </Box>
      )}

      {showConfirmDialog && !!selectedData && (
        <UserEnvironmentsListConfirmDialog
          show={showConfirmDialog}
          isLoading={isLoading}
          onClose={closeConfirmDialog}
          onConfirm={updateUserOwners}
          selectedData={selectedData}
          data={dataFilter ?? []}
        />
      )}
    </>
  ) : null;
};
