import React from 'react';

import { DivRootStyled } from './ReportRootContainer.styles';

type ReportDataContainerProps = {
  children: React.ReactNode;
};

export const ReportDataContainer = ({ children }: ReportDataContainerProps) => {
  return <DivRootStyled>{children}</DivRootStyled>;
};
