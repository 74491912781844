import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { TranslationKeys } from 'app/translations';
import { ListRowSkeleton } from '../ListRowSkeleton';
import type { PaginationProps } from '../../Pagination';
import { Pagination } from '../../Pagination';
import { ActionButton } from '../../ActionButton';
import { SelectAllCheckboxStyled } from './ListContainer.styles';

type SelectAll = {
  areAllSelected: boolean;
  onSelectAll: () => void;
  selectedCount?: never;
  onClearAllSelected?: never;
};

type SelectedAllWithClearAllFn = Pick<SelectAll, 'onSelectAll' | 'areAllSelected'> & {
  selectedCount: number;
  onClearAllSelected: () => void;
};

type SelectAllProps = SelectAll | SelectedAllWithClearAllFn;

export type ListContainerProps = {
  children: React.ReactNode;
  containerProps?: BoxProps;
  childrenContainerStyle?: BoxProps;
  className?: string;
  hasData?: boolean;
  isLoading?: boolean;
  paginationProps?: PaginationProps;
  selectAllProps?: SelectAllProps;
};

export const ListContainer = ({
  containerProps,
  children,
  childrenContainerStyle,
  className,
  paginationProps,
  selectAllProps,
  hasData = true,
  isLoading = false,
}: ListContainerProps) => {
  const { areAllSelected = false, selectedCount = 0 } = selectAllProps || {};

  return (
    <Box className={className} {...containerProps}>
      {isLoading && <ListRowSkeleton rows={paginationProps?.rowsPerPage} />}

      {!isLoading && (
        <Box
          sx={theme => ({
            marginTop: theme.spacing(1),
            overflow: 'auto',
            outline: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
            borderRadius: '4px',
          })}
          {...childrenContainerStyle}
        >
          {hasData && (!!selectAllProps?.onSelectAll || !!selectAllProps?.onClearAllSelected) && (
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={1}
              paddingY={1}
              paddingRight={2}
            >
              {!!selectAllProps?.onSelectAll && (
                <SelectAllCheckboxStyled
                  name={'select-all'}
                  label={<FormattedMessage id={TranslationKeys.global_selectAll} />}
                  checked={areAllSelected || false}
                  onChange={selectAllProps.onSelectAll}
                  disabled={isLoading || paginationProps?.count === 0}
                />
              )}

              {!!selectAllProps?.onClearAllSelected && selectedCount > 0 && (
                <ActionButton
                  sx={{ padding: 0 }}
                  messageId={`Clear all selected (${selectedCount})`}
                  variant={'text'}
                  disabled={isLoading}
                  onClick={selectAllProps.onClearAllSelected}
                />
              )}
            </Box>
          )}

          <PerfectScrollbar>{children}</PerfectScrollbar>
        </Box>
      )}

      {!!paginationProps && (
        <Box>
          <Pagination {...paginationProps} />
        </Box>
      )}
    </Box>
  );
};
