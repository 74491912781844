import type { FuncOverrideComponent } from '../theme.types';

export const MuiTabs: FuncOverrideComponent<'MuiTabs'> = theme => ({
  defaultProps: {
    indicatorColor: 'primary',
    textColor: 'primary',
  },
  styleOverrides: {
    root: {
      minHeight: theme.spacing(3.125),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
});
