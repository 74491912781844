import { useMutation } from '@tanstack/react-query';

import type { UpdateEmployeeArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

const mutationFn = (data: UpdateEmployeeArgs) => {
  return employeeService.updateEmployee(data);
};

export const useEmployeeUpdateEmployeeMutation = () => {
  return useMutation<unknown, unknown, UpdateEmployeeArgs>(mutationFn);
};
