import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Card, CardContent } from '@mui/material';

import type {
  FilterableTableColumnType,
  FilterableTableOnSortChangeFunc,
  FilterableTablePaginationType,
  FilterableTableRowMapperFunc,
  FilterTableOnPaginationChangeFunc,
  KebabMenuItem,
} from 'app/components';
import { FilterableTable, HasAccessTo, KebabMenu } from 'app/components';
import { TranslationKeys } from 'app/translations';
import type { RateTable } from 'app/types';
import { format } from 'app/utils';
import { useLocale } from 'app/components/Intl';
import { SETTINGS } from 'app/common/Authorization/entities';
import { EDIT } from 'app/common/Authorization/permissions';

export const SETTINGS_RATE_TABLE_DATA_TEST_ID = 'settings-rate-table-component';

enum TableColumns {
  Title = 'title',
  Insurer = 'insurer',
  Product = 'product',
  Year = 'year',
  Indexation = 'indexation',
  CreatedAt = 'createdAt',
  Actions = 'col_actions',
}

type SettingsRateTableProps = {
  isLoading?: boolean;
  rateTables: RateTable[];
  onDeleteActionClick: (rateTableId: RateTable['rateTableId']) => void;
  onDownloadActionClick: (rateTable: RateTable) => void;
  pagination: FilterableTablePaginationType;
  onPaginationChange: FilterTableOnPaginationChangeFunc;
  onSortChange: FilterableTableOnSortChangeFunc;
};

export const SettingsRateTable = ({
  rateTables,
  pagination,
  onDeleteActionClick,
  onDownloadActionClick,
  onPaginationChange,
  onSortChange,
  isLoading = false,
}: SettingsRateTableProps) => {
  const { locale } = useLocale();
  const hasPermissionToEdit = HasAccessTo(SETTINGS, EDIT);

  const tableHeaders = React.useMemo<FilterableTableColumnType[]>(() => {
    return [
      {
        name: TableColumns.Title,
        title: <FormattedMessage id={TranslationKeys.global_title} />,
      },
      {
        name: TableColumns.Insurer,
        title: <FormattedMessage id={TranslationKeys.global_insurer} />,
        sortable: true,
      },
      {
        name: TableColumns.Product,
        title: <FormattedMessage id={TranslationKeys.global_product} />,
      },
      {
        name: TableColumns.Year,
        title: <FormattedMessage id={TranslationKeys.global_year} />,
        sortable: true,
      },
      {
        name: TableColumns.Indexation,
        title: <FormattedMessage id={TranslationKeys.global_indexation} />,
      },
      {
        name: TableColumns.CreatedAt,
        title: <FormattedMessage id={TranslationKeys.global_createdAt} />,
        sortable: true,
      },
      {
        name: TableColumns.Actions,
        isActionsColumn: true,
      },
    ];
  }, []);

  const buildRowActions = React.useCallback(
    (rateTable: RateTable) => {
      const actions: Array<KebabMenuItem> = [
        {
          element: <FormattedMessage id={TranslationKeys.global_download} />,
          onClick: () => onDownloadActionClick(rateTable),
        },
        {
          element: <FormattedMessage id={TranslationKeys.global_delete} />,
          onClick: () => onDeleteActionClick(rateTable.rateTableId),
          disabled: !hasPermissionToEdit,
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [hasPermissionToEdit, onDeleteActionClick, onDownloadActionClick],
  );

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<RateTable>>(
    rateTable => {
      return {
        data: {
          [TableColumns.Title]: rateTable.title,
          [TableColumns.Insurer]: rateTable.product.insurer.insurerName,
          [TableColumns.Product]: rateTable.product.productName,
          [TableColumns.Year]: rateTable.year,
          [TableColumns.Indexation]: format.percentageFormat({ value: rateTable.indexation, locale }),
          [TableColumns.CreatedAt]: format.date(rateTable.createdAt),
          [TableColumns.Actions]: buildRowActions(rateTable),
        },
      };
    },
    [buildRowActions, locale],
  );

  return (
    <Card data-testid={SETTINGS_RATE_TABLE_DATA_TEST_ID} sx={{ borderRadius: '10px' }}>
      <CardContent sx={{ padding: 0 }}>
        <FilterableTable<RateTable>
          columns={tableHeaders}
          loading={isLoading}
          rows={rateTables}
          rowMapper={tableRowMapper}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          onSortChange={onSortChange}
        />
      </CardContent>
    </Card>
  );
};
