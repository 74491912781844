import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UseQueryWrapper } from '../../../types';

import type { GetRegulationsParams } from '../../../Domain/Regulations/Service/RegulationsService';
import RegulationsService from '../../../Domain/Regulations/Service/RegulationsService';

import { useSnakeBar } from '../../useSnakeBar';

export type SummaryOfRegulation = {
  summaryOfRegulationId: string;
  usedInCalculations: boolean;
  value: string;
  defaultValue?: string;
  year: number;
  description: string;
  defaultDescription: string;
  flagged: 0 | 1;
  groupName: string;
  fieldType: string;
  options: {
    input: {
      style?: string;
      min?: number;
      max?: number;
      minimumFractionDigits?: number;
      options?: Array<{
        element: string;
        value: string;
        default: boolean;
      }>;
    };
  };
  sort: number;
  productType: string;
};

export type GetRegulationsQueryResultData = {
  data: Array<SummaryOfRegulation>;
  totalCount: number;
};

type GetRegulationsQueryVariables = {
  productId: string | undefined;
  connectionId: string | undefined;
  ownerId: GetRegulationsParams['ownerId'];
  year: GetRegulationsParams['year'];
  productType: GetRegulationsParams['productType'];
};

const queryFn =
  ({ ownerId, productId, connectionId, year, productType }: GetRegulationsQueryVariables) =>
  async () => {
    const response = await RegulationsService.getRegulations(ownerId, {
      year,
      connection: connectionId,
      product: productId,
      productType: productType,
    });

    return response.data;
  };

const QUERY_KEY = 'getRegulations';

export const useRegulationsGetRegulationsQuery: UseQueryWrapper<
  GetRegulationsQueryResultData,
  GetRegulationsQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetRegulationsQueryResultData>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
