import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { Grid, Typography } from '@mui/material';

import { useSnakeBar } from 'app/hooks';

import { isEmpty, isNil, saveFile, startCase } from 'app/utils';
import { TranslationKeys } from 'app/translations';

import { EmployeeListFileReadException } from 'app/Domain/Employers/Components/EmployerConnectionWizard/EmployeesListing/EmployeeListFileReadException';
import { RateTableFileReadException } from 'app/Domain/Settings/Components/RateTableForm/components';
import { CompareBatchFileReadException } from 'app/Domain/Connections/Pages/CompareBatchPage/components/CompareBatchPageContent/components/CompareBatchFileUpload/utils';

import ActionButton from '../ActionButton';
import type { FileType } from '../FileUploadArea';
import FileUploadArea from '../FileUploadArea';
import IntlMessage from '../Intl/IntlMessage';
import CollapsableSection from '../CollapsableSection';

import type { ListFileInterface } from './ListFileInterface';
import { GridStyled } from './FileUploadAreaWithTemplate.styles';

type FileUploadAreaWithTemplateProps = {
  title: string;
  fileName: string;
  allowedFileTypes: Array<FileType>;
  initialFiles: Array<any>;
  onFilesChange?: (files: any) => void;
  onChangeList?: (p: { setFieldTouched: any; setFieldValue: any; value: any[] }) => void;
  loading: boolean;
  disabled?: boolean;
  disabledUpload?: boolean;
  disabledDownload?: boolean;
  showDropArea: boolean;
  initialValues: Array<any>;
  listFile: ListFileInterface;
};

const FileUploadAreaWithTemplate = ({
  title,
  fileName,
  allowedFileTypes,
  initialFiles,
  onFilesChange,
  onChangeList,
  loading,
  showDropArea,
  initialValues,
  listFile,
  disabled = false,
  disabledUpload = false,
  disabledDownload = false,
}: FileUploadAreaWithTemplateProps) => {
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const intl = useIntl();

  const downloadTemplate = React.useCallback(() => {
    const sheetName = startCase(fileName);
    const sheet = listFile.generateTemplateSheet(1000);
    const blob = listFile.sheetToCsv(sheet, { FS: ';' });
    saveFile(blob, sheetName + '.csv');
  }, [listFile, fileName]);

  const { isSubmitting, setFieldValue, setFieldTouched, values } = useFormikContext<any>() || {};
  const [uploadErrorMessage, setUploadErrorMessage] = React.useState<string | null>(null);

  const changeList = React.useCallback(
    async (files: File[]) => {
      if (values.uploadError) {
        setUploadErrorMessage(null);
        setFieldValue('uploadError', false);
      }

      onFilesChange && onFilesChange(files);
      const file = files.slice(0, 1)[0] ?? undefined;
      if (!file) {
        onChangeList && onChangeList({ value: [], setFieldValue, setFieldTouched });
        return;
      }

      try {
        const parseResult = await listFile.loadAndParse(file);
        const headerFields = (initialValues || []).map(item => item.value).filter(header => !isNil(header));
        const fieldRows = parseResult
          .map(row => {
            // map rows into objects according to the field structure
            const rowObj = headerFields.reduce((obj, field, index) => {
              obj[field.fieldName] = (row[index] ?? '') + ''; // backend expects strings
              return obj;
            }, {});

            // If the whole row is empty, or optional are the only ones that are not empty, discard the row
            const discard =
              Object.values(rowObj).filter((value, index) => {
                return !(isEmpty(value) && headerFields[index].required === false);
              }).length !== row.length;
            return discard ? null : rowObj;
          })
          .filter(r => !isNil(r));

        onChangeList && onChangeList({ value: fieldRows, setFieldValue, setFieldTouched });

        showSuccessSnakeBar({
          message: intl.formatMessage({ id: TranslationKeys.upload_listParsed }, { count: fieldRows.length }),
        });
      } catch (error: any) {
        if (
          error instanceof EmployeeListFileReadException ||
          error instanceof RateTableFileReadException ||
          error instanceof CompareBatchFileReadException
        ) {
          setUploadErrorMessage(error.message);
          setFieldValue('uploadError', true);
        } else {
          showErrorSnakeBar({ message: error?.message });
        }
      }
    },
    [
      values,
      onFilesChange,
      setFieldValue,
      onChangeList,
      setFieldTouched,
      listFile,
      initialValues,
      showSuccessSnakeBar,
      intl,
      showErrorSnakeBar,
    ],
  );

  return (
    <CollapsableSection key={1} expanded={showDropArea} header={undefined}>
      <Grid container spacing={2}>
        <GridStyled item xs={12}>
          <ActionButton
            messageId={title}
            disabled={disabled || disabledDownload || loading}
            onClick={downloadTemplate}
          />
        </GridStyled>
        <Grid item xs={12}>
          <FileUploadArea
            types={allowedFileTypes}
            onChange={changeList}
            initialFiles={initialFiles}
            disabled={disabled || disabledUpload || loading}
            showSuccessMessage={false}
            isSubmitting={isSubmitting}
          />
        </Grid>
        {values.uploadError && uploadErrorMessage !== null && (
          <Grid item xs={12}>
            <Typography color="error">
              <IntlMessage value={uploadErrorMessage} />
            </Typography>
          </Grid>
        )}
      </Grid>
    </CollapsableSection>
  );
};

export default FileUploadAreaWithTemplate;
