import { useMutation } from '@tanstack/react-query';
import type { UpdateEmployerNotesParams } from 'app/Domain/Employers/Services/EmployerService';
import EmployerService from 'app/Domain/Employers/Services/EmployerService';

type UpdateEmployerNotesData = UpdateEmployerNotesParams;

const mutationFn = (data: UpdateEmployerNotesData) => {
  return EmployerService.updateEmployerNotes(data);
};

export const useEmployerUpdateNotesMutation = () => {
  return useMutation<unknown, unknown, UpdateEmployerNotesData>(mutationFn);
};
