import React from 'react';
import { Formik } from 'formik';
import type { FormikConfig } from 'formik/dist/types';

import { useSnakeBar, useUserUpdateMutation } from '../../../../../../hooks';

import type { UseQueryRefetch, User } from '../../../../../../types';

import { UserDetailsPanelForm } from './components';

import { useRolesQuery } from '../../../../Hooks';

import type { UserDetailsPanelFormValues } from './UserDetailsPanel.types';
import { IsOwnerAdmin, IsSuperAdmin } from '../../../../../../components';
import { UserDetailsPanelFormWithOwners } from './components/UserDetailsPanelForm/UserDetailsPanelFormWithOwners';

type UserDetailsPanelProps = {
  isLoading?: boolean;
  isLoggedInUser?: boolean;
  user: User | undefined;
  refetchGetUserQuery: UseQueryRefetch;
};

export const UserDetailsPanel = ({
  user,
  refetchGetUserQuery,
  isLoggedInUser = false,
  isLoading = false,
}: UserDetailsPanelProps) => {
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: updateUserMutation, isLoading: isLoadingUserUpdateMutation } = useUserUpdateMutation();

  const [isEdit, setIsEdit] = React.useState(false);

  const { data: roles, isLoading: isLoadingRolesQuery } = useRolesQuery();

  const loading = isLoading || isLoadingRolesQuery;

  const formikInitialValues = React.useMemo<FormikConfig<UserDetailsPanelFormValues>['initialValues']>(
    () => ({
      userId: user?.userId || '',
      firstName: user?.personName.firstName || '',
      middleName: user?.personName.middleName || '',
      lastName: user?.personName.lastName || '',
      emailAddress: user?.emailAddress || '',
      ownerId: user?.ownerId || '',
      roleId: user?.roleId || '',
      language: user?.language || 'nl',
      createdAt: user?.createdAt || '',
      internal: user?.internal || false,
    }),
    [
      user?.createdAt,
      user?.emailAddress,
      user?.language,
      user?.ownerId,
      user?.personName.firstName,
      user?.personName.lastName,
      user?.personName.middleName,
      user?.roleId,
      user?.userId,
      user?.internal,
    ],
  );

  const formikOnSubmit = React.useCallback<FormikConfig<UserDetailsPanelFormValues>['onSubmit']>(
    values => {
      const method = 'updateUser';
      const { createdAt, ...otherValues } = values;

      const payload = {
        ...otherValues,
        language: otherValues.language || 'nl',
        accesses: user?.accesses || {},
      };

      updateUserMutation(payload, {
        onSuccess: () => {
          setIsEdit(false);
          refetchGetUserQuery();
          showSuccessSnakeBar({ method });
        },
        onError: (error: any) => {
          showErrorSnakeBar({ method, message: error?.message });
        },
      });
    },
    [refetchGetUserQuery, showErrorSnakeBar, showSuccessSnakeBar, updateUserMutation, user?.accesses],
  );

  if (IsSuperAdmin() || IsOwnerAdmin()) {
    return (
      <Formik<UserDetailsPanelFormValues>
        enableReinitialize
        initialValues={formikInitialValues}
        onSubmit={formikOnSubmit}
      >
        <UserDetailsPanelFormWithOwners
          isLoading={loading}
          isLoggedInUser={isLoggedInUser}
          disabledActionButtonsWhileLoading={loading || isLoadingUserUpdateMutation}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          user={user}
          roles={roles?.data || []}
        />
      </Formik>
    );
  }

  return (
    <Formik<UserDetailsPanelFormValues>
      enableReinitialize
      initialValues={formikInitialValues}
      onSubmit={formikOnSubmit}
    >
      <UserDetailsPanelForm
        isLoading={loading}
        isLoggedInUser={isLoggedInUser}
        disabledActionButtonsWhileLoading={loading || isLoadingUserUpdateMutation}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        user={user}
        roles={roles?.data || []}
      />
    </Formik>
  );
};
