import React from 'react';
import { useIntl } from 'react-intl';

import { TranslationKeys } from 'app/translations';

import { GenderStatusEnum } from 'app/types';

export const useGenderSelectItems = () => {
  const intl = useIntl();

  const genderSelectItems = React.useMemo(() => {
    return [
      {
        element: intl.formatMessage({ id: TranslationKeys.global_gender_male }),
        value: GenderStatusEnum.Male,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_gender_female }),
        value: GenderStatusEnum.Female,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_gender_other }),
        value: GenderStatusEnum.Unknown,
      },
    ];
  }, [intl]);

  const getGenderDisplayNameByValue = React.useCallback(
    (value: GenderStatusEnum) => {
      if (value === GenderStatusEnum.Undefined) {
        return intl.formatMessage({ id: TranslationKeys.global_gender_other });
      }
      return genderSelectItems.find(gender => gender.value === value)?.element;
    },
    [genderSelectItems, intl],
  );

  return React.useMemo(
    () => ({ genderSelectItems, getGenderDisplayNameByValue }),
    [genderSelectItems, getGenderDisplayNameByValue],
  );
};
