import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AccordionDetailsStyled, AccordionStyled, AccordionSummaryStyled } from './Accordion.styles';

export type AccordionProps = {
  className?: string;
  header: React.ReactNode;
  children: React.ReactNode;
  defaultExpanded?: boolean;
};

export const Accordion = ({ className, header, children, defaultExpanded }: AccordionProps) => {
  return (
    <AccordionStyled className={className} defaultExpanded={defaultExpanded}>
      <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>{header}</AccordionSummaryStyled>
      <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
    </AccordionStyled>
  );
};
