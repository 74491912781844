import { useContext, useEffect, useState } from 'react';
import { useResolvedPath } from 'react-router-dom';

import { Uuid } from '../../utils/uuid';

import { BreadcrumbDispatchContext } from './BreadcrumbProvider';
import type { Breadcrumb, BreadcrumbData } from './Breadcrumb.types';

/** Registers a breadcrumb, using the provided title, and optional additional data. */
export function useBreadcrumb(title: string, data?: BreadcrumbData): void {
  // Allocate a unique ID for this breadcrumb.
  const [id] = useState(() => Uuid.newV4());

  // Returns the path of the current component's route. This is not necessarily the same as the browser's current location, as this may be called by a component that is in a parent route.
  const { pathname, search, hash } = useResolvedPath('');

  const dispatch = useContext(BreadcrumbDispatchContext);

  // On load, "set" the breadcrumb, and re-set it when any of its contents change.
  useEffect(() => {
    const crumb: Breadcrumb = { title, data, to: { pathname: data?.customPathName || pathname, search, hash } };
    dispatch({ type: 'SET', id, crumb });
  }, [pathname, search, hash, title, data, dispatch, id]);

  // On unload, delete the breadcrumb.
  useEffect(() => {
    return () => dispatch({ type: 'DEL', id });
  }, [dispatch, id]);
}
