import React from 'react';

import { Grid } from '@mui/material';

import { PaperStyled } from './PaperBackground.styles';

type PaperBackgroundProps = {
  className?: string;
  children: React.ReactNode;
};

export const PaperBackground = ({ children, className }: PaperBackgroundProps) => {
  return (
    <Grid container>
      <PaperStyled className={className}>{children}</PaperStyled>
    </Grid>
  );
};
