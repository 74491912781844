import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { TranslationKeys } from '../../../../../../translations';

export const RESET_2FA_CODE_VIEW_INVALID_LINK_MSG_DATA_TEST_ID = 'reset-2fa-code-invalid-link-msg-component';

export const Reset2FaCodeInvalidLinkMsg = () => {
  return (
    <Box
      data-testid={RESET_2FA_CODE_VIEW_INVALID_LINK_MSG_DATA_TEST_ID}
      display={'flex'}
      flexDirection={'column'}
      gap={2}
    >
      <Typography variant="h4" color={'error'}>
        <FormattedMessage id={TranslationKeys.signIn_twofa_lost2fa_invalidLinkMessage} />
      </Typography>
      <Typography variant="h5" sx={theme => ({ color: theme.palette.primary.main })}>
        <FormattedMessage id={TranslationKeys.signIn_twofa_lost2fa_invalidLinkSolution} />
      </Typography>
    </Box>
  );
};
