import { styled } from '@mui/material/styles';
import { Snackbar, snackbarContentClasses } from '@mui/material';

export const SnackbarStyled = styled(Snackbar, {
  name: 'SnackbarStyled',
})(() => ({
  [`& .${snackbarContentClasses.root}`]: {
    backgroundColor: 'orange',
  },
}));
