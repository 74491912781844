import React from 'react';

import { TranslationKeys } from 'app/translations';

import type { EmployeeCalculationsIncome } from 'app/hooks';
import type { CalculationsCardProps } from '../CalculationsCard';
import { CalculationsCard } from '../CalculationsCard';

type EmployeePensionIncomeDetailsPanelProps = {
  loading?: boolean;
  data: EmployeeCalculationsIncome | undefined;
  titleId?: string;
};

export const EmployeePensionIncomeDetailsPanel = ({
  loading = false,
  data,
  titleId = TranslationKeys.employees_detail_income,
}: EmployeePensionIncomeDetailsPanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.employees_pension_currentWage,
        formulaId: TranslationKeys.employees_pension_copyFromInfo,
        value: {
          value: data?.currentWage,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_partTimePercentage,
        formulaId: TranslationKeys.employees_pension_copyFromInfo,
        value: {
          value: data?.partTimePercentage,
          type: 'percentage',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_startAveragePartTimePercentage,
        formulaId: TranslationKeys.employees_pension_copyFromInfo,
        value: {
          value: data?.startAveragePartTimePercentage,
          type: 'percentage',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_calculatedAveragePartTime,
        formulaId: TranslationKeys.employees_pension_calculatedFromExcel,
        value: {
          value: data?.calculatedAveragePartTime,
          type: 'percentage',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_partTimeAnnualWage,
        formulaId: TranslationKeys.employees_pension_calcFormula_partTimeAnnualWage,
        value: {
          value: data?.partTimeAnnualWage,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_fullTimeAnnualWageCurrent,
        formulaId: TranslationKeys.employees_pension_calcFormula_fullTimeAnnualWageCurrent,
        value: {
          value: data?.fullTimeAnnualWageCurrent,
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return <CalculationsCard isLoading={loading} titleId={titleId} fields={cardFields} error={data?.message} />;
};
