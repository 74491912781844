import React from 'react';

import { Box } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import type { TableSelectedState } from 'app/store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import type { MissingDetail } from 'app/types';

import { Uuid, triggerFileDownload } from 'app/utils';

import { useDashboardGetMissingDetailsQuery, useProductPensionSchemeSelectItems, useSnakeBar } from 'app/hooks';

import { ActionButton, InputFieldFactory, SearchInput, useBreadcrumb } from 'app/components';
import { MissingDetailsTable } from './components';
import connectionService from '../../../Connections/Services/ConnectionService';
import { UNPROCESSED_TABLE_FILTERS_FIELD_NAMES } from '../../Components';
import { useIntl } from 'react-intl';
import { UnprocessedTableFiltersContainerStyled } from '../../Components/UnprocessedTableFilters/UnprocessedTableFilters.styles';

export const DashboardMissingDetailsPage = () => {
  const intl = useIntl();
  const { productPensionSchemeSelectItems } = useProductPensionSchemeSelectItems();
  const dispatch = useStoreDispatch();
  const { showErrorSnakeBar } = useSnakeBar();
  const [key, setKey] = React.useState(Uuid.newV4);

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const { searchQuery, page, pageSize, filters, sortBy } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.DashboardSelector.selectMissingDetails(state.DashboardReducer),
  );

  const {
    data: missingDetails,
    isLoading: isLoadingMissingDetailsQuery,
    isFetching: isFetchingMissingDetailsQuery,
  } = useDashboardGetMissingDetailsQuery({
    variables: {
      userId,
      page,
      pageSize,
      searchQuery,
      filters,
      sortBy,
    },
    options: {
      enabled: !!userId,
    },
  });

  const isLoading = isLoadingMissingDetailsQuery || isFetchingMissingDetailsQuery;

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.DashboardAction.missingDetails.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.DashboardAction.missingDetails.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.DashboardAction.missingDetails.changePage(page));
      }
    },
    [dispatch],
  );

  const onDownloadActionClick = React.useCallback(
    async (missingDetail: MissingDetail) => {
      try {
        const response = await connectionService.downloadMissingDetails(missingDetail.connectionId);
        triggerFileDownload({
          file: response.data,
          fileName: response.headers['x-filename'],
        });
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        showErrorSnakeBar({ method: 'downloadMissingDetails', message: error });
      }
    },
    [showErrorSnakeBar],
  );

  const productPensionSchemeSelectItemsWithAllOption = React.useMemo(() => {
    return [
      {
        value: '',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...productPensionSchemeSelectItems,
    ];
  }, [intl, productPensionSchemeSelectItems]);

  const onSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(StoreActions.DashboardAction.missingDetails.search(event.target.value));
    },
    [dispatch],
  );

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme && value === '') {
        dispatch(
          StoreActions.DashboardAction.missingDetails.removeFilter(
            UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
          ),
        );
      } else {
        dispatch(StoreActions.DashboardAction.missingDetails.applyFilter(name, value));
      }
    },
    [dispatch],
  );

  const onClickResetButtonHandler = React.useCallback(() => {
    dispatch(
      StoreActions.DashboardAction.missingDetails.removeFilter(
        UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
      ),
    );
    dispatch(StoreActions.DashboardAction.missingDetails.search(''));
    setKey(Uuid.newV4());
  }, [dispatch]);

  useBreadcrumb(TranslationKeys.dashboard_category_dataProcessing, {
    customPathName: '/dashboard/data-processing',
  });
  useBreadcrumb(TranslationKeys.dashboard_subCategory_missingDetails);

  return (
    <Box marginTop={1}>
      <UnprocessedTableFiltersContainerStyled>
        <InputFieldFactory
          key={`product-pension-scheme-${key}`}
          disabled={isLoading}
          onChange={onFilterChange}
          field={{
            type: 'select',
            name: UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
            label: intl.formatMessage({ id: TranslationKeys.global_pensionScheme }),
            fullWidth: false,
            value: filters.productPensionScheme || null,
            items: productPensionSchemeSelectItemsWithAllOption,
          }}
        />

        <SearchInput
          key={`search-${key}`}
          disabled={isLoading}
          initialSearchQuery={searchQuery}
          onChange={onSearchChange}
          debounceTime={1000}
        />

        <ActionButton
          disabled={isLoading}
          messageId={TranslationKeys.global_reset}
          onClick={onClickResetButtonHandler}
          variant={'text'}
        />
      </UnprocessedTableFiltersContainerStyled>

      <MissingDetailsTable
        isLoading={isLoading}
        missingDetails={missingDetails?.data || []}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        onDownloadActionClick={onDownloadActionClick}
        pagination={{
          page,
          pageSize,
          totalCount: missingDetails?.totalCount ?? 0,
        }}
      />
    </Box>
  );
};
