import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { UseQueryWrapper } from 'app/types';
import type { GetRateTablesArgs } from 'app/Domain/Settings/Services';
import { settingsServices } from 'app/Domain/Settings/Services';

import { useSnakeBar } from '../../useSnakeBar';

export type GetRateTableTypesQueryResultData = {
  data: string[];
};

const queryFn = (variables?: GetRateTablesArgs) => async () => {
  if (variables?.ownerId) {
    const response = await settingsServices.getRateTableTypes(variables);
    return (response as AxiosResponse).data;
  }
};

const QUERY_KEY = 'getRateTableTypes';

export const useSettingsGetRateTableTypesQuery: UseQueryWrapper<
  GetRateTableTypesQueryResultData,
  GetRateTablesArgs
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['rateTableTypes'],
    };
  }, []);

  return useQuery<GetRateTableTypesQueryResultData>(
    [QUERY_KEY, ...Object.values(variables || {})],
    queryFn(variables),
    {
      select,
      onError,
      ...(options || {}),
    },
  );
};
