import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, TextField } from 'app/components/FormikField/base';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import FormikRoundCheckbox from 'app/components/FormikRoundCheckbox';
import { IsSuperAdmin } from 'app/components/Authorization';
import {
  DivActionStyles,
  FieldStyled,
  FormLabelStyled,
  GridActionStyled,
  GridHeaderStyled,
  GridPermissionsStyled,
  GridStyled,
  GridTableStyled,
  GridTitleStyled,
  LabelStyled,
  SpanFloatLeftStyled,
  SpanFloatRightStyled,
  TypographySubtitleStyled,
} from './CreateRoleForm.styles';
import { API, WIDGET, ENVIRONMENT } from '../../../../common/Authorization/entities';

const CreateRoleForm = props => {
  const { owners, resources } = props;
  const intl = useIntl();

  const { values } = useFormikContext();

  const usePrevious = value => {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  };

  const previousPermissions = usePrevious(values.permissions);
  const { setFieldValue } = useFormikContext();

  const handleCrossEntityHierarchy = useCallback(
    (entity, permission) => {
      if (resources[entity] && resources[entity].hierarchy[permission]) {
        const missingPermissions = resources[entity].hierarchy[permission].filter(role => {
          return !values.permissions.includes(role);
        });

        if (missingPermissions.length > 0) {
          return missingPermissions;
        }
      }

      return [];
    },
    [resources, values.permissions],
  );

  const handleAddedPermission = useCallback(
    (addedPermission, currentPermissions) => {
      const [role, entity, permission] = addedPermission.split('_');

      if (permission === 'VIEW') {
        const validPermissions = [
          ...currentPermissions.filter(item => !item.includes(`${role}_${entity}`)),
          ...handleCrossEntityHierarchy(entity, permission),
          addedPermission,
        ];

        setFieldValue('permissions', validPermissions);

        return;
      }

      if (permission === 'EDIT') {
        const crossEntityHierarchy = handleCrossEntityHierarchy(entity, permission).filter(role => {
          const [hierarchyRole, hierarchyEntity] = role.split('_');
          return !currentPermissions.includes(`${hierarchyRole}_${hierarchyEntity}_EDIT`);
        });

        const validPermissions = [
          ...currentPermissions.filter(item => item !== `${role}_${entity}_VIEW`),
          ...crossEntityHierarchy,
          addedPermission,
        ];

        setFieldValue('permissions', validPermissions);
      }
    },
    [setFieldValue, handleCrossEntityHierarchy],
  );

  const handleRemovedPermission = useCallback(
    (removedPermission, userPermissions) => {
      const currentPermissions = userPermissions;
      const [role, entity, permission] = removedPermission.split('_');

      if (permission === 'VIEW') {
        const dependencies = {};
        for (const entity in resources) {
          const resource = resources[entity];
          const filteredPermissions = Object.keys(resource.hierarchy).filter(permission =>
            resource.hierarchy[permission].includes(removedPermission),
          );

          if (filteredPermissions.length > 0) {
            const filteredHierarchy = {};
            filteredPermissions.forEach(permission => {
              filteredHierarchy[permission] = resource.hierarchy[permission];
            });

            dependencies[entity] = filteredHierarchy;
          }
        }

        Object.keys(dependencies)?.forEach(entity => {
          const dependency = dependencies[entity];
          if (!Object.keys(dependency).includes('VIEW')) {
            currentPermissions.push(`${role}_${entity}_VIEW`);
          }
          for (let i = currentPermissions.length - 1; i >= 0; i--) {
            if (currentPermissions[i] === `ROLE_${entity}_EDIT`) {
              currentPermissions.splice(i, 1);
            }
          }
        });

        const validPermissions = currentPermissions.filter(
          item => item === `${role}_${entity}_VIEW` || !item.includes(`${role}_${entity}`),
        );

        setFieldValue('permissions', validPermissions);
      }

      if (permission === 'EDIT') {
        const validPermissions = currentPermissions.filter(
          item => item === `${role}_${entity}_VIEW` || !item.includes(`${role}_${entity}`),
        );
        setFieldValue('permissions', validPermissions);
      }

      const defaultPermissions = [
        { view: 'ROLE_CONNECTION_VIEW', edit: 'ROLE_CONNECTION_EDIT' },
        { view: 'ROLE_EMPLOYER_VIEW', edit: 'ROLE_EMPLOYER_EDIT' },
      ];

      defaultPermissions.forEach(defaultPermission => {
        const rule1 =
          removedPermission === defaultPermission.view.toString() &&
          !currentPermissions.includes(defaultPermission.edit);
        const rule2 =
          removedPermission === defaultPermission.edit.toString() &&
          !currentPermissions.includes(defaultPermission.view);
        if (rule1 || rule2) {
          setFieldValue('permissions', [...currentPermissions, removedPermission]);
        }
      });
    },
    [resources, setFieldValue],
  );

  useEffect(() => {
    if (typeof previousPermissions === 'undefined') {
      return;
    }

    const addedPermission = values.permissions.filter(item => !previousPermissions.includes(item));

    const removedPermission = previousPermissions.filter(item => !values.permissions.includes(item));

    if (addedPermission.length > 0) {
      handleAddedPermission(addedPermission[0], values.permissions);
    }

    if (removedPermission.length > 0) {
      handleRemovedPermission(removedPermission[0], values.permissions);
    }
  }, [handleAddedPermission, handleRemovedPermission, previousPermissions, values.permissions]);

  const isDisabled = useCallback(
    value => {
      return !values.permissions?.includes(value);
    },
    [values.permissions],
  );

  const [isSuperAdmin] = useState(IsSuperAdmin());

  return (
    <GridStyled container spacing={2}>
      {isSuperAdmin && (
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <FormLabelStyled>
              <FormattedMessage id="roles.owner.define" />
            </FormLabelStyled>
          </Grid>
          <Grid sx={{ display: 'flex', justifyContent: 'end' }} item xs={6}>
            <FieldStyled color="secondary" component={Select} fullWidth name="ownerId" required variant="outlined">
              <MenuItem disabled value="''">
                <span>{intl.formatMessage({ id: 'employers.owner' }).toUpperCase()}</span>
              </MenuItem>
              {owners.map(owner => (
                <MenuItem key={owner.ownerId} value={owner.ownerId}>
                  {owner.ownerName}
                </MenuItem>
              ))}
            </FieldStyled>
          </Grid>
        </Grid>
      )}
      <GridStyled item xs={6}>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="name"
          placeholder={intl.formatMessage({ id: 'roles.name' }).toUpperCase()}
          required
          variant="outlined"
        />
      </GridStyled>
      <GridStyled item xs={6}>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="description"
          placeholder={intl.formatMessage({ id: 'roles.description' }).toUpperCase()}
          required
          variant="outlined"
        />
      </GridStyled>
      <GridHeaderStyled container>
        <GridTitleStyled xs={8} item>
          <FormattedMessage id="roles.define" />
        </GridTitleStyled>
        <GridActionStyled xs={2} item>
          <FormattedMessage id="roles.view" />
        </GridActionStyled>
        <GridActionStyled xs={2} item>
          <FormattedMessage id="roles.edit" />
        </GridActionStyled>
      </GridHeaderStyled>
      {Object.entries(resources).map(([entity, options]) => {
        return (
          <GridTableStyled container key={entity}>
            <Grid xs={8} item>
              <TypographySubtitleStyled>
                <FormattedMessage id={`roles.entities.${entity}`} />
              </TypographySubtitleStyled>
              <GridPermissionsStyled
                container
                disabled={
                  isDisabled(`ROLE_${entity}_EDIT`) && entity !== API && entity !== WIDGET && entity !== ENVIRONMENT
                }
              >
                {options.permissions
                  .filter(item => !['VIEW', 'EDIT'].includes(item))
                  .map(option => (
                    <Grid xs={12} md={12} item key={option}>
                      <DivActionStyles>
                        <div>
                          <LabelStyled>
                            <SpanFloatLeftStyled>
                              <FormattedMessage id={`roles.permissions.${option}`} />
                            </SpanFloatLeftStyled>
                            <Field
                              type="checkbox"
                              name="permissions"
                              value={`ROLE_${entity}_${option}`}
                              label={`roles.permissions.${option}`}
                              disabled={
                                isDisabled(`ROLE_${entity}_EDIT`) &&
                                entity !== API &&
                                entity !== WIDGET &&
                                entity !== ENVIRONMENT
                              }
                            />
                            <SpanFloatRightStyled />
                          </LabelStyled>
                        </div>
                      </DivActionStyles>
                    </Grid>
                  ))}
              </GridPermissionsStyled>
            </Grid>
            {options.permissions
              .filter(item => ['VIEW', 'EDIT'].includes(item))
              .map(option => (
                <Grid xs={2} item key={option}>
                  <FormikRoundCheckbox name="permissions" value={`ROLE_${entity}_${option}`} />
                </Grid>
              ))}
          </GridTableStyled>
        );
      })}
    </GridStyled>
  );
};

CreateRoleForm.propTypes = {
  initialValues: PropTypes.object,
  permissions: PropTypes.object,
};

export default CreateRoleForm;
