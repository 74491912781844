import { call, put, select, takeLatest, throttle } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import connectionService, {
  forceRunConnection,
  forceRunFromFile,
  getConnection,
  purgeConnection,
} from '../Services/ConnectionService';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS, MESSAGE_SEVERITY_WARNING } from 'app/common/constants';
import { actions as AppActions } from 'app/Domain/App/Ducks/App.duck';
import { generateActionTypes, tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { createSelector } from 'reselect';

export const actionTypes = {
  ResetData: '[Connection] Reset Connection',
  RequestData: '[Connection] Request Connection',
  FulfilledData: '[Connection] Fulfilled Connection',
  ForceRun: '[Connection] Force Run Connection',
  StartSavingNotes: '[Connection] StartSavingNotes for Connection',
  FinishedSavingNotes: '[Connection] FinishedSavingNotes for Connection',
  SetPageLoaded: '[Connection] Set page loaded',
  SetPageLoading: '[Connection] Set page loading',
  FulfilledConnection: '[Connection] Fulfilled connection',
  PurgeConnection: '[Connection] Purge connection',
  UploadFromFile: '[Connection] Upload and run from file',
  FinishedPurging: '[Connection] Finished Purging for Connection',
  UnlockRun: '[Connection] Run unlocked',
  ListedEmployeeLogs: generateActionTypes('Connection.ListedEmployeeLogs'),
  MutationTypes: generateActionTypes('Connection.MutationTypes'),
};

const initialState = {
  ...singleObjectInitialState,
  pageLoaded: false,
  connectionAccess: false,
  purgingConnection: false,
  listedEmployeeLogs: { ...tableInitialState, pageSize: null },
  mutationTypes: { ...tableInitialState, pageSize: null },
};

export const reducer = (state = initialState, action) => {
  if (action.type.startsWith('[Connection.ListedEmployeeLogs]')) {
    return {
      ...state,
      listedEmployeeLogs: tableAction(
        actionTypes.ListedEmployeeLogs,
        state.listedEmployeeLogs,
        action,
        initialState.listedEmployeeLogs,
      ),
    };
  }

  if (action.type.startsWith('[Connection.MutationTypes]')) {
    return {
      ...state,
      mutationTypes: tableAction(actionTypes.MutationTypes, state.mutationTypes, action, initialState.tmuationTypes),
    };
  }

  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.ChangeNotes:
      return { ...newState, item: { ...newState.item, notes: action.payload } };

    case actionTypes.StartSavingNotes:
      return { ...newState, savingNotes: true };

    case actionTypes.FinishedSavingNotes:
      return { ...newState, savingNotes: false };

    case actionTypes.SetPageLoaded: {
      return {
        ...newState,
        pageLoaded: true,
      };
    }

    case actionTypes.SetPageLoading: {
      return {
        ...newState,
        loading: action.loading,
      };
    }

    case actionTypes.FulfilledData: {
      return {
        ...newState,
        connectionAccess: true,
      };
    }
    case actionTypes.PurgeConnection: {
      return {
        ...state,
      };
    }
    case actionTypes.FinishedPurging: {
      return {
        ...newState,
        purgingConnection: false,
      };
    }
    case actionTypes.UnlockRun: {
      return {
        ...newState,
        runIsLocked: false,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  forceRun: payload => ({
    payload,
    type: actionTypes.ForceRun,
  }),
  setPageLoaded: () => ({
    type: actionTypes.SetPageLoaded,
  }),
  setPageLoading: loading => ({
    type: actionTypes.SetPageLoading,
    loading,
  }),
  startSavingNotes: () => ({
    type: actionTypes.StartSavingNotes,
  }),
  finishedSavingNotes: () => ({
    type: actionTypes.FinishedSavingNotes,
  }),
  purgeConnection: connectionId => ({
    connectionId,
    type: actionTypes.PurgeConnection,
  }),
  uploadFromFile: payload => ({
    payload,
    type: actionTypes.UploadFromFile,
  }),
  finishedPurging: () => ({
    type: actionTypes.FinishedPurging,
  }),
  unlockRun: () => ({
    type: actionTypes.UnlockRun,
  }),
  listedEmployeeLogs: tableActions(actionTypes.ListedEmployeeLogs),
  mutationTypes: tableActions(actionTypes.MutationTypes),
};

export const selectors = {
  selectListedEmployeeLogs: createSelector(
    state => state.listedEmployeeLogs,
    owner => owner,
  ),
  mutationTypes: createSelector(
    state => state.mutationTypes,
    connectionId => connectionId,
  ),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestConnectionSaga(action) {
    try {
      const response = yield getConnection(action.payload);
      yield put(actions.setPageLoaded());
      if (response) {
        yield put(actions.fulfilledData(response.data));
      }
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getConnection',
        severity,
      };

      yield put(actions.setPageLoading(false));
      yield put(AppActions.displayMessage(details));
    }
  });

  yield throttle(500, actionTypes.ForceRun, function* forceRun(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;

    const currentState = yield select(state => {
      return state.ConnectionReducer;
    });
    currentState.runIsLocked = true;

    try {
      yield forceRunConnection(action.payload).then(response => {
        if (response.data.isRunInProgress) {
          severity = MESSAGE_SEVERITY_WARNING;
        }
      });
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    currentState.runIsLocked = false;
    const details = {
      method: 'forceRun',
      severity,
    };
    yield put(AppActions.displayMessage(details));
    yield put(actions.unlockRun());
  });

  function* uploadFromFile(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield forceRunFromFile(action.payload);
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    const details = {
      method: 'uploadFromFile',
      severity,
    };
    yield put(AppActions.displayMessage(details));
  }

  yield takeLatest(actionTypes.PurgeConnection, function* purgeConnectionSaga(action) {
    const currentState = yield select(state => {
      return state.ConnectionReducer;
    });
    currentState.purgingConnection = true;

    try {
      yield purgeConnection(action.connectionId);

      const severity = MESSAGE_SEVERITY_SUCCESS;
      const details = {
        method: 'purgeConnection',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'purgeConnection',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
    yield put(actions.finishedPurging());
  });

  yield takeLatest(actionTypes.UploadFromFile, uploadFromFile);

  yield takeLatest(actionTypes.ListedEmployeeLogs.RequestData, function* getListedEmployeeLogsSaga({ payload }) {
    try {
      const { connectionId } = payload;
      const response = yield call(connectionService.getListedEmployeeLogs, connectionId);
      yield put(actions.listedEmployeeLogs.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      yield put(actions.listedEmployeeLogs.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getListedEmployeeLogs',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  });

  yield takeLatest(actionTypes.MutationTypes.RequestData, function* getMutationTypesSaga({ payload }) {
    try {
      const { connectionId } = payload;
      const response = yield call(connectionService.getMutationTypes, connectionId);
      yield put(actions.mutationTypes.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      yield put(actions.mutationTypes.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getMutationTypes',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  });
}
