import { styled } from '@mui/material/styles';

export const DashboardSubCategoryBodyStyled = styled('div', {
  name: 'DashboardSubCategoryBodyStyled',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const DashboardSubCategoryDividerStyled = styled('div', {
  name: 'DashboardSubCategoryDividerStyles',
})(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.light}`,
  height: theme.spacing(4),
}));
