import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import { FieldArray, useFormikContext } from 'formik';
import { TextField } from 'app/components/FormikField/base';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import { Uuid } from '../../../../utils/uuid';

import {
  DividerStyled,
  FieldStyled,
  FontAwesomeIconStyled,
  FormLabelStyled,
  GridItemStyled,
  GridStyled,
  LinkAddStyled,
  LinkDeleteStyled,
} from './EditInsurerOwnerForm.styles';

const EditInsurerOwnerForm = ({ ownerInsurerId, intl }) => {
  const { initialValues } = useFormikContext();

  const [values, setValues] = React.useState(initialValues || {});

  // effects
  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  // events
  const handleDeleteContact = contactIndex => () => {
    const tempContacts = values.contacts.filter((_, index) => index !== contactIndex);
    values.contacts = tempContacts;
    setValues({ ...values, contacts: tempContacts });
  };

  // template
  return (
    <Box sx={{ width: '100%' }}>
      <GridStyled container spacing={2}>
        <GridItemStyled item xs={4}>
          <FormLabelStyled>
            <FormattedMessage id="insurers.agencyNumber" />
          </FormLabelStyled>
          <FieldStyled
            color="secondary"
            component={TextField}
            fullWidth
            name="agencyNumber"
            placeholder={intl.formatMessage({ id: 'insurers.agencyNumber' }).toUpperCase()}
            required
            variant="outlined"
          />
        </GridItemStyled>
      </GridStyled>

      <DividerStyled />

      <FieldArray
        name="contacts"
        render={() => (
          <div>
            {!!values.contacts?.length &&
              values.contacts.map((contact, contactIndex) => (
                <div key={contactIndex}>
                  <GridStyled container spacing={2}>
                    <GridItemStyled item xs={4}>
                      <FormLabelStyled>
                        <FormattedMessage id="insurers.contactName" />
                      </FormLabelStyled>
                      <FieldStyled
                        color="secondary"
                        component={TextField}
                        fullWidth
                        name={`contacts[${contactIndex}].name`}
                        placeholder={intl.formatMessage({ id: 'insurers.contactName' }).toUpperCase()}
                        variant="outlined"
                      />
                    </GridItemStyled>

                    <GridItemStyled item xs={4}>
                      <FormLabelStyled>
                        <FormattedMessage id="insurers.phone" />
                      </FormLabelStyled>
                      <FieldStyled
                        color="secondary"
                        component={TextField}
                        fullWidth
                        name={`contacts[${contactIndex}].phone`}
                        placeholder={intl.formatMessage({ id: 'insurers.phone' }).toUpperCase()}
                        variant="outlined"
                      />
                    </GridItemStyled>

                    <GridItemStyled item xs={4}>
                      <FormLabelStyled>
                        <FormattedMessage id="insurers.email" />

                        <LinkDeleteStyled
                          href="#"
                          tabIndex="-1"
                          variant="body1"
                          onClick={handleDeleteContact(contactIndex, contact)}
                        >
                          <FontAwesomeIconStyled icon={faTrash} />
                          <FormattedMessage id="contacts.delete" />
                        </LinkDeleteStyled>
                      </FormLabelStyled>
                      <FieldStyled
                        color="secondary"
                        component={TextField}
                        fullWidth
                        name={`contacts[${contactIndex}].email`}
                        placeholder={intl.formatMessage({ id: 'insurers.email' }).toUpperCase()}
                        variant="outlined"
                      />
                    </GridItemStyled>
                  </GridStyled>

                  <DividerStyled />
                </div>
              ))}

            {values.contacts?.length < 5 && (
              <LinkAddStyled
                disabled={values.contacts?.length >= 5}
                href="#"
                tabIndex="-1"
                variant="body1"
                onClick={() =>
                  values.contacts.push({
                    contactId: Uuid.newV4(),
                    name: '',
                    phone: '',
                    email: '',
                    ownerInsurerId: ownerInsurerId,
                  })
                }
              >
                <FontAwesomeIconStyled icon={faPlus} />
                <FormattedMessage id="contacts.add" />
              </LinkAddStyled>
            )}
            {values.contacts?.length >= 5 && (
              <Typography variant="body1" textAlign={'center'}>
                <FormattedMessage id="contacts.maximumReached" values={{ b: chunks => <b>{chunks}</b>, br: <br /> }} />
              </Typography>
            )}
          </div>
        )}
      />
    </Box>
  );
};

EditInsurerOwnerForm.propTypes = {
  initialValues: PropTypes.object,
};

export default injectIntl(EditInsurerOwnerForm);
