import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

import type { SecurityInputProps } from '../../../../../../components';
import { SecurityInput } from '../../../../../../components';

import { TranslationKeys } from '../../../../../../translations';

import {
  FormLabelStyled,
  GridItemPhoneStyled,
  GridItemQRStyled,
  ImgQRStyled,
  PaperStyled,
} from './SignInViewTwoFactorAuthSetupSteps.styles';

export const SIGN_IN_VIEW_2FA_SETUP_STEPS_DATA_TEST_ID = 'sign-in-view-2fa-setup-steps-component';

type SignInViewTwoFactorAuthSetupStepsProps = {
  onCodeComplete: SecurityInputProps['onCodeComplete'];
  twoFaFailed: boolean;
  qrCodeImgSrc: string;
};

export const SignInViewTwoFactorAuthSetupSteps = ({
  twoFaFailed,
  qrCodeImgSrc,
  onCodeComplete,
}: SignInViewTwoFactorAuthSetupStepsProps) => {
  return (
    <Box data-testid={SIGN_IN_VIEW_2FA_SETUP_STEPS_DATA_TEST_ID} display={'flex'} flexDirection={'column'} gap={3}>
      {twoFaFailed && (
        <Alert severity="error">
          <FormattedMessage id={TranslationKeys.signIn_twofa_verify_failed} />
        </Alert>
      )}

      <Box>
        <Typography variant="h5">
          <FormattedMessage id={TranslationKeys.signIn_twofa_step1_title} />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id={TranslationKeys.signIn_twofa_step1_description} />
        </Typography>
      </Box>

      <Box>
        <Typography variant="h5">
          <FormattedMessage id={TranslationKeys.signIn_twofa_step2_title} />
        </Typography>

        <PaperStyled elevation={0}>
          <Grid container spacing={0}>
            <GridItemPhoneStyled item xs={4}>
              <img src="/images/2fa/phone.png" alt={'phone'} />
            </GridItemPhoneStyled>
            <GridItemQRStyled item xs={4}>
              <FormLabelStyled>
                <FormattedMessage id={TranslationKeys.signIn_twofa_scanCode} />
              </FormLabelStyled>

              <ImgQRStyled src={qrCodeImgSrc} alt={'qr-code'} />
            </GridItemQRStyled>
            <Grid sx={{ marginTop: '85px' }} item xs={4}>
              <img src="/images/2fa/authenticators.png" alt={'authenticator'} />
            </Grid>
          </Grid>
        </PaperStyled>
      </Box>

      <Box>
        <Typography variant="h5">
          <FormattedMessage id={TranslationKeys.signIn_twofa_step3_title} />
        </Typography>

        <SecurityInput onCodeComplete={onCodeComplete}></SecurityInput>
      </Box>
    </Box>
  );
};
