import React, { memo } from 'react';
import FormikWrapper from '../FormikWrapper';
import InternalSelect from './InternalSelect';
import type { InputFieldFactoryProps } from '../Factory/InputFieldFactory';
import type { useFormikContext, FormikHelpers } from 'formik';

export type SelectProps<TValue = any> = Pick<InputFieldFactoryProps, 'className' | 'field' | 'hideErrorText'> & {
  onChange?: (args: {
    event: React.ChangeEvent<HTMLInputElement>;
    name: string;
    child: unknown;
    value: TValue;
    setFieldValue: FormikHelpers<unknown>['setFieldValue'];
  }) => void;
  variant?: 'default' | 'slim' | string;
};

const Select = (props: SelectProps) => {
  return (
    <FormikWrapper>
      {(formik: ReturnType<typeof useFormikContext>) => <InternalSelect formik={formik} {...props} />}
    </FormikWrapper>
  );
};

export default memo(Select);
