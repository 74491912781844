import React from 'react';
import { Box, Typography } from '@mui/material';
import { ActionLink } from 'app/components/ActionLink';
import { FormattedMessage } from 'react-intl';

export type CustomBreadcrumbItem = {
  title: string | React.ReactNode;
  href?: string;
};

export type CustomBreadcrumbProps = {
  items: CustomBreadcrumbItem[];
};

export const CustomBreadcrumb = ({ items }: CustomBreadcrumbProps) => {
  return (
    <Box display={'flex'} alignItems={'center'} gap={2}>
      {items.map((item, idx) => {
        const variant = idx === 0 ? 'h5' : 'body2';

        if (item.href) {
          return (
            <ActionLink key={idx} to={item.href} underline={'none'}>
              <Typography variant={variant}>
                {typeof item.title === 'string' ? <FormattedMessage id={item.title} /> : item.title}
              </Typography>
            </ActionLink>
          );
        }

        return (
          <Typography key={idx} variant={variant}>
            {typeof item.title === 'string' ? <FormattedMessage id={item.title} /> : item.title}
          </Typography>
        );
      })}
    </Box>
  );
};
