import React, { createContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from '../../utils/jwtDecode';
import { actions as AppActions, selectors } from '../../Domain/App/Ducks/App.duck';

export const AuthorizationContext = createContext({ permissions: null, roles: null });

export const AuthorizationProvider = ({ children }) => {
  const token = useSelector(state => selectors.selectAuthToken(state.AppReducer));

  const dispatch = useDispatch();

  const { permissions, roles } = useMemo(() => {
    if (token == null) {
      return {};
    }
    const result = jwtDecode(token);
    return {
      permissions: result?.permissions || null,
      roles: result?.roles || null,
    };
  }, [token]);

  if (token !== null && (permissions == null || roles == null)) {
    dispatch(AppActions.logout());
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  return <AuthorizationContext.Provider value={{ permissions, roles }}>{children}</AuthorizationContext.Provider>;
};
