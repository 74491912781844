import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  generateActionTypes,
  tableAction,
  tableActions,
  tableInitialState,
  tableSagas,
} from 'app/Domain/App/Ducks/Table.duck';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { createSelector } from 'reselect';
import { normalizeError } from 'app/utils';
import regulationsService from '../Service/RegulationsService';
import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload } from '../../../utils/convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload';

export const actionTypes = {
  Regulations: generateActionTypes('Regulations', {
    Edit: 'Edit',
  }),
  Years: generateActionTypes('Regulations.Years', {
    SetOwnerId: 'Set OwnerId',
  }),
};

const initialState = {
  years: { ...tableInitialState, pageSize: 100, ownerId: null },
  regulations: { ...tableInitialState },
};

const additionalYearsMutators = {};
additionalYearsMutators[`${actionTypes.Years.SetOwnerId}`] = (state, action) => {
  return {
    ...state,
    ownerId: action.payload,
  };
};

export const reducer = (state = initialState, action) => {
  if (action.type.startsWith('[Regulations]')) {
    return {
      ...state,
      regulations: tableAction(actionTypes.Regulations, state.regulations, action, initialState.regulations),
    };
  } else if (action.type.startsWith('[Regulations.Years]')) {
    return {
      ...state,
      years: tableAction(actionTypes.Years, state.years, action, initialState.years, additionalYearsMutators),
    };
  }
  return state;
};

export const actions = {
  regulations: tableActions(actionTypes.Regulations, {
    edit: payload => ({
      type: actionTypes.Regulations.Edit,
      payload,
    }),
  }),
  years: tableActions(actionTypes.Years, {
    setOwnerId: (payload = {}) => ({
      type: actionTypes.Years.SetOwnerId,
      payload,
    }),
  }),
};

export const selectors = {
  selectRegulations: createSelector(
    state => state.regulations,
    regulations => regulations,
  ),
  selectYears: createSelector(
    state => state.years,
    years => years,
  ),
};

export function* saga() {
  const requestYearsWorker = function* getYearsSaga({ payload }) {
    const { ownerId, page, pageSize } = yield select(state => selectors.selectYears(state.RegulationsReducer));
    const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload({ page, pageSize });
    const { onError, onSuccess } = payload || {};
    try {
      yield put(actions.years.requestData());
      const response = yield call(regulationsService.getRegulationsYears, ownerId, pagination);
      yield put(actions.years.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.years.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getRegulationsYears',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  };

  yield takeLatest(actionTypes.Years.SetOwnerId, requestYearsWorker);
  yield* tableSagas(actionTypes.Years, requestYearsWorker);

  yield takeLatest(actionTypes.Regulations.RequestData, function* getRegulationsSaga({ payload }) {
    const { employerId, ownerId, year, productId, onError, onSuccess } = payload;
    try {
      const response = yield call(regulationsService.getRegulations, ownerId, {
        year,
        product: productId,
        employer: employerId,
      });
      yield put(actions.regulations.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.regulations.error(error));
      if (!errorData.violations) {
        yield put(
          AppActions.displayMessage({
            method: 'getInsurerRegulations',
            severity: MESSAGE_SEVERITY_ERROR,
          }),
        );
      }
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.Regulations.Edit, function* editRegulationsSaga({ payload }) {
    const { ownerId, productId, year, employerId, values, onError, onSuccess } = payload;
    try {
      const response = yield call(regulationsService.editRegulations, {
        ownerId,
        productId,
        employerId,
        year,
        values,
      });
      yield put(
        actions.regulations.requestData({
          ownerId,
          productId,
          year,
          employerId,
        }),
      );
      yield put(
        AppActions.displayMessage({
          method: 'editRegulations',
          severity: MESSAGE_SEVERITY_SUCCESS,
        }),
      );
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.regulations.error(error));
      if (!errorData.violations) {
        yield put(
          AppActions.displayMessage({
            method: 'editInsurerRegulations',
            severity: MESSAGE_SEVERITY_ERROR,
          }),
        );
      }
      yield onError && onError(errorData);
    }
  });
}
