import type { Theme } from '@mui/material/styles';
import { tabClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiTab: FuncOverrideComponent<'MuiTab'> = (theme: Theme) => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.overline.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: 0,
      minHeight: 20,
      lineHeight: 'initial',
      [theme.breakpoints.up('sm')]: {
        minWidth: 120,
      },
    },
    textColorPrimary: {
      [`&.${tabClasses.selected}`]: {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
});
