import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createSelector } from 'reselect';
import { MESSAGE_SEVERITY_ERROR, SUPER_ADMIN_OWNER_ID } from 'app/common/constants';

export const actionTypes = {
  LoginUser: '[App] Login',
  RefreshToken: '[App] Refresh token',
  LogoutUser: '[App] Logout',
  SetUser: '[App] Set User',
  SetEnvironment: '[App] Set Environment',
  SetLanguage: '[App] Set Language',
  SetInitialPath: '[App] Set initial path',
  RequestVatCodes: '[App] request vat codes',
  FulfilledVatCodes: '[App] fulfilled vat codes',
  SetLoading: '[App] Set loading',
  SetPlatformSettings: '[App] Set Platform settings',
  DisplayMessage: '[App] Display message',
  DeleteMessage: '[App] Delete message',
  DisplaySnackBar: '[App] Display Snack Bar',
};

export const appDuckInitialState = {
  initialPath: '/',
  loggedIn: false,
  user: {},
  authToken: null,
  refreshToken: null,
  language: 'nl',
  vatCodes: [],
  loading: false,
  environment: {
    ownerId: SUPER_ADMIN_OWNER_ID,
  },
  platformSettings: {
    themeSettings: {
      black: '#000000',
      white: '#FFFFFF',
      primary: {
        contrastText: '#E4F4F9',
        dark: '#002C72',
        main: '#00A0FF',
        light: '#E4F4F9',
      },
      secondary: {
        contrastText: '#FFFFFF',
        dark: '#8BADBF',
        main: '#C5D9E2',
        light: '#E4F4F9',
      },
      success: {
        contrastText: '#FFFFFF',
        dark: '#40E4A1',
        main: '#40E4A1',
        light: '#C5F6E2',
      },
      info: {
        contrastText: '#FFFFFF',
        dark: '#0d47a1',
        main: '#1e88e5',
        light: '#42a5f5',
      },
      warning: {
        contrastText: '#FFFFFF',
        dark: '#F3AE43',
        main: '#F3AE43',
        light: '#FBE6C6',
      },
      error: {
        contrastText: '#FFFFFF',
        dark: '#DF7070',
        main: '#DF7070',
        light: '#F5D4D4',
      },
      text: {
        primary: '#002C72',
        secondary: '#546e7a',
        link: '#1e88e5',
      },
      background: {
        default: '#FFFFFF',
        paper: '#FFFFFF',
      },
      icon: '#546e7a',
      divider: '#eeeeee',
    },
    platformName: 'InsuranceBrydge',
  },
  showSnackBar: false,
  notification: null,
};

export const reducer = persistReducer({ storage, key: 'auth' }, (state = appDuckInitialState, action) => {
  switch (action.type) {
    case actionTypes.LoginUser: {
      return {
        ...state,
        authToken: action.payload.authToken,
        refreshToken: action.payload.refreshToken,
        mercureToken: action.payload.mercureToken,
        user: action.payload.user,
        loggedIn: true,
        language: action.payload.user.language,
        environment: { ownerId: action.payload.user.ownerId ?? SUPER_ADMIN_OWNER_ID },
      };
    }

    case actionTypes.RefreshToken: {
      return {
        ...state,
        authToken: action.payload.authToken,
      };
    }

    case actionTypes.SetLanguage: {
      return {
        ...state,
        language: action.payload.language,
      };
    }
    case actionTypes.SetPlatformSettings: {
      return {
        ...state,
        platformSettings: action.payload.platformSettings,
      };
    }

    case actionTypes.SetUser: {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case actionTypes.SetEnvironment: {
      return {
        ...state,
        environment: action.payload.environment,
      };
    }

    case actionTypes.SetInitialPath: {
      return {
        ...state,
        initialPath: action.payload.initialPath,
      };
    }

    case actionTypes.FulfilledVatCodes: {
      return {
        ...state,
        vatCodes: action.payload,
      };
    }

    case actionTypes.LogoutUser: {
      //prevent Freshdesk widget to be displayed after logout (login screen)
      document.getElementById('freshworks-container')?.remove();
      return {
        ...state,
        authToken: null,
        refreshToken: null,
        user: {},
        loggedIn: false,
      };
    }

    case actionTypes.SetLoading: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case actionTypes.DisplayMessage: {
      return {
        ...state,
        notification: action.payload,
      };
    }

    case actionTypes.DisplaySnackBar: {
      return {
        ...state,
        showSnackBar: action.showSnackBar,
      };
    }

    case actionTypes.DeleteMessage: {
      return {
        ...state,
        notification: null,
      };
    }

    default:
      return state;
  }
});

export const actions = {
  login: (authToken, refreshToken, mercureToken, user) => {
    return {
      type: actionTypes.LoginUser,
      payload: {
        authToken,
        refreshToken,
        mercureToken,
        user,
      },
    };
  },
  refreshToken: authToken => {
    return {
      type: actionTypes.RefreshToken,
      payload: {
        authToken,
      },
    };
  },
  setUser: user => {
    return {
      type: actionTypes.SetUser,
      payload: {
        user,
      },
    };
  },
  setEnvironment: environment => {
    return {
      type: actionTypes.SetEnvironment,
      payload: {
        environment,
      },
    };
  },
  setPlatformSettings: platformSettings => {
    return {
      type: actionTypes.SetPlatformSettings,
      payload: {
        platformSettings,
      },
    };
  },
  setInitialPath: initialPath => {
    return {
      type: actionTypes.SetInitialPath,
      payload: {
        initialPath,
      },
    };
  },
  setLanguage: language => {
    return {
      type: actionTypes.SetLanguage,
      payload: {
        language,
      },
    };
  },
  logout: () => ({
    type: actionTypes.LogoutUser,
  }),
  setLoading: loading => {
    return {
      type: actionTypes.SetLoading,
      loading,
    };
  },
  displayError: payload =>
    actions.displayMessage({
      ...payload,
      severity: MESSAGE_SEVERITY_ERROR,
    }),
  displayMessage: payload => ({
    type: actionTypes.DisplayMessage,
    payload,
  }),
  deleteMessage: () => ({
    type: actionTypes.DeleteMessage,
  }),
  displaySnackBar: showSnackBar => {
    return {
      type: actionTypes.DisplaySnackBar,
      showSnackBar,
    };
  },
};

export const selectors = {
  selectAuthToken: createSelector(
    state => state.authToken,
    authToken => authToken,
  ),
  selectNotification: createSelector(
    state => state.notification,
    notification => notification,
  ),
  selectThemeSettings: createSelector(
    state => state.platformSettings,
    platformSettings => platformSettings?.themeSettings,
  ),
  selectThemeSettingsLogo: createSelector(
    state => state.platformSettings,
    platformSettings => platformSettings?.logoUrl,
  ),
  selectOwnerId: createSelector(
    state => state.user,
    user => user?.ownerId,
  ),
  selectUserId: createSelector(
    state => state.user,
    user => user?.userId,
  ),
  selectOwner: createSelector(
    state => state.user,
    user => user,
  ),
};
