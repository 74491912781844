import React from 'react';
import { useIntl } from 'react-intl';
import { Form, useFormikContext } from 'formik';

import { Alert, Box } from '@mui/material';

import { TranslationKeys } from '../../../../translations';

import { ActionButton, InputFieldFactory } from '../../../../components';

import type { Forgot2FaCodeViewFormValues } from '../../Forgot2FaCodeView.types';
import { FORGOT_2FA_CODE_VIEW_FORM_FIELDS } from '../../Forgot2FaCodeView.types';

export const FORGOT_2FA_CODE_VIEW_FORM_DATA_TEST_ID = 'forgot-2fa-code-view-form-component';

export const Forgot2FaCodeViewForm = () => {
  const intl = useIntl();

  const { submitForm, status, isValid, isSubmitting } = useFormikContext<Forgot2FaCodeViewFormValues>();

  return (
    <Form data-testid={FORGOT_2FA_CODE_VIEW_FORM_DATA_TEST_ID}>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        {status && <Alert severity="error">{status}</Alert>}

        <InputFieldFactory
          field={{
            required: true,
            type: 'email',
            name: FORGOT_2FA_CODE_VIEW_FORM_FIELDS.Email,
            header: intl.formatMessage({ id: TranslationKeys.global_email }),
          }}
        />

        <Box>
          <ActionButton
            messageId={TranslationKeys.signIn_twofa_lost2fa_button_reset}
            disabled={!isValid || isSubmitting}
            type={'submit'}
            onClick={submitForm}
          />
        </Box>
      </Box>
    </Form>
  );
};
