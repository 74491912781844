import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { TranslationKeys } from '../../../../../translations';

import type { GenerateReportData } from '../../../../../hooks';

import { ActionButton, Dialog } from '../../../../../components';

import { ReportNoData } from '../ReportNoData';

import {
  AccordionStyled,
  InnerListStyled,
  ListItemStyled,
  ListStyled,
  TypographyHeaderStyled,
  TypographySelectedLengthStyled,
} from './ReportGenerationConfirmDialog.styles';

type ReportGenerationConfirmDialogProps = {
  open: boolean;
  isReportWithConnections: boolean;
  reportData: GenerateReportData['employers'];
  reportType: string;
  onClose: () => void;
  onConfirm: () => void;
};

export const ReportGenerationConfirmDialog = ({
  open,
  isReportWithConnections,
  reportData,
  onClose,
  onConfirm,
}: ReportGenerationConfirmDialogProps) => {
  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton messageId={TranslationKeys.no} onClick={onClose} variant={'outlined'} />
        <ActionButton messageId={TranslationKeys.yes} onClick={onConfirm} />
      </>
    );
  }, [onClose, onConfirm]);

  const SelectedEmployers = React.useMemo(() => {
    if (!isReportWithConnections) {
      return (
        <ListStyled>
          {reportData.map(employer => (
            <ListItemStyled key={employer.employerId}>
              <Typography>{employer.name}</Typography>
            </ListItemStyled>
          ))}
        </ListStyled>
      );
    }

    return reportData.map(employer => {
      const hasConnections = employer.connections.length > 0;

      return (
        <AccordionStyled
          key={employer.employerId}
          header={
            <TypographySelectedLengthStyled>
              {employer.name}
              <span>{`(${employer.connections.length})`}</span>
            </TypographySelectedLengthStyled>
          }
        >
          {hasConnections && (
            <InnerListStyled>
              {employer.connections.map(connection => (
                <ListItemStyled key={`${employer.employerId}_${connection.connectionId}`}>
                  <Typography key={connection.connectionId}>{connection.name}</Typography>
                </ListItemStyled>
              ))}
            </InnerListStyled>
          )}

          {!hasConnections && <ReportNoData innerRow />}
        </AccordionStyled>
      );
    });
  }, [reportData, isReportWithConnections]);

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      title={<FormattedMessage id={TranslationKeys.reports_confirmationDialog_title} />}
      onClose={onClose}
      actions={DialogActions}
      fullWidth
    >
      <>
        <TypographyHeaderStyled>
          <FormattedMessage id={TranslationKeys.reports_confirmationDialog_header} />
        </TypographyHeaderStyled>

        <Typography>
          <FormattedMessage id={TranslationKeys.reports_confirmationDialog_confirmationText} />
        </Typography>

        <TypographySelectedLengthStyled withMarginTop>
          <FormattedMessage id={TranslationKeys.reports_confirmationDialog_selectedLength} />
          <span>{reportData.length}</span>
        </TypographySelectedLengthStyled>

        {SelectedEmployers}
      </>
    </Dialog>
  );
};
