import axios from 'axios';
import { merge } from 'app/utils';

let getEventsToken;

export const getEventsForBatch = async (
  connectionId,
  batchMongoId,
  page,
  perPage,
  sortBy,
  filters,
  searchQuery,
  useToken,
) => {
  try {
    if (useToken && typeof getEventsToken !== typeof undefined) {
      getEventsToken.cancel('Operation canceled due to new request.');
    }

    if (useToken) {
      getEventsToken = axios.CancelToken.source();
    }

    return axios.get(`/connections/${connectionId}/batches/${batchMongoId}/events`, {
      cancelToken: useToken ? getEventsToken.token : null,
      params: merge(
        {
          page,
          perPage,
          sortBy,
          employee: searchQuery,
        },
        filters,
      ),
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const processEventsService = { getEventsForBatch };

export default processEventsService;
