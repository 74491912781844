import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { defaultLocale, enabledLocales } from './locales';
import { useSelector } from 'react-redux';
import { setLocale } from 'yup';

const Context = React.createContext({
  ...enabledLocales[defaultLocale],
});

/**
 * @type LocaleOption
 */
const locale = { ...enabledLocales[defaultLocale] };

const IntlWrapper = props => {
  const { user } = useSelector(state => state.AppReducer);
  const localeObj = enabledLocales[user.language] ?? enabledLocales[defaultLocale];

  locale.name = localeObj.name;
  locale.messages = localeObj.messages;
  locale.validationMessages = localeObj.validationMessages;
  locale.locale = localeObj.locale;

  useEffect(() => {
    setLocale(localeObj.validationMessages);
  }, [localeObj]);

  return (
    <Context.Provider value={localeObj}>
      <IntlProvider messages={localeObj.messages} locale={localeObj.locale.code}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlWrapper, Context, locale };
