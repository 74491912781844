import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { RateTable, UseQueryWrapper } from 'app/types';
import { useSnakeBar } from 'app/hooks/useSnakeBar';
import type { GetRateTableInterpolationArgs } from 'app/Domain/Settings/Services';
import { settingsServices } from 'app/Domain/Settings/Services';

type GetRateTableInterpolationQueryVariables = GetRateTableInterpolationArgs;

export type GetRateTableInterpolationQueryResultData = RateTable;

const queryFn = (variables: GetRateTableInterpolationQueryVariables) => async () => {
  const response = await settingsServices.getRateTableInterpolation(variables);
  return (response as AxiosResponse).data;
};

const QUERY_KEY = 'getUser';

export const useSettingsGetRateTableInterpolationQuery: UseQueryWrapper<
  GetRateTableInterpolationQueryResultData,
  GetRateTableInterpolationQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetRateTableInterpolationQueryResultData>([QUERY_KEY, variables], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
