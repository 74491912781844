import type { Theme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

export const commonTableHoverEffectStyles = (theme: Theme) => ({
  '&:nth-of-type(2n)': {
    backgroundColor: theme.palette.common.white,
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6FBFD',
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.light, 0.7),
  },
});
