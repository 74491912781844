import { useMutation } from '@tanstack/react-query';

import type { DeleteEmployerFileParams } from 'app/Domain/Employers/Services/EmployerService/employerService';
import { employerService } from 'app/Domain/Employers/Services/EmployerService/employerService';

type DeleteEmployerFileData = DeleteEmployerFileParams;

const mutationFn = (data: DeleteEmployerFileData) => {
  return employerService.deleteEmployerFile(data);
};

export const useEmployerFileDeleteMutation = () => {
  return useMutation<unknown, unknown, DeleteEmployerFileData>(mutationFn);
};
