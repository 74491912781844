import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import { ThemeProvider } from '@mui/material';

import theme from './theme';
import palette from './theme/palette';

import { setupAxios } from './utils';

import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from './store';
import store, { persistor } from './store/store';

import { MESSAGE_SEVERITY_ERROR } from './common';

import {
  AuthorizationProvider,
  BreadcrumbProvider,
  GoogleTagManagerRouteChangeTracker,
  InitializeGoogleTagManager,
  IntlWrapper,
} from './components';

import Routes from './Routes';

setupAxios(store);

InitializeGoogleTagManager();

const PlatformSettingsAvailable = () => {
  const dispatch = useStoreDispatch();

  const platformThemeSettings = useStoreSelector(state =>
    StoreSelectors.AppSelector.selectThemeSettings(state.AppReducer),
  );
  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  React.useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`/owners/${ownerId}/platform-settings`);
        const platformSetting = response.data['hydra:member'][0];

        dispatch(StoreActions.AppActions.setPlatformSettings(platformSetting));

        document.title = platformSetting.platformName;
        const favicon = document.getElementById('favicon') as HTMLAnchorElement | null;

        if (favicon) {
          favicon.href = platformSetting.faviconUrl;
        }
      } catch (error: any) {
        const severity = MESSAGE_SEVERITY_ERROR;
        const details = {
          method: 'PlatformSettingsAvailable',
          message: error.message,
          severity,
        };

        dispatch(StoreActions.AppActions.displayMessage(details));
      }
    })();
  }, [dispatch, ownerId]);

  const themeSettings = React.useMemo(() => theme(platformThemeSettings || palette), [platformThemeSettings]);

  return (
    <IntlWrapper>
      <ThemeProvider theme={themeSettings}>
        <BrowserRouter>
          <GoogleTagManagerRouteChangeTracker />
          <AuthorizationProvider>
            <BreadcrumbProvider>
              <Routes />
            </BreadcrumbProvider>
          </AuthorizationProvider>
        </BrowserRouter>
      </ThemeProvider>
    </IntlWrapper>
  );
};

const oneMinute = 1000 * 60;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: oneMinute,
      refetchOnWindowFocus: process.env.REFETCH_QUERIES_ON_WINDOW_FOCUS === 'yes',
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <PlatformSettingsAvailable />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
