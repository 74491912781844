import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

export const GridItemStyled = styled(Grid, {
  name: 'GridItemStyled',
})(() => ({
  '& > div:first-of-type': {
    height: '100%',
  },
}));
