import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { TranslationKeys } from '../../../../../translations';
import { useSnakeBar } from '../../../../../hooks/useSnakeBar';
import employerService from '../../..//Services/EmployerService';
import FormikModal from '../../../../../components/FormikModal';
import { EmployerFileUpload } from 'app/Domain/Employers/Components/EmployerFileUpload';
import { normalizeError } from 'app/utils';
import { StoreSelectors, useStoreSelector } from 'app/store';

type EmployerUploadFormDialogProps = {
  show: boolean;
  onClose: () => void;
  afterSubmit: () => void;
};

export const EmployerUploadFormDialog = ({ show, onClose, afterSubmit }: EmployerUploadFormDialogProps) => {
  const intl = useIntl();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const [progressMsg, setProgressMsg] = React.useState<string | null>(null);

  const formikInitialValues = React.useMemo(
    () => ({
      employers: [],
      hasErrors: false,
    }),
    [],
  );

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      employers: Yup.array().min(1).max(1000).required(),
      hasErrors: Yup.boolean().oneOf([false]),
    });
  }, []);

  const formikOnSubmit = React.useCallback(
    async values => {
      const method = 'addEmployers';
      setProgressMsg(TranslationKeys.employees_add_fileSending);
      showSuccessSnakeBar({ method });
      await employerService
        .bulkCreateEmployers(ownerId, values.employers)
        .then(() => {
          // @ts-ignore
          showSuccessSnakeBar({ method });
          afterSubmit();
        })
        .catch((err: any) => {
          const errors = normalizeError(err);
          showErrorSnakeBar({ method, message: errors.message });
        })
        .finally(() => {
          setProgressMsg(null);
        });
    },
    [showSuccessSnakeBar, showErrorSnakeBar, afterSubmit, ownerId],
  );

  return (
    <Grid item xs={12}>
      <Formik
        enableReinitialize
        initialValues={formikInitialValues}
        validationSchema={formikValidationSchema}
        onSubmit={formikOnSubmit}
      >
        <FormikModal
          maxWidth="md"
          show={show}
          onHide={onClose}
          title={intl.formatMessage({ id: TranslationKeys.employers_add })}
        >
          <EmployerFileUpload progressMsg={progressMsg} setProgressMsg={setProgressMsg} />
        </FormikModal>
      </Formik>
    </Grid>
  );
};
