import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';

type UserAccessesListContainerProps = {
  children: React.ReactNode;
};

export const UserAccessesListContainer = ({ children }: UserAccessesListContainerProps) => {
  return (
    <Box
      sx={theme => ({
        marginTop: theme.spacing(1),
        maxHeight: theme.spacing(48),
        overflow: 'auto',
        outline: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
        borderRadius: '4px',
      })}
    >
      <PerfectScrollbar>{children}</PerfectScrollbar>
    </Box>
  );
};
