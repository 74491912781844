import { styled } from '@mui/material/styles';

import { SearchInput } from '../../../../components';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const SearchInputStyled = styled(SearchInput, {
  name: 'SearchInputStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
