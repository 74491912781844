import { Grid } from '@mui/material';
import { RegulationsEmployerScales } from '../RegulationsEmployerScales';
import type { UseQueryRefetch } from '../../../../types';
import { RegulationsPanel } from '../RegulationsPanel';
import React from 'react';
import { GROUP_KEYS, type RegulationsFormGroup, type RegulationsFromGroupKeys } from '../RegulationsPanelForm';
import type { SummaryOfRegulation } from '../../../../hooks';

const sortByDescription = (a: SummaryOfRegulation, b: SummaryOfRegulation) => {
  if (a.defaultDescription < b.defaultDescription) {
    return -1;
  }
  if (a.defaultDescription > b.defaultDescription) {
    return 1;
  }
  return 0;
};

const dataReducer = (keys: Array<RegulationsFromGroupKeys>, groups: RegulationsFormGroup) => {
  return keys.reduce((data, k) => {
    data[k] = (groups[k] || []).sort(sortByDescription);
    return data;
  }, {} as RegulationsFormGroup);
};

type PensionRegulationsProps = {
  loading: boolean;
  ownerId: string;
  employerId?: string;
  product: any;
  editing: boolean;
  isEmployer: boolean;
  refetchRegulationsQuery: UseQueryRefetch;
  withRegulationsEmployerScales?: boolean;
  groups: RegulationsFormGroup;
  header: any;
};

export const PensionRegulations = ({
  loading,
  ownerId,
  employerId,
  product,
  editing,
  isEmployer,
  refetchRegulationsQuery,
  withRegulationsEmployerScales = false,
  groups,
  header,
}: PensionRegulationsProps) => {
  const regulationsScales = React.useMemo(() => {
    const scales = groups[GROUP_KEYS.Scales];

    if (!withRegulationsEmployerScales || !scales) {
      return null;
    }

    try {
      return {
        summaryOfRegulationId: scales[0].summaryOfRegulationId,
        year: scales[0].year,
        data: JSON.parse(scales[0].value),
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }, [groups, withRegulationsEmployerScales]);

  const partner = React.useMemo(() => dataReducer([GROUP_KEYS.PartnerPension], groups), [groups]);
  const orphan = React.useMemo(() => dataReducer([GROUP_KEYS.OrphanPension], groups), [groups]);
  const disability = React.useMemo(() => dataReducer([GROUP_KEYS.DisabilityPension], groups), [groups]);
  const survivor = React.useMemo(() => dataReducer([GROUP_KEYS.SurvivorsBridgingPension], groups), [groups]);
  const main = React.useMemo(() => {
    return dataReducer([GROUP_KEYS.OverviewRegulations, GROUP_KEYS.Employees], groups);
  }, [groups]);

  const shouldRenderRegulationsEmployerScales =
    withRegulationsEmployerScales && !!employerId && !!regulationsScales?.summaryOfRegulationId;

  return (
    <Grid container spacing={2}>
      {shouldRenderRegulationsEmployerScales && (
        <Grid item container xs={12}>
          <RegulationsEmployerScales
            loading={loading}
            ownerId={ownerId}
            summaryOfRegulationId={regulationsScales?.summaryOfRegulationId}
            productId={product?.productId}
            connectionId={product?.connectionId}
            employerId={employerId}
            year={regulationsScales?.year}
            refetchRegulationsQuery={refetchRegulationsQuery}
            data={regulationsScales?.data || {}}
          />
        </Grid>
      )}
      <Grid item container xs={12}>
        <RegulationsPanel
          header={isEmployer ? header : undefined}
          loading={loading}
          regulations={main}
          isEmployer={isEmployer}
          editing={editing}
        />
      </Grid>
      <Grid item container md={6} xs={12}>
        <RegulationsPanel loading={loading} regulations={partner} isEmployer={isEmployer} editing={editing} />
      </Grid>
      <Grid item container md={6} xs={12}>
        <RegulationsPanel loading={loading} regulations={orphan} isEmployer={isEmployer} editing={editing} />
      </Grid>
      <Grid item container md={6} xs={12}>
        <RegulationsPanel loading={loading} regulations={disability} isEmployer={isEmployer} editing={editing} />
      </Grid>
      <Grid item container md={6} xs={12}>
        <RegulationsPanel loading={loading} regulations={survivor} isEmployer={isEmployer} editing={editing} />
      </Grid>
    </Grid>
  );
};
