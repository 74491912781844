import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import type { FilterableTablePaginationType } from 'app/components';
import { SnackBar } from 'app/components';
import { StoreActions, useStoreDispatch, useStoreSelector } from 'app/store';
import { useEmployeeGetEmployeeQuery, useSnakeBar } from 'app/hooks';
import { TranslationKeys } from 'app/translations';
import { EmployeeEventsTable } from './components';
import { triggerFileDownload } from 'app/utils';
import { useConnectionResendMutationsMutation } from 'app/hooks/api/connection/useConnectionResendMutationsMutation';
import eventsService from 'app/Domain/Connections/Services/EventsService';

export const EmployeeEventsTab = () => {
  const dispatch = useStoreDispatch();
  const { employerId, employeeId } = useParams();
  const { showErrorSnakeBar, showSuccessSnakeBar } = useSnakeBar();
  const { mutate: resendMutationsMutation } = useConnectionResendMutationsMutation();

  const [pageSize, setPageSize] = React.useState(10);
  const [isResendingMutations, setIsResendingMutations] = React.useState(false);

  const { data: employee } = useEmployeeGetEmployeeQuery({
    variables: { employeeId, employerId },
    options: {
      enabled: !!employeeId && !!employerId,
    },
  });

  const { showSnackBar } = useStoreSelector(state => state.AppReducer);

  const {
    items: events,
    page,
    totalCount,
    filters,
    selectedRowsIds,
    loading: eventsLoading,
  } = useStoreSelector(state => state.EventsReducer);

  const isLoading = eventsLoading || isResendingMutations;

  React.useEffect(() => {
    if (employee?.connectionId) {
      dispatch(StoreActions.EventsActions.setConnectionId(employee.connectionId));
      dispatch(StoreActions.EventsActions.clearEvents());
      dispatch(StoreActions.EventsActions.resetFilters());
      dispatch(StoreActions.EventsActions.applyFilter('employeeId', employee.employeeId));
      dispatch(StoreActions.EventsActions.applyFilter('archived', null));
      dispatch(StoreActions.EventsActions.applyFilter('processed', null));
      dispatch(StoreActions.EventsActions.setPageSize(pageSize));
    }
  }, [dispatch, employee, pageSize]);

  const onDownloadActionClick = React.useCallback(
    async (selectAll = false) => {
      try {
        if (employee?.connectionId) {
          const response = await eventsService.downloadEvents(employee.connectionId, {
            eventIds: selectAll ? [] : selectedRowsIds,
            filters: { ...filters, employeeId },
            selectAll,
          });

          triggerFileDownload({
            file: response.data,
            fileName: response.headers['x-filename'],
          });
        }
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        showErrorSnakeBar({ method: 'downloadEvents', message: error });
      }
    },
    [employee?.connectionId, employeeId, filters, selectedRowsIds, showErrorSnakeBar],
  );

  const onResendMutationsActionClick = React.useCallback(
    async (batches: Record<string, string[]>) => {
      if (Object.keys(batches).length > 0 && employee?.connectionId) {
        try {
          const method = 'resendMutations';
          setIsResendingMutations(true);
          await Promise.all(
            Object.keys(batches).map(batchId => {
              return resendMutationsMutation(
                {
                  connectionId: employee?.connectionId,
                  batchId,
                  externalIds: batches[batchId],
                },
                {
                  onError: error => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    showErrorSnakeBar({ method });
                  },
                },
              );
            }),
          );
          showSuccessSnakeBar({ method });
          dispatch(StoreActions.EventsActions.requestData());
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        } finally {
          setIsResendingMutations(false);
        }
      }
    },
    [dispatch, employee?.connectionId, resendMutationsMutation, showErrorSnakeBar, showSuccessSnakeBar],
  );

  const onSelectAllChange = React.useCallback(() => {
    dispatch(StoreActions.EventsActions.toggleAllEvents(events));
  }, [dispatch, events]);

  const onSelectRowChange = React.useCallback(
    event => {
      dispatch(StoreActions.EventsActions.toggleEvent(event));
    },
    [dispatch],
  );

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === 'reset') {
        dispatch(StoreActions.EventsActions.applyFilter('state', null));
        dispatch(StoreActions.EventsActions.applyFilter('eventDate[after]', null));
        dispatch(StoreActions.EventsActions.applyFilter('eventDate[before]', null));
        dispatch(StoreActions.EventsActions.applyFilter('eventType', null));
      } else {
        dispatch(StoreActions.EventsActions.applyFilter(name, value));
      }
    },
    [dispatch],
  );

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.EventsActions.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    event => {
      if (event.rowSize) {
        setPageSize(event.rowSize);
        dispatch(StoreActions.EventsActions.setPageSize(pageSize));
      }
      if (event.page) {
        dispatch(StoreActions.EventsActions.changePage(event.page));
      }
    },
    [dispatch, pageSize],
  );

  const pagination = React.useMemo<FilterableTablePaginationType>(() => {
    return {
      pageSize,
      totalCount,
      page,
    };
  }, [totalCount, page, pageSize]);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <EmployeeEventsTable
            isLoading={isLoading}
            rows={events}
            pagination={pagination}
            filters={filters}
            onDownloadActionClick={onDownloadActionClick}
            onResendMutationsActionClick={onResendMutationsActionClick}
            onFilterChange={onFilterChange}
            onPaginationChange={onPaginationChange}
            onSortChange={onSortChange}
            onSelectAllChange={onSelectAllChange}
            onSelectRowChange={onSelectRowChange}
            selectedRowsIds={selectedRowsIds}
          />
        </Grid>
      </Grid>
      <SnackBar message={<FormattedMessage id={TranslationKeys.events_tooManyEvents} />} open={showSnackBar} />
    </>
  );
};
