import { buffers } from 'redux-saga';
import { actionChannel, call, put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { createNotes, getConnectionNotes, updateNotes } from '../Services/NotesService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { debounceChannel, normalizeError } from 'app/utils';
import { createSelector } from 'reselect';

export const actionTypes = {
  RequestData: '[Notes] Request Connection Notes',
  FulfilledTable: '[Notes] Fulfilled Connection',
  ChangePage: '[Notes] change page',
  SetPageSize: '[Notes] set page size',
  ChangeNote: '[Notes] Change note of a Connection',
  SetPageLoaded: '[Notes] Set page loaded',
  SetConnectionId: '[Notes] Set connection Id',
};

const initialState = {
  ...tableInitialState,
  pageLoaded: false,
  connectionAccess: false,
  connectionId: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.SetConnectionId: {
      return {
        ...state,
        connectionId: action.connectionId,
      };
    }
    case actionTypes.SetPageLoaded: {
      return {
        ...newState,
        pageLoaded: true,
      };
    }
    case actionTypes.FulfilledData: {
      return {
        ...newState,
        connectionAccess: true,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
  setPageLoaded: () => ({
    type: actionTypes.SetPageLoaded,
  }),
  changeNote: payload => ({
    payload,
    type: actionTypes.ChangeNote,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestNotesSaga() {
    try {
      const currentState = yield select(state => {
        return state.NotesReducer;
      });

      const response = yield getConnectionNotes(currentState.connectionId, currentState.page, currentState.pageSize);
      yield put(actions.setPageLoaded());
      if (response && response.data) {
        yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      }
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getConnectionNotes',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  // Notes auto-saving is special because if there's multiple queued save requests we're only interested on the latest
  // one and, in case we're creating a new note, we must use the just-created note id on the subsequent calls
  const notesChannel = yield actionChannel(actionTypes.ChangeNote, buffers.sliding(1));
  let lastNoteResult = null;
  while (true) {
    // Block until a message is put on the channel
    const message = yield debounceChannel(notesChannel, 500);
    lastNoteResult = yield call(
      function* reloadData({ payload }, previousNote) {
        try {
          // if we just created a note use the new note id on the subsequent update
          const noteId =
            payload.noteId || (payload.connectionId === previousNote?.connectionId ? previousNote?.noteId : null);

          const response = noteId
            ? yield call(updateNotes, payload.text, payload.connectionId, noteId)
            : yield call(createNotes, payload.text, payload.connectionId);

          yield put(actions.requestData(payload.connectionId));
          return {
            noteId: response.status === 201 ? response.data.id : noteId,
            connectionId: payload.connectionId,
          };
        } catch (error) {
          const errorData = normalizeError(error);
          yield put(
            AppActions.displayMessage({
              severity: MESSAGE_SEVERITY_ERROR,
              message: errorData.message,
            }),
          );
        }
        return null;
      },
      message,
      lastNoteResult,
    );
  }
}

export const selectors = {
  selectConnectionNote: createSelector(
    state => state,
    state => {
      const { loading, items: notes, connectionId } = state;
      return {
        loading,
        connectionId,
        note: (notes?.length || 0) === 0 ? { text: '', connectionId } : notes[0],
      };
    },
  ),
};
