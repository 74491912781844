import { styled } from '@mui/material/styles';

import type { DashboardOverviewInfoStatusItemProps } from './DashboardOverviewInfoStatusItem';

export const StatusItemStyled = styled('div', {
  name: 'StatusItemStyled',
  shouldForwardProp: (propName: string) => !['type'].includes(propName),
})<Pick<DashboardOverviewInfoStatusItemProps, 'type'>>(({ theme, type }) => {
  const color = type === 'warning' ? theme.palette.warning.main : theme.palette.error.main;

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '4.5rem',
    width: '19rem',
    borderRadius: '10px',
    padding: theme.spacing(1, 2),
    outline: `1px solid ${color}`,
    color,
    '& svg': {
      color,
      display: 'block',
      margin: 'auto',
      fontSize: '2em',
    },
    '& h5': {
      color,
      flexGrow: 1,
      textAlign: 'center',
    },
  };
});
