import React from 'react';

import { Skeleton } from '../../Skeleton';

import { ListRow } from '../ListRow';

type ListRowSkeletonProps = {
  rows?: number;
};

export const ListRowSkeleton = ({ rows = 5 }: ListRowSkeletonProps) => {
  const rowsArray = React.useMemo(() => new Array(rows).fill(null), [rows]);

  return (
    <>
      {rowsArray.map((_, idx) => (
        <ListRow key={idx}>
          <Skeleton width={'100%'} height={30} />
        </ListRow>
      ))}
    </>
  );
};
