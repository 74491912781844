import React, { createRef, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import WizardStep from './WizardStep';
import { Box, DialogContent, IconButton, Tabs } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isNil } from 'app/utils';
import { ActionButtonStyled, DialogActionsStyled, LinearProgressStyled, TabStyled } from './ Wizard.styles';

const emptyParams = {};

export const hasParentWithClass = (el, classname) => {
  let next = el.parentElement;
  while (!isNil(next)) {
    if (next.classList && next.classList.contains(classname)) {
      return true;
    }
    next = next.parentElement;
  }

  return false;
};

export function handlePickersModalClick(e) {
  if (!hasParentWithClass(e.target, 'MuiPickersPopper-paper')) {
    if (document.activeElement && document.activeElement.classList.contains('MuiPickersPopper-paper')) {
      document.activeElement.blur && document.activeElement.blur();
    }
  }
}

// maxNumberOfSteps is used at first render to avoid dynamic resizing of the initial component state
const Wizard = ({ steps, onSubmit, onClose, title, loading, maxNumberOfSteps = 10 }) => {
  const innerSteps = useMemo(() => {
    return steps || [];
  }, [steps]);

  const [componentsRefs] = React.useState(Array.from({ length: maxNumberOfSteps }, () => createRef()));
  const [stepIndex, setStepIndex] = useState(0);
  const [completeSteps, setCompleteSteps] = useState(Array.from({ length: maxNumberOfSteps }, () => false));
  const [stepsState, setStepsState] = useState(Array.from({ length: maxNumberOfSteps }, () => false));
  const [internalLoading, setInternalLoading] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const actualLoading = !!(internalLoading || submitInProgress || loading);

  const currentStep = innerSteps[stepIndex] ?? null;
  const stepRef = componentsRefs[stepIndex] ?? null;
  const stepParams = useMemo(() => {
    let params = { ...(currentStep?.params || emptyParams) };
    if (isNil(params?.stepState)) {
      params.stepState = stepsState[stepIndex] || {};
    }
    if (isNil(params.stepState?.state)) {
      params.stepState.state = {};
    }
    if (isNil(params.stepState?.form)) {
      params.stepState.form = {};
    }
    params.loading = !!(params.loading || actualLoading);
    if (currentStep?.paramsEnricher) {
      params = { ...params, ...currentStep?.paramsEnricher(stepsState) };
    }
    return params;
  }, [actualLoading, currentStep, stepIndex, stepsState]);

  const stepComponent = currentStep?.component ?? null;
  const firstStep = stepIndex === 0;
  const lastStep = stepIndex === innerSteps.length - 1;

  const isIndexValid = useCallback(
    index => {
      return index >= 0 && index < innerSteps.length;
    },
    [innerSteps.length],
  );

  const saveState = useCallback(
    async stepIndex => {
      stepsState[stepIndex] = (await (stepRef.current.getState && stepRef.current.getState())) || {};
      setStepsState(stepsState);
      return stepsState;
    },
    [stepRef, stepsState],
  );

  const goToNextStep = useCallback(
    async (stepIndex, newStepsState) => {
      completeSteps[stepIndex] = true;
      setCompleteSteps(completeSteps);

      if (lastStep) {
        setInternalLoading(true);
        try {
          await onSubmit(newStepsState.slice(0, innerSteps.length));
        } finally {
          setInternalLoading(false);
        }
        return;
      }

      setStepIndex(stepIndex + 1);
    },
    [completeSteps, innerSteps.length, lastStep, onSubmit],
  );

  const clickNext = useCallback(
    async (event = null) => {
      event && event.preventDefault();
      setSubmitInProgress(true);
      try {
        if (stepRef?.current?.submit) {
          await stepRef.current.submit();
        } else {
          const newStepsState = await saveState(stepIndex);
          await goToNextStep(stepIndex, newStepsState);
        }
      } finally {
        setSubmitInProgress(false);
      }
    },
    [saveState, stepIndex, stepRef, goToNextStep],
  );

  const clickBack = useCallback(
    async event => {
      event && event.preventDefault();

      if (stepRef?.current?.canGoBack) {
        if (!stepRef.current.canGoBack()) {
          return;
        }
      }

      await saveState(stepIndex);

      const nextStep = stepIndex - 1;
      if (nextStep < 0) {
        return;
      }

      stepRef.current?.onBack && stepRef.current.onBack();
      setStepIndex(nextStep);
      currentStep.onBack && currentStep.onBack();
    },
    [stepRef, saveState, stepIndex, currentStep],
  );

  const onStepComplete = useCallback(async () => {
    if (!currentStep) {
      return;
    }
    const newStepsState = await saveState(stepIndex);
    currentStep.onSubmit && currentStep.onSubmit(newStepsState[stepIndex]);
    await goToNextStep(stepIndex, newStepsState);
  }, [currentStep, goToNextStep, saveState, stepIndex]);

  const onDialogClose = useCallback(
    (event, reason) => {
      if (reason === 'backdropClick') {
        event.preventDefault();
        handlePickersModalClick(event);
        return;
      }
      onClose && onClose();
    },
    [onClose],
  );

  const onClickTab = useCallback(
    async index => {
      if (actualLoading) {
        return;
      }
      if (!isIndexValid(index)) {
        return;
      }

      if (completeSteps[index]) {
        await saveState(stepIndex);
        setStepIndex(index);
        return;
      }

      if (index > stepIndex + 1) {
        await clickNext();
      }
    },
    [actualLoading, isIndexValid, completeSteps, saveState, stepIndex, clickNext],
  );

  const progress = Math.ceil(100 * Math.min(1, (stepIndex + 1) / (innerSteps.length || 1)));

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      aria-labelledby="wizard-dialog-title"
      open={true}
      onClose={onDialogClose}
      disableEscapeKeyDown
      scroll={'paper'}
      // needed workaround for https://github.com/mui/material-ui-pickers/issues/1852
      disableEnforceFocus
      onClick={handlePickersModalClick}
    >
      <DialogTitle id="wizard-dialog-title" sx={{ paddingBottom: 0 }}>
        <div>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            <Box>
              {!!onClose && (
                <IconButton size={'large'} aria-label="close" onClick={onClose} sx={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </div>
        <LinearProgressStyled variant={actualLoading ? 'indeterminate' : 'determinate'} value={progress} />
        <Tabs value={stepIndex}>
          {innerSteps.map(({ label, disabled }, index) => (
            <TabStyled
              key={index}
              completed={index !== stepIndex && completeSteps[index]}
              label={label}
              disabled={!!disabled}
              onClick={() => onClickTab(index)}
            />
          ))}
        </Tabs>
      </DialogTitle>
      <DialogContent>
        {!!currentStep && (
          <WizardStep
            ref={componentsRefs[stepIndex]}
            component={stepComponent}
            onSubmit={onStepComplete}
            {...stepParams}
          />
        )}
      </DialogContent>
      <DialogActionsStyled>
        {!!currentStep && (
          <>
            <ActionButtonStyled
              messageId={'step.back'}
              disabled={firstStep || actualLoading}
              onClick={clickBack}
              variant={'outlined'}
            />
            <ActionButtonStyled
              messageId={lastStep ? 'step.finish' : 'step.next'}
              disabled={actualLoading}
              onClick={clickNext}
            />
          </>
        )}
      </DialogActionsStyled>
    </Dialog>
  );
};

Wizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.object.isRequired,
      params: PropTypes.object,
      label: PropTypes.object, // translated string
      paramsEnricher: PropTypes.func,
    }),
  ).isRequired,
  title: PropTypes.any, // translated string
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default Wizard;
