import { useParams } from 'react-router-dom';
import React from 'react';
import { Box } from '@mui/material';
import { EmployeeSicknessLeaves } from './components/EmployeeSicknessLeaves';
import type { EmployeeEmployment } from '../../../../../../types';
import { useEmployeeGetEmploymentsQuery } from '../../../../../../hooks';

export const EmployeeSicknessTab = () => {
  const { employerId, employeeId } = useParams();
  const [activeEmployment, setActiveEmployment] = React.useState<
    EmployeeEmployment & { employeeId: string; connectionId: string }
  >();

  const {
    isLoading: isLoadingEmployments,
    isFetching: isFetchingGetEmploymentsQuery,
    refetch: refetchGetEmploymentsQuery,
  } = useEmployeeGetEmploymentsQuery({
    variables: { employerId, employeeId },
    options: {
      enabled: !!employerId && !!employeeId,
      onSuccess: data => {
        setActiveEmployment(data.data.filter(employment => employment.latest)[0]);
      },
    },
  });

  return (
    <Box display={'flex'} paddingTop={1}>
      <>
        <EmployeeSicknessLeaves
          loading={isLoadingEmployments || isFetchingGetEmploymentsQuery}
          isRegularConnection={false}
          employment={activeEmployment}
          synchronizationStatus={false}
          disabledActionButtonsWhileLoading={false}
          employerId={employerId}
          refetchGetEmploymentsQuery={refetchGetEmploymentsQuery}
        />
      </>
    </Box>
  );
};
