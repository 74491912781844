import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UseQueryWrapper, UnprocessedBatch } from '../../../types';

import type { GetUnprocessedBatchesParams } from '../../../Domain/Dashboard/Services';
import { dashboardService } from '../../../Domain/Dashboard/Services';

import { useSnakeBar } from '../../useSnakeBar';

type GetUnprocessedBatchesQueryVariables = GetUnprocessedBatchesParams;

type GetUnprocessedBatchesQueryResultData = {
  data: Array<UnprocessedBatch>;
  totalCount: number;
};

const queryFn = (variables: GetUnprocessedBatchesQueryVariables) => async () => {
  const response = await dashboardService.getUnprocessedBatches(variables);
  return response.data;
};

const QUERY_KEY = 'getUnprocessedBatches';

export const useDashboardGetUnprocessedBatchesQuery: UseQueryWrapper<
  GetUnprocessedBatchesQueryResultData,
  GetUnprocessedBatchesQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetUnprocessedBatchesQueryResultData>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
