import { TranslationKeys } from 'app/translations';

import { normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc } from 'app/components';

export const EmployeeSicknessLeavesFields = {
  StartDate: {
    Label: TranslationKeys.employees_detail_sickLeaveStartDate,
    InputName: 'startDate',
    Options: {
      style: 'date',
    },
  },
  EndDate: {
    Label: TranslationKeys.employees_detail_sickLeaveEndDate,
    InputName: 'endDate',
    Options: {
      style: 'date',
    },
  },
} as const;

export const EmployeeEmploymentWageFieldOptionsAndLabel =
  normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc(EmployeeSicknessLeavesFields);

export type EmployeeSicknessFormValues = {
  id: string | undefined;
  [EmployeeSicknessLeavesFields.StartDate.InputName]: string | undefined;
  [EmployeeSicknessLeavesFields.EndDate.InputName]: string | undefined;
};
