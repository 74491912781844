import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Button, ListItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const ListItemStyled = styled(ListItem, {
  name: 'ListItemStyled',
})(({ theme }) => ({
  backgroundColor: '#00438D',
  display: 'flex',
  paddingBottom: 0,
  paddingTop: 0,
  paddingLeft: theme.spacing(1.25),
  marginBottom: theme.spacing(0.25),
  minHeight: '50px',
}));

export const ListItemChildrenStyled = styled(ListItem, {
  name: 'ListItemChildrenStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  display: 'flex',
  paddingBottom: 0,
  paddingTop: 0,
  paddingLeft: theme.spacing(2.5),
  marginBottom: theme.spacing(0.25),
  minHeight: '50px',
}));

export const ButtonStyled = styled(Button, {
  name: 'ButtonStyled',
})(({ theme }) => ({
  color: theme.palette.primary.light,
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,
  '&.active': {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightBold,
    '& $icon': {
      color: theme.palette.primary.light,
    },
  },
}));

export const ButtonToggleExpandStyled = styled(Button, {
  name: 'ButtonToggleExpandStyled',
})(() => ({
  minWidth: 0,
}));

const commonIconStyles = (theme: Theme) => ({
  color: theme.palette.primary.light,
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1.25),
});

export const FontAwesomeIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconStyled',
})(({ theme }) => commonIconStyles(theme));

export const ExpandLessIconStyled = styled(ExpandLess, {
  name: 'ExpandLessIconStyled',
})(({ theme }) => commonIconStyles(theme));

export const ExpandMoreIconStyled = styled(ExpandMore, {
  name: 'ExpandMoreIconStyled',
})(({ theme }) => commonIconStyles(theme));
