import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { EmployeeType, UseQueryWrapper } from 'app/types';

import { useSnakeBar } from 'app/hooks';

import type { GetEmployeeTypesParams } from 'app/Domain/Owners/Services/OwnerService';
import { ownerService } from 'app/Domain/Owners/Services/OwnerService';

export type GetEmployeeTypesQueryResultData = {
  data: Array<EmployeeType>;
  totalCount: number;
};

export type GetEmployeeTypesQueryVariables = {
  [key in keyof GetEmployeeTypesParams]: GetEmployeeTypesParams[key] | undefined;
};

const queryFn =
  ({ ownerId, values }: GetEmployeeTypesQueryVariables) =>
  async () => {
    if (ownerId) {
      const response = await ownerService.getEmployeeTypes({ ownerId, values });
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployeeTypes';

export const useEmployeeGetEmployeeTypesQuery: UseQueryWrapper<
  GetEmployeeTypesQueryResultData,
  GetEmployeeTypesQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetEmployeeTypesQueryResultData>(
    [QUERY_KEY, variables.ownerId, JSON.stringify(variables.values || {})],
    queryFn(variables),
    {
      onError,
      select,
      ...(options || {}),
    },
  );
};
