const forceDownload = (blob, name = 'test.csv') => {
  const link = document.createElement('a');

  link.href = blob;
  link.download = name;

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);
};

export default forceDownload;
