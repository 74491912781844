import { get } from '../Lodash';

type FormatNameArg = {
  initials?: string;
  firstName?: string;
  middleName?: string;
  lastNamePrefix?: string;
  lastName?: string;
};

export const formatName = (person: FormatNameArg | undefined) => {
  return [
    get(person, 'initials'),
    get(person, 'firstName'),
    get(person, 'middleName'),
    get(person, 'lastNamePrefix'),
    get(person, 'lastName'),
  ]
    .filter(Boolean)
    .join(' ');
};
