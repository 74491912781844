import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';
import { SnackbarStyled } from './SnackBar.styles';

const SnackBar = props => {
  const { message, open } = props;
  const [state, setState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;
  const dispatch = useDispatch();

  const handleClose = () => {
    setState({ ...state, open: false });
    dispatch(AppActions.displaySnackBar(false));
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <SnackbarStyled
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      message={message}
      key={vertical + horizontal}
      action={action}
    />
  );
};

SnackBar.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
};

export default SnackBar;
