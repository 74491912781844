import React from 'react';
import { useIntl } from 'react-intl';
import { Form, useFormikContext } from 'formik';

import { Alert, Box } from '@mui/material';

import { TranslationKeys } from '../../../../../../translations';

import { ActionButton, InputFieldFactory } from '../../../../../../components';

import type { ResetPassViewFormValues } from '../../ResetPasswordViewContent.types';
import { RESET_PASS_VIEW_FORM_FIELDS } from '../../ResetPasswordViewContent.types';

export const RESET_PASSWORD_VIEW_FORM_DATA_TEST_ID = 'reset-password-form-component';

export const ResetPasswordViewForm = () => {
  const intl = useIntl();

  const { submitForm, status, isValid, isSubmitting } = useFormikContext<ResetPassViewFormValues>();

  return (
    <Form data-testid={RESET_PASSWORD_VIEW_FORM_DATA_TEST_ID}>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        {status && <Alert severity="error">{status}</Alert>}

        <InputFieldFactory
          field={{
            required: true,
            type: 'password',
            name: RESET_PASS_VIEW_FORM_FIELDS.Password,
            header: intl.formatMessage({ id: TranslationKeys.resetPassword_password }),
          }}
        />
        <InputFieldFactory
          field={{
            required: true,
            type: 'password',
            name: RESET_PASS_VIEW_FORM_FIELDS.PasswordConfirmation,
            header: intl.formatMessage({ id: TranslationKeys.resetPassword_passwordConfimation }),
          }}
        />

        <Box>
          <ActionButton
            messageId={TranslationKeys.resetPassword_button}
            disabled={!isValid || isSubmitting}
            type={'submit'}
            onClick={submitForm}
          />
        </Box>
      </Box>
    </Form>
  );
};
