import { useMutation } from '@tanstack/react-query';

import type { DeleteSummaryScaleArgs } from 'app/Domain/Settings/Services';
import { settingsServices } from 'app/Domain/Settings/Services';

type DeleteSummaryScaleData = DeleteSummaryScaleArgs;

const mutationFn = (data: DeleteSummaryScaleData) => {
  return settingsServices.deleteSummaryScale(data);
};

export const useSettingsSummaryScaleTableMutation = () => {
  return useMutation<unknown, unknown, DeleteSummaryScaleData>(mutationFn);
};
