import React from 'react';

import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';

import { Skeleton } from '../Skeleton';

type LoadingTableSkeletonProps = {
  columns?: number;
  rows?: number;
};

export const TableSkeleton = ({ columns = 10, rows = 10 }: LoadingTableSkeletonProps) => {
  const columnsArray = React.useMemo(() => new Array(columns).fill(null), [columns]);
  const rowsArray = React.useMemo(() => new Array(rows).fill(null), [rows]);

  const TableColumns = React.useMemo(() => {
    return (
      <TableRow>
        {columnsArray.map((_, idx) => {
          return (
            <TableCell key={idx}>
              <Skeleton />
            </TableCell>
          );
        })}
      </TableRow>
    );
  }, [columnsArray]);

  const TableRows = React.useMemo(() => {
    return rowsArray.map((_, rowIdx) => {
      return (
        <TableRow key={rowIdx}>
          {columnsArray.map((_, columnIdx) => {
            return (
              <TableCell key={`${rowIdx}-${columnIdx}`}>
                <Skeleton />
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  }, [columnsArray, rowsArray]);

  return (
    <>
      <Table>
        <TableHead>{TableColumns}</TableHead>
        <TableBody>{TableRows}</TableBody>
      </Table>
      <TablePagination
        component="div"
        count={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
        page={0}
        rowsPerPage={rows}
        rowsPerPageOptions={[rows]}
      />
    </>
  );
};
