import { styled } from '@mui/material/styles';

import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  collapseClasses,
  iconButtonClasses,
} from '@mui/material';

import { commonTableHoverEffectStyles } from '../List.styles';

export const DivRowContainerStyled = styled('div', {
  name: 'DivRowContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [`& .${collapseClasses.root}`]: {
    transform: 'translateX(-42px)',
    width: 'calc(100% + 42px)',
  },
  ...commonTableHoverEffectStyles(theme),
}));

export const AccordionStyled = styled(Accordion, {
  name: 'AccordionStyled',
})(() => ({
  width: '100%',
  boxShadow: 'unset',
  backgroundColor: 'inherit',
  '&::before': {
    content: 'unset',
  },
  [`&.${accordionClasses.expanded}`]: {
    margin: 0,
  },
}));

export const AccordionSummaryStyled = styled(AccordionSummary, {
  name: 'AccordionSummaryStyled',
})(({ theme }) => ({
  flexDirection: 'row-reverse',
  minHeight: 'unset',
  height: '2.7em',
  padding: 0,
  [`&.${accordionClasses.expanded}`]: {
    minHeight: 'unset',
  },
  [`& .${accordionSummaryClasses.content}`]: {
    margin: 0,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    paddingLeft: 0,
  },
  [`& .${iconButtonClasses.root}`]: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

export const AccordionDetailsStyled = styled(AccordionDetails, {
  name: 'AccordionDetailsStyled',
  shouldForwardProp: (propName: string) => !['extraLeftPadding'].includes(propName),
})<{ extraLeftPadding: boolean }>(({ theme, extraLeftPadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 0, 1, 0),
  '& > div': {
    paddingLeft: theme.spacing(extraLeftPadding ? 9.25 : 8),
  },
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
}));
