import React from 'react';

import { StoreSelectors, useStoreSelector } from '../../../store';

import type {
  GetOwnerUsersQueryResultData,
  GetOwnerUsersQueryVariables,
  GetUsersQueryResultData,
  GetUsersQueryVariables,
} from '../../../hooks';
import { useOwnerGetOwnerUsersQuery, useUserGetAllQuery } from '../../../hooks';

type QueryVariables = GetUsersQueryVariables | GetOwnerUsersQueryVariables;

type QueryData = GetUsersQueryResultData | GetOwnerUsersQueryResultData | undefined;

export const useUsersQuery = (variables: QueryVariables) => {
  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));
  const isSuperAdmin = !ownerId;

  const {
    data: superAdminUsers,
    isLoading: isLoadingGetUsersQuery,
    isFetching: isFetchingUsersQuery,
    refetch: refetchGetUsersQuery,
  } = useUserGetAllQuery({
    variables,
    options: { enabled: isSuperAdmin },
  });

  const {
    data: ownerUsers,
    isLoading: isLoadingGetOwnerUsersQuery,
    isFetching: isFetchingOwnerUsersQuery,
    refetch: refetchGetOwnerUsersQuery,
  } = useOwnerGetOwnerUsersQuery({
    variables: { ownerId, ...variables },
    options: { enabled: !!ownerId },
  });

  const isGetUserQueryLoading = isSuperAdmin && (isLoadingGetUsersQuery || isFetchingUsersQuery);
  const isGetOwnerUsersQueryLoading = !isSuperAdmin && (isLoadingGetOwnerUsersQuery || isFetchingOwnerUsersQuery);

  const isLoading = isGetUserQueryLoading || isGetOwnerUsersQueryLoading;

  const data = React.useMemo<QueryData>(() => {
    if (isSuperAdmin) {
      return superAdminUsers;
    }
    if (ownerUsers) {
      return ownerUsers;
    }

    return undefined;
  }, [isSuperAdmin, ownerUsers, superAdminUsers]);

  const refetch = React.useCallback(() => {
    if (isSuperAdmin) {
      refetchGetUsersQuery();
    } else {
      refetchGetOwnerUsersQuery();
    }
  }, [isSuperAdmin, refetchGetOwnerUsersQuery, refetchGetUsersQuery]);

  return React.useMemo(() => ({ data, isLoading, refetch }), [isLoading, refetch, data]);
};
