import React from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { HasAccessTo } from './HasAccessTo';

export const RestrictAccess = ({ entity, permission }) => {
  const { userId } = useParams();
  const location = useLocation();

  return HasAccessTo(entity, permission, userId) ? (
    <Outlet />
  ) : (
    <Navigate to="/not-allowed" state={{ from: location }} replace />
  );
};
