import React from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { Grid } from '@mui/material';

import type { Owner, Role } from '../../../../../../../../types';

import { TranslationKeys } from '../../../../../../../../translations';

import { enabledLocales } from '../../../../../../../../components/Intl';
import { InputFieldFactory } from '../../../../../../../../components/FormikField';

import type { CreateUserFormValues } from '../../CreateUserDialog.types';

import { GridContainerStyled } from './CreateUserForm.styles';

type CreateUserFormProps = {
  roles: Role[];
  owners: Owner[];
  isLoading?: boolean;
};

export const CreateUserForm = ({ roles, owners, isLoading = false }: CreateUserFormProps) => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<CreateUserFormValues>();

  const rolesSelectItems = React.useMemo(() => {
    return roles
      .filter(item => item.owner && item.owner.includes(values?.ownerId || ''))
      .map(role => ({
        element: role.name,
        value: role.roleId,
      }));
  }, [roles, values.ownerId]);

  const ownersSelectItems = React.useMemo(() => {
    return owners.map(owner => ({
      element: owner.ownerName,
      value: owner.ownerId,
    }));
  }, [owners]);

  const languagesSelectItems = React.useMemo(() => {
    return Object.keys(enabledLocales).map(language => ({
      element: intl.formatMessage({ id: `translations.${language}` }),
      value: language,
    }));
  }, [intl]);

  const onChangeOwner = React.useCallback(() => {
    setFieldValue('roleId', '');
  }, [setFieldValue]);

  return (
    <>
      <GridContainerStyled container spacing={2}>
        <Grid item xs={6}>
          <InputFieldFactory
            field={{
              name: 'firstName',
              header: intl.formatMessage({ id: TranslationKeys.users_firstName }),
              required: true,
              loading: isLoading,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputFieldFactory
            field={{
              name: 'middleName',
              header: intl.formatMessage({ id: TranslationKeys.users_middleName }),
              loading: isLoading,
            }}
          />
        </Grid>
      </GridContainerStyled>

      <GridContainerStyled container spacing={2}>
        <Grid item xs={6}>
          <InputFieldFactory
            field={{
              name: 'lastName',
              header: intl.formatMessage({ id: TranslationKeys.users_lastName }),
              required: true,
              loading: isLoading,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputFieldFactory
            field={{
              type: 'email',
              name: 'emailAddress',
              header: intl.formatMessage({ id: TranslationKeys.users_email }),
              required: true,
              loading: isLoading,
            }}
          />
        </Grid>
      </GridContainerStyled>

      <GridContainerStyled container spacing={2}>
        <Grid item xs={6}>
          <InputFieldFactory
            onChange={onChangeOwner}
            field={{
              type: 'select',
              name: 'ownerId',
              header: intl.formatMessage({ id: TranslationKeys.owners_title }),
              required: true,
              loading: isLoading,
              items: ownersSelectItems,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputFieldFactory
            field={{
              type: 'select',
              name: 'roleId',
              header: intl.formatMessage({ id: TranslationKeys.users_role }),
              required: true,
              loading: isLoading,
              items: rolesSelectItems,
            }}
          />
        </Grid>
      </GridContainerStyled>
      <GridContainerStyled container spacing={2}>
        <Grid item xs={6}>
          <InputFieldFactory
            field={{
              type: 'select',
              name: 'language',
              header: intl.formatMessage({ id: TranslationKeys.users_language }),
              required: true,
              loading: isLoading,
              items: languagesSelectItems,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputFieldFactory
            field={{
              type: 'checkbox',
              name: 'internal',
              header: intl.formatMessage({ id: TranslationKeys.users_internal }),
              loading: isLoading,
            }}
          />
        </Grid>
      </GridContainerStyled>
    </>
  );
};
