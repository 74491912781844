import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { UseQueryWrapper } from '../../../types';

import { useSnakeBar } from '../../useSnakeBar';
import { dropdownService, type GetDropdownParams } from '../../../Domain/Utils/Services/DropdownService';
import type { Dropdown } from '../../../types/dropdown.types';

export type GetDropdownQueryVariables = GetDropdownParams;

export type GetDropdownQueryResultData = {
  data: Array<Dropdown>;
  totalCount: number;
};

const queryFn = (variables?: GetDropdownQueryVariables) => async () => {
  const response = await dropdownService.getDropdown(variables);
  return (response as AxiosResponse).data;
};

const QUERY_KEY = 'getDropdowns';

export const useGetDropdownsQuery: UseQueryWrapper<GetDropdownQueryResultData, GetDropdownQueryVariables, false> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetDropdownQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
