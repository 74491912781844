import { styled } from '@mui/material/styles';

export const DivStyled = styled('div', {
  name: 'DivStyled',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(3),
  top: theme.spacing(9.5),
  minWidth: '12.8%',

  [theme.breakpoints.down('sm')]: {
    position: 'unset',
    marginTop: theme.spacing(1),
  },
}));
