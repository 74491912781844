import React from 'react';
import { useLocation } from 'react-router';
import TagManager from 'react-gtm-module';

const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '';
const GOOGLE_TAG_MANAGER_AUTH = process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH;
const GOOGLE_TAG_MANAGER_PREVIEW = process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW;

export const InitializeGoogleTagManager = () =>
  TagManager.initialize({
    gtmId: GOOGLE_TAG_MANAGER_ID,
    auth: GOOGLE_TAG_MANAGER_AUTH,
    preview: GOOGLE_TAG_MANAGER_PREVIEW,
  });

export const GoogleTagManagerRouteChangeTracker = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: pathname,
      },
    });
  }, [pathname]);

  return null;
};
