import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { TranslationKeys } from 'app/translations';

import { OWNER, ROLE } from 'app/common/Authorization/entities';
import { EDIT, VIEW } from 'app/common/Authorization/permissions';

import { RestrictAccess, useBreadcrumb } from 'app/components';

import { UserManagementPage } from './UserManagementPage';
import RoleManagementPage from './RoleManagementPage';
import { UserEditPage } from './UserEditPage';

export const UserPages = () => {
  useBreadcrumb(TranslationKeys.menu_users);

  return (
    <Routes>
      <Route element={<RestrictAccess entity={OWNER} permission={VIEW} />}>
        <Route element={<UserManagementPage />} path={'overview'} />

        <Route path={':userId/*'} element={<RestrictAccess entity={OWNER} permission={EDIT} />}>
          <Route path="info" element={<UserEditPage />} />
          <Route index element={<Navigate to="info" replace />} />
          <Route path="*" element={<Navigate to="info" replace />} />
        </Route>

        <Route index element={<Navigate to="overview" replace />} />
        <Route path="*" element={<Navigate to="overview" replace />} />
      </Route>

      <Route element={<RestrictAccess entity={ROLE} permission={VIEW} />}>
        <Route element={<RoleManagementPage />} path="roles" />
      </Route>
    </Routes>
  );
};
