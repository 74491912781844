import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Skeleton, Typography } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';
import { getDisplayCurrencyValue, getDisplayDateValue, getDisplayUTCDateValue } from 'app/utils';

import type { EmployeeEmploymentWage } from 'app/types/employee.types';
import type { UseQueryRefetch } from 'app/types/useQuery.types';

import { useDialog } from 'app/hooks/useDialog';

import ActionButton from 'app/components/ActionButton';
import DetailsPanelRow from 'app/components/DetailsPanelRow';
import DetailsPanel from 'app/components/DetailsPanel';
import { useLocale } from 'app/components/Intl';

import { EmployeeDetailsPanelHeaderWithSyncToggle } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeDetailsPanelHeaderWithSyncToggle';
import { DivPanelBodyDetailsStyled } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/EmployeeEmploymentTab.styles';

import { EmployeeEmploymentWageFormDialog } from './components';
import { EmployeeEmploymentWageFields } from './EmployeeEmploymentWages.types';
import type { Employer } from 'app/types';

import { EMPLOYER, CONNECTION } from 'app/common/Authorization/entities';
import { EDIT, RUN } from 'app/common/Authorization/permissions';
import { HasAccessTo } from 'app/components';

type EmployeeEmploymentWagesProps = {
  loading: boolean;
  isRegularConnection: boolean;
  refetchGetEmploymentsQuery: UseQueryRefetch;
  connectionId: string | undefined;
  employeeId: string | undefined;
  employmentId: string | undefined;
  wages: Array<EmployeeEmploymentWage> | undefined;
  synchronizationStatus: boolean | undefined;
  refetchGetEmployeeSynchronizationQuery: UseQueryRefetch;
  disabledActionButtonsWhileLoading: boolean;
  employerId: Employer['employerId'] | undefined;
  employeeStartDate: string | undefined;
};

export const EmployeeEmploymentWages = ({
  connectionId,
  employeeId,
  employmentId,
  wages = [],
  refetchGetEmploymentsQuery,
  loading,
  isRegularConnection,
  synchronizationStatus,
  refetchGetEmployeeSynchronizationQuery,
  disabledActionButtonsWhileLoading,
  employerId,
  employeeStartDate,
}: EmployeeEmploymentWagesProps) => {
  const { locale } = useLocale();

  const {
    dialogState: wageFormDialogState,
    openDialog: openWageFormDialog,
    closeDialog: closeWageFormDialog,
  } = useDialog();

  const [expanded, setExpanded] = React.useState(wages.map(() => false));
  const [wageToEdit, setWageToEdit] = React.useState<EmployeeEmploymentWage | null>(null);
  const hasWages = wages.length > 0;
  const hasActiveSynchronization = synchronizationStatus !== undefined ? synchronizationStatus : false;
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);

  const shouldRenderEmployeeEmploymentWageFormDialog =
    !!employerId && !!connectionId && !!employeeId && !!employmentId && wageFormDialogState;

  const toggleWageDetailsPanel = React.useCallback(
    ({ id, expanded: newExpanded }) => {
      const newState = new Array(expanded.length).fill(false);
      newState[id] = !newExpanded;
      setExpanded(newState);
    },
    [expanded],
  );

  const onOpenWageFormDialog = React.useCallback(
    (wage?: EmployeeEmploymentWage) => {
      setWageToEdit(wage || null);
      openWageFormDialog();
    },
    [openWageFormDialog],
  );

  const onCloseWageFormDialog = React.useCallback(() => {
    setWageToEdit(null);
    closeWageFormDialog();
  }, [closeWageFormDialog]);

  const onClickAddButton = React.useCallback(() => {
    onOpenWageFormDialog();
  }, [onOpenWageFormDialog]);

  const onClickEditButton = React.useCallback(
    (wage: EmployeeEmploymentWage) => () => {
      onOpenWageFormDialog(wage);
    },
    [onOpenWageFormDialog],
  );

  const LoadingDetailsBody = React.useMemo(() => {
    return (
      <>
        {new Array(5).fill(null).map((_, idx) => (
          <Skeleton key={idx} height={24} />
        ))}
      </>
    );
  }, []);

  const NoWagesDetailsBody = React.useMemo(() => {
    return (
      <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKeys.employees_detail_wages_noWages} />
        </Typography>
      </Box>
    );
  }, []);

  const WagesDetailsBody = React.useMemo(() => {
    return (wages || []).map((wage, idx) => {
      const expandedIfOnlyOneWage = wages.length === 1;
      const isExpanded = typeof expanded[idx] !== 'undefined' ? expanded[idx] : false;

      const rows = {
        [EmployeeEmploymentWageFields.StartDate.Label]: getDisplayDateValue(wage?.startDate),
        [EmployeeEmploymentWageFields.EndDate.Label]: getDisplayUTCDateValue(wage?.endDate),
        [EmployeeEmploymentWageFields.GrossWage.Label]: getDisplayCurrencyValue({
          locale,
          value: wage?.grossWage,
          options: EmployeeEmploymentWageFields.GrossWage.Options,
        }),
        [EmployeeEmploymentWageFields.HourlyWage.Label]: getDisplayCurrencyValue({
          locale,
          value: wage?.hourlyWage,
          options: EmployeeEmploymentWageFields.HourlyWage.Options,
        }),
        [EmployeeEmploymentWageFields.NetWage.Label]: getDisplayCurrencyValue({
          locale,
          value: wage?.netWage,
          options: EmployeeEmploymentWageFields.NetWage.Options,
        }),
        [EmployeeEmploymentWageFields.SvWage.Label]: getDisplayCurrencyValue({
          locale,
          value: wage?.svWage,
          options: EmployeeEmploymentWageFields.SvWage.Options,
        }),
      };

      return (
        <div key={idx}>
          <DetailsPanelRow
            title={<Typography variant="h6">{format.date(wage.startDate)}</Typography>}
            expanded={expandedIfOnlyOneWage || isExpanded}
            rows={rows}
            loading={loading}
            id={idx}
            onExpand={toggleWageDetailsPanel}
            onEditButtonClick={isRegularConnection ? onClickEditButton(wage) : undefined}
            disabledEditButton={
              disabledActionButtonsWhileLoading ||
              hasActiveSynchronization ||
              disabledIfNoAccessToEmployerEdit ||
              disabledIfNoAccessToConnectionRun
            }
          />
        </div>
      );
    });
  }, [
    wages,
    expanded,
    locale,
    loading,
    toggleWageDetailsPanel,
    isRegularConnection,
    onClickEditButton,
    disabledActionButtonsWhileLoading,
    hasActiveSynchronization,
    disabledIfNoAccessToEmployerEdit,
    disabledIfNoAccessToConnectionRun,
  ]);

  const DetailsPanelBody = React.useMemo(() => {
    const finishedLoadingAndHasWages = !loading && hasWages;
    const finishedLoadingAndHasNoWages = !loading && !hasWages;

    return (
      <DivPanelBodyDetailsStyled isRegularConnection={isRegularConnection}>
        <>
          {loading && LoadingDetailsBody}
          {finishedLoadingAndHasWages && WagesDetailsBody}
          {finishedLoadingAndHasNoWages && NoWagesDetailsBody}
        </>
      </DivPanelBodyDetailsStyled>
    );
  }, [loading, hasWages, isRegularConnection, LoadingDetailsBody, WagesDetailsBody, NoWagesDetailsBody]);

  const DetailsPanelActions = React.useMemo(() => {
    return (
      <>
        <ActionButton
          messageId={TranslationKeys.button_employmentWages_add}
          variant="text"
          icon={faPlus}
          onClick={onClickAddButton}
          disabled={
            disabledActionButtonsWhileLoading ||
            hasActiveSynchronization ||
            disabledIfNoAccessToEmployerEdit ||
            disabledIfNoAccessToConnectionRun
          }
        />
      </>
    );
  }, [
    disabledActionButtonsWhileLoading,
    disabledIfNoAccessToEmployerEdit,
    hasActiveSynchronization,
    onClickAddButton,
    disabledIfNoAccessToConnectionRun,
  ]);

  const DetailsPanelHeader = React.useMemo(() => {
    return (
      <EmployeeDetailsPanelHeaderWithSyncToggle
        field={'wage'}
        loading={loading}
        synchronizationStatus={synchronizationStatus}
        refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
        disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
        messageId={TranslationKeys.employees_detail_wages}
        employeeId={employeeId}
        connectionId={connectionId}
      />
    );
  }, [
    loading,
    synchronizationStatus,
    refetchGetEmployeeSynchronizationQuery,
    disabledActionButtonsWhileLoading,
    employeeId,
    connectionId,
  ]);

  return (
    <>
      {shouldRenderEmployeeEmploymentWageFormDialog && (
        <EmployeeEmploymentWageFormDialog
          open={wageFormDialogState}
          closeDialog={onCloseWageFormDialog}
          employerId={employerId}
          connectionId={connectionId}
          employeeId={employeeId}
          employeeStartDate={employeeStartDate}
          employmentId={employmentId}
          wage={wageToEdit}
          refetchGetEmploymentsQuery={refetchGetEmploymentsQuery}
        />
      )}

      <DetailsPanel
        header={DetailsPanelHeader}
        body={DetailsPanelBody}
        actions={isRegularConnection ? DetailsPanelActions : undefined}
        scrollBody={true}
      />
    </>
  );
};
