import React from 'react';
import { FormattedMessage } from 'react-intl';

import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { Typography } from '@mui/material';

import formatter from '../../../../../../utils/formatter';

import type { EmployeeSynchronization } from '../../../../../../types/employee.types';

import { TranslationKeys } from '../../../../../../translations';

import { Tooltip } from '../../../../../../components/Tooltip';

import {
  DivNameContainerStyled,
  DivTooltipTitleContainerStyled,
  TypographyListHeaderStyled,
} from './EmployeeTableNameWithDisabledSync.styles';

type EmployeeTableNameWithDisabledSyncProps = {
  name: React.ReactNode;
  syncStatus: EmployeeSynchronization['status'];
};

export const EmployeeTableNameWithDisabledSync = ({ name, syncStatus }: EmployeeTableNameWithDisabledSyncProps) => {
  const tooltipTitle = React.useMemo(
    () => (
      <DivTooltipTitleContainerStyled>
        <Typography>
          <FormattedMessage id={TranslationKeys.employees_synchronization_disabled_infoText} />
        </Typography>

        <TypographyListHeaderStyled>
          <FormattedMessage id={TranslationKeys.employees_synchronizationStatus} />
        </TypographyListHeaderStyled>

        <ul>
          <li>
            <Typography>
              <FormattedMessage id={TranslationKeys.employees_detail_personal} />
            </Typography>
            <Typography>{formatter.yesNo(syncStatus.employee)}</Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage id={TranslationKeys.employees_detail_partner_title} />
            </Typography>
            <Typography>{formatter.yesNo(syncStatus.partner)}</Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage id={TranslationKeys.employees_detail_children_title} />
            </Typography>
            <Typography>{formatter.yesNo(syncStatus.child)}</Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage id={TranslationKeys.employees_detail_employment} />
            </Typography>
            <Typography>{formatter.yesNo(syncStatus.employment)}</Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage id={TranslationKeys.employees_detail_wages} />
            </Typography>
            <Typography>{formatter.yesNo(syncStatus.wage)}</Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage id={TranslationKeys.employees_detail_schedules} />
            </Typography>
            <Typography>{formatter.yesNo(syncStatus.schedule)}</Typography>
          </li>
        </ul>
      </DivTooltipTitleContainerStyled>
    ),
    [
      syncStatus.child,
      syncStatus.employee,
      syncStatus.employment,
      syncStatus.partner,
      syncStatus.schedule,
      syncStatus.wage,
    ],
  );

  return (
    <DivNameContainerStyled>
      {name}
      <Tooltip title={tooltipTitle}>
        <SyncDisabledIcon fontSize={'small'} />
      </Tooltip>
    </DivNameContainerStyled>
  );
};
