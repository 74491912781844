import React from 'react';
import { useIntl } from 'react-intl';

import { TranslationKeys } from '../translations';

import { YesNoEnum } from '../types/global.types';

export const useYesOrNoSelectItems = () => {
  const intl = useIntl();

  const yesOrNoSelectItems = React.useMemo(() => {
    return [
      {
        element: intl.formatMessage({ id: TranslationKeys.yes }),
        value: YesNoEnum.Yes,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.no }),
        value: YesNoEnum.No,
      },
    ];
  }, [intl]);

  const getYesOrNoDisplayNameByValue = React.useCallback(
    (value: YesNoEnum) => {
      return yesOrNoSelectItems.find(item => item.value === value)?.element;
    },
    [yesOrNoSelectItems],
  );

  return React.useMemo(
    () => ({ yesOrNoSelectItems, getYesOrNoDisplayNameByValue }),
    [yesOrNoSelectItems, getYesOrNoDisplayNameByValue],
  );
};
