import { styled } from '@mui/material/styles';
import { TableCell, TableRow } from '@mui/material';

export const TableRowStyled = styled(TableRow, {
  name: 'TableRowStyled',
  shouldForwardProp: (propName: string) => propName !== 'inactive',
})<{ inactive: boolean }>(({ inactive }) => {
  if (inactive) {
    return {
      opacity: 0.6,
    };
  }
  return {};
});

export const TableCellStyled = styled(TableCell, {
  name: 'TableCellStyled',
  shouldForwardProp: (propName: string) => !['isFirstColumnAndHasChildren'].includes(propName),
})<{ isFirstColumnAndHasChildren: boolean }>(({ isFirstColumnAndHasChildren }) => {
  if (isFirstColumnAndHasChildren) {
    return {
      paddingLeft: 0,
    };
  }
  return {};
});

export const ChildTableCellStyled = styled(TableCell, {
  name: 'ChildTableCellStyled',
  shouldForwardProp: (propName: string) => !['isFirstColumn'].includes(propName),
})<{ isFirstColumn: boolean }>(({ theme, isFirstColumn }) => {
  if (isFirstColumn) {
    return {
      paddingLeft: theme.spacing(7),
    };
  }
  return {};
});
