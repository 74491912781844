import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { OwnerInsurer, UseQueryWrapper } from 'app/types';

import { useSnakeBar } from 'app/hooks/useSnakeBar';

import type { GetOwnerInsurersArgs } from 'app/Domain/Insurers/Services/InsurerService/insurerService';
import insurerService from 'app/Domain/Insurers/Services/InsurerService/insurerService';

export type GetOwnersInsurersQueryVariables = GetOwnerInsurersArgs;

type GetOwnerInsurersQueryResultData = {
  data: Array<OwnerInsurer>;
  totalCount: number;
};

const queryFn = (variables: GetOwnersInsurersQueryVariables) => async () => {
  const response = await insurerService.getOwnerInsurers(variables);
  return response.data;
};

const QUERY_KEY = 'getOwnerInsurers';

export const useOwnerGetOwnerInsurersQuery: UseQueryWrapper<
  GetOwnerInsurersQueryResultData,
  GetOwnersInsurersQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetOwnerInsurersQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
