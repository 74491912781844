import React from 'react';

import { Grid, Typography } from '@mui/material';

import { DivContentStyled, DivRootStyled, ImgStyled } from './NotFoundView.styles';

export const NotFoundView = () => {
  return (
    <DivRootStyled>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <DivContentStyled>
            <Typography variant="h1">{'404: The page you are looking for isn’t here'}</Typography>
            <Typography variant="subtitle2">
              {
                'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation'
              }
            </Typography>

            <ImgStyled alt="Under development" src="/images/undraw_page_not_found_su7k.svg" />
          </DivContentStyled>
        </Grid>
      </Grid>
    </DivRootStyled>
  );
};
