import { styled } from '@mui/material/styles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { TableCell } from '@mui/material';

export const ArrowDropUpStyled = styled(ArrowDropUp, {
  name: 'ArrowDropUpStyled',
  shouldForwardProp: (propName: string) => propName !== 'ascending',
})<{ ascending: boolean }>(({ theme, ascending }) => ({
  opacity: 0.2,
  color: theme.palette.primary.dark,
  marginBottom: '-8px',
  ...(ascending ? { opacity: 0.6, marginBottom: '2px' } : {}),
}));

export const ArrowDropDownStyled = styled(ArrowDropDown, {
  name: 'ArrowDropDownStyled',
  shouldForwardProp: (propName: string) => propName !== 'descending',
})<{ descending: boolean }>(({ theme, descending }) => ({
  opacity: 0.2,
  color: theme.palette.primary.dark,
  marginTop: '-8px',
  ...(descending ? { opacity: 0.6, marginTop: '2px' } : {}),
}));

export const TableCellStyled = styled(TableCell, {
  name: 'TableCellStyled',
  shouldForwardProp: (propName: string) => !['sortable', 'disabled'].includes(propName),
})<{ sortable: boolean; disabled: boolean }>(({ sortable, disabled }) => ({
  cursor: !sortable || disabled ? 'unset' : 'pointer',
  ...(disabled ? { opacity: 0.7 } : {}),
}));

export const DivLabelContainerStyled = styled('div', {
  name: 'DivLabelContainerStyled',
  shouldForwardProp: (propName: string) => !['sortable', 'active', 'textAlign'].includes(propName),
})<{ sortable: boolean; active: boolean; textAlign?: 'left' | 'center' | 'right' }>(
  ({ theme, sortable, active, textAlign = 'left' }) => {
    const alignment = {
      left: 'flex-start',
      center: 'center',
      right: 'flex-end',
    };

    return {
      display: 'flex',
      height: theme.spacing(3),
      justifyContent: alignment[textAlign],
      ...(active ? { color: theme.palette.text.primary } : {}),
      ...(sortable
        ? {
            '&:hover': {
              color: theme.palette.primary.main,
              '& span': {
                opacity: 1,
                transition: 'all 0.1s ease-in-out',
                '& svg': {
                  color: theme.palette.primary.main,
                },
              },
            },
          }
        : {}),
    };
  },
);

export const SpanArrowsStyled = styled('span', {
  name: 'SpanArrowsStyled',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '4px',
}));

export const DivLabelStyled = styled('div', {
  name: 'DivLabelStyled',
})(() => ({
  alignSelf: 'center',
  display: 'inline-block',
  userSelect: 'none',
}));
