import { styled } from '@mui/material/styles';

export const ApiKeyDialogBodyStyled = styled('div', {
  name: 'ApiKeyDialogBodyStyled',
})(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

export const ApiKeyDialogTextStyled = styled('div', {
  name: 'ApiKeyDialogTextStyled',
})(({ theme }) => ({
  padding: theme.spacing(2),
  overflowX: 'scroll',
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  whiteSpace: 'nowrap',
}));
