import { Grid } from '@mui/material';
import type { UseQueryRefetch } from '../../../../types';
import { RegulationsPanel } from '../RegulationsPanel';
import React from 'react';
import type { RegulationsFormGroup, RegulationsFromGroupKeys } from '../RegulationsPanelForm';
import type { GetRegulationsQueryResultData } from '../../../../hooks';

type PensionRegulationsProps = {
  loading: boolean;
  ownerId: string;
  employerId?: string;
  product: any;
  editing: boolean;
  isEmployer: boolean;
  refetchRegulationsQuery: UseQueryRefetch;
  withRegulationsEmployerScales?: boolean;
  regulations?: GetRegulationsQueryResultData;
  header: any;
  groups: RegulationsFormGroup;
};

export const IncomeRegulations = ({ loading, editing, isEmployer, header, groups }: PensionRegulationsProps) => {
  const keys = Object.keys(groups) as Array<RegulationsFromGroupKeys>;
  for (let i = 0; i < keys.length; i++) {
    groups[keys[i]].sort((a, b) => (a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0));
  }

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <RegulationsPanel
          header={isEmployer ? header : undefined}
          loading={loading}
          regulations={groups}
          isEmployer={isEmployer}
          editing={editing}
        />
      </Grid>
    </Grid>
  );
};
