import { alpha } from '@mui/material/styles';
import { tableRowClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiTableRow: FuncOverrideComponent<'MuiTableRow'> = theme => ({
  styleOverrides: {
    root: {
      '&:nth-of-type(odd):not(:first)': {
        backgroundColor: theme.palette.common.white,
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#F6FBFD',
      },
      '&:last-child': {
        borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
      },
      [`&.${tableRowClasses.hover}:hover`]: {
        backgroundColor: alpha(theme.palette.secondary.light, 0.7),
      },
    },
  },
});
