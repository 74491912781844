import React from 'react';

import type { GridSize } from '@mui/material';
import { Box, Grid } from '@mui/material';

import type { Field } from 'app/types';
import { buildInputFields } from '../FormikField';
import ActionButton from '../ActionButton';

import { GridStyled, PaperBackgroundStyled, SearchInputFieldFactoryStyled } from './Filter.styles';

export type FilterProps = {
  onChange: (args: {
    event?: React.ChangeEvent<HTMLInputElement>;
    name: string;
    value: number | string | boolean | null;
  }) => void;
  filters: Array<Field>;
  variant?: string;
  extraFiltersContent?: React.ReactNode;
};

export const Filter = ({ onChange, filters, variant, extraFiltersContent }: FilterProps) => {
  const [key, setKey] = React.useState(Math.random() * 100000);

  const searchFilter = filters.filter(f => f?.type === 'search').slice(-1)[0];
  const actionButtons = filters.filter(f => f?.type === 'action');

  const onClickResetButton = React.useCallback(() => {
    onChange({ name: 'reset', value: null });
    setKey(Math.random() * 100000);
  }, [onChange]);

  const filterElements = React.useMemo(() => {
    const dataFilters = filters.filter(filter => !['search', 'reset', 'action'].includes(filter?.type || ''));
    const resetFilter = filters.find(filter => filter?.type === 'reset');
    const filterInputs: Array<React.ReactElement> = buildInputFields(dataFilters, onChange, key);

    if (resetFilter) {
      filterInputs.push(
        <ActionButton
          key={filterInputs.length}
          messageId={resetFilter?.label || 'reset'}
          onClick={onClickResetButton}
          variant={'text'}
        />,
      );
    }

    return filterInputs;
  }, [filters, onChange, onClickResetButton, key]);

  const onChangeSearchValue = React.useCallback(
    event => {
      onChange({ event, name: searchFilter?.name || 'search', value: event.target.value });
    },
    [onChange, searchFilter?.name],
  );

  const SearchInput = React.useMemo(() => {
    if (!searchFilter) {
      return null;
    }
    return <SearchInputFieldFactoryStyled field={searchFilter} onChange={onChangeSearchValue} />;
  }, [onChangeSearchValue, searchFilter]);

  const filterGridSizeXs = 12;
  const filterGridSizeMd = filterElements.length > 2 ? 12 : 6;
  const filterGridSizeLg = filterElements.length > 0 ? Math.floor(3 * Math.min(2, filterElements.length)) : 0;
  const filterGridSizeXl = filterElements.length <= 2 ? 3 : filterElements.length <= 3 ? 6 : 8;

  return (
    <>
      {filterElements.length > 0 && (
        <Grid item xl={filterGridSizeXl} lg={filterGridSizeLg as GridSize} md={filterGridSizeMd} xs={filterGridSizeXs}>
          <PaperBackgroundStyled outlined={variant === 'outlined'}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {filterElements.map((element, index) => (
                <Box key={index} sx={{ p: 0.5, minWidth: '160px' }}>
                  {element}
                </Box>
              ))}
            </Box>
          </PaperBackgroundStyled>
        </Grid>
      )}
      {(searchFilter || actionButtons.length || extraFiltersContent) && (
        <GridStyled
          item
          xl={(12 - filterGridSizeXl) as GridSize}
          lg={(12 - filterGridSizeLg) as GridSize}
          md={6}
          xs={12}
        >
          {searchFilter && SearchInput}

          {actionButtons.length > 0 && (
            <Box marginTop={1} marginRight={1} marginBottom={1}>
              {actionButtons.map((actionButton, index) => (
                <ActionButton
                  key={index}
                  sx={{ m: 0.5 }}
                  messageId={actionButton?.label ?? 'action'}
                  onClick={() => {
                    onChange({ name: actionButton.name ?? 'action', value: true });
                  }}
                  disabled={actionButton?.disabled || false}
                />
              ))}
            </Box>
          )}

          {extraFiltersContent && extraFiltersContent}
        </GridStyled>
      )}
    </>
  );
};
