import React from 'react';

import { styled } from '@mui/material/styles';

export const DASHBOARD_CATEGORIES_CONTAINER_DATA_TEST_ID = 'dashboard-categories-container';

export const DashboardCategoriesContainerStyled = styled(
  (props: React.HTMLProps<HTMLDivElement>) => (
    <div data-testid={DASHBOARD_CATEGORIES_CONTAINER_DATA_TEST_ID} {...props} />
  ),
  {
    name: 'DashboardCategoriesContainerStyled',
  },
)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: theme.spacing(3),
}));
