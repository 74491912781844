import { alpha, styled } from '@mui/material/styles';

export const DivNoDataDetailsBodyStyled = styled('div', {
  name: 'DivNoDataDetailsBodyStyled',
})(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const DivPanelBodyDetailsStyled = styled('div', {
  name: 'DivPanelBodyDetailsStyled',
})(({ theme }) => ({
  width: '100%',
  height: '18em',
  '& $element:not(:last-child)': {
    borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
  },
}));

export const SpanConfirmDialogBodyContainerStyled = styled('span', {
  name: 'SpanConfirmDialogBodyContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& span': {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.primary.main,
  },
}));
