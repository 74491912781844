import { styled } from '@mui/material/styles';
import { Drawer, drawerClasses } from '@mui/material';

export const DrawerStyled = styled(Drawer, {
  name: 'DrawerStyled',
  shouldForwardProp: (propName: string) => !['zIndex'].includes(propName),
})<{ zIndex: boolean }>(({ theme, zIndex }) => ({
  ...(zIndex ? { zIndex: 1500 } : {}),
  [`& .${drawerClasses.paper}`]: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 60,
      height: 'calc(100% - 60px)',
    },
  },
}));

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));
