import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import crossBrowserListener from '../utils/reduxpersist-listener';
import { persistConfig } from '../Domain/Credentials/Ducks/Credentials.duck';
import { rootReducer, rootSaga } from './root.duck';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

export const persistor = persistStore(store);

window.addEventListener('storage', crossBrowserListener(persistConfig));

sagaMiddleware.run(rootSaga);

export default store;
