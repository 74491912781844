import { styled } from '@mui/material/styles';
import { Divider, FormLabel, Grid, outlinedInputClasses, TextField } from '@mui/material';

export const GridItemStyled = styled(Grid, {
  name: 'GridItemStyled',
})(() => ({
  minHeight: '75px',
}));

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textTransform: 'uppercase',
}));

export const TextFieldStyled = styled(TextField, {
  name: 'TextFieldStyled',
  shouldForwardProp: (propName: string) => propName !== 'small',
})<{ small?: boolean }>(({ theme, small = false }) => ({
  display: 'block',
  ...(small ? { width: '25%' } : {}),
  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1.5),
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: '1px solid ' + theme.palette.secondary.main,
  },
}));

export const DividerStyled = styled(Divider, {
  name: 'DividerStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  margin: theme.spacing(1),
  width: '100%',
}));
