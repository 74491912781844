import { styled } from '@mui/material/styles';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const DivContentStyled = styled('div', {
  name: 'DivContentStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const DivPaginationStyled = styled('div', {
  name: 'DivPaginationStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));
