import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

import { Grid, Typography } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';

import type { GenderStatusEnum } from 'app/types';

import { useDialog, useGenderSelectItems } from 'app/hooks';

import { InputFieldFactory } from 'app/components/FormikField';
import type { GetInputOptionsAndLabelFunc, GetSelectOrAutocompleteDisplayValueFunc } from 'app/components';
import { FormikModal, SubmitConfirmationDialog } from 'app/components';

import type { PartnerDetailsPanelFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/components/PartnerDetailsPanel/PartnerDetailsPanel.types';
import {
  PartnerDetailsPanelFieldOptionsAndLabel,
  PartnerDetailsPanelFields,
} from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/components/PartnerDetailsPanel/PartnerDetailsPanel.types';

type PartnerFormDialogBodyProps = {
  open: boolean;
  isEdit: boolean;
  closeDialog: () => void;
};

export const PartnerFormDialogBody = ({ open, isEdit, closeDialog }: PartnerFormDialogBodyProps) => {
  const { initialValues, values, submitForm, resetForm } = useFormikContext<PartnerDetailsPanelFormValues>();
  const { genderSelectItems, getGenderDisplayNameByValue } = useGenderSelectItems();

  const {
    dialogState: submitConfirmationDialogState,
    openDialog: openSubmitConfirmationDialog,
    closeDialog: closeSubmitConfirmationDialog,
  } = useDialog();

  const onClickSubmit = React.useCallback(() => {
    const hasChangedValues = JSON.stringify(initialValues) !== JSON.stringify(values);

    if (!isEdit) {
      submitForm();
    } else if (hasChangedValues) {
      openSubmitConfirmationDialog();
    } else {
      closeDialog();
    }
  }, [closeDialog, initialValues, isEdit, openSubmitConfirmationDialog, submitForm, values]);

  const submitFormAfterConfirmation = React.useCallback(() => {
    closeSubmitConfirmationDialog();
    submitForm();
  }, [closeSubmitConfirmationDialog, submitForm]);

  const getInputOptionsAndLabel = React.useCallback<GetInputOptionsAndLabelFunc>(inputName => {
    return PartnerDetailsPanelFieldOptionsAndLabel[inputName];
  }, []);

  const getSelectOrAutocompleteDisplayValue = React.useCallback<GetSelectOrAutocompleteDisplayValueFunc>(
    ({ inputName, inputValue }) => {
      if (inputName === PartnerDetailsPanelFields.Gender.InputName) {
        return getGenderDisplayNameByValue(inputValue as GenderStatusEnum) || '';
      }
      return '';
    },
    [getGenderDisplayNameByValue],
  );

  return (
    <>
      {submitConfirmationDialogState && (
        <SubmitConfirmationDialog
          open={submitConfirmationDialogState}
          onClose={closeSubmitConfirmationDialog}
          onConfirm={submitFormAfterConfirmation}
          previousValues={initialValues}
          newValues={values}
          getSelectOrAutocompleteDisplayValue={getSelectOrAutocompleteDisplayValue}
          getInputOptionsAndLabel={getInputOptionsAndLabel}
        />
      )}

      {!submitConfirmationDialogState && (
        <FormikModal
          title={
            <FormattedMessage
              id={isEdit ? TranslationKeys.employees_actions_editPartner : TranslationKeys.employees_actions_addPartner}
            />
          }
          show={open}
          onSubmit={onClickSubmit}
          onCancel={isEdit ? closeDialog : undefined}
          onHide={() => {
            closeDialog();
            resetForm();
          }}
          disableEnforceFocus
        >
          {isEdit && (
            <Typography variant="h6" paragraph>
              {format.name(initialValues.personName)}
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.PersonName.Props.Initials.Label,
                  name: PartnerDetailsPanelFields.PersonName.Props.Initials.InputName,
                  required: true,
                  schema: (schema: any) => schema.trim().max(255),
                  options: PartnerDetailsPanelFields.PersonName.Props.Initials.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.PersonName.Props.FirstName.Label,
                  name: PartnerDetailsPanelFields.PersonName.Props.FirstName.InputName,
                  required: true,
                  schema: (schema: any) => schema.trim().max(255),
                  options: PartnerDetailsPanelFields.PersonName.Props.FirstName.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.PersonName.Props.LastNamePrefix.Label,
                  name: PartnerDetailsPanelFields.PersonName.Props.LastNamePrefix.InputName,
                  schema: (schema: any) => schema.trim().max(12),
                  options: PartnerDetailsPanelFields.PersonName.Props.LastNamePrefix.Options,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.PersonName.Props.LastName.Label,
                  name: PartnerDetailsPanelFields.PersonName.Props.LastName.InputName,
                  required: true,
                  schema: (schema: any) => schema.trim().max(255),
                  options: PartnerDetailsPanelFields.PersonName.Props.LastName.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.SocialSecurityNumber.Label,
                  name: PartnerDetailsPanelFields.SocialSecurityNumber.InputName,
                  schema: (schema: any) => schema.trim().max(12),
                  options: PartnerDetailsPanelFields.SocialSecurityNumber.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.Gender.Label,
                  name: PartnerDetailsPanelFields.Gender.InputName,
                  type: 'select',
                  required: true,
                  options: PartnerDetailsPanelFields.Gender.Options,
                  items: genderSelectItems,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.DateOfBirth.Label,
                  name: PartnerDetailsPanelFields.DateOfBirth.InputName,
                  type: 'date',
                  required: true,
                  options: PartnerDetailsPanelFields.DateOfBirth.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.StartOfRelationship.Label,
                  name: PartnerDetailsPanelFields.StartOfRelationship.InputName,
                  type: 'date',
                  required: true,
                  options: PartnerDetailsPanelFields.StartOfRelationship.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.EndOfRelationship.Label,
                  name: PartnerDetailsPanelFields.EndOfRelationship.InputName,
                  type: 'date',
                  options: PartnerDetailsPanelFields.EndOfRelationship.Options,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <InputFieldFactory
                field={{
                  header: PartnerDetailsPanelFields.ExtraPartnerPensionByStart.Label,
                  name: PartnerDetailsPanelFields.ExtraPartnerPensionByStart.InputName,
                  required: false,
                  options: PartnerDetailsPanelFields.ExtraPartnerPensionByStart.Options,
                }}
              />
            </Grid>
          </Grid>
        </FormikModal>
      )}
    </>
  );
};
