import React from 'react';

import { TranslationKeys } from '../../../../../translations';

import { ReportTypeEnum } from '../../../../../types';

import { InputFieldFactoryStyled, PaperBackgroundStyled } from './ReportSelect.styles';
import { ReportSelectItemElement } from './ReportSelectItemElement';

type ReportSelectProps = {
  onChangeReportType: (reportType: ReportTypeEnum) => void;
  defaultSelectedReportType: ReportTypeEnum;
  disabledActionsWhileLoading?: boolean;
};

export const ReportSelect = ({
  onChangeReportType,
  defaultSelectedReportType,
  disabledActionsWhileLoading = false,
}: ReportSelectProps) => {
  const reportTypeSelectItems = React.useMemo(() => {
    return [
      {
        value: ReportTypeEnum.EmployeeInformation,
        default: ReportTypeEnum.EmployeeInformation === defaultSelectedReportType,
        element: (
          <ReportSelectItemElement
            titleMessageId={TranslationKeys.reports_type_employeeInfo_title}
            descriptionMessageId={TranslationKeys.reports_type_employeeInfo_description}
          />
        ),
      },
      {
        value: ReportTypeEnum.ActiveEmployeeCount,
        default: ReportTypeEnum.ActiveEmployeeCount === defaultSelectedReportType,
        element: (
          <ReportSelectItemElement
            titleMessageId={TranslationKeys.reports_type_activeEmployeeCount_title}
            descriptionMessageId={TranslationKeys.reports_type_activeEmployeeCount_description}
          />
        ),
      },
      {
        value: ReportTypeEnum.SummaryOfRegulationDeviations,
        default: ReportTypeEnum.SummaryOfRegulationDeviations === defaultSelectedReportType,
        element: (
          <ReportSelectItemElement
            titleMessageId={TranslationKeys.reports_type_summaryOfRegulationDeviations_title}
            descriptionMessageId={TranslationKeys.reports_type_summaryOfRegulationDeviations_description}
          />
        ),
      },
      {
        value: ReportTypeEnum.InterruptedSynchronization,
        default: ReportTypeEnum.InterruptedSynchronization === defaultSelectedReportType,
        element: (
          <ReportSelectItemElement
            titleMessageId={TranslationKeys.reports_type_interruptedSynchronization_title}
            descriptionMessageId={TranslationKeys.reports_type_interruptedSynchronization_description}
          />
        ),
      },
      {
        value: ReportTypeEnum.LatestConnectionRun,
        default: ReportTypeEnum.LatestConnectionRun === defaultSelectedReportType,
        element: (
          <ReportSelectItemElement
            titleMessageId={TranslationKeys.reports_type_latestConnectionRun_title}
            descriptionMessageId={TranslationKeys.reports_type_latestConnectionRun_description}
          />
        ),
      },
      {
        value: ReportTypeEnum.CollectiveSalaryChange,
        default: ReportTypeEnum.CollectiveSalaryChange === defaultSelectedReportType,
        element: (
          <ReportSelectItemElement
            titleMessageId={TranslationKeys.reports_type_collectiveSalaryChange_title}
            descriptionMessageId={TranslationKeys.reports_type_collectiveSalaryChange_description}
          />
        ),
      },
    ];
  }, [defaultSelectedReportType]);

  const handleOnChangeReportType = React.useCallback(
    ({ value }: { value: ReportTypeEnum }) => {
      onChangeReportType(value);
    },
    [onChangeReportType],
  );

  return (
    <PaperBackgroundStyled>
      <InputFieldFactoryStyled
        onChange={handleOnChangeReportType}
        disabled={disabledActionsWhileLoading}
        field={{
          type: 'select',
          name: 'reportType',
          header: TranslationKeys.reports_selectReportType,
          fullWidth: false,
          items: reportTypeSelectItems,
        }}
      />
    </PaperBackgroundStyled>
  );
};
