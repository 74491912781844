import { alpha, styled } from '@mui/material/styles';
import { Alert } from '@mui/material';

export const AlertStyled = styled(Alert, {
  name: 'AlertStyled',
})(({ theme }) => ({
  backgroundColor: alpha(theme.palette.error.dark, 0.2),

  '& svg': {
    color: theme.palette.error.main,
  },
}));

export const FormErrorList = styled('ul', {
  name: 'FormErrorList',
})(() => ({
  listStyle: 'none',
}));

export const FormErrorListItem = styled('li', {
  name: 'FormErrorListItem',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  marginBottom: '2px',
  '&::before': {
    width: '1em',
    content: '"-"',
    color: theme.palette.error.dark,
  },
}));
