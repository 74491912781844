import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import { SearchInput } from '../../../../components';

export const DivRowStyled = styled('div', {
  name: 'DivRowStyled',
})(({ theme }) => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

export const SpanSpacerStyled = styled('span', {
  name: 'SpanSpacerStyled',
})(() => ({
  flexGrow: 1,
}));

export const ButtonStyled = styled(Button, {
  name: 'ButtonStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const SearchInputStyled = styled(SearchInput, {
  name: 'SearchInputStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
