import { ActionButton, InputFieldFactory, SearchInput } from '../../../../../../components';
import { UNPROCESSED_TABLE_FILTERS_FIELD_NAMES } from '../../../../Components';
import { TranslationKeys } from '../../../../../../translations';
import React from 'react';
import type { ProductPensionSchemeEnum } from '../../../../../../types';
import type { EventTypeEnum } from '../../../../../../types/eventType.type';
import { useIntl } from 'react-intl';
import { useEventTypeSelectItems, useProductPensionSchemeSelectItems } from '../../../../../../hooks';
import { Grid, Stack } from '@mui/material';
import { useEventFlowStatusSelectItems } from '../../../../../../hooks/useEventFlowStatusSelectItems';

type EventFlowParamsFilterTypes = {
  status?: string;
  subOwners?: Array<string>;
  productPensionScheme?: ProductPensionSchemeEnum;
  eventType?: EventTypeEnum;
  employee?: string;
  ibUser?: string;
  ibInsurer?: string;
  ibEmployer?: string;
  eventDateBefore?: string;
  eventDateAfter?: string;
};

type EventFlowFilterTypes = {
  filterKey: string;
  filters: EventFlowParamsFilterTypes;
  isLoading: boolean;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterChange: (elm: any) => void;
  onClickResetButtonHandler: () => void;
  searchQuery: string;
  usersAsOptions: Array<{ value?: string; element?: string; default?: boolean }> | Array<[]>;
  insurersAsOptions: Array<{ value?: string; element?: string; default?: boolean }> | Array<[]>;
  productsAsOptions: Array<{ value?: string; element?: string; default?: boolean }> | Array<[]>;
  downloadAllEvents: () => void;
  noOptionsText?: string;
};

const EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES = {
  Status: 'status',
  EventType: 'eventType',
  IbUser: 'ibUser',
  IbEmployer: 'ibEmployer',
  IbInsurer: 'ibInsurer',
  IbProduct: 'ibProduct',
  EventDateAfter: 'eventDateAfter',
  EventDateBefore: 'eventDateBefore',
} as const;

export const EventFlowTableFilters = ({
  filterKey,
  filters,
  isLoading,
  onSearchChange,
  onFilterChange,
  onClickResetButtonHandler,
  searchQuery,
  usersAsOptions,
  insurersAsOptions,
  downloadAllEvents,
  productsAsOptions,
  noOptionsText,
}: EventFlowFilterTypes) => {
  const intl = useIntl();
  const { eventFlowStatusSelectItems } = useEventFlowStatusSelectItems();
  const { productPensionSchemeSelectItems } = useProductPensionSchemeSelectItems();
  const { eventTypeSelectItems } = useEventTypeSelectItems();
  const eventStatusSelectItemsWithAllOption = React.useMemo(() => {
    return [
      {
        value: 'all',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...eventFlowStatusSelectItems,
    ];
  }, [intl, eventFlowStatusSelectItems]);

  const productPensionSchemeSelectItemsWithAllOption = React.useMemo(() => {
    return [
      {
        value: '',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...productPensionSchemeSelectItems,
    ];
  }, [intl, productPensionSchemeSelectItems]);

  const eventTypeSelectItemsWithAllOption = React.useMemo(() => {
    return [
      {
        value: '',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...eventTypeSelectItems,
    ];
  }, [intl, eventTypeSelectItems]);

  return (
    <Grid container sx={{ my: theme => theme.spacing(2) }}>
      <Grid item xs={12} md={6}>
        <Stack direction={{ xs: 'column', md: 'row' }} sx={{ gap: theme => theme.spacing(2) }} flexWrap="wrap">
          <InputFieldFactory
            key={`product-status-${filterKey}`}
            disabled={isLoading}
            onChange={onFilterChange}
            field={{
              type: 'select',
              name: 'status',
              label: intl.formatMessage({ id: TranslationKeys.global_status }),
              fullWidth: true,
              value: filters.status || null,
              items: eventStatusSelectItemsWithAllOption,
            }}
          />

          <InputFieldFactory
            key={`product-pension-scheme-${filterKey}`}
            disabled={isLoading}
            onChange={onFilterChange}
            field={{
              type: 'select',
              name: UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
              label: intl.formatMessage({ id: TranslationKeys.global_pensionScheme }),
              fullWidth: true,
              value: filters.productPensionScheme || null,
              items: productPensionSchemeSelectItemsWithAllOption,
            }}
          />

          <InputFieldFactory
            key={`event-type-${filterKey}`}
            disabled={isLoading}
            onChange={onFilterChange}
            field={{
              type: 'select',
              name: EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.EventType,
              label: intl.formatMessage({ id: TranslationKeys.global_eventType }),
              fullWidth: true,
              value: filters.eventType || null,
              items: eventTypeSelectItemsWithAllOption,
            }}
          />

          <InputFieldFactory
            key={`ib-employer-${filterKey}`}
            disabled={isLoading}
            onChange={onFilterChange}
            noOptionsText={noOptionsText}
            field={{
              type: 'autocompleteWithSearch',
              name: EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.IbEmployer,
              label: intl.formatMessage({ id: TranslationKeys.global_employer }),
              fullWidth: true,
              value: filters.ibEmployer || null,
              optionsEntity: 'employer',
            }}
          />

          <InputFieldFactory
            key={`ib-user-${filterKey}`}
            disabled={isLoading}
            onChange={onFilterChange}
            field={{
              type: 'select',
              name: EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.IbUser,
              label: intl.formatMessage({ id: TranslationKeys.global_userIB }),
              fullWidth: true,
              value: filters.ibUser || null,
              items: usersAsOptions,
            }}
          />

          <InputFieldFactory
            key={`ib-insurer-${filterKey}`}
            disabled={isLoading}
            onChange={onFilterChange}
            field={{
              type: 'select',
              name: EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.IbInsurer,
              label: intl.formatMessage({ id: TranslationKeys.global_insurer }),
              fullWidth: true,
              value: filters.ibInsurer || null,
              items: insurersAsOptions,
            }}
          />

          <InputFieldFactory
            key={`ib-product-${filterKey}`}
            disabled={isLoading || productsAsOptions.length === 0}
            onChange={onFilterChange}
            field={{
              type: 'select',
              name: EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.IbProduct,
              label: intl.formatMessage({ id: TranslationKeys.global_product }),
              fullWidth: true,
              value: filters.eventDateAfter || null,
              debounceTime: 3000,
              required: false,
              validate: false,
              items: productsAsOptions,
            }}
          />

          <InputFieldFactory
            key={`ib-event-date-after-${filterKey}`}
            disabled={isLoading}
            onChange={onFilterChange}
            field={{
              type: 'date',
              name: EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.EventDateAfter,
              label: intl.formatMessage({ id: TranslationKeys.global_dateFrom }),
              fullWidth: true,
              value: filters.eventDateAfter || null,
              debounceTime: 3000,
              required: false,
              validate: false,
            }}
          />

          <InputFieldFactory
            key={`ib-event-date-before-${filterKey}`}
            disabled={isLoading}
            onChange={onFilterChange}
            field={{
              type: 'date',
              name: EVENT_FLOW_TABLE_FILTERS_FIELD_NAMES.EventDateBefore,
              label: intl.formatMessage({ id: TranslationKeys.global_dateTo }),
              fullWidth: true,
              value: filters.eventDateBefore || null,
              debounceTime: 3000,
              required: false,
              validate: false,
            }}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
          sx={{ mb: theme => theme.spacing(2) }}
        >
          <ActionButton
            disabled={isLoading}
            onClick={downloadAllEvents}
            messageId={TranslationKeys.global_downloadAll}
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
          sx={{ gap: theme => theme.spacing(2) }}
          flexWrap="wrap"
        >
          <SearchInput
            key={`search-${filterKey}`}
            disabled={isLoading}
            initialSearchQuery={searchQuery}
            onChange={onSearchChange}
            debounceTime={1000}
          />

          <ActionButton
            disabled={isLoading}
            messageId={TranslationKeys.global_reset}
            onClick={onClickResetButtonHandler}
            variant={'text'}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
