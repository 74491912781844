import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { TranslationKeys } from '../../translations';

import { appService } from '../../Domain/App/Services/AppServices';

import { PublicLayout } from '../../layouts';

import { ResetPasswordViewContent } from './components';

export const ResetPasswordView = () => {
  const [searchParams] = useSearchParams();

  const [isLinkValid, setIsLinkValid] = React.useState(true);

  const token = searchParams.get('t');
  const userId = searchParams.get('u');

  React.useEffect(() => {
    if (token && userId) {
      appService
        .checkResetPasswordLink(token, userId)
        .then(data => {
          setIsLinkValid(data.data);
        })
        .catch(() => {
          setIsLinkValid(false);
        });
    }
  }, [token, userId]);

  return (
    <PublicLayout titleId={TranslationKeys.resetPassword_title}>
      <ResetPasswordViewContent token={token} userId={userId} isLinkValid={isLinkValid} />
    </PublicLayout>
  );
};
