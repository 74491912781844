import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { Insurer, UseQueryWrapper } from 'app/types';

import { useSnakeBar } from 'app/hooks/useSnakeBar';

import type { GetInsurersArgs } from 'app/Domain/Insurers/Services/InsurerService/insurerService';
import insurerService from 'app/Domain/Insurers/Services/InsurerService/insurerService';

export type GetInsurersQueryVariables = GetInsurersArgs;

type GetInsurersQueryResultData = {
  data: Array<Insurer>;
  totalCount: number;
};

const queryFn = (variables: GetInsurersQueryVariables) => async () => {
  const response = await insurerService.getInsurers(variables);
  return response.data;
};

const QUERY_KEY = 'getInsurers';

export const useInsurerGetInsurersQuery: UseQueryWrapper<GetInsurersQueryResultData, GetInsurersQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetInsurersQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
