import { useContext } from 'react';
import { AuthorizationContext } from './AuthorizationProvider';
import type { AuthorizationContextInterface } from './AuthorizationContextInterface';
import { useStoreSelector } from '../../store';

export const HasAccessTo = (entity: string, permission: string, userId?: string) => {
  const context: AuthorizationContextInterface = useContext(AuthorizationContext);
  const { user } = useStoreSelector(state => state.AppReducer);
  const { permissions } = context;

  if (userId === user.userId) {
    return true;
  }

  if (!permissions) {
    return false;
  }

  if (!(entity in permissions)) {
    return false;
  }

  return permissions[entity].permissions.includes(permission);
};
