import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FormHelperText, TextField as MuiTextField } from '@mui/material';

import { Autocomplete, Switch, TextField } from 'app/components/FormikField/base';
import { DividerStyled, FieldStyled, FormLabelStyled, GridItemStyled, GridRootStyled } from './EditEmployerForm.styles';
import { EmployerStatus } from '../../../../types';

const EditEmployerForm = ({ employerShowHolding, ownerData, intl }) => {
  const { errors, values, validateForm, setFieldValue, initialValues } = useFormikContext();

  const [employers, setEmployers] = useState(ownerData.employers);
  const [defaultParentId, setDefaultParentId] = useState(initialValues?.parentId);
  const [owner, setOwner] = useState({
    ownerId: ownerData.ownerId,
    ownerName: ownerData.ownerName,
    '@id': ownerData['@id'],
  });
  const [subOwners, setSubOwners] = useState(ownerData.subOwners);

  const [defaultSubOwnerId, setDefaultSubOwnerId] = useState(
    (subOwners || []).find(o => o.value === initialValues.ownerId) || null,
  );

  const ownerIsSubOwner = !!(ownerData.parentId !== null && ownerData.parent);

  useEffect(() => {
    if (ownerIsSubOwner) {
      setOwner({
        ownerId: ownerData.parent.ownerId,
        ownerName: ownerData.parent.ownerName,
        '@id': ownerData.parent['@id'],
      });
      setSubOwners(ownerData.parent.subOwners);
    }
    setDefaultSubOwnerId((subOwners || []).find(o => o.ownerId === initialValues.ownerId) || null);
  }, [initialValues.ownerId, ownerData, ownerIsSubOwner, subOwners]);

  const isPartOfHoldingChange = useCallback(
    event => {
      setFieldValue('isPartOfHolding', event.target.checked);
    },
    [setFieldValue],
  );

  const employerStatusAutoComplete = useMemo(() => {
    const options = Object.keys(EmployerStatus).map(function (status) {
      return {
        label: intl.formatMessage({ id: `employers.${EmployerStatus[status]}` }),
        value: EmployerStatus[status],
      };
    });

    options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

    const initialStatus = (options || []).find(o => o.value === initialValues.status) || null;

    return (
      <Field
        name="status"
        component={Autocomplete}
        options={options}
        value={initialStatus}
        onChange={(e, value) => {
          setFieldValue('status', value.value ?? initialValues.status);
        }}
        disableClearable={true}
        onBlur={() => validateForm()}
        renderInput={params => <MuiTextField {...params} name="status" variant="outlined" fullWidth />}
      />
    );
  }, [initialValues.status, intl, setFieldValue, validateForm]);

  const parentIdAutoComplete = useMemo(() => {
    const reducedOptions = employers.reduce(function (filtered, employer) {
      if (initialValues.employerId !== employer.employerId && employer.parentId === null) {
        const tmpEmp = {
          label: employer.employerName,
          value: employer.employerId,
          '@id': employer['@id'],
        };
        filtered.push(tmpEmp);
      }
      return filtered;
    }, []);
    reducedOptions.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

    const initialParent = (reducedOptions || []).find(o => o.value === defaultParentId) || null;

    return (
      <Field
        name="parentId"
        component={Autocomplete}
        options={reducedOptions}
        getOptionLabel={option => {
          return option.label ? option.label : option.value;
        }}
        disableClearable={true}
        value={initialParent}
        isOptionEqualToValue={(item, current) => {
          if (typeof current === 'string') {
            return item.value === current;
          }
          return item.value === current.value;
        }}
        onChange={(e, value) => {
          setFieldValue('parent', value ? { ...value, parentId: value.value } : null);
          setFieldValue('parentId', value ? value.value : '');
          setDefaultParentId(value ? value.value : '');
        }}
        onBlur={() => validateForm()}
        renderInput={params => <MuiTextField {...params} name="parentId" variant="outlined" fullWidth />}
      />
    );
  }, [defaultParentId, employers, initialValues.employerId, setFieldValue, validateForm]);

  const subOwnerIdAutoComplete = useMemo(() => {
    const subOwnerOptions = (subOwners || []).map(owner => {
      return {
        label: owner.ownerName,
        value: owner.ownerId,
        '@id': owner['@id'],
      };
    });

    return (
      <Field
        name="subOwnerId"
        component={Autocomplete}
        options={subOwnerOptions}
        getOptionLabel={option => {
          return option.ownerName ? option.ownerName : option.label ? option.label : option.value;
        }}
        disableClearable={false}
        value={defaultSubOwnerId}
        isOptionEqualToValue={(item, current) => {
          if (typeof current === 'string') {
            return item.value === current;
          }
          const currentValue = current.value ? current.value : current.ownerId;
          return item.value === currentValue;
        }}
        onChange={(e, value) => {
          setFieldValue('owner', value);
          setFieldValue('subOwnerId', value ? value.value : '');
          setFieldValue('parentId', null);
          setDefaultParentId(null);
          setDefaultSubOwnerId(value);
          if (value === null) {
            setEmployers(ownerIsSubOwner ? ownerData.parent.employers : ownerData.employers);
          } else {
            const tempSubOwner = subOwners.find(subOwner => subOwner.ownerId === value.value);
            setEmployers(tempSubOwner.employers);
          }
        }}
        onBlur={() => validateForm()}
        renderInput={params => <MuiTextField {...params} name="subOwnerId" variant="outlined" fullWidth />}
      />
    );
  }, [defaultSubOwnerId, ownerData, ownerIsSubOwner, setFieldValue, subOwners, validateForm]);

  const ownerAutoComplete = useMemo(() => {
    const ownersOptions = [
      {
        label: owner.ownerName,
        value: owner.ownerId,
        '@id': owner['@id'],
      },
    ];

    const selectedOwner = ownersOptions.find(o => o.value === owner.ownerId);

    return (
      <Field
        name="ownerId"
        component={Autocomplete}
        options={ownersOptions}
        getOptionLabel={option => {
          return option.label ? option.label : option.value;
        }}
        disableClearable={true}
        value={selectedOwner}
        isOptionEqualToValue={(item, current) => {
          if (typeof current === 'string') {
            return item.value === current;
          }
          const currentValue = current.value ? current.value : current.ownerId;
          return item.value === currentValue;
        }}
        onChange={(e, value) => {
          setFieldValue('owner', value);
          setFieldValue('ownerId', value ? value.value : '');
        }}
        onBlur={() => validateForm()}
        renderInput={params => <MuiTextField {...params} name="ownerId" variant="outlined" fullWidth />}
      />
    );
  }, [owner, setFieldValue, validateForm]);

  return (
    <GridRootStyled container spacing={2}>
      {/* name */}
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.name" />
          {' *'}
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="employerName"
          placeholder={intl.formatMessage({ id: 'employers.name' }).toUpperCase()}
          required
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        {employerShowHolding && (
          <div>
            <FormLabelStyled>
              <FormattedMessage id="employers.holdingPart.toggle" />
            </FormLabelStyled>
            <FieldStyled
              displayFlex
              color="primary"
              component={Switch}
              variant="outlined"
              onChange={isPartOfHoldingChange}
              name="isPartOfHolding"
              checked={values?.isPartOfHolding}
              type="checkbox"
            />
          </div>
        )}
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.status" />
        </FormLabelStyled>
        {employerStatusAutoComplete}
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.owner" />
          {' *'}
        </FormLabelStyled>
        {ownerAutoComplete}
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.subOwner" />
        </FormLabelStyled>
        {subOwnerIdAutoComplete}
      </GridItemStyled>

      {values.isPartOfHolding && (
        <GridItemStyled item xs={4}>
          <FormLabelStyled>
            <FormattedMessage id="employers.holding.name" />
          </FormLabelStyled>
          {parentIdAutoComplete}
          {errors.parentId && (
            <FormHelperText className="Mui-error">
              <FormattedMessage id="validationMessage.mixed.required" />
            </FormHelperText>
          )}
        </GridItemStyled>
      )}

      {/* chamberOfCommerce/sectorCode/payrollTaxNumber */}
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.chamberOfCommerce" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="chamberOfCommerce"
          placeholder={intl.formatMessage({ id: 'employers.chamberOfCommerce' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.sectorCode" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="sectorCode"
          placeholder={intl.formatMessage({ id: 'employers.sectorCode' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.payrollTaxNumber" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="payrollTaxNumber"
          placeholder={intl.formatMessage({ id: 'employers.payrollTaxNumber' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>

      {/* address/city/zipCode */}
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.address" />
          {' *'}
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="address"
          placeholder={intl.formatMessage({ id: 'employers.address' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.zipCode" />
          {' *'}
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="zipCode"
          placeholder={intl.formatMessage({ id: 'employers.zipCode' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.city" />
          {' *'}
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="city"
          placeholder={intl.formatMessage({ id: 'employers.city' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>

      {/* email/phone/numberOfEmployees */}
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.employerEmail" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="employerEmail"
          placeholder={intl.formatMessage({ id: 'employers.email' }).toUpperCase()}
          type="email"
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.employerPhone" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="employerPhone"
          placeholder={intl.formatMessage({ id: 'employers.employerPhone' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.internalNumber" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="internalNumber"
          placeholder={intl.formatMessage({ id: 'employers.internalNumber' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4} style={{ display: 'none' }}>
        <FormLabelStyled>
          <FormattedMessage id="employers.numberOfEmployees" />
        </FormLabelStyled>
        <FieldStyled
          sizeSmall
          color="secondary"
          component={TextField}
          disabled
          name="numberOfEmployees"
          placeholder={intl.formatMessage({ id: 'employers.numberOfEmployees' }).toUpperCase()}
          type="number"
          variant="outlined"
        />
      </GridItemStyled>

      <DividerStyled />

      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.contactName" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="contactName"
          placeholder={intl.formatMessage({ id: 'employers.contactName' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.contactEmail" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="contactEmail"
          placeholder={intl.formatMessage({ id: 'employers.contactEmail' }).toUpperCase()}
          type="email"
          variant="outlined"
        />
      </GridItemStyled>
      <GridItemStyled item xs={4}>
        <FormLabelStyled>
          <FormattedMessage id="employers.contactPhone" />
        </FormLabelStyled>
        <FieldStyled
          color="secondary"
          component={TextField}
          fullWidth
          name="contactPhone"
          placeholder={intl.formatMessage({ id: 'employers.contactPhone' }).toUpperCase()}
          variant="outlined"
        />
      </GridItemStyled>
    </GridRootStyled>
  );
};

EditEmployerForm.propTypes = {
  initialValues: PropTypes.object,
  employerShowHolding: PropTypes.bool,
};

EditEmployerForm.defaultProps = {
  employerShowHolding: true,
};

export default injectIntl(EditEmployerForm);
