import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Grid } from '@mui/material';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { OwnerFormFields } from '../OwnerForm';
import { ImageUploadArea } from '../../../../components/ImageUploadArea';
import { DividerStyled, FormLabelStyled, GridItemStyled, TextFieldStyled } from './CreateOwnerForm.styles';

const CreateOwnerForm = props => {
  const { owner, onOwnerChange } = props;

  // uses
  const intl = useIntl();

  // data
  const [ownerErrorField, setOwnerErrorField] = useState(null);
  const [ownerErrorMessage, setOwnerErrorMessage] = useState(null);

  const validationSchema = Yup.object().shape({
    ownerName: Yup.string().trim().strict().max(255).required(),
    ownerEmail: Yup.string().trim().strict().max(255).email(),
    ownerPhone: Yup.string().trim().strict().max(255),
    contactName: Yup.string().trim().strict().max(255),
    contactEmail: Yup.string().trim().strict().max(255).email(),
    contactPhone: Yup.string().trim().strict().max(255),
    address: Yup.string().trim().strict(),
    zipCode: Yup.string().trim().strict().max(255),
    city: Yup.string().trim().strict().max(255),
  });

  // events
  const handleChange = field => event => {
    owner[field] = event.target.value;

    Yup.reach(validationSchema, field)
      .validate(owner[field])
      .then(() => {
        setOwnerErrorField(null);
        setOwnerErrorMessage(null);
      })
      .catch(e => {
        setOwnerErrorField(field);
        setOwnerErrorMessage(e.message);
      });

    onOwnerChange(field, event.target.value);
  };

  const onLogoChange = React.useCallback(
    file => {
      onOwnerChange('logo', file || '');
    },
    [onOwnerChange],
  );

  // template
  return (
    <Grid sx={{ width: '100%' }} container spacing={2}>
      {/* name */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.name" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          error={ownerErrorField === 'ownerName'}
          fullWidth
          helperText={ownerErrorField === 'ownerName' ? ownerErrorMessage : null}
          name="owner.ownerName"
          placeholder={intl.formatMessage({ id: 'owners.name' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('ownerName')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}></GridItemStyled>

      {/* address / city */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.address" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="address"
          placeholder={intl.formatMessage({ id: 'owners.address' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('address')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.zipCode" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="zipCode"
          placeholder={intl.formatMessage({ id: 'owners.zipCode' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('zipCode')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.city" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="city"
          placeholder={intl.formatMessage({ id: 'owners.city' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('city')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.numberOfEmployees" />
        </FormLabelStyled>
        <TextFieldStyled
          small
          color="secondary"
          disabled
          name="numberOfEmployees"
          placeholder={intl.formatMessage({ id: 'owners.numberOfEmployees' }).toUpperCase()}
          type="number"
          variant="outlined"
          onChange={handleChange('numberOfEmployees')}
        />
      </GridItemStyled>

      {/* email / phone */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.ownerEmail" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          error={ownerErrorField === 'ownerEmail'}
          fullWidth
          helperText={ownerErrorField === 'ownerEmail' ? ownerErrorMessage : null}
          name="ownerEmail"
          placeholder={intl.formatMessage({ id: 'owners.email' }).toUpperCase()}
          required
          type="email"
          variant="outlined"
          onChange={handleChange('ownerEmail')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.ownerPhone" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="ownerPhone"
          placeholder={intl.formatMessage({ id: 'owners.ownerPhone' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('ownerPhone')}
        />
      </GridItemStyled>

      <DividerStyled />

      {/* contact name */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.contactName" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="contactName"
          placeholder={intl.formatMessage({ id: 'owners.contactName' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('contactName')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}></GridItemStyled>

      {/* contact email / phone */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.contactEmail" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="contactEmail"
          placeholder={intl.formatMessage({ id: 'owners.contactEmail' }).toUpperCase()}
          type="email"
          variant="outlined"
          onChange={handleChange('contactEmail')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.contactPhone" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="contactPhone"
          placeholder={intl.formatMessage({ id: 'owners.contactPhone' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('contactPhone')}
        />
      </GridItemStyled>

      <GridItemStyled item xs={6}>
        <ImageUploadArea label={OwnerFormFields.Logo.Label} onChange={onLogoChange} />
      </GridItemStyled>
    </Grid>
  );
};

CreateOwnerForm.propTypes = {
  owner: PropTypes.object,
  onOwnerChange: PropTypes.func,
};

export default CreateOwnerForm;
