import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@mui/material/Skeleton';
import ConnectionLogCountDataCell from '../ConnectionLogCountDataCell';
import ConnectionEventCountDataCell from '../ConnectionEventCountDataCell';
import { formatDate } from '../../../../utils/formatter/dateTimeFormatter';
import { Link as RouterLink } from 'react-router-dom';

const ConnectionsTable = props => {
  const {
    className,
    connections,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    logsCount,
    eventsCount,
  } = props;

  return (
    <Card className={className}>
      <CardContent sx={{ padding: 0 }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="connections.connectionName" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.numberOfLogMessages" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.numberOfEvents" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.packages" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connection.latestRun" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="connections.createdAt" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  connections.map(connection => (
                    <TableRow hover key={connection.connectionId}>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/employers/${connection.employerId}/connections/${connection.connectionId}/info`}
                        >
                          {connection.connectionName}
                        </Link>
                        {connection.employerId ? '' : ' (no employer)'}
                      </TableCell>
                      <TableCell>
                        <ConnectionLogCountDataCell connectionId={connection.connectionId} logsCount={logsCount} />
                      </TableCell>
                      <TableCell>
                        <ConnectionEventCountDataCell
                          connectionId={connection.connectionId}
                          eventsCount={eventsCount}
                        />
                      </TableCell>
                      <TableCell>
                        {connection.packages.map((p, index) => (
                          <Chip key={index} label={<FormattedMessage id={`packages.type.${p.packageType}`} />} />
                        ))}
                      </TableCell>
                      <TableCell>{formatDate(connection.latestRun)}</TableCell>
                      <TableCell>{formatDate(connection.createdAt)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <TablePagination
          component="div"
          count={totalCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

ConnectionsTable.propTypes = {
  className: PropTypes.string,
  connections: PropTypes.array.isRequired,
};

export default ConnectionsTable;
