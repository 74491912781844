import React from 'react';

import { Typography } from '@mui/material';

import type { ReportEmployer } from '../../../../../types';

import { ActionLink, ListRow } from '../../../../../components';

import type { OnChangeSelected, ReportData } from '../ReportManagementPage.types';

import { ReportNoData } from '../ReportNoData';
import { ReportDataContainer } from '../ReportDataContainer';

type ReportDataTableProps = {
  selectedData: ReportData;
  reportData: ReportData;
  setSelected: OnChangeSelected;
  disabledActionsWhileLoading?: boolean;
  searchQuery: string;
};

export const ReportDataTable = ({
  selectedData,
  reportData,
  setSelected,
  searchQuery,
  disabledActionsWhileLoading = false,
}: ReportDataTableProps) => {
  const reportDataForRender = React.useMemo(() => {
    if (!searchQuery) {
      return reportData;
    }

    const searchWords = searchQuery.toLowerCase().split(' ');
    return reportData.filter(({ name }) => searchWords.every(word => name.toLowerCase().includes(word)));
  }, [reportData, searchQuery]);

  const onSelect = React.useCallback(
    (employer: ReportEmployer) => () => {
      const newSelectedData = [...selectedData];
      const isSelected = selectedData.some(({ employerId }) => employerId === employer.employerId);

      if (isSelected) {
        setSelected(newSelectedData.filter(({ employerId }) => employerId !== employer.employerId));
      }

      if (!isSelected) {
        newSelectedData.push(employer);
        setSelected(newSelectedData);
      }
    },
    [selectedData, setSelected],
  );

  const hasData = reportDataForRender?.length > 0;

  return (
    <ReportDataContainer>
      {hasData &&
        reportDataForRender.map(employer => {
          const isSelected = selectedData.some(({ employerId }) => employerId === employer.employerId);

          return (
            <ListRow
              key={employer.employerId}
              selected={isSelected}
              onSelect={onSelect(employer)}
              disabled={disabledActionsWhileLoading}
            >
              <Typography>
                <ActionLink to={`/employers/${employer.employerId}`}>{employer.name}</ActionLink>
              </Typography>
            </ListRow>
          );
        })}

      {!hasData && <ReportNoData searchQuery={searchQuery} />}
    </ReportDataContainer>
  );
};
