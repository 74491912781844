import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { ChildTableCellStyled, TableCellStyled, TableRowStyled } from './FilterableTableRow.styles';
import type {
  FilterableTableColumnType,
  FilterableTableMapperFuncReturnValue,
  FilterableTableRowChildMapperFunc,
} from '../../FilterableTable.types';
import { filterableTableColumnActionsStyles } from '../../FilterableTable.styles';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from '../../../../translations';

export type FilterableTableRowProps<TRowData, TChildData> = {
  rowIndex: number;
  columns: FilterableTableColumnType[];
  row: FilterableTableMapperFuncReturnValue<TRowData, TChildData>;
  normalizedRows: TRowData[];
  renderCell: (
    column: FilterableTableColumnType,
    row: FilterableTableMapperFuncReturnValue<TRowData, TChildData> | FilterableTableMapperFuncReturnValue<TChildData>,
  ) => unknown;
  onRowClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: FilterableTableMapperFuncReturnValue<TRowData, TChildData>,
    data: TRowData,
  ) => void;
  rowChildMapper?: FilterableTableRowChildMapperFunc<TChildData>;
  hover?: boolean;
};

export function FilterableTableRow<TRowData = Record<string, any>, TChildData = Record<string, any>>({
  rowIndex,
  columns,
  row,
  normalizedRows,
  renderCell,
  rowChildMapper,
  onRowClick,
  hover = true,
}: FilterableTableRowProps<TRowData, TChildData>) {
  const [showChildren, setShowChildren] = React.useState(false);

  const hasChildren = !!row.data?.children;
  const hasChildrenData = (row.data?.children || []).length > 0;
  const isCollapsibleTable = !!rowChildMapper && hasChildren;

  const onClickExpandIcon = React.useCallback(() => {
    setShowChildren(prevState => !prevState);
  }, []);

  return (
    <>
      <TableRowStyled
        hover={hover}
        onClick={event => onRowClick(event, row, normalizedRows[rowIndex])}
        inactive={!!row?.style?.inactive}
      >
        {columns.map((column, columnIndex) => {
          const isFirstColumnAndHasChildren = hasChildren && columnIndex === 0;
          const isSelectAllOrActionsColumn = column?.isActionsColumn || column?.isSelectAllColumn;

          return (
            <TableCellStyled
              isFirstColumnAndHasChildren={isFirstColumnAndHasChildren}
              sx={column.styles || (isSelectAllOrActionsColumn ? filterableTableColumnActionsStyles : undefined)}
              key={`${rowIndex}-${columnIndex}`}
              align={column.textAlign}
            >
              {isFirstColumnAndHasChildren && (
                <IconButton aria-label="expand-row-button" size="small" disableRipple onClick={onClickExpandIcon}>
                  {showChildren ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              )}
              <>{renderCell(column, row)}</>
            </TableCellStyled>
          );
        })}
      </TableRowStyled>

      {isCollapsibleTable && showChildren && (
        <>
          {hasChildrenData && (
            <>
              {(row.data.children || []).map((child: TChildData, childIdx: number) => {
                return (
                  <TableRowStyled key={`${rowIndex}_child_${childIdx}`} hover={hover} inactive={!!row?.style?.inactive}>
                    {columns.map((column, columnIndex) => {
                      const mappedChildRow = rowChildMapper(child);

                      return (
                        <ChildTableCellStyled
                          isFirstColumn={columnIndex === 0}
                          sx={column.styles}
                          key={`${rowIndex}_child_${childIdx}_col-${column.name}`}
                          align={column.textAlign}
                        >
                          <>{renderCell(column, mappedChildRow)}</>
                        </ChildTableCellStyled>
                      );
                    })}
                  </TableRowStyled>
                );
              })}
            </>
          )}

          {!hasChildrenData && (
            <TableRowStyled key={`${rowIndex}_no-children`} hover={hover} inactive={!!row?.style?.inactive}>
              <ChildTableCellStyled isFirstColumn={true} colSpan={columns.length}>
                <Typography variant={'h6'} sx={{ opacity: 0.38 }}>
                  <FormattedMessage id={TranslationKeys.global_noData} />
                </Typography>
              </ChildTableCellStyled>
            </TableRowStyled>
          )}
        </>
      )}
    </>
  );
}
