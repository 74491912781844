import { styled } from '@mui/material/styles';
import { buttonBaseClasses, chipClasses } from '@mui/material';

export const FileUploadDropzoneAreaContainer = styled('div', {
  name: 'FileUploadDropzoneAreaContainer',
})(({ theme }) => ({
  '& .MuiDropzoneArea-root': {
    border: '1px dashed',
    borderColor: theme.palette.secondary.dark,
    padding: theme.spacing(2),
    background: 'none',
    boxShadow: 'none',
    borderRadius: '10px',
    boxSizing: 'border-box',
    minHeight: '110px',
    overflow: 'auto',
  },
  '& .MuiDropzoneArea-text': {
    display: 'none',
  },
  '& .MuiDropzonePreviewList-root': {
    [`& .${buttonBaseClasses.root}.${chipClasses.root}`]: {
      backgroundColor: 'unset',
    },
  },
}));
