import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Skeleton, Typography } from '@mui/material';

import { useInsurerGetInsurerQuery } from '../../../../hooks';

import { NotFoundView } from '../../../../views';

import { TranslationKeys } from '../../../../translations';

import TabOutlet from '../../../../components/TabOutlet';

export const InsurerDetailPage = () => {
  const { insurerId } = useParams();

  const { data: insurer, isLoading: isLoadingInsurer } = useInsurerGetInsurerQuery({
    variables: { insurerId },
    options: {
      enabled: !!insurerId,
    },
  });

  const exists = isLoadingInsurer || (!isLoadingInsurer && !!insurer);

  const tabData = React.useMemo(() => {
    return [
      {
        path: 'info',
        label: <FormattedMessage id={TranslationKeys.insurers_tab_general} />,
      },
    ];
  }, []);

  const title = React.useMemo(() => {
    if (insurer?.insurerName) {
      return <Typography variant="h5">{insurer.insurerName}</Typography>;
    }
    return (
      <Typography variant="h5">
        <Skeleton variant={'text'} width={200} />
      </Typography>
    );
  }, [insurer]);

  const breadcrumb = React.useMemo(() => {
    return {
      title: insurer?.insurerName,
      data: {
        titleElement: title,
      },
    };
  }, [insurer, title]);

  return (
    <>
      {exists && (
        // @ts-ignore
        <TabOutlet tabs={tabData} breadcrumb={breadcrumb} />
      )}

      {!exists && (
        <Routes>
          <Route element={<NotFoundView />} path="*" />
        </Routes>
      )}
    </>
  );
};
