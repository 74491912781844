export const PROCESS_RESULTS = [
  'pending',
  'awaiting-approval',
  'approved',
  'processing',
  'complete',
  'failed',
  'draft',
  'pending-insurer-data',
  'compared',
  'to-be-checked',
] as const;

export type ProcessResults = Array<(typeof PROCESS_RESULTS)[number]>;

export const PENDING_RESULTS: ProcessResults = ['pending'];
export const FAILED_PROCESS_RESULTS: ProcessResults = ['failed'];
export const APPROVAL_NEEDED_PROCESS_RESULTS: ProcessResults = ['awaiting-approval'];
export const COMPLETE_PROCESS_RESULTS: ProcessResults = ['complete'];
export const CAN_DOWNLOAD: ProcessResults = [
  'failed',
  'awaiting-approval',
  'complete',
  'approved',
  'draft',
  'pending-insurer-data',
  'compared',
  'to-be-checked',
];
export const CAN_COMPARE: ProcessResults = ['draft'];
export const CAN_COMPARE_COMPLETED: ProcessResults = ['compared', 'to-be-checked', 'pending-insurer-data'];
