import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CredentialRequestsManagementPage from './CredentialRequestsManagementPage';
import OauthCredentialResponsePage from './OauthCredentialResponsePage';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { CREDENTIAL } from 'app/common/Authorization/entities';
import { RestrictAccess } from 'app/components';
import { VIEW } from 'app/common/Authorization/permissions';
import { CredentialsManagementPage } from 'app/Domain/Credentials/Pages/CredentialsManagementPage';
import { TranslationKeys } from 'app/translations';

export const CredentialsPages = () => {
  useBreadcrumb(TranslationKeys.menu_credentials);

  return (
    <Routes>
      <Route element={<RestrictAccess entity={CREDENTIAL} permission={VIEW} />}>
        <Route element={<CredentialsManagementPage />} path={'overview'} />
        <Route element={<CredentialRequestsManagementPage />} path={'requests'} />
        <Route element={<OauthCredentialResponsePage />} path={'oauth/:status/:credentialId'} />
        <Route index element={<Navigate to="overview" replace />} />
        <Route path="*" element={<Navigate to="overview" replace />} />
      </Route>
    </Routes>
  );
};
