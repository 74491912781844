import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, DialogActions, DialogContent, Tabs, Tab } from '@mui/material';
import Typography from '@mui/material/Typography';

import type { UseQueryRefetch, User } from '../../../../../../types';

import {
  useDialog,
  useReportsGetDataFilterForSuperAdminQuery,
  useReportsGetDataFilterQuery,
  useSnakeBar,
  useUserUpdateMutation,
} from '../../../../../../hooks';

import { TranslationKeys } from '../../../../../../translations';

import { StoreSelectors, useStoreSelector } from '../../../../../../store';

import { ActionButton, Dialog, HasRole, IsSuperAdmin } from '../../../../../../components';

import { UserAccessesListConfirmDialog, UserAccessesListEmployers, UserAccessesListSubOwners } from './components';
import type { UserAccessesListOnChangeSelected, UserAccessesListSelectedData } from './UserAccessesList.types';
import { UserEnvironmentsList } from '../UserEnvironmentsList';

type UserAccessesListProps = {
  user: User | undefined;
  isLoggedInUser?: boolean;
  openConfirmDeleteDialog: () => void;
  refetchGetUserQuery: UseQueryRefetch;
  onRequestAPIKey: () => void;
};

export const UserAccessesList = ({
  user,
  openConfirmDeleteDialog,
  refetchGetUserQuery,
  isLoggedInUser = false,
  onRequestAPIKey,
}: UserAccessesListProps) => {
  const { mutate: updateUserMutation, isLoading: isLoadingUserUpdateMutation } = useUserUpdateMutation();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const {
    dialogState: showConfirmDialog,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialog();

  const isSuperAdmin = IsSuperAdmin();
  const [userDefaultSelectedData, setUserDefaultSelectedData] = React.useState<UserAccessesListSelectedData>();
  const [selectedData, setSelectedData] = React.useState<UserAccessesListSelectedData>();
  const [confirmationDialog, setConfirmationDialog] = React.useState(false);
  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));
  const hasAPIRole = HasRole('ROLE_API_API') && userId === user?.userId;

  const {
    data: dataFilterForSuperAdmin,
    isLoading: isLoadingGetDataFilterForSuperAdminQuery,
    isFetching: isFetchingGetDataFilterForSuperAdminQuery,
  } = useReportsGetDataFilterForSuperAdminQuery({
    variables: { ownerId: user?.ownerId ?? '' },
    options: {
      enabled: isSuperAdmin && !!user?.ownerId,
    },
  });

  const {
    data: dataFilterForUser,
    isLoading: isLoadingGetDataFilterQuery,
    isFetching: isFetchingGetDataFilterQuery,
    refetch: refetchGetDataFilterQuery,
  } = useReportsGetDataFilterQuery({
    variables: { userId },
    options: {
      enabled: !isSuperAdmin && !!userId,
    },
  });

  const dataFilter = React.useMemo(() => {
    if (isSuperAdmin) {
      return dataFilterForSuperAdmin;
    }

    return dataFilterForUser;
  }, [dataFilterForSuperAdmin, dataFilterForUser, isSuperAdmin]);

  React.useEffect(() => {
    const accesses: UserAccessesListSelectedData = {
      employers: user?.accesses?.employers || {},
      subOwners: user?.accesses?.subOwners || {},
      eventFlows: dataFilter?.employers
        ?.filter(({ eventFlowDefaultUser }) => eventFlowDefaultUser === user?.userId)
        .map(employer => employer.employerId),
      areAllEmployersSelected: false,
      areAllSubOwnersSelected: false,
    };

    if (user?.accesses?.subOwners === '*' && dataFilter?.subOwners) {
      accesses.subOwners = dataFilter.subOwners.reduce<Record<string, string>>((acc, subOwner) => {
        acc[subOwner.subOwnerId] = '*';
        return acc;
      }, {});
    }

    if (user?.accesses?.employers === '*' && dataFilter?.employers) {
      dataFilter.employers.reduce<Record<string, string>>((acc, employer) => {
        acc[employer.employerId] = '*';
        return acc;
      }, {});
    }

    accesses.eventFlows = [
      ...(dataFilter?.employers
        ?.filter(({ eventFlowDefaultUser }) => eventFlowDefaultUser === user?.userId)
        .map(employer => employer.employerId) || []),
      ...(dataFilter?.subOwners?.flatMap(subOwner =>
        subOwner.employers
          .filter(({ eventFlowDefaultUser }) => eventFlowDefaultUser === user?.userId)
          .map(employer => employer.employerId),
      ) || []),
    ];

    setSelectedData(accesses);
    setUserDefaultSelectedData(accesses);

    return () => {};
  }, [user, dataFilter?.employers, dataFilter?.subOwners, user?.accesses, userId]);

  const hasChangedAccesses = React.useMemo(() => {
    const {
      areAllEmployersSelected: defaultAreAllEmployersSelected,
      areAllSubOwnersSelected: defaultAreAllSubOwnersSelected,
      ...defaultData
    } = userDefaultSelectedData || {};

    const {
      areAllEmployersSelected: selectedAreAllEmployersSelected,
      areAllSubOwnersSelected: selectedAreAllSubOwnersSelected,
      ...currentSelectedData
    } = selectedData || {};

    const userAccesses = JSON.stringify(defaultData || {});
    const selectedAccesses = JSON.stringify(currentSelectedData || {});

    return userAccesses !== selectedAccesses;
  }, [userDefaultSelectedData, selectedData]);

  const onClickCancelAccessesChanges = React.useCallback(() => {
    setSelectedData(userDefaultSelectedData);
  }, [userDefaultSelectedData]);

  const onChangeSelected = React.useCallback<UserAccessesListOnChangeSelected>(newSelectedData => {
    setSelectedData(newSelectedData);
  }, []);

  const updateUserAccesses = React.useCallback(() => {
    if (user && selectedData) {
      const method = 'updateUser';

      updateUserMutation(
        {
          userId: user.userId,
          ownerId: user.ownerId || null,
          roleId: user.roleId,
          firstName: user.personName.firstName,
          middleName: user.personName.middleName,
          lastName: user.personName.lastName,
          emailAddress: user.emailAddress,
          language: user.language,
          internal: user.internal,
          accesses: {
            employers: selectedData.employers,
            subOwners: selectedData.subOwners,
          },
          eventFlows: selectedData.eventFlows,
        },
        {
          onSuccess: () => {
            closeConfirmDialog();
            refetchGetUserQuery();
            refetchGetDataFilterQuery();
            showSuccessSnakeBar({ method });
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error?.message });
          },
        },
      );
    }
  }, [
    closeConfirmDialog,
    refetchGetDataFilterQuery,
    refetchGetUserQuery,
    selectedData,
    showErrorSnakeBar,
    showSuccessSnakeBar,
    updateUserMutation,
    user,
  ]);

  const isGetDataFilterForSuperAdminQueryLoading =
    isSuperAdmin && (isLoadingGetDataFilterForSuperAdminQuery || isFetchingGetDataFilterForSuperAdminQuery);

  const isGetDataFilterQueryLoading = !isSuperAdmin && (isLoadingGetDataFilterQuery || isFetchingGetDataFilterQuery);

  const isLoading =
    isGetDataFilterForSuperAdminQueryLoading || isGetDataFilterQueryLoading || isLoadingUserUpdateMutation;

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const [tabValue, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Employers and Subowners" id={'simple-tab-1'} />
          {isSuperAdmin && <Tab label="Environments" id={'simple-tab-2'} />}
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant={'h6'}>
            <FormattedMessage id={TranslationKeys.users_accessesList_headerText} />
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            {hasAPIRole && (
              <ActionButton
                variant={'outlined'}
                onClick={onRequestAPIKey}
                disabled={isLoading}
                messageId={TranslationKeys.button_requestAPI}
              />
            )}
            <ActionButton
              variant={'outlined'}
              messageId={TranslationKeys.button_delete}
              onClick={openConfirmDeleteDialog}
              disabled={isLoggedInUser || isLoading}
            />
          </Box>
        </Box>
        <UserAccessesListSubOwners
          isLoading={isLoading}
          isLoggedInUser={isLoggedInUser}
          onChangeSelected={onChangeSelected}
          selectedData={selectedData}
          subOwners={dataFilter?.subOwners}
          selectedEventFlows={selectedData?.eventFlows || []}
          openConfirmationDialog={setConfirmationDialog}
        />
        <UserAccessesListEmployers
          isLoading={isLoading}
          isLoggedInUser={isLoggedInUser}
          onChangeSelected={onChangeSelected}
          selectedData={selectedData}
          employers={dataFilter?.employers}
          selectedEventFlows={selectedData?.eventFlows || []}
          openConfirmationDialog={setConfirmationDialog}
        />

        {hasChangedAccesses && (
          <Box marginTop={3} marginBottom={3} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={2}>
            <ActionButton
              variant={'outlined'}
              messageId={TranslationKeys.button_cancel}
              onClick={onClickCancelAccessesChanges}
              disabled={isLoggedInUser || isLoading}
            />
            <ActionButton
              messageId={TranslationKeys.button_save}
              onClick={openConfirmDialog}
              disabled={isLoggedInUser || isLoading}
            />
          </Box>
        )}

        {showConfirmDialog && !!selectedData && (
          <UserAccessesListConfirmDialog
            show={showConfirmDialog}
            isLoading={isLoading}
            onClose={closeConfirmDialog}
            onConfirm={updateUserAccesses}
            selectedData={selectedData}
            data={dataFilter}
          />
        )}
        <Dialog
          open={confirmationDialog}
          title={<FormattedMessage id={TranslationKeys.users_accessesList_dialog_title} />}
          onClose={() => {
            setConfirmationDialog(false);
          }}
        >
          <DialogContent>
            <Typography variant={'body1'}>
              <FormattedMessage id={TranslationKeys.users_accessesList_dialog_content} />
            </Typography>
          </DialogContent>
          <DialogActions>
            <ActionButton
              messageId={TranslationKeys.button_confirm}
              onClick={() => {
                setConfirmationDialog(false);
              }}
            />
          </DialogActions>
        </Dialog>
      </CustomTabPanel>
      {isSuperAdmin && (
        <CustomTabPanel value={tabValue} index={1}>
          <UserEnvironmentsList user={user} refetchGetUserQuery={refetchGetUserQuery}></UserEnvironmentsList>
        </CustomTabPanel>
      )}
    </>
  );
};
