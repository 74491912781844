import type { IntlShape } from 'react-intl/src/types';

import { isEmpty, isNil } from 'app/utils';

import { TranslationKeys } from 'app/translations';

import type { ListFileStrategyInterface } from 'app/components/FileUploadAreaWithTemplate/ListFileStrategyInterface';

import { ScalesTableFileReadException } from './ScalesTableFileReadException';
import { SCALES_TABLE_FILE_HEADERS } from './ScalesTableFileReadStrategy.types';

const parseValue = (value: string | number) => parseFloat(value.toString().replace(',', '.'));

const isNegativeValue = (value: string | number) => {
  const parsedValue = parseValue(value);
  return parsedValue < 0;
};

const throwError = (errorMsg: string) => {
  throw new ScalesTableFileReadException(errorMsg);
};

export class ScalesTableFileReadStrategy implements ListFileStrategyInterface {
  constructor(private readonly intl: IntlShape) {}

  public execute(data: Array<any>): Array<any> {
    const rows = data.filter((row: any, index: number) => {
      const isHeadersRow = index === 0;

      if (isHeadersRow) {
        return false;
      }

      const validRowColumns = Object.keys(row).filter(key => {
        if (row.length < SCALES_TABLE_FILE_HEADERS.length) {
          throwError(
            this.intl.formatMessage(
              { id: TranslationKeys.settings_rateTable_fileUpload_error_missingColumns },
              { columns: SCALES_TABLE_FILE_HEADERS.map(col => col).join(', ') },
            ),
          );
        }

        const value = row[key];
        const rowNumber = index + 1;

        if (isNil(value) || isEmpty(value)) {
          throwError(
            this.intl.formatMessage(
              { id: TranslationKeys.settings_rateTable_fileUpload_error_emptyValues },
              { rowNumber },
            ),
          );
        }

        if (isNegativeValue(value)) {
          throwError(
            this.intl.formatMessage(
              { id: TranslationKeys.settings_rateTable_fileUpload_error_negativeValues },
              { rowNumber },
            ),
          );
        }

        return value.trim() !== '';
      });

      return validRowColumns.length > 0;
    });

    if (rows.length === 0) {
      throwError(TranslationKeys.global_error_fileEmpty);
    }

    return rows;
  }
}
