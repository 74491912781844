import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

import { ActionButton } from '../../../../components/ActionButton';

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const ActionButtonStyled = styled(ActionButton, {
  name: 'ActionButtonStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
