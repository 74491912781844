export const parseValue = (
  value: any,
  valueType?: undefined | string | 'integer' | 'float' | 'boolean',
  defaultValue: any = '',
): any => {
  if (value) {
    if (valueType === 'integer') {
      return parseInt(value);
    }
    if (valueType === 'float') {
      return parseFloat(value);
    }
    if (valueType === 'boolean') {
      return ['true', 'yes', '1', true].includes(value)
        ? true
        : ['false', 'no', '0', false].includes(value)
        ? false
        : defaultValue;
    }
    return value;
  }
  return defaultValue;
};

/**
 * Assumes string are in ISO 8601 format
 */
export const parseDate = (date: string | Date, asLocal = false): Date => {
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 100;

  if (date instanceof Date) {
    const year = date.getFullYear();
    if (year < minYear) {
      return new Date(date.setFullYear(minYear));
    }
    return new Date(date.toISOString());
  }

  let innerDate = date.trim();
  if (innerDate.length === 10) {
    innerDate = `${innerDate}T00:00:00`;
  }

  if (innerDate.length === 19) {
    if (asLocal) {
      const parts = innerDate.split(/[^0-9]/).map(s => parseInt(s, 10));
      return new Date(parts[0], parts[1] - 1 || 0, parts[2] || 1, parts[3] || 0, parts[4] || 0, parts[5] || 0);
    }
    innerDate = `${innerDate}Z`;
  }

  const parsedDate = new Date(innerDate);

  const year = parsedDate.getFullYear();
  if (isNaN(parsedDate.getTime())) {
    return new Date(NaN);
  }
  if (year < minYear) {
    return new Date(parsedDate.setFullYear(minYear));
  }

  return parsedDate;
};
