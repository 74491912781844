import type { Locale } from 'date-fns';

import type { NumericInputHelperOptions } from '../../components/FormikField/Text/NumericInputHelper';
import NumericInputHelper from '../../components/FormikField/Text/NumericInputHelper';
import { useLocale } from '../../components/Intl';

type FormatCurrencyArgs = {
  locale: Locale;
  value: string | number;
  options?: NumericInputHelperOptions;
};

// To be used inside React hooks
export const formatCurrency = ({ locale, value, options = {} }: FormatCurrencyArgs) => {
  // @ts-ignore
  const helper = new NumericInputHelper(locale.locale, {
    ...options,
    style: 'currency',
    currency: options?.currency || 'EUR',
  });
  return helper.format(value);
};

/**
 * This method should not be used inside useEffect, useMemo or useCallback.
 * To use it inside react hooks, use the currencyFormat instead.
 */
export const FormattedCurrency = (value: FormatCurrencyArgs['value'], options: FormatCurrencyArgs['options'] = {}) => {
  const { locale } = useLocale();

  if (typeof value === 'object' && value !== null) {
    // eslint-disable-next-line no-param-reassign
    value = value['value'];
  }

  return formatCurrency({ locale, value, options });
};
