import { styled } from '@mui/material/styles';
import { Divider, FormLabel, Grid, Link, outlinedInputClasses } from '@mui/material';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const GridItemStyled = styled(Grid, {
  name: 'GridItemStyled',
})(() => ({
  minHeight: '75px',
}));

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textTransform: 'uppercase',
}));

export const FieldStyled = styled(Field, {
  name: 'FieldStyled',
})(({ theme }) => ({
  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1.5),
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: '1px solid ' + theme.palette.secondary.main,
  },
}));

export const DividerStyled = styled(Divider, {
  name: 'DividerStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: '100%',
}));

export const LinkDeleteStyled = styled(Link, {
  name: 'LinkDeleteStyled',
})(({ theme }) => ({
  color: theme.palette.primary.dark,
  float: 'right',
  fontSize: '0.75rem',
  textTransform: 'initial',
}));

export const LinkAddStyled = styled(Link, {
  name: 'LinkAddStyled',
  shouldForwardProp: (propName: string) => !['disabled'].includes(propName),
})<{ disabled: boolean }>(({ theme, disabled }) => ({
  color: theme.palette.primary.main,
  float: 'right',
  textTransform: 'initial',
  ...(disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}),
}));

export const FontAwesomeIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
