export interface Dictionary<T> {
  [Key: string]: T;
}

export const isDate = (obj?: any): boolean => obj instanceof Date;
export const isNumeric = (obj?: any): boolean => !isNaN(parseFloat(obj)) && isFinite(obj);
export const isEmptyArray = (value?: any): boolean => Array.isArray(value) && value.length === 0;
export const isFunction = (obj: any): obj is Function => typeof obj === 'function';
export const isObject = (obj: any): obj is Object => obj !== null && typeof obj === 'object';
export const isInteger = (obj: any): boolean => isNumeric(obj) && obj % 1 === 0;
export const isFloat = (obj: any): boolean => isNumeric(obj) && !isInteger(obj);
export const isString = (obj: any): obj is string => Object.prototype.toString.call(obj) === '[object String]';
// eslint-disable-next-line no-self-compare
export const isNaN = (obj: any): boolean => obj !== obj;
export const isPromise = (value: any): value is PromiseLike<any> => isObject(value) && isFunction(value.then);
export const isNil = (value: any): boolean => value === null || typeof value === 'undefined';
//export const isEmpty = (value: any): boolean => value === null || typeof value === 'undefined' || value === '';
export const isBoolean = (value: any) =>
  value === true ||
  value === false ||
  (isObject(value) && Object.prototype.toString.call(value) === '[object Boolean]');

export const getValueType = (value: any): string => {
  if (isBoolean(value)) {
    return 'boolean';
  }
  if (isString(value)) {
    return 'string';
  }
  if (isObject(value)) {
    return 'object';
  }
  if (isFunction(value)) {
    return 'function';
  }
  if (isInteger(value)) {
    return 'integer';
  }
  if (isFloat(value)) {
    return 'float';
  }
  return typeof value;
};
