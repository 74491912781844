import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { Grid } from '@mui/material';

import type { Owner } from '../../../../types/owner.types';

import { InputFieldFactory } from '../../../../components/FormikField';
import { ImageUploadArea } from '../../../../components/ImageUploadArea';

import type { SubOwnerFormValues } from './SubOwnerForm.types';
import { SubOwnerFormFields } from './SubOwnerForm.types';

type SubOwnerFormProps = {
  owners: Array<Owner> | undefined;
  loading?: boolean;
};

export const SubOwnerForm = ({ owners, loading = false }: SubOwnerFormProps) => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<SubOwnerFormValues>();

  const parentSelectItems = React.useMemo(() => {
    return (owners || [])
      .sort((a, b) => (a.ownerName < b.ownerName ? -1 : 0))
      .map(owner => ({
        element: owner.ownerName,
        value: owner.ownerId,
      }));
  }, [owners]);

  const onLogoChange = React.useCallback(
    (file?: File) => {
      setFieldValue(SubOwnerFormFields.Logo.InputName, file || '');
    },
    [setFieldValue],
  );

  return (
    <Grid container spacing={2}>
      {!!owners && (
        <Grid item xs={12}>
          <InputFieldFactory
            field={{
              name: SubOwnerFormFields.ParentId.InputName,
              header: SubOwnerFormFields.ParentId.Label,
              type: 'select',
              required: true,
              loading,
              items: parentSelectItems,
            }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.OwnerName.InputName,
            header: SubOwnerFormFields.OwnerName.Label,
            required: true,
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.OwnerName.Label }),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.OwnerEmail.InputName,
            header: SubOwnerFormFields.OwnerEmail.Label,
            required: true,
            type: 'email',
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.OwnerEmail.Label }),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.OwnerPhone.InputName,
            header: SubOwnerFormFields.OwnerPhone.Label,
            required: true,
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.OwnerPhone.Label }),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.Address.InputName,
            header: SubOwnerFormFields.Address.Label,
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.Address.Label }),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.ZipCode.InputName,
            header: SubOwnerFormFields.ZipCode.Label,
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.ZipCode.Label }),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.City.InputName,
            header: SubOwnerFormFields.City.Label,
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.City.Label }),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.ContactName.InputName,
            header: SubOwnerFormFields.ContactName.Label,
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.ContactName.Label }),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.ContactEmail.InputName,
            header: SubOwnerFormFields.ContactEmail.Label,
            type: 'email',
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.ContactEmail.Label }),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <InputFieldFactory
          field={{
            name: SubOwnerFormFields.ContactPhone.InputName,
            header: SubOwnerFormFields.ContactPhone.Label,
            placeholder: intl.formatMessage({ id: SubOwnerFormFields.ContactPhone.Label }),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ImageUploadArea label={SubOwnerFormFields.Logo.Label} initialFile={values.logo} onChange={onLogoChange} />
      </Grid>
    </Grid>
  );
};
