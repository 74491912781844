import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import Grid from '@mui/material/Grid';

import { isEmpty, startCase } from 'app/utils';
import { InputFieldFactory } from 'app/components';
import { TranslationKeys } from 'app/translations';
import type { ApiField } from 'app/types';

type UpdateCredentialFormProps = {
  fields: ApiField[];
};

export const UpdateCredentialForm = ({ fields }: UpdateCredentialFormProps) => {
  const intl = useIntl();

  const { initialValues, values } = useFormikContext<any>();

  const ownerSelectItems = React.useMemo(() => {
    return [
      {
        element: initialValues.ownerName,
        value: initialValues.ownerId,
      },
    ];
  }, [initialValues.ownerId, initialValues.ownerName]);

  const packageTypeSelectItems = React.useMemo(() => {
    return [
      {
        element: startCase(initialValues.packageType?.replace('-', ' ')),
        value: initialValues.packageType,
      },
    ];
  }, [initialValues.packageType]);

  const hasSelectedPackageTypeAndHaveFields = values.packageType && !isEmpty(fields);

  return (
    <>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12}>
          <InputFieldFactory
            disabled
            field={{
              type: 'select',
              name: 'ownerId',
              label: intl.formatMessage({ id: TranslationKeys.credentials_owner }),
              items: ownerSelectItems,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputFieldFactory
            disabled
            field={{
              type: 'select',
              name: 'packageType',
              label: intl.formatMessage({ id: TranslationKeys.credentials_packageType }),
              items: packageTypeSelectItems,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {hasSelectedPackageTypeAndHaveFields &&
          fields.map(field => {
            return (
              <Grid key={field.fieldName} item xs={12}>
                <InputFieldFactory
                  disabled={field.fieldOptions?.disabled ?? false}
                  field={{
                    name: field.fieldName,
                    required: field.fieldOptions?.required ?? false,
                    label: intl.formatMessage({ id: `onboarding.credentials.new.${field.fieldName}` }),
                    type: field.fieldType,
                    options: field.fieldOptions,
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};
