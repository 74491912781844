import { useMutation } from '@tanstack/react-query';

import type { DeclineProcessRequestParams } from '../../../Domain/Connections/Services/ProcessesService';
import { processesService } from '../../../Domain/Connections/Services/ProcessesService';

export type ProcessDeclineData = DeclineProcessRequestParams;

const mutationFn = (data: ProcessDeclineData) => {
  return processesService.declineProcessRequest(data);
};

export const useProcessDeclineMutation = () => {
  return useMutation<unknown, unknown, ProcessDeclineData>(mutationFn);
};
