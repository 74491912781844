import { styled } from '@mui/material/styles';
import PaperBackground from '../PaperBackground';
import { Grid } from '@mui/material';

import { InputFieldFactory } from '../FormikField';

export const PaperBackgroundStyled = styled(PaperBackground, {
  name: 'PaperBackgroundStyled',
  shouldForwardProp: (propName: string) => propName !== 'outlined',
})<{ outlined: boolean }>(({ outlined }) => {
  if (outlined) {
    return {
      backgroundColor: 'unset',
    };
  }
});

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SearchInputFieldFactoryStyled = styled(InputFieldFactory, {
  name: 'SearchInputFieldFactoryStyled',
})(() => ({
  maxHeight: '38px',
  marginTop: 'auto',
  marginBottom: 'auto',
}));
