import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Grid, Skeleton } from '@mui/material';

import type { Insurer } from '../../../../../types';

import type { SelectOwnerInsurersMapSelectedState, TableSelectedState } from '../../../../../store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from '../../../../../store';
import {
  useOwnerGetEmployersQuery,
  useOwnerGetOwnerQuery,
  useDialog,
  useOwnerGetSubOwners,
} from '../../../../../hooks';

import { TranslationKeys } from '../../../../../translations';

import { OWNER, SUBOWNER } from '../../../../../common/Authorization/entities';
import { EDIT, VIEW } from '../../../../../common/Authorization/permissions';

import { ViewDialog, HasAccessTo, TableSkeleton } from '../../../../../components';
import DetailsPanelRow from '../../../../../components/DetailsPanelRow';
import DetailsPanel from '../../../../../components/DetailsPanel';
import ActionButton from '../../../../../components/ActionButton';

import { InsurersTable } from '../../../../Insurers/Components/InsurersTable';
import { EmployersTable } from '../../../../Employers/Components';

import { EditOwnerDialog } from '../../../Components/EditOwnerDialog';

import { DivButtonsContainerStyled } from './OwnerInformationTab.styles';
import { OwnerSubOwners } from './OwnerSubOwners';

export const OwnerInformationTab = () => {
  const dispatch = useStoreDispatch();
  const { ownerId } = useParams();
  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const { page: subOwnerEmployersPage, pageSize: subOwnerEmployersPageSize } = useStoreSelector<TableSelectedState>(
    state => state.EmployersReducer,
  );

  const {
    dialogState: editOwnerDialogOpen,
    closeDialog: editOwnerCloseDialog,
    openDialog: editOwnerOpenDialog,
  } = useDialog();

  const {
    dialogState: viewSubOwnersDialogOpen,
    closeDialog: viewSubOwnersCloseDialog,
    openDialog: viewSubOwnersOpenDialog,
  } = useDialog();

  const hasOwnerId = !!ownerId;
  const {
    data: owner,
    isLoading: isOwnerLoading,
    refetch: refetchGetOwnerQuery,
  } = useOwnerGetOwnerQuery({
    variables: { ownerId: ownerId || '' },
    options: {
      enabled: hasOwnerId,
    },
  });

  const { data: subOwners, isLoading: isLoadingSubOwners } = useOwnerGetSubOwners({
    variables: { userId, page: 1, perPage: 500 },
    options: {},
  });

  const isSubOwner = !!owner?.parentId;
  const { data: subOwnerEmployersData, isLoading: isLoadingSubOwnerEmployers } = useOwnerGetEmployersQuery({
    variables: {
      userId: userId || '',
      page: subOwnerEmployersPage,
      perPage: subOwnerEmployersPageSize,
      ownerId: ownerId || '',
    },
    options: {
      enabled: isSubOwner,
    },
  });

  const {
    loading: insurersLoading,
    items: insurers,
    page,
    pageSize,
    totalCount,
  } = useStoreSelector<TableSelectedState<Insurer>>(state =>
    StoreSelectors.InsurersSelector.selectInsurers(state.InsurersReducer),
  );

  const {
    loading: ownerInsurersLoading,
    map: ownerInsurersMap,
    ownerId: mapOwnerId,
  } = useStoreSelector<SelectOwnerInsurersMapSelectedState>(state =>
    StoreSelectors.InsurersSelector.selectOwnerInsurersMap(state.InsurersReducer),
  );

  const isOwner = owner?.parentId === null;
  const disabledIfNoAccessToEdit = isOwner ? !HasAccessTo(OWNER, EDIT) : !HasAccessTo(SUBOWNER, EDIT);
  const disabledIfNoAccessToSubOwnerView = !HasAccessTo(SUBOWNER, VIEW);

  const disabledEditButtonIfLoadingOrNoOwnerOrNoAccess = isOwnerLoading || !owner?.ownerId || disabledIfNoAccessToEdit;

  const disabledShowSubOwnersButtonIfLoadingOrNoAccessOrNoSubOwners =
    isLoadingSubOwners || disabledIfNoAccessToSubOwnerView || !subOwners?.data.length;

  React.useEffect(() => {
    if (owner && isOwner && owner.ownerId !== mapOwnerId) {
      // @ts-ignore
      dispatch(StoreActions.InsurersActions.ownerInsurers.setOwnerId(owner.ownerId));
    }
  }, [dispatch, isOwner, mapOwnerId, owner]);

  React.useEffect(() => {
    if (isOwner) {
      dispatch(StoreActions.InsurersActions.insurers.requestData());
    }
  }, [dispatch, isOwner]);

  const onClickEditButton = React.useCallback(() => {
    editOwnerOpenDialog();
  }, [editOwnerOpenDialog]);

  const onClickViewSubOwnersButton = React.useCallback(() => {
    viewSubOwnersOpenDialog();
  }, [viewSubOwnersOpenDialog]);

  const onEmployersTablePaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.EmployersActions.setPageSize(rowSize, false));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.EmployersActions.changePage(page, false));
      }
    },
    [dispatch],
  );

  const onInsurersTablePaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.InsurersActions.insurers.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.InsurersActions.insurers.changePage(page));
      }
    },
    [dispatch],
  );

  const insurersTableData = React.useMemo(() => {
    if (insurersLoading || ownerInsurersLoading) {
      return [];
    }

    return (insurers || []).map(insurer => {
      if (ownerInsurersMap && insurer.insurerId in ownerInsurersMap) {
        return {
          ...insurer,
          agencyNumber: ownerInsurersMap[insurer.insurerId].agencyNumber,
        };
      }

      return {
        ...insurer,
        agencyNumber: null,
      };
    });
  }, [ownerInsurersMap, insurers, insurersLoading, ownerInsurersLoading]);

  const ownerTitle = React.useMemo(() => {
    if (owner?.ownerName) {
      return (
        <span>
          {owner.ownerName}
          {owner.subOwners?.length ? ' : ' + owner.subOwners?.length + ' ' : null}
          {owner.subOwners?.length ? <FormattedMessage id={TranslationKeys.owners_subOwners} /> : null}
        </span>
      );
    }
    return <Skeleton variant={'text'} width={200} />;
  }, [owner]);

  const DetailsPanelBody = React.useMemo(() => {
    const infoRow = {
      [TranslationKeys.owners_ownerName]: owner?.ownerName,
      [TranslationKeys.owners_subOwners]: owner?.subOwners?.length ?? 0,
      [TranslationKeys.owners_address]: owner?.address,
      [TranslationKeys.owners_zipCode]: owner?.zipCode,
      [TranslationKeys.owners_city]: owner?.city,
      [TranslationKeys.owners_ownerEmail]: owner?.ownerEmail,
      [TranslationKeys.owners_ownerPhone]: owner?.ownerPhone,
    };

    const contactRow = {
      [TranslationKeys.owners_contactName]: owner?.contactName,
      [TranslationKeys.owners_ownerEmail]: owner?.contactEmail,
      [TranslationKeys.owners_ownerPhone]: owner?.contactPhone,
    };

    if (!isOwner) {
      // @ts-ignore
      delete infoRow[TranslationKeys.owners_subOwners];
    }

    return (
      <>
        <DetailsPanelRow rows={infoRow} loading={isOwnerLoading} />
        <DetailsPanelRow rows={contactRow} loading={isOwnerLoading} />
      </>
    );
  }, [
    isOwner,
    owner?.address,
    owner?.city,
    owner?.contactEmail,
    owner?.contactName,
    owner?.contactPhone,
    owner?.ownerEmail,
    owner?.ownerName,
    owner?.ownerPhone,
    owner?.subOwners?.length,
    owner?.zipCode,
    isOwnerLoading,
  ]);

  const DetailsPanelActions = React.useMemo(() => {
    return (
      <DivButtonsContainerStyled>
        {isOwner && (
          <ActionButton
            variant="outlined"
            messageId={TranslationKeys.owners_showSubOwners}
            onClick={onClickViewSubOwnersButton}
            disabled={disabledShowSubOwnersButtonIfLoadingOrNoAccessOrNoSubOwners}
          />
        )}
        <ActionButton
          messageId={TranslationKeys.global_edit}
          disabled={disabledEditButtonIfLoadingOrNoOwnerOrNoAccess}
          onClick={onClickEditButton}
        />
      </DivButtonsContainerStyled>
    );
  }, [
    disabledEditButtonIfLoadingOrNoOwnerOrNoAccess,
    disabledShowSubOwnersButtonIfLoadingOrNoAccessOrNoSubOwners,
    isOwner,
    onClickEditButton,
    onClickViewSubOwnersButton,
  ]);

  const Table = React.useMemo(() => {
    if (isOwner) {
      return (
        <InsurersTable
          insurers={insurersTableData}
          totalCount={totalCount}
          page={page}
          rowsPerPage={pageSize}
          onPaginationChange={onInsurersTablePaginationChange}
          loading={insurersLoading || ownerInsurersLoading}
        />
      );
    }

    if (isSubOwner) {
      return (
        <EmployersTable
          onPaginationChange={onEmployersTablePaginationChange}
          employers={subOwnerEmployersData?.data || []}
          totalCount={subOwnerEmployersData?.totalCount ?? 0}
          page={subOwnerEmployersPage}
          rowsPerPage={subOwnerEmployersPageSize}
          loading={isLoadingSubOwnerEmployers}
        />
      );
    }

    return <TableSkeleton />;
  }, [
    insurersLoading,
    insurersTableData,
    isLoadingSubOwnerEmployers,
    isOwner,
    isSubOwner,
    onEmployersTablePaginationChange,
    onInsurersTablePaginationChange,
    ownerInsurersLoading,
    page,
    pageSize,
    subOwnerEmployersData?.data,
    subOwnerEmployersData?.totalCount,
    subOwnerEmployersPage,
    subOwnerEmployersPageSize,
    totalCount,
  ]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <DetailsPanel
                header={<FormattedMessage id={TranslationKeys.owners_contactInformation} />}
                body={DetailsPanelBody}
                actions={DetailsPanelActions}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={8}>
          <Grid container>
            <Grid item xs={12}>
              {Table}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {editOwnerDialogOpen && !!owner && (
        <EditOwnerDialog
          open={editOwnerDialogOpen}
          onClose={editOwnerCloseDialog}
          owner={owner}
          refetchGetOwnerQuery={refetchGetOwnerQuery}
        />
      )}

      {viewSubOwnersDialogOpen && !!subOwners?.data && (
        <ViewDialog
          open={viewSubOwnersDialogOpen}
          setOpen={viewSubOwnersOpenDialog}
          title={ownerTitle}
          onClose={viewSubOwnersCloseDialog}
        >
          <OwnerSubOwners subOwners={subOwners?.data} />
        </ViewDialog>
      )}
    </>
  );
};
