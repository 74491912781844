import React from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { TranslationKeys } from 'app/translations';

import type { EmployeeEmploymentSchedule, UseQueryRefetch } from 'app/types';

import type { ShowSnakeBarParams } from 'app/hooks';
import {
  useEmployeeCreateEmploymentScheduleMutation,
  useEmployeeUpdateEmploymentScheduleMutation,
  useSnakeBar,
} from 'app/hooks';

import type {
  CreateEmployeeEmploymentScheduleArgs,
  UpdateEmployeeEmploymentScheduleArgs,
} from 'app/Domain/Employees/Services/EmployeeService';

import type { EmployeeEmploymentScheduleFormValues } from '../../EmployeeEmploymentSchedules.types';

import { EmployeeEmploymentScheduleFormDialogBody } from './components';

type EmployeeEmploymentScheduleFormDialogProps = {
  open: boolean;
  closeDialog: () => void;
  employerId: string;
  connectionId: string;
  employeeId: string;
  employmentId: string | undefined;
  schedule: EmployeeEmploymentSchedule | null;
  refetchGetEmploymentsQuery: UseQueryRefetch;
};

export const EmployeeEmploymentScheduleFormDialog = ({
  open,
  closeDialog,
  employerId,
  connectionId,
  employeeId,
  employmentId,
  schedule,
  refetchGetEmploymentsQuery,
}: EmployeeEmploymentScheduleFormDialogProps) => {
  const intl = useIntl();
  const { showSnakeBar } = useSnakeBar();
  const { mutate: createEmploymentSchedule } = useEmployeeCreateEmploymentScheduleMutation();
  const { mutate: updateEmploymentSchedule } = useEmployeeUpdateEmploymentScheduleMutation();

  const isEdit = schedule !== null;

  const showSnackBarMessage = React.useCallback(
    ({ method, severity }: Omit<ShowSnakeBarParams, 'message'>) => {
      showSnakeBar({
        severity,
        method,
        message: intl.formatMessage({
          id:
            severity === 'success'
              ? TranslationKeys.global_update_successMessage
              : TranslationKeys.global_update_errorMessage,
        }),
      });
    },
    [intl, showSnakeBar],
  );

  const formikInitialValues = React.useMemo<EmployeeEmploymentScheduleFormValues>(() => {
    return {
      startDate: schedule?.startDate || null,
      endDate: schedule?.endDate || null,
      partTimePercentage: schedule?.partTimePercentage ?? '',
      startAveragePartTimePercentage: schedule?.startAveragePartTimePercentage ?? null,
      hoursPerWeek: schedule?.hoursPerWeek ?? '',
      fullTimeHoursPerWeek: schedule?.fullTimeHoursPerWeek ?? '',
    };
  }, [
    schedule?.startDate,
    schedule?.endDate,
    schedule?.partTimePercentage,
    schedule?.startAveragePartTimePercentage,
    schedule?.hoursPerWeek,
    schedule?.fullTimeHoursPerWeek,
  ]);

  const formikOnSubmit = React.useCallback<FormikConfig<EmployeeEmploymentScheduleFormValues>['onSubmit']>(
    (values, { setSubmitting, resetForm }) => {
      const onSuccess = (method: string) => () => {
        setSubmitting(false);
        resetForm();
        closeDialog();
        showSnackBarMessage({ method, severity: 'success' });
        refetchGetEmploymentsQuery();
      };

      const onError = (method: string) => () => {
        setSubmitting(false);
        showSnackBarMessage({ method, severity: 'error' });
      };

      const mutationData = {
        connectionId,
        employeeId,
        employerId,
        employmentId: employmentId!,
        schedule: {
          scheduleId: schedule?.scheduleId,
          externalId: schedule?.externalId,
          startDate: values.startDate,
          endDate: values.endDate || null,
          partTimePercentage: values.partTimePercentage,
          startAveragePartTimePercentage: values.startAveragePartTimePercentage,
          hoursPerWeek: values.hoursPerWeek,
          fullTimeHoursPerWeek: values.fullTimeHoursPerWeek,
        },
      } as CreateEmployeeEmploymentScheduleArgs | UpdateEmployeeEmploymentScheduleArgs;

      if (isEdit) {
        const method = 'updateEmploymentSchedule';
        updateEmploymentSchedule(mutationData as UpdateEmployeeEmploymentScheduleArgs, {
          onSuccess: onSuccess(method),
          onError: onError(method),
        });
      } else {
        const method = 'createEmploymentSchedule';
        createEmploymentSchedule(mutationData, {
          onSuccess: onSuccess(method),
          onError: onError(method),
        });
      }
    },
    [
      connectionId,
      employeeId,
      employerId,
      employmentId,
      schedule?.scheduleId,
      schedule?.externalId,
      isEdit,
      closeDialog,
      showSnackBarMessage,
      refetchGetEmploymentsQuery,
      updateEmploymentSchedule,
      createEmploymentSchedule,
    ],
  );

  return (
    <Formik<EmployeeEmploymentScheduleFormValues> initialValues={formikInitialValues} onSubmit={formikOnSubmit}>
      <EmployeeEmploymentScheduleFormDialogBody open={open} isEdit={isEdit} closeDialog={closeDialog} />
    </Formik>
  );
};
