import { styled } from '@mui/material/styles';
import { Avatar, Popper, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(() => ({
  alignItems: 'center',
  display: 'flex',
  minHeight: 'fit-content',
}));

export const AvatarStyled = styled(Avatar, {
  name: 'AvatarStyled',
})(() => ({
  fontSize: '0.875rem', // 14px
  height: 30,
  width: 30,
}));

export const TypographyNameStyled = styled(Typography, {
  name: 'TypographyNameStyled',
})(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '0.875rem', // 14px,
  paddingLeft: theme.spacing(1),
  fontWeight: theme.typography.fontWeightRegular,
}));

export const FontAwesomeIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconStyled',
})(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  paddingLeft: theme.spacing(0.5),
}));

export const PopperMenuStyled = styled(Popper, {
  name: 'PopperMenuStyled',
})(() => ({
  minWidth: '175px',
}));

export const FontAwesomeIconMenuIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconMenuIconStyled',
})(({ theme }) => ({
  paddingRight: theme.spacing(1),
}));
