import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Skeleton, Typography } from '@mui/material';

import { TranslationKeys } from '../../../../../translations';

import { useInsurerGetInsurerQuery } from '../../../../../hooks';

import { NotFoundView } from '../../../../../views';

import TabOutlet from '../../../../../components/TabOutlet';

export const InsurerProductRegulations = () => {
  const { insurerId, productId } = useParams();

  const { data: insurer, isLoading: isLoadingInsurer } = useInsurerGetInsurerQuery({
    variables: { insurerId },
    options: {
      enabled: !!insurerId,
    },
  });

  const product = React.useMemo(() => {
    return insurer?.products?.find(product => product.productId === productId);
  }, [insurer?.products, productId]);

  const exists = isLoadingInsurer || (!isLoadingInsurer && !!insurer && !!product);

  const tabData = React.useMemo(() => {
    return [
      {
        path: 'summary',
        label: <FormattedMessage id={TranslationKeys.insurers_tab_summary} />,
      },
    ];
  }, []);

  const title = React.useMemo(() => {
    if (product?.productName) {
      return <Typography variant="h5">{product.productName}</Typography>;
    }
    return (
      <Typography variant="h5">
        <Skeleton variant={'text'} width={200} />
      </Typography>
    );
  }, [product?.productName]);

  const breadcrumb = React.useMemo(() => {
    return {
      title: product?.productName,
      data: {
        titleElement: title,
      },
    };
  }, [product?.productName, title]);

  return (
    <>
      {exists && (
        // @ts-ignore
        <TabOutlet tabs={tabData} breadcrumb={breadcrumb} />
      )}

      {!exists && (
        <Routes>
          <Route element={<NotFoundView />} path="*" />
        </Routes>
      )}
    </>
  );
};
