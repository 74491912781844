import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

import type { SecurityInputProps } from '../../../../../../components';
import { ActionLink, SecurityInput } from '../../../../../../components';

import { TranslationKeys } from '../../../../../../translations';

export const SIGN_IN_VIEW_2FA_SECURITY_CODE_INPUT_DATA_TEST_ID = 'sign-in-view-2fa-security-code-input-component';

type SignInViewTwoFactorAuthSecurityCodeInputProps = {
  onCodeComplete: SecurityInputProps['onCodeComplete'];
  twoFaFailed: boolean;
};

export const SignInViewTwoFactorAuthSecurityCodeInput = ({
  twoFaFailed,
  onCodeComplete,
}: SignInViewTwoFactorAuthSecurityCodeInputProps) => {
  return (
    <Box
      data-testid={SIGN_IN_VIEW_2FA_SECURITY_CODE_INPUT_DATA_TEST_ID}
      display={'flex'}
      flexDirection={'column'}
      gap={3}
    >
      <Typography variant="h5">
        <FormattedMessage id={TranslationKeys.signIn_twofa_verify} />
      </Typography>

      {twoFaFailed && (
        <Alert severity="error">
          <FormattedMessage id={TranslationKeys.signIn_twofa_verify_failed} />
        </Alert>
      )}

      <Box>
        <SecurityInput onCodeComplete={onCodeComplete}></SecurityInput>
      </Box>

      <Box marginLeft={1}>
        <ActionLink to="/forgot-2fa">
          <Typography variant={'subtitle2'}>
            <FormattedMessage id={TranslationKeys.signIn_twofa_lost2fa} />
          </Typography>
        </ActionLink>
      </Box>
    </Box>
  );
};
