import type { Product } from './product.type';
import type { EmployeeEmploymentWagePeriodEnum } from 'app/types/employee.types';

export type ConnectionNote = {
  '@id': string;
  '@type': string;
  noteId: string;
  text: string;
};

export enum ConnectionSourcePackageTypeEnum {
  FileUpload = 'file-upload',
  Manual = 'manual-input',
  API = 'api',
}

export type ConnectionPackageSettings = {
  '@id': string;
  '@type': string;
  businessUnitFID: number;
  perBatch: number;
  adequatePensionScheme: boolean;
  annualWageFactor: number;
  fullTimeHoursPerWeek: number;
  includeEmploymentsEqGtDate: string;
  pensionSchemeEndAge: number;
  pensionSchemeStartAge: number;
  wageComponentCodes: string[];
  workedHoursComponentCodes: string[];
  wagePeriod: EmployeeEmploymentWagePeriodEnum;
  listedEmployeesType?: string;
  listedEmployees?: ListedEmployee[];
};

export type ConnectionPackage = {
  '@id': string;
  '@type': string;
  credentialId: string;
  packageType: ConnectionSourcePackageTypeEnum | string;
  settings: ConnectionPackageSettings;
};

export const EVENT_TYPES = [
  'new-employee',
  'contract-updated',
  'employee-address-updated',
  'employee-information-updated',
  'employee-part-time-percentage-updated',
  'employee-partner-updated',
  'gross-wage-updated',
  'employee-left-company',
  'new-periodic-wage',
  're-employed',
] as const;

export enum ConnectionEventTypes {
  NewEmployee = 'new-employee',
  ContractUpdated = 'contract-updated',
  EmployeeAddressUpdated = 'employee-address-updated',
  EmployeeInformationUpdated = 'employee-information-updated',
  EmployeePartTimePercentageUpdated = 'employee-part-time-percentage-updated',
  EmployeePartnerUpdated = 'employee-partner-updated',
  GrossWageUpdated = 'gross-wage-updated',
  EmployeeLeftCompany = 'employee-left-company',
  NewPeriodicWage = 'new-periodic-wage',
  ReEmployed = 're-employed',
}

export const EVENT_TYPE_MAPPER: Record<EventType, string> = {
  'new-employee': 'newEmployee',
  'contract-updated': 'contractUpdated',
  'employee-address-updated': 'employeeAddressUpdated',
  'employee-information-updated': 'employeeInformationUpdated',
  'employee-part-time-percentage-updated': 'employeePartTimePercentageUpdated',
  'employee-partner-updated': 'employeePartnerUpdated',
  'gross-wage-updated': 'grossWageUpdated',
  'employee-left-company': 'employeeLeftCompany',
  'new-periodic-wage': 'newPeriodicWage',
  're-employed': 'reEmployed',
} as const;

export type EventType = (typeof EVENT_TYPES)[number];

export type ConnectionEvent = {
  '@id': string;
  '@type': string;
  mongoId: string;
  externalId: string;
  connectionId: Connection['connectionId'];
  batchId: string;
  eventDate: string;
  eventType: EventType;
  processed: boolean;
  state: 'state.sent' | 'state.processing' | 'events.archived' | 'events.new';
  createdAt: string;
  periodStart?: string;
  periodEnd?: string;
  correction?: boolean;
  initials?: string;
  firstName?: string;
  middleName?: string;
  lastNamePrefix?: string;
  lastName?: string;
  archived?: boolean;
  batch?: {
    '@id': string;
    '@type': string;
    batchStatus: string;
    triggeredAt: string;
  };
  employment?: {
    '@type': string;
    employmentNumber: string;
    probationDate: string;
    endDate: string | null;
    startDate: string;
  };
  address?: {
    '@type': string;
    city: string;
    country: string;
    houseNumber: string;
    houseNumberSuffix: string;
    street: string;
    zipCode: string;
  };
  employee?: {
    employmentStart?: string;
  };
};

export type ConnectionTask = {
  '@context': string;
  '@id': string;
  '@type': string;
  taskId: string;
  taskStatus: string;
  jobId: string;
  moduleId: string;
  connectionId: Connection['connectionId'];
  connection: string;
  createdAt: string;
  startedAt: string;
  updatedAt: string;
  finishedAt: string;
  complete: boolean;
  failed: boolean;
  timedOut: boolean;
  parentTask: string | null;
  sequential: boolean;
  sequence: unknown | null;
  parameters: {
    connectionId: Connection['connectionId'];
  };
};

export type Connection = {
  '@context': string;
  '@id': string;
  '@type': string;
  connectionId: string;
  connectionName: string;
  connectionType: string | null;
  createdAt: string;
  lastRun: string | null;
  deleted: boolean;
  employerId: string | null;
  notes: Array<ConnectionNote>;
  packages: Array<ConnectionPackage>;
  productId: string | null;
  product: Product | null;
};

export type ListedEmployee = {
  employeeName: string;
  employeeNumber: string;
  employeeSocialSecurityNumber: string;
};
