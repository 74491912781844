import { outlinedInputClasses, svgIconClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiOutlinedInput: FuncOverrideComponent<'MuiOutlinedInput'> = theme => ({
  styleOverrides: {
    root: {
      borderRadius: '5px',
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.common.white,
      minWidth: '150px',
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.palette.primary.dark,
      },
      [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderWidth: '1px',
      },
      [`&.${outlinedInputClasses.sizeSmall} .${svgIconClasses.root}`]: {
        top: 'calc(50% - 10px)',
        width: '20px',
        height: '20px',
        right: '0px',
      },
    },
    notchedOutline: {
      borderColor: theme.palette.secondary.main,
      fontSize: theme.typography.caption.fontSize,
      letterSpacing: theme.typography.caption.letterSpacing,
      textTransform: theme.typography.caption.textTransform,
      padding: theme.spacing(0.75),
    },
    input: {
      padding: theme.spacing(1.25, 1.5),
    },
    inputSizeSmall: {
      fontSize: '0.8125rem', // 13px
    },
  },
});
