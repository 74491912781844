import React from 'react';

import { Paper, useMediaQuery, useTheme } from '@mui/material';

import { Header, NotificationBar, Sidebar, TopBar } from './components';

import { DivStyled, MainStyled } from './MainLayout.styles';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const [openSidebar, setOpenSidebar] = React.useState(false);

  const handleSidebarOpen = React.useCallback(() => {
    setOpenSidebar(true);
  }, []);

  const handleSidebarClose = React.useCallback(() => {
    setOpenSidebar(false);
  }, []);

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <DivStyled isDesktop={isDesktop}>
      <TopBar onSidebarOpen={handleSidebarOpen} />
      <Sidebar onClose={handleSidebarClose} open={shouldOpenSidebar} variant={isDesktop ? 'persistent' : 'temporary'} />
      <MainStyled>
        <Paper elevation={0} square>
          <Header />
          {children}
        </Paper>
      </MainStyled>
      <NotificationBar />
    </DivStyled>
  );
};
