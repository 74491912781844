import { TranslationKeys } from 'app/translations';

import type { EmploymentTypeEnum, YesNoEnum } from 'app/types';

import { normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc } from 'app/components';

export const EmployeeEmploymentDetailsFields = {
  SequenceEmploymentId: {
    Label: TranslationKeys.employees_detail_sequenceEmploymentId,
    InputName: 'sequenceEmploymentId',
    Options: {
      style: 'text',
    },
  },
  StartDate: {
    Label: TranslationKeys.employees_detail_employmentStart,
    InputName: 'startDate',
    Options: {
      style: 'date',
    },
  },
  CalculationStartDate: {
    Label: TranslationKeys.employees_detail_calculationStartDate,
    InputName: 'calculationStartDate',
    Options: {
      style: 'date',
    },
  },
  YearsFrom: {
    Label: TranslationKeys.employees_detail_employmentYearsFrom,
    InputName: 'yearsFrom',
    Options: {
      style: 'date',
    },
  },
  EndDate: {
    Label: TranslationKeys.employees_detail_employmentEnd,
    InputName: 'endDate',
    Options: {
      style: 'date',
    },
  },
  ProbationDate: {
    Label: TranslationKeys.employees_detail_probationDate,
    InputName: 'probationDate',
    Options: {
      style: 'date',
    },
  },
  EmploymentType: {
    Label: TranslationKeys.employees_detail_employmentType,
    InputName: 'employmentType',
    Options: {
      style: 'select',
    },
  },
  JobDescription: {
    Label: TranslationKeys.employees_detail_jobDescription,
    InputName: 'jobDescription',
    Options: {
      style: 'text',
    },
  },
  DeductionPlan: {
    Label: TranslationKeys.employees_detail_deductionPlan,
    InputName: 'deductionPlan',
    Options: {
      style: 'text',
    },
  },
  VacationPay: {
    Label: TranslationKeys.employees_detail_vacationPay,
    InputName: 'vacationPay',
    Options: {
      style: 'text',
    },
  },
  VacationPayPercentage: {
    Label: TranslationKeys.employees_detail_vacationPayPercentage,
    InputName: 'vacationPayPercentage',
    Options: {
      style: 'percent',
      openScale: true,
      min: 0,
      max: 100,
    },
  },
  ExtraMonth: {
    Label: TranslationKeys.employees_detail_extraMonth,
    InputName: 'extraMonth',
    Options: {
      style: 'autocomplete',
    },
  },
  OtherWageComponents: {
    Label: TranslationKeys.employees_detail_otherWageComponents,
    InputName: 'otherWageComponents',
    Options: {
      style: 'autocomplete',
    },
  },
  GrossWage: {
    Label: TranslationKeys.global_grossWage,
    InputName: 'grossWage',
    Options: {
      style: 'currency',
      currency: 'EUR',
      openScale: true,
      min: 0,
    },
  },
  PartTimePercentage: {
    Label: TranslationKeys.global_partTimePercentage,
    InputName: 'partTimePercentage',
    Options: {
      style: 'percent',
      openScale: true,
      min: 0,
      max: 100,
    },
  },
  Emoluments: {
    Label: TranslationKeys.employees_detail_emoluments,
    InputName: 'emoluments',
    Options: {
      style: 'currency',
      currency: 'EUR',
      openScale: true,
      min: 0,
    },
  },
} as const;

export const EmployeeEmploymentDetailsFormFieldOptionsAndLabel =
  normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc(EmployeeEmploymentDetailsFields);

export type EmployeeEmploymentDetailsFormValues = {
  [EmployeeEmploymentDetailsFields.SequenceEmploymentId.InputName]: string | null;
  [EmployeeEmploymentDetailsFields.StartDate.InputName]: string | null;
  [EmployeeEmploymentDetailsFields.YearsFrom.InputName]: string | null;
  [EmployeeEmploymentDetailsFields.EndDate.InputName]: string | null;
  [EmployeeEmploymentDetailsFields.ProbationDate.InputName]: string | null;
  [EmployeeEmploymentDetailsFields.EmploymentType.InputName]: EmploymentTypeEnum | '';
  [EmployeeEmploymentDetailsFields.JobDescription.InputName]: string;
  [EmployeeEmploymentDetailsFields.Emoluments.InputName]: string | null;
  [EmployeeEmploymentDetailsFields.DeductionPlan.InputName]: string;
  [EmployeeEmploymentDetailsFields.VacationPay.InputName]: YesNoEnum | '';
  [EmployeeEmploymentDetailsFields.VacationPayPercentage.InputName]: number | string;
  [EmployeeEmploymentDetailsFields.ExtraMonth.InputName]: YesNoEnum | '';
  [EmployeeEmploymentDetailsFields.OtherWageComponents.InputName]: YesNoEnum | '';
  [EmployeeEmploymentDetailsFields.GrossWage.InputName]: number | string | null;
  [EmployeeEmploymentDetailsFields.PartTimePercentage.InputName]: number | string | null;
  [EmployeeEmploymentDetailsFields.CalculationStartDate.InputName]: string | null;
};
