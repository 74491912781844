import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { TranslationKeys } from '../../../translations';

import { OWNER } from '../../../common/Authorization/entities';
import { EDIT } from '../../../common/Authorization/permissions';

import { RestrictAccess, useBreadcrumb } from '../../../components';

import { ToolsManagementPage } from './ToolsManagementPage';
import { UPAXmlSplitterPage } from './UPAXmlSplitterPage';

export const ToolsPages = () => {
  useBreadcrumb(TranslationKeys.menu_tools);

  return (
    <Routes>
      <Route element={<RestrictAccess entity={OWNER} permission={EDIT} />}>
        <Route element={<UPAXmlSplitterPage />} path={'splitter'} />
      </Route>

      <Route element={<ToolsManagementPage />} path={'overview'} />
      <Route index element={<Navigate to="overview" replace />} />
      <Route path="*" element={<Navigate to="overview" replace />} />
    </Routes>
  );
};
