import React from 'react';

import type { EmployeeEmployment } from '../../../types/employee.types';

import type { GetEmployeeEmploymentsArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

import { useSnakeBar } from '../../useSnakeBar';
import type { UseQueryWrapper } from '../../../types/useQuery.types';
import { useQuery } from '@tanstack/react-query';

export type GetEmployeeEmploymentsQueryResultData = {
  data: Array<
    EmployeeEmployment & {
      employeeId: string;
      connectionId: string;
    }
  >;
  totalCount: number;
};

type GetEmployeeEmploymentsQueryVariables = {
  [Key in keyof GetEmployeeEmploymentsArgs]: GetEmployeeEmploymentsArgs[Key] | undefined;
};

const queryFn =
  ({ employeeId, employerId }: GetEmployeeEmploymentsQueryVariables) =>
  async () => {
    if (employerId && employeeId) {
      const response = await employeeService.getEmployeeEmployments({
        employerId,
        employeeId,
      });
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployeeEmployments';

export const useEmployeeGetEmploymentsQuery: UseQueryWrapper<
  GetEmployeeEmploymentsQueryResultData,
  GetEmployeeEmploymentsQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetEmployeeEmploymentsQueryResultData>([QUERY_KEY, variables.employeeId], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
