import React, { useEffect } from 'react';
import { Card, CardContent, Chip, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Skeleton from '@mui/material/Skeleton';
import { actions as CredentialActions } from '../../Ducks/Credentials.duck';
import { Link as RouterLink } from 'react-router-dom';
import { DialogStyled, DialogTitleStyled } from './CredentialConnectionsModal.styles';

const CredentialConnectionsModal = props => {
  const { className, show, hide } = props;

  const dispatch = useDispatch();
  const { credentialId, credentialAccess, loading } = useSelector(state => state.CredentialsReducer);

  useEffect(() => {
    if (credentialId) {
      dispatch(CredentialActions.requestConnections(credentialId));
    }
  }, [credentialId, dispatch]);

  const placeholderRowsCount = 10;
  return (
    <DialogStyled open={show} maxWidth="lg" fullWidth>
      <DialogTitleStyled>
        <Typography variant="h5" color="primary">
          <FormattedMessage id="button.details" />
        </Typography>
      </DialogTitleStyled>
      <DialogContent>
        <Card className={className}>
          <CardContent>
            <PerfectScrollbar>
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="connections.connectionName" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="connections.packages" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading &&
                      new Array(placeholderRowsCount)
                        .fill(index => index)
                        .map((value, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                          </TableRow>
                        ))}
                    {credentialAccess &&
                      credentialAccess.length > 0 &&
                      credentialAccess.map(access => (
                        <TableRow hover key={access['@id']}>
                          <TableCell>
                            <Link
                              component={RouterLink}
                              to={`/employers/${access.employerId}/connections/${access.connectionId}/info`}
                            >
                              {access.connectionName}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {access.packages.map((p, index) => (
                              <Chip key={index} label={<FormattedMessage id={`packages.type.${p.packageType}`} />} />
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide}>
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};

export default CredentialConnectionsModal;
