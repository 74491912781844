import React from 'react';

import { Grid } from '@mui/material';

import type { Field } from 'app/types';

import { InputFieldFactory, Skeleton } from 'app/components';

type SettingsStepFieldsRenderProps = {
  fields: Field[];
  isLoading?: boolean;
};

export const SettingsStepFieldsRender = ({ fields, isLoading = false }: SettingsStepFieldsRenderProps) => {
  const sizes: Record<string, number> = {
    large: 12,
    medium: 8,
    small: 4,
  };

  const placeholders = React.useMemo(() => {
    return [4, 4, 12, 4, 4].map((w, i) => (
      <Grid key={i} item xs={w}>
        <Skeleton height={'24px'} />
      </Grid>
    ));
  }, []);

  return (
    <>
      {isLoading && placeholders}

      {!isLoading &&
        fields.map((field, index) => {
          const size = sizes[field.options?.size ?? (field.sizeHint === 'large' ? 'large' : 'small')];
          return (
            <Grid item key={index} xs={size}>
              <InputFieldFactory field={field} />
            </Grid>
          );
        })}
    </>
  );
};
