import { styled } from '@mui/material/styles';
import { DialogActions, LinearProgress, Tab } from '@mui/material';

import { ActionButton } from '../ActionButton';

export const LinearProgressStyled = styled(LinearProgress, {
  name: 'LinearProgressStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(1.75),
  height: theme.spacing(0.25),
}));

export const TabStyled = styled(Tab, {
  name: 'TabStyled',
  shouldForwardProp: (propName: string) => !['completed'].includes(propName),
})<{ completed: boolean }>(({ theme, completed }) => {
  if (completed) {
    return {
      opacity: 0.6,
      color: theme.palette.primary.main,
    };
  }

  return {};
});

export const DialogActionsStyled = styled(DialogActions, {
  name: 'DialogActionsStyled',
})(({ theme }) => ({
  padding: theme.spacing(6, 2.5, 2.5, 2.5),
  display: 'flex',
  justifyContent: 'center',
}));

export const ActionButtonStyled = styled(ActionButton, {
  name: 'ActionButtonStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
