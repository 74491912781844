import { styled } from '@mui/material/styles';
import { inputLabelClasses } from '@mui/material';
import { Field } from 'formik';

export const FieldStyled = styled(Field, {
  name: 'FieldStyled',
  shouldForwardProp: (propName: string) => propName !== 'slim',
})<{ slim: boolean }>(({ slim }) => ({
  // Needed because date picker does not provide a way to propagate margin=dense into the input label
  [`& .${inputLabelClasses.outlined}`]: {
    [`&:not(.${inputLabelClasses.shrink})`]: {
      transform: slim ? 'translate(10px, 1px) scale(1)' : 'translate(14px, 5px) scale(1)',
    },
  },
}));
