import React, { forwardRef } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Collapse, List } from '@mui/material';

import { HasAccessTo } from 'app/components/Authorization';

import type { Page } from '../../Sidebar.types';
import {
  ButtonStyled,
  ButtonToggleExpandStyled,
  ExpandLessIconStyled,
  ExpandMoreIconStyled,
  FontAwesomeIconStyled,
  ListItemChildrenStyled,
  ListItemStyled,
} from './SidebarPageGroup.styles';

type SidebarPageGroupProps = {
  page: Page;
};

const CustomRouterLink = (props: any, ref: any) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} />
  </div>
);

const CustomRouterLinkWithRef = forwardRef(CustomRouterLink);

export const SidebarPageGroup = ({ page }: SidebarPageGroupProps) => {
  const { pathname: currentPath } = useLocation();

  const [toggleChildren, setToggleChildren] = React.useState(false);

  const children = (page?.children ?? []).filter(item => HasAccessTo(item.entity, item.permission));

  const handleToggle = React.useCallback(() => setToggleChildren(!toggleChildren), [toggleChildren]);

  React.useEffect(() => {
    const toggle = page.children ? page.children.some(child => child.href === currentPath) : false;
    setToggleChildren(toggle);
  }, [currentPath, page]);

  if (page.entity && page.permission) {
    if (!HasAccessTo(page.entity, page.permission)) {
      if (children.length === 0) {
        return <></>;
      }

      return (
        <>
          {children.map((child, index) => (
            <ListItemStyled disableGutters key={index} data-testid="navbar-children-item" id={'navbar-children-item'}>
              <ButtonStyled
                // @ts-ignore
                component={CustomRouterLinkWithRef}
                to={child.href}
                variant={'text'}
              >
                <FontAwesomeIconStyled icon={page.iconName} />
                {child.title}
              </ButtonStyled>
            </ListItemStyled>
          ))}
        </>
      );
    }
  }

  return (
    <List disablePadding>
      <ListItemStyled disableGutters>
        <ButtonStyled
          data-testid="navbar-item"
          // @ts-ignore
          component={CustomRouterLinkWithRef}
          to={page.href}
          variant={'text'}
        >
          <FontAwesomeIconStyled icon={page.iconName} />
          {page.title}
        </ButtonStyled>
        {children.length > 0 && (
          <ButtonToggleExpandStyled onClick={handleToggle} variant={'text'}>
            {toggleChildren ? <ExpandLessIconStyled /> : <ExpandMoreIconStyled />}
          </ButtonToggleExpandStyled>
        )}
      </ListItemStyled>
      {children.length > 0 && (
        <Collapse in={toggleChildren} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((page, index) => (
              <ListItemChildrenStyled disableGutters key={index}>
                <ButtonStyled
                  data-testid="navbar-item-child"
                  // @ts-ignore
                  component={CustomRouterLinkWithRef}
                  to={page.href}
                  variant={'text'}
                >
                  {page.title}
                </ButtonStyled>
              </ListItemChildrenStyled>
            ))}
          </List>
        </Collapse>
      )}
    </List>
  );
};
