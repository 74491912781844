import React from 'react';

import type { TableSelectedState } from '../../../../store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from '../../../../store';

import { TranslationKeys } from '../../../../translations';

import {
  useDashboardGetUnprocessedBatchesQuery,
  useProcessApproveMutation,
  useProcessDeclineMutation,
  useSnakeBar,
} from '../../../../hooks';

import { useBreadcrumb } from '../../../../components';

import { UnprocessedTableFilters } from '../../Components';

import { UnprocessedBatchesTable } from './components';
import type { UnprocessedBatch } from '../../../../types';

export const DashboardUnprocessedBatchesPage = () => {
  const dispatch = useStoreDispatch();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const { mutate: processApproveMutation, isLoading: isLoadingProcessApproveMutation } = useProcessApproveMutation();
  const { mutate: processDeclineMutation, isLoading: isLoadingProcessDeclineMutation } = useProcessDeclineMutation();

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const { searchQuery, page, pageSize, filters, sortBy } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.DashboardSelector.selectUnprocessedBatches(state.DashboardReducer),
  );

  const {
    data: unprocessedBatches,
    isLoading: isLoadingUnprocessedBatchesQuery,
    isFetching: isFetchingUnprocessedBatchesQuery,
    refetch: refetchUnprocessedBatchesQuery,
  } = useDashboardGetUnprocessedBatchesQuery({
    variables: {
      userId,
      page,
      pageSize,
      searchQuery,
      filters,
      sortBy,
    },
    options: {
      enabled: !!userId,
    },
  });

  const isLoading =
    isLoadingUnprocessedBatchesQuery ||
    isFetchingUnprocessedBatchesQuery ||
    isLoadingProcessApproveMutation ||
    isLoadingProcessDeclineMutation;

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.DashboardAction.unprocessedBatches.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.DashboardAction.unprocessedBatches.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.DashboardAction.unprocessedBatches.changePage(page));
      }
    },
    [dispatch],
  );

  const onClickApproveBatch = React.useCallback(
    (unprocessedBatch: UnprocessedBatch) => {
      const method = 'approveProcess';

      processApproveMutation(
        {
          connectionId: unprocessedBatch.connectionId,
          batchId: unprocessedBatch.batchId,
        },
        {
          onSuccess: () => {
            refetchUnprocessedBatchesQuery();
            showSuccessSnakeBar({ method });
          },
          onError: () => {
            showErrorSnakeBar({ method });
          },
        },
      );
    },
    [processApproveMutation, refetchUnprocessedBatchesQuery, showErrorSnakeBar, showSuccessSnakeBar],
  );

  const onClickDeclineBatch = React.useCallback(
    (unprocessedBatch: UnprocessedBatch) => {
      const method = 'declineProcess';

      processDeclineMutation(
        {
          connectionId: unprocessedBatch.connectionId,
          batchId: unprocessedBatch.batchId,
        },
        {
          onSuccess: () => {
            refetchUnprocessedBatchesQuery();
            showSuccessSnakeBar({ method });
          },
          onError: () => {
            showErrorSnakeBar({ method });
          },
        },
      );
    },
    [processDeclineMutation, refetchUnprocessedBatchesQuery, showErrorSnakeBar, showSuccessSnakeBar],
  );

  useBreadcrumb(TranslationKeys.dashboard_category_dataProcessing, {
    customPathName: '/dashboard/data-processing',
  });
  useBreadcrumb(TranslationKeys.dashboard_subCategory_unprocessedBatches);

  return (
    <>
      <UnprocessedTableFilters
        isLoading={isLoading}
        reduxActionSearch={StoreActions.DashboardAction.unprocessedBatches.search}
        reduxActionApplyFilter={StoreActions.DashboardAction.unprocessedBatches.applyFilter}
        reduxActionRemoveFilter={StoreActions.DashboardAction.unprocessedBatches.removeFilter}
        reduxStateSelector={'selectUnprocessedBatches'}
      />

      <UnprocessedBatchesTable
        isLoading={isLoading}
        unprocessedBatches={unprocessedBatches?.data || []}
        page={page}
        pageSize={pageSize}
        totalCount={unprocessedBatches?.totalCount ?? 0}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        onClickApproveBatch={onClickApproveBatch}
        onClickDeclineBatch={onClickDeclineBatch}
      />
    </>
  );
};
