import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { Owner, UseQueryWrapper } from 'app/types';

import type { GetOwnersParams } from 'app/Domain/Owners/Services/OwnerService';
import OwnerService from '../../../Domain/Owners/Services/OwnerService';

import { useSnakeBar } from '../../useSnakeBar';

export type GetOwnersQueryVariables = GetOwnersParams;

export type GetOwnersQueryResultData = {
  data: Array<Owner>;
  totalCount: number;
};

const queryFn = (variables?: GetOwnersQueryVariables) => async () => {
  const response = await OwnerService.getOwners(variables);
  return (response as AxiosResponse).data;
};

const QUERY_KEY = 'getOwners';

export const useOwnerGetOwnersQuery: UseQueryWrapper<GetOwnersQueryResultData, GetOwnersQueryVariables, false> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetOwnersQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
