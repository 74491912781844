import { formHelperTextClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiFormHelperText: FuncOverrideComponent<'MuiFormHelperText'> = theme => ({
  styleOverrides: {
    root: {
      ...theme.typography.body2,
      textTransform: 'initial',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
      [`&.${formHelperTextClasses.error}`]: {
        marginLeft: theme.spacing(0.5),
      },
    },
    sizeSmall: {
      lineHeight: '12px',
    },
  },
});
