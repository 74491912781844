import { styled } from '@mui/material/styles';
import { FormLabel, inputClasses, Paper } from '@mui/material';

export const DivFieldStyled = styled('div', {
  name: 'DivFieldStyled',
})(({ theme }) => ({
  width: '40px',
  display: 'inline-block',
  marginRight: '10px',
  float: 'left',
  [`& .${inputClasses.input}`]: {
    border: '1px solid ' + theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    fontSize: '30px',
    height: '42px',
    margin: 0,
    padding: 0,
    textAlign: 'center',
    width: '42px',
    '&:focus': {
      border: '1px solid  ' + theme.palette.primary.main,
    },
  },
}));

export const PaperStyled = styled(Paper, {
  name: 'PaperStyled',
})(({ theme }) => ({
  backgroundColor: 'transparent',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: theme.spacing(0.5),
}));

export const DivLabelWrapperStyled = styled('div', {
  name: 'DivLabelWrapperStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textTransform: 'uppercase',
}));

export const DivCodeWrapperStyled = styled('div', {
  name: 'DivCodeWrapperStyled',
})(() => ({
  display: 'inline-block',
  minWidth: '240px',
  textAlign: 'center',
}));
