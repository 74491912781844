import React from 'react';

export const useDialog = (initialState = false) => {
  const [open, setOpen] = React.useState(initialState);

  const closeDialog = React.useCallback(() => {
    setOpen(false);
  }, []);

  const openDialog = React.useCallback(() => {
    setOpen(true);
  }, []);

  return React.useMemo(
    () => ({
      dialogState: open,
      closeDialog,
      openDialog,
    }),
    [closeDialog, open, openDialog],
  );
};
