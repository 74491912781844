import { useMutation } from '@tanstack/react-query';

import type { CreateEmployeeEmploymentScheduleArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

const mutationFn = (data: CreateEmployeeEmploymentScheduleArgs) => {
  return employeeService.createEmployeeEmploymentSchedule(data);
};

export const useEmployeeCreateEmploymentScheduleMutation = () => {
  return useMutation<unknown, unknown, CreateEmployeeEmploymentScheduleArgs>(mutationFn);
};
