import { takeLatest } from 'redux-saga/effects';

export const generateActionTypes = (prefix, additional = {}) => {
  const actionTypes = {
    RequestData: `[${prefix}] Request`,
    RequestDataEnvironment: `[${prefix}] Request Environment`,
    FulfilledTable: `[${prefix}] Fulfilled`,
    SearchTable: `[${prefix}] Search`,
    ChangePage: `[${prefix}] Change page`,
    SetPageSize: `[${prefix}] Set page size`,
    SortTable: `[${prefix}] Sort`,
    ApplyFilter: `[${prefix}] Apply filter`,
    RemoveFilter: `[${prefix}] Remove filter`,
    Error: `[${prefix}] Error`,
    Reset: `[${prefix}] Reset`,
    RequestUnfilteredTotalCount: `[${prefix}] Request Total count`,
    SetUnfilteredTotalCount: `[${prefix}] Set Total Count`,
    ToggleRow: `[${prefix}] Toggle row`,
    SelectAllRows: `[${prefix}] Select all rows`,
  };

  Object.keys(additional || {}).forEach(key => {
    actionTypes[key] = `[${prefix}] ${additional[key]}`;
  });

  return actionTypes;
};

export const tableInitialState = {
  items: [],
  loading: false,
  loaded: false,
  page: 1,
  pageSize: 10,
  totalCount: 0,
  sortBy: {},
  filters: {},
  selectedRow: null,
  selectedRows: [],
  searchQuery: ``,
  error: false,
  unfilteredTotalCount: {
    total: 0,
  },
  context: null,
};

export const tableStateMutators = {
  fulfilled: (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      items: action.items,
      totalCount: action.totalCount,
      error: false,
    };
  },
  requestData: (state, action) => {
    return {
      ...state,
      loading: true,
      error: false,
      context: action.payload ?? null,
    };
  },
  searchTable: (state, action) => {
    return {
      ...state,
      page: 1,
      searchQuery: action.searchQuery,
      loading: true,
      error: false,
    };
  },
  changePage: (state, action) => {
    return {
      ...state,
      page: action.page > 0 ? action.page : 1,
      loading: true,
      error: false,
    };
  },
  setPageSize: (state, action) => {
    return {
      ...state,
      page: 1,
      pageSize: action.pageSize > 0 ? action.pageSize : state.pageSize,
      loading: true,
      error: false,
    };
  },
  sortTable: (state, action) => {
    return {
      ...state,
      sortBy: action.sortBy,
      loading: true,
      error: false,
    };
  },
  applyFilter: (state, action) => {
    return {
      ...state,
      page: 1,
      loading: true,
      error: false,
      filters: {
        ...state.filters,
        [action.key]: action.value,
      },
    };
  },
  removeFilter: (state, action) => {
    const filters = { ...state.filters };
    delete filters[action.key];

    return {
      ...state,
      loading: true,
      error: false,
      filters,
    };
  },
  setSelectedRow: (state, action) => {
    return {
      ...state,
      selectedRow: action.selectedRow,
    };
  },
  error: (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  },
  reset: (state, initialState) => {
    return {
      ...state,
      ...initialState,
    };
  },
  requestUnfilteredTotalCount: state => ({
    ...state,
  }),
  setUnfilteredTotalCount: (state, action) => ({
    ...state,
    unfilteredTotalCount: action.unfilteredTotalCount,
  }),
  toggleRow: (state, action) => ({
    ...state,
    selectedRows: state.selectedRows.find(s => s === action.selectedRow)
      ? state.selectedRows.filter(s => s !== action.selectedRow)
      : [...state.selectedRows, action.selectedRow],
  }),
  selectAllRows: (state, action) => ({
    ...state,
    selectedRows: action.selectedRows,
  }),
};

export const tableAction = (actionTypes, state, action, initialState, additionalMutators = {}) => {
  if (action.type in additionalMutators) {
    return additionalMutators[action.type](state, action);
  }

  switch (action.type) {
    case actionTypes.FulfilledTable: {
      return tableStateMutators.fulfilled(state, action);
    }
    case actionTypes.RequestData: {
      return tableStateMutators.requestData(state, action);
    }
    case actionTypes.SearchTable: {
      return tableStateMutators.searchTable(state, action);
    }
    case actionTypes.ChangePage: {
      return tableStateMutators.changePage(state, action);
    }
    case actionTypes.SetPageSize: {
      return tableStateMutators.setPageSize(state, action);
    }
    case actionTypes.SortTable: {
      return tableStateMutators.sortTable(state, action);
    }
    case actionTypes.ApplyFilter: {
      return tableStateMutators.applyFilter(state, action);
    }
    case actionTypes.RemoveFilter: {
      return tableStateMutators.removeFilter(state, action);
    }
    case actionTypes.SetSelectedRow: {
      return tableStateMutators.setSelectedRow(state, action);
    }
    case actionTypes.Error: {
      return tableStateMutators.error(state, action);
    }
    case actionTypes.Reset: {
      return tableStateMutators.reset(state, initialState);
    }
    case actionTypes.RequestUnfilteredTotalCount: {
      return tableStateMutators.requestUnfilteredTotalCount(state, action);
    }
    case actionTypes.SetUnfilteredTotalCount: {
      return tableStateMutators.setUnfilteredTotalCount(state, action);
    }
    case actionTypes.ToggleRow: {
      return tableStateMutators.toggleRow(state, action);
    }
    case actionTypes.SelectAllRows: {
      return tableStateMutators.selectAllRows(state, action);
    }
    default:
      return state;
  }
};

export const tableActions = (actionTypes, additionalActions = {}) => ({
  applyFilter: (key, value) => ({ type: actionTypes.ApplyFilter, key, value }),
  removeFilter: key => ({ type: actionTypes.RemoveFilter, key }),
  setPageSize: (pageSize, reloadData = true) => ({ type: actionTypes.SetPageSize, pageSize, reloadData }),
  sortTable: sortBy => ({ type: actionTypes.SortTable, sortBy }),
  changePage: (page, reloadData = true) => ({ type: actionTypes.ChangePage, page, reloadData }),
  search: (searchQuery, reloadData = true) => ({
    type: actionTypes.SearchTable,
    searchQuery,
    reloadData,
  }),
  requestData: payload => ({ type: actionTypes.RequestData, payload }),
  requestDataEnvironment: payload => ({ type: actionTypes.RequestDataEnvironment, payload }),
  fulfilled: (items, totalCount) => ({
    type: actionTypes.FulfilledTable,
    items,
    totalCount,
  }),
  setSelectedRow: selectedRow => ({ type: actionTypes.SetSelectedRow, selectedRow }),
  error: error => ({
    type: actionTypes.Error,
    error,
  }),
  reset: () => ({
    type: actionTypes.Reset,
  }),
  requestUnfilteredTotalCount: () => ({
    type: actionTypes.RequestUnfilteredTotalCount,
  }),
  requestEnvironmentTotalCount: () => ({
    type: actionTypes.RequestUnfilteredTotalCount,
  }),
  setUnfilteredTotalCount: unfilteredTotalCount => ({
    type: actionTypes.SetUnfilteredTotalCount,
    unfilteredTotalCount,
  }),
  setEnvironmentTotalCount: unfilteredTotalCount => ({
    type: actionTypes.SetUnfilteredTotalCount,
    unfilteredTotalCount,
  }),
  toggleRow: selectedRow => ({ type: actionTypes.ToggleRow, selectedRow }),
  selectAllRows: selectedRows => ({ type: actionTypes.SelectAllRows, selectedRows }),
  setEmployerId: employerId => ({
    type: actionTypes.setEmployerId,
    employerId,
  }),
  ...additionalActions,
});

export function* tableSagas(types, requestWorker) {
  return [
    yield takeLatest(types.SearchTable, requestWorker),
    yield takeLatest(types.ChangePage, requestWorker),
    yield takeLatest(types.SetPageSize, requestWorker),
    yield takeLatest(types.SortTable, requestWorker),
    yield takeLatest(types.ApplyFilter, requestWorker),
    yield takeLatest(types.RemoveFilter, requestWorker),
  ];
}
