import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';

import type { Owner } from '../../../../types/owner.types';

import { StoreSelectors, useStoreSelector } from '../../../../store';

import { ROLE } from '../../../../common/Authorization/entities';
import { EDIT } from '../../../../common/Authorization/permissions';

import { TranslationKeys } from '../../../../translations';

import { useOwnerGetOwnersQuery } from '../../../../hooks/api/owner/useOwnerGetOwnersQuery';

import { HasAccessTo } from '../../../../components/Authorization';
import FormikModal from '../../../../components/FormikModal';

import type { SubOwnerFormValues } from '../../Forms/SubOwnerForm';
import { SubOwnerForm, useSubOwnerForm } from '../../Forms/SubOwnerForm';

type CreateSubOwnerDialogProps = {
  open: boolean;
  closeDialog: (reloadList?: boolean) => void;
};

export const CreateSubOwnerDialog = ({ open, closeDialog }: CreateSubOwnerDialogProps) => {
  const disabledIfNoAccessToEdit = !HasAccessTo(ROLE, EDIT);
  const owner = useStoreSelector<Owner>(state => StoreSelectors.AppSelector.selectOwner(state.AppReducer));

  const { data: ownersData, isLoading } = useOwnerGetOwnersQuery({
    options: { enabled: !owner?.ownerId },
  });

  const { formikInitialValues, formikValidationSchema, formikOnSubmit } = useSubOwnerForm({
    owner,
    owners: ownersData?.data,
    afterSubmit: () => closeDialog(true),
  });

  return (
    <Formik<SubOwnerFormValues>
      initialValues={formikInitialValues}
      validationSchema={formikValidationSchema}
      onSubmit={formikOnSubmit}
    >
      {({ resetForm }) => (
        <FormikModal
          title={<FormattedMessage id={TranslationKeys.subOwners_add} />}
          show={open}
          buttonDisabled={disabledIfNoAccessToEdit}
          onHide={() => {
            closeDialog();
            resetForm();
          }}
        >
          <SubOwnerForm loading={isLoading} owners={ownersData?.data} />
        </FormikModal>
      )}
    </Formik>
  );
};
