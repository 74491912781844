import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Input } from '@mui/material';

import { TranslationKeys } from '../../translations';

import {
  DivCodeWrapperStyled,
  DivFieldStyled,
  DivLabelWrapperStyled,
  FormLabelStyled,
  PaperStyled,
} from './SecurityInput.styles';

export type SecurityInputProps = {
  className?: string;
  codeLength?: number;
  style?: Record<string, any>;
  onCodeComplete: (code: string) => void;
};

export const SecurityInput = ({ className, style, onCodeComplete, codeLength = 6 }: SecurityInputProps) => {
  const inputRef = React.useRef<HTMLInputElement[]>([]);
  const [securityCode, setSecurityCode] = React.useState(new Array(codeLength).fill(''));

  const completeIfDone = React.useCallback(
    code => {
      const fullCode = code.join('');
      if (fullCode.length !== codeLength) {
        return;
      }
      onCodeComplete && onCodeComplete(code.join(''));
    },
    [codeLength, onCodeComplete],
  );

  const isValueValid = React.useCallback(value => {
    if (typeof value === 'undefined') {
      return false;
    }
    if (value === '') {
      return true;
    }
    return !!(value + '').match(/^\d$/g);
  }, []);

  const setElement = React.useCallback(
    (index, value) => {
      if (isValueValid(value)) {
        const storeValue = value === '' ? value : parseInt(value);
        if (index >= 0 && index < codeLength) {
          inputRef.current[index].focus();
          inputRef.current[index].value = storeValue;
          securityCode[index] = storeValue;
          setSecurityCode([...securityCode]);
        }
        if (index === codeLength - 1) {
          completeIfDone(securityCode);
        }
      }
    },
    [codeLength, completeIfDone, isValueValid, securityCode],
  );

  const nextElement = React.useCallback(
    currentIndex => {
      if (!isValueValid(inputRef.current[currentIndex].value)) {
        return false;
      }
      const targetIndex = currentIndex + 1;
      if (targetIndex >= codeLength) {
        return false;
      }
      inputRef.current[targetIndex].focus();
      return true;
    },
    [codeLength, isValueValid],
  );

  const previousElement = React.useCallback(currentIndex => {
    const targetIndex = currentIndex - 1;
    if (targetIndex < 0) {
      return false;
    }
    inputRef.current[targetIndex].focus();
    return true;
  }, []);

  const clearThisOrPrevious = React.useCallback(
    currentIndex => {
      const currentValue = inputRef.current[currentIndex].value;
      setElement(currentIndex, '');
      if (!isValueValid(currentValue) || currentValue === '') {
        setElement(currentIndex - 1, '');
        previousElement(currentIndex);
      }
    },
    [isValueValid, previousElement, setElement],
  );

  const handleInput = React.useCallback(
    (index, event) => {
      event.preventDefault();
      // remove all non-digits from the input
      const allDigits = (event.target.value || '').trim().replace(/\D/g, '');
      for (let valueIndex = 0; valueIndex < allDigits.length; valueIndex++) {
        const inputIndex = valueIndex + index;
        setElement(inputIndex, allDigits[valueIndex]);
        nextElement(inputIndex);
      }
    },
    [nextElement, setElement],
  );

  const handleKeyDown = React.useCallback(
    (i, event) => {
      const keyCode = event.which || event.keyCode;
      switch (keyCode) {
        case 5: // enter
          completeIfDone(securityCode);
          return;
        case 8: // backspace
          clearThisOrPrevious(i);
          break;
        case 9: // tab
          break;
        case 37: // left
          previousElement(i);
          return;
        case 39: // right
          nextElement(i);
          return;
        default:
          setElement(i, '');
          break;
      }
    },
    [clearThisOrPrevious, completeIfDone, nextElement, previousElement, securityCode, setElement],
  );

  const handleChange = React.useCallback(
    (i, event) => {
      if (['insertText', 'insertReplacementText'].includes(event.nativeEvent?.inputType)) {
        setElement(i, event.target.value);
        nextElement(i);
      } else if (event.nativeEvent?.inputType?.startsWith('delete')) {
        clearThisOrPrevious(i);
      }
      return [event.preventDefault(), false];
    },
    [clearThisOrPrevious, nextElement, setElement],
  );

  // refactor to be able to annotate with one-time-code autocomplete tag
  const inputs = React.useMemo(() => {
    return [...Array(codeLength)].map((e, i) => (
      <DivFieldStyled key={i}>
        <Input
          data-index={i}
          autoFocus={i === 0}
          disableUnderline
          inputRef={el => (inputRef.current[i] = el)}
          value={securityCode[i]}
          onChange={e => handleChange(i, e)}
          onInput={e => handleInput(i, e)}
          onKeyDown={e => handleKeyDown(i, e)}
          type="tel"
          inputProps={{ min: 0, max: 9 }}
        />
      </DivFieldStyled>
    ));
  }, [codeLength, handleChange, handleInput, handleKeyDown, securityCode]);

  return (
    <PaperStyled className={className} elevation={0} style={style}>
      <DivLabelWrapperStyled>
        <FormLabelStyled>
          <FormattedMessage id={TranslationKeys.signIn_challengeCode} />
        </FormLabelStyled>
      </DivLabelWrapperStyled>
      <DivCodeWrapperStyled>{inputs}</DivCodeWrapperStyled>
    </PaperStyled>
  );
};
