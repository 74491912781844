import * as XLSX from 'xlsx';

import { ExtensionToFileType, FileType } from 'app/components/FileUploadArea/FileUploadArea.types';
import type { ParseResult } from 'papaparse';
import Papa from 'papaparse';
import type { ListFileInterface } from './ListFileInterface';
import type { ListFileStrategyInterface } from './ListFileStrategyInterface';

export class ListFile implements ListFileInterface {
  constructor(private readonly headers: string[], private listFileStrategy: ListFileStrategyInterface) {}

  public generateTemplateSheet = (fillerRows: number = 1000): XLSX.WorkSheet => {
    const data = [this.headers].concat(new Array(fillerRows).fill(new Array(this.headers.length).fill('')));
    const worksheet = XLSX.utils.aoa_to_sheet(data, { sheetStubs: true });
    worksheet['!refs'] = XLSX.utils.encode_range({
      s: { r: 0, c: 0 },
      e: { r: data.length - 1, c: this.headers.length - 1 },
    });
    this.headers.forEach((h, column) => {
      if (!worksheet['!cols']) {
        worksheet['!cols'] = [];
      }
      if (!worksheet['!cols'][column]) {
        worksheet['!cols'][column] = { wch: 16 };
      }
      for (let row = 0; row <= fillerRows; row++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: column })];
        cell.z = '@'; // force cell to be text type (and avoid issues with 0's)
      }
    });
    return worksheet;
  };

  public generateDataSheet = (data: any[], keys: string[]): XLSX.WorkSheet => {
    const dataWithHeaders = data.reduce(
      (result, item) => {
        const newRow = keys.slice(0, this.headers.length).map(k => item[k] ?? '');
        result.push(newRow);
        return result;
      },
      [[...this.headers]],
    );
    return XLSX.utils.aoa_to_sheet(dataWithHeaders);
  };

  public sheetToCsv(worksheet: XLSX.WorkSheet, options?: XLSX.Sheet2CSVOpts): string {
    return XLSX.utils.sheet_to_csv(worksheet, options);
  }

  public writeSheetToWorkbook = (name: string, worksheet: XLSX.WorkSheet): Blob => {
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, name);
    return XLSX.write(workbook, { type: 'array', cellStyles: true });
  };

  public loadAndParse = async (file: File): Promise<any[]> => {
    const fileType = this.getFileType(file);
    if (fileType !== FileType.Excel && fileType !== FileType.Csv) {
      throw new Error('Unsupported file type');
    }

    const data = await (fileType === FileType.Excel ? this.parseExcelFile(file) : this.parseCsvFile(file));

    return this.listFileStrategy.execute(data);
  };

  protected getFileType = (file: File): FileType => {
    const extension = (file.name.split('.').slice(-1)[0] || '').toLowerCase();
    return ExtensionToFileType[extension] || FileType.Unknown;
  };

  protected parseExcelFile = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        try {
          const payload = event.target?.result;
          const workbook = XLSX.read(payload, { type: 'binary' });
          const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            blankrows: false,
            skipHidden: true,
            rawNumbers: true,
            raw: true,
          });
          resolve(data);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = () => {
        reject(new Error(`Error occurred reading file: ${file.name}`));
      };
      reader.readAsBinaryString(file);
    });
  };

  protected parseCsvFile = (file: File): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        fastMode: false,
        skipEmptyLines: true,
        header: false,
        complete: (results: ParseResult<any>) => {
          resolve(results.data);
        },
        error: (error: Error) => {
          reject(error);
        },
      });
    });
  };
}
