import { styled } from '@mui/material/styles';
import { formControlLabelClasses } from '@mui/material';

export const DivSwitchContainerStyled = styled('div', {
  name: 'DivSwitchContainerStyled',
})(({ theme }) => ({
  [`& .${formControlLabelClasses.root}`]: {
    flexDirection: 'row-reverse',
    gap: theme.spacing(1),
    textTransform: 'none',
    [`& .${formControlLabelClasses.label}`]: {
      color: theme.palette.primary.dark,
    },
  },
}));
