import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { useFormikContext } from 'formik';
import { Field, getIn } from 'formik';
import { Select as BaseSelect } from '../base';
import { Checkbox, ClickAwayListener, FormLabel, MenuItem, Popper, Skeleton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { getValueType, isEmptyArray, isNil } from 'app/utils';
import { IntlMessage } from 'app/components/Intl';
import { useFieldValidator } from '../Factory';
import { getDefaultOption, normalizeOptions } from '../Shared/formikFieldHelpers';
import { getMultiSelectActualValue } from './formikFieldHelpers';
import { InputAdornmentStyled, MenuItemStyled } from './MultiSelect.styles';
import type { MultiSelectProps } from './MultiSelect';

export const MULTI_SELECT_DATA_TEST_ID = 'multi-select-component';

type InternalMultiSelectProps = MultiSelectProps & {
  formik: ReturnType<typeof useFormikContext>;
};

const InternalMultiSelect = ({ onChange, field, variant = 'default', formik, className }: InternalMultiSelectProps) => {
  const {
    label,
    name,
    value,
    header,
    defaultValue,
    required,
    renderInfo,
    loading = false,
    disabled = false,
    disableOnChange = false,
    fullWidth = true,
    displayEmptyOption = false,
    ...rest
  } = field || {};

  // should be ignored!
  delete rest.options;
  delete rest.schema;
  delete rest.items;

  const { initialValues, setFieldValue, setFieldTouched, isSubmitting, values: formikValues } = formik;
  const formikValue = getIn(formikValues, name);

  const normalizedOptions = useMemo(() => {
    return normalizeOptions({ field, loading });
  }, [field, loading]);

  const defaultOption = useMemo(() => {
    return getDefaultOption({
      normalizedOptions,
      defaultValue,
    });
  }, [defaultValue, normalizedOptions]);

  const actualDefaultValue = useMemo(() => {
    const initialValue = getIn(initialValues, name);
    return isNil(initialValue) ? (isNil(defaultOption?.value) ? null : defaultOption.value) : initialValue;
  }, [initialValues, name, defaultOption?.value]);

  const actualValue = useMemo(() => {
    return getMultiSelectActualValue({
      normalizedOptions,
      defaultValue: actualDefaultValue,
      formikValue,
      value,
    });
  }, [actualDefaultValue, normalizedOptions, value, formikValue]);

  const actualDisabled = loading || disabled || isSubmitting;
  const [currentValue, setCurrentValue] = useState(actualValue);
  const [fieldDisabled, setFieldDisabled] = useState(actualDisabled);
  const [infoPopperAnchorElement, setInfoPopperAnchorElement] = useState<HTMLDivElement | null>(null);
  const containerDivRef = useRef<HTMLDivElement | null>(null);
  const validator = useFieldValidator('multi-select');
  const valueType = getValueType(currentValue);
  const hasEmptyOption = !!(displayEmptyOption && normalizedOptions.find(option => option.value === null));
  const showInfoIcon = !!renderInfo && !!actualValue;

  useEffect(() => {
    setFieldDisabled(actualDisabled);
  }, [actualDisabled]);

  useEffect(() => {
    setCurrentValue(actualValue);
  }, [actualValue]);

  const onInfoIconClick = useCallback(() => {
    setInfoPopperAnchorElement(infoPopperAnchorElement ? null : containerDivRef.current);
  }, [infoPopperAnchorElement]);

  const endAdornment = useMemo(() => {
    if (showInfoIcon) {
      return (
        <InputAdornmentStyled slim={variant === 'slim'} position="end">
          <InfoIcon onClick={onInfoIconClick} />
        </InputAdornmentStyled>
      );
    }
    return undefined;
  }, [onInfoIconClick, showInfoIcon, variant]);

  const selectItems = useMemo(() => {
    const renderValue = (option?: any) => {
      if (loading) {
        return <Skeleton />;
      }

      const valueToDisplay = option?.element ? option?.element : option?.value || null;
      return <IntlMessage value={valueToDisplay} />;
    };

    if (loading && isEmptyArray(normalizedOptions)) {
      return <MenuItem value={''}>{renderValue()}</MenuItem>;
    }

    return normalizedOptions.map((option, index) => (
      <MenuItemStyled key={index} value={option?.value} disabled={option?.disabled}>
        <Checkbox color={'secondary'} checked={currentValue.some(value => value === option?.value)} />
        {renderValue(option)}
      </MenuItemStyled>
    ));
  }, [loading, normalizedOptions, currentValue]);

  const selectedItems = useMemo(() => {
    if (currentValue.length > 0) {
      return () => `${label || header} (${currentValue.length})`;
    }

    return () => label;
  }, [label, header, currentValue]);

  const change = useCallback(
    async (event, child) => {
      const {
        target: { value },
      } = event;
      if (disableOnChange) {
        setFieldDisabled(true);
      }

      const newValue = isNil(value) ? [] : value;
      setCurrentValue(newValue);
      // needs to be performed on this order!
      setFieldTouched(name, true, false);
      await setFieldValue(name, newValue, true);
      onChange && onChange({ event, child, name, value: newValue, setFieldValue });
    },
    [setFieldValue, setFieldTouched, name, onChange, disableOnChange],
  );

  const displayEmpty = !!defaultOption || loading || hasEmptyOption;
  const isEmpty = currentValue === null;
  const size = variant === 'slim' ? 'small' : undefined;
  // notch is the white background behind the label when it's shrunk
  const hasNotch = !!(((!!defaultOption && isEmpty) || !isEmpty || loading || hasEmptyOption) && label);

  const formControlProps = useMemo(() => {
    return {
      fullWidth: fullWidth,
      required: required,
      size,
    };
  }, [fullWidth, size, required]);

  const inputLabelProps = useMemo(() => {
    const shrink = displayEmpty || !isEmpty;
    const isRequiredAndHasLabel = !!label && required;

    return {
      shrink,
      size,
      required: isRequiredAndHasLabel,
    };
  }, [displayEmpty, isEmpty, label, size, required]);

  const validate = useCallback(
    value => {
      return validator(value, field);
    },
    [field, validator],
  );

  return (
    <>
      <div className={className} ref={containerDivRef}>
        {header && (
          <FormLabel>
            <IntlMessage value={header} /> {required ? ' *' : ''}
          </FormLabel>
        )}
        <Field
          data-testid={MULTI_SELECT_DATA_TEST_ID}
          disabled={fieldDisabled}
          component={BaseSelect}
          name={name}
          label={label}
          value={currentValue}
          onChange={change}
          formControl={formControlProps}
          inputLabel={inputLabelProps}
          displayEmpty={displayEmpty}
          notched={hasNotch}
          valueType={valueType}
          validate={validate}
          multiple
          renderValue={selectedItems}
          {...rest}
          size={size}
          endAdornment={endAdornment}
        >
          {selectItems}
        </Field>
      </div>

      {showInfoIcon && (
        // https://github.com/mui/material-ui/issues/35287
        // @ts-ignore
        <Popper
          open={Boolean(infoPopperAnchorElement)}
          anchorEl={infoPopperAnchorElement}
          placement="bottom-start"
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [0, 6],
                },
              },
            ],
          }}
        >
          <ClickAwayListener onClickAway={onInfoIconClick}>
            <div>{renderInfo(actualValue)}</div>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};

export default InternalMultiSelect;
