import React from 'react';
import ActionButton from '../../ActionButton';
import { Grid, Typography } from '@mui/material';
import { TranslationKeys } from '../../../translations';
import { FormattedMessage } from 'react-intl';

type BaseFileUploadIcon = {
  disabled?: boolean;
};

type FileUploadOnlyExcelIcon = BaseFileUploadIcon & {
  onlyExcel?: boolean;
  onlyXml?: never;
};

type FileUploadOnlyXmlIcon = BaseFileUploadIcon & {
  onlyXml?: boolean;
  onlyExcel?: never;
};

type FileUploadIconProps = FileUploadOnlyExcelIcon | FileUploadOnlyXmlIcon;

export const FileUploadIcon = ({ disabled = false, onlyExcel = false, onlyXml = false }: FileUploadIconProps) => {
  const messageId = React.useMemo(() => {
    if (onlyExcel) {
      return TranslationKeys.upload_fileTypesOnlyExcel;
    }
    if (onlyXml) {
      return TranslationKeys.upload_fileTypesOnlyXml;
    }

    return TranslationKeys.upload_fileTypes;
  }, [onlyExcel, onlyXml]);

  return (
    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Grid item xs={12}>
        <ActionButton messageId={TranslationKeys.button_upload} variant={'outlined'} disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'caption'}>
          <FormattedMessage id={messageId} />
        </Typography>
      </Grid>
    </Grid>
  );
};
