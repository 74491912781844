import { styled } from '@mui/material/styles';
import { outlinedInputClasses, TextField } from '@mui/material';

export const TextFieldStyled = styled(TextField, {
  name: 'TextFieldStyled',
})(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1.5),
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: '1px solid ' + theme.palette.secondary.main,
  },
}));
