import React from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { TranslationKeys } from 'app/translations';

import { genderMapper } from 'app/utils';

import type { Employee, EmployeePartner, UseQueryRefetch } from 'app/types';

import { useEmployeeUpdatePartnerMutation, useSnakeBar } from 'app/hooks';

import type { PartnerDetailsPanelFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/components/PartnerDetailsPanel/PartnerDetailsPanel.types';

import { PartnerFormDialogBody } from './components';

type PartnerFormDialogProps = {
  open: boolean;
  closeDialog: () => void;
  employee: Employee | undefined;
  partner: EmployeePartner | null;
  refetchGetEmployeeQuery: UseQueryRefetch;
};

export const PartnerFormDialog = ({
  open,
  refetchGetEmployeeQuery,
  closeDialog,
  employee,
  partner,
}: PartnerFormDialogProps) => {
  const intl = useIntl();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: updateEmployeePartner } = useEmployeeUpdatePartnerMutation();

  const isEdit = partner !== null;

  const formikInitialValues = React.useMemo<PartnerDetailsPanelFormValues>(() => {
    return {
      socialSecurityNumber: partner?.socialSecurityNumber || '',
      gender: genderMapper(partner?.gender),
      dateOfBirth: partner?.dateOfBirth || null,
      startOfRelationship: partner?.startOfRelationship || '',
      endOfRelationship: partner?.endOfRelationship || null,
      personName: {
        initials: partner?.personName?.initials || '',
        firstName: partner?.personName?.firstName || '',
        lastName: partner?.personName?.lastName || '',
        lastNamePrefix: partner?.personName?.lastNamePrefix || '',
      },
      extraPartnerPensionByStart: partner?.extraPartnerPensionByStart,
    };
  }, [
    partner?.dateOfBirth,
    partner?.endOfRelationship,
    partner?.gender,
    partner?.personName?.firstName,
    partner?.personName?.initials,
    partner?.personName?.lastName,
    partner?.personName?.lastNamePrefix,
    partner?.socialSecurityNumber,
    partner?.startOfRelationship,
    partner?.extraPartnerPensionByStart,
  ]);

  const setPartnerDataToSubmit = React.useCallback(partnerData => {
    return {
      externalId: partnerData?.externalId || null,
      socialSecurityNumber: partnerData.socialSecurityNumber || null,
      gender: genderMapper(partnerData?.gender),
      dateOfBirth: partnerData.dateOfBirth,
      startOfRelationship: partnerData.startOfRelationship,
      endOfRelationship: partnerData?.endOfRelationship || null,
      personName: {
        initials: partnerData.personName.initials,
        firstName: partnerData.personName.firstName,
        lastName: partnerData.personName.lastName,
        lastNamePrefix: partnerData.personName.lastNamePrefix,
      },
      extraPartnerPensionByStart: partnerData?.extraPartnerPensionByStart,
    };
  }, []);

  const formikOnSubmit = React.useCallback<FormikConfig<PartnerDetailsPanelFormValues>['onSubmit']>(
    (values, { setSubmitting, resetForm }) => {
      const partners = (employee?.partners || []).map(employeePartner => {
        const partnerData =
          isEdit && employeePartner.externalId === partner?.externalId
            ? { externalId: partner?.externalId, ...values }
            : employeePartner;

        return setPartnerDataToSubmit(partnerData);
      });

      if (!isEdit) {
        partners.push(setPartnerDataToSubmit(values));
      }

      if (employee?.connectionId && employee?.employeeId) {
        const method = 'updateEmployeePartner';
        updateEmployeePartner(
          {
            connectionId: employee?.connectionId,
            employeeId: employee?.employeeId,
            partners,
          },
          {
            onSuccess: () => {
              setSubmitting(false);
              resetForm();
              closeDialog();
              refetchGetEmployeeQuery();
              showSuccessSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_successMessage }),
              });
            },
            onError: () => {
              setSubmitting(false);
              showErrorSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_errorMessage }),
              });
            },
          },
        );
      }
    },
    [
      employee?.partners,
      employee?.connectionId,
      employee?.employeeId,
      isEdit,
      partner?.externalId,
      setPartnerDataToSubmit,
      updateEmployeePartner,
      closeDialog,
      refetchGetEmployeeQuery,
      showSuccessSnakeBar,
      intl,
      showErrorSnakeBar,
    ],
  );

  return (
    <Formik<PartnerDetailsPanelFormValues> initialValues={formikInitialValues} onSubmit={formikOnSubmit}>
      <PartnerFormDialogBody open={open} isEdit={isEdit} closeDialog={closeDialog} />
    </Formik>
  );
};
