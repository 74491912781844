import { useMutation } from '@tanstack/react-query';

import OwnerService from '../../../Domain/Owners/Services/OwnerService';

type DeleteOwnerData = {
  ownerId: string;
};

const mutationFn = (data: DeleteOwnerData) => {
  return OwnerService.deleteOwner(data?.ownerId);
};

export const useOwnerDeleteMutation = () => {
  return useMutation<unknown, unknown, DeleteOwnerData>(mutationFn);
};
