import React from 'react';
import { TextFieldStyled } from './ConfirmDialogWithTextInput.styles';
import { Dialog } from '../Dialog';
import { ActionButton } from '../ActionButton';
import { TranslationKeys } from '../../translations';
import { useStoreSelector } from '../../store';

type ConfirmDialogWithTextInputProps = {
  children: React.ReactNode;
  open: boolean;
  title: string | React.ReactElement;
  solution: string;
  label: string | React.ReactElement;
  errorMessage: string | React.ReactElement;
  onClose?: () => void;
  setOpen?: (state: boolean) => void;
  onConfirm?: () => void;
};

export const ConfirmDialogWithTextInput = ({
  title,
  solution,
  label,
  errorMessage,
  children,
  open,
  onClose,
  setOpen,
  onConfirm,
}: ConfirmDialogWithTextInputProps) => {
  const { loading: appLoading } = useStoreSelector(state => state.AppReducer);
  const [loading, setLoading] = React.useState(false);
  const [answer, setAnswer] = React.useState<string | null>(null);
  const [isAnswerIncorrect, setIsAnswerIncorrect] = React.useState(false);

  const helperText = React.useMemo(() => {
    return isAnswerIncorrect ? errorMessage : label;
  }, [isAnswerIncorrect, errorMessage, label]);

  const closeDialog = React.useCallback(() => {
    onClose && onClose();
    setOpen && setOpen(false);
  }, [onClose, setOpen]);

  const handleOnClick = React.useCallback(() => {
    const isAnswerIncorrect = answer?.replace(/\s+/g, ' ').trim() !== solution.replace(/\s+/g, ' ').trim();

    if (isAnswerIncorrect) {
      setIsAnswerIncorrect(true);
      return;
    }

    setLoading(true);
    onConfirm && onConfirm();
    closeDialog();
    setLoading(false);
  }, [answer, closeDialog, onConfirm, solution]);

  const onChangeInput = React.useCallback(
    event => {
      if (isAnswerIncorrect) {
        setIsAnswerIncorrect(false);
      }

      setAnswer(event.target.value);
    },
    [isAnswerIncorrect],
  );

  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton messageId={TranslationKeys.no} onClick={closeDialog} variant="outlined" />
        <ActionButton
          messageId={TranslationKeys.yes}
          onClick={handleOnClick}
          disabled={loading || appLoading || isAnswerIncorrect}
        />
      </>
    );
  }, [appLoading, closeDialog, handleOnClick, isAnswerIncorrect, loading]);

  return (
    <Dialog open={open} title={title} onClose={closeDialog} actions={DialogActions}>
      {children}

      <TextFieldStyled
        id="securityAnswer"
        color="secondary"
        variant="standard"
        error={isAnswerIncorrect}
        helperText={helperText}
        autoComplete="off"
        onChange={onChangeInput}
      />
    </Dialog>
  );
};
