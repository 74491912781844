import { useMutation } from '@tanstack/react-query';
import ConnectionService from '../../../Domain/Connections/Services/ConnectionService';

type ConnectionApproveDraftData = {
  connectionId: string;
  batchId: string;
  externalIds: string[];
};

const mutationFn = (data: ConnectionApproveDraftData) => {
  return ConnectionService.archiveFromDraftBatch(data);
};

export const useConnectionArchiveEventsFromDraftMutation = () => {
  return useMutation<unknown, unknown, ConnectionApproveDraftData>(mutationFn);
};
