import { styled } from '@mui/material/styles';

export const UnorderedListStyled = styled('ul', {
  name: 'UnorderedListStyled',
})(() => ({
  overflow: 'auto',
  maxHeight: '15em',
  listStyle: 'none',
}));

export const ListItemStyled = styled('li', {
  name: 'ListItemStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  '& span': {
    marginLeft: theme.spacing(1),
  },
  '&::before': {
    width: '1em',
    content: '"•"',
    color: theme.palette.secondary.dark,
  },
}));
