import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { Connection, RequestFiltersParams, UseQueryWrapper } from 'app/types';
import connectionService from '../../../Domain/Connections/Services/ConnectionService';
import { useSnakeBar } from '../../useSnakeBar';

type GetBatchQueryVariables =
  | Omit<RequestFiltersParams, 'filters' | 'sortBy'> & {
      batchId: string | undefined;
      connectionId: Connection['connectionId'] | undefined;
    };

type GetBatchQueryResultData = {
  data: 'draft' | 'pending-insurer-data' | 'compared' | 'to-be-checked';
};

const queryFn =
  ({ batchId, connectionId }: GetBatchQueryVariables) =>
  async () => {
    if (batchId && connectionId) {
      const response = await connectionService.getBatch({
        batchId,
        connectionId,
      });
      return response.data;
    }
  };

const QUERY_KEY = 'getBatch';

export const useConnectionGetBatchQuery: UseQueryWrapper<GetBatchQueryResultData, GetBatchQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(
    error => {
      if (error.response?.status !== 422) {
        showErrorSnakeBar({ method: QUERY_KEY });
      }
    },
    [showErrorSnakeBar],
  );

  const select = React.useCallback(data => {
    return {
      data: data.batchStatus,
    };
  }, []);

  return useQuery<GetBatchQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
    retry: (failureCount: number, error: any) => {
      return error.response?.status !== 422 && failureCount < 3;
    },
    ...(options || {}),
  });
};
