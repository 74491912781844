import React from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { TranslationKeys } from 'app/translations';

import type { EmployeeEmployment, EmployeeSicknessLeave, UseQueryRefetch } from 'app/types';

import type { ShowSnakeBarParams } from 'app/hooks';
import { useEmployeeUpdateEmploymentMutation, useSnakeBar } from 'app/hooks';

import type {
  CreateEmployeeEmploymentArgs,
  UpdateEmployeeEmploymentArgs,
} from 'app/Domain/Employees/Services/EmployeeService';

import type { EmployeeSicknessFormValues } from '../../EmployeeSicknessLeaves.types';
import { EmployeeSicknessLeavesFields } from '../../EmployeeSicknessLeaves.types';
import { EmployeeSicknessLeaveFormDialogBody } from './components';
import { EmployeeEmploymentWageFields } from '../../../../../EmployeeEmploymentTab/components/EmployeeEmploymentWages/EmployeeEmploymentWages.types';
import { isAfter, isEqual } from 'date-fns';
import { parseDate } from '@storybook/components';

type EmployeeEmploymentSicknessLeavesFormDialogProps = {
  open: boolean;
  closeDialog: () => void;
  employment: EmployeeEmployment & { employeeId: string; connectionId: string };
  sickLeave: EmployeeSicknessLeave | null;
  refetchGetEmploymentsQuery: UseQueryRefetch;
};

export const EmployeeSicknessLeaveFormDialog = ({
  open,
  closeDialog,
  employment,
  sickLeave,
  refetchGetEmploymentsQuery,
}: EmployeeEmploymentSicknessLeavesFormDialogProps) => {
  const intl = useIntl();
  const { showSnakeBar } = useSnakeBar();
  const { mutate: updateEmploymentSicknessLeave } = useEmployeeUpdateEmploymentMutation();

  const isEdit = sickLeave !== null;

  const showSnackBarMessage = React.useCallback(
    ({ method, severity }: Omit<ShowSnakeBarParams, 'message'>) => {
      showSnakeBar({
        severity,
        method,
        message: intl.formatMessage({
          id:
            severity === 'success'
              ? TranslationKeys.global_update_successMessage
              : TranslationKeys.global_update_errorMessage,
        }),
      });
    },
    [intl, showSnakeBar],
  );

  const formikInitialValues = React.useMemo<EmployeeSicknessFormValues>(() => {
    return {
      id: sickLeave?.id,
      [EmployeeEmploymentWageFields.StartDate.InputName]: sickLeave?.startDate,
      [EmployeeSicknessLeavesFields.EndDate.InputName]: sickLeave?.endDate,
    };
  }, [sickLeave]);

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [EmployeeSicknessLeavesFields.StartDate.InputName]: Yup.string()
        .test(
          EmployeeSicknessLeavesFields.StartDate.InputName,
          intl.formatMessage({ id: TranslationKeys.employees_error_sicknessLeaveStartDate }),
          value => {
            if (employment.startDate) {
              const employeeStartDateInstance = new Date(employment.startDate);
              employeeStartDateInstance.setHours(0, 0, 0, 0);
              return value
                ? // eslint-disable-next-line no-undef
                  isAfter(parseDate(value), employeeStartDateInstance) ||
                    isEqual(parseDate(value), employeeStartDateInstance)
                : false;
            }
          },
        )
        .test(
          EmployeeSicknessLeavesFields.StartDate.InputName,
          intl.formatMessage({ id: TranslationKeys.employees_error_sicknessLeaveDateAlreadyExists }),
          value => {
            if (!value) {
              return true;
            }

            return sickLeave
              ? employment.sicknessLeaves
                  .filter(sickLeaveToEdit => sickLeaveToEdit.id !== sickLeave?.id)
                  .every(
                    sickLeave =>
                      !(
                        parseDate(value) >= parseDate(sickLeave.startDate) &&
                        parseDate(value) <= parseDate(sickLeave.endDate)
                      ),
                  )
              : employment.sicknessLeaves.every(
                  sickLeave =>
                    !(
                      parseDate(value) >= parseDate(sickLeave.startDate) &&
                      parseDate(value) <= parseDate(sickLeave.endDate)
                    ),
                );
          },
        )
        .required(),
      [EmployeeSicknessLeavesFields.EndDate.InputName]: Yup.string()
        .test(
          EmployeeSicknessLeavesFields.EndDate.InputName,
          intl.formatMessage({ id: TranslationKeys.employees_error_sicknessLeaveEndDate }),
          value => {
            if (!employment.startDate) {
              return;
            }
            if (!value) {
              return true;
            }

            const employeeStartDateInstance = new Date(employment.startDate);
            return value
              ? isAfter(parseDate(value), employeeStartDateInstance) ||
                  isEqual(parseDate(value), employeeStartDateInstance)
              : false;
          },
        )
        .nullable()
        .test(
          EmployeeSicknessLeavesFields.EndDate.InputName,
          intl.formatMessage({ id: TranslationKeys.employees_error_sicknessLeaveStartEndDate }),
          (value, { parent }) => {
            const startDate = parent[EmployeeSicknessLeavesFields.StartDate.InputName] ?? sickLeave?.startDate;
            if (!startDate) {
              return;
            }
            if (!value) {
              return true;
            }

            const startDateInstance = new Date(startDate);
            return value
              ? isAfter(parseDate(value), startDateInstance) || isEqual(parseDate(value), startDateInstance)
              : false;
          },
        )
        .test(
          EmployeeSicknessLeavesFields.StartDate.InputName,
          intl.formatMessage({ id: TranslationKeys.employees_error_sicknessLeaveDateAlreadyExists }),
          value => {
            if (!value) {
              return true;
            }

            return sickLeave
              ? employment.sicknessLeaves
                  .filter(sickLeaveToEdit => sickLeaveToEdit.id !== sickLeave?.id)
                  .every(
                    sickLeave =>
                      !(
                        parseDate(value) >= parseDate(sickLeave.startDate) &&
                        parseDate(value) <= parseDate(sickLeave.endDate)
                      ),
                  )
              : employment.sicknessLeaves.every(
                  sickLeave =>
                    !(
                      parseDate(value) >= parseDate(sickLeave.startDate) &&
                      parseDate(value) <= parseDate(sickLeave.endDate)
                    ),
                );
          },
        )
        .nullable(),
    });
  }, [employment.sicknessLeaves, employment.startDate, intl, sickLeave]);

  const formikOnSubmit = React.useCallback<FormikConfig<EmployeeSicknessFormValues>['onSubmit']>(
    (values, { setSubmitting, resetForm }) => {
      const onSuccess = (method: string) => () => {
        setSubmitting(false);
        resetForm();
        closeDialog();
        showSnackBarMessage({ method, severity: 'success' });
        refetchGetEmploymentsQuery();
      };

      const onError = (method: string) => () => {
        setSubmitting(false);
        showSnackBarMessage({ method, severity: 'error' });
      };

      const mutationData = {
        connectionId: employment.connectionId,
        employeeId: employment.employeeId,
        employment: {
          startDate: employment.startDate,
          yearsFrom: employment.yearsFrom,
          endDate: employment.endDate,
          probationDate: employment.probationDate,
          employmentType: employment.employmentType,
          jobDescription: employment.jobDescription,
          deductionPlan: employment.deductionPlan,
          vacationPay: employment.vacationPay,
          vacationPayPercentage: employment.vacationPayPercentage,
          extraMonth: employment.extraMonth,
          otherWageComponents: employment.otherWageComponents,
          partTimePercentage: employment.partTimePercentage,
          employmentId: employment.employmentId,
          externalId: employment.externalId,
          sicknessLeaves: [
            {
              id: values?.id,
              startDate: values?.startDate,
              endDate: values?.endDate,
            },
          ],
        },
      } as unknown as CreateEmployeeEmploymentArgs | UpdateEmployeeEmploymentArgs;

      const method = 'updateEmployeeSicknessLeave';
      updateEmploymentSicknessLeave(mutationData as UpdateEmployeeEmploymentArgs, {
        onSuccess: onSuccess(method),
        onError: onError(method),
      });
    },
    [employment, updateEmploymentSicknessLeave, closeDialog, showSnackBarMessage, refetchGetEmploymentsQuery],
  );

  return (
    <Formik<EmployeeSicknessFormValues>
      enableReinitialize={true}
      initialValues={formikInitialValues}
      validationSchema={formikValidationSchema}
      onSubmit={formikOnSubmit}
    >
      <EmployeeSicknessLeaveFormDialogBody open={open} isEdit={isEdit} closeDialog={closeDialog} />
    </Formik>
  );
};
