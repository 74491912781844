import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UnprocessedEventConnectionEventNames, UseQueryWrapper } from '../../../types';

import type { GetUnprocessedEventsCountParams } from '../../../Domain/Dashboard/Services';
import { dashboardService } from '../../../Domain/Dashboard/Services';

import { useSnakeBar } from '../../useSnakeBar';

type UnprocessedEventsCountKeys = (typeof UnprocessedEventConnectionEventNames)[number];

export type GetUnprocessedEventsCountQueryResultData = Record<UnprocessedEventsCountKeys, number>;

export type GetUnprocessedEventsCountQueryVariables = GetUnprocessedEventsCountParams;

const queryFn = (variables: GetUnprocessedEventsCountQueryVariables) => async () => {
  const response = await dashboardService.getUnprocessedEventsCount(variables);
  return response.data;
};

const QUERY_KEY = 'getUnprocessedEventsCount';

export const useDashboardGetUnprocessedEventsCountQuery: UseQueryWrapper<
  GetUnprocessedEventsCountQueryResultData,
  GetUnprocessedEventsCountQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetUnprocessedEventsCountQueryResultData>(
    [QUERY_KEY, ...Object.values(variables)],
    queryFn(variables),
    {
      onError,
      ...(options || {}),
    },
  );
};
