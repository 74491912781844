import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from '../../../../../../store';
import { ActionButton, ButtonDropdown, ConfirmDialog, Dialog, InputFieldFactory } from '../../../../../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import { TranslationKeys } from '../../../../../../translations';
import { useBulkUpdateEventFlow } from '../../../../../../hooks/api/dashboard/useBulkUpdateEventFlow';
import { Stack } from '@mui/material';
import { TypographyStyled } from '../../../../../Employers/Pages/EmployerDetailPage/EmployerInformationTab/EmployerInformationTab.styles';

const comfirmDialogInitialState = {
  action: null,
  title: '',
  value: undefined,
  message: '',
};

const updateStateInitialState = { key: '', value: '' };

type UserOptionType = { value?: string; element?: string; default?: boolean };

type EventFlowTableActionsProps = {
  isLoading: boolean;
  usersAsOptions: Array<UserOptionType> | Array<[]>;
  refetchEventFlow: () => {};
  downloadSelectedEvents: () => void;
};

export const EventFlowTableActions = ({
  isLoading,
  usersAsOptions,
  refetchEventFlow,
  downloadSelectedEvents,
}: EventFlowTableActionsProps) => {
  const intl = useIntl();
  const dispatch = useStoreDispatch();
  const [updateState, setUpdateState] = useState<{ key: string; value: string }>(updateStateInitialState);
  const [commentDialogForm, setCommentDialogForm] = useState<boolean>(false);

  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));
  const { selectedRows } = useStoreSelector(state =>
    StoreSelectors.DashboardSelector.selectEventFlowDetails(state.DashboardReducer),
  );

  const { mutateAsync: updateEventsFlowAsync, isLoading: isUpdateEventsFlowLoading } = useBulkUpdateEventFlow();

  const [confirmDialog, setConfirmDialog] = useState<{
    action: string | null;
    title: string;
    value: string | undefined;
    message: string | undefined;
  }>(comfirmDialogInitialState);

  const onUpdate = useCallback(async () => {
    try {
      if (!isUpdateEventsFlowLoading) {
        await updateEventsFlowAsync({
          ownerId: ownerId,
          eventFlowIds: selectedRows,
          [updateState.key]: updateState.value,
        });
        dispatch(StoreActions.DashboardAction.eventFlowDetails.selectAllRows([]));
        setUpdateState(updateStateInitialState);
        await refetchEventFlow();
      }
    } catch (e) {
      // TODO: handle error?
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [
    dispatch,
    isUpdateEventsFlowLoading,
    ownerId,
    refetchEventFlow,
    selectedRows,
    updateEventsFlowAsync,
    updateState.key,
    updateState.value,
  ]);

  const onClickChangeStatus = useCallback(
    (status: string) => {
      setUpdateState({ key: 'status', value: status });
      setConfirmDialog({
        action: 'changeStatus',
        value: status,
        title: intl.formatMessage({ id: TranslationKeys.eventflow_dialog_changeStatus_title }),
        message: intl.formatMessage(
          { id: TranslationKeys.eventflow_dialog_changeStatus_message },
          { newStatus: intl.formatMessage({ id: 'eventflow.category.' + status }) },
        ),
      });
    },
    [intl],
  );

  const onClickChangeUser = useCallback(
    (userId: string, fullName: string) => {
      setUpdateState({ key: 'userId', value: userId });
      setConfirmDialog({
        action: 'changeUser',
        value: userId,
        title: intl.formatMessage({ id: TranslationKeys.eventflow_dialog_changeUser_title }),
        message: intl.formatMessage(
          { id: TranslationKeys.eventflow_dialog_changeUser_message },
          { newUserFullName: fullName },
        ),
      });
    },
    [intl],
  );

  const changeStatusOptions = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: TranslationKeys.eventflow_actions_change_status }),
        onClick: () => {},
        disabled: true,
      },
      {
        title: intl.formatMessage({ id: TranslationKeys.eventflow_category_todo }),
        onClick: () => onClickChangeStatus('to-do'),
      },
      {
        title: intl.formatMessage({ id: TranslationKeys['eventflow_category_in-progress_checked-employer'] }),
        onClick: () => onClickChangeStatus('in-progress.checked-employer'),
      },
      {
        title: intl.formatMessage({ id: TranslationKeys.eventflow_category_inProgress_sent }),
        onClick: () => onClickChangeStatus('in-progress.sent'),
      },
      {
        title: intl.formatMessage({ id: TranslationKeys.eventflow_category_inProgress_rejected }),
        onClick: () => onClickChangeStatus('in-progress.rejected'),
      },
      {
        title: intl.formatMessage({ id: TranslationKeys.eventflow_category_inProgress_checked }),
        onClick: () => onClickChangeStatus('in-progress.checked'),
      },
      {
        title: intl.formatMessage({ id: TranslationKeys.eventflow_category_completed_done }),
        onClick: () => onClickChangeStatus('completed.done'),
      },
      {
        title: intl.formatMessage({ id: TranslationKeys.eventflow_category_completed_checked }),
        onClick: () => onClickChangeStatus('completed.checked'),
      },
    ],
    [intl, onClickChangeStatus],
  );

  const changeUserOptions = useMemo(() => {
    const mappedUsers = usersAsOptions.slice(1, usersAsOptions.length).map((option: UserOptionType) => ({
      title: option?.element || '',
      onClick: () => onClickChangeUser(option?.value || '', option?.element || ''),
    }));

    return [
      {
        title: intl.formatMessage({ id: TranslationKeys.eventflow_actions_change_user }),
        onClick: () => {},
        disabled: true,
      },
      ...mappedUsers,
    ];
  }, [intl, usersAsOptions, onClickChangeUser]);

  const onDialogConfirm = useCallback(async () => {
    onUpdate().catch();
  }, [onUpdate]);

  const onChangeCommentForm = useCallback(({ value }) => {
    setUpdateState({ key: 'comment', value: value });
  }, []);

  const onClickWriteComment = useCallback(() => {
    setCommentDialogForm(true);
  }, []);

  const onClickSaveComment = useCallback(async () => {
    onUpdate().then(() => setCommentDialogForm(false));
  }, [onUpdate]);

  const onCloseDialog = useCallback(() => {
    setCommentDialogForm(false);
    setUpdateState(updateStateInitialState);
  }, []);

  return selectedRows?.length ? (
    <Fragment>
      <Stack
        alignItems={{ xs: 'center', lg: 'flex-end' }}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ gap: theme => theme.spacing(2), mb: theme => theme.spacing(2) }}
      >
        <div>
          <TypographyStyled variant="body2">
            <FormattedMessage id={TranslationKeys.eventflow_actions_change_status} />
          </TypographyStyled>
          <ButtonDropdown placement={'bottom-start'} options={changeStatusOptions} />
        </div>
        <div>
          <TypographyStyled variant="body2">
            <FormattedMessage id={TranslationKeys.eventflow_actions_change_user} />
          </TypographyStyled>
          <ButtonDropdown options={changeUserOptions} />
        </div>
        <div>
          <ActionButton
            disabled={isLoading}
            messageId={'eventflow.comment.placeholder'}
            onClick={onClickWriteComment}
          />
        </div>
        <div>
          <ActionButton
            disabled={isLoading}
            messageId={TranslationKeys.global_downloadSelected}
            onClick={downloadSelectedEvents}
          />
        </div>
      </Stack>
      <ConfirmDialog
        onConfirm={onDialogConfirm}
        title={confirmDialog.title}
        onClose={() => setConfirmDialog(comfirmDialogInitialState)}
        open={confirmDialog.action !== null}
      >
        <p>{confirmDialog.message}</p>
      </ConfirmDialog>
      <Dialog
        open={commentDialogForm}
        onExit={onCloseDialog}
        title={intl.formatMessage({ id: TranslationKeys.eventflow_comment_placeholder })}
        onClose={() => setCommentDialogForm(false)}
      >
        <InputFieldFactory
          onChange={onChangeCommentForm}
          field={{
            type: 'text',
            disabled: isUpdateEventsFlowLoading,
            name: 'event-flow-bulk-action-comment',
            placeholder: intl.formatMessage({ id: TranslationKeys.eventflow_comment_placeholder }),
            multiline: true,
            value: updateState.value,
          }}
        />
        <Stack sx={{ my: theme => theme.spacing(2) }} alignItems={'flex-end'}>
          <div>
            <ActionButton
              disabled={isUpdateEventsFlowLoading || isLoading}
              messageId={'button.save'}
              onClick={onClickSaveComment}
            />
          </div>
        </Stack>
      </Dialog>
    </Fragment>
  ) : null;
};
