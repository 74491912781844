import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  EmployeeDetailPage,
  EmployeeEmploymentTab,
  EmployeeEventsTab,
  EmployeeIncomeTab,
  EmployeeInfoTab,
  EmployeePensionTab,
  EmployeeSicknessTab,
} from './EmployeeDetailPage';

export const EmployeePages = () => {
  return (
    <Routes>
      <Route element={<EmployeeDetailPage />} path="*">
        <Route path="info" element={<EmployeeInfoTab />} />
        <Route path="employment" element={<EmployeeEmploymentTab />} />
        <Route path="pension" element={<EmployeePensionTab />} />
        <Route path="income" element={<EmployeeIncomeTab />} />
        <Route path="events" element={<EmployeeEventsTab />} />
        <Route path="health" element={<EmployeeSicknessTab />} />
        <Route path="*" element={<Navigate to="info" replace />} />
      </Route>
    </Routes>
  );
};
