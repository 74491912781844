import { backgroundAxios } from 'app/utils/api';
import axios from 'axios';
import { isBoolean, isNil } from 'app/utils';
import type { ProductIncomeTypeEnum } from '../../../../types';

export type GetRegulationsYearsParams = {
  ownerId: string;
  values?: Record<string, any>;
};

const getRegulationsYears = async (
  ownerId: GetRegulationsYearsParams['ownerId'],
  values: GetRegulationsYearsParams['values'] = {},
) => {
  return backgroundAxios.get(`/owners/${ownerId}/summary-of-regulations/years`, {
    params: {
      ...values,
    },
  });
};

export type GetRegulationsParams = {
  ownerId: string;
  product?: string;
  connection?: string;
  year?: number;
  productType?: ProductIncomeTypeEnum;
};

const getRegulations = async (
  ownerId: GetRegulationsParams['ownerId'],
  params: Omit<GetRegulationsParams, 'ownerId'>,
) => {
  return axios.get(`/owners/${ownerId}/summary-of-regulations`, {
    params: {
      ...params,
    },
  });
};

const mapToRegulationValue = (value: Record<string, any>) => {
  if (isNil(value)) {
    return null;
  }
  if (isBoolean(value)) {
    return value ? '1' : '0';
  }
  return value + '';
};

export type EditRegulationsParams = {
  ownerId: string;
  productId: string | null;
  connectionId: string | null;
  employerId?: string;
  year: number;
  values: Record<string, { value: any; description?: string }>;
};

type SummaryOfRegulation = Pick<EditRegulationsParams, 'productId' | 'employerId' | 'year'> & {
  summaryOfRegulationId: string;
  description?: string;
  value: string | null;
};

const editRegulations = async ({
  ownerId,
  productId,
  connectionId,
  year,
  employerId,
  values,
}: EditRegulationsParams) => {
  const summaryOfRegulations: Array<SummaryOfRegulation> = Object.keys(values).map(id => ({
    summaryOfRegulationId: id,
    productId,
    connectionId,
    employerId,
    year,
    description: values[id].description || undefined,
    value: mapToRegulationValue(values[id].value),
  }));

  return axios.post(`/owners/${ownerId}/summary-of-regulations`, { summaryOfRegulations });
};

export const regulationsService = {
  getRegulationsYears,
  getRegulations,
  editRegulations,
};
