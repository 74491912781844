import React from 'react';
import { FormattedMessage } from 'react-intl';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import type { ShowSnakeBarParams } from '../../../../hooks';

import { useStoreDispatch, useStoreSelector } from '../../../../store';

import { actions as AppActions, selectors } from '../../../..//Domain/App/Ducks/App.duck';

import type { MessagesType } from '../../../../common';
import Messages from '../../../../common/messages';

type Notification = Omit<ShowSnakeBarParams, 'severity'> & {
  severity: 'success' | 'error';
  timeout?: number;
};

export const NotificationBar = () => {
  const dispatch = useStoreDispatch();
  const [open, setOpen] = React.useState(false);
  const [notification, setNotification] = React.useState<Notification | null>(null);

  const notificationState = useStoreSelector(state => selectors.selectNotification(state.AppReducer));

  const deleteMessage = React.useCallback(() => {
    dispatch(AppActions.deleteMessage());
  }, [dispatch]);

  const removeNotification = React.useCallback(() => {
    setOpen(false);
    deleteMessage();
    setNotification(null);
  }, [deleteMessage]);

  React.useEffect(() => {
    if (notificationState) {
      setNotification(notificationState);
      setOpen(true);
    } else {
      setNotification(null);
    }
  }, [setOpen, notificationState, removeNotification]);

  const severity = notification?.severity ?? 'success';
  const method = (notification?.method || '') as keyof MessagesType;

  const id =
    (Messages[method] && Messages[method][severity as keyof MessagesType[keyof MessagesType]]) ??
    (severity === 'success' ? 'requestSuccessful' : 'unknownError');

  return notification ? (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={notification?.timeout ?? 5000}
      onClose={removeNotification}
    >
      <Alert severity={severity}>
        {!!id && (
          <>
            <FormattedMessage id={id} key={1} />
            {notification.message ? <span key={2}> {`: ${notification.message}`}</span> : ''}
          </>
        )}
      </Alert>
    </Snackbar>
  ) : null;
};
