import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import type { SearchInputProps } from 'app/components';
import { SearchInput } from 'app/components';
import { TranslationKeys } from 'app/translations';
import { CheckboxStyled } from './UsersAccessListHeader.styles';

type UserAccessesListHeaderProps = {
  isLoading: boolean;
  onChange: SearchInputProps['onChange'];
  headerTextId: string;
  onSelectAll: () => void;
  areAllSelected?: boolean;
  noData?: boolean;
  disabled?: boolean;
  count?: number;
};

export const UserAccessesListHeader = ({
  onSelectAll,
  onChange,
  isLoading,
  headerTextId,
  areAllSelected = false,
  disabled = false,
  noData = false,
  count = 0,
}: UserAccessesListHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Typography variant={'h5'} marginLeft={1}>
          <FormattedMessage id={headerTextId} values={{ count }} />
        </Typography>
        <CheckboxStyled
          name={'select-all'}
          label={<FormattedMessage id={TranslationKeys.global_selectAll} />}
          checked={areAllSelected}
          onChange={onSelectAll}
          disabled={disabled || isLoading || noData}
        />
      </Box>

      <SearchInput disabled={isLoading} onChange={onChange} />
    </Box>
  );
};
