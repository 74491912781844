import { createSelector } from 'reselect';

import { generateActionTypes, tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';

const ActionTypePrefixes = {
  DashboardUnprocessedEvents: 'Dashboard.UnprocessedEvents',
  DashboardUnprocessedBatches: 'Dashboard.UnprocessedBatches',
  DashboardMissingDetails: 'Dashboard.MissingDetails',
  DashboardEventFlowDetails: 'Dashboard.EventFlowDetails',
  DashboardConnectionErrorsDetails: 'Dashboard.ConnectionErrorsDetails',
  DashboardBlackAndWhiteListDetails: 'Dashboard.BlackAndWhiteListDetails',
} as const;

export const actionTypes = {
  UnprocessedEvents: generateActionTypes(ActionTypePrefixes.DashboardUnprocessedEvents),
  UnprocessedBatches: generateActionTypes(ActionTypePrefixes.DashboardUnprocessedBatches),
  MissingDetails: generateActionTypes(ActionTypePrefixes.DashboardMissingDetails),
  EventFlowDetails: generateActionTypes(ActionTypePrefixes.DashboardEventFlowDetails),
  ConnectionErrorsDetails: generateActionTypes(ActionTypePrefixes.DashboardConnectionErrorsDetails),
  BlackAndWhiteListDetails: generateActionTypes(ActionTypePrefixes.DashboardBlackAndWhiteListDetails),
} as const;

export const dashboardDuckInitialState = {
  unprocessedEvents: {
    ...tableInitialState,
  },
  unprocessedBatches: {
    ...tableInitialState,
  },
  missingDetails: {
    ...tableInitialState,
  },
  eventFlowDetails: {
    ...tableInitialState,
  },
  connectionErrorsDetails: {
    ...tableInitialState,
  },
  blackAndWhiteListDetails: {
    ...tableInitialState,
  },
};

export type DashboardDuckState = typeof dashboardDuckInitialState;

export const reducer = (state = dashboardDuckInitialState, action: Record<string, any>): DashboardDuckState => {
  if (action.type.startsWith(`[${ActionTypePrefixes.DashboardUnprocessedEvents}]`)) {
    return {
      ...state,
      unprocessedEvents: tableAction(
        actionTypes.UnprocessedEvents,
        state.unprocessedEvents,
        action,
        dashboardDuckInitialState.unprocessedEvents,
      ),
    };
  }

  if (action.type.startsWith(`[${ActionTypePrefixes.DashboardUnprocessedBatches}]`)) {
    return {
      ...state,
      unprocessedBatches: tableAction(
        actionTypes.UnprocessedBatches,
        state.unprocessedBatches,
        action,
        dashboardDuckInitialState.unprocessedBatches,
      ),
    };
  }

  if (action.type.startsWith(`[${ActionTypePrefixes.DashboardMissingDetails}]`)) {
    return {
      ...state,
      missingDetails: tableAction(
        actionTypes.MissingDetails,
        state.missingDetails,
        action,
        dashboardDuckInitialState.missingDetails,
      ),
    };
  }

  if (action.type.startsWith(`[${ActionTypePrefixes.DashboardEventFlowDetails}]`)) {
    return {
      ...state,
      eventFlowDetails: tableAction(
        actionTypes.EventFlowDetails,
        state.eventFlowDetails,
        action,
        dashboardDuckInitialState.eventFlowDetails,
      ),
    };
  }

  if (action.type.startsWith(`[${ActionTypePrefixes.DashboardConnectionErrorsDetails}]`)) {
    return {
      ...state,
      connectionErrorsDetails: tableAction(
        actionTypes.ConnectionErrorsDetails,
        state.connectionErrorsDetails,
        action,
        dashboardDuckInitialState.connectionErrorsDetails,
      ),
    };
  }

  if (action.type.startsWith(`[${ActionTypePrefixes.DashboardBlackAndWhiteListDetails}]`)) {
    return {
      ...state,
      blackAndWhiteListDetails: tableAction(
        actionTypes.BlackAndWhiteListDetails,
        state.blackAndWhiteListDetails,
        action,
        dashboardDuckInitialState.blackAndWhiteListDetails,
      ),
    };
  }

  return state;
};

export const actions = {
  unprocessedEvents: tableActions(actionTypes.UnprocessedEvents),
  unprocessedBatches: tableActions(actionTypes.UnprocessedBatches),
  missingDetails: tableActions(actionTypes.MissingDetails),
  eventFlowDetails: tableActions(actionTypes.EventFlowDetails),
  connectionErrorsDetails: tableActions(actionTypes.ConnectionErrorsDetails),
  blackAndWhiteListDetails: tableActions(actionTypes.BlackAndWhiteListDetails),
};

export const selectors = {
  selectUnprocessedEvents: createSelector(
    (state: DashboardDuckState) => state.unprocessedEvents,
    unprocessedEvents => unprocessedEvents,
  ),
  selectUnprocessedBatches: createSelector(
    (state: DashboardDuckState) => state.unprocessedBatches,
    unprocessedBatches => unprocessedBatches,
  ),
  selectMissingDetails: createSelector(
    (state: DashboardDuckState) => state.missingDetails,
    missingDetails => missingDetails,
  ),
  selectEventFlowDetails: createSelector(
    (state: DashboardDuckState) => state.eventFlowDetails,
    eventFlowDetails => eventFlowDetails,
  ),
  selectConnectionErrorsDetails: createSelector(
    (state: DashboardDuckState) => state.connectionErrorsDetails,
    connectionErrorsDetails => connectionErrorsDetails,
  ),
  selectBlackAndWhiteListDetails: createSelector(
    (state: DashboardDuckState) => state.blackAndWhiteListDetails,
    blackAndWhiteListDetails => blackAndWhiteListDetails,
  ),
};
