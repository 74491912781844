import { styled } from '@mui/material/styles';
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  iconButtonClasses,
} from '@mui/material';

export const AccordionStyled = styled(Accordion, {
  name: 'AccordionStyled',
})(() => ({
  width: '100%',
  boxShadow: 'unset',
  backgroundColor: 'inherit',
  '&::before': {
    content: 'unset',
  },
  [`&.${accordionClasses.expanded}`]: {
    margin: 0,
  },
}));

export const AccordionSummaryStyled = styled(AccordionSummary, {
  name: 'AccordionSummaryStyled',
})(() => ({
  flexDirection: 'row-reverse',
  minHeight: 'unset',
  padding: 0,
  [`&.${accordionClasses.expanded}`]: {
    minHeight: 'unset',
  },
  [`& .${accordionSummaryClasses.content}`]: {
    margin: 0,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    paddingLeft: 0,
  },
  [`& .${iconButtonClasses.root}`]: {
    padding: 0,
    marginRight: 0,
  },
}));

export const AccordionDetailsStyled = styled(AccordionDetails, {
  name: 'AccordionDetailsStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 0, 1, 4),
  backgroundColor: theme.palette.common.white,
}));
