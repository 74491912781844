import React, { useCallback, useMemo, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { Button, Fade, Menu } from '@mui/material';
import type { KebabMenuItem } from './KebabMenu.types';
import { MenuItemStyled } from './KebabMenu.styles';

export const KEBAB_MENU_BUTTON_DATA_TEST_ID = 'kebab-menu-button';
export const KEBAB_MENU_ITEM_DATA_TEST_ID = 'kebab-menu-item';

type KebabMenuProps = {
  items: Array<KebabMenuItem>;
};

export const KebabMenu = ({ items }: KebabMenuProps) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const open = !!anchorElement;

  const handleMenuClick = useCallback(event => {
    setAnchorElement(event.currentTarget);
    event.stopPropagation();
  }, []);

  const onMenuCloseAndOnBlur = useCallback(event => {
    event.stopPropagation();
    setAnchorElement(null);
  }, []);

  const MenuElements = useMemo(() => {
    return (items || []).map((item, index) => {
      if (item.to) {
        return (
          <MenuItemStyled
            data-testid={KEBAB_MENU_ITEM_DATA_TEST_ID}
            key={index}
            component={ReactRouterLink}
            to={item.to}
            disabled={!!item.disabled}
          >
            {item.element}
          </MenuItemStyled>
        );
      }

      return (
        <MenuItemStyled
          data-testid={KEBAB_MENU_ITEM_DATA_TEST_ID}
          key={index}
          onClick={item.onClick}
          disabled={!!item.disabled}
        >
          {item.element}
        </MenuItemStyled>
      );
    });
  }, [items]);

  return (
    <>
      <Button
        data-testid={KEBAB_MENU_BUTTON_DATA_TEST_ID}
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuClick}
        variant={'text'}
        sx={{ minWidth: 0, width: '100%' }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{ 'aria-labelledby': 'fade-button' }}
        anchorEl={anchorElement}
        open={open}
        onClose={onMenuCloseAndOnBlur}
        onBlur={onMenuCloseAndOnBlur}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {MenuElements}
      </Menu>
    </>
  );
};
