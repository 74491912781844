import { AxiosError } from 'axios';
import type { Dictionary } from './Types';
import { isEmpty } from './Lodash';
import { ValidationError } from 'yup';
import { locale } from 'app/components/Intl/IntlWrapper';
import type { FormikHelpers, FormikValues } from 'formik';
import isLocalEnvironment from './isLocalEnvironment';

interface NormalizedError {
  error: Error | AxiosError;
  message: string;
  violations: undefined | Dictionary<any>;
  status: number;
}

const formatViolationMessage = (violation: any): string | null => {
  let message = violation.message;

  if (locale.messages[message]) {
    return locale.messages[message];
  }

  try {
    const decoded = JSON.parse(message);
    const decodedMessage = locale.messages[decoded.message] ?? decoded.message;
    message = ValidationError.formatError(decodedMessage, decoded.params || {});
  } catch (e) {
    // ignore
  }

  return message;
};

const parseViolations = (violations: any | Dictionary<any>): undefined | Dictionary<any> => {
  if (isEmpty(violations)) {
    return undefined;
  }

  const parsed: Dictionary<any> = {};
  violations.forEach((violation: any) => {
    const message = formatViolationMessage(violation);
    if (parsed[violation.propertyPath]) {
      parsed[violation.propertyPath] += `\n${message}`;
    } else {
      parsed[violation.propertyPath] = message;
    }
  });

  return parsed;
};

export const normalizeFormError = <TFormValues = FormikValues>(
  error: Error | AxiosError,
  form: FormikHelpers<TFormValues>,
): NormalizedError => {
  const errorData = normalizeError(error);
  form.setSubmitting(false);
  if (errorData.violations) {
    form.setStatus(errorData.message);
    form.setErrors(errorData.violations as any);
  }
  return errorData;
};

export const normalizeError = (error: Error | AxiosError): NormalizedError => {
  let message = error.message ?? 'An error occurred.';
  let violations: undefined | Dictionary<any> = undefined;
  let status = 400;

  if (error instanceof AxiosError) {
    status = error.response?.status || status;

    const data: any = error.response?.data || {};
    if (data) {
      message = data['hydra:description'] || data['hydra:title'] || 'An error occurred.';
      if (locale.messages[message]) {
        message = locale.messages[message];
      }
      violations = parseViolations(data.violations);
    }
  }

  if (isLocalEnvironment()) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return {
    error,
    message,
    violations,
    status,
  };
};

export const convertBytesToMbsOrKbs = (filesize: number): string => {
  let size: string;
  if (filesize >= 1048576) {
    size = filesize / 1048576 + ' MB';
  } else if (filesize >= 1024) {
    size = filesize / 1024 + ' KB';
  } else {
    size = filesize + ' B';
  }
  return size;
};

export const stripDiacritics = (value: string): string => {
  return typeof value.normalize !== 'undefined' ? value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : value;
};
