import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { Owner, UseQueryWrapper } from 'app/types';

import type { GetOwnerSubOwnersParams } from 'app/Domain/Owners/Services/OwnerService';
import { ownerService } from 'app/Domain/Owners/Services/OwnerService';

import { useSnakeBar } from 'app/hooks/useSnakeBar';

type GetOwnerSubOwnersQueryVariables = GetOwnerSubOwnersParams;

type GetOwnerSubOwnersQueryResultData = {
  data: Array<Owner>;
  totalCount: number;
};

const queryFn = (variables: GetOwnerSubOwnersQueryVariables) => async () => {
  const response = await ownerService.getOwnerSubOwners(variables);
  return (response as AxiosResponse).data;
};

const QUERY_KEY = 'getOwnerSubOwners';

export const useOwnerGetSubOwners: UseQueryWrapper<
  GetOwnerSubOwnersQueryResultData,
  GetOwnerSubOwnersQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetOwnerSubOwnersQueryResultData>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
