import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  generateActionTypes,
  tableAction,
  tableActions,
  tableInitialState,
  tableSagas,
} from 'app/Domain/App/Ducks/Table.duck';
import employerService from '../Services/EmployerService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { createSelector } from 'reselect';
import { normalizeError } from 'app/utils';
import {
  generateActionTypes as generateSingleActionTypes,
  singleObjectAction,
  singleObjectActions,
  singleObjectInitialState,
} from '../../App/Ducks/SingleObject.duck';
import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload } from '../../../utils';

export const actionTypes = {
  Employer: generateSingleActionTypes('Employer', {
    SetEmployer: 'Set Employer',
  }),
  Employees: generateActionTypes('Employer.Employees', {
    SetEmployerId: 'Set EmployerId',
  }),
  EmployerFiles: generateActionTypes('Employer.Files', {
    SetEmployerId: 'Set EmployerId',
  }),
};

const initialState = {
  employer: { ...singleObjectInitialState },
  employees: {
    ...tableInitialState,
    employerId: null,
    filters: {
      status: 'active',
    },
  },
  files: {
    ...tableInitialState,
    employerId: null,
  },
  legal: null,
  regulations: null,
};

const additionalEmployeesMutators = {};
additionalEmployeesMutators[`${actionTypes.Employees.SetEmployerId}`] = (state, action) => {
  return {
    ...state,
    employerId: action.payload,
  };
};

const additionalEmployerFilesMutators = {};
additionalEmployerFilesMutators[`${actionTypes.EmployerFiles.SetEmployerId}`] = (state, action) => {
  return {
    ...state,
    employerId: action.payload,
  };
};

export const reducer = (state = initialState, action) => {
  if (action.type.startsWith('[Employer]')) {
    return {
      ...state,
      employer: singleObjectAction(actionTypes.Employer, state.employer, action),
    };
  } else if (action.type.startsWith('[Employer.Employees]')) {
    return {
      ...state,
      employees: tableAction(
        actionTypes.Employees,
        state.employees,
        action,
        initialState.employees,
        additionalEmployeesMutators,
      ),
    };
  } else if (action.type.startsWith('[Employer.Files]')) {
    return {
      ...state,
      files: tableAction(
        actionTypes.EmployerFiles,
        state.files,
        action,
        initialState.files,
        additionalEmployerFilesMutators,
      ),
    };
  }
  return state;
};

export const actions = {
  employer: singleObjectActions(actionTypes.Employer, {
    setEmployer: (employer, onError, onSuccess, sortBy) => ({
      type: actionTypes.Employer.SetEmployer,
      payload: {
        employer,
        onError,
        onSuccess,
        sortBy,
      },
    }),
  }),
  employees: tableActions(actionTypes.Employees, {
    setEmployerId: (payload = {}) => ({
      type: actionTypes.Employees.SetEmployerId,
      payload,
    }),
  }),
  files: tableActions(actionTypes.EmployerFiles, {
    setEmployerId: (payload = {}) => ({
      type: actionTypes.EmployerFiles.SetEmployerId,
      payload,
    }),
  }),
};

export const selectors = {
  selectEmployer: createSelector(
    state => state.employer,
    employer => employer,
  ),
  selectEmployees: createSelector(
    state => state.employees,
    employees => employees,
  ),
  selectEmployerFiles: createSelector(
    state => state.files,
    files => files,
  ),
};

export function* saga() {
  yield takeLatest(actionTypes.Employer.RequestData, function* getEmployerSaga({ payload }) {
    try {
      const { employerId } = payload;
      const response = yield call(employerService.getEmployer, employerId);
      yield put(actions.employer.fulfilledData(response.data));
    } catch (error) {
      yield put(actions.employer.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getEmployer',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  });

  yield takeLatest(actionTypes.Employer.SetEmployer, function* setEmployerSaga({ payload }) {
    const { employer, onError, onSuccess } = payload;
    try {
      const response = yield call(employerService.editEmployer, employer);
      yield put(actions.employer.fulfilledData(response.data));
      yield put(actions.employer.requestData({ employerId: employer.employerId }));
      yield onSuccess && onSuccess(employer);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.employer.error(error));
      yield onError && onError(errorData);
    }
  });

  const requestEmployeesWorker = function* getEmployeesSaga({ payload }) {
    const { employerId, page, pageSize, sortBy, filters } = yield select(state =>
      selectors.selectEmployees(state.EmployerReducer),
    );
    const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload({
      page,
      pageSize,
      sortBy,
      filters,
    });
    const { onError, onSuccess } = payload || {};

    try {
      yield put(actions.employees.requestData());
      const response = yield call(employerService.getEmployees, { employerId, values: pagination });
      yield put(actions.employees.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.employees.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getEmployees',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  };

  yield takeLatest(actionTypes.Employees.SetEmployerId, requestEmployeesWorker);
  yield* tableSagas(actionTypes.Employees, requestEmployeesWorker);

  const requestEmployerFilesWorker = function* getEmployerFilesSaga({ payload }) {
    const { employerId, page, pageSize, sortBy, filters } = yield select(state =>
      selectors.selectEmployerFiles(state.EmployerReducer),
    );
    const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload({
      page,
      pageSize,
      sortBy,
      filters,
    });
    const { onError, onSuccess } = payload || {};

    try {
      yield put(actions.files.requestData());
      const response = yield call(employerService.getEmployerFiles, { employerId, values: pagination });
      yield put(actions.files.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.files.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getEmployerFiles',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  };

  yield takeLatest(actionTypes.EmployerFiles.SetEmployerId, requestEmployerFilesWorker);
  yield* tableSagas(actionTypes.EmployerFiles, requestEmployerFilesWorker);
}
