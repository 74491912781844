// @ts-ignore
import robotoRegular from '@fontsource/roboto/300.css';
// @ts-ignore
import nunitoLight from '@fontsource/nunito-sans/200.css';
// @ts-ignore
import nunitoRegular from '@fontsource/nunito-sans/400.css';
// @ts-ignore
import nunitoMedium from '@fontsource/nunito-sans/600.css';
// @ts-ignore
import nunitoBold from '@fontsource/nunito-sans/700.css';

const fontFaces = [robotoRegular, nunitoLight, nunitoRegular, nunitoMedium, nunitoBold];

export default fontFaces;
