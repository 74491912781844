import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { DivMessageContainer } from './CalculationCardError.styles';

type CalculationCardErrorProps = {
  message: string | undefined;
};

export const CalculationCardError = ({ message }: CalculationCardErrorProps) => {
  return (
    <DivMessageContainer>
      <Typography variant={'body2'}>
        {message && <FormattedMessage id={message} />}
        {!message && '-'}
      </Typography>
    </DivMessageContainer>
  );
};
