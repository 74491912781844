import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';

export const MenuItemStyled = styled(MenuItem, {
  name: 'MenuItemStyled',
})(({ disabled }) => {
  if (disabled) {
    return {
      opacity: 0.5,
      pointerEvents: 'none',
    } as const;
  }
  return {};
}) as typeof MenuItem;
