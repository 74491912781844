import React from 'react';
import { useNavigate } from 'react-router-dom';

import { appService } from '../../../../Domain/App/Services/AppServices';

import { Reset2FaCodeConfirmation, Reset2FaCodeInvalidLinkMsg } from './components';

export const RESET_2FA_CODE_VIEW_CONTENT_DATA_TEST_ID = 'reset-2fa-view-content-component';

type ResetPasswordViewContentProps = {
  token: string | null;
  userId: string | null;
  isLinkValid: boolean;
};

export const Reset2FaCodeViewContent = ({ token, userId, isLinkValid }: ResetPasswordViewContentProps) => {
  const navigate = useNavigate();

  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const onConfirmResetClick = React.useCallback(async () => {
    try {
      if (userId && token) {
        setError(null);
        setIsLoading(true);

        const data = await appService.reset2FaCode({ token, userId });

        if (data.data.code === 401) {
          setError(data.data.message);
        } else {
          navigate('/sign-in');
        }
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [navigate, setError, token, userId]);

  return (
    <div data-testid={RESET_2FA_CODE_VIEW_CONTENT_DATA_TEST_ID}>
      {!isLinkValid && <Reset2FaCodeInvalidLinkMsg />}

      {isLinkValid && (
        <Reset2FaCodeConfirmation error={error} isLoading={isLoading} onConfirmResetClick={onConfirmResetClick} />
      )}
    </div>
  );
};
