import React from 'react';

import { Box } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import type { TableSelectedState } from 'app/store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import { Uuid } from 'app/utils';

import { ActionButton, InputFieldFactory, SearchInput, useBreadcrumb } from 'app/components';
import { useIntl } from 'react-intl';
import { UnprocessedTableFiltersContainerStyled } from '../../Components/UnprocessedTableFilters/UnprocessedTableFilters.styles';
import { BlackAndWhiteListDetailsTable } from './components';
import { useDashboardGetBlackAndWhiteListDetailsQuery } from '../../../../hooks/api/dashboard/useDashboardGetBlackAndWhiteListDetailsQuery';
import { useEmployeeListTypeSchemeSelectItems } from '../../../../hooks/useEmployeeListTypeSchemeSelectItems';

export const BLACK_AND_WHITE_LIST_TABLE_FILTERS_FIELD_NAMES = {
  Status: 'employerStatus',
  SubOwners: 'subOwners',
  EmployeeListType: 'employeesListTypeScheme',
} as const;

export const DashboardBlackAndWhiteListDetailsPage = () => {
  const intl = useIntl();
  const { employeeListTypeSchemeSelectItems } = useEmployeeListTypeSchemeSelectItems();
  const dispatch = useStoreDispatch();
  const [key, setKey] = React.useState(Uuid.newV4);

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const { searchQuery, page, pageSize, filters, sortBy } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.DashboardSelector.selectBlackAndWhiteListDetails(state.DashboardReducer),
  );

  const {
    data: blackAndWhiteListDetails,
    isLoading: isLoadingConnectionErrorsDetailsQuery,
    isFetching: isFetchingConnectionErrorsDetailsQuery,
  } = useDashboardGetBlackAndWhiteListDetailsQuery({
    variables: {
      userId,
      page,
      pageSize,
      searchQuery,
      filters,
      sortBy,
    },
    options: {
      enabled: !!userId,
    },
  });

  const isLoading = isLoadingConnectionErrorsDetailsQuery || isFetchingConnectionErrorsDetailsQuery;

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.DashboardAction.blackAndWhiteListDetails.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.DashboardAction.blackAndWhiteListDetails.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.DashboardAction.blackAndWhiteListDetails.changePage(page));
      }
    },
    [dispatch],
  );

  const blackAndWhiteSchemeSelectItemsWithAllOption = React.useMemo(() => {
    return [
      {
        value: 'all',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...employeeListTypeSchemeSelectItems,
    ];
  }, [intl, employeeListTypeSchemeSelectItems]);

  const onSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(StoreActions.DashboardAction.blackAndWhiteListDetails.search(event.target.value));
    },
    [dispatch],
  );

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === BLACK_AND_WHITE_LIST_TABLE_FILTERS_FIELD_NAMES.EmployeeListType && value === '') {
        dispatch(
          StoreActions.DashboardAction.blackAndWhiteListDetails.applyFilter(
            BLACK_AND_WHITE_LIST_TABLE_FILTERS_FIELD_NAMES.EmployeeListType,
            'all',
          ),
        );
      } else {
        dispatch(StoreActions.DashboardAction.blackAndWhiteListDetails.applyFilter(name, value));
      }
    },
    [dispatch],
  );

  const onClickResetButtonHandler = React.useCallback(() => {
    dispatch(
      StoreActions.DashboardAction.blackAndWhiteListDetails.applyFilter(
        BLACK_AND_WHITE_LIST_TABLE_FILTERS_FIELD_NAMES.EmployeeListType,
        'all',
      ),
    );
    dispatch(StoreActions.DashboardAction.blackAndWhiteListDetails.search(''));
    setKey(Uuid.newV4());
  }, [dispatch]);

  useBreadcrumb(TranslationKeys.dashboard_category_dataProcessing, {
    customPathName: '/dashboard/data-processing',
  });
  useBreadcrumb(TranslationKeys.dashboard_subCategory_blackAndWhiteList);

  return (
    <Box marginTop={1}>
      <UnprocessedTableFiltersContainerStyled>
        <InputFieldFactory
          key={`product-pension-scheme-${key}`}
          disabled={isLoading}
          onChange={onFilterChange}
          field={{
            type: 'select',
            name: BLACK_AND_WHITE_LIST_TABLE_FILTERS_FIELD_NAMES.EmployeeListType,
            label: intl.formatMessage({ id: TranslationKeys.global_pensionScheme }),
            fullWidth: false,
            value: filters.blackAndWhiteList || null,
            items: blackAndWhiteSchemeSelectItemsWithAllOption,
          }}
        />

        <SearchInput
          key={`search-${key}`}
          disabled={isLoading}
          initialSearchQuery={searchQuery}
          onChange={onSearchChange}
          debounceTime={1000}
        />

        <ActionButton
          disabled={isLoading}
          messageId={TranslationKeys.global_reset}
          onClick={onClickResetButtonHandler}
          variant={'text'}
        />
      </UnprocessedTableFiltersContainerStyled>

      <BlackAndWhiteListDetailsTable
        isLoading={isLoading}
        blackAndWhiteListDetail={blackAndWhiteListDetails?.data || []}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        pagination={{
          page,
          pageSize,
          totalCount: blackAndWhiteListDetails?.totalCount ?? 0,
        }}
      />
    </Box>
  );
};
