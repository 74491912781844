import axios from 'axios';
import { backgroundAxios } from '../../../../utils';

let getConnectionsToken;
export const getConnections = async (page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getConnectionsToken !== typeof undefined) {
      getConnectionsToken.cancel('Operation canceled due to new request.');
    }

    getConnectionsToken = axios.CancelToken.source();

    return axios.get('connections', {
      cancelToken: getConnectionsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const getUserConnections = async (userId, page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getConnectionsToken !== typeof undefined) {
      getConnectionsToken.cancel('Operation canceled due to new request.');
    }

    getConnectionsToken = axios.CancelToken.source();

    return axios.get(`users/${userId}/connections`, {
      cancelToken: getConnectionsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getConnectionLogsToken;
export const getConnectionLogs = async (connectionId, page, perPage, sortBy, filters, globalFilter, useToken) => {
  try {
    if (useToken && typeof getConnectionLogsToken !== typeof undefined) {
      getConnectionLogsToken.cancel('Operation canceled due to new request.');
    }

    if (useToken) {
      getConnectionLogsToken = axios.CancelToken.source();
    }

    return axios.get(`connections/${connectionId}/log-entries`, {
      cancelToken: useToken ? getConnectionLogsToken.token : null,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getConnectionActivityLogsToken;
export async function getConnectionActivityLogs(connectionId, page, perPage) {
  try {
    if (typeof getConnectionActivityLogsToken !== typeof undefined) {
      getConnectionActivityLogsToken.cancel('Operation canceled due to new request.');
    }

    getConnectionActivityLogsToken = axios.CancelToken.source();

    return axios.get(`connections/${connectionId}/activity-logs`, {
      cancelToken: getConnectionActivityLogsToken.token,
      params: {
        page,
        perPage,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
}

let getConnectionTasksToken;
export const getConnectionTasks = async (connectionId, page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getConnectionTasksToken !== typeof undefined) {
      getConnectionTasksToken.cancel('Operation canceled due to new request.');
    }

    getConnectionTasksToken = axios.CancelToken.source();

    return axios.get(`connections/${connectionId}/tasks`, {
      cancelToken: getConnectionTasksToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getConnectionModulesToken;
export const getConnectionModules = async (connectionId, page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getConnectionModulesToken !== typeof undefined) {
      getConnectionModulesToken.cancel('Operation canceled due to new request.');
    }

    getConnectionModulesToken = axios.CancelToken.source();

    return axios.get(`connections/${connectionId}/modules`, {
      cancelToken: getConnectionModulesToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const getLogsCount = async connectionsIds => {
  return axios.post('connections/logscount', { connectionsIds });
};

export const getEventsCount = async connectionsIds => {
  return axios.post('connections/eventscount', { connectionsIds });
};

export const getConnection = async connectionId => {
  return axios.get(`/connections/${connectionId}`);
};

export const createConnection = async values => {
  return axios.post('/connections', { ...values });
};

export const clearConnectionTasks = async (connectionId, force) => {
  return axios.post(`/connections/${connectionId}/clear-tasks`, { connectionId, force });
};

export const clearConnectionLogs = async (connectionId, old) => {
  return axios.post(`/connections/${connectionId}/clear-logs`, { connectionId, old });
};

export const runConnectionModule = async values => {
  return axios.post(`/connections/${values.connectionId}/module/run`, { ...values });
};

export const purgeConnection = async values => {
  return axios.post(`/connections/${values.connectionId}/purge`, { ...values });
};

export const forceRunConnection = async values => {
  return axios.post(`/connections/${values.connectionId}/force-run`, { ...values });
};

export const forceRunFromFile = async values => {
  return axios.post(`/connections/${values.connectionId}/force-run-from-file`, { ...values });
};

export const changeConnectionModule = async values => {
  return axios.post(`/connections/${values.connectionId}/settings`, { ...values });
};

export const updateConnection = async values => {
  return axios.put(`connections/${values.connectionId}`, { ...values });
};

export const deleteConnection = async connectionId => {
  return axios.delete(`connections/${connectionId}`);
};

export const downloadMissingDetails = async connectionId => {
  return axios.get(`/connections/${connectionId}/missing-information/download`);
};

export const checkDownload = async connectionId => {
  return axios.get(`/connections/${connectionId}/missing-information/check-download`);
};

const getListedEmployeeLogs = async connectionId => {
  return backgroundAxios.get(`/connections/${connectionId}/listed-employees-logs`);
};

export const getMutationTypes = async connectionId => {
  return backgroundAxios.get(`/connections/${connectionId}/mutation-types`);
};

export const createManualEmployee = async values => {
  return axios.post(`/connections/${values.employee.connectionId}/employees`, {
    connectionId: values.employee.connectionId,
    ...values,
  });
};

export const collectiveSalaryChange = async (connectionId, values) => {
  return axios.post(`/connections/${connectionId}/employees/collective-salary-change`, {
    connectionId: connectionId,
    employees: values,
  });
};

export const createManualEmployees = async (connectionId, values) => {
  return axios.post(`/connections/${connectionId}/employees/create-multiple`, {
    connectionId: connectionId,
    employees: values,
  });
};

const uploadInsurerData = async ({ connectionId, batchId, employeeData }) => {
  return axios.post(`/connections/${connectionId}/batches/${batchId}/compare-data`, {
    employeeData: employeeData,
  });
};

/**
 * @param {Object} args
 * @param {string} args.connectionId
 * @param {string} args.batchId
 * @param {number} [args.page]
 * @param {number} [args.pageSize]
 * @param {string} [args.searchQuery]
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getBatchCompareData = async ({ connectionId, batchId, page, pageSize, searchQuery, filters }) => {
  return axios.get(`/connections/${connectionId}/batches/${batchId}/compare`, {
    params: {
      page,
      perPage: pageSize,
      searchQuery,
      filters,
    },
  });
};

/**
 * @param {Object} args
 * @param {string} args.connectionId
 * @param {string} args.batchId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getBatch = async ({ connectionId, batchId }) => {
  return axios.get(`/connections/${connectionId}/batches/${batchId}/status`, {
    params: {},
  });
};

/**
 * @param {Object} args
 * @param {string} args.connectionId
 * @param {string} args.taskId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getConnectionTask = async ({ connectionId, taskId }) => {
  return axios.get(`/connections/${connectionId}/tasks/${taskId}`, {
    params: { taskId },
  });
};

/**
 * @param {Object} args
 * @param {string} args.connectionId
 * @param {string} args.batchId
 * @param {Array.<string>} args.externalIds
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const approveDraftBatch = async ({ connectionId, batchId, externalIds }) => {
  return axios.post(`/connections/${connectionId}/module/run`, {
    moduleType: 'insurance_brydge_approve_draft_batch_module',
    parameters: {
      batchId,
      connectionId,
      externalIds,
    },
  });
};

/**
 * @param {Object} args
 * @param {string} args.connectionId
 * @param {string} args.batchId
 * @param {Array.<string>} args.externalIds
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const archiveFromDraftBatch = async ({ connectionId, batchId, externalIds }) => {
  return axios.post(`/connections/${connectionId}/module/run`, {
    moduleType: 'insurance_brydge_archive_events_from_draft_batch_module',
    parameters: {
      batchId,
      connectionId,
      externalIds,
    },
  });
};

/**
 * @param {CreateEventsFromDifferencesArgs} args
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const createEvent = async args => {
  return axios.post(`/connections/${args.connectionId}/batches/${args.batchId}/create-event`, {
    connectionId: args.connectionId,
    batchId: args.batchId,
    eventExternalId: args.externalId,
    eventType: args.eventType,
    socialSecurityNumber: args.socialSecurityNumber,
  });
};

/**
 * @param {Object} args
 * @param {string} args.connectionId
 * @param {string} args.batchId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const markAsComplete = async ({ connectionId, batchId }) => {
  return axios.post(`/connections/${connectionId}/batches/${batchId}/mark-as-complete`, {
    batchId,
  });
};

const connectionService = {
  getConnection,
  getConnections,
  createConnection,
  getListedEmployeeLogs,
  getMutationTypes,
  createManualEmployee,
  createManualEmployees,
  collectiveSalaryChange,
  downloadMissingDetails,
  uploadInsurerData,
  getBatchCompareData,
  getConnectionTask,
  approveDraftBatch,
  archiveFromDraftBatch,
  markAsComplete,
  getBatch,
  createEvent,
};

export default connectionService;
