import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { roleService } from '../Services';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS, SUPER_ADMIN_OWNER_ID } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  InitializeRolesState: '[Roles] Initialize state',
  RequestData: '[Roles] Request',
  FulfilledTable: '[Roles] Fulfilled',
  SearchTable: '[Roles] Search',
  ChangePage: '[Roles] change page',
  SetPageSize: '[Roles] set page size',
  SortTable: '[Roles] Sort',
  ApplyFilter: '[Roles] Apply filter',
  RemoveFilter: '[Roles] Remove filter',
  SetSelectedRole: '[Roles] Set selected role',
  ClearAll: '[Roles] Clear all',
  CreateRole: '[Roles] Create new user',
  EditRole: '[Roles] Edit new user',
  DeleteRole: '[Roles] Delete user',
};

const initialState = {
  ...tableInitialState,
  selectedRole: null,
  pageSize: 99,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.InitializeRolesState: {
      return { ...initialState };
    }
    case actionTypes.SetSelectedRole: {
      return {
        ...state,
        selectedRole: action.selectedRole,
      };
    }
    case actionTypes.CreateRole: {
      return {
        ...state,
      };
    }
    case actionTypes.EditRole: {
      return {
        ...state,
      };
    }
    case actionTypes.ClearAll: {
      return {
        ...state,
        selectedRole: null,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initializeRolesState: () => ({
    type: actionTypes.InitializeRolesState,
  }),
  setSelectedRole: selectedRole => ({
    type: actionTypes.SetSelectedRole,
    selectedRole,
  }),
  clearAll: () => ({
    type: actionTypes.ClearAll,
  }),
  createRole: roleValues => ({
    type: actionTypes.CreateRole,
    roleValues,
  }),
  editRole: roleValues => ({
    type: actionTypes.EditRole,
    roleValues,
  }),
  deleteRole: roleId => ({
    type: actionTypes.DeleteRole,
    roleId,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.CreateRole, function* createRoleSaga(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield roleService.createRole(action.roleValues);
      yield reloadData();
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    } finally {
      const details = {
        method: 'createRole',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.EditRole, function* editRoleSaga(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield roleService.editRole(action.roleValues);
      yield reloadData();
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    } finally {
      const details = {
        method: 'editRole',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RequestData, function* requestRoles() {
    try {
      const currentState = yield select(state => {
        return state.RolesReducer;
      });

      const ownerId = yield select(state => {
        return state.AppReducer.environment.ownerId;
      });

      if (ownerId === SUPER_ADMIN_OWNER_ID) {
        const response = yield roleService.getRoles({
          page: currentState.page,
          perPage: currentState.pageSize,
          searchQuery: currentState.searchQuery,
        });

        yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));

        return;
      }

      const response = yield roleService.getOwnerRoles({
        ownerId,
        page: currentState.page,
        perPage: currentState.pageSize,
        searchQuery: currentState.searchQuery,
      });

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getRoles',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.DeleteRole, function* deleteRoleSaga(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;

    try {
      const response = yield roleService.deleteRole(action.roleId);

      if (response.status === 204) {
        yield put(actions.requestData());
      }
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    const details = {
      method: 'deleteRoles',
      severity,
    };
    yield put(AppActions.displayMessage(details));
  });
}
