import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListSubheader } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const disabledStyles = (theme: Theme) => ({
  color: theme.palette.secondary.dark,
});

const highlightedStyles = (theme: Theme) => ({
  fontWeight: 650,
  color: theme.palette.common.black,
});

export const SpanFieldLabelStyled = styled('span', {
  name: 'SpanFieldLabelStyled',
  shouldForwardProp: (propName: string) => !['disabled', 'highlighted'].includes(propName),
})<{ disabled: boolean; highlighted: boolean }>(({ theme, disabled, highlighted }) => ({
  ...theme.typography.h6,
  fontWeight: 500,
  lineHeight: '24px',
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  paddingRight: theme.spacing(0.5),
  overflowWrap: 'break-word',
  ...(disabled ? disabledStyles(theme) : {}),
  ...(highlighted ? highlightedStyles(theme) : {}),
}));

export const SpanFiledValueStyled = styled('span', {
  name: 'SpanFiledValueStyled',
  shouldForwardProp: (propName: string) => !['disabled', 'highlighted'].includes(propName),
})<{ disabled: boolean; highlighted: boolean }>(({ theme, disabled, highlighted }) => ({
  ...theme.typography.h6,
  lineHeight: '24px',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  overflowWrap: 'break-word',
  ...(disabled ? disabledStyles(theme) : {}),
  ...(highlighted ? highlightedStyles(theme) : {}),
}));

export const ListItemStyled = styled(ListItem, {
  name: 'ListItemStyled',
})(({ theme }) => ({
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  paddingLeft: 0,
  paddingRight: 0,
}));

export const ListSubheaderStyled = styled(ListSubheader, {
  name: 'ListSubheaderStyled',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0.5, 0),
  lineHeight: 'unset',
  backgroundColor: theme.palette.primary.light,
}));

export const FontAwesomeIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconStyled',
})(() => ({
  fontSize: '0.8rem',
}));

export const ListStyled = styled(List, {
  name: 'ListStyled',
})(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.light,
  position: 'relative',
  padding: 0,
}));
