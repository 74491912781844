import { styled } from '@mui/material/styles';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  padding: theme.spacing(4),
}));

export const DivContentStyled = styled('div', {
  name: 'DivContentStyled',
})(() => ({
  paddingTop: 150,
  textAlign: 'center',
}));

export const ImgStyled = styled('img', {
  name: 'ImgStyled',
})(() => ({
  marginTop: 50,
  display: 'inline-block',
  maxWidth: '100%',
  width: 560,
}));
