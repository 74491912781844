import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import type { FormikConfig, FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import { TranslationKeys } from 'app/translations';
import { FileType, HasAccessTo } from 'app/components';
import FileUploadAreaWithTemplate from 'app/components/FileUploadAreaWithTemplate';
import { ListFile } from 'app/components/FileUploadAreaWithTemplate/ListFile';
import { BATCH, CONNECTION } from 'app/common/Authorization/entities';
import { RUN, VIEW } from 'app/common/Authorization/permissions';
import type { Connection } from 'app/types';
import type { EmployeeData } from 'app/hooks';
import { useConnectionUploadInsurerDataMutation, useSnakeBar } from 'app/hooks';
import {
  COMPARE_BATCH_FILE_HEADERS,
  COMPARE_BATCH_FILE_STRUCTURE,
  COMPARE_BATCH_TEMPLATE_FILE_NAME,
  CompareBatchFileReadStrategy,
} from './utils';
import { DivStyled } from './CompareBatchFileUpload.styles';
import * as XLSX from 'xlsx';

export const COMPARE_BATCH_FILE_UPLOAD_DATA_TEST_ID = 'compare_batch_file_upload_component';

type FormValues = {
  uploadError: boolean;
  file?: File;
};

type CompareBatchFileUploadProps = {
  connectionId: Connection['connectionId'] | undefined;
  batchId: string | undefined;
  isLoading?: boolean;
  startListeningForDataAfterUploadFile: (taskId: string) => void;
};

export const CompareBatchFileUpload = ({
  connectionId,
  batchId,
  startListeningForDataAfterUploadFile,
  isLoading = false,
}: CompareBatchFileUploadProps) => {
  const intl = useIntl();
  const { showErrorSnakeBar } = useSnakeBar();

  const { mutate: uploadInsurerDataMutation, isLoading: isUploading } = useConnectionUploadInsurerDataMutation();

  const canDownloadTemplate = HasAccessTo(BATCH, VIEW);
  const canUploadFile = HasAccessTo(CONNECTION, RUN);

  const formikInitialValues = React.useMemo<FormValues>(() => {
    return {
      uploadError: false,
      file: undefined,
    };
  }, []);

  const convertToISOString = (dateString: string): string => {
    if (dateString === '') {
      return '';
    }

    const delimiter = dateString.includes('-') ? '-' : '/';
    const parts = dateString.split(delimiter);
    const day = Number(parts[0]);
    const month = Number(parts[1]) - 1;
    const year = Number(parts[2]);

    const date = new Date(Date.UTC(year, month, day));

    return date.toISOString().split('T')[0] + 'T00:00:00.000Z';
  };

  interface PayloadData {
    'Firstname *': string;
    'Name Prefix'?: string;
    'Lastname *': string;
    'Birthdate (dd-mm-yyyy)': string;
    'Gender (e.g. male/female/unknown)': string;
    Email: string;
    'BSN *': string;
    'Civil Status (e.g. single/married/living-together)': string;
    'Employee Number': string;
    'Employment Start Date (dd-mm-yyyy)': string;
    'Employment End Date (dd-mm-yyyy)': string;
    'Wage * (e.g. 1000.00)': string;
    'Part-time Percentage * (e.g. 50)': string;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parseCSVFile = (csvContent: any): string => {
    const csvRows = csvContent.split('\n');
    const headers = csvRows[0].split(';');

    const birthdateIndex = headers.findIndex((header: string | string[]) => header.includes('Birthdate'));
    const startDateIndex = headers.findIndex((header: string | string[]) => header.includes('Employment Start Date'));
    const endDateIndex = headers.findIndex((header: string | string[]) => header.includes('Employment End Date'));

    const payloadData: PayloadData[] = csvRows.slice(1).map((row: string) => {
      const values = row.split(';');
      const payload: Partial<PayloadData> = {};

      if (birthdateIndex >= 0) {
        payload['Birthdate (dd-mm-yyyy)'] = values[birthdateIndex]?.trim() || '';
      }
      if (startDateIndex >= 0) {
        payload['Employment Start Date (dd-mm-yyyy)'] = values[startDateIndex]?.trim() || '';
      }

      if (endDateIndex >= 0) {
        payload['Employment End Date (dd-mm-yyyy)'] = values[endDateIndex]?.trim() || '';
      }

      return payload as PayloadData;
    });

    const modifiedPayloadData = payloadData.map(employee => ({
      'Birthdate (dd-mm-yyyy)': convertToISOString(employee['Birthdate (dd-mm-yyyy)']),
      'Employment Start Date (dd-mm-yyyy)': convertToISOString(employee['Employment Start Date (dd-mm-yyyy)']),
      'Employment End Date (dd-mm-yyyy)': convertToISOString(employee['Employment End Date (dd-mm-yyyy)']),
    }));

    const modifiedCsvRows = csvRows.slice(1).map((row: string, index: string | number) => {
      const values = row.split(';');

      // @ts-ignore
      values[birthdateIndex] = modifiedPayloadData[index]['Birthdate (dd-mm-yyyy)'];
      // @ts-ignore
      values[startDateIndex] = modifiedPayloadData[index]['Employment Start Date (dd-mm-yyyy)'];
      // @ts-ignore
      values[endDateIndex] = modifiedPayloadData[index]['Employment End Date (dd-mm-yyyy)'];

      return values.join(';');
    });

    return [csvRows[0], ...modifiedCsvRows].join('\n');
  };

  const parseExcelFile = useCallback(
    file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
          try {
            const payload = event.target?.result;
            const parsedPayload = parseCSVFile(payload);
            const workbook = XLSX.read(parsedPayload, { type: 'binary', dateNF: 'dd-mm-yyyy' });
            const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
            const range = XLSX.utils.decode_range(sheet['!ref'] || '');

            range.s.r = 1;
            const employeeData: object[] = XLSX.utils.sheet_to_json(sheet, {
              header: [
                'employee.name_first',
                'employee.name_prefix',
                'employee.name_last',
                'employee.date_birth',
                'employee.gender',
                'employee.email',
                'employee.bsn',
                'employee.civil_status',
                'employee.number',
                'employment.start',
                'employment.end',
                'employment.wage',
                'employment.parttime',
              ],
              blankrows: false,
              skipHidden: true,
              rawNumbers: true,
              raw: false,
              range: range,
            });

            resolve(employeeData);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error parsing file:', error);
            showErrorSnakeBar({
              message: intl.formatMessage({ id: TranslationKeys.global_uploadFile_error }),
            });
            reject(error);
          }
        };
        reader.onerror = () => {
          // eslint-disable-next-line no-console
          console.error('File read error:', file.name);
          reject(new Error(`Error occurred reading file: ${file.name}`));
        };
        reader.readAsBinaryString(file);
      });
    },
    [intl, parseCSVFile, showErrorSnakeBar],
  );

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return '';
    }

    const datePart = dateString.split('T')[0];
    const [year, month, day] = datePart.split('-');

    return `${day}-${month}-${year}`;
  };

  const formikOnSubmit = React.useCallback<FormikConfig<FormValues>['onSubmit']>(
    async ({ uploadError, file }) => {
      if (file) {
        await parseExcelFile(file)
          .then(async data => {
            if (!uploadError && connectionId && batchId && data) {
              const method = 'uploadInsurerData';
              const employeeData = data as EmployeeData[];
              const payload = employeeData.map((employee: { [x: string]: any }) => ({
                socialSecurityNumber: employee['employee.bsn'],
                employmentStartDate: formatDate(employee['employment.start']),
                employmentEndDate: formatDate(employee['employment.end']),
                annualWage: parseFloat(employee['employment.wage'] ?? 0),
                partTimePercentage: parseFloat(employee['employment.parttime'] ?? 0),
                firstName: employee['employee.name_first'],
                middleName: employee['employee.name_prefix'],
                lastName: employee['employee.name_last'],
                dateOfBirth: formatDate(employee['employee.date_birth']),
                gender: employee['employee.gender'],
                civilStatus: employee['employee.civil_status'],
              }));

              uploadInsurerDataMutation(
                {
                  connectionId,
                  batchId,
                  // @ts-ignore
                  employeeData: payload,
                },
                {
                  onSuccess: data => {
                    startListeningForDataAfterUploadFile(data.taskId);
                  },
                  onError: error => {
                    showErrorSnakeBar({
                      method,
                      message: intl.formatMessage({ id: TranslationKeys.global_uploadFile_error }),
                    });
                    // eslint-disable-next-line no-console
                    console.error(error);
                  },
                },
              );
            }
          })
          .catch(() => {})
          .finally(() => {});
      }
    },
    [
      parseExcelFile,
      connectionId,
      batchId,
      uploadInsurerDataMutation,
      startListeningForDataAfterUploadFile,
      showErrorSnakeBar,
      intl,
    ],
  );

  const initialValues = React.useMemo<Array<{ element: string; value: { fieldName: string } }>>(() => {
    return COMPARE_BATCH_FILE_STRUCTURE.map(fileStructure => ({
      element: fileStructure.headerTitle,
      value: {
        fieldName: fileStructure.fieldName,
      },
    }));
  }, []);

  const onFilesChange = React.useCallback(
    (form: FormikProps<FormValues>) => (files: File[]) => {
      form.setFieldValue('file', files[0]);
    },
    [],
  );

  const onChangeList = React.useCallback(
    (form: FormikProps<FormValues>) => () => {
      form.submitForm();
    },
    [],
  );

  const readFileStrategy = new CompareBatchFileReadStrategy(intl);
  const listFile = new ListFile(COMPARE_BATCH_FILE_HEADERS, readFileStrategy);

  return (
    <Formik<FormValues> initialValues={formikInitialValues} onSubmit={formikOnSubmit}>
      {form => (
        <Form noValidate>
          <DivStyled data-testid={COMPARE_BATCH_FILE_UPLOAD_DATA_TEST_ID}>
            <FileUploadAreaWithTemplate
              title={TranslationKeys.global_download_templateFile}
              fileName={COMPARE_BATCH_TEMPLATE_FILE_NAME}
              allowedFileTypes={[FileType.Csv]}
              initialFiles={[]}
              onFilesChange={onFilesChange(form)}
              onChangeList={onChangeList(form)}
              showDropArea={true}
              initialValues={initialValues}
              listFile={listFile}
              loading={isLoading || isUploading}
              disabledDownload={!canDownloadTemplate}
              disabledUpload={!canUploadFile}
            />
          </DivStyled>
        </Form>
      )}
    </Formik>
  );
};
