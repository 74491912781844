import { styled } from '@mui/material/styles';
import { formLabelClasses, inputBaseClasses, outlinedInputClasses, selectClasses } from '@mui/material';

import PaperBackground from '../../../../../components/PaperBackground';
import { InputFieldFactory } from '../../../../../components/FormikField';

export const PaperBackgroundStyled = styled(PaperBackground, {
  name: 'PaperBackgroundStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const InputFieldFactoryStyled = styled(InputFieldFactory, {
  name: 'InputFieldFactoryStyled',
})(({ theme }) => ({
  [`& .${formLabelClasses.root}`]: {
    lineHeight: '34px',
    textTransform: 'none',
    fontSize: '0.8125rem',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  [`& .${inputBaseClasses.input}.${outlinedInputClasses.input}.${selectClasses.select}`]: {
    height: '1em',
  },
}));
