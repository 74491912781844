import { useMutation } from '@tanstack/react-query';
import type { CreateEmployerNotesParams } from 'app/Domain/Employers/Services/EmployerService';
import EmployerService from 'app/Domain/Employers/Services/EmployerService';

type CreateEmployerNotesData = CreateEmployerNotesParams;

const mutationFn = (data: CreateEmployerNotesData) => {
  return EmployerService.createEmployerNotes(data);
};

export const useEmployerCreateNotesMutation = () => {
  return useMutation<unknown, unknown, CreateEmployerNotesData>(mutationFn);
};
