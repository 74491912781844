import { useMutation } from '@tanstack/react-query';

import type { CreateUserEnvironmentsArgs } from '../../../Domain/Users/Services';
import { userService } from '../../../Domain/Users/Services';

const mutationFn = (data: CreateUserEnvironmentsArgs) => {
  return userService.createUserEnvironments(data);
};

export const useUserOwnerUpdateMutation = () => {
  return useMutation<unknown, unknown, CreateUserEnvironmentsArgs>(mutationFn);
};
