import { styled } from '@mui/material/styles';

export const DivContainerStyled = styled('div', {
  name: 'DivContainerStyled',
})(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '78vh',
  textAlign: 'center',
}));
