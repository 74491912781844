import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { UnprocessedBatch } from '../../../../../../types';
import { ProductPensionSchemeEnum } from '../../../../../../types';

import formatter from '../../../../../../utils/formatter';

import { TranslationKeys } from '../../../../../../translations';

import { useDialog, useProductPensionSchemeSelectItems } from '../../../../../../hooks';

import type {
  FilterableTableColumnType,
  FilterableTableRowMapperFunc,
  KebabMenuItem,
  TableCellHeaderSortOptions,
} from '../../../../../../components';
import { ActionLink, ConfirmDialog, FilterableTable, HasAccessTo, KebabMenu } from '../../../../../../components';
import { BATCH } from '../../../../../../common/Authorization/entities';
import { EDIT } from '../../../../../../common/Authorization/permissions';

enum TableColumns {
  Connection = 'connectionName',
  Type = 'connectionType',
  TriggerDate = 'triggeredAt',
  Events = 'eventsCount',
  Mutations = 'mutationsCount',
  Errors = 'errorsCount',
  Status = 'batchStatus',
  Actions = 'col_actions',
}

type UnprocessedBatchesTableProps = {
  isLoading?: boolean;
  unprocessedBatches: Array<UnprocessedBatch>;
  onPaginationChange: (args: { page?: number; rowSize?: number }) => void;
  onSortChange?: (sortBy: Record<TableColumns, TableCellHeaderSortOptions>) => void;
  page: number;
  pageSize: number;
  totalCount: number;
  rowsPerPageOptions?: Array<number>;
  onClickApproveBatch: (unprocessedBatch: UnprocessedBatch) => void;
  onClickDeclineBatch: (unprocessedBatch: UnprocessedBatch) => void;
};

export const UnprocessedBatchesTable = ({
  isLoading,
  unprocessedBatches,
  page,
  pageSize,
  totalCount,
  rowsPerPageOptions,
  onPaginationChange,
  onSortChange,
  onClickApproveBatch,
  onClickDeclineBatch,
}: UnprocessedBatchesTableProps) => {
  const { getProductSchemeDisplayNameByValue } = useProductPensionSchemeSelectItems();

  const disabledIfNoAccessToBatchEdit = !HasAccessTo(BATCH, EDIT);

  const {
    dialogState: showBatchApproveConfirmationDialog,
    openDialog: openBatchApproveConfirmationDialog,
    closeDialog: closeBatchApproveConfirmationDialog,
  } = useDialog();

  const {
    dialogState: showBatchDeclineConfirmationDialog,
    openDialog: openBatchDeclineConfirmationDialog,
    closeDialog: closeBatchDeclineConfirmationDialog,
  } = useDialog();

  const [batch, setBatch] = React.useState<UnprocessedBatch | null>(null);

  const tablePagination = React.useMemo(() => {
    return {
      page,
      pageSize,
      totalCount,
      rowsPerPageOptions,
    };
  }, [page, pageSize, rowsPerPageOptions, totalCount]);

  const onClickApproveBatchHandler = React.useCallback(
    (data: UnprocessedBatch) => {
      openBatchApproveConfirmationDialog();
      setBatch(data);
    },
    [openBatchApproveConfirmationDialog],
  );

  const onClickDeclineBatchHandler = React.useCallback(
    (data: UnprocessedBatch) => {
      openBatchDeclineConfirmationDialog();
      setBatch(data);
    },
    [openBatchDeclineConfirmationDialog],
  );

  const onConfirmApproveBatch = React.useCallback(() => {
    if (batch) {
      onClickApproveBatch(batch);
      setBatch(null);
    }
  }, [batch, onClickApproveBatch]);

  const onConfirmDeclineBatch = React.useCallback(() => {
    if (batch) {
      onClickDeclineBatch(batch);
      setBatch(null);
    }
  }, [batch, onClickDeclineBatch]);

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.Connection,
        title: <FormattedMessage id={TranslationKeys.global_connection} />,
        sortable: true,
      },
      {
        name: TableColumns.Type,
        title: <FormattedMessage id={TranslationKeys.global_type} />,
      },
      {
        name: TableColumns.TriggerDate,
        title: <FormattedMessage id={TranslationKeys.connection_processes_triggeredAt} />,
      },
      {
        name: TableColumns.Events,
        title: <FormattedMessage id={TranslationKeys.connection_processes_eventsNumber} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.Mutations,
        title: <FormattedMessage id={TranslationKeys.connection_processes_mutationsNumber} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.Errors,
        title: <FormattedMessage id={TranslationKeys.connection_processes_errorMessagesNumber} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.Status,
        title: <FormattedMessage id={TranslationKeys.connection_processes_result} />,
      },
      {
        name: TableColumns.Actions,
        styles: theme => ({
          width: theme.spacing(5),
          paddingLeft: 0,
          paddingRight: 0,
        }),
      },
    ];
  }, []);

  const buildRowActions = React.useCallback(
    (data: UnprocessedBatch) => {
      const actions: Array<KebabMenuItem> = [
        {
          to: `/employers/${data.employerId}/connections/${data.connectionId}`,
          element: <FormattedMessage id={TranslationKeys.connection_goto} />,
        },
        {
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_approve} />,
          onClick: () => onClickApproveBatchHandler(data),
          disabled: disabledIfNoAccessToBatchEdit,
        },
        {
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_decline} />,
          onClick: () => onClickDeclineBatchHandler(data),
          disabled: disabledIfNoAccessToBatchEdit,
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [disabledIfNoAccessToBatchEdit, onClickApproveBatchHandler, onClickDeclineBatchHandler],
  );

  const getTypeTranslation = React.useCallback(
    (type: string | null) => {
      if (type === ProductPensionSchemeEnum.Adequate) {
        return getProductSchemeDisplayNameByValue(ProductPensionSchemeEnum.Adequate);
      }
      if (type === ProductPensionSchemeEnum.Regular) {
        return getProductSchemeDisplayNameByValue(ProductPensionSchemeEnum.Regular);
      }

      return type;
    },
    [getProductSchemeDisplayNameByValue],
  );

  const renderNumber = React.useCallback((value: number | undefined) => {
    return value ?? 0;
  }, []);

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<UnprocessedBatch>>(
    data => {
      return {
        data: {
          [TableColumns.Connection]: (
            <ActionLink to={`/employers/${data.employerId}/connections/${data.connectionId}`}>
              {data.connectionName}
            </ActionLink>
          ),
          [TableColumns.Type]: getTypeTranslation(data.connectionType),
          [TableColumns.TriggerDate]: formatter.dateTime(data.triggeredAt),
          [TableColumns.Events]: renderNumber(data.eventsCount),
          [TableColumns.Mutations]: renderNumber(data.mutationsCount),
          [TableColumns.Errors]: renderNumber(data.errorsCount),
          [TableColumns.Status]: <FormattedMessage id={`connection.processes.result.${data.state}`} />,
          [TableColumns.Actions]: buildRowActions(data),
        },
      };
    },
    [buildRowActions, getTypeTranslation, renderNumber],
  );

  return (
    <>
      <FilterableTable<UnprocessedBatch>
        loading={isLoading}
        columns={tableHeaders}
        rows={unprocessedBatches}
        rowMapper={tableRowMapper}
        pagination={tablePagination}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
      />

      {showBatchApproveConfirmationDialog && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.connection_processes_approveConfirmationTitle} />}
          open={showBatchApproveConfirmationDialog}
          onClose={closeBatchApproveConfirmationDialog}
          onConfirm={onConfirmApproveBatch}
        >
          <FormattedMessage id={TranslationKeys.connection_processes_approveConfirmationMessage} />
        </ConfirmDialog>
      )}

      {showBatchDeclineConfirmationDialog && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.connection_processes_declineConfirmationTitle} />}
          open={showBatchDeclineConfirmationDialog}
          onClose={closeBatchDeclineConfirmationDialog}
          onConfirm={onConfirmDeclineBatch}
        >
          <FormattedMessage id={TranslationKeys.connection_processes_declineConfirmationMessage} />
        </ConfirmDialog>
      )}
    </>
  );
};
