import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { formatDate, formatRelativeTime } from '../../../../utils/formatter/dateTimeFormatter';
import { HasAccessTo } from '../../../../components/Authorization';
import { CONNECTION, CREDENTIAL } from '../../../../common/Authorization/entities';
import { EDIT, VIEW } from '../../../../common/Authorization/permissions';
import { TranslationKeys } from '../../../../translations';
import KebabMenu from '../../../../components/KebabMenu';
import { ConfirmDialog } from '../../../../components';
import { useDialog } from '../../../../hooks/useDialog';

const CredentialsTable = ({
  className,
  credentials,
  handleShowDetails,
  handlePageChange,
  handleRowsPerPageChange,
  handleClickChange,
  onDelete,
  page,
  rowsPerPage,
  totalCount,
  loading,
}) => {
  const disabledIfNoAccessToCredentialsEdit = !HasAccessTo(CREDENTIAL, EDIT);
  const disabledIfNoAccessToConnectionsView = !HasAccessTo(CONNECTION, VIEW);

  const {
    dialogState: showDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog,
    openDialog: openDeleteConfirmationDialog,
  } = useDialog();

  const [credentialIdToDelete, setCredentialIdToDelete] = React.useState(null);

  const onDeleteClick = React.useCallback(
    credentialId => () => {
      openDeleteConfirmationDialog();
      setCredentialIdToDelete(credentialId);
    },
    [openDeleteConfirmationDialog],
  );

  const onClickConfirmDelete = React.useCallback(() => {
    onDelete(credentialIdToDelete);
    setCredentialIdToDelete(null);
  }, [credentialIdToDelete, onDelete]);

  const buildRowActions = React.useCallback(
    credential => {
      const actions = [
        {
          element: <FormattedMessage id={TranslationKeys.credentials_connections_show} />,
          onClick: () => handleShowDetails(credential.credentialId),
          disabled: disabledIfNoAccessToConnectionsView,
        },
        {
          element: <FormattedMessage id={TranslationKeys.global_edit} />,
          onClick: () => handleClickChange(credential),
          disabled: disabledIfNoAccessToCredentialsEdit,
        },
        {
          element: <FormattedMessage id={TranslationKeys.button_delete} />,
          onClick: onDeleteClick(credential.credentialId),
          disabled: disabledIfNoAccessToCredentialsEdit,
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [
      disabledIfNoAccessToConnectionsView,
      disabledIfNoAccessToCredentialsEdit,
      handleClickChange,
      handleShowDetails,
      onDeleteClick,
    ],
  );

  return (
    <>
      {showDeleteConfirmationDialog && !!credentialIdToDelete && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.global_delete} />}
          open={showDeleteConfirmationDialog}
          onClose={closeDeleteConfirmationDialog}
          onConfirm={onClickConfirmDelete}
        >
          <FormattedMessage id={TranslationKeys.global_deleteConfirmationMessage} />
        </ConfirmDialog>
      )}

      <Card className={className}>
        <CardContent sx={{ padding: 0 }}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id={TranslationKeys.credentials_name} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={TranslationKeys.owners_title} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={TranslationKeys.credentials_type} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={TranslationKeys.credentials_packageType} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={TranslationKeys.credentials_expires} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={TranslationKeys.credentials_createdAt} />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading &&
                    new Array(rowsPerPage)
                      .fill(index => index)
                      .map((value, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      ))}
                  {!loading &&
                    credentials.map(credential => (
                      <TableRow hover key={credential.credentialId}>
                        <TableCell>{credential.name}</TableCell>
                        <TableCell>{credential.owner?.ownerName}</TableCell>
                        <TableCell>{credential['@type']}</TableCell>
                        <TableCell>
                          <FormattedMessage id={`packages.type.${credential.packageType}`} />
                        </TableCell>
                        <TableCell>{formatRelativeTime(credential.accessTokenExpires)}</TableCell>
                        <TableCell>{formatDate(credential.createdAt)}</TableCell>
                        <TableCell>{buildRowActions(credential)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      </Card>
    </>
  );
};

CredentialsTable.propTypes = {
  className: PropTypes.string,
  credentials: PropTypes.array.isRequired,
};

export default CredentialsTable;
