import React from 'react';

import type { PaperProps } from '@mui/material';
import { useIntl } from 'react-intl';

import { debounce } from '../../utils';

import { TranslationKeys } from '../../translations';

import { InputStyled, LeftSearchIconStyled, PaperStyled, RightSearchIconStyled } from './SearchInput.styles';
import type { Field } from '../../types';

export const SEARCH_INPUT_DATA_TEST_ID = 'search-input-component';

export type SearchInputProps = {
  className?: string;
  iconPosition?: 'left' | 'right';
  debounceTime?: number;
  style?: PaperProps['style'];
  initialSearchQuery?: string;
  placeholder?: Field['placeholder'];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export const SearchInput = ({
  className,
  onChange,
  initialSearchQuery,
  iconPosition,
  style,
  placeholder,
  debounceTime = 200,
  disabled = false,
}: SearchInputProps) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = React.useState(initialSearchQuery ?? '');

  const left = typeof iconPosition === 'undefined' || iconPosition === 'left';
  const right = iconPosition === 'right';

  const debouncedChangeHandler = React.useMemo(() => debounce(onChange, debounceTime), [onChange, debounceTime]);

  React.useEffect(() => {
    return () => {
      // Stop the invocation of the debounced function after unmounting
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      debouncedChangeHandler(event);
    },
    [debouncedChangeHandler],
  );

  const placeholderText = React.useMemo(() => {
    if (!placeholder) {
      return intl.formatMessage({ id: TranslationKeys.global_search }).toUpperCase();
    }
    return placeholder;
  }, [intl, placeholder]);

  return (
    <PaperStyled className={className} style={style} elevation={0} variant={'elevation'}>
      {left && <LeftSearchIconStyled />}
      <InputStyled
        data-testid={SEARCH_INPUT_DATA_TEST_ID}
        disableUnderline
        onChange={onInputChange}
        value={inputValue}
        placeholder={placeholderText}
        inputProps={{ type: 'search' }}
        disabled={disabled}
      />
      {right && <RightSearchIconStyled />}
    </PaperStyled>
  );
};
