import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UseQueryWrapper } from '../../../types';

import { useSnakeBar } from '../../useSnakeBar';
import type { GetDataProcessingCountsParams } from '../../../Domain/Dashboard/Services';
import { dashboardService } from '../../../Domain/Dashboard/Services';

export const DATA_PROCESSING_COUNT_KEYS = {
  UnprocessedEvents: 'unprocessedEvents',
  UnprocessedBatches: 'unprocessedBatches',
  MissingDetails: 'missingDetails',
  EventFlow: 'eventFlow',
  ConnectionErrors: 'connectionErrors',
  BlackAndWhiteList: 'blackAndWhiteList',
} as const;

const DataProcessingCountKeyValues = Object.values(DATA_PROCESSING_COUNT_KEYS);

type DataProcessingCountKeys = (typeof DataProcessingCountKeyValues)[number];

export type GetDataProcessingCountsQueryResultData = Record<DataProcessingCountKeys, number>;

export type GetDataProcessingCountsQueryVariables = GetDataProcessingCountsParams;

const queryFn = (variables: GetDataProcessingCountsQueryVariables) => async () => {
  const response = await dashboardService.getDataProcessingCounts(variables);

  return response.data;
};

const QUERY_KEY = 'getDataProcessingCounts';

export const useDashboardGetDataProcessingCountsQuery: UseQueryWrapper<
  GetDataProcessingCountsQueryResultData,
  GetDataProcessingCountsQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetDataProcessingCountsQueryResultData>([QUERY_KEY, variables.userId], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
