import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch } from 'react-redux';
import { formatTimestamp } from '../../../../../../utils/formatter/dateTimeFormatter';
import { formatPath } from '../../../../../../utils/fileSystemHelper';
import { ConfirmDialog } from '../../../../../../components';
import { actions as DownloadsActions } from '../../../../Ducks/Downloads.duck';

import { ButtonStyled } from './DownloadsTable.styles';

let filePath = null;

const DownloadsTable = props => {
  const { className, files, rowsPerPage, loading, downloadFile } = props;

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (filePath !== null) {
      dispatch(DownloadsActions.deleteFile(filePath));
    }
  };

  return (
    <Card className={className}>
      <CardContent sx={{ padding: 0 }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="files.name" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="files.createdAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="files.actions" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  files.map(file => (
                    <TableRow hover key={file.path}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1">{formatPath(file.path)}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{formatTimestamp(file.last_modified)}</TableCell>
                      <TableCell>
                        {file.type !== 'dir' && (
                          <div>
                            <ButtonStyled onClick={() => downloadFile(file.path)}>
                              <FormattedMessage id="files.download" />
                            </ButtonStyled>
                            <ButtonStyled
                              onClick={() => {
                                filePath = file.path;
                                setConfirmDeleteOpen(true);
                              }}
                            >
                              <FormattedMessage id="files.delete" />
                            </ButtonStyled>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <TableFooter>
          <TableRow>
            <TableCell>{`Results: ${files.length}`}</TableCell>
          </TableRow>
        </TableFooter>
      </CardActions>
      <ConfirmDialog
        title="Delete file?"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleDelete}
      >
        <p>
          <FormattedMessage id="files.confirmDelete" />
        </p>
      </ConfirmDialog>
    </Card>
  );
};

DownloadsTable.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default DownloadsTable;
