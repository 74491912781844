import { useEmployeeGetCalculationsQuery } from '../../../../../../hooks';
import { useParams } from 'react-router-dom';
import React, { Fragment } from 'react';
import { format } from 'date-fns';
import * as Yup from 'yup';
import { TranslationKeys } from '../../../../../../translations';
import { Grid } from '@mui/material';
import { InputFieldFactory } from '../../../../../../components';
import {
  EmployeePensionIncomeDetailsPanel,
  EmployeePensionNotRegularMsg,
  EmployeePensionPersonalDetailsPanel,
} from '../EmployeePensionTab/components';
import {
  EmployeeIncomePremiumSickLeavePanel,
  EmployeeIncomePremiumsWGA,
  EmployeeIncomePremiumsWGAERD,
  EmployeeIncomePremiumsWIA,
} from './components';

const ONE_SECOND_DEBOUNCE_TIME = 1000;

export const EmployeeIncomeTab = () => {
  const { employerId, employeeId } = useParams();

  const defaultDateFilterValue = React.useMemo(() => format(Date.now(), 'yyyy-MM-dd'), []);
  const [dateFilterValue, setDateFilterValue] = React.useState<string>(defaultDateFilterValue);

  const {
    data,
    isLoading: isLoadingEmployeeCalculationsQuery,
    isFetching: isFetchingEmployeeCalculationsQuery,
  } = useEmployeeGetCalculationsQuery({
    variables: {
      employerId,
      employeeId,
      date: dateFilterValue,
    },
    options: {
      enabled: !!(employerId && employeeId && dateFilterValue),
    },
  });

  const isEmployeeCalculationsLoading = isLoadingEmployeeCalculationsQuery || isFetchingEmployeeCalculationsQuery;

  const isLoading = isEmployeeCalculationsLoading;
  const isRegularConnection = data?.isRegular ?? true;

  const onFilterChange = React.useCallback(({ name, value }: { name: string; value: number | string }) => {
    if (name === 'date' && typeof value === 'string') {
      const isValidDate = Yup.date().isValidSync(value);
      isValidDate && setDateFilterValue(value);
    }
  }, []);

  const FilterFields = React.useMemo(() => {
    return [
      {
        type: 'date',
        name: 'date',
        header: TranslationKeys.employees_pension_referenceDate,
        defaultValue: defaultDateFilterValue,
        loading: isLoading,
        debounceTime: ONE_SECOND_DEBOUNCE_TIME,
      },
    ].map((field, idx) => (
      <Grid key={idx} item xs={12}>
        <InputFieldFactory field={field} onChange={onFilterChange} />
      </Grid>
    ));
  }, [defaultDateFilterValue, isLoading, onFilterChange]);

  return (
    <Fragment>
      {!isRegularConnection && <EmployeePensionNotRegularMsg />}

      {isRegularConnection && (
        <Grid container spacing={2} wrap={'wrap-reverse'}>
          <Grid item xs={12} md={10}>
            <Grid container spacing={2}>
              <Grid item container xs={12} md={6}>
                <EmployeePensionPersonalDetailsPanel loading={isLoading} data={data?.personal} />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeePensionIncomeDetailsPanel
                  loading={isLoading}
                  titleId={TranslationKeys.employees_detail_wagesIncome}
                  data={data?.income}
                />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeeIncomePremiumSickLeavePanel data={data?.premiumSickLeave} loading={isLoading} />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeeIncomePremiumsWIA data={data?.premiumWiaExcedentIvaExcedent} loading={isLoading} />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeeIncomePremiumsWGA data={data?.premiumWgaGatWiaBodem} loading={isLoading} />
              </Grid>
              <Grid item container xs={12} md={6}>
                <EmployeeIncomePremiumsWGAERD data={data?.premiumWgaErd} loading={isLoading} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={2}>
            <Grid container spacing={2}>
              {FilterFields}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
