import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Skeleton, Typography } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';
import { getDisplayDateValue } from 'app/utils';

import type { EmployeeEmployment, EmployeeSicknessLeave } from 'app/types/employee.types';
import type { UseQueryRefetch } from 'app/types/useQuery.types';

import { useDialog } from 'app/hooks/useDialog';

import ActionButton from 'app/components/ActionButton';
import DetailsPanelRow from 'app/components/DetailsPanelRow';
import DetailsPanel from 'app/components/DetailsPanel';

import { DivPanelBodyDetailsStyled } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/EmployeeEmploymentTab.styles';

import type { Employer } from 'app/types';

import { EMPLOYER, CONNECTION } from 'app/common/Authorization/entities';
import { EDIT, RUN } from 'app/common/Authorization/permissions';
import { ConfirmDialog, HasAccessTo, Tooltip } from 'app/components';
import { EmployeeSicknessLeavesFields } from './EmployeeSicknessLeaves.types';
import { EmployeeSicknessLeaveFormDialog } from './components';
import type { DeleteEmployeeSicknessLeaveArgs } from '../../../../../../Services/EmployeeService';
import { type ShowSnakeBarParams, useSnakeBar } from '../../../../../../../../hooks';
import { useEmployeeEmploymentDeleteSicknessLeaveMutation } from '../../../../../../../../hooks/api/employee/useEmployeeEmploymentDeleteSicknessLeaveMutation';
import { DivTooltipContentStyled } from '../../../../../../../../components/DetailsPanelRow/DetailsPanelRowValueWithReset/DetailsPanelRowValueWithReset.styles';

type EmployeeSicknessLeavesProps = {
  loading: boolean;
  isRegularConnection: boolean;
  refetchGetEmploymentsQuery: UseQueryRefetch;
  employment: (EmployeeEmployment & { employeeId: string; connectionId: string }) | undefined;
  synchronizationStatus: boolean | undefined;
  disabledActionButtonsWhileLoading: boolean;
  employerId: Employer['employerId'] | undefined;
};

export const EmployeeSicknessLeaves = ({
  employment,
  refetchGetEmploymentsQuery,
  loading,
  isRegularConnection,
  synchronizationStatus,
  disabledActionButtonsWhileLoading,
}: EmployeeSicknessLeavesProps) => {
  const {
    dialogState: leaveFormDialogState,
    openDialog: openLeaveFormDialog,
    closeDialog: closeLeaveFormDialog,
  } = useDialog();

  const { mutate: deleteSicknessLeaveMutation } = useEmployeeEmploymentDeleteSicknessLeaveMutation();
  const [expanded, setExpanded] = React.useState(employment?.sicknessLeaves?.map(() => false) || []);
  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState<boolean>(false);
  const [sickLeaveToEdit, setSickLeaveToEdit] = React.useState<EmployeeSicknessLeave | null>(null);
  const hasSicknessLeaves =
    employment?.sicknessLeaves.length !== undefined ? employment.sicknessLeaves.length > 0 : false;
  const hasActiveSynchronization = synchronizationStatus !== undefined ? synchronizationStatus : false;
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);
  const disabledIfSicknessLeaveNotEnded: boolean =
    employment?.sicknessLeaves.some(sicknessLeave => sicknessLeave.endDate === null) ?? false;
  const intl = useIntl();
  const { showSnakeBar } = useSnakeBar();

  const showSnackBarMessage = React.useCallback(
    ({ method, severity }: Omit<ShowSnakeBarParams, 'message'>) => {
      showSnakeBar({
        severity,
        method,
        message: intl.formatMessage({
          id:
            severity === 'success'
              ? TranslationKeys.global_update_successMessage
              : TranslationKeys.global_update_errorMessage,
        }),
      });
    },
    [intl, showSnakeBar],
  );

  const toggleLeaveDetailsPanel = React.useCallback(
    ({ id, expanded: newExpanded }) => {
      const newState = new Array(expanded?.length).fill(false);
      newState[id] = !newExpanded;
      setExpanded(newState);
    },
    [expanded],
  );

  const onOpenSicknessLeaveFormDialog = React.useCallback(
    (leave?: EmployeeSicknessLeave) => {
      setSickLeaveToEdit(leave || null);
      openLeaveFormDialog();
    },
    [openLeaveFormDialog],
  );

  const onOpenConfirmDeleteDialog = React.useCallback((leave?: EmployeeSicknessLeave) => {
    setSickLeaveToEdit(leave || null);
    setConfirmDeleteModal(true);
  }, []);

  const onCloseSicknessLeaveFormDialog = React.useCallback(() => {
    setSickLeaveToEdit(null);
    closeLeaveFormDialog();
  }, [closeLeaveFormDialog]);

  const onClickAddButton = React.useCallback(() => {
    onOpenSicknessLeaveFormDialog();
  }, [onOpenSicknessLeaveFormDialog]);

  const onClickEditButton = React.useCallback(
    (leave: EmployeeSicknessLeave) => () => {
      onOpenSicknessLeaveFormDialog(leave);
    },
    [onOpenSicknessLeaveFormDialog],
  );

  const onClickDeleteButton = React.useCallback(
    (leave: EmployeeSicknessLeave) => {
      onOpenConfirmDeleteDialog(leave);
    },
    [onOpenConfirmDeleteDialog],
  );

  const LoadingDetailsBody = React.useMemo(() => {
    return (
      <>
        {new Array(5).fill(null).map((_, idx) => (
          <Skeleton key={idx} height={24} />
        ))}
      </>
    );
  }, []);

  const deleteSicknessLeave = React.useCallback(() => {
    const onSuccess = (method: string) => () => {
      setConfirmDeleteModal(false);
      showSnackBarMessage({ method, severity: 'success' });
      refetchGetEmploymentsQuery();
    };

    const onError = (method: string) => () => {
      setConfirmDeleteModal(false);
      showSnackBarMessage({ method, severity: 'error' });
    };

    const method = 'deleteSicknessLeave';
    deleteSicknessLeaveMutation(
      {
        connectionId: employment?.connectionId ?? '',
        employeeId: employment?.employeeId ?? '',
        sicknessLeaveId: sickLeaveToEdit?.id ?? '',
        employmentId: employment?.employmentId ?? '',
      } as DeleteEmployeeSicknessLeaveArgs,
      {
        onSuccess: onSuccess(method),
        onError: onError(method),
      },
    );
  }, [
    deleteSicknessLeaveMutation,
    employment?.connectionId,
    employment?.employeeId,
    employment?.employmentId,
    refetchGetEmploymentsQuery,
    showSnackBarMessage,
    sickLeaveToEdit?.id,
  ]);

  const NoSicknessLeavesDetailsBody = React.useMemo(() => {
    return (
      <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKeys.employees_detail_noSickLeaves} />
        </Typography>
      </Box>
    );
  }, []);

  const SicknessLeavesDetailsBody = React.useMemo(() => {
    return (employment?.sicknessLeaves || []).map((sickLeave, idx) => {
      const expandedIfOnlyOneSicknessLeave = employment?.sicknessLeaves?.length === 1;
      const isExpanded = typeof expanded[idx] !== 'undefined' ? expanded[idx] : false;

      const rows = {
        [EmployeeSicknessLeavesFields.StartDate.Label]: getDisplayDateValue(sickLeave?.startDate),
        [EmployeeSicknessLeavesFields.EndDate.Label]: getDisplayDateValue(sickLeave?.endDate),
      };

      return (
        <div key={idx}>
          <DetailsPanelRow
            title={<Typography variant="h6">{format.date(sickLeave.startDate)}</Typography>}
            expanded={expandedIfOnlyOneSicknessLeave || isExpanded}
            rows={rows}
            loading={loading}
            id={idx}
            onExpand={toggleLeaveDetailsPanel}
            onEditButtonClick={onClickEditButton(sickLeave)}
            onDeleteButtonClick={() => onClickDeleteButton(sickLeave)}
            disabledEditButton={
              disabledActionButtonsWhileLoading ||
              hasActiveSynchronization ||
              disabledIfNoAccessToEmployerEdit ||
              disabledIfNoAccessToConnectionRun
            }
          />
        </div>
      );
    });
  }, [
    employment?.sicknessLeaves,
    expanded,
    loading,
    toggleLeaveDetailsPanel,
    onClickEditButton,
    disabledActionButtonsWhileLoading,
    hasActiveSynchronization,
    disabledIfNoAccessToEmployerEdit,
    disabledIfNoAccessToConnectionRun,
    onClickDeleteButton,
  ]);

  const DetailsPanelBody = React.useMemo(() => {
    const finishedLoadingAndHasSicknessLeaves = !loading && hasSicknessLeaves;
    const finishedLoadingAndHasNoSicknessLeaves = !loading && !hasSicknessLeaves;

    return (
      <DivPanelBodyDetailsStyled isRegularConnection={isRegularConnection}>
        <>
          {loading && LoadingDetailsBody}
          {finishedLoadingAndHasSicknessLeaves && SicknessLeavesDetailsBody}
          {finishedLoadingAndHasNoSicknessLeaves && NoSicknessLeavesDetailsBody}
        </>
      </DivPanelBodyDetailsStyled>
    );
  }, [
    loading,
    hasSicknessLeaves,
    isRegularConnection,
    LoadingDetailsBody,
    SicknessLeavesDetailsBody,
    NoSicknessLeavesDetailsBody,
  ]);

  const addButtonTooltipText = React.useMemo(() => {
    return (
      <DivTooltipContentStyled>
        <Typography variant="body1">
          <FormattedMessage id={TranslationKeys.employees_employment_sicknessLeaves_addButtonToolTip} />
        </Typography>
      </DivTooltipContentStyled>
    );
  }, []);

  const DetailsPanelActions = React.useMemo(() => {
    return disabledIfSicknessLeaveNotEnded ? (
      <Tooltip title={addButtonTooltipText}>
        <span>
          <ActionButton
            messageId={TranslationKeys.button_employmentWages_add}
            variant="text"
            icon={faPlus}
            onClick={onClickAddButton}
            disabled={
              disabledActionButtonsWhileLoading ||
              hasActiveSynchronization ||
              disabledIfNoAccessToEmployerEdit ||
              disabledIfNoAccessToConnectionRun ||
              disabledIfSicknessLeaveNotEnded ||
              !employment
            }
          />
        </span>
      </Tooltip>
    ) : (
      <ActionButton
        messageId={TranslationKeys.button_employmentWages_add}
        variant="text"
        icon={faPlus}
        onClick={onClickAddButton}
        disabled={
          disabledActionButtonsWhileLoading ||
          hasActiveSynchronization ||
          disabledIfNoAccessToEmployerEdit ||
          disabledIfNoAccessToConnectionRun ||
          disabledIfSicknessLeaveNotEnded ||
          !employment
        }
      />
    );
  }, [
    disabledIfSicknessLeaveNotEnded,
    addButtonTooltipText,
    onClickAddButton,
    disabledActionButtonsWhileLoading,
    hasActiveSynchronization,
    disabledIfNoAccessToEmployerEdit,
    disabledIfNoAccessToConnectionRun,
    employment,
  ]);

  return (
    <>
      {employment && (
        <EmployeeSicknessLeaveFormDialog
          open={leaveFormDialogState}
          closeDialog={onCloseSicknessLeaveFormDialog}
          employment={employment}
          sickLeave={sickLeaveToEdit}
          refetchGetEmploymentsQuery={refetchGetEmploymentsQuery}
        />
      )}

      <DetailsPanel
        header={<FormattedMessage id={TranslationKeys.employees_employment_sicknessLeaves} />}
        body={DetailsPanelBody}
        actions={DetailsPanelActions}
        scrollBody={true}
      />

      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.employees_employment_sicknessLeaves_confirmDeleteTitle} />}
          open={confirmDeleteModal}
          onConfirm={deleteSicknessLeave}
          onClose={() => setConfirmDeleteModal(false)}
        >
          <FormattedMessage id={TranslationKeys.employees_employment_sicknessLeaves_confirmDeleteMessage} />
        </ConfirmDialog>
      )}
    </>
  );
};
