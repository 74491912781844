import React from 'react';

import type { InputFieldFactoryProps } from './InputFieldFactory';
import InputFieldFactory from './InputFieldFactory';
import RenderFieldFactory from './RenderFieldFactory';

type EditableFieldFactoryProps = Pick<InputFieldFactoryProps, 'onChange' | 'field'> & {
  editing?: boolean;
  value: any;
};

export const EditableFieldFactory = ({ value, field, editing = false, ...otherProps }: EditableFieldFactoryProps) => {
  const fieldData = React.useMemo(() => {
    if (editing) {
      field.defaultValue = value;
      return field;
    }

    field.value = value;
    return field;
  }, [field, editing, value]);

  return (
    <>
      {editing && <InputFieldFactory {...otherProps} field={fieldData} />}
      {!editing && <RenderFieldFactory {...otherProps} field={fieldData} />}
    </>
  );
};
