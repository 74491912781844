import React from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import type { FormikConfig } from 'formik/dist/types';

import { TranslationKeys } from '../../../../../../translations';

import { Uuid } from '../../../../../../utils';

import type { Owner, Role } from '../../../../../../types';

import FormikModal from '../../../../../../components/FormikModal';

import { CreateUserForm } from './components';
import type { CreateUserFormValues } from './CreateUserDialog.types';
import { useOwnersQuery } from '../../../../Hooks';

type CreateUserDialogProps = {
  show: boolean;
  onSubmit: FormikConfig<CreateUserFormValues>['onSubmit'];
  onClose: () => void;
  ownerId?: Owner['ownerId'];
  roles: Role[];
};

export const CreateUserDialog = ({ show, onSubmit, onClose, ownerId, roles }: CreateUserDialogProps) => {
  const { data: owners, isLoading: isLoadingOwnersQuery } = useOwnersQuery();

  const isLoading = isLoadingOwnersQuery;

  const formikValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().trim().min(2).max(50).required(),
        lastName: Yup.string().trim().min(2).max(50).required(),
        emailAddress: Yup.string().email().required(),
        roleId: Yup.string().required(),
      }),
    [],
  );

  const formikInitialValues = React.useMemo<CreateUserFormValues>(() => {
    return {
      userId: Uuid.newV4(),
      emailAddress: '',
      firstName: '',
      middleName: '',
      lastName: '',
      roleId: isLoading ? '' : roles[0] ? roles[0].roleId : '',
      language: isLoading ? '' : 'nl',
      ownerId: isLoading ? '' : ownerId || '',
      internal: false,
    };
  }, [isLoading, ownerId, roles]);

  return (
    <Formik<CreateUserFormValues>
      enableReinitialize
      initialValues={formikInitialValues}
      validationSchema={formikValidationSchema}
      onSubmit={onSubmit}
    >
      {({ resetForm }) => (
        <FormikModal
          show={show}
          onHide={() => {
            onClose();
            resetForm();
          }}
          title={<FormattedMessage id={TranslationKeys.users_add} />}
        >
          <CreateUserForm roles={roles || []} owners={owners?.data || []} isLoading={isLoading} />
        </FormikModal>
      )}
    </Formik>
  );
};
