import { TranslationKeys } from '../../../../translations';
import type { OwnerFormFields } from '../OwnerForm';

export const SubOwnerFormFields = {
  ParentId: {
    Label: TranslationKeys.subOwner_owner,
    InputName: 'parentId',
  },
  OwnerName: {
    Label: TranslationKeys.global_name,
    InputName: 'ownerName',
  },
  OwnerEmail: {
    Label: TranslationKeys.global_emailAddress,
    InputName: 'ownerEmail',
  },
  OwnerPhone: {
    Label: TranslationKeys.global_phoneNumber,
    InputName: 'ownerPhone',
  },
  Address: {
    Label: TranslationKeys.global_address,
    InputName: 'address',
  },
  ZipCode: {
    Label: TranslationKeys.global_address_zipCode,
    InputName: 'zipCode',
  },
  City: {
    Label: TranslationKeys.global_address_city,
    InputName: 'city',
  },
  ContactName: {
    Label: TranslationKeys.global_contactName,
    InputName: 'contactName',
  },
  ContactEmail: {
    Label: TranslationKeys.global_contactEmail,
    InputName: 'contactEmail',
  },
  ContactPhone: {
    Label: TranslationKeys.global_contactPhoneNumber,
    InputName: 'contactPhone',
  },
  Logo: {
    Label: TranslationKeys.global_logo,
    InputName: 'logo',
  },
} as const;

export type SubOwnerFormValues = {
  subOwnerId?: string | null;
  [SubOwnerFormFields.ParentId.InputName]: string;
  [SubOwnerFormFields.OwnerName.InputName]: string;
  [SubOwnerFormFields.OwnerEmail.InputName]?: string;
  [SubOwnerFormFields.OwnerPhone.InputName]?: string;
  [SubOwnerFormFields.Address.InputName]?: string;
  [SubOwnerFormFields.ZipCode.InputName]?: string;
  [SubOwnerFormFields.City.InputName]?: string;
  [SubOwnerFormFields.ContactName.InputName]?: string;
  [SubOwnerFormFields.ContactEmail.InputName]?: string;
  [SubOwnerFormFields.ContactPhone.InputName]?: string;
  [OwnerFormFields.Logo.InputName]?: string | File;
};
