import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';

import ActionButton from '../ActionButton';

export const AlertStyled = styled(Alert, {
  name: 'AlertStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

export const SubmitActionButtonStyled = styled(ActionButton, {
  name: 'SubmitActionButtonStyled',
})(({ theme }) => ({
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
}));
