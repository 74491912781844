import type { FuncOverrideComponent } from '../theme.types';

export const MuiPaper: FuncOverrideComponent<'MuiPaper'> = () => ({
  styleOverrides: {
    root: {
      backgroundImage: 'unset',
    },
    elevation1: {
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    },
    rounded: {
      borderRadius: '10px',
    },
  },
});
