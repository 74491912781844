import React from 'react';
import { TablePagination } from '@mui/material';

export const PAGINATION_DATA_TEST_ID = 'pagination-component';

export type PaginationProps = {
  count?: number;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onPaginationChange?: (args: { page?: number; rowSize?: number }) => void;
};

export const Pagination = ({
  onPaginationChange,
  count = 0,
  page = 1,
  rowsPerPage = 10,
  rowsPerPageOptions = [5, 10, 25, 50, 100],
}: PaginationProps) => {
  const onPageChange = React.useCallback(
    (_, page) => {
      onPaginationChange && onPaginationChange({ page: page + 1 });
    },
    [onPaginationChange],
  );

  const onRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onPaginationChange && onPaginationChange({ rowSize: +event.target.value });
    },
    [onPaginationChange],
  );

  return (
    <TablePagination
      data-testid={PAGINATION_DATA_TEST_ID}
      component="div"
      count={count}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      page={count === 0 ? 0 : Math.max(0, page - 1)}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};
