import React from 'react';
import { useIntl } from 'react-intl';
import { Form, useFormikContext } from 'formik';

import { Box, Grid } from '@mui/material';

import { InputFieldFactory } from 'app/components';

import { ScalesTable, ScalesTableFormErrors, ScalesTableFileUpload } from './components';
import type { SummaryScalesFormValues } from './ScalesTableForm.types';
import { SummaryScalesTableFormFields } from './ScalesTableForm.types';

export const SCALES_TABLE_FORM_DATA_TEST_ID = 'scales-table-form-component';
export const SCALES_TABLE_FORM_ID = 'scales-table-form-id';

type ScaleTableFormProps = {
  isLoading?: boolean;
};

export const ScalesTableForm = ({ isLoading = false }: ScaleTableFormProps) => {
  const intl = useIntl();

  const { values, errors, setFieldValue } = useFormikContext<SummaryScalesFormValues>();

  const prefillTitleValueOnFileUpload = React.useCallback(
    (fileName: string) => {
      setFieldValue(SummaryScalesTableFormFields.Title.InputName, fileName);
    },
    [setFieldValue],
  );

  return (
    <Box data-testid={SCALES_TABLE_FORM_DATA_TEST_ID} paddingTop={2} display={'flex'} flexDirection={'column'} gap={2}>
      <ScalesTableFormErrors errors={errors} />

      <Form id={SCALES_TABLE_FORM_ID} noValidate>
        <Grid container>
          <Grid item xs={12} md={12}>
            <ScalesTableFileUpload
              isLoading={isLoading}
              prefillTitleValueOnFileUpload={prefillTitleValueOnFileUpload}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} md={2}>
            <InputFieldFactory
              field={{
                name: SummaryScalesTableFormFields.TaxLimitPercentage.InputName,
                header: intl.formatMessage({ id: SummaryScalesTableFormFields.TaxLimitPercentage.Label }),
                required: true,
                loading: isLoading,
              }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <InputFieldFactory
              field={{
                name: SummaryScalesTableFormFields.Title.InputName,
                header: intl.formatMessage({ id: SummaryScalesTableFormFields.Title.Label }),
                required: true,
                loading: isLoading,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputFieldFactory
              field={{
                type: 'number',
                name: SummaryScalesTableFormFields.Year.InputName,
                header: intl.formatMessage({ id: SummaryScalesTableFormFields.Year.Label }),
                required: true,
                loading: isLoading,
              }}
            />
          </Grid>
        </Grid>
        {values.agePercentages.length > 0 && (
          <Grid container spacing={2} marginY={1}>
            <Grid item xs={12} md={12}>
              <ScalesTable table={values.agePercentages} />
            </Grid>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
