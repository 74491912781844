import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { Connection, UseQueryWrapper } from '../../../types';

import connectionService from '../../../Domain/Connections/Services/ConnectionService';

import { useSnakeBar } from '../../useSnakeBar';

type GetConnectionQueryVariables = {
  connectionId: Connection['connectionId'] | undefined;
};

type GetConnectionQueryResultData = Connection;

const queryFn =
  ({ connectionId }: GetConnectionQueryVariables) =>
  async () => {
    const response = await connectionService.getConnection(connectionId);
    return response.data;
  };

const QUERY_KEY = 'getConnection';

export const useConnectionGetConnectionQuery: UseQueryWrapper<
  GetConnectionQueryResultData,
  GetConnectionQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetConnectionQueryResultData>([QUERY_KEY, variables.connectionId], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
