import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikModal from 'app/components/FormikModal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialog, HasAccessTo } from 'app/components';
import { normalizeError } from '../../../../../utils';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../../../common';
import { actions as AppActions } from '../../../../App/Ducks/App.duck';
import EmployeeTypesTable from '../../../Components/OwnersSettingsTable';
import { actions as OwnerActions, selectors as ownerSelector, selectors } from '../../../Ducks/Owner.duck';
import ownerService from '../../../Services/OwnerService';
import EmployeeTypeForm from '../../../Forms/Settings/EmployeeTypes';
import { Uuid } from '../../../../../utils';
import { GridStyled, TypographySubTitleStyled } from './EmployeeTypesTab.styles';
import { ActionButton } from '../../../../../components';
import { SETTINGS } from '../../../../../common/Authorization/entities';
import { EDIT } from '../../../../../common/Authorization/permissions';

const EmployeeTypesTab = () => {
  const dispatch = useDispatch();
  const { item: owner, loading: ownerLoading } = useSelector(state => selectors.selectOwner(state.OwnerReducer));
  const { loading: employeeTypesLoading, items: employeeTypes } = useSelector(state =>
    ownerSelector.selectEmployeeTypes(state.OwnerReducer),
  );
  const [employeeTypesTotalCount, setEmployeeTypesTotalCount] = useState(0);
  const [employeeTypeToDelete, setEmployeeTypeToDelete] = useState(false);
  const ownerId = owner?.ownerId;
  const [employeeType, setEmployeeType] = useState({
    employeeTypeId: Uuid.newV4(),
    name: '',
    description: '',
    owner: owner ? owner['@id'] : null,
    ownerId: ownerId,
  });
  const disabledIfNoAccessToSettingEdit = !HasAccessTo(SETTINGS, EDIT);

  useEffect(() => {
    if (ownerId) {
      dispatch(OwnerActions.employeeTypes.requestData({ ownerId }));
    }
  }, [dispatch, ownerId]);

  useEffect(() => {
    if (employeeTypes) {
      setEmployeeTypesTotalCount(employeeTypes.length);
    }
  }, [employeeTypes]);

  const title = useMemo(() => {
    return (
      <div>
        <Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }}>
          <FormattedMessage
            id="owners.settings.employeeTypes.totalCount"
            values={{ totalCount: employeeTypesTotalCount }}
          />
        </Typography>
        <TypographySubTitleStyled variant="subtitle2">
          <FormattedMessage id="owners.settings.employeeTypes.subTitle" />
        </TypographySubTitleStyled>
      </div>
    );
  }, [employeeTypesTotalCount]);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [formHeaderText, setFormHeaderText] = useState('owners.settings.employeeTypes.edit');

  const descriptionCharacterLimit = 100;
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().strict().min(2).max(255).required(),
    description: Yup.string().trim().max(descriptionCharacterLimit),
  });

  const handleUpdate = useCallback(
    item => {
      setIsNewRecord(false);
      setFormHeaderText('owners.settings.employeeTypes.edit');
      setEmployeeType({
        employeeTypeId: item.employeeTypeId,
        name: item.name,
        description: item.description ?? '',
        owner: owner ? owner['@id'] : null,
        ownerId: ownerId,
      });
      setFormModalOpen(true);
    },
    [owner, ownerId],
  );

  const handleAddNew = useCallback(() => {
    setEmployeeType({
      employeeTypeId: Uuid.newV4(),
      name: '',
      description: '',
      owner: owner ? owner['@id'] : null,
      ownerId: ownerId,
    });
    setIsNewRecord(true);
    setFormHeaderText('owners.settings.employeeTypes.add');
    setFormModalOpen(true);
  }, [owner, ownerId]);

  const handleSubmit = (values, form) => {
    if (isNewRecord) {
      submitCreateEmployeeType(values).then(() => {
        closeModalAndResetForm(form);
      });
      return;
    }
    submitEditEmployeeType(values).then(() => {
      closeModalAndResetForm(form);
    });
  };

  const closeModalAndResetForm = form => {
    setFormModalOpen(false);
    form.resetForm();
  };

  const submitEditEmployeeType = async values => {
    ownerService
      .editEmployeeType(values)
      .then(() => {
        dispatch(OwnerActions.employeeTypes.requestData({ ownerId }));
        dispatch(
          AppActions.displayMessage({
            method: 'editEmployeeType',
            severity: MESSAGE_SEVERITY_SUCCESS,
          }),
        );
      })
      .catch(err => {
        const errors = normalizeError(err);
        dispatch(
          AppActions.displayMessage({
            method: 'editEmployeeType',
            severity: MESSAGE_SEVERITY_ERROR,
            message: errors.message,
          }),
        );
      });
  };

  const submitCreateEmployeeType = async values => {
    ownerService
      .createEmployeeType(values)
      .then(() => {
        dispatch(OwnerActions.employeeTypes.requestData({ ownerId }));
        dispatch(
          AppActions.displayMessage({
            method: 'addEmployeeType',
            severity: MESSAGE_SEVERITY_SUCCESS,
          }),
        );
      })
      .catch(err => {
        const errors = normalizeError(err);
        dispatch(
          AppActions.displayMessage({
            method: 'addEmployeeType',
            severity: MESSAGE_SEVERITY_ERROR,
            message: errors.message,
          }),
        );
      });
  };

  const handleDelete = useCallback(item => {
    setConfirmDeleteModal(true);
    setEmployeeTypeToDelete(item.employeeTypeId);
  }, []);

  const removeEmployeeType = useCallback(() => {
    if (employeeTypeToDelete) {
      let severity = MESSAGE_SEVERITY_SUCCESS;
      let message = '';

      ownerService
        .deleteEmployeeType({ ownerId, employeeTypeId: employeeTypeToDelete })
        .then(() => {
          dispatch(OwnerActions.employeeTypes.requestData({ ownerId }));
        })
        .catch(err => {
          const errors = normalizeError(err);
          message = errors.message;
          severity = MESSAGE_SEVERITY_ERROR;
        });
      const details = {
        method: 'deleteEmployeeType',
        severity,
        message: message,
      };
      dispatch(AppActions.displayMessage(details));
    }
  }, [dispatch, employeeTypeToDelete, ownerId]);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={8}>
          {title}
          <EmployeeTypesTable
            loading={employeeTypesLoading || ownerLoading}
            employeeTypes={employeeTypes}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            editPermissions={disabledIfNoAccessToSettingEdit}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <GridStyled item xs={8}>
          <ActionButton
            sx={{ float: 'right' }}
            messageId={'owners.settings.employeeTypes.add'}
            icon={faPlus}
            onClick={handleAddNew}
            disabled={disabledIfNoAccessToSettingEdit}
          />
        </GridStyled>
      </Grid>
      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id="owners.settings.employeeTypes.confirmDeleteTitle" />}
          open={confirmDeleteModal}
          setOpen={setConfirmDeleteModal}
          onConfirm={removeEmployeeType}
        >
          <FormattedMessage id="owners.settings.employeeTypes.confirmDeleteMessage" />
          <br />
          <FormattedMessage id="owners.settings.employeeTypes.confirmDeleteInfo" />
        </ConfirmDialog>
      )}
      {formModalOpen && (
        <Formik initialValues={employeeType} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <FormikModal
            show={formModalOpen}
            title={<FormattedMessage id={formHeaderText} />}
            onHide={() => setFormModalOpen(false)}
          >
            <EmployeeTypeForm initialValues={employeeType} descriptionCharacterLimit={descriptionCharacterLimit} />
          </FormikModal>
        </Formik>
      )}
    </>
  );
};

export default injectIntl(EmployeeTypesTab);
