import { useMutation } from '@tanstack/react-query';

import type { ApproveProcessRequestParams } from '../../../Domain/Connections/Services/ProcessesService';
import { processesService } from '../../../Domain/Connections/Services/ProcessesService';

export type ProcessApproveData = ApproveProcessRequestParams;

const mutationFn = (data: ProcessApproveData) => {
  return processesService.approveProcessRequest(data);
};

export const useProcessApproveMutation = () => {
  return useMutation<unknown, unknown, ProcessApproveData>(mutationFn);
};
