import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from '../../../../../../translations';
import { ActionButton } from '../../../../../../components';

type Reset2FaCodeConfirmationProps = {
  error: string | null;
  isLoading: boolean;
  onConfirmResetClick: () => void;
};

export const RESET_2FA_CODE_CONFIRMATION_DATA_TEST_ID = 'reset-2fa-confirmation-component';

export const Reset2FaCodeConfirmation = ({ error, isLoading, onConfirmResetClick }: Reset2FaCodeConfirmationProps) => {
  return (
    <div data-testid={RESET_2FA_CODE_CONFIRMATION_DATA_TEST_ID}>
      {error !== null && (
        <Box marginBottom={3}>
          <Typography variant="h4" color={'error'}>
            {error}
          </Typography>
        </Box>
      )}

      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Typography>
          <FormattedMessage id={TranslationKeys.signIn_twofa_lost2fa_confirmation_description_paragraph1} />
        </Typography>

        <Typography fontWeight={'bold'}>
          <FormattedMessage id={TranslationKeys.signIn_twofa_lost2fa_confirmation_description_paragraph2} />
        </Typography>
      </Box>

      <Box marginTop={3}>
        <ActionButton
          disabled={isLoading}
          messageId={TranslationKeys.signIn_twofa_lost2fa_button_confirmation}
          onClick={onConfirmResetClick}
        />
      </Box>
    </div>
  );
};
