import React from 'react';
import type { FieldArrayRenderProps } from 'formik';
import { useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import { Grid } from '@mui/material';

import { useGenderSelectItems } from '../../../../../../../hooks/useGenderSelectItems';

import { InputFieldFactory } from '../../../../../../../components/FormikField';

import { ActionButtonStyled, DividerStyled, IconButtonStyled } from './ChildrenStepFieldArray.style';
import type { EmployeeChild } from '../ChildrenStep.types';

const MAX_CHILDREN = 6;

export const CHILD_DEFAULT_VALUES: EmployeeChild = {
  personName: {
    firstName: null,
    lastNamePrefix: null,
    lastName: null,
  },
  gender: null,
  dateOfBirth: null,
  socialSecurityNumber: null,
};

type ChildrenStepFieldArrayProps = {
  arrayHelpers: FieldArrayRenderProps;
  loading?: boolean;
};

export const ChildrenStepFieldArray = ({ arrayHelpers, loading = false }: ChildrenStepFieldArrayProps) => {
  const intl = useIntl();
  const { genderSelectItems } = useGenderSelectItems();

  const childrenCount = arrayHelpers.form.values.employee.children.length;

  const onClickAddChildButton = React.useCallback(() => {
    arrayHelpers.push(CHILD_DEFAULT_VALUES);
  }, [arrayHelpers]);

  const onClickRemoveChildButton = React.useCallback(
    (childIdx: number) => () => {
      arrayHelpers.remove(childIdx);
    },
    [arrayHelpers],
  );

  return (
    <>
      <Grid container spacing={2}>
        {arrayHelpers.form.values.employee.children.map((_: unknown, idx: number) => {
          const fieldNamePrefix = `${arrayHelpers.name}[${idx}]`;
          const showDeleteButtonExceptForFirstChild = idx !== 0;
          const showDividerExceptForLastChild = idx < MAX_CHILDREN;

          return (
            <React.Fragment key={idx}>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    name: `${fieldNamePrefix}.personName.initials`,
                    type: 'text',
                    required: false,
                    header: 'global.initials',
                    placeholder: intl.formatMessage({ id: 'global.initials' }),
                    loading: loading,
                    schema: (schema: any) => schema.trim().strict().max(255),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    name: `${fieldNamePrefix}.personName.firstName`,
                    type: 'text',
                    required: false,
                    header: 'employees.detail.firstName',
                    placeholder: intl.formatMessage({ id: 'employees.detail.firstName' }),
                    loading: loading,
                    schema: (schema: any) => schema.trim().strict().max(255),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    name: `${fieldNamePrefix}.personName.lastNamePrefix`,
                    type: 'text',
                    required: false,
                    header: 'employees.detail.lastNamePrefix',
                    placeholder: intl.formatMessage({ id: 'employees.detail.lastNamePrefix' }),
                    loading: loading,
                    schema: (schema: any) => schema.trim().strict().max(12),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    name: `${fieldNamePrefix}.personName.lastName`,
                    type: 'text',
                    required: false,
                    header: 'employees.detail.lastName',
                    placeholder: intl.formatMessage({ id: 'employees.detail.lastName' }),
                    loading: loading,
                    schema: (schema: any) => schema.trim().strict().max(255),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    name: `${fieldNamePrefix}.dateOfBirth`,
                    type: 'date',
                    required: false,
                    header: 'employees.detail.dateOfBirth',
                    placeholder: intl.formatMessage({ id: 'employees.detail.dateOfBirth' }),
                    loading: loading,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    name: `${fieldNamePrefix}.gender`,
                    type: 'autocomplete',
                    required: false,
                    header: 'employees.detail.gender',
                    placeholder: intl.formatMessage({ id: 'employees.detail.gender' }),
                    loading: loading,
                    items: genderSelectItems,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    name: `${fieldNamePrefix}.socialSecurityNumber`,
                    type: 'text',
                    required: false,
                    header: 'employees.detail.socialSecurityNumber',
                    placeholder: intl.formatMessage({ id: 'employees.detail.socialSecurityNumber' }),
                    loading: loading,
                    schema: (schema: any) => schema.trim().strict().max(12),
                  }}
                />
              </Grid>

              {showDeleteButtonExceptForFirstChild && (
                <Grid item xs={12}>
                  <IconButtonStyled size="small" onClick={onClickRemoveChildButton(idx)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButtonStyled>
                </Grid>
              )}

              {showDividerExceptForLastChild && <DividerStyled />}
            </React.Fragment>
          );
        })}
      </Grid>

      {childrenCount < MAX_CHILDREN && (
        <ActionButtonStyled
          variant="text"
          messageId={'employees.actions.addChild'}
          icon={faPlus}
          onClick={onClickAddChildButton}
        />
      )}
    </>
  );
};
