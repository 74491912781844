import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { Skeleton } from '@mui/material';

const ConnectionEventCountDataCell = ({ connectionId, eventsCount }) => {
  const [eventCount, setEventCount] = useState(null);

  useEffect(() => {
    (() => {
      setEventCount(eventsCount[connectionId] || 0);
    })();
  }, [connectionId, eventsCount]);

  if (eventCount === null) {
    return <Skeleton variant="rect" width={50} />;
  }

  return <Chip label={eventCount} color={eventCount > 0 ? 'primary' : 'default'} size="small" />;
};

export default ConnectionEventCountDataCell;
