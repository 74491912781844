import React from 'react';

import type { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

import { MuiTooltipStyled } from './Tooltip.styles';

type TooltipProps = {
  children: React.ReactElement;
  title: React.ReactElement;
  className?: string;
  placement?: MuiTooltipProps['placement'];
  disableInteractive?: boolean;
  arrow?: boolean;
  open?: boolean;
};

export const Tooltip = ({
  className,
  children,
  title,
  disableInteractive = false,
  placement = 'bottom',
  arrow = true,
  open,
  ...otherProps
}: TooltipProps) => {
  return (
    <MuiTooltipStyled
      {...otherProps}
      className={className}
      title={title}
      placement={placement}
      disableInteractive={disableInteractive}
      arrow={arrow}
      open={open}
    >
      {children}
    </MuiTooltipStyled>
  );
};
