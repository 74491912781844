import { styled } from '@mui/material/styles';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  padding: theme.spacing(4),
}));

export const IFrameStyled = styled('iframe', {
  name: 'IFrameStyled',
})(() => ({
  width: '100%',
  minHeight: 640,
  border: 0,
}));
