import React, { forwardRef, useCallback } from 'react';

const WizardStep = forwardRef(({ component: Component, onSubmit, ...rest }, ref) => {
  const submit = useCallback(
    settings => {
      if (onSubmit) {
        onSubmit(settings);
      }
    },
    [onSubmit],
  );

  return <Component onSubmit={submit} ref={ref} {...rest} />;
});

WizardStep.displayName = 'WizardStep';

export default WizardStep;
