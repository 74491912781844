import type { FuncOverrideComponent } from '../theme.types';

export const MuiTablePagination: FuncOverrideComponent<'MuiTablePagination'> = theme => ({
  styleOverrides: {
    toolbar: {
      color: theme.palette.text.secondary,
    },
    actions: {
      color: theme.palette.secondary.main,
    },
  },
});
