import { debounce, put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { saveFileFromResponse } from 'app/utils';
import { reportService } from '../Services/ReportService';

export const actionTypes = {
  InitializeReportsState: '[Reports] Initialize state',
  RequestData: '[Reports] Request',
  FulfilledTable: '[Reports] Fulfilled',
  SearchTable: '[Reports] Search',
  ChangePage: '[Reports] change page',
  SetPageSize: '[Reports] set page size',
  SortTable: '[Reports] Sort',
  ApplyFilter: '[Reports] Apply filter',
  RemoveFilter: '[Reports] Remove filter',
  ToggleReport: '[Reports] Toggle report',
  ToggleAllReports: '[Reports] Toggle all Reports',
  ClearAll: '[Reports] Clear all',
  GetInvoicingReports: '[Reports] Get invoicing reports',
  GoToFolder: '[Reports] Go to folder',
  DownloadReport: '[Reports] Download Report',
};

const initialState = {
  ...tableInitialState,
  path: 'reports',
  breadCrumbs: [],
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.InitializeReportsState: {
      return { ...initialState };
    }
    case actionTypes.GetInvoicingReports: {
      return {
        ...state,
      };
    }
    case actionTypes.RequestData: {
      return {
        ...state,
        selectedRowsIds: [],
        loading: true,
        error: false,
      };
    }
    case actionTypes.GoToFolder: {
      return {
        ...state,
        selectedRowsIds: [],
        loading: true,
        error: false,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initializeReportsState: () => ({
    type: actionTypes.InitializeReportsState,
  }),
  toggleAllReports: events => ({
    type: actionTypes.ToggleAllReports,
    events,
  }),
  goToFolder: folderPath => ({
    type: actionTypes.GoToFolder,
    folderPath,
  }),
  downloadReport: filePath => ({
    type: actionTypes.DownloadReport,
    filePath,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.GoToFolder, function* request(action) {
    try {
      const currentState = yield select(state => {
        return state.ReportsReducer;
      });
      const response = yield reportService.getInvoicingReports({
        page: currentState.page,
        perPage: currentState.pageSize,
        searchQuery: currentState.searchQuery,
        path: action.folderPath,
      });
      currentState.breadCrumbs = response.data.breadCrumbs;
      yield put(actions.fulfilled(response.data.listing, response.data.listing.length));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getInvoicingReports',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RequestData, function* request() {
    try {
      const currentState = yield select(state => {
        return state.ReportsReducer;
      });
      const response = yield reportService.getInvoicingReports({
        page: currentState.page,
        perPage: currentState.pageSize,
        searchQuery: currentState.searchQuery,
        path: currentState.path,
      });
      currentState.breadCrumbs = response.data.breadCrumbs;
      yield put(actions.fulfilled(response.data.listing, response.data.listing.length));
    } catch (error) {
      let method = 'getInvoicingReports';

      if (error.response.status === 403) {
        method = 'access';
      }

      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method,
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.DownloadReport, function* download(action) {
    try {
      const response = yield reportService.downloadReport(action.filePath);
      saveFileFromResponse(response);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'downloadReport',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });
}
