import * as React from 'react';
import type { FormikContextType } from 'formik';
import { Formik, FormikContext } from 'formik';
import { isFunction } from 'app/utils';

const emptyValues = {};
const nopSubmit = () => {};

type FormikWrapperProps = {
  children: React.ReactNode;
};

const FormikWrapper = ({ children }: FormikWrapperProps) => {
  const formik = React.useContext<FormikContextType<any>>(FormikContext);

  if (formik) {
    return <>{isFunction(children) ? children(formik) : children}</>;
  }

  return (
    <Formik initialValues={emptyValues} onSubmit={nopSubmit}>
      {children}
    </Formik>
  );
};

export default FormikWrapper;
