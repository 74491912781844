import { styled } from '@mui/material/styles';
import { Button, Grid, TextField, Typography } from '@mui/material';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
}));

export const GridContainerStyled = styled(Grid, {
  name: 'GridContainerStyled',
})(() => ({
  height: '100%',
}));

export const GridQuoteContainerStyled = styled(Grid, {
  name: 'GridQuoteContainerStyled',
})(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const DivQuoteStyled = styled('div', {
  name: 'DivQuoteStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(/images/auth.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

export const DivInnerQuoteStyled = styled('div', {
  name: 'DivInnerQuoteStyled',
})(() => ({
  textAlign: 'center',
  flexBasis: '600px',
}));

export const TypographyQuoteTextStyled = styled(Typography, {
  name: 'TypographyQuoteTextStyled',
})(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 300,
}));

export const TypographyPersonNameStyled = styled(Typography, {
  name: 'TypographyPersonNameStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(3),
  color: theme.palette.common.white,
}));

export const TypographyPersonBioStyled = styled(Typography, {
  name: 'TypographyPersonBioStyled',
})(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const GridContentStyled = styled(Grid, {
  name: 'GridContentStyled',
})(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const DivContentStyled = styled('div', {
  name: 'DivContentStyled',
})(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const DivContentHeaderStyled = styled('div', {
  name: 'DivContentHeaderStyled',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

export const DivContentBodyStyled = styled('div', {
  name: 'DivContentBodyStyled',
})(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

export const FormStyled = styled('form', {
  name: 'FormStyled',
})(({ theme }) => ({
  paddingLeft: 100,
  paddingRight: 100,
  paddingBottom: 125,
  flexBasis: 700,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const TypographyTitleStyled = styled(Typography, {
  name: 'TypographyTitleStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const TextFieldStyled = styled(TextField, {
  name: 'TextFieldStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const DivPolicyStyled = styled('div', {
  name: 'DivPolicyStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

export const SignUpButtonStyled = styled(Button, {
  name: 'SignUpButtonStyled',
})(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
