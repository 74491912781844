import { TranslationKeys } from 'app/translations';
import type { RateTable } from 'app/types';

export const RateTableFormFields = {
  InsurerId: {
    Label: TranslationKeys.global_insurer,
    InputName: 'insurerId',
  },
  ProductId: {
    Label: TranslationKeys.global_product,
    InputName: 'productId',
  },
  Year: {
    Label: TranslationKeys.global_year,
    InputName: 'year',
  },
  Discount: {
    Label: TranslationKeys.global_discount,
    InputName: 'discount',
  },
  UnitRate: {
    Label: TranslationKeys.global_unitRate,
    InputName: 'unitRate',
  },
  TableType: {
    Label: TranslationKeys.global_TableType,
    InputName: 'tableType',
  },
  Indexation: {
    Label: TranslationKeys.settings_rateTables_indexation,
    InputName: 'indexation',
  },
  Title: {
    Label: TranslationKeys.global_title,
    InputName: 'title',
  },
  Table: {
    InputName: 'table',
  },
} as const;

export type RateTableFormValues = {
  uploadError: boolean;
  table: RateTable['table'];
  [RateTableFormFields.Title.InputName]: string;
  [RateTableFormFields.InsurerId.InputName]: string;
  [RateTableFormFields.ProductId.InputName]: string;
  [RateTableFormFields.Year.InputName]: string | number;
  [RateTableFormFields.Indexation.InputName]: string | number;
  [RateTableFormFields.Discount.InputName]: number;
  [RateTableFormFields.UnitRate.InputName]: number;
  [RateTableFormFields.TableType.InputName]: string;
};
