import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatDate } from '../../../../../utils/formatter/dateTimeFormatter';

import { TranslationKeys } from '../../../../../translations';

import type { Employer, EmployerFile } from '../../../../../types';

import { useDialog, useSnakeBar } from '../../../../../hooks';

import type { SingleSelectedState, TableSelectedState } from '../../../../../store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from '../../../../../store';

import {
  ConfirmDialog,
  FilterableTable,
  type FilterableTableColumnType,
  KebabMenu,
  type KebabMenuItem,
} from '../../../../../components';

import { useEmployerFileDeleteMutation } from '../../../../../hooks/api/employer/useEmployerFileDeleteMutation';
import { EmployerFileUploadFormDialog } from './EmployerFileUploadFormDialog';
import { employerService } from '../../../Services/EmployerService';
import { triggerAWSFileDownload } from '../../../../../utils';
import { columnActionsStyles } from 'app/Domain/Employers/Pages/EmployerDetailPage/EmployerFilesTab/EmployerFilesTable.styles';

export const EmployerFilesTab = () => {
  const dispatch = useStoreDispatch();
  const intl = useIntl();

  const { item: employer, loading: employerLoading } = useStoreSelector<SingleSelectedState<Employer>>(state =>
    StoreSelectors.EmployerSelector.selectEmployer(state.EmployerReducer),
  );

  const {
    loading: filesLoading,
    loaded: filesLoaded,
    items: files,
    page,
    pageSize,
    totalCount,
    filters: employerFileFilters,
  } = useStoreSelector<TableSelectedState<EmployerFile>>(state =>
    StoreSelectors.EmployerSelector.selectEmployerFiles(state.EmployerReducer),
  );

  const {
    dialogState: showEmployerFileSelectorDialog,
    openDialog: openEmployerFileSelectorDialog,
    closeDialog: closeEmployerFileCSCModeSelectorDialog,
  } = useDialog();

  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState(false);
  const [fileIdToDelete, setFileIdToDelete] = React.useState<EmployerFile['fileId'] | null>(null);
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const employerId = employer?.employerId;
  const searchQuery = employerFileFilters.file || null;

  const { mutate: deleteEmployerFileMutation } = useEmployerFileDeleteMutation();

  React.useEffect(() => {
    if (employerId) {
      dispatch(StoreActions.EmployerActions.files.setEmployerId(employerId));
    }
  }, [dispatch, employerId]);

  const onDeleteActionClick = React.useCallback(fileId => {
    setConfirmDeleteModal(true);
    setFileIdToDelete(fileId);
  }, []);

  const onDownloadEmployerFileActionClick = React.useCallback(
    async (fileId, filename) => {
      try {
        const response = await employerService.downloadEmployerFile({
          employerId: employerId,
          fileId: fileId,
          encodedFilename: filename,
        });

        await triggerAWSFileDownload({
          fileUrl: response.data,
          encodedFilename: filename,
        });
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        showErrorSnakeBar({ method: 'downloadEmployerFile', message: error });
      }
    },
    [employerId, showErrorSnakeBar],
  );

  const deleteEmployerFile = React.useCallback(() => {
    if (fileIdToDelete) {
      const method = 'deleteEmployerFile';

      deleteEmployerFileMutation(
        { fileId: fileIdToDelete, employerId: employerId },
        {
          onSuccess: () => {
            dispatch(StoreActions.EmployerActions.files.setEmployerId(employerId));

            setFileIdToDelete(null);
            showSuccessSnakeBar({ method });
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error.message });
          },
        },
      );
    }
  }, [fileIdToDelete, deleteEmployerFileMutation, dispatch, employerId, showSuccessSnakeBar, showErrorSnakeBar]);

  const buildRowActions = React.useCallback(
    (file: EmployerFile) => {
      const actions: Array<KebabMenuItem> = [
        {
          element: <FormattedMessage id={TranslationKeys.global_download} />,
          onClick: () => onDownloadEmployerFileActionClick(file.fileId, file.encodedFilename),
        },
        {
          element: <FormattedMessage id={TranslationKeys.global_delete} />,
          onClick: () => onDeleteActionClick(file.fileId),
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [onDeleteActionClick, onDownloadEmployerFileActionClick],
  );

  const afterSubmitEmployerFileUploadFormDialog = React.useCallback(() => {
    closeEmployerFileCSCModeSelectorDialog();
  }, [closeEmployerFileCSCModeSelectorDialog]);

  const filesData = React.useMemo(() => {
    if (filesLoading || employerLoading) {
      return [];
    }

    return (files || []).map(file => {
      return {
        ...file,
        title: file?.title,
        encodedFilename: file?.encodedFilename,
        createdAt: file?.createdAt,
        employerId: file?.employerId,
      };
    });
  }, [files, filesLoading, employerLoading]);

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === 'addFile') {
        openEmployerFileSelectorDialog();
      } else {
        dispatch(StoreActions.EmployerActions.files.applyFilter('title', value));
      }
    },
    [dispatch, openEmployerFileSelectorDialog],
  );

  const pagination = React.useMemo(() => {
    return {
      pageSize,
      totalCount,
      page,
    };
  }, [totalCount, page, pageSize]);

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (employerId) {
        if (typeof rowSize !== 'undefined') {
          dispatch(StoreActions.EmployerActions.files.setPageSize(rowSize));
        }
        if (typeof page !== 'undefined') {
          dispatch(StoreActions.EmployerActions.files.changePage(page));
        }
      }
    },
    [dispatch, employerId],
  );

  const onSortChange = React.useCallback(
    sortBy => {
      if (employerId) {
        dispatch(StoreActions.EmployerActions.files.sortTable(sortBy));
      }
    },
    [dispatch, employerId],
  );

  const filters = React.useMemo(() => {
    return [
      {
        type: 'search',
        name: 'employerFile',
        label: intl.formatMessage({ id: TranslationKeys.employers_tab_search }),
        initialValue: searchQuery,
      },
      {
        type: 'action',
        name: 'addFile',
        label: intl.formatMessage({ id: TranslationKeys.employers_tab_files_addFile }),
      },
    ];
  }, [intl, searchQuery]);

  const headers = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: 'title',
        title: <FormattedMessage id={TranslationKeys.employers_tab_files_title} />,
      },
      {
        name: 'createdAt',
        title: <FormattedMessage id={TranslationKeys.employers_tab_files_createdAt} />,
      },
      {
        name: 'actions',
        isActionColumn: true,
        styles: columnActionsStyles,
      },
    ];
  }, []);

  const rowMapper = React.useCallback(
    file => {
      return {
        data: {
          title: file?.title,
          createdAt: formatDate(file?.createdAt) || '-',
          actions: buildRowActions(file),
        },
      };
    },
    [buildRowActions],
  );

  return (
    <>
      <FilterableTable
        columns={headers}
        loading={filesLoading || !filesLoaded}
        rows={filesData}
        pagination={pagination}
        filters={filters}
        onFilterChange={onFilterChange}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        rowMapper={rowMapper}
      />
      {showEmployerFileSelectorDialog && !!employerId && (
        <EmployerFileUploadFormDialog
          show={true}
          onClose={closeEmployerFileCSCModeSelectorDialog}
          afterSubmit={afterSubmitEmployerFileUploadFormDialog}
          employerId={employerId}
          employerConnections={[]}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.employers_tab_files_confirmDeleteTitle} />}
          open={confirmDeleteModal}
          setOpen={setConfirmDeleteModal}
          onConfirm={deleteEmployerFile}
        >
          <FormattedMessage id={TranslationKeys.employers_tab_files_confirmDeleteMessage} />
        </ConfirmDialog>
      )}
    </>
  );
};
