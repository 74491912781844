import { styled } from '@mui/material/styles';

export const DivContainerStyled = styled('div', {
  name: 'DivContainerStyled',
})(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
  transform: 'translate(0px, -5px)',
  '& p': {
    color: theme.palette.primary.dark,
  },
}));
