import { useMutation } from '@tanstack/react-query';
import ConnectionService from '../../../Domain/Connections/Services/ConnectionService';

type MarkAsComplete = {
  connectionId: string;
  batchId: string;
};

const mutationFn = (data: MarkAsComplete) => {
  return ConnectionService.markAsComplete(data);
};

export const useConnectionMarkAsCompleteMutation = () => {
  return useMutation<unknown, unknown, MarkAsComplete>(mutationFn);
};
