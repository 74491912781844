import type { SummaryOfRegulation } from '../../../../hooks';

export const GROUP_KEYS = {
  OverviewRegulations: 'summaryOfRegulations.group.overviewRegulations',
  Employees: 'summaryOfRegulations.group.employees',
  Scales: 'summaryOfRegulations.group.scales',
  PartnerPension: 'summaryOfRegulations.group.partnerPension',
  OrphanPension: 'summaryOfRegulations.group.orphanPension',
  DisabilityPension: 'summaryOfRegulations.group.disabilityPension',
  SurvivorsBridgingPension: 'summaryOfRegulations.group.survivorsBridgingPension',
} as const;

const GROUP_VALUES = Object.values(GROUP_KEYS);

export type RegulationsFromGroupKeys = (typeof GROUP_VALUES)[number];

export type RegulationsFormGroup = Record<RegulationsFromGroupKeys, Array<SummaryOfRegulation>>;
