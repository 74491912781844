import { styled } from '@mui/material/styles';
import { InputFieldFactory } from '../../../../../../components';

export const EventFlowCommentFieldStyled = styled(InputFieldFactory, { name: 'EventFlowCommentFieldStyled' })(
  ({ theme }) => ({
    margin: theme.spacing(0, 0, 2),
  }),
);

export const EventFlowCommentSubmitWrapper = styled('div', { name: 'EventFlowCommentSubmitWrapper' })({
  display: 'flex',
  justifyContent: 'flex-end',
});
