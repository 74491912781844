import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { actions as ConnectionActions } from '../../../Ducks/Connection.duck';
import { actions as EventsActions } from '../../../Ducks/Events.duck';
import { isEmpty } from 'app/utils';
import Grid from '@mui/material/Grid';
import EventsToolbar from '../../../Components/EventsToolbar/EventsToolbar';
import EventsTable from '../../../Packages/InsuranceBrydge/Components/EventsTable';
import { ShowEventDetailsDialog } from '../../../Packages/InsuranceBrydge/Components/ShowEventDetailsDialog';
import { SnackBar } from 'app/components';
import { FormattedMessage } from 'react-intl';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { ConnectionNoteInput } from '../../../Components/ConnectionNoteInput';
import { HasAccessTo } from 'app/components/Authorization';
import { CONNECTION } from '../../../../../common/Authorization/entities';
import { NOTE } from '../../../../../common/Authorization/permissions';
import { DivContentStyled } from './EventsTab.styles';
import { ProductPensionSchemeEnum } from '../../../../../types';

const EventsTab = () => {
  const dispatch = useDispatch();
  const { connectionId } = useParams();
  const [showEventDetails, setShowEventDetails] = useState(null);
  const [allEventsArchived, setAllEventsArchived] = useState(false);
  const [allEventsUnarchived, setAllEventsUnarchived] = useState(false);
  const [filtersCheck, setFiltersCheck] = useState(true);

  const { items, page, pageSize, totalCount, filters, selectedRowsIds, archivedRows, unarchivedRows, searchQuery } =
    useSelector(state => state.EventsReducer);

  const { loading: appLoading, showSnackBar } = useSelector(state => state.AppReducer);
  const { loading: connectionLoading, item: connection } = useSelector(state => state.ConnectionReducer);
  const isRegularConnection = connection?.product?.pensionScheme === ProductPensionSchemeEnum.Regular;

  useEffect(() => {
    dispatch(ConnectionActions.requestData(connectionId));
    dispatch(EventsActions.setConnectionId(connectionId));
    dispatch(EventsActions.clearEvents());
    dispatch(EventsActions.resetFilters());
  }, [connectionId, dispatch]);

  useEffect(() => {
    dispatch(EventsActions.search(searchQuery));
  }, [dispatch, searchQuery]);

  useEffect(() => {
    if (
      !isEmpty(filters.eventType) ||
      !isEmpty(filters['eventDate[before]']) ||
      !isEmpty(filters['eventDate[after]']) ||
      filters.archived !== false ||
      searchQuery !== ''
    ) {
      setFiltersCheck(false);
    } else {
      setFiltersCheck(true);
    }
  }, [filters, searchQuery]);

  useEffect(() => {
    if (archivedRows > 0 && unarchivedRows === 0) {
      setAllEventsArchived(true);
      setAllEventsUnarchived(false);
    } else if (archivedRows === 0 && unarchivedRows > 0) {
      setAllEventsArchived(false);
      setAllEventsUnarchived(true);
    } else {
      setAllEventsArchived(false);
      setAllEventsUnarchived(false);
    }
  }, [archivedRows, unarchivedRows]);

  const handlePageChange = useCallback(
    (event, newPage) => {
      dispatch(EventsActions.changePage(newPage + 1));
    },
    [dispatch],
  );

  const handleRowsPerPageChange = useCallback(
    event => {
      dispatch(EventsActions.setPageSize(event.target.value));
    },
    [dispatch],
  );

  const handleToggleEvent = useCallback(
    event => {
      dispatch(EventsActions.toggleEvent(event));
    },
    [dispatch],
  );

  const handleToggleAllEvents = useCallback(
    events => {
      dispatch(EventsActions.toggleAllEvents(events));
    },
    [dispatch],
  );

  const applyFilter = useCallback(
    (key, value) => {
      dispatch(EventsActions.applyFilter(key, value));
    },
    [dispatch],
  );

  const resetFilters = useCallback(() => {
    dispatch(EventsActions.resetFilters());
    setFiltersCheck(true);
  }, [dispatch]);

  const archiveAllEvents = useCallback(() => {
    dispatch(EventsActions.archiveSelectedEvents(true));
  }, [dispatch]);

  const archiveEvents = useCallback(() => {
    dispatch(EventsActions.archiveSelectedEvents(false));
  }, [dispatch]);

  const unArchiveAllEvents = useCallback(() => {
    dispatch(EventsActions.unArchiveSelectedEvents(true));
  }, [dispatch]);

  const unArchiveEvents = useCallback(() => {
    dispatch(EventsActions.unArchiveSelectedEvents(false));
  }, [dispatch]);

  const createDraftBatch = React.useCallback(
    selectAll => {
      dispatch(EventsActions.processSelectedEvents({ selectAll, isDraft: true }));
    },
    [dispatch],
  );

  const processAllEvents = useCallback(() => {
    dispatch(EventsActions.processSelectedEvents({ selectAll: true }));
  }, [dispatch]);

  const processEvents = useCallback(() => {
    dispatch(EventsActions.processSelectedEvents({ selectAll: false }));
  }, [dispatch]);

  const downloadAllEvents = useCallback(() => {
    dispatch(EventsActions.downloadSelectedEvents(true));
  }, [dispatch]);

  const downloadEvents = useCallback(() => {
    dispatch(EventsActions.downloadSelectedEvents(false));
  }, [dispatch]);

  const handleSearch = useCallback(
    event => {
      dispatch(EventsActions.search(event.target.value));
    },
    [dispatch],
  );

  const handleShowDetails = useCallback(event => {
    setShowEventDetails(event);
  }, []);

  const archiveSamePeriodEvents = useCallback(() => {
    dispatch(EventsActions.archiveSamePeriodEvents());
  }, [dispatch]);

  useBreadcrumb('connectionDetails.events');

  const enabledIfAccessToConnectionNote = HasAccessTo(CONNECTION, NOTE);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <EventsToolbar
            isRegularConnection={isRegularConnection}
            connectionId={connectionId}
            handleSearch={handleSearch}
            filters={filters}
            filtersCheck={filtersCheck}
            resetFilters={resetFilters}
            applyFilter={applyFilter}
            archiveEvents={archiveEvents}
            unArchiveEvents={unArchiveEvents}
            createDraftBatch={createDraftBatch}
            processEvents={processEvents}
            downloadEvents={downloadEvents}
            archiveAllEvents={archiveAllEvents}
            unArchiveAllEvents={unArchiveAllEvents}
            processAllEvents={processAllEvents}
            downloadAllEvents={downloadAllEvents}
            hasSelectedItems={selectedRowsIds.length > 0}
            allEventsArchived={allEventsArchived}
            allEventsUnarchived={allEventsUnarchived}
            archiveSamePeriodEvents={archiveSamePeriodEvents}
            initialSearchQuery={searchQuery}
          />
          <DivContentStyled />
          <EventsTable
            events={items}
            page={page - 1}
            rowsPerPage={pageSize}
            totalCount={totalCount}
            loading={connectionLoading || appLoading}
            selectedRowsIds={selectedRowsIds}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handleToggleEvent={handleToggleEvent}
            handleToggleAllEvents={handleToggleAllEvents}
            handleShowDetails={handleShowDetails}
          />
          {showEventDetails && (
            <ShowEventDetailsDialog
              open={!!showEventDetails}
              onClose={() => setShowEventDetails(null)}
              event={showEventDetails}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {enabledIfAccessToConnectionNote && <ConnectionNoteInput connectionId={connectionId} />}
        </Grid>
      </Grid>
      <SnackBar message={<FormattedMessage id="events.tooManyEvents" />} open={showSnackBar} />
    </>
  );
};

export default EventsTab;
