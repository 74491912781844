import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';

import { TranslationKeys } from 'app/translations';

import { OWNER, SUBOWNER } from 'app/common/Authorization/entities';
import { EDIT, VIEW } from 'app/common/Authorization/permissions';

import { RestrictAccess, useBreadcrumb } from 'app/components';

import { OwnerManagementPage } from './OwnerManagementPage';
import OwnerDetailPage from './OwnerDetailPage';
import OwnerAddPage from './OwnerAddPage';
import OwnerInformationTab from './OwnerDetailPage/OwnerInformationTab';
import OwnerSettingsPage from './OwnerSettingsPage';
import EmployeeTypesTab from './OwnerManagementPage/EmployeeTypesTab';
import { SubOwnerOverviewPage } from './SubOwnerOverviewPage';

export const OwnerPages = () => {
  const { pathname } = useLocation();
  const isSubOwnersPath = !!matchPath('/sub-owners/*', pathname);
  const breadcrumbTitle = isSubOwnersPath ? TranslationKeys.menu_subOwners : TranslationKeys.menu_owners;
  const breadcrumbData = isSubOwnersPath ? { customPathName: '/owners/sub-owners' } : undefined;

  useBreadcrumb(breadcrumbTitle, breadcrumbData);

  return (
    <Routes>
      <Route element={<RestrictAccess entity={OWNER} permission={EDIT} />}>
        <Route element={<OwnerAddPage />} path={'add'} />
        <Route element={<OwnerSettingsPage />} path={'settings'}>
          <Route path="employee-types" element={<EmployeeTypesTab />} />
          <Route index element={<Navigate to="employee-types" replace />} />
          <Route path="*" element={<Navigate to="employee-types" replace />} />
        </Route>
      </Route>

      <Route element={<RestrictAccess entity={OWNER} permission={VIEW} />}>
        <Route element={<OwnerManagementPage />} path={'overview'} />
        <Route index element={<Navigate to="overview" replace />} />
        <Route path="*" element={<Navigate to="overview" replace />} />
        <Route element={<OwnerDetailPage />} path={':ownerId/*'}>
          <Route path="info" element={<OwnerInformationTab />} />
          <Route index element={<Navigate to="info" replace />} />
          <Route path="*" element={<Navigate to="info" replace />} />
        </Route>
        <Route index element={<Navigate to="overview" replace />} />
        <Route path="*" element={<Navigate to="overview" replace />} />
      </Route>

      <Route element={<RestrictAccess entity={SUBOWNER} permission={VIEW} />}>
        <Route element={<SubOwnerOverviewPage />} path={'sub-owners'} />
      </Route>
    </Routes>
  );
};
