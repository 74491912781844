import React from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { StoreSelectors, useStoreSelector } from 'app/store';

export const EventFlowDownloadPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const downloadEventFlow = React.useCallback(
    async (encodedFilename: string) => {
      return axios.get(`/owners/${ownerId}/event-flow/${encodedFilename}`, {});
    },
    [ownerId],
  );

  const executeDownload = React.useCallback(async () => {
    const response = await downloadEventFlow(params?.encodedFilename ?? '');

    if (response.status === 200) {
      const element = document.createElement('a');
      element.href = response.data;

      document.body.appendChild(element);
      element.click();
    }
  }, [downloadEventFlow, params?.encodedFilename]);

  React.useEffect(() => {
    executeDownload().then(() => {
      navigate('/');
    });
    // eslint-disable-next-line
  }, []);

  return null;
};
