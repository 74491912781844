import React from 'react';
import { Formik } from 'formik';

import { TranslationKeys } from 'app/translations';

import { StoreSelectors, useStoreSelector } from 'app/store';

import { useBreadcrumb } from 'app/components';

import { useInsurerGetInsurersQuery, useOwnerGetOwnerInsurersQuery } from 'app/hooks';

import { RateTableForm, useRateTableForm } from 'app/Domain/Settings/Components';
import type { RateTableFormValues } from 'app/Domain/Settings/Components/RateTableForm/RateTableForm.types';

import { SettingsRateTableCreateToolbar } from './components';

export const SettingsRateTableCreatePage = () => {
  const { isSubmitting, formikInitialValues, formikValidationSchema, formikOnSubmitCreate } = useRateTableForm();

  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const {
    data: insurersData,
    isLoading: isLoadingGetInsurersQuery,
    isFetching: isFetchingGetInsurersQuery,
  } = useInsurerGetInsurersQuery({
    variables: { page: 1, pageSize: 999 },
  });

  const {
    data: ownerInsurersData,
    isLoading: isLoadingGetOwnerInsurersQuery,
    isFetching: isFetchingGetOwnerInsurersQuery,
  } = useOwnerGetOwnerInsurersQuery({
    variables: { ownerId, page: 1, pageSize: 999 },
    options: {
      enabled: !!ownerId,
    },
  });

  const insurers = React.useMemo(() => {
    const ownerInsurers = (ownerInsurersData?.data || []).map(insurer => insurer.insurerId);

    return (insurersData?.data || []).filter(insurer => ownerInsurers.includes(insurer.insurerId));
  }, [insurersData?.data, ownerInsurersData?.data]);

  const isLoading =
    isLoadingGetInsurersQuery ||
    isFetchingGetInsurersQuery ||
    (!!ownerId && isLoadingGetOwnerInsurersQuery) ||
    isFetchingGetOwnerInsurersQuery ||
    isSubmitting;

  useBreadcrumb(TranslationKeys.menu_settings_rateTables, {
    hideTrail: true,
    hideTitle: true,
    otherElements: <SettingsRateTableCreateToolbar isLoading={isLoading} />,
  });

  return (
    <div>
      <Formik<RateTableFormValues>
        initialValues={formikInitialValues}
        validationSchema={formikValidationSchema}
        onSubmit={formikOnSubmitCreate}
      >
        <RateTableForm isLoading={isLoading} insurers={insurers} />
      </Formik>
    </div>
  );
};
