import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormikModal } from 'app/components';
import EditInsurerOwnerForm from './EditInsurerOwnerForm';
import { FormattedMessage } from 'react-intl';

const EditInsurerOwnerFormModal = ({ insurerOwner, insurer, insurerContacts, open, onSubmit, onHide }) => {
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      agencyNumber: Yup.string().trim().strict().required(),
      contacts: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string()
              .trim()
              .required(<FormattedMessage id="validationMessage.mixed.required" />),
            phone: Yup.string().trim(),
            email: Yup.string().trim().email(),
          }),
        )
        .max(5, 'Maximum of 5 contacts'),
    });
  }, []);

  const initialValues = useMemo(() => {
    return {
      insurerId: insurer?.insurerId,
      insurerName: insurer?.insurerName,
      insurerEmail: insurer?.insurerEmail,
      insurerPhone: insurer?.insurerPhone,
      address: insurer?.address,
      zipCode: insurer?.zipCode,
      city: insurer?.city,
      products: insurer?.products,
      portalLinks: insurer?.portalLinks,
      contacts: insurerContacts || [],
      agencyNumber: insurerOwner?.agencyNumber,
    };
  }, [insurerOwner, insurer, insurerContacts]);

  const submit = useCallback(
    (values, form) => {
      onSubmit && onSubmit(values, form);
    },
    [onSubmit],
  );

  return (
    <>
      {!!insurerOwner && (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
          <FormikModal maxWidth="md" show={open} title={insurer?.insurerName} onHide={onHide}>
            <EditInsurerOwnerForm ownerInsurerId={insurerOwner.ownerInsurerId} />
          </FormikModal>
        </Formik>
      )}
    </>
  );
};

export default EditInsurerOwnerFormModal;
