import React from 'react';
import { useIntl } from 'react-intl';
import type { FormikConfig } from 'formik';
import * as Yup from 'yup';

import type { Owner } from '../../../../types/owner.types';

import { convertFileToBase64, normalizeError } from '../../../../utils';
import { Uuid } from '../../../../utils/uuid';

import { useSnakeBar } from '../../../../hooks/useSnakeBar';

import { TranslationKeys } from '../../../../translations';

import OwnerService from '../../Services/OwnerService';

import type { SubOwnerFormValues } from './SubOwnerForm.types';

type UseSubOwnerFormArgs = {
  subOwner?: Owner;
  owner?: Owner;
  owners: Array<Owner> | undefined;
  afterSubmit?: () => void;
};

export const useSubOwnerForm = ({ subOwner, owner, owners, afterSubmit }: UseSubOwnerFormArgs) => {
  const intl = useIntl();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const ownerData = React.useMemo(() => {
    if (owner?.ownerId) {
      return {
        '@id': owner.owner,
        ownerId: owner.ownerId,
      };
    }
    return null;
  }, [owner]);

  const formikInitialValues = React.useMemo<SubOwnerFormValues>(() => {
    return {
      ownerId: subOwner?.ownerId || Uuid.newV4(),
      parentId: subOwner?.parentId || owner?.ownerId || '',
      ownerName: subOwner?.ownerId || '',
      ownerEmail: subOwner?.ownerEmail || '',
      ownerPhone: subOwner?.ownerPhone || '',
      address: subOwner?.address || '',
      zipCode: subOwner?.zipCode || '',
      city: subOwner?.city || '',
      contactName: subOwner?.contactName || '',
      contactEmail: subOwner?.contactEmail || '',
      contactPhone: subOwner?.contactPhone || '',
      logo: subOwner?.logo || '',
    };
  }, [
    owner?.ownerId,
    subOwner?.address,
    subOwner?.city,
    subOwner?.contactEmail,
    subOwner?.contactName,
    subOwner?.contactPhone,
    subOwner?.logo,
    subOwner?.ownerEmail,
    subOwner?.ownerId,
    subOwner?.ownerPhone,
    subOwner?.parentId,
    subOwner?.zipCode,
  ]);

  const formikValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        parentId: Yup.string().required(),
        ownerName: Yup.string().trim().min(2).max(50).required(),
        ownerEmail: Yup.string().trim().min(2).max(255).email().required(),
        ownerPhone: Yup.string().trim().min(2).max(50).required(),
        contactName: Yup.string().trim().min(2).max(50),
        contactEmail: Yup.string().trim().min(2).max(255).email(),
        contactPhone: Yup.string().trim().min(2).max(50),
        address: Yup.string().trim().max(255),
        zipCode: Yup.string().trim().max(255),
        city: Yup.string().trim().max(255),
      }),
    [],
  );

  const formikOnSubmit = React.useCallback<FormikConfig<SubOwnerFormValues>['onSubmit']>(
    async (values, { setSubmitting, resetForm }) => {
      const parent = ownerData ? ownerData : owners?.find((owner: Owner) => owner.ownerId === values.parentId)!;
      const valuesToSubmit = { ...values, parent: parent['@id'] };

      if (values.logo && typeof values.logo !== 'string') {
        valuesToSubmit.logo = (await convertFileToBase64(values.logo)) as string;
      }

      try {
        await OwnerService.createOwner(valuesToSubmit);
        resetForm();

        if (afterSubmit) {
          afterSubmit();
        }

        showSuccessSnakeBar({
          method: 'createOwner',
          message: intl.formatMessage({ id: TranslationKeys.subOwners_create_successMessage }),
        });
      } catch (error: any) {
        showErrorSnakeBar({
          method: 'createOwner',
          message: normalizeError(error).message,
        });
        setSubmitting(false);
      }
    },
    [afterSubmit, intl, ownerData, owners, showErrorSnakeBar, showSuccessSnakeBar],
  );

  return React.useMemo(
    () => ({
      formikValidationSchema,
      formikInitialValues,
      formikOnSubmit,
    }),
    [formikInitialValues, formikOnSubmit, formikValidationSchema],
  );
};
