import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Grid, Typography } from '@mui/material';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';

import { OWNER } from 'app/common/Authorization/entities';
import { EDIT } from 'app/common/Authorization/permissions';

import { ActionButton, HasAccessTo, Skeleton } from 'app/components';

import { DivRootStyled, SearchInputStyled } from './UsersToolbar.styles';

type UsersToolbarProps = {
  className?: string;
  handleClickAdd: () => void;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  initialSearchQuery?: string;
  totalCount?: number;
};

export const UsersToolbar = ({
  className,
  handleClickAdd,
  handleSearch,
  totalCount,
  initialSearchQuery,
}: UsersToolbarProps) => {
  const intl = useIntl();
  const disabledIfNoAccessToUserEdit = !HasAccessTo(OWNER, EDIT);

  return (
    <DivRootStyled className={className}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="h5">
            <FormattedMessage id={TranslationKeys.menu_users} />
          </Typography>
          <Typography variant="subtitle2" textTransform={'uppercase'}>
            {typeof totalCount !== 'undefined' ? (
              <FormattedMessage id={TranslationKeys.users_totalCount} values={{ totalCount }} />
            ) : (
              <Skeleton width={200} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SearchInputStyled
            onChange={handleSearch}
            placeholder={intl.formatMessage({ id: TranslationKeys.users_search }).toUpperCase()}
            initialSearchQuery={initialSearchQuery}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ActionButton
            messageId={TranslationKeys.users_add}
            icon={faPlus}
            onClick={handleClickAdd}
            disabled={disabledIfNoAccessToUserEdit}
          />
        </Grid>
      </Grid>
    </DivRootStyled>
  );
};
