import React from 'react';
import { XMLBuilder, XMLParser } from 'fast-xml-parser';
import { FormattedMessage, useIntl } from 'react-intl';

import { Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from '../../../../translations';

import { useBreadcrumb } from '../../../../components/Breadcrumbs';
import { FileType, FileUploadArea } from '../../../../components/FileUploadArea';
import { ActionLink } from '../../../../components/ActionLink';

import {
  DivContainerStyled,
  DivInputContainerStyled,
  DivListContainerStyled,
  LinearProgressStyled,
  ListItemStyled,
  ListStyled,
} from './UPAXmlSplitterPage.styles';

export const UPAXmlSplitterPage = () => {
  const intl = useIntl();

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [showNoResults, setShowNoResults] = React.useState(false);
  const [filesToDownload, setFilesToDownload] = React.useState<Array<{ filename: string; href: string }>>([]);

  const showResultingFiles = !isProcessing && !hasError && !showNoResults && filesToDownload.length > 0;

  const createFileFromXmlObjectAndSave = React.useCallback((xmlObject: Record<string, any>, filename: string) => {
    const builder = new XMLBuilder({ format: true, ignoreAttributes: false });
    const xml = builder.build(xmlObject);

    const blob = new Blob([xml], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);

    setFilesToDownload(prevFilesToDownload => {
      const newFilesToDownload = [...prevFilesToDownload];
      newFilesToDownload.push({
        filename,
        href: url,
      });
      return newFilesToDownload;
    });
  }, []);

  const readFile = React.useCallback(async (uploadedFile: File): Promise<string | ArrayBuffer | null | undefined> => {
    return await new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = loadEvent => {
        try {
          const payload = loadEvent.target?.result;
          resolve(payload);
        } catch (error) {
          reject(error);
        }
      };

      fileReader.onerror = () => {
        reject(new Error(`Error occurred reading file: ${uploadedFile.name}`));
      };

      fileReader.readAsText(uploadedFile, 'utf8');
    });
  }, []);

  const processUploadedFile = React.useCallback(
    async (file: File) => {
      let newFilesCount = 0;

      try {
        const fileName = file.name;
        const uploadedFileContent = await readFile(file);

        const parser = new XMLParser({ ignoreAttributes: false });
        const objectDataFromXml = parser.parse(uploadedFileContent as string);

        const generateFile = (
          inkomstenverhouding: Record<string, any>,
          filenamePrefix: 'Regulation1' | 'Regulation',
        ) => {
          const xmlObj = JSON.parse(JSON.stringify(objectDataFromXml));
          xmlObj.leveringSTP.administratieveEenheid.inkomstenverhouding = inkomstenverhouding;

          const translatedPrefix =
            filenamePrefix === 'Regulation1'
              ? intl.formatMessage({ id: TranslationKeys.tools_upaXmlSplitter_filename_regulation1 })
              : intl.formatMessage({ id: TranslationKeys.tools_upaXmlSplitter_filename_regulation });

          createFileFromXmlObjectAndSave(xmlObj, `${translatedPrefix}_${fileName}`);
          newFilesCount += 1;
        };

        const inkomstenverhouding: Array<Record<string, any>> =
          objectDataFromXml.leveringSTP?.administratieveEenheid?.inkomstenverhouding || [];

        if (inkomstenverhouding.length) {
          const premieWerknemer00: Array<Record<string, any>> = [];
          const otherPremieWerknemer: Array<Record<string, any>> = [];

          inkomstenverhouding.forEach(income => {
            const isPremieWerknemer00 = Array.isArray(income.inkomstenperiode)
              ? income.inkomstenperiode.every((incomePeriod: Record<string, any>) => incomePeriod.premieWerknemer === 0)
              : income.inkomstenperiode.premieWerknemer === 0;

            if (isPremieWerknemer00) {
              premieWerknemer00.push(income);
            } else {
              otherPremieWerknemer.push(income);
            }
          });

          if (premieWerknemer00.length) {
            generateFile(premieWerknemer00, 'Regulation1');
          }
          if (otherPremieWerknemer.length) {
            generateFile(otherPremieWerknemer, 'Regulation');
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setHasError(true);
      }

      return newFilesCount;
    },
    [createFileFromXmlObjectAndSave, intl, readFile],
  );

  const onFileChange = React.useCallback(
    async (files: Array<File>) => {
      setHasError(false);
      setShowNoResults(false);
      setFilesToDownload([]);

      const uploadedFile = files[0] ?? undefined;

      if (uploadedFile) {
        setIsProcessing(true);
        const count = await processUploadedFile(uploadedFile);
        setShowNoResults(count === 0);
      }

      setIsProcessing(false);
    },
    [processUploadedFile],
  );

  useBreadcrumb(TranslationKeys.menu_tools_upaXmlSplitter);

  return (
    <DivContainerStyled>
      <DivInputContainerStyled>
        <FileUploadArea
          types={[FileType.Xml]}
          onChange={onFileChange}
          initialFiles={[]}
          disabled={false}
          showSuccessMessage={false}
        />
      </DivInputContainerStyled>

      <DivListContainerStyled>
        {isProcessing && <LinearProgressStyled />}

        {showResultingFiles && (
          <>
            <Typography variant={'h5'}>
              <FormattedMessage id={TranslationKeys.tools_upaXmlSplitter_listHeaderText} />
            </Typography>

            <ListStyled>
              {filesToDownload.map((file, idx) => (
                <ListItemStyled key={idx}>
                  <ActionLink href={file.href} download={file.filename}>
                    <FontAwesomeIcon icon={faFileArrowDown} />
                    <Typography color={'primary'}>{file.filename}</Typography>
                  </ActionLink>
                </ListItemStyled>
              ))}
            </ListStyled>
          </>
        )}

        {!hasError && showNoResults && (
          <Typography variant={'body2'}>
            <FormattedMessage id={TranslationKeys.tools_upaXmlSplitter_noDataToSplitMsg} />
          </Typography>
        )}

        {hasError && (
          <Typography color={'error'} variant={'body2'}>
            <FormattedMessage id={TranslationKeys.tools_upaXmlSplitter_errorMsg} />
          </Typography>
        )}
      </DivListContainerStyled>
    </DivContainerStyled>
  );
};
