import React from 'react';
import { useIntl } from 'react-intl';

import { TranslationKeys } from '../translations';

import { ProductPensionSchemeEnum } from '../types';

export const useProductPensionSchemeSelectItems = () => {
  const intl = useIntl();

  const productPensionSchemeSelectItems = React.useMemo(() => {
    return [
      {
        value: ProductPensionSchemeEnum.Adequate,
        element: intl.formatMessage({ id: TranslationKeys.products_type_adequate }),
      },
      {
        value: ProductPensionSchemeEnum.Regular,
        element: intl.formatMessage({ id: TranslationKeys.products_type_regular }),
      },
    ];
  }, [intl]);

  const getProductSchemeDisplayNameByValue = React.useCallback(
    (value: ProductPensionSchemeEnum) => {
      return productPensionSchemeSelectItems.find(productPensionScheme => productPensionScheme.value === value)
        ?.element;
    },
    [productPensionSchemeSelectItems],
  );

  return React.useMemo(
    () => ({ productPensionSchemeSelectItems, getProductSchemeDisplayNameByValue }),
    [productPensionSchemeSelectItems, getProductSchemeDisplayNameByValue],
  );
};
