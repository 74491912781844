import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Typography } from '@mui/material';

export const DivContentStyled = styled('div', {
  name: 'DivContentStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const CardStyled = styled(Card, {
  name: 'CardStyled',
})(({ theme }) => ({
  borderRadius: '10px',
  height: '250px',
  position: 'relative',
  '&:hover': {
    border: '1px solid ' + theme.palette.primary.main,
  },
}));

export const CardContentStyled = styled(CardContent, {
  name: 'CardContentStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  height: '100%',
  textAlign: 'center',
  padding: '26px 20px 35px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const TypographyCardTitleStyled = styled(Typography, {
  name: 'TypographyCardTitleStyled',
})(({ theme }) => ({
  color: theme.palette.primary.dark,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const TypographyCardDescriptionStyled = styled(Typography, {
  name: 'TypographyCardDescriptionStyled',
})(({ theme }) => ({
  color: theme.palette.primary.dark,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const ButtonStyled = styled(Button, {
  name: 'ButtonStyled',
})(({ theme }) => ({
  marginBottom: '20px',
  borderWidth: '2px',
  backgroundColor: theme.palette.primary.light,
  borderRadius: '17.5px',
  width: '110px',
  alignSelf: 'center',
}));
