import switchBaseClasses from '@mui/material/internal/switchBaseClasses';
import { switchClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiSwitch: FuncOverrideComponent<'MuiSwitch'> = theme => ({
  styleOverrides: {
    root: {
      fontFamily: theme.typography.fontFamily,
      textTransform: theme.typography.caption.textTransform,
      color: theme.palette.common.white,
      fontSize: '0.625rem',
      width: '58px',
      height: '33px',
      padding: '8px 12px 8px 0',
    },
    thumb: {
      width: '15px',
      height: '15px',
      backgroundColor: theme.palette.common.white,
    },
    disabled: {},
    checked: {},
    track: {
      borderRadius: '8px',
    },
    switchBase: {
      padding: '2px',
      transform: 'translate(0px, 7px)',
      [`&.${switchBaseClasses.checked}`]: {
        transform: 'translate(28px, 7px)',
      },
    },
    colorPrimary: {
      [`&.${switchClasses.checked} + .${switchClasses.track}`]: {
        opacity: 1,
      },
      [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
        opacity: 0.12,
      },
    },
    sizeSmall: {
      width: '58px',
      height: '24px',
      padding: '3px 12px 3px 0',
      [`& .${switchClasses.switchBase}`]: {
        padding: '2px',
        transform: 'translate(-1px, 2px)',
        [`&.${switchClasses.checked}`]: {
          transform: 'translate(27px, 2px)',
        },
      },
    },
  },
});
