import { styled } from '@mui/material/styles';
import { Input, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const PaperStyled = styled(Paper, {
  name: 'PaperStyled',
})(({ theme }) => ({
  alignItems: 'center',
  border: '1px solid ' + theme.palette.secondary.main,
  borderRadius: '20px',
  display: 'flex',
  flexBasis: 420,
  padding: theme.spacing(0.5),
}));

export const LeftSearchIconStyled = styled(SearchIcon, {
  name: 'LeftSearchIconStyled',
})(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
}));

export const InputStyled = styled(Input, {
  name: 'InputStyled',
})(() => ({
  flexGrow: 1,
  fontSize: '12px',
  lineHeight: '40px',
  letterSpacing: '0.5px',
}));

export const RightSearchIconStyled = styled(SearchIcon, {
  name: 'RightSearchIconStyled',
})(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(1),
}));
