import type { PaletteOptions } from '@mui/material/styles/createPalette';
import { createTheme } from '@mui/material';

import typography from './typography';
import overrides from './overrides';

const theme = (palette: PaletteOptions) => {
  const firstPassTheme = createTheme({
    palette,
  });

  const secondPassTheme = createTheme({
    ...firstPassTheme,
    typography: typography(firstPassTheme.palette),
  });

  return createTheme({
    ...secondPassTheme,
    components: {
      ...overrides(secondPassTheme),
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
};

export default theme;
