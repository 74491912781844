import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import type { StatusBulletProps } from './StatusBullet';

const colors = (theme: Theme, color: StatusBulletProps['color']) => {
  if (color === 'neutral') {
    return { backgroundColor: theme.palette.secondary.dark };
  }
  if (color === 'primary') {
    return { backgroundColor: theme.palette.primary.main };
  }
  if (color === 'info') {
    return { backgroundColor: theme.palette.info.main };
  }
  if (color === 'warning') {
    return { backgroundColor: theme.palette.warning.main };
  }
  if (color === 'danger') {
    return { backgroundColor: theme.palette.error.main };
  }
  if (color === 'success') {
    return { backgroundColor: theme.palette.success.main };
  }

  return {};
};

const sizes = (theme: Theme, size: StatusBulletProps['size']) => {
  if (size === 'sm') {
    return {
      height: theme.spacing(1),
      width: theme.spacing(1),
    };
  }
  if (size === 'md') {
    return {
      height: theme.spacing(2),
      width: theme.spacing(2),
    };
  }
  if (size === 'lg') {
    return {
      height: theme.spacing(3),
      width: theme.spacing(3),
    };
  }

  return {};
};

export const SpanStyled = styled('span', {
  name: 'SpanStyled',
  shouldForwardProp: (propName: string) => !['color', 'size'].includes(propName),
})<{ color: StatusBulletProps['color']; size: StatusBulletProps['size'] }>(({ theme, color, size }) => ({
  display: 'inline-block',
  borderRadius: '50%',
  flexGrow: 0,
  flexShrink: 0,
  ...colors(theme, color),
  ...sizes(theme, size),
}));
