import English from '../../translations/en.json';
import Dutch from '../../translations/nl.json';
import { enGB, nl } from 'date-fns/locale';
import type { Locale } from 'date-fns';
import type { Dictionary } from '../../utils';
import type { YupLocaleObject } from './validationMessages';
import yupValidationMessages from './validationMessages';

export interface LocaleOption {
  locale: Locale;
  messages: Dictionary<string>;
  name: string;
  validationMessages: YupLocaleObject;
}

const defaultLocale = 'nl';

const enabledLocales: Dictionary<LocaleOption> = {
  en: {
    locale: enGB,
    messages: English,
    name: 'English',
    validationMessages: yupValidationMessages(English),
  },
  nl: {
    locale: nl,
    messages: Dutch,
    name: 'Dutch',
    validationMessages: yupValidationMessages(Dutch),
  },
};

export { enabledLocales, defaultLocale };
