import type { Breadcrumb, BreadcrumbState } from './Breadcrumb.types';

export type BreadcrumbAction =
  | {
      type: 'SET';
      id: string;
      crumb: Breadcrumb;
    }
  | {
      type: 'DEL';
      id: string;
    };

function buildState(crumbsById: Record<string, Breadcrumb>) {
  return {
    crumbsById,
    crumbTrail: Object.values(crumbsById)
      // The path of each breadcrumb will include the path of the crumbs in the parent components, so sorting by path length will guarantee that the breadcrumbs are in the correct order,
      // reflecting the nested hierarchy. This approach contrasts with just using the order of invocation, for example, which could give incorrect results if there is a re-render part-way
      // up the hierarchy, where it decides not to re-render a child component.
      .sort((a, b) => a.to.pathname.length - b.to.pathname.length),
  };
}

export function breadcrumbReducer(state: BreadcrumbState, action: BreadcrumbAction): BreadcrumbState {
  switch (action.type) {
    case 'DEL': {
      // eslint-disable-next-line
      const { [action.id]: _, ...crumbsById } = state.crumbsById;

      return buildState(crumbsById);
    }

    case 'SET': {
      return buildState({
        ...state.crumbsById,
        [action.id]: action.crumb,
      });
    }

    default:
      return state;
  }
}
