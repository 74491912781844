import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as CredentialActions } from '../../Ducks/Credentials.duck';
import { FormattedMessage } from 'react-intl';
import { DivRootStyled } from './OauthCredentialResponsePage.styles';

const OauthCredentialResponsePage = () => {
  const dispatch = useDispatch();
  const { credentialId, status } = useParams();

  useEffect(() => {
    dispatch(CredentialActions.oauthStatus({ [credentialId]: status }));
  }, [dispatch, credentialId, status]);

  return (
    <DivRootStyled>
      <FormattedMessage id={`credential.oauth.${status}`} />
    </DivRootStyled>
  );
};

export default OauthCredentialResponsePage;
