import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { processesService } from '../Services/ProcessesService';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from 'app/common/constants';

export const actionTypes = {
  RequestData: '[Processes] Request',
  FulfilledTable: '[Processes] Fulfilled',
  ChangePage: '[Processes] change page',
  SetPageSize: '[Processes] set page size',
  SortTable: '[Processes] Sort',
  ApplyFilter: '[Processes] Apply filter',
  RemoveFilter: '[Processes] Remove filter',
  SetConnectionId: '[Processes] Set connection',
  RetryProcess: '[Processes] Retry process',
  ApproveProcess: '[Processes] Approve process',
  DeclineProcess: '[Processes] Decline process',
  ForceSendProcess: '[Processes] Force send process',
  ForceDeclineProcess: '[Processes] Force decline process',
  ResetFilters: '[Processes] reset filters',
};

const initialState = {
  ...tableInitialState,
  connectionId: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.FulfilledTable: {
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.items.map(item => {
          item.selected = false;
          return item;
        }),
        totalCount: action.totalCount,
      };
    }
    case actionTypes.SetConnectionId: {
      return {
        ...state,
        connectionId: action.connectionId,
      };
    }
    case actionTypes.ResetFilters: {
      return {
        ...state,
        filters: { ...initialState.filters },
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  resetFilters: () => ({
    type: actionTypes.ResetFilters,
  }),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
  retryProcess: (connectionId, process) => ({
    type: actionTypes.RetryProcess,
    connectionId,
    process,
  }),
  approveProcess: (connectionId, process) => ({
    type: actionTypes.ApproveProcess,
    connectionId,
    process,
  }),
  declineProcess: (connectionId, process) => ({
    type: actionTypes.DeclineProcess,
    connectionId,
    process,
  }),
  forceSendProcess: (connectionId, process, withFailed) => ({
    type: actionTypes.ForceSendProcess,
    connectionId,
    process,
    withFailed,
  }),
  forceDeclineProcess: (connectionId, process) => ({
    type: actionTypes.ForceDeclineProcess,
    connectionId,
    process,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  function* retryProcess(action) {
    yield processesService.retryProcessRequest({
      connectionId: action.connectionId,
      batchId: action.process.batchId,
    });

    yield put(actions.requestData());
  }

  function* approveProcess(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield processesService.approveProcessRequest({
        connectionId: action.connectionId,
        batchId: action.process.batchId,
      });
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      method: 'approveProcess',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }

  function* declineProcess(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield processesService.declineProcessRequest({
        connectionId: action.connectionId,
        batchId: action.process.batchId,
      });
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      method: 'declineProcess',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }

  function* forceSendProcess(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield processesService.forceSendProcessRequest({
        connectionId: action.connectionId,
        batchId: action.process.batchId,
        withFailed: action.withFailed,
      });
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      method: 'forceSendProcess',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }

  function* forceDeclineProcess(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield processesService.forceDeclineProcessRequest({
        connectionId: action.connectionId,
        batchId: action.process.batchId,
      });
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      method: 'forceDeclineProcess',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }

  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestProcessesSaga() {
    try {
      const currentState = yield select(state => {
        return state.ProcessesReducer;
      });

      const response = yield processesService.getProcesses({
        connectionId: currentState.connectionId,
        page: currentState.page,
        perPage: currentState.pageSize,
        sortBy: currentState.sortBy,
        filters: currentState.filters,
        searchQuery: currentState.searchQuery,
      });

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getBatches',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RetryProcess, retryProcess);
  yield takeLatest(actionTypes.ApproveProcess, approveProcess);
  yield takeLatest(actionTypes.DeclineProcess, declineProcess);
  yield takeLatest(actionTypes.ForceSendProcess, forceSendProcess);
  yield takeLatest(actionTypes.ForceDeclineProcess, forceDeclineProcess);
}
