import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { UseQueryWrapper } from '../../../types/useQuery.types';

import type { GetReportDataFilterForSuperAdminParams } from '../../../Domain/Reports/Services/ReportService';
import { reportService } from '../../../Domain/Reports/Services/ReportService';

import { useSnakeBar } from '../../useSnakeBar';
import type { Report } from '../../../types/report.types';

type GetReportDataFilterForSuperAdminQueryResultData = Report;

type GetReportDataFilterForSuperAdminQueryVariables = {
  [Key in keyof GetReportDataFilterForSuperAdminParams]: GetReportDataFilterForSuperAdminParams[Key] | undefined;
};

const queryFn =
  ({ ownerId }: GetReportDataFilterForSuperAdminQueryVariables) =>
  async () => {
    if (ownerId) {
      const response = await reportService.getReportDataFilterForSuperAdmin({ ownerId });
      return (response as AxiosResponse).data;
    }
  };

const QUERY_KEY = 'getReportDataFilterForSuperAdmin';

export const useReportsGetDataFilterForSuperAdminQuery: UseQueryWrapper<
  GetReportDataFilterForSuperAdminQueryResultData,
  GetReportDataFilterForSuperAdminQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetReportDataFilterForSuperAdminQueryResultData>([QUERY_KEY], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
