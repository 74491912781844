import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { Employer, UseQueryWrapper } from 'app/types';

import { useSnakeBar } from 'app/hooks/useSnakeBar';
import { employerService } from 'app/Domain/Employers/Services/EmployerService';

export type GetEmployerQueryResultData = Employer;

export type GetEmployerQueryVariables = {
  employerId: Employer['employerId'] | undefined;
};

const queryFn =
  ({ employerId }: GetEmployerQueryVariables) =>
  async () => {
    if (employerId) {
      const response = await employerService.getEmployer(employerId);
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployer';

export const useEmployerGetEmployerQuery: UseQueryWrapper<GetEmployerQueryResultData, GetEmployerQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetEmployerQueryResultData>([QUERY_KEY, variables.employerId], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
