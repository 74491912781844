import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { useFormikContext } from 'formik';
import { getIn } from 'formik';

import { FormLabel } from '@mui/material';

import { debounce, isNil, parseDate, serializeDate } from 'app/utils';

import { IntlMessage } from 'app/components/Intl';

import { useFieldValidator } from '../Factory';
import { DatePicker as BaseField } from '../base';

import { FieldStyled } from './DatePicker.styles';
import type { DatePickerProps } from './DatePicker';

type InternalDatePickerFieldProps = DatePickerProps & {
  formik: ReturnType<typeof useFormikContext>;
};

const InternalDatePicker = ({
  onChange,
  field,
  variant = 'default',
  formik,
  className,
  debounceTime = 200,
}: InternalDatePickerFieldProps) => {
  const {
    label,
    options,
    name,
    value,
    defaultValue,
    header,
    type = 'date',
    required = false,
    loading = false,
    disabled = false,
    disableOnChange = false,
    fullWidth = true,
    ...rest
  } = field || {};

  // should be ignored!
  delete rest.schema;

  const { initialValues, setFieldValue, setFieldTouched, isSubmitting, values: formikValues } = formik;
  const formikValue = getIn(formikValues, name);
  const { startOfDay, endOfDay, localDate = true } = options || {};

  const actualDefaultValue = useMemo(() => {
    const initialValue = getIn(initialValues, name);
    return isNil(initialValue) ? (isNil(defaultValue) ? '' : defaultValue) : initialValue;
  }, [defaultValue, initialValues, name]);

  const actualValue = useMemo(() => {
    let selectedValue = isNil(value) ? actualDefaultValue : value ?? '';
    if (!isNil(formikValue)) {
      selectedValue = formikValue;
    }
    if (typeof selectedValue === 'string' && selectedValue !== '') {
      return parseDate(selectedValue, localDate);
    }

    return !selectedValue ? null : new Date(selectedValue);
  }, [value, actualDefaultValue, formikValue, localDate]);

  const actualDisabled = loading || disabled || isSubmitting;

  const [currentValue, setCurrentValue] = useState(actualValue);
  const [fieldDisabled, setFieldDisabled] = useState(actualDisabled);
  const validator = useFieldValidator('date');

  const debouncedFormikSetFieldValue = React.useMemo(() => {
    return debounce(setFieldValue, debounceTime);
  }, [setFieldValue, debounceTime]);

  const debouncedOnChange = React.useMemo(() => {
    if (onChange) {
      return debounce(onChange, debounceTime);
    }

    return null;
  }, [onChange, debounceTime]);

  React.useEffect(() => {
    return () => {
      debouncedFormikSetFieldValue.cancel();
      debouncedOnChange && debouncedOnChange.cancel();
    };
  }, [debouncedOnChange, debouncedFormikSetFieldValue]);

  useEffect(() => {
    setCurrentValue(actualValue);
  }, [actualValue]);

  useEffect(() => {
    setFieldDisabled(actualDisabled);
  }, [actualDisabled]);

  const change = useCallback(
    async (newValue, keyboardInputValue) => {
      if (disableOnChange) {
        setFieldDisabled(true);
      }
      setCurrentValue(newValue);

      // null triggers the optional/required check while empty string triggers the wrong format check
      const fallbackValue = isNil(keyboardInputValue) ? null : '';
      const serializedDate = serializeDate(newValue, localDate, startOfDay, endOfDay) ?? fallbackValue;

      // needs to be performed on this order!
      setFieldTouched(name, true, false);
      await debouncedFormikSetFieldValue(name, serializedDate, true);
      debouncedOnChange && debouncedOnChange({ name, value: serializedDate, setFieldValue });
    },
    [
      disableOnChange,
      localDate,
      startOfDay,
      endOfDay,
      setFieldTouched,
      name,
      debouncedFormikSetFieldValue,
      debouncedOnChange,
      setFieldValue,
    ],
  );

  const textFieldProps = useMemo(() => {
    return {
      fullWidth: fullWidth,
      required: required,
    };
  }, [fullWidth, required]);

  const size = variant === 'slim' ? 'small' : undefined;

  const inputLabelProps = useMemo(() => {
    return {
      size,
    };
  }, [size]);

  const inputProps = useMemo(() => {
    return {
      size,
    };
  }, [size]);

  const validate = useCallback(
    value => {
      return validator(value, field);
    },
    [field, validator],
  );

  return (
    <div className={className}>
      {header && (
        <FormLabel>
          <IntlMessage value={header} /> {required ? ' *' : ''}
        </FormLabel>
      )}
      <FieldStyled
        slim={variant === 'slim'}
        disabled={fieldDisabled}
        component={BaseField}
        name={name}
        label={label}
        value={currentValue ?? null}
        onChange={change}
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        textField={textFieldProps}
        type={type}
        loading={loading}
        validate={validate}
        {...rest}
      />
    </div>
  );
};

export default InternalDatePicker;
