import type { FuncOverrideComponent } from '../theme.types';

export const MuiFormControl: FuncOverrideComponent<'MuiFormControl'> = () => ({
  styleOverrides: {
    marginDense: {
      margin: 0,
      marginTop: 0,
      marginBottom: 0,
    },
  },
});
