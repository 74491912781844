import { styled } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { dialogActionsClasses, dialogTitleClasses } from '@mui/material';

export const MuiDialogStyled = styled(MuiDialog, {
  name: 'MuiDialogStyled',
})(({ theme }) => ({
  [`& .${dialogTitleClasses.root}`]: {
    ...theme.typography.h5,
    lineHeight: 'initial',
    padding: theme.spacing(2),
  },
  [`& .${dialogActionsClasses.root}`]: {
    padding: theme.spacing(2),
  },
}));

export const CloseIconStyled = styled(CloseIcon, {
  name: 'CloseIconStyled',
})(({ theme }) => ({
  marginRight: '10px',
  cursor: 'pointer',
  color: theme.palette.secondary.main,
  '&:hover': {
    color: theme.palette.secondary.dark,
  },
}));
