import type { IntlShape } from 'react-intl/src/types';

import { EmployeeListFileReadException } from './EmployeeListFileReadException';

import { isNil } from '../../../../../utils';
import { bsnValidator } from '../../../../../utils/bsnValidator';
import { TranslationKeys } from '../../../../../translations';

import type { ListFileStrategyInterface } from '../../../../../components/FileUploadAreaWithTemplate/ListFileStrategyInterface';

export class EmployeeListFileStrategy implements ListFileStrategyInterface {
  constructor(private readonly intl: IntlShape) {}

  public execute = (data: Array<any>): Array<any> => {
    return data.filter((row: any, index: number) => {
      if (index === 0) {
        return false; // skip headers
      }

      return (
        Object.keys(row).filter(key => {
          if (row.length < 3) {
            throw new EmployeeListFileReadException(TranslationKeys.onboarding_list_upload_error_missingColumns);
          }

          let value = row[key];
          if (isNil(value)) {
            return false;
          }
          value = value + '';

          const isBSNRow = typeof key === 'string' ? key === '1' : key === 1;

          if (isBSNRow) {
            const BSN = row[key];

            if (!bsnValidator({ value: BSN })) {
              throw new EmployeeListFileReadException(
                this.intl.formatMessage(
                  { id: TranslationKeys.onboarding_list_upload_error_invalidBSN },
                  { index: index + 1 },
                ),
              );
            }
          }

          return value.trim() !== '';
        }).length > 0
      );
    });
  };
}
