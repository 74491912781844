import React from 'react';
import { Form, useFormikContext } from 'formik';

import type { Dictionary } from 'app/utils';

import InputFieldFactory from '../../../../../components/FormikField/Factory/InputFieldFactory';

import { RenderFieldFactory } from '../../../../../components/FormikField';

import type {
  EmployerRegulationsScalesTableColumn,
  MaximumFiscalPercentagesSelectItem,
  RegulationsEmployerScalesFormValues,
} from '../RegulationsEmployerScales.types';

import {
  ActionButtonStyled,
  DivButtonsContainerStyled,
  DivTableContainerStyled,
} from './RegulationsEmployerScalesTable.styles';
import { IntlMessage } from '../../../../../components/Intl';
import { FilterableTable, HasAccessTo } from '../../../../../components';
import type { Field } from '../../../../../types';
import { EMPLOYER } from '../../../../../common/Authorization/entities';
import { EDIT } from '../../../../../common/Authorization/permissions';

enum RowTypeEnum {
  BasicPensionRegulations = 'basicPensionScheme',
  wtp = 'wtp',
  MaximumFiscalPercentage = 'maximumFiscalPercentage',
  Diff = 'diff',
}

type RowData = {
  data: {
    type: RowTypeEnum;
    enabled?: boolean;
  };
};

type RegulationsEmployerScalesTableProps = {
  readonly: boolean;
  toggleReadonly: () => void;
  loading: boolean;
  year: number;
  ages: Array<string>;
  columns: Array<Array<EmployerRegulationsScalesTableColumn>>;
  maximumFiscalPercentagesSelectItems: Array<MaximumFiscalPercentagesSelectItem>;
};

const percentageFieldFormat = {
  type: 'number',
  options: { style: 'percent', minimumFractionDigits: 2 },
};

export const RegulationsEmployerScalesTable = ({
  readonly,
  toggleReadonly,
  loading,
  year,
  ages,
  columns,
  maximumFiscalPercentagesSelectItems,
}: RegulationsEmployerScalesTableProps) => {
  const { values, setValues, resetForm, isSubmitting, submitForm, isValid } =
    useFormikContext<RegulationsEmployerScalesFormValues>();

  const onClickCancelButton = React.useCallback(() => {
    toggleReadonly();
    resetForm();
  }, [resetForm, toggleReadonly]);

  const rows = React.useMemo<Array<RowData>>(
    () =>
      [
        {
          data: {
            type: RowTypeEnum.BasicPensionRegulations,
          },
        },
        {
          data: {
            type: RowTypeEnum.wtp,
            enabled: year > 2023,
          },
        },
        {
          data: {
            type: RowTypeEnum.MaximumFiscalPercentage,
          },
        },
        {
          data: {
            type: RowTypeEnum.Diff,
          },
        },
      ].filter(row => row.data.enabled || row.data.enabled === undefined),
    [year],
  );

  const onChangeSelectValue = React.useCallback(
    ({ value }) => {
      const newInputValues = ages.reduce<Dictionary<number>>((acc, age) => ({ ...acc, [age]: 0 }), {});
      setValues(
        {
          selectedOption: value,
          wtp: values.wtp,
          basicPensionScheme: values.basicPensionScheme,
          ...newInputValues,
        },
        true,
      );
    },
    [ages, setValues, values.basicPensionScheme, values.wtp],
  );

  const MaximumFiscalPercentagesSelect = React.useMemo(() => {
    if (readonly) {
      // The element is already translated
      return values.selectedOption?.element;
    }

    return (
      <InputFieldFactory
        onChange={onChangeSelectValue}
        field={{
          type: 'select',
          name: 'selectedOption',
          items: maximumFiscalPercentagesSelectItems,
        }}
      />
    );
  }, [readonly, maximumFiscalPercentagesSelectItems, onChangeSelectValue, values.selectedOption?.element]);

  const getBasicPensionRegulationsRowValues = React.useCallback(
    () =>
      ages.reduce<Dictionary<React.ReactElement>>((acc, age) => {
        const key = `${RowTypeEnum.BasicPensionRegulations}.${age}`;

        const value = values.basicPensionScheme[age as keyof typeof values];
        const maxValue = values.selectedOption?.values?.agePercentages[age];

        const field = {
          type: 'number',
          name: key,
          fullWidth: false,
          schema: (schema: any) => schema.max(maxValue),
          options: {
            style: 'percent',
            size: 'small',
            textAlign: 'right',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            min: 0.0,
          },
        } as Field;

        if (readonly) {
          field.value = value;
          acc[age] = <RenderFieldFactory key={`${key}-readonly`} field={field} />;
        } else {
          field.defaultValue = value;
          acc[age] = <InputFieldFactory key={key} field={field} hideErrorText={true} />;
        }

        return acc;
      }, {}),
    [ages, readonly, values],
  );

  const getWTPRowValues = React.useCallback(
    () =>
      ages.reduce<Dictionary<React.ReactElement>>((acc, age) => {
        const key = `${RowTypeEnum.wtp}.${age}`;

        const field = {
          type: 'number',
          name: key,
          fullWidth: false,
          options: {
            style: 'percent',
            size: 'small',
            textAlign: 'right',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            min: 0.0,
          },
        } as Field;
        const value = values.wtp[age as keyof typeof values];

        if (readonly) {
          field.value = value;
          acc[age] = <RenderFieldFactory key={`${key}-readonly`} field={field} />;
        } else {
          field.defaultValue = value;
          acc[age] = <InputFieldFactory key={key} field={field} hideErrorText={true} />;
        }

        return acc;
      }, {}),
    [ages, readonly, values],
  );

  const getMaximumFiscalPercentageRowValues = React.useCallback(() => {
    const rowValues = values.selectedOption?.values?.agePercentages || {};
    return ages.reduce<Dictionary<React.ReactElement>>((acc, age) => {
      const value = rowValues[age] ?? 0;
      acc[age] = (
        <RenderFieldFactory
          key={`${RowTypeEnum.MaximumFiscalPercentage}-${age}`}
          field={{ value, name: age, ...percentageFieldFormat }}
        />
      );
      return acc;
    }, {});
  }, [ages, values.selectedOption?.values?.agePercentages]);

  const getDiffRowValues = React.useCallback(() => {
    return ages.reduce<Dictionary<React.ReactElement>>((acc, age) => {
      const maxValue = values.selectedOption?.values?.agePercentages[age] ?? 0;
      const savingsCalculation =
        values.wtp[age as keyof typeof values] < 3
          ? maxValue - values.basicPensionScheme[age as keyof typeof values]
          : maxValue -
            (values.wtp[age as keyof typeof values] - 3 + values.basicPensionScheme[age as keyof typeof values]);
      const value = Math.max(0, savingsCalculation);
      acc[age] = (
        <RenderFieldFactory key={`${RowTypeEnum.Diff}-${age}`} field={{ value, name: age, ...percentageFieldFormat }} />
      );

      return acc;
    }, {});
  }, [ages, values]);

  const rowMapper = React.useCallback(
    (row: RowData) => {
      const type = row.data.type;

      if (type === RowTypeEnum.BasicPensionRegulations) {
        return {
          data: {
            label: <IntlMessage value="summaryOfRegulations.scales.basicPensionRegulations" />,
            ...getBasicPensionRegulationsRowValues(),
          },
        };
      }

      if (type === RowTypeEnum.wtp) {
        return {
          data: {
            label: <IntlMessage value="summaryOfRegulations.scales.wtp" />,
            ...getWTPRowValues(),
          },
        };
      }
      if (type === RowTypeEnum.MaximumFiscalPercentage) {
        return {
          data: {
            label: readonly ? (
              <IntlMessage value={MaximumFiscalPercentagesSelect.toString()} />
            ) : (
              MaximumFiscalPercentagesSelect
            ),
            ...getMaximumFiscalPercentageRowValues(),
          },
        };
      }

      return {
        data: {
          label: <IntlMessage value="summaryOfRegulations.scales.difference" />,
          ...getDiffRowValues(),
        },
      };
    },
    [
      getDiffRowValues,
      getBasicPensionRegulationsRowValues,
      getWTPRowValues,
      readonly,
      MaximumFiscalPercentagesSelect,
      getMaximumFiscalPercentageRowValues,
    ],
  );

  const disabledIfNoAccessToEdit = HasAccessTo(EMPLOYER, EDIT);

  return (
    <Form>
      <DivTableContainerStyled>
        <FilterableTable
          columns={columns[1]}
          loading={loading}
          rows={rows}
          rowMapper={rowMapper}
          defaultPageSize={3}
          pagination={{ enabled: false }}
        />
      </DivTableContainerStyled>
      <DivButtonsContainerStyled>
        {readonly && disabledIfNoAccessToEdit && (
          <ActionButtonStyled onClick={toggleReadonly} messageId={'button.edit'} />
        )}
        {!readonly && (
          <>
            <ActionButtonStyled
              onClick={onClickCancelButton}
              messageId={'button.cancel'}
              disabled={isSubmitting}
              variant={'outlined'}
            />
            <ActionButtonStyled onClick={submitForm} messageId={'button.save'} disabled={isSubmitting || !isValid} />
          </>
        )}
      </DivButtonsContainerStyled>
    </Form>
  );
};
