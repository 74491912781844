import axios from 'axios';

let getUsersToken;
export const getFiles = async (connectionId, page, perPage, sortBy, filters, globalFilter, path) => {
  try {
    if (typeof getUsersToken !== typeof undefined) {
      getUsersToken.cancel('Operation canceled due to new request.');
    }

    getUsersToken = axios.CancelToken.source();

    return axios.get('downloads', {
      cancelToken: getUsersToken.token,
      params: {
        connectionId,
        page,
        perPage,
        user: globalFilter,
        path,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const downloadFile = async filePath => {
  return axios.post('/downloads/', {
    filePath,
  });
};

export const deleteFile = async (connectionId, filePath) => {
  if (typeof getUsersToken !== typeof undefined) {
    getUsersToken.cancel('Operation canceled due to new request.');
  }

  getUsersToken = axios.CancelToken.source();
  return axios.delete('/downloads/delete', {
    cancelToken: getUsersToken.token,
    data: { filePath },
    params: {
      connectionId,
    },
  });
};

const downloadService = { getFiles, downloadFile, deleteFile };
export default downloadService;
