import React from 'react';
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';
import {
  getDisplayCurrencyValue,
  getDisplayDateValue,
  getDisplayOptionalValue,
  getDisplayPercentValue,
  getDisplayUTCDateValue,
  getDisplayYesOrNoValue,
  isNil,
} from 'app/utils';

import type { EmployeeEmployment, UseQueryRefetch } from 'app/types';

import { useDialog, useEmploymentTypeSelectItems } from 'app/hooks';

import ActionButton from 'app/components/ActionButton';
import DetailsPanel from 'app/components/DetailsPanel';
import { useLocale } from 'app/components/Intl';
import { Tooltip } from 'app/components/Tooltip';

import { EmployeeDetailsPanelHeaderWithSyncToggle } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeDetailsPanelHeaderWithSyncToggle';

import { EmployeeEmploymentDetailsFields } from './EmployeeEmploymentDetails.types';
import { EmployeeEmploymentFormDialog } from './components';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DivTooltipContentStyled } from '../../../../../../../../components/DetailsPanelRow/DetailsPanelRowValueWithReset/DetailsPanelRowValueWithReset.styles';

import { EMPLOYER, CONNECTION } from 'app/common/Authorization/entities';
import { EDIT, RUN } from 'app/common/Authorization/permissions';
import { HasAccessTo } from 'app/components';

type EmployeeEmploymentDetailsProps = {
  loading: boolean;
  isRegularConnection: boolean;
  refetchGetEmploymentsQuery: UseQueryRefetch;
  connectionId: string | undefined;
  employeeId: string | undefined;
  employment: EmployeeEmployment | null;
  synchronizationStatus: boolean | undefined;
  refetchGetEmployeeSynchronizationQuery: UseQueryRefetch;
  disabledActionButtonsWhileLoading: boolean;
};

export const EmployeeEmploymentDetails = ({
  connectionId,
  employeeId,
  employment,
  loading,
  isRegularConnection,
  refetchGetEmploymentsQuery,
  synchronizationStatus,
  refetchGetEmployeeSynchronizationQuery,
  disabledActionButtonsWhileLoading,
}: EmployeeEmploymentDetailsProps) => {
  const { locale } = useLocale();
  const { getEmploymentTypeDisplayNameByValue } = useEmploymentTypeSelectItems();
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);

  const {
    dialogState: employmentFormDialogState,
    openDialog: openEmploymentFormDialog,
    closeDialog: closeEmploymentFormDialog,
  } = useDialog();

  const [employmentToEdit, setEmploymentToEdit] = React.useState<EmployeeEmployment | null>(null);
  const shouldRenderEmployeeEmploymentFormDialog = !!connectionId && !!employeeId && employmentFormDialogState;

  const onOpenEmploymentFormDialog = React.useCallback(
    (employment: EmployeeEmployment | null) => {
      setEmploymentToEdit(employment);
      openEmploymentFormDialog();
    },
    [openEmploymentFormDialog],
  );

  const onCloseScheduleFormDialog = React.useCallback(() => {
    setEmploymentToEdit(null);
    closeEmploymentFormDialog();
  }, [closeEmploymentFormDialog]);

  const onClickAddButton = React.useCallback(() => {
    onOpenEmploymentFormDialog(null);
  }, [onOpenEmploymentFormDialog]);

  const onClickEditButton = React.useCallback(() => {
    onOpenEmploymentFormDialog(employment);
  }, [employment, onOpenEmploymentFormDialog]);

  const DetailsPanelColumns = React.useMemo(() => {
    const rows = {
      [EmployeeEmploymentDetailsFields.SequenceEmploymentId.Label]: employment?.sequenceEmploymentId ?? '-',
      [EmployeeEmploymentDetailsFields.StartDate.Label]: getDisplayDateValue(employment?.startDate),
      [EmployeeEmploymentDetailsFields.YearsFrom.Label]: getDisplayDateValue(employment?.yearsFrom),
      [EmployeeEmploymentDetailsFields.EndDate.Label]: getDisplayUTCDateValue(employment?.endDate),
      [EmployeeEmploymentDetailsFields.ProbationDate.Label]: getDisplayDateValue(employment?.probationDate),
      [EmployeeEmploymentDetailsFields.EmploymentType.Label]: !isNil(employment?.employmentType)
        ? getEmploymentTypeDisplayNameByValue(employment?.employmentType!)
        : '-',
      [EmployeeEmploymentDetailsFields.JobDescription.Label]: getDisplayOptionalValue(employment?.jobDescription),
      [EmployeeEmploymentDetailsFields.DeductionPlan.Label]: getDisplayOptionalValue(employment?.deductionPlan),
      [EmployeeEmploymentDetailsFields.Emoluments.Label]: getDisplayCurrencyValue({
        locale,
        value: employment?.emoluments,
        options: EmployeeEmploymentDetailsFields.Emoluments.Options,
      }),
      [EmployeeEmploymentDetailsFields.VacationPay.Label]: getDisplayYesOrNoValue(employment?.vacationPay),
      [EmployeeEmploymentDetailsFields.VacationPayPercentage.Label]: getDisplayPercentValue({
        locale,
        value: employment?.vacationPayPercentage,
        options: EmployeeEmploymentDetailsFields.VacationPayPercentage.Options,
      }),
      [EmployeeEmploymentDetailsFields.ExtraMonth.Label]: getDisplayYesOrNoValue(employment?.extraMonth),
      [EmployeeEmploymentDetailsFields.OtherWageComponents.Label]: getDisplayYesOrNoValue(
        employment?.otherWageComponents,
      ),
      [EmployeeEmploymentDetailsFields.CalculationStartDate.Label]: getDisplayDateValue(
        employment?.calculationStartDate,
      ),
    };

    return [{ rows }];
  }, [
    employment?.sequenceEmploymentId,
    employment?.startDate,
    employment?.yearsFrom,
    employment?.endDate,
    employment?.probationDate,
    employment?.employmentType,
    employment?.jobDescription,
    employment?.deductionPlan,
    employment?.emoluments,
    employment?.vacationPay,
    employment?.vacationPayPercentage,
    employment?.extraMonth,
    employment?.otherWageComponents,
    employment?.calculationStartDate,
    getEmploymentTypeDisplayNameByValue,
    locale,
  ]);

  let disableAddButton = true;
  if (employment?.endDate !== undefined && employment?.endDate !== null && employment?.latest === true) {
    disableAddButton = false;
  }

  const addButtonTooltipText = React.useMemo(() => {
    return (
      <DivTooltipContentStyled>
        <Typography variant="body1">
          <FormattedMessage id={TranslationKeys.employer_employee_employment_addButtonToolTip} />
        </Typography>
      </DivTooltipContentStyled>
    );
  }, []);

  const DetailsPanelActions = React.useMemo(() => {
    return (
      <>
        <ActionButton
          messageId={TranslationKeys.global_edit}
          variant={'text'}
          icon={faPenToSquare}
          onClick={onClickEditButton}
          disabled={
            disabledActionButtonsWhileLoading ||
            synchronizationStatus ||
            disabledIfNoAccessToEmployerEdit ||
            disabledIfNoAccessToConnectionRun
          }
        />

        {isRegularConnection &&
          (disableAddButton ? (
            <Tooltip title={addButtonTooltipText}>
              <span>
                <ActionButton
                  messageId={TranslationKeys.button_add}
                  variant="text"
                  icon={faPlus}
                  onClick={onClickAddButton}
                  disabled={
                    disabledActionButtonsWhileLoading ||
                    synchronizationStatus ||
                    disableAddButton ||
                    disabledIfNoAccessToEmployerEdit ||
                    disabledIfNoAccessToConnectionRun
                  }
                />
              </span>
            </Tooltip>
          ) : (
            <ActionButton
              messageId={TranslationKeys.button_add}
              variant="text"
              icon={faPlus}
              onClick={onClickAddButton}
              disabled={
                disabledActionButtonsWhileLoading ||
                synchronizationStatus ||
                disabledIfNoAccessToEmployerEdit ||
                disabledIfNoAccessToEmployerEdit ||
                disabledIfNoAccessToConnectionRun
              }
            />
          ))}
      </>
    );
  }, [
    addButtonTooltipText,
    disableAddButton,
    disabledActionButtonsWhileLoading,
    disabledIfNoAccessToEmployerEdit,
    isRegularConnection,
    onClickAddButton,
    onClickEditButton,
    synchronizationStatus,
    disabledIfNoAccessToConnectionRun,
  ]);

  const DetailsPanelHeader = React.useMemo(() => {
    return (
      <EmployeeDetailsPanelHeaderWithSyncToggle
        field={'employment'}
        loading={loading}
        synchronizationStatus={synchronizationStatus}
        refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
        disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
        messageId={TranslationKeys.employees_detail_employment}
        employeeId={employeeId}
        connectionId={connectionId}
      />
    );
  }, [
    disabledActionButtonsWhileLoading,
    connectionId,
    employeeId,
    loading,
    refetchGetEmployeeSynchronizationQuery,
    synchronizationStatus,
  ]);

  return (
    <>
      {shouldRenderEmployeeEmploymentFormDialog && (
        <EmployeeEmploymentFormDialog
          open={employmentFormDialogState}
          closeDialog={onCloseScheduleFormDialog}
          connectionId={connectionId}
          employeeId={employeeId}
          employment={employmentToEdit}
          refetchGetEmploymentsQuery={refetchGetEmploymentsQuery}
        />
      )}

      <DetailsPanel
        header={DetailsPanelHeader}
        columnsContainerSx={{ minHeight: '35.6em' }}
        columns={DetailsPanelColumns}
        actions={DetailsPanelActions}
        loading={loading}
      />
    </>
  );
};
