import { styled } from '@mui/material/styles';
import { FormLabel, Grid, Link, outlinedInputClasses, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GridItemStyled = styled(Grid, {
  name: 'GridItemStyled',
})(() => ({
  minHeight: '75px',
}));

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textTransform: 'uppercase',
}));

export const TextFieldStyled = styled(TextField, {
  name: ' TextFieldStyled',
})(({ theme }) => ({
  backgroundColor: 'white',
  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1.5),
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: '1px solid ' + theme.palette.secondary.main,
  },
}));

export const LinkDeleteStyled = styled(Link, {
  name: 'LinkDeleteStyled',
  shouldForwardProp: (propName: string) => !['disabled'].includes(propName),
})<{ disabled: boolean }>(({ theme, disabled }) => ({
  color: theme.palette.primary.dark,
  float: 'right',
  ...(disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}),
}));

export const FontAwesomeIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const GridAddressContainerStyled = styled(Grid, {
  name: 'GridAddressContainerStyled',
})(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  width: '100%',
}));

export const GridContactContainerStyled = styled(Grid, {
  name: 'GridContactContainerStyled',
})(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  width: '100%',
}));
