import { useMutation } from '@tanstack/react-query';

import type { CreateRateTableArgs } from 'app/Domain/Settings/Services';
import { settingsServices } from 'app/Domain/Settings/Services';

const mutationFn = (data: CreateRateTableArgs) => {
  return settingsServices.createRateTable(data);
};

export const useSettingsCreateRateTableMutation = () => {
  return useMutation<unknown, unknown, CreateRateTableArgs>(mutationFn);
};
