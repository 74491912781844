import type { Dictionary } from '../../../utils';
import { isEqual, isNil, isObject, isString } from '../../../utils';
import type { Field } from '../../../types/field.types';

export type NormalizeOptionsParams = {
  field: Field;
  loading?: boolean;
};

export const normalizeOptions = ({ field, loading = false }: NormalizeOptionsParams) => {
  if (loading) {
    return [];
  }
  return (field.items || []).map(fieldItem => {
    if (!isObject(fieldItem)) {
      return {
        element: fieldItem,
        value: fieldItem,
      };
    }
    return fieldItem;
  });
};

export type GetDefaultOptionParams = {
  normalizedOptions: Array<Dictionary<any>>;
  defaultValue: any;
};

export const getDefaultOption = ({ normalizedOptions, defaultValue }: GetDefaultOptionParams) => {
  const option = normalizedOptions.find(option => option.default === true);

  if (!isNil(option)) {
    return option;
  }

  if (isNil(defaultValue)) {
    return defaultValue;
  }

  return normalizedOptions.find(option => option.value === defaultValue);
};

export type GetActualValueParams = {
  normalizedOptions: Array<Dictionary<any>>;
  value: any;
  defaultValue: any;
  formikValue: any;
};

export const getActualValue = ({ normalizedOptions, defaultValue, formikValue, value }: GetActualValueParams) => {
  let selectedValue = (isNil(value) ? defaultValue : value) ?? '';

  if (!isNil(formikValue)) {
    selectedValue = formikValue;
  }

  if (isString(selectedValue)) {
    const validOption = normalizedOptions.find(option => option.value === selectedValue);
    if (validOption) {
      return validOption;
    }
  }

  if (isObject(selectedValue)) {
    const validOption = normalizedOptions.find(option => isEqual(option.value, selectedValue));
    if (validOption) {
      return validOption;
    }
  }

  return selectedValue;
};
