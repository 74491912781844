import React, { useCallback, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import FileUploadArea, { FileType } from '../../../../components/FileUploadArea';
import { isEmpty } from 'app/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { InputFieldFactory } from '../../../../components/FormikField';
import Button from '@mui/material/Button';
import employeesTemplate from './employees-template.xlsx';
import { isFuture, isValid } from 'date-fns';

import { TypographyStyled } from './EmployeeFileUpload.styles';
import { trim } from 'lodash';
import { TranslationKeys } from '../../../../translations';

const EmployeeFileUpload = ({ employerConnections, progressMsg, setProgressMsg }) => {
  const intl = useIntl();
  const [fileErrors, setFileErrors] = useState([]);
  const { isSubmitting, setFieldValue, setFieldError } = useFormikContext();
  const [validating, setValidating] = useState(false);
  const rowOffset = 19;

  const childIsValid = useCallback(child => {
    let personNameValid = true;
    let dateOfBirthValid = true;
    let genderValid = true;

    if (!child.personName.lastName) {
      personNameValid = false;
    }

    if (!child.dateOfBirth) {
      dateOfBirthValid = false;
    }

    if (!child.gender) {
      genderValid = false;
    }
    return personNameValid && dateOfBirthValid && genderValid;
  }, []);

  const childIsEmpty = useCallback(child => {
    return (
      isEmpty(child.personName.firstName) &&
      isEmpty(child.personName.lastName) &&
      isEmpty(child.gender) &&
      isEmpty(child.dateOfBirth)
    );
  }, []);

  const partnerIsValid = useCallback(partner => {
    return !isEmpty(partner.personName.lastName) && !isEmpty(partner.gender) && !isEmpty(partner.dateOfBirth);
  }, []);

  const employeePersonalInformationIsValid = useCallback(employee => {
    return (
      !isEmpty(employee.personName.lastName) &&
      !isEmpty(employee.gender) &&
      !isEmpty(employee.dateOfBirth) &&
      !isEmpty(employee.civilStatus) &&
      !isEmpty(employee.gender) &&
      !isEmpty(employee.dateOfBirth) &&
      !isEmpty(employee.socialSecurityNumber)
    );
  }, []);

  const employeeContactInformationIsValid = useCallback(employee => {
    return (
      !isEmpty(employee.address.street) &&
      !isEmpty(employee.address.houseNumber) &&
      !isEmpty(employee.address.zipCode) &&
      !isEmpty(employee.address.city) &&
      !isEmpty(employee.address.country)
    );
  }, []);

  const employmentYearsFromInformationIsValid = useCallback(employment => {
    if (isEmpty(employment.yearsFrom)) {
      return true;
    }

    // Default format date
    let yearsFrom = new Date(employment.yearsFrom);

    if (isValid(yearsFrom)) {
      return !isFuture(yearsFrom);
    }

    // dd-mm-YYYY format date
    const yearsFromDateArray = employment.yearsFrom.split('-');
    if (yearsFromDateArray.length === 3 && yearsFromDateArray[2].length === 4) {
      const yearsFromDateArrayParsedInt = yearsFromDateArray.map(el => parseInt(el));

      yearsFrom = new Date(
        yearsFromDateArrayParsedInt[2],
        yearsFromDateArrayParsedInt[1] - 1,
        yearsFromDateArrayParsedInt[0],
      );
    }

    if (isValid(yearsFrom)) {
      return !isFuture(yearsFrom);
    }

    return false;
  }, []);

  const validateEmployees = useCallback(
    employees => {
      return new Promise((resolve, reject) => {
        try {
          const employeeErrors = [];
          employees.forEach((employee, index) => {
            for (const childrenKey in employee.children) {
              if (!childIsValid(employee.children[childrenKey])) {
                setFileErrors(prevArr => [
                  ...prevArr,
                  { message: 'employees.error.children', values: index + rowOffset + 1 },
                ]);
                employeeErrors.push({ message: 'employees.error.children', values: index + rowOffset + 1 });
              }
            }

            const validStatuses = [
              'married',
              'alleenstaand',
              'gehuwd',
              'living-together',
              'samenwonend',
              'registered-partnership',
              'registered_partners',
              'geregistreerd partnerschap',
              'divorced',
              'gescheiden',
              'single',
              'ongehuwd',
              'widower',
              'weduwe/weduwnaar',
              'unknown',
              'overig',
              'living-together-with-agreement',
              'living-together-without-agreement',
            ];

            if (validStatuses.includes(employee.civilStatus)) {
              if (!partnerIsValid(employee.partner)) {
                employee.partner = null;
              }
            } else {
              // eslint-disable-next-line no-console
              console.error('The Civil Status is not valid: ' + employee.civilStatus && '');
              employee.partner = null;
            }

            if (!employeePersonalInformationIsValid(employee)) {
              setFileErrors(prevArr => [
                ...prevArr,
                { message: 'employees.error.personalInformation', values: index + rowOffset + 1 },
              ]);
              employeeErrors.push({ message: 'employees.error.personalInformation', values: index + rowOffset + 1 });
            }
            if (!employeeContactInformationIsValid(employee)) {
              setFileErrors(prevArr => [
                ...prevArr,
                { message: 'employees.error.contactInformation', values: index + rowOffset + 1 },
              ]);
              employeeErrors.push({ message: 'employees.error.contactInformation', values: index + rowOffset + 1 });
            }

            if (!employmentYearsFromInformationIsValid(employee.employment)) {
              const message = {
                message: 'employees.error.employment.yearsFrom',
                values: index + rowOffset + 1,
              };

              setFileErrors(prevArr => [...prevArr, message]);
              employeeErrors.push(message);
            }
          });

          if (employeeErrors.length) {
            setFieldValue('hasErrors', true);
          } else {
            setFieldValue('hasErrors', false);
          }

          resolve(employeeErrors);
        } catch (e) {
          reject(e);
        }
      });
    },
    [
      childIsValid,
      employeeContactInformationIsValid,
      employeePersonalInformationIsValid,
      employmentYearsFromInformationIsValid,
      partnerIsValid,
      setFieldValue,
    ],
  );

  const parseExcelFile = useCallback(
    file => {
      return new Promise((resolve, reject) => {
        setFileErrors([]);
        const reader = new FileReader();
        reader.onload = event => {
          try {
            const payload = event.target?.result;
            const workbook = XLSX.read(payload, { type: 'binary', dateNF: 'dd/MM/yyyy' });
            const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
            const range = XLSX.utils.decode_range(sheet['!ref']);

            range.s.r = rowOffset;
            const headers = [
              'personnelNumber',
              'socialSecurityNumber',
              'personName.initials',
              'personName.firstName',
              'personName.lastNamePrefix',
              'personName.lastName',
              'civilStatus',
              'gender',
              'dateOfBirth',
              'dateOfDeath',
              'emailAddress',
              'costCenter',
              'profession',
              'address.street',
              'address.houseNumber',
              'address.houseNumberSuffix',
              'address.zipCode',
              'address.city',
              'address.country',
              'employment.partTimePercentage',
              'employment.wage.grossWage',
              'employment.startDate',
              'employment.yearsFrom',
              'employment.endDate',
              'employment.emoluments',
              'partner.socialSecurityNumber',
              'partner.personName.initials',
              'partner.personName.firstName',
              'partner.personName.lastNamePrefix',
              'partner.personName.lastName',
              'partner.gender',
              'partner.dateOfBirth',
              'partner.dateOfDeath',
              'partner.startOfRelationship',
              'partner.endOfRelationship',
              'children[0].socialSecurityNumber',
              'children[0].personName.initials',
              'children[0].personName.firstName',
              'children[0].personName.lastNamePrefix',
              'children[0].personName.lastName',
              'children[0].gender',
              'children[0].dateOfBirth',
              'children[1].socialSecurityNumber',
              'children[1].personName.initials',
              'children[1].personName.firstName',
              'children[1].personName.lastNamePrefix',
              'children[1].personName.lastName',
              'children[1].gender',
              'children[1].dateOfBirth',
              'children[2].socialSecurityNumber',
              'children[2].personName.initials',
              'children[2].personName.firstName',
              'children[2].personName.lastNamePrefix',
              'children[2].personName.lastName',
              'children[2].gender',
              'children[2].dateOfBirth',
            ];
            const data = XLSX.utils.sheet_to_json(sheet, {
              header: headers,
              blankrows: false,
              skipHidden: true,
              rawNumbers: true,
              raw: false,
              range: range,
            });

            const employees = [];

            if (data.length > 1000) {
              setFileErrors([...fileErrors, { message: 'employees.error.fileTooBig' }]);
            }

            if (!data.length) {
              setFileErrors([...fileErrors, { message: 'employees.error.fileEmpty' }]);
            }

            data.forEach(row => {
              const wage = parseFloat(row['employment.wage.grossWage']);
              const partTimePercentage = parseFloat(row['employment.partTimePercentage']);

              const partner = {
                socialSecurityNumber: row['partner.socialSecurityNumber'],
                personName: {
                  initials: row['partner.personName.initials'],
                  firstName: row['partner.personName.firstName'],
                  lastNamePrefix: row['partner.personName.lastNamePrefix'],
                  lastName: row['partner.personName.lastName'],
                },
                gender: row['partner.gender'],
                dateOfBirth: row['partner.dateOfBirth'],
                dateOfDeath: row['partner.dateOfDeath'],
                startOfRelationship: row['partner.startOfRelationship'],
                endOfRelationship: row['partner.endOfRelationship'],
              };

              const children = [];
              for (let i = 0; i <= 2; i++) {
                const child = {
                  socialSecurityNumber: row[`children[${i}].socialSecurityNumber`],
                  personName: {
                    initials: row[`children[${i}].personName.initials`],
                    firstName: row[`children[${i}].personName.firstName`],
                    lastNamePrefix: row[`children[${i}].personName.lastNamePrefix`],
                    lastName: row[`children[${i}].personName.lastName`],
                  },
                  gender: row[`children[${i}].gender`],
                  dateOfBirth: row[`children[${i}].dateOfBirth`],
                };
                if (!childIsEmpty(child)) {
                  children.push(child);
                }
              }

              const employee = {
                personnelNumber: row.personnelNumber ?? null,
                socialSecurityNumber: row.socialSecurityNumber ?? null,
                personName: {
                  initials: row['personName.initials'] ?? null,
                  firstName: row['personName.firstName'],
                  lastNamePrefix: row['personName.lastNamePrefix'] ?? null,
                  lastName: row['personName.lastName'] ?? null,
                },
                civilStatus: row.civilStatus?.toLowerCase() ?? null,
                gender: row.gender ?? null,
                dateOfBirth: row.dateOfBirth ?? null,
                dateOfDeath: row.dateOfDeath ?? null,
                emailAddress: row.emailAddress ?? null,
                costCenter: row['costCenter'] ?? null,
                profession: row['profession'] ?? null,
                address: {
                  street: row['address.street'] ?? null,
                  houseNumber: row['address.houseNumber'] ?? null,
                  houseNumberSuffix: row['address.houseNumberSuffix'] ?? null,
                  zipCode: row['address.zipCode'] !== undefined ? trim(row['address.zipCode']) : null,
                  city: row['address.city'] ?? null,
                  country: row['address.country'] ?? null,
                },
                employment: {
                  partTimePercentage: partTimePercentage ?? null,
                  startDate: row['employment.startDate'] ?? null,
                  yearsFrom: row['employment.yearsFrom'] ?? null,
                  endDate: row['employment.endDate'] ?? null,
                  emoluments: row['employment.emoluments'] ?? null,
                  wage: {
                    grossWage: wage ?? null,
                    fullTime: partTimePercentage === 100.0,
                  },
                },
                partner: partner ?? null,
                children: children ?? null,
              };

              employees.push(employee);
            });

            resolve(employees);
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = () => {
          reject(new Error(`Error occurred reading file: ${file.name}`));
        };
        reader.readAsBinaryString(file);
      });
    },
    [childIsEmpty, fileErrors],
  );

  const changeList = useCallback(
    async files => {
      const file = files.slice(0, 1)[0] ?? undefined;

      if (file) {
        setProgressMsg('employees.add.readFile');
        setValidating(true);
        await parseExcelFile(file)
          .then(async employees => {
            setProgressMsg('employees.add.validateFile');
            await validateEmployees(employees);
            setFieldValue('employees', employees);
          })
          .catch(error => {
            setFileErrors([...fileErrors, error]);
            setFieldError('employees', error);
          })
          .finally(() => {
            setProgressMsg('employees.add.validateFileFinish');
            setValidating(false);
          });
      } else {
        setProgressMsg('employees.add.fileClear');
        setFileErrors([]);
        setFieldValue('employees', []);
      }
    },
    [fileErrors, parseExcelFile, setFieldError, setFieldValue, setProgressMsg, validateEmployees],
  );

  const autoCompletefields = useMemo(() => {
    return {
      name: 'connectionId',
      type: 'autocomplete',
      required: true,
      header: 'onboarding.connectionStep',
      placeholder: intl.formatMessage({ id: 'onboarding.connectionStep' }),
      loading: false,
      displayEmpty: true,
      items: (employerConnections || []).map(connection => {
        return {
          element: connection.connectionName,
          value: connection.connectionId,
          default: false,
        };
      }),
    };
  }, [employerConnections, intl]);

  const multiSelectFields = useMemo(() => {
    return {
      type: 'multi-select',
      name: 'connectionId',
      label: intl.formatMessage({ id: TranslationKeys.onboarding_connectionStep }),
      displayEmpty: true,
      items: (employerConnections || []).map(connection => {
        return {
          element: connection.connectionName,
          value: connection.connectionId,
          default: false,
        };
      }),
    };
  }, [employerConnections, intl]);

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={4}>
          <InputFieldFactory field={employerConnections.length < 2 ? autoCompletefields : multiSelectFields} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button href={employeesTemplate} download="employees-template">
            <FormattedMessage id={'employees.template.download'} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FileUploadArea
            types={[FileType.Excel]}
            onChange={changeList}
            disabled={false}
            showSuccessMessage={false}
            isSubmitting={isSubmitting}
          />
          {fileErrors.length > 0 &&
            !validating &&
            fileErrors.map((fileError, index) => (
              <TypographyStyled key={index} color="error" variant="body1">
                <FormattedMessage
                  id={fileError.message}
                  values={{ row: fileError?.values, b: chunks => <b>{chunks}</b>, br: <br /> }}
                />
              </TypographyStyled>
            ))}
          {progressMsg && (
            <TypographyStyled color="primary" variant="body1">
              <FormattedMessage id={progressMsg} />
            </TypographyStyled>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeeFileUpload;
