import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, Typography } from '@mui/material';

import { TranslationKeys } from '../../../../../translations';

import type { OwnerEmployer } from '../../../../../types/owner.types';

import { ActionLink } from '../../../../../components/ActionLink';

import { GridItem } from './OwnerEmployers.styles';

type OwnerEmployersProps = {
  ownerEmployers: Array<OwnerEmployer>;
};

export const OwnerEmployers = ({ ownerEmployers }: OwnerEmployersProps) => {
  return (
    <Grid sx={{ width: '100%' }} container spacing={2}>
      {!!ownerEmployers.length &&
        ownerEmployers.map(employer => (
          <GridItem item key={employer.employerId} xs={12}>
            <Grid container>
              <Grid item xs={7}>
                <Typography variant="body1">{employer.employerName}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Box textAlign="right">
                  <ActionLink to={`/employers/${employer.employerId}`}>
                    <Typography variant="body1">
                      <FormattedMessage id={TranslationKeys.employers_showDetailPage} />
                    </Typography>
                  </ActionLink>
                </Box>
              </Grid>
            </Grid>
          </GridItem>
        ))}
    </Grid>
  );
};
