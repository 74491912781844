import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DialogContentText } from '@mui/material';

import type { Dictionary } from '../../utils';
import { isObject } from '../../utils';

import format from '../../utils/formatter';

import { TranslationKeys } from '../../translations';

import ActionButton from '../ActionButton';
import { Dialog } from '../Dialog';

import { DivPanelsContainerStyled } from './SubmitConfirmationDialog.styles';
import { SubmitConfirmationDialogDataPanel } from './SubmitConfirmationDialogDataPanel';

import type {
  ChangedValues,
  CheckValuesRecursivelyFunc,
  GetInputOptionsAndLabelFunc,
  GetSelectOrAutocompleteDisplayValueFunc,
} from './SubmitConfirmationDialog.types';

type SubmitConfirmationDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  previousValues: Dictionary<any>;
  newValues: Dictionary<any>;
  getInputOptionsAndLabel: GetInputOptionsAndLabelFunc;
  getSelectOrAutocompleteDisplayValue?: GetSelectOrAutocompleteDisplayValueFunc;
};

export const SubmitConfirmationDialog = ({
  open,
  onConfirm,
  onClose,
  previousValues,
  newValues,
  getInputOptionsAndLabel,
  getSelectOrAutocompleteDisplayValue,
}: SubmitConfirmationDialogProps) => {
  const setFieldData = React.useCallback(
    ({ inputName, value }) => {
      const input = getInputOptionsAndLabel(inputName);
      const isSelectOrAutocomplete = ['select', 'autocomplete'].includes(input.options?.style || '');
      const isSelectOrAutoCompleteAndGetterFunctionWasProvided =
        isSelectOrAutocomplete && getSelectOrAutocompleteDisplayValue;

      let inputValue = isSelectOrAutoCompleteAndGetterFunctionWasProvided
        ? getSelectOrAutocompleteDisplayValue({ inputName, inputValue: value })
        : value;

      if (input.options?.style === 'date') {
        inputValue = format.date(value);
      }

      return {
        label: input.label,
        options: input.options,
        value: inputValue,
      };
    },
    [getInputOptionsAndLabel, getSelectOrAutocompleteDisplayValue],
  );

  /*
    This recursive function only works for objects and doesn't work for arrays.
    If you want to try make it working with arrays, check out this commit: 5a0c24fbb095200bbf148aa0919ee7e8f3bcf485
   */
  const checkValuesRecursively = React.useCallback<CheckValuesRecursivelyFunc>(
    ({ inputName, previousValue, newValue }): ChangedValues | undefined => {
      if (!Array.isArray(previousValue) && !isObject(previousValue)) {
        if (previousValue !== newValue) {
          return {
            previousValues: {
              [inputName]: setFieldData({ inputName, value: previousValue }),
            },
            newValues: {
              [inputName]: setFieldData({ inputName, value: newValue }),
            },
          };
        }
      }

      if (isObject(previousValue)) {
        return Object.keys(previousValue).reduce<ChangedValues>(
          (acc, key) => {
            const ret = checkValuesRecursively({
              inputName: key,
              previousValue: previousValue[key],
              newValue: newValue[key],
            });

            if (!ret) {
              return acc;
            }

            acc.previousValues = {
              ...acc?.previousValues,
              ...(ret?.previousValues || {}),
            };
            acc.newValues = {
              ...acc?.newValues,
              ...(ret?.newValues || {}),
            };
            return acc;
          },
          { previousValues: {}, newValues: {} },
        );
      }

      return undefined;
    },
    [setFieldData],
  );

  const changedValues = React.useMemo(() => {
    const hasChanges = JSON.stringify(previousValues) !== JSON.stringify(newValues);

    if (!hasChanges) {
      return null;
    }

    return Object.keys(previousValues).reduce<ChangedValues>(
      (acc, key) => {
        const ret = checkValuesRecursively({
          inputName: key,
          previousValue: previousValues[key],
          newValue: newValues[key],
        });

        if (!ret) {
          return acc;
        }

        acc.previousValues = {
          ...acc.previousValues,
          ...(ret?.previousValues || {}),
        };
        acc.newValues = {
          ...acc.newValues,
          ...(ret?.newValues || {}),
        };

        return acc;
      },
      { previousValues: {}, newValues: {} },
    );
  }, [previousValues, newValues, checkValuesRecursively]);

  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton messageId={TranslationKeys.button_cancel} onClick={onClose} variant="outlined" />
        <ActionButton messageId={TranslationKeys.button_save} onClick={onConfirm} />
      </>
    );
  }, [onClose, onConfirm]);

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      title={<FormattedMessage id={TranslationKeys.global_saveChanges} />}
      actions={DialogActions}
    >
      <DialogContentText>
        <FormattedMessage id={TranslationKeys.global_confirmSaveChanges} />
      </DialogContentText>

      <DivPanelsContainerStyled>
        <SubmitConfirmationDialogDataPanel
          title={TranslationKeys.global_originalData}
          values={changedValues?.previousValues}
        />
        <SubmitConfirmationDialogDataPanel
          title={TranslationKeys.global_newData}
          values={changedValues?.newValues}
          highlighted
        />
      </DivPanelsContainerStyled>
    </Dialog>
  );
};
