import { deburr, isNil, orderBy } from '../../utils';

import type { TableCellHeaderSortOptions } from '../TableCellHeader';

import type { FilterableTableColumnType } from './FilterableTable.types';

export const sortableMapper = (sortable: FilterableTableColumnType['sortable']) => {
  if (sortable === 'asc') {
    return 'asc';
  }

  if (sortable === 'desc') {
    return 'desc';
  }
  return null;
};

export const sortableReducer = (
  result: Record<string, TableCellHeaderSortOptions>,
  column: FilterableTableColumnType,
) => {
  result[column.name] = sortableMapper(column?.sortable || null);
  return result;
};

export const defaultSorter = (value: string): string => deburr(((value ?? '') + '').toLocaleLowerCase());

export const sortRows = <TRowData>(
  columns: FilterableTableColumnType[],
  rows: TRowData[],
  sortOrders: Record<string, TableCellHeaderSortOptions>,
) => {
  const sortableColumns = columns
    .filter(column => !!column.sortable)
    .reduce<Record<string, FilterableTableColumnType['sortProvider']>>((map, column) => {
      let provider = column.sortProvider;
      if (!provider) {
        provider = defaultSorter;
      }
      map[column.name] = provider;
      return map;
    }, {});

  const sortKeys = Object.keys(sortOrders).filter(key => !isNil(sortOrders[key]) && key in sortableColumns);
  const sortFunctions = sortKeys.map(key => sortableColumns[key]);
  const sortOrder = sortKeys.map(key => sortOrders[key]) as Array<boolean | 'asc' | 'desc'>;

  return orderBy(rows, sortFunctions, sortOrder) as TRowData[];
};
