import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { formatDate } from '../../../../utils/formatter/dateTimeFormatter';
import { EDIT } from '../../../../common/Authorization/permissions';
import { CREDENTIAL } from '../../../../common/Authorization/entities';
import { HasAccessTo } from '../../../../components/Authorization';

const CredentialRequestsTable = props => {
  const {
    className,
    credentialRequests,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
  } = props;

  const disabledIfNoAccessToCredentialsEdit = !HasAccessTo(CREDENTIAL, EDIT);

  return (
    <Card className={className}>
      <CardContent sx={{ padding: 0 }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="credentialRequests.name" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="owners.title" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentialRequests.packageType" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentialRequests.createdAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="credentialRequests.connect" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  credentialRequests.map(credentialRequest => (
                    <TableRow hover key={credentialRequest.requestId}>
                      <TableCell>
                        <div>
                          <Typography variant="body1">{credentialRequest.name}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{credentialRequest.owner?.ownerName}</TableCell>
                      <TableCell>
                        <FormattedMessage id={`packages.type.${credentialRequest.packageType}`} />
                      </TableCell>
                      <TableCell>{formatDate(credentialRequest.createdAt)}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          href={`${process.env.REACT_APP_AUTH_URL}/connect/${credentialRequest.packageType}/${credentialRequest.requestId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          disabled={disabledIfNoAccessToCredentialsEdit}
                        >
                          <FormattedMessage id="credentialRequests.connect" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <TablePagination
          component="div"
          count={totalCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

CredentialRequestsTable.propTypes = {
  className: PropTypes.string,
  credentialRequests: PropTypes.array.isRequired,
};

export default CredentialRequestsTable;
