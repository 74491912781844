import React from 'react';
import { useIntl } from 'react-intl';
import { Form, useFormikContext } from 'formik';

import { Box, Grid } from '@mui/material';

import type { Insurer, RateTableTable } from 'app/types';

import { format } from 'app/utils';

import { InputFieldFactory } from 'app/components';
import { useLocale } from 'app/components/Intl';

import type { RateTableFormValues } from './RateTableForm.types';
import { RateTableFormFields } from './RateTableForm.types';
import { RateTable, RateTableFileUpload, RateTableFormErrors } from './components';
import { useSettingsGetRateTableTypesQuery } from '../../../../hooks';
import { StoreSelectors, useStoreSelector } from '../../../../store';

export const RATE_TABLE_FORM_DATA_TEST_ID = 'rate-table-form-component';
export const RATE_TABLE_FORM_ID = 'rate-table-form-id';

type RateTableFormProps = {
  isLoading?: boolean;
  insurers: Insurer[];
};

export const RateTableForm = ({ insurers, isLoading = false }: RateTableFormProps) => {
  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));
  const intl = useIntl();
  const { locale } = useLocale();

  const { values, errors, setFieldValue } = useFormikContext<RateTableFormValues>();

  const { data: rateTableTypes } = useSettingsGetRateTableTypesQuery({
    variables: {
      ownerId,
    },
  });

  const insurersSelectItems = React.useMemo(() => {
    return insurers.map(insurer => ({
      element: insurer.insurerName,
      value: insurer.insurerId,
      default: insurer.insurerId === values.insurerId,
    }));
  }, [insurers, values.insurerId]);

  const productsSelectItems = React.useMemo(() => {
    const selectedInsurer = insurers.find(insurer => insurer.insurerId === values.insurerId);

    return (selectedInsurer?.products || []).map(product => ({
      element: product.productName,
      value: product.productId,
      default: product.productId === values.productId,
    }));
  }, [insurers, values.insurerId, values.productId]);

  const indexationSelectItems = React.useMemo(() => {
    return [0, 1, 2, 3].map(indexation => ({
      element: format.percentageFormat({ locale, value: indexation }),
      value: indexation,
      default: indexation === values.indexation,
    }));
  }, [locale, values.indexation]);

  const rateTableTypesItems = React.useMemo(() => {
    return (rateTableTypes?.data || []).map(type => ({
      element: type,
      value: type,
    }));
  }, [rateTableTypes]);

  const prefillTitleValueOnFileUpload = React.useCallback(
    (fileName: string) => {
      setFieldValue(RateTableFormFields.Title.InputName, fileName);
    },
    [setFieldValue],
  );

  const prefillTablePreview = React.useCallback(
    (table: RateTableTable[]) => {
      setFieldValue(RateTableFormFields.Table.InputName, table);
    },
    [setFieldValue],
  );

  const onChangeInsurer = React.useCallback(() => {
    setFieldValue(RateTableFormFields.ProductId.InputName, '');
  }, [setFieldValue]);

  return (
    <Box data-testid={RATE_TABLE_FORM_DATA_TEST_ID} paddingTop={2} display={'flex'} flexDirection={'column'} gap={2}>
      <RateTableFormErrors errors={errors} />

      <Form id={RATE_TABLE_FORM_ID} noValidate>
        <Grid container>
          <Grid item xs={12} md={12}>
            <RateTableFileUpload
              isLoading={false}
              prefillTitleValueOnFileUpload={prefillTitleValueOnFileUpload}
              values={values}
              prefillTablePreview={prefillTablePreview}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} md={3}>
            <InputFieldFactory
              field={{
                name: RateTableFormFields.Title.InputName,
                header: intl.formatMessage({ id: RateTableFormFields.Title.Label }),
                required: true,
                loading: isLoading,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputFieldFactory
              field={{
                type: 'number',
                name: RateTableFormFields.Year.InputName,
                header: intl.formatMessage({ id: RateTableFormFields.Year.Label }),
                required: true,
                loading: isLoading,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputFieldFactory
              field={{
                type: 'select',
                name: RateTableFormFields.Indexation.InputName,
                header: intl.formatMessage({ id: RateTableFormFields.Indexation.Label }),
                required: true,
                loading: isLoading,
                items: indexationSelectItems,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputFieldFactory
              field={{
                type: 'select',
                name: RateTableFormFields.TableType.InputName,
                header: intl.formatMessage({ id: RateTableFormFields.TableType.Label }),
                required: true,
                loading: isLoading,
                items: rateTableTypesItems,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} md={3}>
            <InputFieldFactory
              onChange={onChangeInsurer}
              field={{
                type: 'autocomplete',
                name: RateTableFormFields.InsurerId.InputName,
                header: intl.formatMessage({ id: RateTableFormFields.InsurerId.Label }),
                required: true,
                loading: isLoading,
                items: insurersSelectItems,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputFieldFactory
              field={{
                type: 'autocomplete',
                name: RateTableFormFields.ProductId.InputName,
                header: intl.formatMessage({ id: RateTableFormFields.ProductId.Label }),
                required: true,
                disabled: !values.insurerId,
                loading: isLoading,
                items: productsSelectItems,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputFieldFactory
              field={{
                type: 'number',
                name: RateTableFormFields.Discount.InputName,
                header: intl.formatMessage({ id: RateTableFormFields.Discount.Label }),
                defaultValue: 100,
                required: true,
                loading: isLoading,
                options: {
                  style: 'percent',
                  openScale: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputFieldFactory
              field={{
                type: 'number',
                name: RateTableFormFields.UnitRate.InputName,
                header: intl.formatMessage({ id: RateTableFormFields.UnitRate.Label }),
                defaultValue: 10000,
                required: true,
                loading: isLoading,
                options: {
                  openScale: true,
                },
              }}
            />
          </Grid>
        </Grid>

        {values.table?.length > 0 && (
          <Grid container spacing={2} marginY={1}>
            <Grid item xs={12} md={12}>
              <RateTable table={values.table} />
            </Grid>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
