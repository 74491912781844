import type { CancelTokenSource } from 'axios';
import axios from 'axios';

import type { Owner, Role } from '../../../../types';

let getRolesToken: CancelTokenSource;

export type GetRolesParams = {
  page?: number;
  perPage?: number;
  searchQuery?: string;
};

const getRoles = async (params?: GetRolesParams) => {
  const { page, perPage, searchQuery } = params || {};

  try {
    if (typeof getRolesToken !== typeof undefined) {
      getRolesToken.cancel('Operation canceled due to new request.');
    }

    getRolesToken = axios.CancelToken.source();

    return axios.get('roles', {
      cancelToken: getRolesToken.token,
      params: {
        page,
        perPage,
        name: searchQuery,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const getRole = async (roleId: Role['roleId']) => {
  return axios.get(`/roles/${roleId}`);
};

const getPermissions = async () => {
  return axios.get('/permissions');
};

const createRole = async (values: Record<string, any>) => {
  return axios.post('/roles', { ...values });
};

const editRole = async (values: Record<string, any>) => {
  return axios.put(`/roles/${values.roleId}`, { ...values });
};

const deleteRole = async (roleId: Role['roleId']) => {
  return axios.delete(`/users/${roleId}`);
};

export type GetOwnerRolesParams = {
  ownerId: Owner['ownerId'];
  page?: number;
  perPage?: number;
  searchQuery?: string;
};

const getOwnerRoles = async ({ ownerId, page, perPage, searchQuery }: GetOwnerRolesParams) => {
  try {
    if (typeof getRolesToken !== typeof undefined) {
      getRolesToken.cancel('Operation canceled due to new request.');
    }

    getRolesToken = axios.CancelToken.source();

    return axios.get(`/owners/${ownerId}/roles`, {
      cancelToken: getRolesToken.token,
      params: {
        page,
        perPage,
        name: searchQuery,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const roleService = { getRoles, createRole, getRole, getPermissions, editRole, deleteRole, getOwnerRoles };
