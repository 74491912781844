import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  generateActionTypes,
  tableAction,
  tableActions,
  tableInitialState,
  tableSagas,
} from 'app/Domain/App/Ducks/Table.duck';
import insurerService from 'app/Domain/Insurers/Services/InsurerService/insurerService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { createSelector } from 'reselect';
import { normalizeError } from 'app/utils';

export const actionTypes = {
  Insurers: generateActionTypes('Insurers'),
  OwnerInsurers: generateActionTypes('Insurers.Owner', {
    SetOwnerId: 'Set OwnerId',
  }),
};

const initialState = {
  insurers: { ...tableInitialState, pageSize: 100 },
  ownerInsurers: { ...tableInitialState, pageSize: 100, ownerId: null },
};

const additionalInsurersMutators = {};
additionalInsurersMutators[`${actionTypes.OwnerInsurers.SetOwnerId}`] = (state, action) => {
  return {
    ...state,
    ownerId: action.payload,
  };
};

export const reducer = (state = initialState, action) => {
  if (action.type.startsWith('[Insurers]')) {
    return {
      ...state,
      insurers: tableAction(actionTypes.Insurers, state.insurers, action, initialState.insurers),
    };
  } else if (action.type.startsWith('[Insurers.Owner]')) {
    return {
      ...state,
      ownerInsurers: tableAction(
        actionTypes.OwnerInsurers,
        state.ownerInsurers,
        action,
        initialState.ownerInsurers,
        additionalInsurersMutators,
      ),
    };
  }
  return state;
};

export const actions = {
  insurers: tableActions(actionTypes.Insurers),
  ownerInsurers: tableActions(actionTypes.OwnerInsurers, {
    setOwnerId: (payload = {}) => ({
      type: actionTypes.OwnerInsurers.SetOwnerId,
      payload,
    }),
  }),
};

export function* saga() {
  const requestInsurersWorker = function* getInsurersSaga({ payload }) {
    const pagination = yield select(state => selectors.selectInsurers(state.InsurersReducer));
    const { onError, onSuccess } = payload || {};
    try {
      const response = yield call(insurerService.getInsurers, pagination);
      yield put(actions.insurers.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.insurers.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getInsurers',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  };

  yield takeLatest(actionTypes.Insurers.RequestData, requestInsurersWorker);
  yield* tableSagas(actionTypes.Insurers, requestInsurersWorker);

  const requestOwnerInsurersWorker = function* getOwnerInsurersSaga({ payload }) {
    const { ownerId, page, pageSize } = yield select(state => selectors.selectOwnerInsurers(state.InsurersReducer));
    const { onError, onSuccess } = payload || {};
    try {
      yield put(actions.ownerInsurers.requestData());
      const response = yield call(insurerService.getOwnerInsurers, { ownerId, page, pageSize });
      yield put(actions.ownerInsurers.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.ownerInsurers.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getInsurerOwner',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  };

  yield takeLatest(actionTypes.OwnerInsurers.SetOwnerId, requestOwnerInsurersWorker);
  yield* tableSagas(actionTypes.OwnerInsurers, requestOwnerInsurersWorker);
}

export const selectors = {
  selectInsurers: createSelector(
    state => state.insurers,
    insurers => insurers,
  ),
  selectOwnerInsurers: createSelector(
    state => state.ownerInsurers,
    ownerInsurers => ownerInsurers,
  ),
  selectOwnerInsurersMap: createSelector(
    state => state.ownerInsurers,
    ({ loading, ownerId, items }) => {
      return {
        loading,
        ownerId,
        map: (items || []).reduce((map, value) => {
          map[`${value.insurerId}`] = value;
          return map;
        }, {}),
      };
    },
  ),
};
