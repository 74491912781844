import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { appService } from '../../Domain/App/Services/AppServices';
import { PublicLayout } from '../../layouts';
import { TranslationKeys } from '../../translations';
import { Reset2FaCodeViewContent } from './components';

export const Reset2FaCodeView = () => {
  const [searchParams] = useSearchParams();

  const [isLinkValid, setIsLinkValid] = React.useState(true);

  const token = searchParams.get('t');
  const userId = searchParams.get('u');

  React.useEffect(() => {
    if (token && userId) {
      appService
        .checkReset2FaLink(token, userId)
        .then(data => {
          setIsLinkValid(data.data);
        })
        .catch(() => {
          setIsLinkValid(false);
        });
    }
  }, [token, userId]);

  return (
    <PublicLayout titleId={TranslationKeys.signIn_twofa_lost2fa_confirmation_title}>
      <Reset2FaCodeViewContent token={token} userId={userId} isLinkValid={isLinkValid} />
    </PublicLayout>
  );
};
