export const COMPARE_BATCH_TEMPLATE_FILE_NAME = 'Compare_Batch_Template';

export const COMPARE_BATCH_FILE_STRUCTURE: Array<{
  headerTitle: string;
  fieldName: string;
}> = [
  {
    headerTitle: 'Firstname *',
    fieldName: 'employee.name_first',
  },
  {
    headerTitle: 'Name Prefix',
    fieldName: 'employee.name_prefix',
  },
  {
    headerTitle: 'Lastname *',
    fieldName: 'employee.name_last',
  },
  {
    headerTitle: 'Birthdate (dd-mm-yyyy)',
    fieldName: 'employee.date_birth',
  },
  {
    headerTitle: 'Gender (e.g. male/female/unknown)',
    fieldName: 'employee.gender',
  },
  {
    headerTitle: 'Email',
    fieldName: 'employee.email',
  },
  {
    headerTitle: 'BSN *',
    fieldName: 'employee.bsn',
  },
  {
    headerTitle: 'Civil Status (e.g. single/married/living-together)',
    fieldName: 'employee.civil_status',
  },
  {
    headerTitle: 'Employee Number',
    fieldName: 'employee.number',
  },
  {
    headerTitle: 'Employment Start Date * (dd-mm-yyyy)',
    fieldName: 'employment.start',
  },
  {
    headerTitle: 'Employment End Date (dd-mm-yyyy)',
    fieldName: 'employment.end',
  },
  {
    headerTitle: 'Wage * (e.g. 1000.00)',
    fieldName: 'employment.wage',
  },
  {
    headerTitle: 'Part-time Percentage * (e.g. 50)',
    fieldName: 'employment.parttime',
  },
];

export const COMPARE_BATCH_FILE_HEADERS = COMPARE_BATCH_FILE_STRUCTURE.map(fileStructure => fileStructure.headerTitle);
