import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { Accordion } from '../../../../../components/Accordion';

export const TypographyHeaderStyled = styled(Typography, {
  name: 'TypographyHeaderStyled',
})(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(1),
}));

export const TypographySelectedLengthStyled = styled(Typography, {
  name: 'TypographySelectedLengthStyled',
  shouldForwardProp: (propName: string) => !['withMarginTop'].includes(propName),
})<{ withMarginTop?: boolean }>(({ theme, withMarginTop = false }) => ({
  ...(withMarginTop ? { marginTop: theme.spacing(2) } : {}),
  '& span': {
    fontWeight: 700,
    marginLeft: theme.spacing(1),
  },
}));

export const ListStyled = styled('ul', {
  name: 'ListStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  maxHeight: '25em',
  listStyle: 'none',
}));

export const ListItemStyled = styled('li', {
  name: 'ListItemStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  lineHeight: '1.5em',
  '&::before': {
    width: '1em',
    content: '"•"',
    color: theme.palette.secondary.dark,
  },
}));

export const InnerListStyled = styled('ul', {
  name: 'InnerListStyled',
})(() => ({
  maxHeight: '7.5em',
  listStyle: 'none',
  overflow: 'auto',
}));

export const AccordionStyled = styled(Accordion, {
  name: 'AccordionStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
