import React from 'react';
import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { InputFieldFactory } from '../../../../components/FormikField';
import { ImageUploadArea } from '../../../../components/ImageUploadArea';

import type { OwnerFormValues } from './OwnerForm.types';
import { OwnerFormFields } from './OwnerForm.types';

export const OwnerForm = () => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<OwnerFormValues>();

  const onLogoChange = React.useCallback(
    (file?: File) => {
      setFieldValue(OwnerFormFields.Logo.InputName, file || '');
    },
    [setFieldValue],
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.OwnerName.InputName,
              header: OwnerFormFields.OwnerName.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.OwnerName.Label }).toUpperCase(),
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.OwnerEmail.InputName,
              header: OwnerFormFields.OwnerEmail.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.OwnerEmail.Label }).toUpperCase(),
              type: 'email',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.OwnerPhone.InputName,
              header: OwnerFormFields.OwnerPhone.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.OwnerPhone.Label }).toUpperCase(),
              required: true,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.Address.InputName,
              header: OwnerFormFields.Address.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.Address.Label }).toUpperCase(),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.City.InputName,
              header: OwnerFormFields.City.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.City.Label }).toUpperCase(),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.ZipCode.InputName,
              header: OwnerFormFields.ZipCode.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.ZipCode.Label }).toUpperCase(),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.ContactName.InputName,
              header: OwnerFormFields.ContactName.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.ContactName.Label }).toUpperCase(),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.ContactEmail.InputName,
              header: OwnerFormFields.ContactEmail.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.ContactEmail.Label }).toUpperCase(),
              type: 'email',
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputFieldFactory
            field={{
              name: OwnerFormFields.ContactPhone.InputName,
              header: OwnerFormFields.ContactPhone.Label,
              placeholder: intl.formatMessage({ id: OwnerFormFields.ContactPhone.Label }).toUpperCase(),
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ImageUploadArea label={OwnerFormFields.Logo.Label} initialFile={values.logo} onChange={onLogoChange} />
        </Grid>
      </Grid>
    </>
  );
};
