import { styled } from '@mui/material/styles';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(() => ({
  height: '100%',
  backgroundImage: 'url(/images/bridge.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

export const DivContentStyled = styled('div', {
  name: 'DivContentStyled',
})(({ theme }) => {
  return {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '50%',
    height: '100%',
    right: 0,
    backgroundColor: theme.palette.primary.light,
    opacity: 0.9,

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  };
});
