import type { HTMLAttributeAnchorTarget, HTMLAttributeReferrerPolicy } from 'react';
import React from 'react';

import type { LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom';

import type { LinkProps as MuiLinkProps } from '@mui/material/Link';
import MuiLink from '@mui/material/Link';

type CommonProps = {
  children: React.ReactNode;
  underline?: MuiLinkProps['underline'];
  target?: HTMLAttributeAnchorTarget;
  referrerPolicy?: HTMLAttributeReferrerPolicy;
  download?: HTMLAnchorElement['download'];
};

type InternalLink = CommonProps & {
  to: ReactRouterLinkProps['to'];
  href?: never;
};

type ExternalLink = CommonProps & {
  href: MuiLinkProps['href'];
  to?: never;
};

type LinkProps = InternalLink | ExternalLink;

export const ActionLink = ({
  children,
  to,
  href,
  target,
  referrerPolicy,
  download,
  underline = 'hover',
  ...otherProps
}: LinkProps) => {
  const commonProps = {
    ...otherProps,
    target,
    referrerPolicy,
    underline,
    download,
  } as CommonProps;

  if (to) {
    return (
      <MuiLink component={ReactRouterLink} to={to} {...commonProps}>
        {children}
      </MuiLink>
    );
  }

  if (href) {
    return (
      <MuiLink href={href} {...commonProps}>
        {children}
      </MuiLink>
    );
  }

  return null;
};
