import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import formatISO from 'date-fns/formatISO';
import { isNil } from './Types';

export const serializeDate = (date: Date | null, asLocal?: boolean, start?: boolean, end?: boolean): string | null => {
  if (!date) {
    return null;
  }
  try {
    let innerDate = date;
    if (!isNil(start) && start) {
      innerDate = startOfDay(innerDate);
      return innerDate.toISOString();
    }

    if (!isNil(end) && end) {
      innerDate = endOfDay(innerDate);
      return innerDate.toISOString();
    }

    if (!isNil(asLocal) && asLocal) {
      // discard the time part from Local ISO 8601 string
      return formatISO(innerDate).substring(0, 10);
    }
    // discard the time part from UTC ISO 8601 string
    return innerDate.toISOString().substring(0, 10);
  } catch (err) {
    return null;
  }
};
