import React, { memo } from 'react';
import FormikWrapper from '../FormikWrapper';
import InternalMultiSelect from './InternalMultiSelect';
import type { InputFieldFactoryProps } from '../Factory/InputFieldFactory';
import type { SelectProps } from '../Select/Select';
import type { useFormikContext } from 'formik';

export type MultiSelectProps<TValue = any> = Pick<InputFieldFactoryProps, 'className' | 'field' | 'hideErrorText'> & {
  onChange?: SelectProps<TValue>['onChange'];
  variant?: 'default' | 'slim' | string;
};

const MultiSelect = (props: MultiSelectProps) => {
  return (
    <FormikWrapper>
      {(formik: ReturnType<typeof useFormikContext>) => <InternalMultiSelect formik={formik} {...props} />}
    </FormikWrapper>
  );
};

export default memo(MultiSelect);
