import type { Theme } from '@mui/material/styles';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiTableHead: FuncOverrideComponent<'MuiTableHead'> = (theme: Theme) => ({
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.primary.light + '!important',
      textTransform: 'uppercase',
      '& th:first-of-type': {
        borderRadius: '10px 0 0 0',
      },
      '& th:last-child': {
        borderRadius: '0 10px 0 0',
      },
    },
  },
});
