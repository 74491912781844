import React from 'react';
import { Typography } from '@mui/material';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

import { TranslationKeys } from '../../../../translations';

import type { UseQueryRefetch } from '../../../../types';

import { useRegulationsEditMutation, useSnakeBar } from '../../../../hooks';

import type { Dictionary } from '../../../../utils';
import { isNil } from '../../../../utils';

import { IntlMessage } from '../../../../components/Intl';

import type {
  EmployerRegulationsScalesTableColumn,
  MaximumFiscalPercentage,
  MaximumFiscalPercentagesSelectItem,
  RegulationsEmployerScalesFormValues,
} from './RegulationsEmployerScales.types';
import { DivHeaderStyled, PaperBackgroundStyled } from './RegulationsEmployerScales.styles';
import { RegulationsEmployerScalesTable } from './RegulationsEmployerScalesTable';
import type { EditRegulationsParams } from '../../Service/RegulationsService';

export type EmployerRegulationsScalesProps = {
  loading?: boolean;
  ownerId: string;
  summaryOfRegulationId: string;
  productId: string;
  connectionId?: string;
  employerId: string;
  year: number;
  refetchRegulationsQuery: UseQueryRefetch;
  data: {
    basicPensionRegulation: Dictionary<number>;
    wtp: Dictionary<number>;
    maximumFiscalPercentage: MaximumFiscalPercentage;
  };
};

export const RegulationsEmployerScales = ({
  loading = false,
  ownerId,
  summaryOfRegulationId,
  productId,
  connectionId,
  employerId,
  year,
  refetchRegulationsQuery,
  data,
}: EmployerRegulationsScalesProps) => {
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: editRegulations } = useRegulationsEditMutation();
  const [readonly, setReadonly] = React.useState(true);

  const toggleReadonly = React.useCallback(() => {
    setReadonly(stateIsReadonly => !stateIsReadonly);
  }, []);

  const columns = React.useMemo(() => {
    const generateColumns = (rowData: Dictionary<number>) => {
      return Object.keys(rowData).reduce(
        (acc: EmployerRegulationsScalesTableColumn[], age) => {
          acc.push({
            textAlign: 'right',
            name: age,
            title: age,
          });
          return acc;
        },
        [{ name: 'label', textAlign: 'right', title: <FormattedMessage id="age" /> }],
      );
    };

    const rows = [data.basicPensionRegulation, data.wtp];
    const cols = rows.map(generateColumns);

    if (loading && cols.length > 0) {
      // Fake columns for first loading state
      cols.forEach(col =>
        col.concat(
          ['15-20', '20-25', '25-30', '30-35', '35-40', '40-45', '45-50', '50-55', '55-60', '60-65 ', '65-68'].map(
            age => ({
              textAlign: 'right',
              name: age,
              title: age,
            }),
          ),
        ),
      );
    }
    return cols;
  }, [data.basicPensionRegulation, data.wtp, loading]);

  const ages = React.useMemo(() => {
    return columns[0].slice(1).map(col => col.name);
  }, [columns]);

  const maximumFiscalPercentagesSelectItems = React.useMemo<Array<MaximumFiscalPercentagesSelectItem>>(() => {
    const options = (data.maximumFiscalPercentage?.options || [])
      .map(option => ({
        element: option.values.title,
        value: option,
        default: option.element === data.maximumFiscalPercentage?.selected,
      }))
      .sort((a, b) => (a.element < b.element ? -1 : 0));

    if (options.length && !options.some(item => item.default)) {
      options[0].default = true;
    }

    return options;
  }, [data.maximumFiscalPercentage?.options, data.maximumFiscalPercentage?.selected]);

  const formikInitialValues = React.useMemo<RegulationsEmployerScalesFormValues>(() => {
    const selectedOption =
      data.maximumFiscalPercentage?.options.find(option => option.element === data.maximumFiscalPercentage?.selected) ||
      data.maximumFiscalPercentage?.options[0];

    if (selectedOption) {
      selectedOption.element = selectedOption.values.title;
    }

    return {
      selectedOption,
      basicPensionScheme: data.basicPensionRegulation,
      wtp: data.wtp,
    };
  }, [
    data.basicPensionRegulation,
    data.wtp,
    data.maximumFiscalPercentage?.options,
    data.maximumFiscalPercentage?.selected,
  ]);

  const formikOnSubmit = React.useCallback<FormikConfig<RegulationsEmployerScalesFormValues>['onSubmit']>(
    (values, { setSubmitting, setStatus, setErrors }) => {
      const { selectedOption, ...params } = ages.reduce(
        (acc, age) => {
          // @ts-ignore
          if (!isNil(values[age])) {
            // @ts-ignore
            acc[age] = +values[age];
          }
          return acc;
        },
        { ...values },
      );

      const payload = {
        ownerId,
        productId,
        connectionId: connectionId || null,
        employerId,
        year,
        values: {
          [summaryOfRegulationId]: {
            value: JSON.stringify({
              basicPensionRegulation: { ...params.basicPensionScheme },
              wtp: { ...params.wtp },
              maximumFiscalPercentage: selectedOption.values.taxLimitPercentage,
              code: selectedOption.values.code,
            }),
          },
        },
      } satisfies EditRegulationsParams;

      editRegulations(payload, {
        onSuccess: () => {
          showSuccessSnakeBar({ method: 'editRegulations' });
          setSubmitting(false);
          toggleReadonly();
          refetchRegulationsQuery();
        },
        onError: (error: any) => {
          showErrorSnakeBar({ method: 'editRegulations' });
          setSubmitting(false);
          if (error.violations) {
            setStatus(error.violations ? null : error.message);
            setErrors(error.violations);
          }
        },
      });
    },
    [
      ages,
      ownerId,
      productId,
      connectionId,
      employerId,
      year,
      summaryOfRegulationId,
      editRegulations,
      showSuccessSnakeBar,
      toggleReadonly,
      refetchRegulationsQuery,
      showErrorSnakeBar,
    ],
  );

  return (
    <PaperBackgroundStyled>
      <DivHeaderStyled>
        <Typography variant="overline">
          <IntlMessage value={TranslationKeys.summaryOfRegulations_scales_tableTitle} />
        </Typography>
      </DivHeaderStyled>

      <Formik<RegulationsEmployerScalesFormValues>
        enableReinitialize
        onSubmit={formikOnSubmit}
        initialValues={formikInitialValues}
      >
        <RegulationsEmployerScalesTable
          readonly={readonly}
          toggleReadonly={toggleReadonly}
          loading={loading}
          year={year}
          ages={ages}
          columns={columns}
          maximumFiscalPercentagesSelectItems={maximumFiscalPercentagesSelectItems}
        />
      </Formik>
    </PaperBackgroundStyled>
  );
};
