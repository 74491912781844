import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TranslationKeys } from '../../../../translations';

import type { Employer } from '../../../../types';
import type {
  FilterableTableRowMapperFunc,
  FilterableTableColumnType,
  FilterableTableRowChildMapperFunc,
  KebabMenuItem,
  TableCellHeaderSortOptions,
} from '../../../../components';
import { FilterableTable, HasAccessTo, ActionLink } from '../../../../components';
import KebabMenu from '../../../../components/KebabMenu';
import { EMPLOYER } from '../../../../common/Authorization/entities';
import { VIEW } from '../../../../common/Authorization/permissions';

import { useIntl } from 'react-intl';

enum TableColumns {
  Name = 'employerName',
  ChamberOfCommerce = 'chamberOfCommerce',
  Status = 'status',
  SubEmployers = 'subEmployers',
  ContactName = 'contactName',
  ContactEmail = 'contactEmail',
  ContactPhone = 'contactPhone',
  ColumnActions = 'col_actions',
}

type EmployersTableProps = {
  className?: string;
  employers: Array<Employer>;
  page: number;
  rowsPerPage: number;
  totalCount: number;
  onPaginationChange: (args: { page?: number; rowSize?: number }) => void;
  loading?: boolean;
  onSortChange?: (sortBy: Record<TableColumns, TableCellHeaderSortOptions>) => void;
};

export const EmployersTable = ({
  employers,
  onPaginationChange,
  page,
  rowsPerPage,
  totalCount,
  loading = false,
  onSortChange,
}: EmployersTableProps) => {
  const intl = useIntl();
  const hasEmployerView = HasAccessTo(EMPLOYER, VIEW);

  const tablePagination = React.useMemo(() => {
    return {
      page,
      rowsPerPage,
      totalCount,
    };
  }, [page, rowsPerPage, totalCount]);

  const buildMenuOptions = React.useCallback((employer: Employer, canView: boolean) => {
    const menuOptions: Array<KebabMenuItem> = [];

    if (canView && employer) {
      menuOptions.unshift({
        to: `/employers/${employer.employerId}/info`,
        element: <FormattedMessage id={TranslationKeys.employers_menu_view} />,
      });
    }

    return menuOptions;
  }, []);

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.Name,
        title: <FormattedMessage id={TranslationKeys.employers_title} />,
        sortable: true,
      },
      {
        name: TableColumns.ChamberOfCommerce,
        title: <FormattedMessage id={TranslationKeys.employers_chamberOfCommerce} />,
      },
      {
        name: TableColumns.Status,
        title: <FormattedMessage id={TranslationKeys.employers_status} />,
      },
      {
        name: TableColumns.SubEmployers,
        title: <FormattedMessage id={TranslationKeys.employers_subEmployers} />,
      },
      {
        name: TableColumns.ContactName,
        title: <FormattedMessage id={TranslationKeys.employers_contactName} />,
      },
      {
        name: TableColumns.ContactEmail,
        title: <FormattedMessage id={TranslationKeys.employers_contactEmail} />,
      },
      {
        name: TableColumns.ContactPhone,
        title: <FormattedMessage id={TranslationKeys.employers_contactPhone} />,
      },
      {
        name: TableColumns.ColumnActions,
      },
    ];
  }, []);

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<Employer, Employer['subEmployers'][number]>>(
    data => {
      const employerName =
        hasEmployerView && data ? (
          <ActionLink to={`/employers/${data.employerId}/info`}>{data.employerName}</ActionLink>
        ) : (
          data.employerName
        );

      return {
        data: {
          [TableColumns.Name]: employerName,
          [TableColumns.ChamberOfCommerce]: data.chamberOfCommerce,
          [TableColumns.Status]: intl.formatMessage({ id: `employers.${data.status}` }),
          [TableColumns.SubEmployers]: data.subEmployers.length,
          [TableColumns.ContactName]: data.contactName,
          [TableColumns.ContactEmail]: data.contactEmail,
          [TableColumns.ContactPhone]: data.contactPhone,
          [TableColumns.ColumnActions]: <KebabMenu items={buildMenuOptions(data, hasEmployerView)} />,
          children: data.subEmployers,
        },
      };
    },
    [buildMenuOptions, hasEmployerView, intl],
  );

  const rowChildMapper = React.useCallback<FilterableTableRowChildMapperFunc<Employer['subEmployers'][number]>>(
    child => {
      const employerName =
        hasEmployerView && child ? (
          <ActionLink to={`/employers/${child.employerId}/info`}>{child.employerName}</ActionLink>
        ) : (
          child.employerName
        );

      return {
        data: {
          [TableColumns.Name]: employerName,
          [TableColumns.ChamberOfCommerce]: child.chamberOfCommerce,
          [TableColumns.Status]: intl.formatMessage({ id: `employers.${child.status}` }),
          [TableColumns.SubEmployers]: child.subEmployers.length,
          [TableColumns.ContactName]: child.contactName,
          [TableColumns.ContactEmail]: child.contactEmail,
          [TableColumns.ContactPhone]: child.contactPhone,
          [TableColumns.ColumnActions]: <KebabMenu items={buildMenuOptions(child, hasEmployerView)} />,
        },
      };
    },
    [buildMenuOptions, hasEmployerView, intl],
  );

  return (
    <>
      <FilterableTable<Employer, Employer['subEmployers'][number]>
        loading={loading}
        columns={tableHeaders}
        rows={employers}
        rowMapper={tableRowMapper}
        rowChildMapper={rowChildMapper}
        pagination={tablePagination}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
      />
    </>
  );
};
