import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { ButtonProps } from '@mui/material';
import { FormLabel } from '@mui/material';

import { debounce } from 'app/utils';

import IntlMessage from '../Intl/IntlMessage';

import { ButtonStyled, FontAwesomeIconStyled } from './ActionButton.styles';

type ActionButtonProps = {
  messageId: string;
  onClick?: ButtonProps['onClick'] | (() => void);
  className?: ButtonProps['className'];
  disabled?: ButtonProps['disabled'];
  variant?: ButtonProps['variant'];
  type?: ButtonProps['type'];
  form?: ButtonProps['form'];
  size?: ButtonProps['size'];
  color?: ButtonProps['color'];
  header?: string;
  required?: boolean;
  icon?: IconProp;
  sx?: ButtonProps['sx'];
};

export const ActionButton = ({
  onClick,
  messageId,
  className,
  sx,
  type,
  form,
  size,
  color,
  header = '',
  required = false,
  icon = undefined,
  disabled = false,
  variant = 'contained',
  ...otherProps
}: ActionButtonProps) => {
  const intl = useIntl();

  const debouncedClick = useMemo(() => {
    return debounce(
      (click, event) => {
        event.preventDefault();
        click && click(event);
      },
      100,
      { leading: true, trailing: false },
    );
  }, []);

  const doClick = useCallback(event => debouncedClick(onClick, event), [debouncedClick, onClick]);

  return (
    <>
      {header && (
        <FormLabel>
          <IntlMessage value={header} /> {required ? ' *' : ''}
        </FormLabel>
      )}
      <ButtonStyled
        {...otherProps}
        className={className}
        hasHeader={!!header}
        onClick={onClick ? doClick : undefined}
        disabled={disabled}
        variant={variant}
        sx={sx}
        type={type}
        form={form}
        size={size}
        color={color}
      >
        {!!icon && <FontAwesomeIconStyled icon={icon} />}
        {intl.messages[messageId] ?? messageId}
      </ButtonStyled>
    </>
  );
};
