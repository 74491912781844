import React from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Grid } from '@mui/material';

import { normalizeError } from '../../../../../../utils';

import { TranslationKeys } from '../../../../../../translations';

import type { Employer } from '../../../../../../types';

import { useSnakeBar } from '../../../../../../hooks';

import { StoreActions, useStoreDispatch } from '../../../../../../store';

import FormikModal from '../../../../../../components/FormikModal';

import { EmployerFileUpload } from '../EmployerFileUpload';
import { employerService } from '../../../../Services/EmployerService';

type EmployeeUploadFormDialogProps = {
  show: boolean;
  onClose: () => void;
  afterSubmit: () => void;
  employerId: string;
  employerConnections: Employer['connections'] | null;
};

export const EmployerFileUploadFormDialog = ({
  show,
  onClose,
  afterSubmit,
  employerId,
}: EmployeeUploadFormDialogProps) => {
  const intl = useIntl();
  const dispatch = useStoreDispatch();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const [progressMsg, setProgressMsg] = React.useState<string | null>(null);

  const formikInitialValues = React.useMemo(
    () => ({
      hasErrors: false,
    }),
    [],
  );

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      title: Yup.string().trim().strict(true).min(1).max(255).required(),
      hasErrors: Yup.boolean().oneOf([false]),
    });
  }, []);

  const formikOnSubmit = React.useCallback(
    async values => {
      const method = 'createEmployerFile';
      setProgressMsg(TranslationKeys.employees_add_fileSending);

      const payload = {
        employerId: employerId,
        employeeId: null,
        base64File: values.base64File,
        title: values.title,
      };

      await employerService
        .createEmployerFile(payload)
        .then(() => {
          dispatch(StoreActions.EmployerActions.files.setEmployerId(employerId));
          showSuccessSnakeBar({ method });
          afterSubmit();
        })
        .catch((err: any) => {
          const errors = normalizeError(err);
          showErrorSnakeBar({ method, message: errors.message });
        })
        .finally(() => {
          setProgressMsg(null);
        });
    },
    [afterSubmit, dispatch, employerId, showErrorSnakeBar, showSuccessSnakeBar],
  );

  return (
    <Grid item xs={12}>
      <Formik
        enableReinitialize
        initialValues={formikInitialValues}
        validationSchema={formikValidationSchema}
        onSubmit={formikOnSubmit}
      >
        <FormikModal
          maxWidth="md"
          show={show}
          onHide={onClose}
          title={intl.formatMessage({ id: TranslationKeys.employers_tab_files_addFile })}
        >
          <EmployerFileUpload progressMsg={progressMsg} setProgressMsg={setProgressMsg} />
        </FormikModal>
      </Formik>
    </Grid>
  );
};
