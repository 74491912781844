import { useMutation } from '@tanstack/react-query';

import type { UpdateUserOwnerIdArgs } from '../../../Domain/Users/Services';
import { userService } from '../../../Domain/Users/Services';

const mutationFn = (data: UpdateUserOwnerIdArgs) => {
  return userService.editUserOwnerId(data);
};

export const useUserUpdateOwnerIdMutation = () => {
  return useMutation<unknown, unknown, UpdateUserOwnerIdArgs>(mutationFn);
};
