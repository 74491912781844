import { styled } from '@mui/material/styles';
import { Field } from 'formik';

export const FieldStyled = styled(Field, {
  name: 'FieldStyled',
})(({ theme }) => ({
  display: 'none',
  '&+ label': {
    left: '45%',
    display: 'block',
    position: 'relative',
    paddingLeft: '35px',
    marginBottom: '20px',
    cursor: 'pointer',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
  },
  '&:hover + label:hover': {
    color: 'rgb(23, 86, 228)',
  },
  '&:hover + label:before': {
    border: '2px solid #FFF',
    background: theme.palette.info.light,
    boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
  },
  '&+ label:last-child': {
    marginBottom: 0,
  },
  '&+ label:before': {
    content: '""',
    display: 'block',
    width: '15px',
    height: '15px',
    border: '2px solid #FFF',
    borderRadius: '1em',
    boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
    position: 'absolute',
    left: 0,
    top: 0,
    WebkitTransition: 'all 0.2s, transform 0.3s ease-in-out',
    transition: 'all 0.2s, transform 0.3s ease-in-out',
    background: '#fff',
  },
  '&:checked + label:before': {
    background: theme.palette.info.light,
    boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
    WebkitTransform: 'rotateX(180deg)',
    transform: 'rotateX(180deg)',
  },
}));
