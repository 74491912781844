import type { FuncOverrideComponent } from '../theme.types';
import fontFaces from '../fontFaces';

export const MuiCssBaseline: FuncOverrideComponent<'MuiCssBaseline'> = () => ({
  styleOverrides: {
    '@global': {
      '@font-face': fontFaces,
    },
  },
});
