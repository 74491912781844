import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const GridContainerStyled = styled(Grid, {
  name: 'GridContainerStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const GridItemStyled = styled(Grid, {
  name: 'GridItemStyled',
})(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
