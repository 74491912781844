import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Grid, Typography } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';
import { ActionButton, HasAccessTo, SearchInput, Skeleton } from 'app/components';
import { SETTINGS } from 'app/common/Authorization/entities';
import { EDIT } from 'app/common/Authorization/permissions';

type SettingsRateTablesToolbarProps = {
  isLoading?: boolean;
  totalCount?: number;
  onAddButtonClick: () => void;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  initialSearchQuery?: string;
};

export const SettingsRateTablesToolbar = ({
  onAddButtonClick,
  onSearchChange,
  initialSearchQuery,
  totalCount = 0,
  isLoading = false,
}: SettingsRateTablesToolbarProps) => {
  const intl = useIntl();
  const hasPermissionToEdit = HasAccessTo(SETTINGS, EDIT);

  return (
    <Box paddingTop={2}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="h5">
            <FormattedMessage id={TranslationKeys.menu_settings_rateTables} />
          </Typography>

          <Typography variant="subtitle2" textTransform={'uppercase'}>
            {isLoading && <Skeleton width={200} />}
            {!isLoading && (
              <FormattedMessage id={TranslationKeys.global_totalCount_inDatabase} values={{ totalCount }} />
            )}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <SearchInput
            disabled={isLoading}
            onChange={onSearchChange}
            placeholder={intl.formatMessage({ id: TranslationKeys.settings_rateTable_search }).toUpperCase()}
            initialSearchQuery={initialSearchQuery}
          />
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ActionButton
            disabled={!hasPermissionToEdit || isLoading}
            messageId={TranslationKeys.button_add}
            icon={faPlus}
            onClick={onAddButtonClick}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
