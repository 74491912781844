import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { BlackAndWhiteListDetail } from 'app/types';

import { TranslationKeys } from 'app/translations';

import type {
  FilterableTableColumnType,
  FilterableTableOnSortChangeFunc,
  FilterableTablePaginationType,
  FilterableTableRowMapperFunc,
  FilterTableOnPaginationChangeFunc,
  KebabMenuItem,
} from 'app/components';
import { KebabMenu } from 'app/components';
import { ActionLink, FilterableTable } from 'app/components';
import { saveFile, startCase } from '../../../../../../utils';
import { EmployeeListFileStrategy } from '../../../../../Employers/Components/EmployerConnectionWizard/EmployeesListing/EmployeeListFileStrategy';
import { ListFile } from '../../../../../../components/FileUploadAreaWithTemplate/ListFile';
import { formatDate } from '../../../../../../utils/formatter/dateTimeFormatter';

enum ListedEmployeesType {
  'BlacklistedEmployeeDto' = 'blacklist',
  'WhitelistedEmployeeDto' = 'whitelist',
}

export const BLACK_AND_WHITE_LIST_TABLE_DATA_TEST_ID = 'black-and-white-list-details-table-component';

enum TableColumns {
  Connection = 'connectionName',
  listedEmployeesType = 'listedEmployeesType',
  UpdatedAt = 'updatedAt',
  Actions = 'col_actions',
}

type ConnectionErrorsDetailsTableProps = {
  isLoading?: boolean;
  blackAndWhiteListDetail: BlackAndWhiteListDetail[];
  pagination: FilterableTablePaginationType;
  onPaginationChange: FilterTableOnPaginationChangeFunc;
  onSortChange: FilterableTableOnSortChangeFunc;
};

export const BlackAndWhiteListDetailsTable = ({
  blackAndWhiteListDetail,
  pagination,
  onPaginationChange,
  onSortChange,
  isLoading = false,
}: ConnectionErrorsDetailsTableProps) => {
  const intl = useIntl();

  const downloadFile = React.useCallback(
    file => {
      const employeeListFileStrategy = new EmployeeListFileStrategy(intl);
      const listFile = new ListFile(['ID', 'BSN', 'Name'], employeeListFileStrategy);

      const sheet = listFile.generateDataSheet(file.data || [], [
        'employeeNumber',
        'employeeSocialSecurityNumber',
        'employeeName',
      ]);
      const blob = listFile.sheetToCsv(sheet, { FS: ';', forceQuotes: true });
      saveFile(blob, file.name);
    },
    [intl],
  );

  const buildRowActions = React.useCallback(
    latestList => {
      const actions: Array<KebabMenuItem> = [
        {
          element: <FormattedMessage id={TranslationKeys.files_download} />,
          onClick: () => downloadFile(latestList),
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [downloadFile],
  );

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.Connection,
        title: <FormattedMessage id={TranslationKeys.global_connection} />,
      },
      {
        name: TableColumns.listedEmployeesType,
        title: <FormattedMessage id={TranslationKeys.dashboard_blackAndWhiteList_col_listType} />,
      },
      {
        name: TableColumns.UpdatedAt,
        title: <FormattedMessage id={TranslationKeys.connections_notes_updatedAt} />,
      },
      {
        name: TableColumns.Actions,
        isActionsColumn: true,
      },
    ];
  }, []);

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<BlackAndWhiteListDetail>>(
    data => {
      const listedEmployeesType = data.packages.filter(elm => elm.settings.listedEmployeesType != null).pop();

      const type =
        ListedEmployeesType[
          listedEmployeesType?.settings.listedEmployeesType?.split('\\').pop() as keyof typeof ListedEmployeesType
        ];

      const latestList = data.packages
        .filter(elm => elm.settings.listedEmployees != null)
        .map(f => {
          const sheetName = startCase(data.connectionName);
          const name = `${sheetName}_${type}.csv`;
          return { name: name, data: f.settings.listedEmployees, sheetName: sheetName };
        })
        .pop();

      const updatedAt = data.listedEmployeesLogs
        .sort((a, b) => new Date(a.updatedAt).getDate() - new Date(b.updatedAt).getDate())
        .pop()?.updatedAt;

      return {
        data: {
          [TableColumns.Connection]: (
            <ActionLink to={`/employers/${data.employerId}/connections/${data.connectionId}`}>
              {data.connectionName}
            </ActionLink>
          ),
          [TableColumns.listedEmployeesType]: <FormattedMessage id={TranslationKeys[`employee_list_type_${type}`]} />,
          [TableColumns.UpdatedAt]: formatDate(updatedAt),
          [TableColumns.Actions]: buildRowActions(latestList),
        },
      };
    },
    [buildRowActions],
  );

  return (
    <div data-testid={BLACK_AND_WHITE_LIST_TABLE_DATA_TEST_ID}>
      <FilterableTable<BlackAndWhiteListDetail>
        loading={isLoading}
        columns={tableHeaders}
        rows={blackAndWhiteListDetail}
        rowMapper={tableRowMapper}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
      />
    </div>
  );
};
