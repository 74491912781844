import React, { createRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { InputFieldFactory } from 'app/components/FormikField';
import { TranslationKeys } from '../../../../../translations';

const ConnectionsStep = forwardRef(({ employerConnections, onSubmit, stepState }, ref) => {
  const formRef = createRef();
  const intl = useIntl();

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        formRef.current.submitForm();
      },
      getState() {
        return {
          form: formRef.current.values,
        };
      },
    }),
    [formRef],
  );

  const autoCompletefields = useMemo(() => {
    return {
      name: 'connectionId',
      type: 'autocomplete',
      required: true,
      header: 'onboarding.connectionStep',
      placeholder: intl.formatMessage({ id: 'onboarding.connectionStep' }),
      loading: false,
      displayEmpty: true,
      items: (employerConnections || []).map(connection => {
        return {
          element: connection.connectionName,
          value: connection.connectionId,
          default: false,
        };
      }),
    };
  }, [employerConnections, intl]);

  const multiSelectFields = useMemo(() => {
    return {
      type: 'multi-select',
      name: 'employee.connectionId',
      label: intl.formatMessage({ id: TranslationKeys.onboarding_connectionStep }),
      displayEmpty: true,
      items: (employerConnections || []).map(connection => {
        return {
          element: connection.connectionName,
          value: connection.connectionId,
          default: false,
        };
      }),
    };
  }, [employerConnections, intl]);

  const initialValues = useMemo(() => {
    return {
      employee: {
        connectionId: employerConnections?.length === 1 ? employerConnections[0].connectionId : null,
        ...(stepState.form.employee || {}),
      },
    };
  }, [employerConnections, stepState.form.employee]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} innerRef={formRef}>
      {() => (
        <>
          <Grid container spacing={2} justifyContent="center" sx={{ paddingTop: 1 }}>
            <Grid item xs={4}>
              <InputFieldFactory field={employerConnections.length < 2 ? autoCompletefields : multiSelectFields} />
            </Grid>
          </Grid>
          <Box p={1} />
        </>
      )}
    </Formik>
  );
});

ConnectionsStep.displayName = 'ConnectionsStep';

export default ConnectionsStep;
