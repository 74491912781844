import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Grid } from '@mui/material';

import { TranslationKeys } from '../../../../../translations';

import type { Employer, Owner } from '../../../../../types';

import type { SingleSelectedState } from '../../../../../store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from '../../../../../store';

import { useSnakeBar } from '../../../../../hooks';

import { CONNECTION, EMPLOYER } from '../../../../../common/Authorization/entities';
import { EDIT, ONBOARD } from '../../../../../common/Authorization/permissions';

import { HasAccessTo, ViewDialog } from '../../../../../components';
import ActionButton from '../../../../../components/ActionButton';
import DetailsPanel from '../../../../../components/DetailsPanel';
import DetailsPanelRow from '../../../../../components/DetailsPanelRow';

import EmployerConnectionWizard from '../../../Components/EmployerConnectionWizard';
import { EmployerConnectionsTable } from '../../../Components/EmployerConnectionsTable';

import EditEmployerFormModal from '../../../Forms/EditEmployerFormModal';
import ViewSubEmployersForm from '../../../Forms/ViewSubEmployersForm';

import { ActionButtonStyled, ChipStyled, GridStyled, TypographyStyled } from './EmployerInformationTab.styles';
import { EmployerNotes } from './components';

export const EmployerInformationTab = () => {
  const dispatch = useStoreDispatch();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const [editEmployerModalOpen, setEditEmployerModalOpen] = React.useState(false);
  const [viewSubEmployersModalOpen, setViewSubEmployersModalOpen] = React.useState(false);
  const [addProductModalOpen, setAddProductModalOpen] = React.useState(false);

  const { item: employer, loading: employerLoading } = useStoreSelector<SingleSelectedState<Employer>>(state =>
    StoreSelectors.EmployerSelector.selectEmployer(state.EmployerReducer),
  );

  const { item: owner, loading: ownerLoading } = useStoreSelector<SingleSelectedState<Owner>>(state =>
    StoreSelectors.OwnerSelector.selectOwner(state.OwnerReducer),
  );

  const disabledIfNoAccessToEdit = !HasAccessTo(EMPLOYER, EDIT);
  const employerReady = !!(!employerLoading && employer);
  const ownerReady = !ownerLoading && owner?.ownerId === employer?.ownerId;

  React.useEffect(() => {
    if (employerReady) {
      if (!owner || owner.ownerId !== employer.ownerId) {
        dispatch(StoreActions.OwnerActions.owner.requestData({ ownerId: employer.ownerId }));
      }
    }
  }, [owner, dispatch, employer, employerReady]);

  const submitEditEmployer = React.useCallback(
    (values, form) => {
      if (typeof values.parent === 'object' && values.parent !== null) {
        values.parentId = values.parent.parentId;
      }

      if (typeof values.owner === 'object' && values.owner !== null) {
        values.ownerId = values.owner.value;
        values.owner = values.owner['@id'];
      } else if (values.subOwnerId === '') {
        values.owner = owner?.parent ? owner?.parent['@id'] : owner?.['@id'];
        values.ownerId = owner?.parent ? owner?.parent.ownerId : owner?.ownerId;
      }

      if (!values.isPartOfHolding) {
        values.parent = null;
        values.parentId = null;
      }
      delete values.isPartOfHolding;
      delete values.subOwnerId;

      const method = 'editEmployer';
      const onSuccess = () => {
        setEditEmployerModalOpen(false);
        form.resetForm();
        showSuccessSnakeBar({ method });
      };

      const onError = (error: any) => {
        form.setSubmitting(false);
        if (error.violations) {
          form.setStatus(error.violations ? null : error.message);
          form.setErrors(error.violations);
        } else {
          showErrorSnakeBar({ method });
        }
      };

      // @ts-ignore
      dispatch(StoreActions.EmployerActions.employer.setEmployer(values, onError, onSuccess));
    },
    [dispatch, owner, showErrorSnakeBar, showSuccessSnakeBar],
  );

  const handleClickEdit = React.useCallback(() => {
    setEditEmployerModalOpen(true);
  }, []);

  const handleCloseEdit = React.useCallback(() => setEditEmployerModalOpen(false), []);

  const handleClickViewSubEmployers = React.useCallback(() => {
    setViewSubEmployersModalOpen(true);
  }, []);

  const openAddProduct = React.useCallback(() => {
    setAddProductModalOpen(true);
  }, []);

  const closeAddProduct = React.useCallback(() => {
    setAddProductModalOpen(false);
  }, []);

  const refreshConnections = React.useCallback(() => {
    if (employer) {
      dispatch(StoreActions.EmployerActions.employer.requestData({ employerId: employer.employerId }));
    }
  }, [dispatch, employer]);

  const employerConnectionUpserted = React.useCallback(() => {
    refreshConnections();
    closeAddProduct();
  }, [closeAddProduct, refreshConnections]);

  const handleCloseViewSubEmployers = React.useCallback(() => setViewSubEmployersModalOpen(false), []);

  const employerIsHolding = React.useMemo(() => !!employer?.subEmployers?.length, [employer]);
  const accessToConnectionOnboard = HasAccessTo(CONNECTION, ONBOARD);

  const employerTitle = React.useMemo(() => {
    return (
      employer && (
        <span>
          {employer.employerName}
          {employer.subEmployers?.length ? ' : ' + employer.subEmployers?.length + ' ' : null}
          {employer.subEmployers?.length ? (
            <FormattedMessage id={TranslationKeys.employers_operatingCompanies} />
          ) : null}
        </span>
      )
    );
  }, [employer]);

  let subownerName = TranslationKeys.employers_subOwner_none.toString();

  if (owner?.ownerName && owner?.subOwners?.length === 0) {
    subownerName = owner.ownerName;
  }

  const details = React.useMemo(() => {
    const infoRow = {
      [TranslationKeys.employers_employerName]: employer?.employerName,
      [TranslationKeys.employers_chamberOfCommerce]: employer?.chamberOfCommerce,
      [TranslationKeys.employers_sectorCode]: employer?.sectorCode,
      [TranslationKeys.employers_payrollTaxNumber]: employer?.payrollTaxNumber,
      [TranslationKeys.employers_address]: employer?.address,
      [TranslationKeys.employers_zipCode]: employer?.zipCode,
      [TranslationKeys.employers_city]: employer?.city,
      [TranslationKeys.employers_employerEmail]: employer?.employerEmail,
      [TranslationKeys.employers_employerPhone]: employer?.employerPhone,
      [TranslationKeys.employers_subOwner]: subownerName,
      [TranslationKeys.employers_internalNumber]: employer?.internalNumber,
    };

    const contactRow = {
      [TranslationKeys.employers_contactName]: employer?.contactName,
      [TranslationKeys.employers_employerEmail]: employer?.contactEmail,
      [TranslationKeys.employers_employerPhone]: employer?.contactPhone,
    };

    return (
      <>
        <DetailsPanelRow
          title={
            <>
              <TypographyStyled variant="body1">
                <FormattedMessage id={TranslationKeys.employers_contactInformation} />
              </TypographyStyled>
              {employerIsHolding && (
                <ChipStyled color="primary" label={<FormattedMessage id={TranslationKeys.employers_holding} />} />
              )}
            </>
          }
          rows={infoRow}
          loading={employerLoading || ownerLoading}
        />

        <Box m={1}></Box>
        <DetailsPanelRow rows={contactRow} loading={employerLoading} />
      </>
    );
  }, [employer, employerIsHolding, employerLoading, ownerLoading, subownerName]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <DetailsPanel
                header={undefined}
                body={details}
                actions={
                  <>
                    <ActionButton
                      variant="outlined"
                      messageId={TranslationKeys.employers_showSubEmployers}
                      disabled={!employerIsHolding || disabledIfNoAccessToEdit}
                      onClick={handleClickViewSubEmployers}
                    />
                    <ActionButtonStyled
                      onClick={handleClickEdit}
                      messageId={TranslationKeys.employers_edit}
                      disabled={!(employerReady && ownerReady) || disabledIfNoAccessToEdit}
                    />
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <EmployerNotes employer={employer} isLoading={employerLoading} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Grid container>
            <Grid item xs={12}>
              <EmployerConnectionsTable
                loading={employerLoading}
                employer={employer}
                connections={employer?.connections}
                onChange={refreshConnections}
              />
            </Grid>
            {accessToConnectionOnboard && (
              <GridStyled item xs={12}>
                <ActionButton
                  onClick={openAddProduct}
                  messageId={TranslationKeys.products_add}
                  disabled={employerLoading}
                />
              </GridStyled>
            )}
          </Grid>
        </Grid>
      </Grid>

      {employer && editEmployerModalOpen && (
        <EditEmployerFormModal
          employer={employer}
          owner={owner}
          open={editEmployerModalOpen}
          onSubmit={submitEditEmployer}
          onHide={handleCloseEdit}
        />
      )}
      {employer && viewSubEmployersModalOpen && !!employerTitle && (
        <ViewDialog
          open={viewSubEmployersModalOpen}
          setOpen={setViewSubEmployersModalOpen}
          title={employerTitle}
          onClose={handleCloseViewSubEmployers}
        >
          <ViewSubEmployersForm employer={employer} />
        </ViewDialog>
      )}
      {employer && addProductModalOpen && (
        <EmployerConnectionWizard
          employer={employer}
          onClose={closeAddProduct}
          onUpserted={employerConnectionUpserted}
        />
      )}
    </>
  );
};
