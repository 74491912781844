import React from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Typography } from '@mui/material';

import { TranslationKeys } from '../../../../../../../../translations';

import type { UserOwner } from '../../../../../../../../types';

import { ActionButton, Dialog } from '../../../../../../../../components';

import type { UserEnvironmentsListSelectedData } from '../../UserEnvironmentsList.types';

import { ListItemStyled, UnorderedListStyled } from './UserEnvironmentsListConfirmDialog.styles';

type UserEnvironmentsListConfirmDialogProps = {
  show: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedData: UserEnvironmentsListSelectedData;
  data: Array<UserOwner>;
};

export const UserEnvironmentsListConfirmDialog = ({
  show,
  isLoading,
  onConfirm,
  onClose,
  selectedData,
  data,
}: UserEnvironmentsListConfirmDialogProps) => {
  const userEnvironments = React.useMemo(() => {
    if (selectedData?.owners.length === 0) {
      return (
        <Typography variant={'caption'} textTransform={'initial'}>
          <FormattedMessage id={TranslationKeys.users_environmentsList_confirmDialog_noOwnersSelected} />
        </Typography>
      );
    }

    return selectedData?.owners.map((selectedOwner: UserOwner) => {
      return (
        <ListItemStyled key={selectedOwner?.ownerId}>
          <Typography>{selectedOwner?.ownerName}</Typography>
        </ListItemStyled>
      );
    });
  }, [selectedData.owners]);

  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton messageId={TranslationKeys.no} disabled={isLoading} onClick={onClose} variant={'outlined'} />
        <ActionButton messageId={TranslationKeys.yes} disabled={isLoading} onClick={onConfirm} />
      </>
    );
  }, [isLoading, onClose, onConfirm]);

  return (
    <Dialog
      open={show}
      maxWidth={'md'}
      title={<FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_title} />}
      onClose={onClose}
      actions={DialogActions}
      fullWidth
    >
      <>
        <Typography>
          <FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_header} />
        </Typography>

        <Typography variant={'h6'} marginTop={2} marginBottom={1}>
          <FormattedMessage
            id={TranslationKeys.users_accessesList_employers}
            values={{ count: Object.keys(selectedData.owners || {}).length }}
          />
        </Typography>

        <UnorderedListStyled>
          <PerfectScrollbar>{userEnvironments}</PerfectScrollbar>
        </UnorderedListStyled>
      </>
    </Dialog>
  );
};
