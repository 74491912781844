import { useMutation } from '@tanstack/react-query';
import { dashboardService } from '../../../Domain/Dashboard/Services';
import type { DownloadEventFlowRowPayload } from '../../../Domain/Dashboard/Services';
import type { AxiosResponse } from 'axios';

const mutationFn = (data: DownloadEventFlowRowPayload) => {
  return dashboardService.downloadEventFlowRows(data);
};

export const useDownloadEventFlow = () => {
  return useMutation<AxiosResponse, unknown, DownloadEventFlowRowPayload>(mutationFn);
};
