export const EDIT = 'EDIT';
export const VIEW = 'VIEW';
export const DOWNLOAD = 'DOWNLOAD';
export const EVENT = 'EVENT';
export const BATCH = 'BATCH';
export const ONBOARD = 'ONBOARD';
export const UPDATE = 'UPDATE';
export const NOTE = 'NOTE';
export const RUN = 'RUN';
export const CLEAR = 'CLEAR';
export const DELETE = 'DELETE';
export const SUPPORT = 'SUPPORT';
