import React from 'react';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material';
import type { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import MuiTooltip from '@mui/material/Tooltip';

export const MuiTooltipStyled = styled(
  ({ className, ...props }: MuiTooltipProps) => <MuiTooltip {...props} classes={{ popper: className }} />,
  { name: 'MuiTooltipStyled' },
)(({ theme }) => ({
  [`&.${tooltipClasses.popper}`]: {
    cursor: 'pointer',
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      outline: `1px solid ${theme.palette.secondary.main}`,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      '&::before': {
        outline: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
}));
