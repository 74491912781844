import React, { useMemo } from 'react';

import type { SingleSelectedState } from '../../../../../store';
import { StoreSelectors, useStoreSelector } from '../../../../../store';

import { RegulationsPanelForm } from '../../../../Regulations/Components/RegulationsPanelForm';

import { HasAccessTo } from 'app/components/Authorization';
import { EMPLOYER } from '../../../../../common/Authorization/entities';
import { EDIT } from '../../../../../common/Authorization/permissions';

export const EmployerSummaryTab = () => {
  const { item: employer, loading } = useStoreSelector<SingleSelectedState>(state =>
    StoreSelectors.EmployerSelector.selectEmployer(state.EmployerReducer),
  );

  const products = useMemo(() => {
    if (loading || !employer) {
      return [];
    }

    const productsMap = (employer?.connections || []).reduce(
      (map: Record<string, any>, connection: Record<string, any>) => {
        if (connection.product) {
          map[connection['@id']] = {
            ...connection.product,
            connectionId: connection.connectionId,
            connectionName: connection.connectionName,
          };
        }
        return map;
      },
      {},
    );

    return Object.keys(productsMap).map(k => productsMap[k]);
  }, [employer, loading]);

  const disabledIfNoAccessToEdit = HasAccessTo(EMPLOYER, EDIT);

  return (
    <RegulationsPanelForm
      ownerId={employer?.ownerId}
      employerId={employer?.employerId}
      products={products}
      productsLoading={loading}
      editable={disabledIfNoAccessToEdit}
      withRegulationsEmployerScales
    />
  );
};
