import React from 'react';
import { EmployeeListingType } from './EmployeeListingType';
import { useFormikContext } from 'formik';
import FileUploadAreaWithTemplate from '../../../../../components/FileUploadAreaWithTemplate';
import { orderBy, parseDate, saveFile, startCase } from '../../../../../utils';
import { IntlMessage } from '../../../../../components/Intl';
import InputFieldFactory from '../../../../../components/FormikField/Factory/InputFieldFactory';
import ActionButton from '../../../../../components/ActionButton';
import { FileType } from '../../../../../components';
import DetailsPanel from '../../../../../components/DetailsPanel';
import { EmployeeListFileStrategy } from './EmployeeListFileStrategy';
import { ListFile } from '../../../../../components/FileUploadAreaWithTemplate/ListFile';
import { useIntl } from 'react-intl';

const listValueToTypeMap = Object.keys(EmployeeListingType).reduce((m, k) => {
  const value = EmployeeListingType[k];
  m[value] = k;
  return m;
}, {});

const EmployeesListing = ({
  typeField,
  listField,
  filesLog,
  onChangeType,
  onChangeList,
  initialFiles,
  onFilesChange,
  loading = false,
}) => {
  const intl = useIntl();
  const { setFieldValue, setFieldTouched } = useFormikContext() || {};

  const [showDropArea, setShowDropArea] = React.useState(typeField?.defaultValue !== EmployeeListingType.None);
  const [listType, setListType] = React.useState(typeField?.defaultValue || null);

  const listName = (listValueToTypeMap[listType] || '').toLowerCase();

  const headers = React.useMemo(() => {
    return (listField.items || []).map(item => item.element);
  }, [listField]);

  const headerKeys = React.useMemo(() => {
    return (listField.items || []).map(item => item.value.fieldName);
  }, [listField]);

  const changeType = React.useCallback(
    data => {
      const { value } = data;
      setShowDropArea(value !== EmployeeListingType.None);
      setListType(value);
      onChangeType && onChangeType({ value, setFieldValue, setFieldTouched });
    },
    [onChangeType, setFieldTouched, setFieldValue],
  );

  const downloadFile = React.useCallback(
    file => {
      const employeeListFileStrategy = new EmployeeListFileStrategy(intl);
      const listFile = new ListFile(headers, employeeListFileStrategy);

      const sheet = listFile.generateDataSheet(file.data || [], headerKeys);
      const blob = listFile.sheetToCsv(sheet, { FS: ';', forceQuotes: true });
      saveFile(blob, file.name);
    },
    [headerKeys, headers, intl],
  );

  const sortedFiles = React.useMemo(() => {
    const filtered = (filesLog || [])
      .filter(
        f =>
          f.listedEmployeesType !== EmployeeListingType.None &&
          (!listType || listType === EmployeeListingType.None || f.listedEmployeesType === listType),
      )
      .map((f, i) => {
        const date = parseDate(f.createdAt);
        const sheetName = startCase(listValueToTypeMap[f.listedEmployeesType] || '');
        const name = `${date.toISOString().split('T')[0]}_${sheetName}_${i}.csv`;
        return { created: date, name: name, data: f.listedEmployees, sheetName: sheetName };
      });
    return orderBy(filtered, ['created'], ['desc']);
  }, [filesLog, listType]);

  const columns = React.useMemo(() => {
    const employeeListFileStrategy = new EmployeeListFileStrategy(intl);
    const listFile = new ListFile(headers, employeeListFileStrategy);

    return [
      {
        title: <IntlMessage value={'onboarding.list.upload'} />,
        subtitle: <IntlMessage value={'onboarding.list.upload.description'} />,
        rows: [
          <InputFieldFactory key={0} field={typeField} onChange={changeType} />,
          <FileUploadAreaWithTemplate
            key={0}
            title={`onboarding.list.template.download.${listName}`}
            fileName={listName}
            allowedFileTypes={[FileType.Csv, FileType.Excel]}
            initialFiles={initialFiles}
            onFilesChange={onFilesChange}
            onChangeList={onChangeList}
            loading={loading}
            showDropArea={showDropArea}
            initialValues={listField.items}
            listFile={listFile}
          />,
        ],
      },
      {
        title: <IntlMessage value={'onboarding.list.uploaded'} />,
        rows: sortedFiles.map(file => {
          return <ActionButton key={file} variant={'text'} messageId={file.name} onClick={() => downloadFile(file)} />;
        }),
      },
    ];
  }, [
    intl,
    headers,
    typeField,
    changeType,
    listName,
    initialFiles,
    onFilesChange,
    onChangeList,
    loading,
    showDropArea,
    listField.items,
    sortedFiles,
    downloadFile,
  ]);

  return <DetailsPanel titleVariant={'subtitle1'} columns={columns} divider={true} />;
};

export default EmployeesListing;
