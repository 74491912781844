import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { DivContainerStyled } from './ReportSelectItemElement.styles';

type ReportSelectItemElementProps = {
  titleMessageId: string;
  descriptionMessageId: string;
};

export const ReportSelectItemElement = ({ titleMessageId, descriptionMessageId }: ReportSelectItemElementProps) => {
  return (
    <DivContainerStyled>
      <Typography sx={{ fontWeight: 650, lineHeight: '1.2em', padding: '5px 0' }}>
        <FormattedMessage id={titleMessageId} />
      </Typography>
      <Typography>
        <FormattedMessage id={descriptionMessageId} />
      </Typography>
    </DivContainerStyled>
  );
};
