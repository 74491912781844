import { styled } from '@mui/material/styles';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const DivRowStyled = styled('div', {
  name: 'DivRowStyled',
})(() => ({
  height: '42px',
  display: 'flex',
  alignItems: 'center',
}));

export const SpanSpacerStyled = styled('span', {
  name: 'SpanSpacerStyled',
})(() => ({
  flexGrow: 1,
}));
