import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { MissingDetail } from 'app/types';

import { TranslationKeys } from 'app/translations';

import formatter from 'app/utils/formatter';

import type {
  FilterableTableColumnType,
  FilterableTableOnSortChangeFunc,
  FilterableTablePaginationType,
  FilterableTableRowMapperFunc,
  FilterTableOnPaginationChangeFunc,
  KebabMenuItem,
} from 'app/components';
import { ActionLink, FilterableTable, KebabMenu } from 'app/components';

export const MISSING_DETAILS_TABLE_DATA_TEST_ID = 'missing-details-table-component';

enum TableColumns {
  Connection = 'connectionName',
  LatestRun = 'latestRun',
  CreatedAt = 'createdAt',
  Actions = 'col_actions',
}

type MissingDetailsTableProps = {
  isLoading?: boolean;
  missingDetails: MissingDetail[];
  pagination: FilterableTablePaginationType;
  onPaginationChange: FilterTableOnPaginationChangeFunc;
  onSortChange: FilterableTableOnSortChangeFunc;
  onDownloadActionClick: (missingDetail: MissingDetail) => void;
};

export const MissingDetailsTable = ({
  missingDetails,
  pagination,
  onPaginationChange,
  onSortChange,
  onDownloadActionClick,
  isLoading = false,
}: MissingDetailsTableProps) => {
  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.Connection,
        title: <FormattedMessage id={TranslationKeys.global_connection} />,
      },
      {
        name: TableColumns.LatestRun,
        title: <FormattedMessage id={TranslationKeys.connection_latestRun} />,
      },
      {
        name: TableColumns.CreatedAt,
        title: <FormattedMessage id={TranslationKeys.connections_createdAt} />,
        sortable: true,
      },
      {
        name: TableColumns.Actions,
        isActionsColumn: true,
      },
    ];
  }, []);

  const buildRowActions = React.useCallback(
    (data: MissingDetail) => {
      const actions: Array<KebabMenuItem> = [
        {
          element: <FormattedMessage id={TranslationKeys.files_download} />,
          onClick: () => onDownloadActionClick(data),
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [onDownloadActionClick],
  );

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<MissingDetail>>(
    data => {
      return {
        data: {
          [TableColumns.Connection]: (
            <ActionLink to={`/employers/${data.employerId}/connections/${data.connectionId}`}>
              {data.connectionName}
            </ActionLink>
          ),
          [TableColumns.LatestRun]: formatter.date(data.latestRun),
          [TableColumns.CreatedAt]: formatter.date(data.createdAt),
          [TableColumns.Actions]: buildRowActions(data),
        },
      };
    },
    [buildRowActions],
  );

  return (
    <div data-testid={MISSING_DETAILS_TABLE_DATA_TEST_ID}>
      <FilterableTable<MissingDetail>
        loading={isLoading}
        columns={tableHeaders}
        rows={missingDetails}
        rowMapper={tableRowMapper}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
      />
    </div>
  );
};
