import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@mui/material/Skeleton';
import { formatName } from 'app/utils/formatter/nameFormatter';
import { formatDate, formatDateTime, formatEvent } from 'app/utils/formatter/dateTimeFormatter';
import { formatYesNo, formatYesNoEmpty } from 'app/utils/formatter/booleanFormatter';
import { useConnectionEventTypeSelectItems } from '../../../../../../hooks/useConnectionEventTypeSelectItems';
import { ActionLink } from '../../../../../../components';
import { useParams } from 'react-router-dom';

const EventsTable = props => {
  const {
    className,
    events,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    selectedRowsIds,
    handleToggleEvent,
    handleToggleAllEvents,
    handleShowDetails,
  } = props;

  const { employerId } = useParams();

  const { connectionEventTypes } = useConnectionEventTypeSelectItems();

  return (
    <Card className={className}>
      <CardContent sx={{ padding: 0 }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormHelperText>
                      <FormattedMessage id="events.selectAllInPage" />
                    </FormHelperText>
                    <Checkbox
                      onClick={() => handleToggleAllEvents(events)}
                      checked={
                        events.filter(item => selectedRowsIds.includes(item.mongoId)).length === events.length &&
                        events.length !== 0
                      }
                      indeterminate={
                        events.filter(item => selectedRowsIds.includes(item.mongoId)).length !== events.length &&
                        events.filter(item => selectedRowsIds.includes(item.mongoId)).length !== 0
                      }
                      disabled={events.length === 0}
                      inputProps={{ 'aria-label': 'all items selected' }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.type" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.details" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.employee" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.archived" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.createdAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.periodStart" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.periodEnd" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="events.correction" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  events.map(event => (
                    <TableRow hover key={event.mongoId}>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={selectedRowsIds.includes(event.mongoId)}
                          onChange={() => handleToggleEvent(event)}
                        />
                      </TableCell>
                      <TableCell>
                        <Button variant="text" onClick={() => handleShowDetails(event)}>
                          {connectionEventTypes.find(item => item.value === event.eventType)?.element || '-'}
                        </Button>
                      </TableCell>
                      <TableCell>{formatEvent(event)}</TableCell>
                      <TableCell>
                        <ActionLink
                          to={`/employers/${employerId}/employees/${event.hrEmployee.mongoId}`}
                          target={'_blank'}
                        >
                          {formatName(event.hrEmployee.personName)}
                        </ActionLink>
                      </TableCell>
                      <TableCell>{formatYesNo(event.archived)}</TableCell>
                      <TableCell>{formatDateTime(event.createdAt)}</TableCell>
                      <TableCell>{formatDate(event.periodStart)}</TableCell>
                      <TableCell>{formatDate(event.periodEnd)}</TableCell>
                      <TableCell>{formatYesNoEmpty(event.correction)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <TablePagination
          component="div"
          count={totalCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[100, 500, 1000]}
        />
      </CardActions>
    </Card>
  );
};

EventsTable.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array.isRequired,
};

export default EventsTable;
