import React from 'react';

import { Badge, Box, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';

import { StoreSelectors, useStoreSelector } from '../../../../store';

import { ProfileMenu } from './components';

import { AppBarStyled, hiddenLgUp, hiddenMdDown, iconStyles, ToolbarStyled } from './TopBar.styles';

type TopBarProps = {
  className?: string;
  onSidebarOpen: () => void;
};

export const TopBar = ({ className, onSidebarOpen }: TopBarProps) => {
  const { loading } = useStoreSelector(state => state.AppReducer);
  const platformThemeSettings = useStoreSelector(state => {
    return StoreSelectors.AppSelector.selectThemeSettingsLogo(state.AppReducer);
  });

  const [notifications] = React.useState([]);

  return (
    <AppBarStyled className={className} style={{ backgroundImage: `url(${platformThemeSettings})` }}>
      <ToolbarStyled>
        <Box sx={{ flexGrow: 1 }} />
        {loading && <CircularProgress color="secondary" />}
        {notifications.length > 0 && (
          <IconButton size={'large'} sx={hiddenMdDown}>
            <Badge badgeContent={notifications.length} color="error" variant="dot" overlap={'rectangular'}>
              <NotificationsIcon sx={iconStyles} />
            </Badge>
          </IconButton>
        )}
        <Box sx={hiddenMdDown}>
          <ProfileMenu />
        </Box>
        <IconButton size={'large'} sx={hiddenLgUp} onClick={onSidebarOpen}>
          <MenuIcon sx={iconStyles} />
        </IconButton>
      </ToolbarStyled>
    </AppBarStyled>
  );
};
