import React from 'react';

import type { CheckboxProps as MuiCheckboxProps, FormControlLabelProps } from '@mui/material';
import { FormControlLabel, FormHelperText } from '@mui/material';

import { MuiCheckboxStyled } from './Checkbox.styles';

type BaseCheckbox = {
  name: string;
  checked: boolean;
  className?: string;
  onChange?: MuiCheckboxProps['onChange'];
  disabled?: boolean;
  error?: string | React.ReactNode;
};

type CheckboxWithLabel = BaseCheckbox & {
  label: string | React.ReactNode;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
};

type CheckboxWithoutLabel = BaseCheckbox & {
  label?: never;
  labelPlacement?: never;
};

type CheckboxProps = CheckboxWithLabel | CheckboxWithoutLabel;

export const Checkbox = ({
  className,
  name,
  checked,
  label,
  labelPlacement,
  onChange,
  disabled = false,
  error,
}: CheckboxProps) => {
  const TheCheckbox = React.useMemo(() => {
    return <MuiCheckboxStyled name={name} checked={checked} onChange={onChange} disabled={disabled} />;
  }, [checked, disabled, name, onChange]);

  return (
    <div className={className}>
      {!!label && <FormControlLabel label={label} labelPlacement={labelPlacement} control={TheCheckbox} />}
      {!label && TheCheckbox}
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};
