import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';
import { Outlet } from 'react-router';
import Freshdesk from '../Freshdesk/Freshdesk';
import { HasAccessTo } from '../Authorization';
import { WIDGET } from '../../common/Authorization/entities';
import { SUPPORT } from '../../common/Authorization/permissions';

const RouteWithLayout = props => {
  const { layout: Layout, secure } = props;
  const { loggedIn } = useSelector(state => state.AppReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isCustomerSupportEnable = HasAccessTo(WIDGET, SUPPORT);

  useEffect(() => {
    if (!loggedIn && secure) {
      dispatch(AppActions.setInitialPath(location.pathname));
      navigate('/sign-in');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  if (!loggedIn && secure) {
    return null;
  }

  return (
    <Layout>
      <Outlet />
      {isCustomerSupportEnable && <Freshdesk />}
    </Layout>
  );
};

RouteWithLayout.propTypes = {
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  secure: PropTypes.bool,
};

export default RouteWithLayout;
