import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { actions as AppActions } from 'app/Domain/App/Ducks/App.duck';
import { actions as EmployerActions, selectors } from '../../Ducks/Employer.duck';
import employerService from '../../Services/EmployerService';
import { ConfirmDialog } from 'app/components/ConfirmDialog';
import { InfoDialog } from 'app/components/InfoDialog';
import { normalizeError } from 'app/utils';
import { MESSAGE_SEVERITY_ERROR } from '../../../../common';
import TabOutlet from 'app/components/TabOutlet';
import { IsOwnerAdmin } from 'app/components/Authorization';
import { NotFoundView } from '../../../../views';
import { useDialog } from '../../../../hooks';
import { TranslationKeys } from '../../../../translations';
import { DivHeaderActionsStyled, TypographyStyled } from './EmployerDetailPage.styles';
import { ActionButton } from '../../../../components';

let employerToDelete = null;

const EmployerDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employerId } = useParams();
  const { item: employer, loaded, loading } = useSelector(state => selectors.selectEmployer(state.EmployerReducer));

  const {
    dialogState: showDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog,
    openDialog: openDeleteConfirmationDialog,
  } = useDialog();

  const {
    dialogState: showInfoHasConnectionsDialog,
    closeDialog: closeInfoHasConnectionsDialog,
    openDialog: openInfoHasConnectionsDialog,
  } = useDialog();

  const disabledIfNotOwner = !IsOwnerAdmin();

  useEffect(() => {
    if (!employer || employer.employerId !== employerId) {
      dispatch(EmployerActions.employer.requestData({ employerId }));
    }
  }, [employer, dispatch, employerId]);

  const tabData = useMemo(() => {
    return [
      {
        path: 'info',
        label: <FormattedMessage id={TranslationKeys.employers_tab_general} />,
      },
      {
        path: 'summary',
        label: <FormattedMessage id={TranslationKeys.employers_tab_summary} />,
      },
      {
        path: 'employees',
        label: <FormattedMessage id={TranslationKeys.employers_tab_employees} />,
      },
      {
        path: 'files',
        label: <FormattedMessage id={TranslationKeys.employers_tab_files} />,
      },
      {
        path: 'legal',
        label: <FormattedMessage id={TranslationKeys.employers_tab_legal} />,
        disabled: true,
      },
    ];
  }, []);

  const removeEmployer = useCallback(async () => {
    if (employerToDelete) {
      employerService
        .deleteEmployer(employerToDelete)
        .then(() => {
          navigate('/employers');
        })
        .catch(err => {
          const errors = normalizeError(err);
          const severity = MESSAGE_SEVERITY_ERROR;
          const details = {
            method: 'deleteEmployer',
            severity,
            message: errors.message,
          };
          dispatch(AppActions.displayMessage(details));
        });
    }
  }, [dispatch, navigate]);

  const handleClickDelete = useCallback(() => {
    const hasConnections = employer?.connections?.length === 0;
    employerToDelete = employerId;
    hasConnections ? openDeleteConfirmationDialog() : openInfoHasConnectionsDialog();
  }, [employer?.connections?.length, employerId, openDeleteConfirmationDialog, openInfoHasConnectionsDialog]);

  const title = useMemo(() => {
    if (employer) {
      return (
        <>
          <Typography variant="h5">{employer.employerName}</Typography>
          <TypographyStyled variant="h6">
            <FormattedMessage id={`employers.${employer.status}`} />
          </TypographyStyled>
        </>
      );
    }

    return (
      <>
        <Typography variant="h5">
          <Skeleton variant={'text'} width={200} />
        </Typography>
        <Typography variant="h6">
          <Skeleton variant={'text'} width={30} />
        </Typography>
      </>
    );
  }, [employer]);

  const deleteButton = useMemo(() => {
    return (
      <DivHeaderActionsStyled>
        <ActionButton
          sx={{ minHeight: '35px', minWidth: '200px', padding: 0, zIndex: 1000 }}
          messageId={TranslationKeys.employers_delete}
          onClick={handleClickDelete}
          disabled={disabledIfNotOwner}
          variant={'outlined'}
        />
      </DivHeaderActionsStyled>
    );
  }, [handleClickDelete, disabledIfNotOwner]);

  const breadcrumb = useMemo(() => {
    return {
      title: employer?.employerName,
      data: {
        titleElement: title,
        otherElements: deleteButton,
      },
    };
  }, [employer, title, deleteButton]);

  const exists = loading || (loaded && !!employer);

  return exists ? (
    <TabOutlet tabs={tabData} breadcrumb={breadcrumb}>
      {showDeleteConfirmationDialog && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.employers_confirmDeleteTitle} />}
          open={showDeleteConfirmationDialog}
          onClose={closeDeleteConfirmationDialog}
          onConfirm={removeEmployer}
        >
          <FormattedMessage id={TranslationKeys.employers_confirmDeleteMessage} />
        </ConfirmDialog>
      )}

      {showInfoHasConnectionsDialog && (
        <InfoDialog
          title={<FormattedMessage id={TranslationKeys.employers_confirmDeleteTitle} />}
          open={showInfoHasConnectionsDialog}
          onClose={closeInfoHasConnectionsDialog}
          type="warning"
        >
          <Typography>
            <FormattedMessage id={TranslationKeys.employers_hasConnections} />
          </Typography>
        </InfoDialog>
      )}
    </TabOutlet>
  ) : (
    <Routes>
      <Route element={<NotFoundView />} path="*" />
    </Routes>
  );
};

export default EmployerDetailPage;
