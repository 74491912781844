import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { actions as ConnectionActions } from '../../../Ducks/Connection.duck';
import { actions as DownloadsActions } from '../../../Ducks/Downloads.duck';
import Grid from '@mui/material/Grid';
import DownloadsTable from '../../../Packages/InsuranceBrydge/Components/DownloadsTable';
import { useBreadcrumb } from 'app/components/Breadcrumbs';

const DownloadsTab = () => {
  const dispatch = useDispatch();
  const { connectionId } = useParams();
  const { items, totalCount } = useSelector(state => state.DownloadsReducer);
  const { loading } = useSelector(state => state.ConnectionReducer);

  useEffect(() => {
    dispatch(ConnectionActions.requestData(connectionId));
    dispatch(DownloadsActions.setConnectionId(connectionId));
    dispatch(DownloadsActions.requestData(connectionId));
  }, [connectionId, dispatch]);

  const downloadFile = filePath => {
    dispatch(DownloadsActions.downloadFile(filePath));
  };

  const deleteFile = filePath => {
    dispatch(DownloadsActions.deleteFile(filePath));
  };

  useBreadcrumb('button.downloads');

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <DownloadsTable
            files={items}
            totalCount={totalCount}
            loading={loading}
            downloadFile={downloadFile}
            deleteFile={deleteFile}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DownloadsTab;
