import { useMutation } from '@tanstack/react-query';

import type { EditUserArgs } from '../../../Domain/Users/Services';
import { userService } from '../../../Domain/Users/Services';

const mutationFn = (data: EditUserArgs) => {
  return userService.editUser(data);
};

export const useUserUpdateMutation = () => {
  return useMutation<unknown, unknown, EditUserArgs>(mutationFn);
};
