import { takeLatest } from 'redux-saga/effects';

export const singleObjectInitialState = {
  item: undefined,
  loading: true,
  loaded: false,
  error: false,
  sortBy: {},
};

export const singleObjectMutators = {
  fulfilled: (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      item: action.payload,
      error: false,
    };
  },
  requestData: state => {
    return {
      ...state,
      loading: true,
      error: false,
    };
  },
  resetData: state => {
    return {
      ...state,
      loading: false,
      loaded: false,
      item: {},
    };
  },
  error: (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      item: undefined,
      error: action.payload,
    };
  },
  sortTable: (state, action) => {
    return {
      ...state,
      sortBy: action.sortBy,
      loading: true,
      error: false,
    };
  },
};

export const singleObjectAction = (actionTypes, state, action) => {
  switch (action.type) {
    case actionTypes.FulfilledData: {
      return singleObjectMutators.fulfilled(state, action);
    }
    case actionTypes.RequestData: {
      return singleObjectMutators.requestData(state, action);
    }
    case actionTypes.ResetData: {
      return singleObjectMutators.resetData(state, action);
    }
    case actionTypes.Error: {
      return singleObjectMutators.error(state, action);
    }
    case actionTypes.SortTable: {
      return singleObjectMutators.sortTable(state, action);
    }
    default:
      return state;
  }
};

export const singleObjectActions = (actionTypes, additionalActions = {}) => {
  return {
    resetData: () => ({ type: actionTypes.ResetData }),
    requestData: payload => ({
      type: actionTypes.RequestData,
      payload,
    }),
    fulfilledData: payload => ({
      type: actionTypes.FulfilledData,
      payload,
    }),
    error: payload => ({
      type: actionTypes.Error,
      payload,
    }),
    sortTable: sortBy => ({
      type: actionTypes.SortTable,
      sortBy,
    }),
    ...additionalActions,
  };
};

export const generateActionTypes = (prefix, additional = {}) => {
  const actionTypes = {
    RequestData: `[${prefix}] Request`,
    FulfilledData: `[${prefix}] Fulfilled`,
    Error: `[${prefix}] Error`,
    Reset: `[${prefix}] Reset`,
    SortTable: `[${prefix}] Sort`,
  };

  Object.keys(additional || {}).forEach(key => {
    actionTypes[key] = `[${prefix}] ${additional[key]}`;
  });

  return actionTypes;
};

export function* tableSagas(types, requestWorker) {
  return [yield takeLatest(types.SortTable, requestWorker)];
}
