import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { isEmpty, normalizeError } from 'app/utils';
import { deleteConnection, getEventsCount, getLogsCount, getUserConnections, onboardingService } from '../Services';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from 'app/Domain/App/Ducks/App.duck';
import {
  generateActionTypes as generateSingleActionTypes,
  singleObjectAction,
  singleObjectActions,
  singleObjectInitialState,
} from 'app/Domain/App/Ducks/SingleObject.duck';
import { createSelector } from 'reselect';

export const actionTypes = {
  InitConnectionState: '[Connections] Initialize',
  RequestData: '[Connections] Request',
  FulfilledTable: '[Connections] Fulfilled',
  SearchTable: '[Connections] Search',
  ChangePage: '[Connections] change page',
  SetPageSize: '[Connections] set page size',
  SortTable: '[Connections] Sort',
  ApplyFilter: '[Connections] Apply filter',
  RemoveFilter: '[Connections] Remove filter',
  SetSelectedRow: '[Connections] Set selected row',
  AddTenant2SelectedRow: '[Connections] Add tenant to selected row tenants',
  RemoveTenantFromSelectedRow: '[Connections] Remove tenant from selected row tenants',
  DeleteOneConnection: '[Connections] Delete a connection',
  FulfilledEventsAndLogsCount: '[Connections] Fulfilled logs count',
  PackageSettingsDetails: generateSingleActionTypes('Connections.PackageSettingsDetails'),
};

const initialState = {
  ...tableInitialState,
  packageSettingsDetails: { ...singleObjectInitialState },
  selectedRowTenants: [],
  logsCount: [],
  eventsCount: [],
};

export const reducer = (state = initialState, action) => {
  if (action.type.startsWith('[Connections.PackageSettingsDetails]')) {
    return {
      ...state,
      packageSettingsDetails: singleObjectAction(
        actionTypes.PackageSettingsDetails,
        state.packageSettingsDetails,
        action,
      ),
    };
  }

  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.InitConnectionState: {
      return { ...initialState };
    }
    case actionTypes.SetSelectedRow: {
      return {
        ...state,
        selectedRow: action.selectedRow,
      };
    }
    case actionTypes.FulfilledEventsAndLogsCount: {
      return {
        ...state,
        loading: false,
        loaded: true,
        logsCount: action.logsCount,
        eventsCount: action.eventsCount,
      };
    }
    case actionTypes.DeleteOneConnection: {
      return { ...state };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  packageSettingsDetails: singleObjectActions(actionTypes.PackageSettingsDetails),
  initConnectionPage: () => ({
    type: actionTypes.InitConnectionState,
  }),
  deleteOneConnection: () => {
    return {
      type: actionTypes.DeleteOneConnection,
    };
  },
  fulfilledEventsAndLogsCount: (logsCount, eventsCount) => ({
    type: actionTypes.FulfilledEventsAndLogsCount,
    logsCount,
    eventsCount,
  }),
};

export const selectors = {
  selectPackageSettingsDetails: createSelector(
    state => state.packageSettingsDetails,
    packageSettingsDetails => packageSettingsDetails,
  ),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestConnections() {
    try {
      const currentState = yield select(state => {
        return state.ConnectionsReducer;
      });

      const userId = yield select(state => {
        return state.AppReducer.user.userId;
      });

      const response = yield getUserConnections(
        userId,
        currentState.page,
        currentState.pageSize,
        currentState.sortBy,
        currentState.filters,
        currentState.searchQuery,
      );

      const ids = response.data['hydra:member'].map(conn => conn.connectionId);

      if (!isEmpty(ids)) {
        try {
          const [logsCount, eventsCount] = yield all([getLogsCount(ids), getEventsCount(ids)]);
          yield put(actions.fulfilledEventsAndLogsCount(logsCount.data, eventsCount.data));
        } catch (error) {
          const severity = MESSAGE_SEVERITY_ERROR;
          const details = {
            method: 'getLogsAndEventsCount',
            severity,
          };
          yield put(AppActions.displayMessage(details));
        }
      }
      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getConnections',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.DeleteOneConnection, function* deleteOneConnection() {
    const currentState = yield select(state => {
      return state.ConnectionsReducer;
    });
    yield deleteConnection(currentState.selectedRow.connectionId);
    yield put(actions.requestData());
  });

  yield takeLatest(
    actionTypes.PackageSettingsDetails.RequestData,
    function* requestPackageSettingsDetailsSaga({ payload }) {
      const { packageType, productId, connectionId, onError, onSuccess } = payload;

      try {
        const response = yield call(onboardingService.getPackageSettingsDetails, {
          packageType,
          productId,
          connectionId,
        });
        yield put(actions.packageSettingsDetails.fulfilledData(response.data['hydra:member']));
        yield onSuccess && onSuccess(response.data);
      } catch (error) {
        const errorData = normalizeError(error);
        yield put(actions.packageSettingsDetails.error(error));
        yield put(
          AppActions.displayError({
            method: 'getPackageSettingsDetails',
          }),
        );
        yield onError && onError(errorData);
      }
    },
  );
}
