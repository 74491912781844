import React from 'react';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import MuiTextField from '@mui/material/TextField';
import type { FieldProps } from 'formik';
import { getIn } from 'formik';

export const TEXT_FIELD_INPUT_DATA_TEST_ID = 'text-field-input';

export interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {
  hideErrorText?: boolean;
}

export function TextField({
  children,
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  hideErrorText,
  ...props
}: TextFieldProps) {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  const fieldHelperText = React.useMemo(() => {
    if (hideErrorText) {
      return undefined;
    }
    if (showError) {
      return fieldError;
    }

    return helperText;
  }, [fieldError, helperText, hideErrorText, showError]);

  const fieldOnBlurHandler = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      fieldOnBlur(event ?? field.name);
    },
    [field.name, fieldOnBlur],
  );

  return (
    <MuiTextField
      error={showError}
      helperText={fieldHelperText}
      disabled={disabled ?? isSubmitting}
      onBlur={onBlur ?? fieldOnBlurHandler}
      {...field}
      {...props}
      inputProps={{
        ...(props?.inputProps || {}),
        'data-testid': TEXT_FIELD_INPUT_DATA_TEST_ID,
      }}
    >
      {children}
    </MuiTextField>
  );
}

TextField.displayName = 'FormikMaterialUITextField';
