import type { Report, ReportEmployer, ReportEmployerConnection } from '../../../../types/report.types';

const flattenEmployers = (employers: Array<ReportEmployer>) => {
  return (employers || []).reduce<Array<ReportEmployer>>((acc, employer) => {
    acc.push(employer);

    if (employer.subEmployers?.length) {
      employer.subEmployers.forEach(subEmployer => {
        acc.push(subEmployer);
      });
    }

    return acc;
  }, []);
};

const sortAscByName = (
  elementA: ReportEmployer | ReportEmployerConnection,
  elementB: ReportEmployer | ReportEmployerConnection,
) => {
  if (elementA.name < elementB.name) {
    return -1;
  }
  if (elementA.name > elementB.name) {
    return 1;
  }
  return 0;
};

export const normalizeReportData = (data: Report | undefined) => {
  const reportData: Array<ReportEmployer> = [];

  if (data?.employers?.length) {
    reportData.push(...flattenEmployers(data.employers));
  }

  if (data?.subOwners?.length) {
    data.subOwners.forEach(subOwner => {
      reportData.push(...flattenEmployers(subOwner.employers));
    });
  }

  return reportData
    .map(employer => {
      employer.connections.sort(sortAscByName);
      return employer;
    })
    .sort(sortAscByName);
};
