import React from 'react';
import type { Employee, Employer } from 'app/types';
import { useEmployeeCreateNotesMutation, useEmployeeGetNotesQuery, useEmployeeUpdateNotesMutation } from 'app/hooks';
import { HasAccessTo, NotesInput } from 'app/components';

import { EMPLOYER } from 'app/common/Authorization/entities';
import { EDIT } from 'app/common/Authorization/permissions';

type EmployeeNotesProps = {
  employerId: Employer['employerId'] | undefined;
  employeeId: Employee['employeeId'] | undefined;
  isLoading: boolean;
};

export const EmployeeNotes = ({ employerId, employeeId, isLoading }: EmployeeNotesProps) => {
  const { mutate: createNotesMutation, isLoading: isCreating } = useEmployeeCreateNotesMutation();
  const { mutate: updateNotesMutation, isLoading: isUpdating } = useEmployeeUpdateNotesMutation();

  const {
    data: note,
    isLoading: getNotesQueryLoading,
    isFetching: getNotesQueryFetching,
    refetch: refetchGetNotesQuery,
  } = useEmployeeGetNotesQuery({
    variables: {
      employerId,
      employeeId,
    },
    options: {
      enabled: !!employerId && !!employeeId,
    },
  });

  const isGetNotesQueryLoading = getNotesQueryLoading || getNotesQueryFetching;
  const loading = isLoading || isGetNotesQueryLoading || isCreating || isUpdating;
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);

  const onSaveNote = React.useCallback(
    (noteText: string) => {
      if (!employerId || !employeeId) {
        return;
      }

      const onSuccess = () => refetchGetNotesQuery();

      const commonData = {
        employerId,
        employeeId,
        noteText,
      };

      if (note?.noteId) {
        updateNotesMutation(
          {
            ...commonData,
            noteId: note.noteId,
          },
          { onSuccess },
        );
      } else {
        createNotesMutation(commonData, { onSuccess });
      }
    },
    [createNotesMutation, employeeId, employerId, note, refetchGetNotesQuery, updateNotesMutation],
  );

  return (
    <NotesInput
      isLoading={loading}
      inputValue={note?.text || ''}
      onSaveNote={onSaveNote}
      isNotAllowed={disabledIfNoAccessToEmployerEdit}
    />
  );
};
