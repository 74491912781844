import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material';

import { ActionButton } from '../../../../../components/ActionButton';

export const DivTableContainerStyled = styled('div', {
  name: 'DivTableContainerStyled',
})(({ theme }) => ({
  [`& .${tableCellClasses.root}`]: {
    padding: theme.spacing(1),
  },
}));

export const DivButtonsContainerStyled = styled('div', {
  name: 'DivButtonsContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(2),
}));

export const ActionButtonStyled = styled(ActionButton, {
  name: 'ActionButtonStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
