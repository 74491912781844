const MutationTypes = {
  'Databrydge\\Synchronizer\\Domain\\Package\\BrandNewDaySoap\\Model\\NewParticipation': 'New Participation',
  'Databrydge\\Synchronizer\\Domain\\Package\\BrandNewDaySoap\\Model\\EmploymentUpdateTerm': 'Employment Update Term',
  'Databrydge\\Synchronizer\\Domain\\Package\\BrandNewDaySoap\\Model\\EmploymentEnd': 'Employment End',
  'Databrydge\\Synchronizer\\Domain\\Package\\BrandNewDaySoap\\Model\\ReEmployment': 'Re Employment',
  'Databrydge\\Synchronizer\\Domain\\Package\\BrandNewDay\\Model\\ExportedMutation': 'Exported Mutation',
  'Databrydge\\Synchronizer\\Domain\\Package\\Amersfoortse\\Model\\ExportedMutation': 'Exported Mutation',
  'Databrydge\\Synchronizer\\Domain\\Package\\NationaleNederlanden\\Model\\EmploymentEnd': 'Employment End',
  'Databrydge\\Synchronizer\\Domain\\Package\\NationaleNederlanden\\Model\\EmploymentNew': 'Employment New',
  'Databrydge\\Synchronizer\\Domain\\Package\\NationaleNederlanden\\Model\\SalaryChange': 'Salary Change',
  'Databrydge\\Synchronizer\\Domain\\Package\\NationaleNederlanden\\Model\\WagesChange': 'Wages Change',
  'Databrydge\\Synchronizer\\Domain\\Package\\NationaleNederlanden\\Model\\ParttimeChange': 'Part Time Change',
} as const;

type MutationTypeKeys = keyof typeof MutationTypes;

export const formatMutationType = (mutationType: MutationTypeKeys) => {
  return MutationTypes[mutationType];
};
