import type { FuncOverrideComponent } from '../theme.types';

export const MuiDialogTitle: FuncOverrideComponent<'MuiDialogTitle'> = theme => ({
  styleOverrides: {
    root: {
      ...theme.typography.h5,
      lineHeight: 'initial',
      padding: theme.spacing(2.5),
    },
  },
});
