import { useMutation } from '@tanstack/react-query';

import type { UpdateEmployeeEmploymentScheduleArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

const mutationFn = (data: UpdateEmployeeEmploymentScheduleArgs) => {
  return employeeService.updateEmployeeEmploymentSchedule(data);
};

export const useEmployeeUpdateEmploymentScheduleMutation = () => {
  return useMutation<unknown, unknown, UpdateEmployeeEmploymentScheduleArgs>(mutationFn);
};
