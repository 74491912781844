import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Grid, IconButton, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';

import type { User } from '../../../../types';

import { useDialog, useSnakeBar, useUserDeleteMutation, useUserGetUserQuery } from '../../../../hooks';

import { TranslationKeys } from '../../../../translations';

import { useStoreSelector } from '../../../../store';

import { ConfirmDialog, Dialog, useBreadcrumb } from '../../../../components';

import { UserAccessesList, UserDetailsPanel } from './components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUserRequestAPIKeyQuery } from '../../../../hooks/api/user/useUserRequestAPIKeyQuery';
import { ApiKeyDialogBodyStyled, ApiKeyDialogTextStyled } from './UserEditPage.styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const UserEditPage = () => {
  const { userId } = useParams<Pick<User, 'userId'>>();
  const navigate = useNavigate();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: deleteUserMutation } = useUserDeleteMutation();
  const { requestAPIKey } = useUserRequestAPIKeyQuery();

  const intl = useIntl();

  const [apiKey, setApiKey] = React.useState<string | undefined>();

  const {
    dialogState: showConfirmDeleteDialog,
    openDialog: openConfirmDeleteDialog,
    closeDialog: closeConfirmDeleteDialog,
  } = useDialog();

  const { dialogState: showApiKeyDialog, openDialog: openApiKeyDialog, closeDialog: closeApiKeyDialog } = useDialog();

  const loggedInUser = useStoreSelector(state => state.AppReducer.user);
  const isLoggedInUser = loggedInUser.userId === userId;

  const {
    data: user,
    isLoading: isLoadingGetUserQuery,
    isFetching: isFetchingGetUserQuery,
    refetch: refetchGetUserQuery,
  } = useUserGetUserQuery({
    variables: { userId },
    options: { enabled: !!userId },
  });

  const isLoading = isLoadingGetUserQuery || isFetchingGetUserQuery;

  const deleteUser = React.useCallback(() => {
    if (userId) {
      const method = 'deleteUser';

      closeConfirmDeleteDialog();

      deleteUserMutation(
        { userId },
        {
          onSuccess: () => {
            showSuccessSnakeBar({ method });
            navigate('/users');
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error.message });
          },
        },
      );
    }
  }, [closeConfirmDeleteDialog, deleteUserMutation, navigate, showErrorSnakeBar, showSuccessSnakeBar, userId]);

  const onRequestAPIKey = React.useCallback(async () => {
    try {
      if (user?.userId) {
        const response = await requestAPIKey({ userId: user.userId });
        setApiKey(response?.apiKey);
      }
    } catch (error) {
      showErrorSnakeBar({ message: intl.formatMessage({ id: TranslationKeys.users_apiKey_errorMessage }) });
    }
  }, [requestAPIKey, showErrorSnakeBar, user?.userId, intl]);

  const onCopyApiKey = React.useCallback(() => {
    navigator.clipboard.writeText(apiKey || '');
    showSuccessSnakeBar({ message: intl.formatMessage({ id: TranslationKeys.global_copied }) });
  }, [apiKey, showSuccessSnakeBar, intl]);

  const breadcrumbTitleElement = React.useMemo(() => {
    if (isLoadingGetUserQuery) {
      return <Skeleton variant={'text'} width={200} />;
    }

    return <Typography variant="h5">{user?.personName.fullName}</Typography>;
  }, [isLoadingGetUserQuery, user?.personName.fullName]);

  useBreadcrumb(user?.personName?.fullName || '', {
    titleElement: breadcrumbTitleElement,
  });

  return (
    <>
      <Grid container spacing={2} sx={theme => ({ marginTop: theme.spacing(1) })}>
        <Grid item md={4} xs={12}>
          <UserDetailsPanel
            isLoading={isLoading}
            isLoggedInUser={isLoggedInUser}
            user={user}
            refetchGetUserQuery={refetchGetUserQuery}
          />
        </Grid>

        <Grid item md={8} xs={12}>
          <UserAccessesList
            user={user}
            isLoggedInUser={isLoggedInUser}
            openConfirmDeleteDialog={openConfirmDeleteDialog}
            refetchGetUserQuery={refetchGetUserQuery}
            onRequestAPIKey={openApiKeyDialog}
          />
        </Grid>
      </Grid>

      <ConfirmDialog
        title={<FormattedMessage id={TranslationKeys.users_apiKey_confirmDialog_title} />}
        open={showApiKeyDialog}
        onClose={closeApiKeyDialog}
        onConfirm={onRequestAPIKey}
      >
        <FormattedMessage id={TranslationKeys.users_apiKey_confirmDialog_description} />
      </ConfirmDialog>

      {showConfirmDeleteDialog && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.users_confirmDeleteTitle} />}
          open={showConfirmDeleteDialog}
          onClose={closeConfirmDeleteDialog}
          onConfirm={deleteUser}
        >
          <FormattedMessage id={TranslationKeys.users_confirmDeleteMessage} />
        </ConfirmDialog>
      )}
      <Dialog
        open={!!apiKey}
        title={<FormattedMessage id={TranslationKeys.users_apiKey_dialogTitle} />}
        onClose={() => setApiKey(undefined)}
      >
        <Box sx={{ p: 2 }}>
          <ApiKeyDialogBodyStyled>
            <ApiKeyDialogTextStyled>
              <Typography variant="body2">{apiKey}</Typography>
            </ApiKeyDialogTextStyled>
            <IconButton color="primary" onClick={onCopyApiKey}>
              <ContentCopyIcon />
            </IconButton>
          </ApiKeyDialogBodyStyled>
          <Typography variant={'body1'}>
            <FormattedMessage id={TranslationKeys.users_apiKey_storeYourKey} />
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};
