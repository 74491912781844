import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TableCell, TableRow } from '@mui/material';

import { useCivilStatusSelectItems } from '../../../../../../hooks/useCivilStatusSelectItems';
import { useGenderSelectItems } from '../../../../../../hooks/useGenderSelectItems';

import EventContentTable from '../EventContentTable';

type EventContentTableRowProps = {
  header: string;
  value: any;
  renderer?: (value: any, header: string) => any;
};

export const EventContentTableRow = ({ header, value, renderer }: EventContentTableRowProps) => {
  const { getGenderDisplayNameByValue } = useGenderSelectItems();
  const { getCivilStatusDisplayNameByValue } = useCivilStatusSelectItems();

  let content = value;
  if (typeof renderer === 'function') {
    content = renderer(value, header);
  } else if (value && typeof value === 'object') {
    content = <EventContentTable event={value} ignoreKeys={['@key', '@type', '@id']} collapse />;
  }

  if (header === 'gender') {
    content = getGenderDisplayNameByValue(content);
  } else if (header === 'civilStatus') {
    content = getCivilStatusDisplayNameByValue(content);
  } else if (header === 'state') {
    content = <FormattedMessage id={value} />;
  }

  return (
    <TableRow hover>
      <TableCell variant="head">
        {/^\d+$/.test(header) ? header : <FormattedMessage id={`events.content.header.${header}`} />}
      </TableCell>
      <TableCell>{content}</TableCell>
    </TableRow>
  );
};
