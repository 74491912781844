import React, { useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import { ConfirmDialog } from 'app/components';

const Dropdown = props => {
  const { className, options } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const anchorRef = useRef(null);

  function handleClick() {
    setConfirmOpen(true);
  }

  function handleMenuItemClick(event, index) {
    setSelectedIndex(index);
    setConfirmOpen(true);
    setOpen(false);
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  return (
    <div className={className}>
      <ConfirmDialog
        title={options[selectedIndex].confirmDialog.title}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={options[selectedIndex].action}
      >
        {options[selectedIndex].confirmDialog.message}
      </ConfirmDialog>
      <ButtonGroup ref={anchorRef} aria-label="Split button">
        <Tooltip title={options[selectedIndex].disabled ? options[selectedIndex].tooltip : ''}>
          <Button
            variant={'contained'}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              boxShadow: 'unset',
            }}
            onClick={handleClick}
            disabled={options[selectedIndex].disabled}
          >
            {options[selectedIndex].title}
          </Button>
        </Tooltip>
        <Button
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          size="small"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={1} id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <Tooltip key={option.title} title={options[index].disabled ? options[index].tooltip : ''}>
                      <MenuItem
                        key={option.title}
                        disabled={options[index].disabled}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option.title}
                      </MenuItem>
                    </Tooltip>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Dropdown;
