import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { UseQueryWrapper, User } from '../../../types';

import { userService } from '../../../Domain/Users/Services';

import { useSnakeBar } from '../../useSnakeBar';

type GetUserQueryVariables = Partial<Pick<User, 'userId'>>;

export type GetUserQueryResultData = User;

const queryFn = (variables: GetUserQueryVariables) => async () => {
  if (variables?.userId) {
    const response = await userService.getUser(variables.userId);
    return (response as AxiosResponse).data;
  }
};

const QUERY_KEY = 'getUser';

export const useUserGetUserQuery: UseQueryWrapper<GetUserQueryResultData, GetUserQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetUserQueryResultData>([QUERY_KEY, variables?.userId], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
