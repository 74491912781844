import React from 'react';

import {
  MESSAGE_SEVERITY_ERROR,
  MESSAGE_SEVERITY_INFO,
  MESSAGE_SEVERITY_SUCCESS,
  MESSAGE_SEVERITY_WARNING,
} from '../common';

import { StoreActions, useStoreDispatch } from '../store';

const SEVERITY_TYPES = [
  MESSAGE_SEVERITY_SUCCESS,
  MESSAGE_SEVERITY_ERROR,
  MESSAGE_SEVERITY_INFO,
  MESSAGE_SEVERITY_WARNING,
] as const;

type Severity = (typeof SEVERITY_TYPES)[number];

export type ShowSnakeBarParams = {
  method?: string;
  severity: Severity;
  message?: string;
};

type SnakeBarParams = Omit<ShowSnakeBarParams, 'severity'>;

export const useSnakeBar = () => {
  const dispatch = useStoreDispatch();

  const showSnakeBar = React.useCallback(
    ({ method, severity, message }: ShowSnakeBarParams) => {
      dispatch(StoreActions.AppActions.displayMessage({ method, severity, message }));
    },
    [dispatch],
  );

  const showSuccessSnakeBar = React.useCallback(
    ({ method, message }: SnakeBarParams) => {
      showSnakeBar({ severity: MESSAGE_SEVERITY_SUCCESS, method, message });
    },
    [showSnakeBar],
  );

  const showErrorSnakeBar = React.useCallback(
    ({ method, message }: SnakeBarParams) => {
      showSnakeBar({ severity: MESSAGE_SEVERITY_ERROR, method, message });
    },
    [showSnakeBar],
  );

  const showInfoSnakeBar = React.useCallback(
    ({ method, message }: SnakeBarParams) => {
      showSnakeBar({ severity: MESSAGE_SEVERITY_INFO, method, message });
    },
    [showSnakeBar],
  );

  const showWarningSnakeBar = React.useCallback(
    ({ method, message }: SnakeBarParams) => {
      showSnakeBar({ severity: MESSAGE_SEVERITY_WARNING, method, message });
    },
    [showSnakeBar],
  );

  return React.useMemo(
    () => ({
      showSnakeBar,
      showSuccessSnakeBar,
      showErrorSnakeBar,
      showInfoSnakeBar,
      showWarningSnakeBar,
    }),
    [showErrorSnakeBar, showInfoSnakeBar, showSnakeBar, showSuccessSnakeBar, showWarningSnakeBar],
  );
};
