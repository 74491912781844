import type { FuncOverrideComponent } from '../theme.types';

export const MuiButton: FuncOverrideComponent<'MuiButton'> = theme => ({
  styleOverrides: {
    root: {
      minWidth: 100,
      fontWeight: theme.typography.fontWeightRegular,
    },
    text: {
      textTransform: 'initial',
    },
    contained: {
      borderRadius: '18px',
      boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
      textTransform: 'initial',
    },
    outlined: {
      borderRadius: '18px',
      textTransform: 'initial',
    },
  },
});
