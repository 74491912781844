import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ConnectionsListPage from './ConnectionsListPage';

import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { RestrictAccess } from 'app/components/Authorization';
import { CONNECTION } from 'app/common/Authorization/entities';
import { VIEW } from 'app/common/Authorization/permissions';

const ConnectionPages = () => {
  useBreadcrumb('menu.connections');
  return (
    <Routes>
      <Route element={<RestrictAccess entity={CONNECTION} permission={VIEW} />}>
        <Route element={<ConnectionsListPage />} path={'overview'} />
        <Route index element={<Navigate to="overview" replace />} />
        <Route path="*" element={<Navigate to="overview" replace />} />
      </Route>
    </Routes>
  );
};

export default ConnectionPages;
