import React from 'react';
import { useIntl } from 'react-intl';

import type { SummaryScaleTable } from 'app/types';
import { TranslationKeys } from 'app/translations';

import { FileType } from 'app/components';
import FileUploadAreaWithTemplate from 'app/components/FileUploadAreaWithTemplate';
import { ListFile } from 'app/components/FileUploadAreaWithTemplate/ListFile';

import { DivContainer } from './ScalesTableFileUpload.styles';
import {
  SCALES_TABLE_DOWNLOAD_FILE_HEADERS,
  SCALES_TABLE_FILE_STRUCTURE,
  SCALES_TABLE_TEMPLATE_FILE_NAME,
  ScalesTableFileReadStrategy,
} from './utils';
import { SummaryScalesTableFormFields } from '../../ScalesTableForm.types';

export const SCALE_TABLE_FILE_UPLOAD_DATA_TEST_ID = 'scale-table-file-upload-component';

type ScalesTableFileUploadProps = {
  isLoading?: boolean;
  prefillTitleValueOnFileUpload: (fileName: string) => void;
};

const convertToFloat = (value: string | number) => parseFloat(value.toString().replace(',', '.'));

export const ScalesTableFileUpload = ({
  prefillTitleValueOnFileUpload,
  isLoading = false,
}: ScalesTableFileUploadProps) => {
  const intl = useIntl();

  const initialValues = React.useMemo<Array<{ element: string; value: { fieldName: string } }>>(() => {
    return SCALES_TABLE_FILE_STRUCTURE.map(fileStructure => ({
      element: fileStructure.headerTitle,
      value: {
        fieldName: fileStructure.fieldName,
      },
    }));
  }, []);

  const onFilesChange = React.useCallback(
    files => {
      const fileName = (files[0]?.name || '').replace('.csv', '');
      prefillTitleValueOnFileUpload(fileName);
    },
    [prefillTitleValueOnFileUpload],
  );

  const onChangeList = React.useCallback(({ setFieldValue, value }) => {
    const tableWithScales = (value || []).map((row: Required<SummaryScaleTable>, index: number) => {
      return {
        '15-20': convertToFloat(row['15-20']),
        '20-25': convertToFloat(row['20-25']),
        '25-30': convertToFloat(row['25-30']),
        '30-35': convertToFloat(row['30-35']),
        '35-40': convertToFloat(row['35-40']),
        '40-45': convertToFloat(row['40-45']),
        '45-50': convertToFloat(row['45-50']),
        '50-55': convertToFloat(row['50-55']),
        '55-60': convertToFloat(row['55-60']),
        '60-65': convertToFloat(row['60-65']),
        '65-68': convertToFloat(row['65-68']),
      } satisfies SummaryScaleTable;
    });
    setFieldValue(SummaryScalesTableFormFields.AgePercentages.InputName, tableWithScales);
  }, []);

  const scaleTableReadFileStrategy = new ScalesTableFileReadStrategy(intl);
  const listFile = new ListFile(SCALES_TABLE_DOWNLOAD_FILE_HEADERS, scaleTableReadFileStrategy);

  return (
    <DivContainer data-testid={SCALE_TABLE_FILE_UPLOAD_DATA_TEST_ID}>
      <FileUploadAreaWithTemplate
        title={TranslationKeys.global_download_templateFile}
        fileName={SCALES_TABLE_TEMPLATE_FILE_NAME}
        allowedFileTypes={[FileType.Csv]}
        initialFiles={[]}
        onFilesChange={onFilesChange}
        onChangeList={onChangeList}
        loading={isLoading}
        showDropArea={true}
        initialValues={initialValues}
        listFile={listFile}
      />
    </DivContainer>
  );
};
