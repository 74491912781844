import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { RateTable, UseQueryWrapper } from 'app/types';
import type { GetRateTablesArgs } from 'app/Domain/Settings/Services';
import { settingsServices } from 'app/Domain/Settings/Services';

import { useSnakeBar } from '../../useSnakeBar';

export type GetAllRateTablesQueryVariables = GetRateTablesArgs;

export type GetAllRateTablesQueryResultData = {
  data: RateTable[];
  totalCount: number;
};

const queryFn = (variables?: GetAllRateTablesQueryVariables) => async () => {
  if (variables?.ownerId) {
    const response = await settingsServices.getRateTables(variables);
    return (response as AxiosResponse).data;
  }
};

const QUERY_KEY = 'getRateTables';

export const useSettingsGetAllRateTablesQuery: UseQueryWrapper<
  GetAllRateTablesQueryResultData,
  GetAllRateTablesQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetAllRateTablesQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
