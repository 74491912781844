import React from 'react';

import { StoreSelectors, useStoreSelector } from '../../../store';

import type {
  GetOwnerRolesQueryResultData,
  GetOwnerRolesQueryVariables,
  GetRolesQueryResultData,
  GetRolesQueryVariables,
} from '../../../hooks';
import { useOwnerGetOwnerRolesQuery, useRoleGetRolesQuery } from '../../../hooks';

type QueryVariables = GetRolesQueryVariables | GetOwnerRolesQueryVariables;

type QueryData = GetRolesQueryResultData | GetOwnerRolesQueryResultData | undefined;

export const useRolesQuery = (variables?: QueryVariables) => {
  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));
  const isSuperAdmin = !ownerId;

  const {
    data: superAdminRolesData,
    isLoading: isLoadingGetRolesQuery,
    isFetching: isFetchingGetRolesQuery,
    refetch: refetchGetRolesQuery,
  } = useRoleGetRolesQuery({
    variables: { page: 1, perPage: 999, ...(variables || {}) },
    options: { enabled: isSuperAdmin },
  });

  const {
    data: ownerRolesData,
    isLoading: isLoadingGetOwnerRolesQuery,
    isFetching: isFetchingGetOwnerRolesQuery,
    refetch: refetchGetOwnerRolesQuery,
  } = useOwnerGetOwnerRolesQuery({
    variables: { ownerId, page: 1, perPage: 999, ...(variables || {}) },
    options: { enabled: !!ownerId },
  });

  const isGetRolesQueryLoading = isSuperAdmin && (isLoadingGetRolesQuery || isFetchingGetRolesQuery);
  const isGetOwnerRolesQueryLoading = !isSuperAdmin && (isLoadingGetOwnerRolesQuery || isFetchingGetOwnerRolesQuery);

  const isLoading = isGetRolesQueryLoading || isGetOwnerRolesQueryLoading;

  const data = React.useMemo<QueryData>(() => {
    if (isSuperAdmin) {
      return superAdminRolesData;
    }
    if (ownerRolesData) {
      return ownerRolesData;
    }

    return undefined;
  }, [isSuperAdmin, ownerRolesData, superAdminRolesData]);

  const refetch = React.useCallback(() => {
    if (isSuperAdmin) {
      refetchGetRolesQuery();
    } else {
      refetchGetOwnerRolesQuery();
    }
  }, [isSuperAdmin, refetchGetOwnerRolesQuery, refetchGetRolesQuery]);

  return React.useMemo(() => ({ data, isLoading, refetch }), [isLoading, refetch, data]);
};
