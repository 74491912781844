import { useMutation } from '@tanstack/react-query';

import type { DeleteEmployeeArgs } from 'app/Domain/Employees/Services/EmployeeService/employeeService';
import { employeeService } from 'app/Domain/Employees/Services/EmployeeService/employeeService';

type DeleteEmployeeData = DeleteEmployeeArgs;

const mutationFn = (data: DeleteEmployeeData) => {
  return employeeService.deleteEmployee(data);
};

export const useEmployeeDeleteMutation = () => {
  return useMutation<unknown, unknown, DeleteEmployeeData>(mutationFn);
};
