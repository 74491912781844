import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
export const DivTooltipTitleContainerStyled = styled('div', {
  name: 'DivTooltipTitleContainerStyled',
})(({ theme }) => ({
  padding: theme.spacing(1),
  '& ul': {
    marginLeft: theme.spacing(1),
  },
  '& li': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& p:nth-of-typ(2)': {
      color: theme.palette.primary.dark,
      fontWeight: 700,
    },
  },
}));

export const TypographyListHeaderStyled = styled(Typography, {
  name: 'TypographyListHeaderStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.dark,
  fontWeight: 600,
}));

export const DivNameContainerStyled = styled('div', {
  name: 'DivNameContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  flexWrap: 'nowrap',
  '& svg': {
    color: theme.palette.warning.main,
  },
}));
