import { styled } from '@mui/material/styles';

export const DivStyled = styled('div', {
  name: 'DivStyled',
  shouldForwardProp: (propName: string) => !['isDesktop'].includes(propName),
})<{ isDesktop: boolean }>(({ theme, isDesktop }) => ({
  height: '100%',
  paddingTop: 56,
  ...(isDesktop ? { paddingLeft: 240 } : {}),
  [theme.breakpoints.up('sm')]: {
    paddingTop: 64,
  },
}));

export const MainStyled = styled('main', {
  name: 'MainStyled',
})(({ theme }) => ({
  height: '100%',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));
