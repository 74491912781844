import React from 'react';
import { FormattedMessage } from 'react-intl';

import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import type { InsurerProduct } from '../../../../types/insurer.types';

import { TranslationKeys } from '../../../../translations';

import { FilterableTable } from '../../../../components/FilterableTable';

import { ActionLink } from '../../../../components/ActionLink';

import { FontAwesomeIconStyled } from './ProductsTable.styles';

type ProductsTableProps = {
  insurerId: string;
  products: Array<InsurerProduct>;
  loading: boolean;
};

export const ProductsTable = ({ insurerId, products, loading }: ProductsTableProps) => {
  const headers = React.useMemo(() => {
    return [
      {
        name: 'title',
        title: <FormattedMessage id={TranslationKeys.products_title} />,
      },
      {
        title: <FormattedMessage id={TranslationKeys.products_table_details} />,
        name: 'link',
      },
    ];
  }, []);

  const rowMapper = React.useCallback(
    product => {
      return {
        data: {
          title: product?.productName,
          description: product?.description,
          link: (
            <ActionLink to={`/insurers/${insurerId}/product/${product?.productId}/summary`}>
              <FontAwesomeIconStyled icon={faArrowUpRightFromSquare} />
              <FormattedMessage id={TranslationKeys.products_showSummary} />
            </ActionLink>
          ),
        },
      };
    },
    [insurerId],
  );

  const pagination = React.useMemo(() => {
    return {
      enabled: false,
    };
  }, []);

  return (
    <FilterableTable
      columns={headers}
      loading={loading}
      rows={products || []}
      rowMapper={rowMapper}
      pagination={pagination}
    />
  );
};
