import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CardContent, Grid, Typography } from '@mui/material';
import { faFileArrowUp, faHandPointer } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from '../../../../../../translations';

import { ViewDialog } from '../../../../../../components';

import { CardStyled, FontAwesomeIconStyled, TypographyStyled } from './EmployeeCreationModeSelectorDialog.styles';

type EmployeeCreationModeSelectorDialogProps = {
  show: boolean;
  onClose: () => void;
  onOpenWizard: () => void;
  onOpenUploadFormDialog: () => void;
};

export const EmployeeCreationModeSelectorDialog = ({
  show,
  onClose,
  onOpenWizard,
  onOpenUploadFormDialog,
}: EmployeeCreationModeSelectorDialogProps) => {
  return (
    <ViewDialog
      closeButton={false}
      open={show}
      title={<FormattedMessage id={TranslationKeys.employees_add} />}
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardStyled onClick={onOpenWizard}>
            <CardContent>
              <FontAwesomeIconStyled icon={faHandPointer} />
              <TypographyStyled variant="h5">
                <FormattedMessage id={TranslationKeys.employees_add_manually} />
              </TypographyStyled>
              <Typography variant="body1">
                <FormattedMessage id={TranslationKeys.employees_add_manually_description} />
              </Typography>
            </CardContent>
          </CardStyled>
        </Grid>
        <Grid item xs={6}>
          <CardStyled onClick={onOpenUploadFormDialog}>
            <CardContent>
              <FontAwesomeIconStyled icon={faFileArrowUp} />
              <TypographyStyled variant="h5">
                <FormattedMessage id={TranslationKeys.employees_add_fileUpload} />
              </TypographyStyled>
              <Typography variant="body1">
                <FormattedMessage id={TranslationKeys.employees_add_fileUpload_description} />
              </Typography>
            </CardContent>
          </CardStyled>
        </Grid>
      </Grid>
    </ViewDialog>
  );
};
