import axios, { type CancelTokenSource } from 'axios';

export type GetDropdownParams = {
  entity: string;
  page?: number;
  perPage?: number;
  q?: string;
  sort?: string;
  parentId?: string;
};

let getDropdownToken: CancelTokenSource;

const getDropdown = async (params?: GetDropdownParams) => {
  try {
    if (typeof getDropdownToken !== typeof undefined) {
      getDropdownToken.cancel('Operation canceled due to new request.');
    }

    getDropdownToken = axios.CancelToken.source();

    return axios.get('dropdowns', {
      cancelToken: getDropdownToken.token,
      params,
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const dropdownService = {
  getDropdown,
};
