import { useMutation } from '@tanstack/react-query';

import type { User } from '../../../types';

import { userService } from '../../../Domain/Users/Services';

type DeleteUserData = Pick<User, 'userId'>;

const mutationFn = (data: DeleteUserData) => {
  return userService.deleteUser(data?.userId);
};

export const useUserDeleteMutation = () => {
  return useMutation<unknown, unknown, DeleteUserData>(mutationFn);
};
