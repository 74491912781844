import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Card, CardContent } from '@mui/material';

import formatter from '../../../../../../utils/formatter';

import type { Role, User } from 'app/types';

import { useStoreSelector } from 'app/store';

import { OWNER } from 'app/common/Authorization/entities';
import { EDIT } from 'app/common/Authorization/permissions';

import { TranslationKeys } from 'app/translations';

import type { FilterableTableColumnType, KebabMenuItem } from 'app/components';
import { ActionLink, FilterableTable, HasAccessTo, KebabMenu } from 'app/components';

export const USERS_TABLE_DATA_TEST_ID = 'users-table-component';

enum TableColumns {
  Name = 'name',
  Email = 'email',
  Internal = 'internal',
  Role = 'role',
  CreatedAt = 'createdAt',
  Actions = 'col_actions',
}

type UsersTableProps = {
  users: User[];
  roles: Role[];
  rowsPerPage: number;
  totalCount: number;
  page: number;
  onPaginationChange: (args: { page?: number; rowSize?: number }) => void;
  onDeleteActionClick: (userId: User['userId']) => void;
  onReset2FaActionClick: (userId: User['userId']) => void;
  isLoading?: boolean;
};

export const UsersTable = ({
  users,
  roles,
  rowsPerPage,
  totalCount,
  page,
  onPaginationChange,
  onDeleteActionClick,
  onReset2FaActionClick,
  isLoading = false,
}: UsersTableProps) => {
  const loggedInUser = useStoreSelector(state => state.AppReducer.user);
  const hasPermissionToEdit = HasAccessTo(OWNER, EDIT);

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('UsersTable component mounted or updated');
    return () => {
      // eslint-disable-next-line no-console
      console.log('UsersTable component will unmount');
    };
  }, []);

  const tableHeaders = React.useMemo<FilterableTableColumnType[]>(() => {
    return [
      {
        name: TableColumns.Name,
        title: <FormattedMessage id={TranslationKeys.users_name} />,
      },
      {
        name: TableColumns.Email,
        title: <FormattedMessage id={TranslationKeys.users_email} />,
      },
      {
        name: TableColumns.Internal,
        title: <FormattedMessage id={TranslationKeys.users_internal} />,
      },
      {
        name: TableColumns.Role,
        title: <FormattedMessage id={TranslationKeys.users_role} />,
      },
      {
        name: TableColumns.CreatedAt,
        title: <FormattedMessage id={TranslationKeys.users_createdAt} />,
      },
      {
        name: TableColumns.Actions,
        isActionsColumn: true,
      },
    ];
  }, []);

  const buildRowActions = React.useCallback(
    (user: User) => {
      const isLoggedInUserOrDontHavePermissionToDelete = loggedInUser.userId === user.userId || !hasPermissionToEdit;

      const actions: Array<KebabMenuItem> = [
        {
          to: `/users/${user.userId}`,
          element: <FormattedMessage id={TranslationKeys.global_edit} />,
          disabled: !hasPermissionToEdit,
        },
        {
          element: <FormattedMessage id={TranslationKeys.global_delete} />,
          onClick: () => onDeleteActionClick(user.userId),
          disabled: isLoggedInUserOrDontHavePermissionToDelete,
        },
      ];

      if (user.totpVerified) {
        actions.push({
          element: <FormattedMessage id={TranslationKeys.users_reset2FA} />,
          onClick: () => onReset2FaActionClick(user.userId),
          disabled: isLoggedInUserOrDontHavePermissionToDelete,
        });
      }

      return <KebabMenu items={actions} />;
    },
    [hasPermissionToEdit, loggedInUser.userId, onDeleteActionClick, onReset2FaActionClick],
  );

  const getRoleName = React.useCallback(
    (user: User) => {
      const role = roles.find(role => user.role === role['@id']);

      return role ? role.name : null;
    },
    [roles],
  );

  const getInternal = React.useCallback(
    (internal: boolean) =>
      internal ? <FormattedMessage id={TranslationKeys.yes} /> : <FormattedMessage id={TranslationKeys.no} />,
    [],
  );

  const rowMapper = React.useCallback(
    user => {
      return {
        data: {
          [TableColumns.Name]: hasPermissionToEdit ? (
            <ActionLink to={`/users/${user.userId}`}>{user.personName.fullName}</ActionLink>
          ) : (
            user.personName.fullName
          ),
          [TableColumns.Email]: user?.emailAddress,
          [TableColumns.Internal]: getInternal(user.internal),
          [TableColumns.Role]: getRoleName(user),
          [TableColumns.CreatedAt]: formatter.date(user.createdAt),
          [TableColumns.Actions]: buildRowActions(user),
        },
      };
    },
    [buildRowActions, getRoleName, hasPermissionToEdit, getInternal],
  );

  const pagination = React.useMemo(() => {
    return {
      pageSize: rowsPerPage,
      totalCount,
      page,
    };
  }, [rowsPerPage, totalCount, page]);

  return (
    <Card data-testid={USERS_TABLE_DATA_TEST_ID} sx={{ borderRadius: '10px' }}>
      <CardContent sx={{ padding: 0 }}>
        <FilterableTable
          columns={tableHeaders}
          loading={isLoading}
          rows={users}
          rowMapper={rowMapper}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
        />
      </CardContent>
    </Card>
  );
};
