import { styled } from '@mui/material/styles';

import ActionButton from '../../../../components/ActionButton';

export const DivHeaderActionsStyled = styled('div', {
  name: 'DivHeaderActionsStyled',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
}));

export const ActionButtonStyled = styled(ActionButton, {
  name: 'ActionButtonStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(1),
  minHeight: '35px',
  minWidth: '200px',
  padding: 0,
  zIndex: 1000,
}));
