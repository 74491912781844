import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Skeleton } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import { useSnakeBar, useUpdateEmployeeSynchronization } from 'app/hooks';

import type { EmployeeSynchronizationStatusFields, UseQueryRefetch } from 'app/types';

import Switch from 'app/components/FormikField/Switch';

import { DivSwitchContainerStyled } from './EmployeeDetailsPanelHeaderWithSyncToggle.styles';

import { HasAccessTo } from 'app/components';
import { CONNECTION, EMPLOYER } from 'app/common/Authorization/entities';
import { EDIT, UPDATE } from 'app/common/Authorization/permissions';

type EmployeeDetailsPanelHeaderWithSyncToggleProps = {
  loading: boolean;
  messageId: string;
  employeeId: string | undefined;
  connectionId: string | undefined;
  synchronizationStatus: boolean | undefined;
  field: EmployeeSynchronizationStatusFields;
  refetchGetEmployeeSynchronizationQuery: UseQueryRefetch;
  disabledActionButtonsWhileLoading: boolean;
};

export const EmployeeDetailsPanelHeaderWithSyncToggle = ({
  synchronizationStatus,
  refetchGetEmployeeSynchronizationQuery,
  disabledActionButtonsWhileLoading,
  loading,
  employeeId,
  connectionId,
  messageId,
  field,
}: EmployeeDetailsPanelHeaderWithSyncToggleProps) => {
  const intl = useIntl();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: updateEmployeeSynchronization, isLoading: isUpdatingSync } = useUpdateEmployeeSynchronization();
  const showSynchronizationToggle = loading || synchronizationStatus !== undefined;
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);
  const disabledIfNoAccessToConnectionEdit = !HasAccessTo(CONNECTION, UPDATE);

  const onToggleSync = React.useCallback(
    ({ value }) => {
      const method = 'updateEmployeeSynchronization';

      if (employeeId && connectionId) {
        updateEmployeeSynchronization(
          {
            connectionId,
            employeeId,
            field,
            value,
          },
          {
            onSuccess: () => {
              showSuccessSnakeBar({ method });
              refetchGetEmployeeSynchronizationQuery();
            },
            onError: () => {
              showErrorSnakeBar({ method });
            },
          },
        );
      }
    },
    [
      connectionId,
      employeeId,
      field,
      refetchGetEmployeeSynchronizationQuery,
      showErrorSnakeBar,
      showSuccessSnakeBar,
      updateEmployeeSynchronization,
    ],
  );

  const SynchronizationSwitch = React.useMemo(() => {
    return (
      <DivSwitchContainerStyled>
        {loading && <Skeleton width={150} height={20} />}
        {!loading && (
          <Switch
            variant={'slim'}
            onChange={onToggleSync}
            field={{
              name: 'synchronizationStatus',
              label: intl.formatMessage({ id: TranslationKeys.global_synchronization }),
              defaultValue: synchronizationStatus,
              disabled:
                disabledActionButtonsWhileLoading ||
                isUpdatingSync ||
                disabledIfNoAccessToEmployerEdit ||
                disabledIfNoAccessToConnectionEdit,
              loading,
            }}
          />
        )}
      </DivSwitchContainerStyled>
    );
  }, [
    disabledActionButtonsWhileLoading,
    disabledIfNoAccessToConnectionEdit,
    disabledIfNoAccessToEmployerEdit,
    intl,
    isUpdatingSync,
    loading,
    onToggleSync,
    synchronizationStatus,
  ]);

  return (
    <>
      {!showSynchronizationToggle && <FormattedMessage id={messageId} />}
      {showSynchronizationToggle && (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormattedMessage id={messageId} />
          {SynchronizationSwitch}
        </Box>
      )}
    </>
  );
};
