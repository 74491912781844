import type { AuthorizationContextInterface } from './AuthorizationContextInterface';
import { useContext } from 'react';
import { AuthorizationContext } from './AuthorizationProvider';

export const HasRole = (roleName: string) => {
  const context: AuthorizationContextInterface = useContext(AuthorizationContext);
  const { roles } = context;

  if (!roles) {
    return false;
  }

  return roles.includes(roleName);
};
