import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { EventFlow, UseQueryWrapper } from '../../../types';

import type { GetEventFlowDetailsParams } from '../../../Domain/Dashboard/Services';
import { dashboardService } from '../../../Domain/Dashboard/Services';

import { useSnakeBar } from '../../useSnakeBar';

type GetEventFlowDetailsQueryVariables = GetEventFlowDetailsParams;

type GetEventFlowDetailsQueryResultData = {
  data: EventFlow[];
  totalCount: number;
};

const queryFn = (variables: GetEventFlowDetailsQueryVariables) => async () => {
  const response = await dashboardService.getEventFlowDetails(variables);
  return response.data;
};

const QUERY_KEY = 'getEventFlowDetails';

export const useGetEventFlowDetailsQuery: UseQueryWrapper<
  GetEventFlowDetailsQueryResultData,
  GetEventFlowDetailsQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetEventFlowDetailsQueryResultData>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
