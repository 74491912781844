import React from 'react';
import { FormattedMessage } from 'react-intl';

import { faCheck as trueIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { Palette } from '@mui/material';

import palette from '../../theme/palette';

import { parseValue } from '../Parsers';

export const formatYes = (value: any) => {
  const boolValue = parseValue(value, 'boolean');
  return boolValue && <FormattedMessage id="yes" />;
};

export const formatYesNo = (value: any) => {
  const boolValue = parseValue(value, 'boolean');
  return boolValue ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />;
};

export const formatYesNoEmpty = (value: any) => {
  if (value !== undefined) {
    const boolValue = parseValue(value, 'boolean');

    return boolValue ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />;
  }

  return '';
};

export const formatOnOff = (value: any) => {
  const boolValue = parseValue(value, 'boolean');
  return boolValue ? <FormattedMessage id="on" /> : <FormattedMessage id="off" />;
};

export const formatYesAsIcon = (value: any) => {
  const themePalette = palette as Palette;
  const boolValue = parseValue(value, 'boolean');
  return boolValue && <FontAwesomeIcon icon={trueIcon} color={themePalette.success.main} />;
};
