import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar } from '@mui/material';

export const hiddenMdDown = () => ({
  display: { xs: 'none', md: 'block' },
});

export const hiddenLgUp = () => ({
  display: { lg: 'none', md: 'block' },
});

export const iconStyles = (theme: Theme) => ({
  color: theme.palette.secondary.light,
});

export const AppBarStyled = styled(AppBar, {
  name: 'AppBarStyled',
})(() => ({
  backgroundPosition: '10px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '200px',
  boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.25)',
}));

export const ToolbarStyled = styled(Toolbar, {
  name: 'ToolbarStyled',
})(({ theme }) => ({
  minHeight: theme.spacing(7.5),
}));
