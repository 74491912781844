import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { FormLabel, Grid, outlinedInputClasses, Typography } from '@mui/material';
import { Field } from 'formik';

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '15px',
  fontWeight: theme.typography.fontWeightBold,
}));

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(() => ({
  minHeight: '75px',
}));

export const GridTitleStyled = styled(Grid, {
  name: 'GridTitleStyled',
})(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '14px',
  fontWeight: theme.typography.fontWeightBold,
}));

export const GridPermissionsStyled = styled(Grid, {
  name: 'GridPermissionsStyled',
  shouldForwardProp: (propName: PropertyKey) => propName !== 'disabled',
})<{ disabled: boolean }>(({ theme, disabled }) => ({
  ...(disabled
    ? {
        opacity: 0.2,
        cursor: 'not-allowed',
        backgroundColor: theme.palette.common.white,
      }
    : {}),
}));

export const TypographySubtitleStyled = styled(Typography, {
  name: 'TypographySubtitleStyled',
})(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '13px',
  fontWeight: theme.typography.fontWeightBold,
}));

export const GridTableStyled = styled(Grid, {
  name: 'GridTableStyled',
})(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: `${theme.palette.primary.light}40`,
  },
  overflow: 'hidden',
  fontFamily: theme.typography.fontFamily,
}));

const commonActionStyles = (theme: Theme) => ({
  color: theme.palette.primary.dark,
  fontSize: '13px',
  paddingLeft: theme.spacing(2),
});

export const GridActionStyled = styled(Grid, {
  name: 'GridActionStyled',
})(({ theme }) => commonActionStyles(theme));

export const DivActionStyles = styled('div', {
  name: 'DivActionStyles',
})(({ theme }) => commonActionStyles(theme));

export const GridHeaderStyled = styled(Grid, {
  name: 'GridHeaderStyled',
})(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  border: `1px solid ${theme.palette.primary.light}`,
  borderTop: 0,
  borderRight: 0,
  borderLeft: 0,
  fontFamily: theme.typography.fontFamily,
}));

export const FieldStyled = styled(Field, {
  name: 'FieldStyled',
})(({ theme }) => ({
  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1.5),
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export const LabelStyled = styled('label', {
  name: 'LabelStyled',
})(({ theme }) => ({
  width: '100%',
  fontFamily: theme.typography.fontFamily,
  marginRight: '10px',
  marginTop: '5px',
  marginBottom: '5px',
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '25px',
  height: '20px',
  '& input:disabled': {
    cursor: 'not-allowed',
    '& + span': {
      cursor: 'not-allowed',
    },
    '& ~ span': {
      cursor: 'not-allowed',
    },
  },
  '& input': {
    display: 'none',
  },
  '& span:first-of-type': {
    color: theme.palette.primary.dark,
    fontSize: '13px',
    lineHeight: '23px',
    verticalAlign: 'top',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  '& span:last-of-type': {
    position: 'relative',
    display: 'inline-block',
    width: '45px',
    height: '20px',
    borderRadius: '20px',
    border: '1px solid #979797',
    backgroundColor: theme.palette.secondary.dark,
    fontSize: '10px',
    '&:before': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '15px',
      height: '15px',
      borderRadius: '15px',
      margin: '-1px',
      transition: 'all 0.2s ease',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.secondary.dark}`,
      zIndex: 1,
      left: '1px',
      top: '1px',
    },
    '&:after': {
      position: 'absolute',
      content: '"OFF"',
      lineHeight: '18px',
      right: '6.25px',
      color: theme.palette.common.white,
    },
  },
  '& input:checked ~ span:last-of-type': {
    backgroundColor: theme.palette.info.light,
    '&:before': {
      top: '1px',
      left: '25px',
    },
    '&:after': {
      content: '"ON"',
      color: theme.palette.common.white,
      right: '22px',
    },
  },
}));

export const SpanFloatLeftStyled = styled('span', {
  name: 'SpanFloatLeftStyled',
})(() => ({
  float: 'left',
}));

export const SpanFloatRightStyled = styled('span', {
  name: 'SpanFloatRightStyled',
})(() => ({
  float: 'right',
}));
