import { alpha } from '@mui/material/styles';
import { autocompleteClasses, inputBaseClasses, outlinedInputClasses, svgIconClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiAutocomplete: FuncOverrideComponent<'MuiAutocomplete'> = theme => ({
  styleOverrides: {
    inputRoot: {
      [`&[class*='${outlinedInputClasses.root}']`]: {
        padding: 0,
        [`& .${inputBaseClasses.input}`]: {
          [`&.${autocompleteClasses.input}:first-of-type`]: {
            padding: theme.spacing(1.25, 0.75, 1.25, 1.5),
            [`&.${inputBaseClasses.inputSizeSmall}`]: {
              padding: theme.spacing(0, 0.5, 0, 0.75),
            },
          },
        },
      },
    },
    popupIndicator: {
      marginRight: '-8px',
      padding: 0,
      [`& .${svgIconClasses.root}`]: {
        top: 'calc(50% - 16px)',
        width: '32px',
        height: '32px',
        right: '0px',
      },
    },
    clearIndicator: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    endAdornment: {
      top: 'calc(50% - 16px)',
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: alpha(theme.palette.primary.light, 0.3),
      },
      '&[data-focus="true"]': {
        backgroundColor: alpha(theme.palette.primary.light, 0.6),
      },
    },
    paper: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
});
