import React, { useEffect } from 'react';

import { actions as ReportsActions } from '../../Ducks/Reports.duck';

import { TranslationKeys } from '../../../../translations';

import type { TableSelectedState } from '../../../../store';
import { StoreActions, useStoreDispatch, useStoreSelector } from '../../../../store';

import { useBreadcrumb } from '../../../../components/Breadcrumbs';

import { InvoicingTable } from '../../Components';

import { DivContentStyled } from './InvoicingReportPage.styles';

export const InvoicingReportPage = () => {
  const dispatch = useStoreDispatch();

  const { loading, items, page, pageSize, totalCount, breadCrumbs } = useStoreSelector<
    { breadCrumbs: any } & TableSelectedState
  >(state => state.ReportsReducer);

  useEffect(() => {
    dispatch(StoreActions.ReportsActions.initializeReportsState());
    dispatch(ReportsActions.requestData());
  }, [dispatch]);

  const handlePageChange = React.useCallback(
    (event, newPage) => {
      dispatch(StoreActions.ReportsActions.changePage(newPage + 1));
    },
    [dispatch],
  );

  const handleRowsPerPageChange = React.useCallback(
    event => {
      dispatch(StoreActions.ReportsActions.setPageSize(event.target.value));
    },
    [dispatch],
  );

  const handleToggleReport = React.useCallback(
    reportTemp => {
      // @ts-ignore
      dispatch(StoreActions.ReportsActions.toggleReport(reportTemp));
    },
    [dispatch],
  );

  const handleToggleAllReports = React.useCallback(
    reports => dispatch(StoreActions.ReportsActions.toggleAllReports(reports)),
    [dispatch],
  );

  const requestData = React.useCallback(() => {
    dispatch(StoreActions.ReportsActions.requestData());
  }, [dispatch]);

  const goToFolder = React.useCallback(
    folderPath => {
      dispatch(StoreActions.ReportsActions.goToFolder(folderPath));
    },
    [dispatch],
  );

  const downloadReport = React.useCallback(
    filePath => {
      dispatch(ReportsActions.downloadReport(filePath));
    },
    [dispatch],
  );

  useBreadcrumb(TranslationKeys.menu_reports_invoicing, {
    hideTrail: true,
  });

  return (
    <div>
      <DivContentStyled>
        <InvoicingTable
          // @ts-ignore
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handleToggleReport={handleToggleReport}
          handleToggleAllReports={handleToggleAllReports}
          reports={items || []}
          breadCrumbs={breadCrumbs}
          page={page - 1}
          rowsPerPage={pageSize}
          totalCount={totalCount}
          loading={loading}
          requestData={requestData}
          goToFolder={goToFolder}
          downloadReport={downloadReport}
        />
      </DivContentStyled>
    </div>
  );
};
