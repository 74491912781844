export const OWNER = 'OWNER';
export const SUBOWNER = 'SUBOWNER';
export const EMPLOYER = 'EMPLOYER';
export const ROLE = 'ROLE';
export const CONNECTION = 'CONNECTION';
export const EVENT = 'EVENT';
export const BATCH = 'BATCH';
export const CREDENTIAL = 'CREDENTIAL';
export const SETTINGS = 'SETTINGS';
export const INSURER = 'INSURER';
export const API = 'API';
export const WIDGET = 'WIDGET';
export const ENVIRONMENT = 'ENVIRONMENT';
