import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { formatName } from 'app/utils/formatter/nameFormatter';
import { formatDate } from 'app/utils/formatter/dateTimeFormatter';
import { actions as ProcessEventsActions } from '../../../../Ducks/ProcessEvents.duck';
import { HasAccessTo } from 'app/components';
import { CONNECTION } from 'app/common/Authorization/entities';
import { DOWNLOAD } from 'app/common/Authorization/permissions';
import { IntlMessage } from '../../../../../../components/Intl';
import { EVENT_TYPE_MAPPER } from 'app/types';

const ProcessEventsDialog = ({ open, connectionId, batchId, batchMongoId, handleClose }) => {
  const dispatch = useDispatch();

  const { items, page, pageSize, totalCount, loading, error } = useSelector(state => state.ProcessEventsReducer);

  useEffect(() => {
    dispatch(ProcessEventsActions.clearEvents());
    dispatch(ProcessEventsActions.setConnectionId(connectionId));
    dispatch(ProcessEventsActions.setIds(batchId, batchMongoId));
    dispatch(ProcessEventsActions.requestData());
  }, [connectionId, batchId, batchMongoId, dispatch]);

  const handlePageChange = useCallback(
    (event, newPage) => {
      dispatch(ProcessEventsActions.changePage(newPage + 1));
    },
    [dispatch],
  );

  const handleRowsPerPageChange = useCallback(
    event => {
      dispatch(ProcessEventsActions.setPageSize(event.target.value));
    },
    [dispatch],
  );

  const downloadEvents = useCallback(() => {
    dispatch(ProcessEventsActions.downloadEvents());
  }, [dispatch]);

  const disabledIfNoAccessToConnectionDownload = !HasAccessTo(CONNECTION, DOWNLOAD);

  const formatEventType = React.useCallback(eventType => {
    return <FormattedMessage id={'events.type.' + EVENT_TYPE_MAPPER[eventType]} />;
  }, []);

  return (
    !error && (
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md" aria-labelledby="process-events-dialog">
        <DialogTitle id="process-events-dialog">
          <FormattedMessage id="connection.processes.events.dialogTitle" />
        </DialogTitle>
        <DialogContent>
          {loading && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="events.type" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="events.employee" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="events.archived" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="events.createdAt" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(event => (
                <TableRow hover key={event.mongoId}>
                  <TableCell>{formatEventType(event.eventType)}</TableCell>
                  <TableCell>{formatName(event.hrEmployee.personName)}</TableCell>
                  <TableCell>{event.archived ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</TableCell>
                  <TableCell>{formatDate(event.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[100, 500, 1000]}
          />
          <Button onClick={downloadEvents} disabled={disabledIfNoAccessToConnectionDownload}>
            <FormattedMessage id="events.download" />
          </Button>
          <Button onClick={() => handleClose()} color="secondary">
            <IntlMessage value={'close'} />
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

ProcessEventsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  batchId: PropTypes.string.isRequired,
  batchMongoId: PropTypes.string.isRequired,
  connectionId: PropTypes.string.isRequired,
};

export default ProcessEventsDialog;
