import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));
