import { put, select, takeLatest, debounce } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { getConnectionModules } from '../Services/ConnectionService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  RequestData: '[Modules] Request',
  FulfilledTable: '[Modules] Fulfilled',
  SearchTable: '[Modules] Search',
  ChangePage: '[Modules] change page',
  SetPageSize: '[Modules] set page size',
  SortTable: '[Modules] Sort',
  ApplyFilter: '[Modules] Apply filter',
  RemoveFilter: '[Modules] Remove filter',
};

const initialState = {
  ...tableInitialState,
  pageSize: 1000,
  connectionId: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.RequestData: {
      return {
        ...state,
        loading: true,
        connectionId: action.connectionId,
      };
    }

    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  requestData: connectionId => ({ type: actionTypes.RequestData, connectionId }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestData, function* request() {
    try {
      const currentState = yield select(state => {
        return state.ModulesReducer;
      });

      const response = yield getConnectionModules(
        currentState.connectionId,
        currentState.page,
        currentState.pageSize,
        currentState.sortBy,
        currentState.filters,
        currentState.searchQuery,
      );

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getModules',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });
}
