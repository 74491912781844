import axios from 'axios';

import { backgroundAxios } from 'app/utils/api';

import type { Connection, ConnectionPackage, Credential, Product } from '../../../../types';

export const NEW_CREDENTIAL_VALUE = 'newCredential';
export const CREDENTIAL_TYPE_OAUTH = 'oauth-2';
export const INSURANCE_BRYDGE = 'insurance-brydge';

const getAvailablePackages = (selectedPackages: unknown) => {
  return axios.get('synchronizer/packages/connectable', { params: { usedPackages: selectedPackages } });
};

const getAvailableCredentialForPackageType = (packageType: string) => {
  return axios.get('credentials', {
    params: { packageType, perPage: 999 },
  });
};

export type GetCredentialSettingsParams = {
  credentialId: Credential['credentialId'];
  productId?: Product['productId'];
};

const getCredentialSettings = ({ credentialId, productId }: GetCredentialSettingsParams) => {
  return backgroundAxios.get(`credentials/${credentialId}/setting-details`, {
    params: { productId },
  });
};

const getConnectionSettings = (connectionId: Connection['connectionId']) => {
  return axios.get(`connections/${connectionId}/setting-details`);
};

const getNewCredentialDetails = (packageType: Credential['packageType']) => {
  return backgroundAxios.get(`synchronizer/packages/${packageType}/credentials-details`);
};

const getPackageTypes = () => {
  return axios.get('synchronizer/package-types');
};

type GetPackageSettingsDetailsParams = {
  packageType: ConnectionPackage['packageType'];
  productId: Product['productId'];
  connectionId: Connection['connectionId'];
};

const getPackageSettingsDetails = ({ packageType, productId, connectionId }: GetPackageSettingsDetailsParams) => {
  return backgroundAxios.get(`synchronizer/packages/${packageType}/settings-details`, {
    params: {
      productId,
      connectionId,
    },
  });
};

const getPackageRelations = (packageType: ConnectionPackage['packageType']) => {
  return axios.get('synchronizer/package-types', { params: { packageType } });
};

const getCredentialForPackageType = (packageType: Credential['packageType']) => {
  return axios.get(`synchronizer/package-types/${packageType}/credential`);
};

const getSettingsForPackageType = (packageType: string) => {
  return axios.get(`synchronizer/package-types/${packageType}/settings`);
};

export type OnboardUpdateCredentialParams = {
  credentialId: Credential['credentialId'];
};

const onboardUpdateCredential = async (values: OnboardUpdateCredentialParams) => {
  return axios.put(`credentials/${values.credentialId}`, { ...values });
};

export const onboardingService = {
  getPackageTypes,
  getPackageRelations,
  getCredentialForPackageType,
  getSettingsForPackageType,
  onboardUpdateCredential,
  getCredentialSettings,
  getAvailablePackages,
  getAvailableCredentialForPackageType,
  getConnectionSettings,
  getNewCredentialDetails,
  getPackageSettingsDetails,
};
