import type { FuncOverrideComponent } from '../theme.types';

export const MuiIconButton: FuncOverrideComponent<'MuiIconButton'> = theme => ({
  styleOverrides: {
    root: {
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
      },
    },
    sizeSmall: {
      fontSize: '1.5rem',
    },
    colorPrimary: {
      color: theme.palette.primary.main,
    },
    colorSecondary: {
      color: theme.palette.secondary.main,
    },
  },
});
