import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { TranslationKeys } from '../../../translations';

import { RestrictAccess, useBreadcrumb } from '../../../components';

import { InsurerManagementPage } from './InsurerManagementPage';
import { InsurerDetailPage } from './InsurerDetailPage';
import { InsurerInformationTab } from './InsurerDetailPage/InsurerInformationTab';
import { InsurerProductRegulations } from './InsurerDetailPage/InsurerProductRegulations';
import { InsurerProductSummaryTab } from './InsurerDetailPage/InsurerProductRegulations/InsurerProductSummaryTab';
import { INSURER } from '../../../common/Authorization/entities';
import { VIEW } from '../../../common/Authorization/permissions';

export const InsurerPages = () => {
  useBreadcrumb(TranslationKeys.menu_insurers);
  return (
    <Routes>
      <Route element={<RestrictAccess entity={INSURER} permission={VIEW} />}>
        <Route element={<InsurerManagementPage />} path="overview" />
        <Route element={<InsurerDetailPage />} path=":insurerId/*">
          <Route path="info" element={<InsurerInformationTab />} />

          <Route element={<InsurerProductRegulations />} path="product/:productId/*">
            <Route path="summary" element={<InsurerProductSummaryTab />} />
            <Route path="*" element={<Navigate to="summary" replace />} />
            <Route index element={<Navigate to="summary" replace />} />
          </Route>

          <Route path="*" element={<Navigate to="info" replace />} />
          <Route index element={<Navigate to="info" replace />} />
        </Route>
        <Route index element={<Navigate to="overview" replace />} />
        <Route path="*" element={<Navigate to="overview" replace />} />
      </Route>
    </Routes>
  );
};
