import React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';

import { TranslationKeys } from '../../../../translations';

import type { Employer } from '../../../../types';

import {
  useDialog,
  useOwnerDeleteMutation,
  useOwnerGetEmployersQuery,
  useOwnerGetOwnerQuery,
  useSnakeBar,
} from '../../../../hooks';

import { normalizeError } from 'app/utils';
import { EMPLOYER, OWNER, SUBOWNER } from '../../../../common/Authorization/entities';
import { EDIT, VIEW } from '../../../../common/Authorization/permissions';

import { ConfirmDialog, HasAccessTo, IsSuperAdmin, ViewDialog } from '../../../../components';
import TabOutlet from '../../../../components/TabOutlet';

import { NotFoundView } from '../../../../views';

import { OwnerEmployers } from './OwnerEmployers';

import { ActionButtonStyled, DivHeaderActionsStyled } from './OwnerDetailPage.styles';
import { StoreSelectors, useStoreSelector } from '../../../../store';

export const OwnerDetailPage = () => {
  const { pathname } = useLocation();
  const { mutate: deleteOwner } = useOwnerDeleteMutation();
  const { showErrorSnakeBar } = useSnakeBar();
  const navigate = useNavigate();
  const { ownerId } = useParams();
  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const [ownerEmployers, setOwnerEmployers] = React.useState<Array<Employer> | undefined>();

  const isSuperAdmin = IsSuperAdmin();

  const {
    dialogState: showDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog,
    openDialog: openDeleteConfirmationDialog,
  } = useDialog();

  const {
    dialogState: showViewEmployersDialog,
    closeDialog: closeViewEmployersDialog,
    openDialog: openViewEmployersDialog,
  } = useDialog();

  const hasOwnerId = !!ownerId;
  const { data: owner, isLoading: isLoadingOwner } = useOwnerGetOwnerQuery({
    variables: { ownerId: ownerId || '' },
    options: {
      enabled: hasOwnerId,
    },
  });

  const isOwner = owner?.parentId === null;
  const { isLoading: isLoadingOwnerEmployers } = useOwnerGetEmployersQuery({
    variables: {
      userId: userId || '',
      page: 1,
      perPage: 999,
    },
    options: {
      enabled: hasOwnerId && isOwner,
      onSuccess: data => {
        const sortedEmployerData = data.data.sort((a, b) => (a.employerName < b.employerName ? -1 : 0));
        setOwnerEmployers(sortedEmployerData);
      },
    },
  });

  React.useEffect(() => {
    if (owner) {
      const isOwnersPath = !!matchPath('/owners/*', pathname);
      const redirectPath = `/${isOwnersPath ? 'sub-owners' : 'owners'}/${ownerId}`;
      const shouldRedirect = (isOwnersPath && !isOwner) || (!isOwnersPath && isOwner);

      if (shouldRedirect) {
        navigate(redirectPath, { replace: true });
      }
    }
  }, [owner, pathname, navigate, ownerId, isOwner]);

  const disabledIfNoAccessToEdit = isOwner ? !HasAccessTo(OWNER, EDIT) : !HasAccessTo(SUBOWNER, EDIT);
  const disabledIfNoAccessToEmployerView = !HasAccessTo(EMPLOYER, VIEW);
  const isLoading = isLoadingOwner || isLoadingOwnerEmployers;

  const disabledShowEmployersButtonIfLoadingOrNoAccessOrNoEmployers =
    isLoading || disabledIfNoAccessToEmployerView || !ownerEmployers?.length;

  const disabledDeleteButtonIfLoadingOrNoAccess = isLoadingOwner || disabledIfNoAccessToEdit;
  const existsOwner = isLoadingOwner || !!owner;

  const onClickConfirmDelete = React.useCallback(() => {
    if (ownerId) {
      deleteOwner(
        { ownerId },
        {
          onSuccess: () => navigate(isOwner ? '/owners' : '/owners/sub-owners'),
          onError: (error: any) => {
            const errors = normalizeError(error);
            showErrorSnakeBar({
              method: 'deleteOwner',
              message: errors.message,
            });
          },
        },
      );
    }
  }, [isOwner, deleteOwner, navigate, ownerId, showErrorSnakeBar]);

  const tabData = React.useMemo(() => {
    return [
      {
        path: 'info',
        label: <FormattedMessage id={TranslationKeys.owners_tab_general} />,
      },
    ];
  }, []);

  const ownerEmployersTitle = React.useMemo(() => {
    if (owner) {
      return (
        <span>
          {`${owner.ownerName}: ${ownerEmployers?.length} `}
          <FormattedMessage id={TranslationKeys.owners_employers} />
        </span>
      );
    }
    return <Skeleton width={100} />;
  }, [owner, ownerEmployers]);

  const breadcrumbTitle = React.useMemo(() => {
    if (owner?.ownerName) {
      return <Typography variant="h5">{owner.ownerName}</Typography>;
    }
    return (
      <Typography variant="h5">
        <Skeleton variant={'text'} width={200} />
      </Typography>
    );
  }, [owner]);

  const breadcrumbButtons = React.useMemo(() => {
    return (
      <DivHeaderActionsStyled>
        {isOwner && (
          <ActionButtonStyled
            messageId={TranslationKeys.owners_showEmployers}
            onClick={openViewEmployersDialog}
            disabled={disabledShowEmployersButtonIfLoadingOrNoAccessOrNoEmployers || !owner}
          />
        )}
        {isSuperAdmin ? (
          <ActionButtonStyled
            messageId={TranslationKeys.global_delete}
            onClick={openDeleteConfirmationDialog}
            disabled={disabledDeleteButtonIfLoadingOrNoAccess}
            variant="outlined"
          />
        ) : (
          false
        )}
      </DivHeaderActionsStyled>
    );
  }, [
    owner,
    isOwner,
    openViewEmployersDialog,
    disabledShowEmployersButtonIfLoadingOrNoAccessOrNoEmployers,
    openDeleteConfirmationDialog,
    disabledDeleteButtonIfLoadingOrNoAccess,
    isSuperAdmin,
  ]);

  const breadcrumb = React.useMemo(() => {
    return {
      title: owner?.ownerName,
      data: {
        titleElement: breadcrumbTitle,
        otherElements: breadcrumbButtons,
      },
    };
  }, [owner, breadcrumbTitle, breadcrumbButtons]);

  return (
    <>
      {existsOwner && (
        <TabOutlet tabs={tabData} breadcrumb={breadcrumb}>
          {showDeleteConfirmationDialog && (
            <ConfirmDialog
              title={<FormattedMessage id={TranslationKeys.global_delete} />}
              open={showDeleteConfirmationDialog}
              onClose={closeDeleteConfirmationDialog}
              onConfirm={onClickConfirmDelete}
            >
              <FormattedMessage id={TranslationKeys.global_deleteConfirmationMessage} />
            </ConfirmDialog>
          )}

          {showViewEmployersDialog && !!ownerEmployers && (
            <ViewDialog open={showViewEmployersDialog} title={ownerEmployersTitle} onClose={closeViewEmployersDialog}>
              <OwnerEmployers ownerEmployers={ownerEmployers} />
            </ViewDialog>
          )}
        </TabOutlet>
      )}

      {!existsOwner && (
        <Routes>
          <Route element={<NotFoundView />} path="*" />
        </Routes>
      )}
    </>
  );
};
