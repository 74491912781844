import { useMutation } from '@tanstack/react-query';

import type { UpdateEmployeePartnerArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

const mutationFn = (data: UpdateEmployeePartnerArgs) => {
  return employeeService.updateEmployeePartner(data);
};

export const useEmployeeUpdatePartnerMutation = () => {
  return useMutation<unknown, unknown, UpdateEmployeePartnerArgs>(mutationFn);
};
