import { backgroundAxios } from 'app/utils/api';

export const getConnectionNotes = async (connectionId, page, pageSize) => {
  return backgroundAxios.get(`/connections/${connectionId}/notes`, { params: { page, pageSize } });
};

export const createNotes = async (text, connectionId) => {
  return backgroundAxios.post(`/connections/${connectionId}/notes`, { text, connectionId });
};

export const updateNotes = async (text, connectionId, noteId) => {
  return backgroundAxios.put(`/connections/${connectionId}/notes/${noteId}`, { text, connectionId, noteId });
};
