import React from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';

import { AlertStyled, FormErrorList, FormErrorListItem } from './ScalesTableFormErrors.styles';
import { TranslationKeys } from '../../../../../../translations';

export const SCALE_TABLE_FORM_ERROR_DUPLICATE_TITLE = 'rateTable.error.duplicateTitle';
export const SCALE_TABLE_FORM_ERROR_MISSING_INTERVAL = 'rateTable.error.missingInterval';
export const SCALE_TABLE_FORM_ERROR_DUPLICATE_INTERVALS = 'rateTable.error.duplicateIntervals';

type ScalesTableFormErrorsProps = {
  errors: Record<string, any>;
};

export const ScalesTableFormErrors = ({ errors }: ScalesTableFormErrorsProps) => {
  const intl = useIntl();

  const errorsToShow = React.useMemo(() => {
    if (Object.keys(errors).length === 0) {
      return [];
    }

    return Object.keys(errors).reduce<string[]>((acc, key) => {
      const error = errors[key as keyof typeof errors];

      if (error?.code === SCALE_TABLE_FORM_ERROR_DUPLICATE_TITLE) {
        acc.push(intl.formatMessage({ id: TranslationKeys.settings_rateTable_error_duplicateTitle }));
      }
      if (error?.code === SCALE_TABLE_FORM_ERROR_MISSING_INTERVAL) {
        const interval = error.message.slice(13);
        acc.push(intl.formatMessage({ id: TranslationKeys.settings_rateTable_error_missingInterval }, { interval }));
      }
      if (error?.code === SCALE_TABLE_FORM_ERROR_DUPLICATE_INTERVALS) {
        const interval = error.message.slice(49);
        acc.push(intl.formatMessage({ id: TranslationKeys.settings_rateTable_error_duplicateIntervals }, { interval }));
      }

      return acc;
    }, []);
  }, [errors, intl]);

  if (errorsToShow.length === 0) {
    return null;
  }

  return (
    <AlertStyled severity="error">
      <FormErrorList>
        {errorsToShow.map((error, idx) => (
          <FormErrorListItem key={idx}>
            <Typography variant={'body2'} color={'inherit'} lineHeight={'1.5em'}>
              {error}
            </Typography>
          </FormErrorListItem>
        ))}
      </FormErrorList>
    </AlertStyled>
  );
};
