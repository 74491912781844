import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import Skeleton from '@mui/material/Skeleton';
import FolderIcon from '@mui/icons-material/Folder';

import { formatTimestamp } from '../../../../utils/formatter/dateTimeFormatter';
import { formatPath } from '../../../../utils/fileSystemHelper';

import { DivStyled } from './InvoincingTable.styles';

const InvoicingTable = props => {
  const { className, reports, breadCrumbs, rowsPerPage, loading, goToFolder, downloadReport } = props;

  return (
    <Card className={className}>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: '10px' }}>
          {breadCrumbs.map((breadCrumb, idx, arr) => (
            <Chip
              key={breadCrumb.path}
              color={idx === arr.length - 1 ? 'primary' : 'inherit'}
              label={breadCrumb.name}
              onClick={() => {
                goToFolder(breadCrumb.path);
              }}
            />
          ))}
        </Breadcrumbs>
      </div>

      <CardContent sx={{ padding: 0 }}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="reports.name" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="reports.createdAt" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="reports.actions" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  new Array(rowsPerPage)
                    .fill(index => index)
                    .map((value, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                {!loading &&
                  reports.map(report => (
                    <TableRow hover key={report.path}>
                      <TableCell>
                        {report.type === 'dir' ? (
                          <DivStyled
                            role="presentation"
                            onClick={() => {
                              goToFolder(report.path);
                            }}
                          >
                            <FolderIcon />
                            <Typography variant="body1">{formatPath(report.path)}</Typography>
                          </DivStyled>
                        ) : (
                          <DivStyled>
                            <Typography variant="body1">{formatPath(report.path)}</Typography>
                          </DivStyled>
                        )}
                      </TableCell>
                      <TableCell>{formatTimestamp(report.last_modified)}</TableCell>
                      <TableCell>
                        {report.type !== 'dir' && (
                          <Button onClick={() => downloadReport(report.path)}>
                            <FormattedMessage id="reports.download" />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <TableFooter>
          <TableRow>
            <TableCell>{`Results: ${reports.length}`}</TableCell>
          </TableRow>
        </TableFooter>
      </CardActions>
    </Card>
  );
};

InvoicingTable.propTypes = {
  className: PropTypes.string,
  reports: PropTypes.array.isRequired,
};

export default InvoicingTable;
