import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const TypographyStyled = styled(Typography, {
  name: 'TypographyStyled',
})(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const DivHeaderActionsStyled = styled('div', {
  name: 'DivHeaderActionsStyled',
})(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
}));
