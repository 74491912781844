import React from 'react';
import PropTypes from 'prop-types';
import { FieldStyled } from './FormikRoundCheckbox.styles';

const FormikRoundCheckbox = props => {
  const { name, value } = props;

  return (
    <div>
      <FieldStyled type="checkbox" name={name} value={value} id={value} />
      <label htmlFor={value} />
    </div>
  );
};

FormikRoundCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

export default FormikRoundCheckbox;
