import { styled } from '@mui/material/styles';

export const DivButtonsContainerStyled = styled('div', {
  name: 'DivButtonsContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));
