import React from 'react';

import { Box } from '@mui/material';

import { TranslationKeys } from 'app/translations';
import { ActionButton, CustomBreadcrumb } from 'app/components';
import { SCALES_TABLE_FORM_ID } from 'app/Domain/Settings/Components';

type SettingsSummaryScalesCreateToolbarProps = {
  isLoading?: boolean;
};

export const SettingsSummaryScalesCreateToolbar = ({ isLoading = false }: SettingsSummaryScalesCreateToolbarProps) => {
  const breadcrumbItems = React.useMemo(() => {
    return [
      {
        title: TranslationKeys.global_createNew,
      },
      {
        title: TranslationKeys.menu_settings_summaryScales,
        href: '/settings/summary-scales',
      },
    ];
  }, []);

  return (
    <Box paddingTop={2} display={'flex'} justifyContent={'space-between'}>
      <CustomBreadcrumb items={breadcrumbItems} />
      <ActionButton
        messageId={TranslationKeys.button_create}
        type={'submit'}
        form={SCALES_TABLE_FORM_ID}
        disabled={isLoading}
      />
    </Box>
  );
};
