import { getIn } from 'formik';
import { deburr, isNil, isString, orderBy, parseValue } from 'app/utils';
import type { ApiField, CredentialSettings, Field } from '../../../types';
import type { IntlShape } from 'react-intl/src/types';

export enum SettingsFieldGroup {
  EmployeeList = 'employee_list',
  Insurer = 'insurer',
  Connection = 'connection',
  Rules = 'rules',
  Advanced = 'advanced',
}

const mapSettingsFieldType = (type: string): string => {
  if (type === 'string') {
    return 'text';
  }
  if (type === 'float') {
    return 'number';
  }
  if (type === 'tags') {
    return 'autocomplete';
  }
  if (type === 'defaultEmail') {
    return 'email';
  }
  if (type === 'listUpload') {
    return 'select';
  }
  return type;
};

function getSelectionItems(intl: IntlShape, field: ApiField): any[] {
  let options = (field.fieldOptions?.items || []).map((option: any) => {
    if (isString(option)) {
      return {
        value: option,
      };
    }

    const { key, label, value } = option;
    const element = (intl.messages[key] ? key : label) || key;

    return {
      element: isNil(element) && value === '' ? intl.messages['none'] : element,
      value: isNil(value) ? '' : value,
    };
  });

  if (field.fieldName === 'defaultAddressCountry') {
    const intlRegionFactory = new Intl.DisplayNames([intl.locale], { type: 'region' });
    const filteredOptions = options.filter(item => item.value !== '');
    options = filteredOptions.map(option => ({
      element: intlRegionFactory.of(option.value),
      value: option.value,
    }));
    options = orderBy(options, [(o: any) => deburr((o.element ?? '').toLocaleLowerCase())], ['asc']);
  }

  return options;
}

export const mapSettingsField = <TSetting extends CredentialSettings = CredentialSettings>(
  intl: IntlShape,
  settings: TSetting,
  headerPrefix: string,
  loading: boolean,
  formValues: any,
  currentValues: any | null,
  group: SettingsFieldGroup | null = SettingsFieldGroup.Connection,
): Field[] => {
  const filterGroup = group ?? SettingsFieldGroup.Connection;
  const filteredFields = settings.fields.filter(field => {
    const fg = field?.fieldOptions?.group ?? SettingsFieldGroup.Connection;
    return fg === filterGroup;
  });

  return filteredFields.map(field => {
    const fieldName = `packages.${settings.packageType}.${field.fieldName}`;
    const disabled = parseValue(field.fieldOptions.disabled, 'boolean', false);
    const currentValue = getIn(formValues, fieldName) ?? (currentValues || {})[field.fieldName];
    const defaultValue = field.fieldOptions.default ?? null;
    const basicField: Field = {
      name: fieldName,
      header: `${headerPrefix}${field.fieldName}`,
      type: mapSettingsFieldType(field.fieldType || 'text'),
      required: parseValue(field.fieldOptions.required, 'boolean', false),
      disabled: parseValue(field.fieldOptions.disabled, 'boolean', false),
      defaultValue: disabled ? defaultValue : currentValue ?? defaultValue, // when disabled we take whatever the backend send us
      loading: loading,
      options: {},
    };

    if (['tags'].includes(field.fieldType)) {
      basicField.sizeHint = 'large';
      basicField.options!.freeSolo = true;
      basicField.options!.multiple = true;
    }

    if (['float'].includes(field.fieldType)) {
      basicField.options!.openScale = true;
    }

    if (['select', 'autocomplete', 'template', 'tags'].includes(field.fieldType)) {
      basicField.items = getSelectionItems(intl, field);
    }

    basicField.options!.min = field.fieldOptions?.min;
    basicField.options!.max = field.fieldOptions?.max;
    basicField.options!.match = field.fieldOptions?.match;
    basicField.options!.size = field.fieldOptions?.size;

    return basicField;
  });
};
