import React from 'react';

import { TranslationKeys } from '../../translations';

import type { SelectedAppState } from '../../store';
import { useStoreSelector } from '../../store';

import ActionButton from '../ActionButton';
import { Dialog } from '../Dialog';

type ViewDialogProps = {
  children: React.ReactElement;
  title: React.ReactElement | string;
  open: boolean;
  onClose: () => void;
  setOpen?: (state: boolean) => void;
  closeLabel?: string;
  closeButton?: boolean;
};

export const ViewDialog = ({
  title,
  children,
  open,
  setOpen,
  onClose,
  closeLabel = TranslationKeys.close,
  closeButton = true,
}: ViewDialogProps) => {
  const { loading: appLoading } = useStoreSelector<SelectedAppState>(state => state.AppReducer);

  const [loading, setLoading] = React.useState(false);

  const onCloseButtonClick = React.useCallback(() => {
    setLoading(true);
    setOpen && setOpen(false);
    onClose();
  }, [onClose, setOpen]);

  const DialogActions = React.useMemo(() => {
    if (closeButton) {
      return <ActionButton onClick={onCloseButtonClick} disabled={loading || appLoading} messageId={closeLabel} />;
    }
    return undefined;
  }, [appLoading, closeButton, closeLabel, loading, onCloseButtonClick]);

  return (
    <Dialog open={open} maxWidth={'sm'} title={title} onClose={onClose} actions={DialogActions}>
      {children}
    </Dialog>
  );
};
