import type { PaletteOptions } from '@mui/material/styles/createPalette';

const palette: PaletteOptions = {
  info: {
    dark: '#0D47A1',
    main: '#1E88E5',
    light: '#42A5F5',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#002C72',
    secondary: '#546E7A',
  },
  error: {
    dark: '#DF7070',
    main: '#DF7070',
    light: '#F5D4D4',
    contrastText: '#FFFFFF',
  },
  divider: '#EEEEEE',
  primary: {
    dark: '#002C72',
    main: '#00A0FF',
    light: '#E4F4F9',
    contrastText: '#E4F4F9',
  },
  success: {
    dark: '#40E4A1',
    main: '#40E4A1',
    light: '#C5F6E2',
    contrastText: '#FFFFFF',
  },
  warning: {
    dark: '#F3AE43',
    main: '#F3AE43',
    light: '#FBE6C6',
    contrastText: '#FFFFFF',
  },
  secondary: {
    dark: '#8BADBF',
    main: '#C5D9E2',
    light: '#E4F4F9',
    contrastText: '#FFFFFF',
  },
  background: {
    paper: '#FFFFFF',
    default: '#F4F6F8',
  },
};

export default palette;
