import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Divider, IconButton } from '@mui/material';

import { ActionButton } from '../../../../../../../components/ActionButton';

const commonButtonStyles = (theme: Theme) =>
  ({
    color: theme.palette.primary.main,
    float: 'right',
    marginTop: theme.spacing(1),
  } as const);

export const IconButtonStyled = styled(IconButton, {
  name: 'IconButtonStyled',
})(({ theme }) => ({
  ...commonButtonStyles(theme),
  color: theme.palette.primary.main,
  fontSize: '1em',
}));

export const ActionButtonStyled = styled(ActionButton, {
  name: 'ActionButtonStyled',
})(({ theme }) => ({
  ...commonButtonStyles(theme),
}));

export const DividerStyled = styled(Divider, {
  name: 'DividerStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: '100%',
}));
