import { styled } from '@mui/material/styles';
import { FormLabel, Grid, Paper } from '@mui/material';

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textTransform: 'uppercase',
}));

export const PaperStyled = styled(Paper, {
  name: 'PaperStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const GridItemPhoneStyled = styled(Grid, {
  name: 'GridItemPhoneStyled',
})(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));

export const GridItemQRStyled = styled(Grid, {
  name: 'GridItemQRStyled',
})(({ theme }) => ({
  display: 'grid',
  padding: theme.spacing(2),
}));

export const ImgQRStyled = styled('img', {
  name: 'ImgQRStyled',
})(() => ({
  width: '125px',
}));
