import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box, Collapse, Grid, IconButton } from '@mui/material';
import { faChevronDown, faChevronUp, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';

import type { Dictionary } from 'app/utils';
import { isNil, isObject } from 'app/utils';

import { IntlMessage } from '../Intl';
import { InputFieldFactory, RenderFieldFactory } from '../FormikField';

import { DetailsPanelRowValueWithReset } from './DetailsPanelRowValueWithReset';
import {
  FontAwesomeIconStyled,
  ListItemStyled,
  ListStyled,
  ListSubheaderStyled,
  SpanFieldLabelStyled,
  SpanFiledValueStyled,
} from './DetailsPanelRow.styles';

export const DETAILS_PANEL_ROW_DATA_TEST_ID = 'details-panel-row';
export const DETAILS_PANEL_ROW_DELETE_BUTTON_DATA_TEST_ID = 'details-panel-row-delete-button';
export const DETAILS_PANEL_ROW_EDIT_BUTTON_DATA_TEST_ID = 'details-panel-row-edit-button';
export const DETAILS_PANEL_ROW_EXPAND_BUTTON_DATA_TEST_ID = 'details-panel-row-expand-button';

type OnExpandedFunc = ({ id, expanded }: { id: number; expanded: boolean }) => void;

type DetailsPanelRowProps = {
  rows: Dictionary<any>;
  expanded?: boolean;
  id?: number;
  title?: React.ReactElement | string;
  config?: any;
  loading?: boolean;
  disabled?: boolean;
  onExpand?: OnExpandedFunc;
  onEditButtonClick?: () => void;
  onDeleteButtonClick?: () => void;
  disabledDeleteButton?: boolean;
  disabledEditButton?: boolean;
};

const DetailsPanelRow = ({
  rows,
  id,
  title,
  config,
  onExpand,
  expanded,
  onEditButtonClick,
  onDeleteButtonClick,
  loading = false,
  disabled = false,
  disabledDeleteButton = false,
  disabledEditButton = false,
}: DetailsPanelRowProps) => {
  const isExpandable = typeof expanded !== 'undefined' && expanded !== null;
  const hasHeader = !!title || isExpandable;
  const initialState = isExpandable ? expanded : true;
  const skeletonRows = config?.skeletonRows || 5;

  const [visible, setVisible] = React.useState(initialState);

  React.useEffect(() => {
    setVisible(initialState);
  }, [initialState]);

  const onClick = React.useCallback(
    event => {
      event.stopPropagation();
      const newState = !visible;
      setVisible(newState);
      if (onExpand && typeof id === 'number') {
        onExpand({ id, expanded: visible });
      }
    },
    [id, onExpand, visible],
  );

  const skeletonRowElements = React.useMemo(() => {
    return new Array(skeletonRows).fill(null);
  }, [skeletonRows]);

  const renderFieldLabel = React.useCallback(
    (row, key) => {
      if (loading) {
        return <Skeleton width={'100%'} height={'100%'} />;
      }

      const shouldRenderInputLabel = row?.editable && !!row?.label;
      const isLabelResettable = !!row?.label?.onReset;
      const isLabelHighlighted = row?.label?.highlight || false;

      if (shouldRenderInputLabel) {
        const labelFieldObject = {
          type: 'text',
          ...row.label,
          disabled: row.label.disabled || disabled,
        };

        return <InputFieldFactory field={labelFieldObject} />;
      }

      const label = (
        <SpanFieldLabelStyled disabled={disabled} highlighted={isLabelHighlighted}>
          <IntlMessage value={key} />
        </SpanFieldLabelStyled>
      );

      if (isLabelResettable) {
        return <DetailsPanelRowValueWithReset value={label} onReset={row.label.onReset} />;
      }

      return label;
    },
    [disabled, loading],
  );

  const renderValue = React.useCallback(
    row => {
      if (loading) {
        return <Skeleton width={'100%'} height={'100%'} />;
      }

      let rowObj = row;
      const isFieldObject = isObject(row) && !isNil(row?.field);
      const isValueResettable = !!row?.field?.onReset;
      const isValueHighlighted = row?.field?.highlight || false;

      if (!isFieldObject) {
        rowObj = {
          field: {
            value: row,
          },
        };
      }

      rowObj.field.disabled = rowObj.field.disabled || disabled;
      delete rowObj.field.highlight;

      if (rowObj.editable === true) {
        return <InputFieldFactory field={rowObj.field} />;
      }

      const value = (
        <SpanFiledValueStyled disabled={rowObj.field.disabled} highlighted={isValueHighlighted}>
          <RenderFieldFactory field={rowObj.field} />
        </SpanFiledValueStyled>
      );

      if (isValueResettable) {
        return <DetailsPanelRowValueWithReset value={value} onReset={row.field.onReset} />;
      }

      return value;
    },
    [disabled, loading],
  );

  const renderedRows = React.useMemo(() => {
    let rowsToRender = rows || {};
    const rowKeys = Object.keys(rowsToRender) || [];

    if (rowKeys.length === 0 && loading) {
      rowsToRender = skeletonRowElements;
    }

    return Object.keys(rowsToRender).map((key, rowIndex) => {
      const row = rowsToRender[key] ?? null;
      const label = renderFieldLabel(row, key);
      const value = renderValue(row);
      const hasKey = !Array.isArray(rowsToRender) && key;

      return (
        <ListItemStyled key={`${rowIndex}`}>
          <Grid container spacing={1}>
            {hasKey && (
              <Grid item xs={12} sm={5}>
                {label}
              </Grid>
            )}
            <Grid item xs={12} sm={hasKey ? 7 : 12}>
              {value}
            </Grid>
          </Grid>
        </ListItemStyled>
      );
    });
  }, [rows, loading, skeletonRowElements, renderFieldLabel, renderValue]);

  const subHeader = React.useMemo(() => {
    if (!hasHeader) {
      return undefined;
    }

    return (
      <ListSubheaderStyled>
        <Box>{title}</Box>
        {(isExpandable || !!onEditButtonClick || !!onDeleteButtonClick) && (
          <Box>
            {!!onDeleteButtonClick && (
              <IconButton
                data-testid={DETAILS_PANEL_ROW_DELETE_BUTTON_DATA_TEST_ID}
                color="primary"
                onClick={onDeleteButtonClick}
                disabled={disabledDeleteButton}
                size="small"
              >
                <FontAwesomeIconStyled icon={faTrash} />
              </IconButton>
            )}
            {!!onEditButtonClick && (
              <IconButton
                data-testid={DETAILS_PANEL_ROW_EDIT_BUTTON_DATA_TEST_ID}
                color="primary"
                onClick={onEditButtonClick}
                disabled={disabledEditButton}
                size="small"
              >
                <FontAwesomeIconStyled icon={faPenToSquare} />
              </IconButton>
            )}
            {isExpandable && (
              <IconButton
                data-testid={DETAILS_PANEL_ROW_EXPAND_BUTTON_DATA_TEST_ID}
                color="primary"
                onClick={onClick}
                size="small"
              >
                <FontAwesomeIconStyled icon={visible ? faChevronUp : faChevronDown} />
              </IconButton>
            )}
          </Box>
        )}
      </ListSubheaderStyled>
    );
  }, [
    disabledDeleteButton,
    disabledEditButton,
    hasHeader,
    isExpandable,
    onClick,
    onDeleteButtonClick,
    onEditButtonClick,
    title,
    visible,
  ]);

  return (
    <>
      <ListStyled data-testid={DETAILS_PANEL_ROW_DATA_TEST_ID} subheader={subHeader}>
        <Collapse in={visible}>{renderedRows}</Collapse>
      </ListStyled>
    </>
  );
};

export default React.memo(DetailsPanelRow);
