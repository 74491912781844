import type { Theme } from '@mui/material/styles';

import { MuiAutocomplete } from './MuiAutocomplete';
import { MuiButton } from './MuiButton';
import { MuiButtonGroup } from './MuiButtonGroup';
import { MuiChip } from './MuiChip';
import { MuiDialog } from './MuiDialog';
import { MuiDialogContent } from './MuiDialogContent';
import { MuiDialogTitle } from './MuiDialogTitle';
import { MuiFormControl } from './MuiFormControl';
import { MuiFormLabel } from './MuiFormLabel';
import { MuiIconButton } from './MuiIconButton';
import { MuiInputBase } from './MuiInputBase';
import { MuiInputLabel } from './MuiInputLabel';
import { MuiLinearProgress } from './MuiLinearProgress';
import { MuiListItem } from './MuiListItem';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiPaper } from './MuiPaper';
import { MuiOutlinedInput } from './MuiOutlinedInput';
import { MuiTab } from './MuiTab';
import { MuiTabs } from './MuiTabs';
import { MuiTableCell } from './MuiTableCell';
import { MuiTableHead } from './MuiTableHead';
import { MuiTablePagination } from './MuiTablePagination';
import { MuiTableRow } from './MuiTableRow';
import { MuiTypography } from './MuiTypography';
import { MuiSelect } from './MuiSelect';
import { MuiSvgIcon } from './MuiSvgIcon';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiSwitch } from './MuiSwitch';
import { MuiDialogActions } from './MuiDialogActions';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiTextField } from './MuiTextField';

const overrides = (theme: Theme) => ({
  MuiAutocomplete: MuiAutocomplete(theme),
  MuiButton: MuiButton(theme),
  MuiButtonGroup: MuiButtonGroup(theme),
  MuiChip: MuiChip(theme),
  MuiDialog: MuiDialog(theme),
  MuiDialogContent: MuiDialogContent(theme),
  MuiDialogTitle: MuiDialogTitle(theme),
  MuiDialogActions: MuiDialogActions(theme),
  MuiFormControl: MuiFormControl(theme),
  MuiFormLabel: MuiFormLabel(theme),
  MuiFormHelperText: MuiFormHelperText(theme),
  MuiIconButton: MuiIconButton(theme),
  MuiInputBase: MuiInputBase(theme),
  MuiInputLabel: MuiInputLabel(theme),
  MuiLinearProgress: MuiLinearProgress(theme),
  MuiListItem: MuiListItem(theme),
  MuiMenuItem: MuiMenuItem(theme),
  MuiOutlinedInput: MuiOutlinedInput(theme),
  MuiPaper: MuiPaper(theme),
  MuiSelect: MuiSelect(theme),
  MuiSvgIcon: MuiSvgIcon(theme),
  MuiTab: MuiTab(theme),
  MuiTabs: MuiTabs(theme),
  MuiTableCell: MuiTableCell(theme),
  MuiTableHead: MuiTableHead(theme),
  MuiTablePagination: MuiTablePagination(theme),
  MuiTableRow: MuiTableRow(theme),
  MuiTypography: MuiTypography(theme),
  MuiSwitch: MuiSwitch(theme),
  MuiCssBaseline: MuiCssBaseline(theme),
  MuiTextField: MuiTextField(theme),
});

export default overrides;
