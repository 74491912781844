import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { UseQueryWrapper, UserOwner } from 'app/types';

import type { GetUserEnvironmentParams } from 'app/Domain/Owners/Services/OwnerService';
import { ownerService } from 'app/Domain/Owners/Services/OwnerService';

import { useSnakeBar } from 'app/hooks/useSnakeBar';

type GetUserEnvironmentQueryVariables = GetUserEnvironmentParams;

const queryFn = (variables: GetUserEnvironmentQueryVariables) => async () => {
  const response = await ownerService.getUserEnvironment(variables.userId);

  return (response as AxiosResponse).data;
};

const QUERY_KEY = 'getUserEnvironment';

export const useUserGetEnvironmentsQuery: UseQueryWrapper<
  Array<UserOwner> | undefined,
  GetUserEnvironmentQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return data['hydra:member'];
  }, []);

  return useQuery<Array<UserOwner> | undefined>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
