import { styled } from '@mui/material/styles';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
  shouldForwardProp: (propName: string) => !['innerRow'].includes(propName),
})<{ innerRow: boolean }>(({ theme, innerRow }) => ({
  textAlign: innerRow ? 'left' : 'center',
  padding: theme.spacing(innerRow ? 1 : 4),
  opacity: 0.38,
}));
