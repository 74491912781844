import { put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { downloadFile, getFiles, deleteFile } from '../Services/DownloadsService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { forceDownload } from '../../../utils';

export const actionTypes = {
  InitializeFilesState: '[Files] Initialize state',
  RequestData: '[Files] Request',
  FulfilledTable: '[Files] Fulfilled',
  SearchTable: '[Files] Search',
  ChangePage: '[Files] change page',
  SetPageSize: '[Files] set page size',
  SortTable: '[Files] Sort',
  ApplyFilter: '[Files] Apply filter',
  RemoveFilter: '[Files] Remove filter',
  SetConnectionId: '[Files] Set connection id',
  ToggleReport: '[Files] Toggle report',
  ToggleAllFiles: '[Files] Toggle all Files',
  ClearAll: '[Files] Clear all',
  GetFiles: '[Files] Get files',
  DownloadFile: '[Files] Download file',
  DeleteFile: '[Files] Delete file',
};

const initialState = {
  ...tableInitialState,
  path: 'reports',
  breadCrumbs: [],
  connectionId: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.InitializeFilesState: {
      return { ...initialState };
    }
    case actionTypes.GetFiles: {
      return {
        ...state,
      };
    }
    case actionTypes.RequestData: {
      return {
        ...state,
        selectedRowsIds: [],
        loading: true,
        error: false,
      };
    }
    case actionTypes.SetConnectionId: {
      return {
        ...state,
        connectionId: action.connectionId,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initializeFilesState: () => ({
    type: actionTypes.InitializeFilesState,
  }),
  toggleAllFiles: events => ({
    type: actionTypes.ToggleAllFiles,
    events,
  }),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
  downloadFile: filePath => ({
    type: actionTypes.DownloadFile,
    filePath,
  }),
  deleteFile: filePath => ({
    type: actionTypes.DeleteFile,
    filePath,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* request() {
    const currentState = yield select(state => {
      return state.DownloadsReducer;
    });

    try {
      const response = yield getFiles(
        currentState.connectionId,
        currentState.page,
        currentState.pageSize,
        currentState.sortBy,
        currentState.filters,
        currentState.searchQuery,
        currentState.path,
      );

      yield put(actions.fulfilled(response.data.listing, response.data.listing.length));
    } catch (error) {
      let method = 'getFiles';

      if (error.response.status === 403) {
        method = 'access';
      }

      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method,
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.DownloadFile, function* download(action) {
    try {
      const response = yield downloadFile(action.filePath);
      const fileName = action.filePath.split('/');
      forceDownload(response.data, fileName[fileName.length - 1]);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'downloadFile',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.DeleteFile, function* deleteCurrentFile(action) {
    const currentState = yield select(state => {
      return state.DownloadsReducer;
    });
    try {
      const response = yield deleteFile(currentState.connectionId, action.filePath);

      if (response.status === 204) {
        yield put(actions.requestData());
      }
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'downloadFile',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });
}
