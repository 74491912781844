import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

export const DivContainerStyled = styled('div', {
  name: 'DivContainerStyled',
})(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const DivInputContainerStyled = styled('div', {
  name: 'DivInputContainerStyled',
})(() => ({
  maxWidth: '50em',
}));

export const LinearProgressStyled = styled(LinearProgress, {
  name: 'LinearProgressStyled',
})(() => ({
  maxWidth: '50em',
}));

export const DivListContainerStyled = styled('div', {
  name: 'DivListContainerStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const ListStyled = styled('ul', {
  name: 'ListStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  listStyleType: 'none',
}));

export const ListItemStyled = styled('li', {
  name: 'ListItemStyled',
})(({ theme }) => ({
  minHeight: '1.5em',
  marginBottom: theme.spacing(1),
  '& a': {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));
