import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { skeletonClasses, typographyClasses } from '@mui/material';

export const NavLinkStyled = styled(NavLink, {
  name: 'NavLinkStyled',
})(() => ({
  display: 'flex',
}));

export const DivCrumbsStyled = styled('div', {
  name: 'DivCrumbsStyled',
})(({ theme }) => ({
  display: 'flex',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [`& .${typographyClasses.root}`]: {
    marginLeft: theme.spacing(1),
  },
  [`& .${skeletonClasses.root}`]: {
    marginLeft: theme.spacing(1),
  },
}));

export const DivHeaderStyled = styled('div', {
  name: 'DivHeaderStyled',
})(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
}));
