import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Box, Divider, Grid, Link, MenuItem, Paper, Skeleton, Switch, Typography } from '@mui/material';

import { Uuid } from '../../../../utils/uuid';

import { convertFileToBase64, normalizeError } from 'app/utils';

import { actions as AppActions } from '../../../App/Ducks/App.duck';
import { MESSAGE_SEVERITY_ERROR } from '../../../../common/constants';

import ownerService from '../../Services/OwnerService';

import { useBreadcrumb } from 'app/components/Breadcrumbs';

import CreateOwnerForm from '../../Forms/CreateOwnerForm';
import CreateSubOwnerForm from '../../Forms/CreateSubOwnerForm';

import {
  ButtonStyled,
  DividerStyled,
  FormLabelStyled,
  PaperInfoStyled,
  PaperSubStyled,
  TextFieldStyled,
  TypographyDescriptionStyled,
  TypographyTotalCountStyled,
  TypographyWithMarginBottomStyled,
} from './OwnerAddPage.styles';

const OwnerAddPage = () => {
  // uses
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // data
  const [owner, setOwner] = useState({
    ownerId: Uuid.newV4(),
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    address: '',
    zipCode: '',
    city: '',
    parentId: null,
    numberOfEmployees: 0,
    subOwners: [],
    logo: '',
  });

  const [ownerIsHolding, setOwnerIsHolding] = useState(false);
  const [ownerIsSubOwner, setOwnerIsSubOwner] = useState(false);
  const [ownersTotalCount, setOwnersTotalCount] = useState({});
  const [ownersHolding, setOwnersHolding] = useState([]);

  const userId = useSelector(state => state.AppReducer.user.userId);

  const loadOwnersHolding = useCallback(async () => {
    try {
      const response = await ownerService.getOwners();

      setOwnersHolding(response.data['hydra:member']);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getOwnersTotalCount',
        severity,
      };
      dispatch(AppActions.displayMessage(details));
    }
  }, [dispatch]);

  const saveOwner = useCallback(async values => {
    const valuesToSubmit = { ...values };

    if (values.logo && typeof values.logo !== 'string') {
      valuesToSubmit.logo = await convertFileToBase64(values.logo);
    }

    return ownerService.createOwner(valuesToSubmit);
  }, []);

  const loadTotalCount = useCallback(async () => {
    try {
      const response = await ownerService.getOwnersTotalCount(userId);
      setOwnersTotalCount(response.data);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getOwnersTotalCount',
        severity,
      };
      dispatch(AppActions.displayMessage(details));
    }
  }, [dispatch, userId]);

  // effects
  useEffect(() => {
    loadTotalCount();
    loadOwnersHolding();
  }, [loadOwnersHolding, loadTotalCount]);

  // events
  const handleChange = useCallback(
    (field, value) => {
      const newOwner = { ...owner };
      newOwner[field] = value;

      setOwner(newOwner);
    },
    [owner],
  );

  const handleChangeParent = useCallback(
    event => {
      setOwner({
        ...owner,
        parentId: event.target.value,
      });
    },
    [owner],
  );

  const handleCancel = useCallback(() => {
    navigate('../');
  }, [navigate]);

  const handleCreateOwner = useCallback(async () => {
    saveOwner(owner)
      .then(() => navigate('../'))
      .catch(err => {
        const errors = normalizeError(err);
        const severity = MESSAGE_SEVERITY_ERROR;
        const details = {
          method: 'createOwner',
          severity,
          message: errors.message,
        };
        dispatch(AppActions.displayMessage(details));
      });
  }, [dispatch, navigate, owner, saveOwner]);

  const handleSwitchOwnerIsHolding = useCallback(() => {
    if (!ownerIsHolding && !owner.subOwners.length) {
      owner.subOwners.push({
        ownerId: Uuid.newV4(),
        ownerName: '',
        ownerEmail: '',
        ownerPhone: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        address: '',
        zipCode: '',
        city: '',
        numberOfEmployees: 0,
        parentId: owner.ownerId,
      });
    } else {
      owner.subOwners = [];
    }

    setOwnerIsHolding(!ownerIsHolding);
    setOwnerIsSubOwner(false);
  }, [owner, ownerIsHolding]);

  const handleSwitchOwnerIsSubOwner = useCallback(() => {
    setOwnerIsHolding(false);
    setOwnerIsSubOwner(!ownerIsSubOwner);
  }, [ownerIsSubOwner]);

  const handleClickAddAnother = useCallback(() => {
    owner.subOwners.push({
      ownerId: Uuid.newV4(),
      ownerName: '',
      ownerEmail: '',
      ownerPhone: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      address: '',
      zipCode: '',
      city: '',
      numberOfEmployees: 0,
      parentId: owner.ownerId,
    });
  }, [owner]);

  const handleDeleteSubOwner = useCallback(
    ownerId => {
      const newSubOwners = owner.subOwners.filter(se => se.ownerId !== ownerId);

      setOwner({
        ...owner,
        subOwners: newSubOwners,
      });
    },
    [owner],
  );

  const title = useMemo(() => {
    return (
      <div>
        <Typography variant="h5">
          <FormattedMessage id="owners.add" />
        </Typography>
        <Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }}>
          {typeof ownersTotalCount?.total !== 'undefined' ? (
            <FormattedMessage id="owners.totalCount" values={{ totalCount: ownersTotalCount.total }} />
          ) : (
            <Skeleton width={200} />
          )}
        </Typography>
      </div>
    );
  }, [ownersTotalCount]);

  useBreadcrumb('owners.add', {
    hideTrail: true,
    titleElement: title,
  });

  return (
    <Paper elevation={0} square>
      <Grid container spacing={3}>
        {/* owner form */}
        <Grid item xs={6}>
          <PaperInfoStyled>
            <CreateOwnerForm
              owner={owner}
              ownerIsHolding={false}
              ownerShowHolding={false}
              onOwnerChange={handleChange}
            />
          </PaperInfoStyled>
        </Grid>

        {/* owner additional info */}
        <Grid item xs={6}>
          <PaperSubStyled>
            {/* type switches */}
            <div>
              <TypographyWithMarginBottomStyled variant="body1">
                <FormattedMessage id="owners.holding.question" values={{ b: chunks => <b>{chunks}</b> }} />
              </TypographyWithMarginBottomStyled>
              <FormLabelStyled>
                <FormattedMessage id="owners.holding" />
              </FormLabelStyled>
              <div>
                <Switch checked={ownerIsHolding} onChange={handleSwitchOwnerIsHolding} />
              </div>
            </div>

            {!ownerIsHolding && (
              <div>
                <TypographyWithMarginBottomStyled variant="body1">
                  <FormattedMessage id="owners.holdingPart.question" values={{ b: chunks => <b>{chunks}</b> }} />
                </TypographyWithMarginBottomStyled>
                <FormLabelStyled>
                  <FormattedMessage id="owners.holdingPart" />
                </FormLabelStyled>
                <div>
                  <Switch checked={ownerIsSubOwner} onChange={handleSwitchOwnerIsSubOwner} />
                </div>
              </div>
            )}

            {/* owner HAS subOwners */}
            {!!ownerIsHolding && (
              <TypographyTotalCountStyled variant="body1">
                <FormattedMessage
                  id="owners.add.subOwners.description"
                  values={{ totalCount: ownersTotalCount.total }}
                />
              </TypographyTotalCountStyled>
            )}

            {!!ownerIsHolding &&
              !!owner.subOwners.length &&
              owner.subOwners.map((subOwner, subOwnerIndex) => (
                <Grid container key={subOwnerIndex}>
                  <CreateSubOwnerForm owner={owner} subOwner={subOwner} onDelete={handleDeleteSubOwner} />

                  <DividerStyled />
                </Grid>
              ))}

            {!!ownerIsHolding && (
              <Link sx={{ textAlign: 'right', width: '100%' }} href="OwnerAddPage#" onClick={handleClickAddAnother}>
                <Typography variant="h6">
                  <FormattedMessage id="owners.add.subOwners.button" />
                </Typography>
              </Link>
            )}

            {/* owner IS subOwner */}
            {!!ownerIsSubOwner && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormLabelStyled>
                    <FormattedMessage id="owners.add.subOwners.holding" />
                  </FormLabelStyled>
                  <TextFieldStyled fullWidth name="parentId" select variant="outlined" onChange={handleChangeParent}>
                    {!!ownersHolding.length &&
                      ownersHolding.map((owner, ownerIndex) => (
                        <MenuItem key={ownerIndex} value={owner.ownerId}>
                          {owner.ownerName}
                        </MenuItem>
                      ))}

                    <Divider />

                    <TypographyDescriptionStyled variant="body1">
                      <FormattedMessage
                        id="owners.add.subOwners.holding.description"
                        values={{ b: chunks => <b>{chunks}</b>, br: <br /> }}
                      />
                    </TypographyDescriptionStyled>
                  </TextFieldStyled>
                </Grid>
              </Grid>
            )}
          </PaperSubStyled>
        </Grid>

        {/* buttons */}
        <Grid item xs={12}>
          <Box textAlign="right">
            <ButtonStyled onClick={handleCancel} variant="outlined">
              <FormattedMessage id="button.cancel" />
            </ButtonStyled>

            <ButtonStyled disabled={!owner.ownerName} onClick={handleCreateOwner}>
              <FormattedMessage id="owners.add.createNewOwner" />
            </ButtonStyled>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OwnerAddPage;
