import { isEmpty } from './Lodash';

export type ConvertPaginationFilterOrderAndSearchFromReduxStateToRequestPayloadParams = {
  page?: number;
  pageSize?: number;
  sortBy?: Record<string, unknown>;
  filters?: Record<string, unknown>;
  searchQuery?: string;
};

export const convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload = ({
  page,
  pageSize,
  sortBy,
  filters,
  searchQuery,
}: ConvertPaginationFilterOrderAndSearchFromReduxStateToRequestPayloadParams) => {
  return {
    page,
    perPage: pageSize,
    searchQuery: isEmpty(searchQuery) ? undefined : searchQuery,
    ...(filters || {}),
    ...Object.keys(sortBy || {}).reduce<Record<string, unknown>>((result, key) => {
      if (sortBy) {
        const direction = sortBy[key] || null;

        if (direction) {
          result[`order[${key}]`] = direction;
        }
      }

      return result;
    }, {}),
  };
};
