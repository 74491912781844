import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { Role, UseQueryWrapper } from '../../../types';

import type { GetOwnerRolesParams } from '../../../Domain/Users/Services';
import { roleService } from '../../../Domain/Users/Services';

import { useSnakeBar } from '../../useSnakeBar';

export type GetOwnerRolesQueryVariables = GetOwnerRolesParams;

export type GetOwnerRolesQueryResultData = {
  data: Array<Role>;
  totalCount: number;
};

const queryFn = (variables: GetOwnerRolesQueryVariables) => async () => {
  const response = await roleService.getOwnerRoles(variables);
  return (response as AxiosResponse).data;
};

const QUERY_KEY = 'getOwnerRoles';

export const useOwnerGetOwnerRolesQuery: UseQueryWrapper<GetOwnerRolesQueryResultData, GetOwnerRolesQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetOwnerRolesQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
