import React from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { MainLayout } from './layouts';

import {
  Forgot2FaCodeView,
  ForgotPasswordView,
  NotAllowedView,
  NotFoundView,
  Reset2FaCodeView,
  ResetPasswordView,
  SignInView,
} from './views';

import { OwnerPages } from './Domain/Owners/Pages';
import EmployerPages from './Domain/Employers/Pages';
import ConnectionsPages from './Domain/Connections/Pages';
import { InsurerPages } from './Domain/Insurers/Pages';
import { UserPages } from './Domain/Users/Pages';
import CredentialsPages from 'app/Domain/Credentials/Pages';
import { ReportsPages } from './Domain/Reports/Pages';
import { ToolsPages } from './Domain/Tools/Pages';
import { DashboardPages } from './Domain/Dashboard/Pages/DashboardPages';
import { SettingsPages } from 'app/Domain/Settings/Pages';

const Routes = () => {
  return (
    <RouterRoutes>
      <Route path="" element={<Navigate to="/dashboard" replace />} />
      <Route element={<RouteWithLayout secure layout={MainLayout} />}>
        <Route element={<OwnerPages />} path="/owners/*" />
        <Route element={<OwnerPages />} path="/sub-owners/*" />
        <Route element={<EmployerPages />} path="/employers/*" />
        <Route element={<ConnectionsPages />} path="/connection/*" />
        <Route element={<InsurerPages />} path="/insurers/*" />
        <Route element={<UserPages />} path="/users/*" />
        <Route element={<CredentialsPages />} path="/credential/*" />
        <Route element={<ReportsPages />} path="/reports/*" />
        <Route element={<ToolsPages />} path="/tools/*" />
        <Route element={<DashboardPages />} path="/dashboard/*" />
        <Route element={<SettingsPages />} path="/settings/*" />
      </Route>

      <Route element={<SignInView />} path="/sign-in" />
      <Route element={<ForgotPasswordView />} path="/forgot-password" />
      <Route element={<ResetPasswordView />} path="/reset-password" />
      <Route element={<Forgot2FaCodeView />} path="/forgot-2fa" />
      <Route element={<Reset2FaCodeView />} path="/reset-2fa" />
      <Route element={<NotAllowedView />} path="/not-allowed" />
      <Route element={<NotFoundView />} path="/not-found" />
      <Route path="*" element={<Navigate to="/not-found" />} />
    </RouterRoutes>
  );
};

export default Routes;
