import { styled } from '@mui/material/styles';
import { autocompleteClasses, Divider, FormLabel, Grid, outlinedInputClasses, svgIconClasses } from '@mui/material';
import { Field } from 'formik';

export const GridRootStyled = styled(Grid, {
  name: 'GridRootStyled',
})(({ theme }) => ({
  width: '100%',
  [`& .${autocompleteClasses.popupIndicator} .${svgIconClasses.root}`]: {
    color: theme.palette.primary.main,
  },
}));

export const GridItemStyled = styled(Grid, {
  name: 'GridItemStyled',
})(() => ({
  minHeight: '75px',
}));

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textTransform: 'uppercase',
}));

export const FieldStyled = styled(Field, {
  name: ' FieldStyled',
  shouldForwardProp: (propName: string) => !['displayFlex', 'sizeSmall'].includes(propName),
})<{ displayFlex?: boolean; sizeSmall?: boolean }>(({ theme, displayFlex = false, sizeSmall = false }) => ({
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: '1px solid ' + theme.palette.secondary.main,
  },
  ...(displayFlex ? { display: 'flex' } : {}),
  ...(sizeSmall ? { width: '45%' } : {}),
}));

export const DividerStyled = styled(Divider, {
  name: 'DividerStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  margin: theme.spacing(1),
  width: '100%',
}));
