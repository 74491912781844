import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Switch as BaseField } from '../base';
import type { useFormikContext } from 'formik';
import { Field, getIn } from 'formik';
import { FormControlLabel, FormLabel } from '@mui/material';
import { isNil, parseValue } from 'app/utils';
import { useIntl } from 'react-intl';
import { IntlMessage } from 'app/components/Intl';
import { useFieldValidator } from '../Factory';
import type { SpanStyledProps } from './Switch.styles';
import { SpanStyled } from './Switch.styles';
import type { SwitchProps } from './Switch';

const unsetValue = false;

type InternalSwitchProps = SwitchProps & {
  formik: ReturnType<typeof useFormikContext>;
};

const InternalSwitch = ({ onChange, field, variant = 'default', formik, className }: InternalSwitchProps) => {
  const {
    label,
    name,
    value,
    defaultValue,
    header,
    innerLabel = 'yesNo',
    required = false,
    loading = false,
    disabled = false,
    disableOnChange = false,
    fullWidth = true,
    ...rest
  } = field || {};

  // should be ignored!
  delete rest.schema;
  delete rest.type;

  const intl = useIntl();

  const { initialValues, setFieldValue, setFieldTouched, isSubmitting, values: formikValues } = formik;
  const formikValue = getIn(formikValues, name);

  const actualDefaultValue = useMemo(() => {
    const initialValue = getIn(initialValues, name);
    return isNil(initialValue) ? (isNil(defaultValue) ? unsetValue : defaultValue) : initialValue;
  }, [defaultValue, initialValues, name]);

  const actualValue = useMemo(() => {
    let selectedValue = (isNil(value) ? actualDefaultValue : value) ?? unsetValue;
    if (!isNil(formikValue)) {
      selectedValue = formikValue;
    }
    return parseValue(selectedValue, 'boolean', false);
  }, [actualDefaultValue, formikValue, value]);

  const innerLabelState = useMemo<SpanStyledProps['innerLabelState']>(() => {
    if (!innerLabel) {
      return null;
    }

    const isOnOff = innerLabel === 'onOff';
    const onLabel = isOnOff ? 'on' : 'yes';
    const offLabel = isOnOff ? 'off' : 'no';

    return {
      on: (intl.messages[onLabel] || onLabel) as string,
      off: (intl.messages[offLabel] || offLabel) as string,
    };
  }, [innerLabel, intl]);

  const actualDisabled = loading || disabled || isSubmitting;
  const [currentValue, setCurrentValue] = useState(actualValue);
  const [fieldDisabled, setFieldDisabled] = useState(actualDisabled);
  const fieldType = 'checkbox';
  const validator = useFieldValidator(fieldType);

  useEffect(() => {
    setCurrentValue(actualValue);
  }, [actualValue]);

  useEffect(() => {
    setFieldDisabled(actualDisabled);
  }, [actualDisabled]);

  const change = useCallback(
    async (event, value) => {
      if (disableOnChange) {
        setFieldDisabled(true);
      }
      setCurrentValue(value);
      // needs to be performed on this order!
      setFieldTouched(name, true, false);
      await setFieldValue(name, value, true);
      onChange && onChange({ event, name, value, setFieldValue });
    },
    [name, onChange, disableOnChange, setFieldValue, setFieldTouched],
  );

  const validate = useCallback(
    value => {
      // make sure we feed the proper type to the validator
      return validator(parseValue(value, 'boolean', false), field);
    },
    [field, validator],
  );

  return (
    <FormControlLabel
      sx={{ margin: 0 }}
      disabled={fieldDisabled}
      control={
        <div className={className}>
          {header && (
            <FormLabel>
              <IntlMessage value={header} /> {required ? ' *' : ''}
            </FormLabel>
          )}
          <SpanStyled
            fullWidth={fullWidth}
            withInnerLabel={!!innerLabel}
            innerLabelState={innerLabelState}
            checked={actualValue}
          >
            <Field
              type={fieldType}
              disabled={fieldDisabled}
              required={required}
              component={BaseField}
              name={name}
              label={label}
              checked={currentValue}
              value={currentValue}
              onChange={change}
              size={variant === 'slim' ? 'small' : undefined}
              validate={validate}
              {...rest}
            />
          </SpanStyled>
        </div>
      }
      label={label}
    />
  );
};

export default InternalSwitch;
