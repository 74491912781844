import React from 'react';

import { CardContent } from '@mui/material';

import type { FilterableTableColumnType, FilterableTableRowMapperFunc } from 'app/components';
import { FilterableTable } from 'app/components';

import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from 'app/translations';
import type { SummaryScalesFormValues } from '../../ScalesTableForm.types';

export const SCALES_TABLE_DATA_TEST_ID = 'scales-table-component';

enum TableColumns {
  Age1520 = '15-20',
  Age2025 = '20-25',
  Age2530 = '25-30',
  Age3035 = '30-35',
  Age3540 = '35-40',
  Age4045 = '40-45',
  Age4550 = '45-50',
  Age5055 = '50-55',
  Age5560 = '55-60',
  Age6065 = '60-65',
  Age6568 = '65-68',
}

type ScalesTableProps = {
  isLoading?: boolean;
  table: SummaryScalesFormValues['agePercentages'];
};

export const ScalesTable = ({ table, isLoading = false }: ScalesTableProps) => {
  const tableColumns = React.useMemo<FilterableTableColumnType[]>(
    () => [
      {
        name: TableColumns.Age1520,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 15-20'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age2025,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 20-25'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age2530,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 25-30'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age3035,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 30-35'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age3540,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 35-40'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age4045,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 40-45'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age4550,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 45-50'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age5055,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 50-55'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age5560,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 55-60'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age6065,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 60-65'}
          </>
        ),
        textAlign: 'right',
      },
      {
        name: TableColumns.Age6568,
        title: (
          <>
            <FormattedMessage id={TranslationKeys.age} />
            {' 65-68'}
          </>
        ),
        textAlign: 'right',
      },
    ],
    [],
  );

  const tableRowMapper = React.useCallback<
    FilterableTableRowMapperFunc<SummaryScalesFormValues['agePercentages'][number]>
  >(table => {
    return {
      data: {
        [TableColumns.Age1520]: table['15-20'],
        [TableColumns.Age2025]: table['20-25'],
        [TableColumns.Age2530]: table['25-30'],
        [TableColumns.Age3035]: table['30-35'],
        [TableColumns.Age3540]: table['35-40'],
        [TableColumns.Age4045]: table['40-45'],
        [TableColumns.Age4550]: table['45-50'],
        [TableColumns.Age5055]: table['50-55'],
        [TableColumns.Age5560]: table['55-60'],
        [TableColumns.Age6065]: table['60-65'],
        [TableColumns.Age6568]: table['65-68'],
      },
    };
  }, []);

  return (
    <CardContent data-testid={SCALES_TABLE_DATA_TEST_ID} sx={{ padding: 0 }}>
      <FilterableTable
        columns={tableColumns}
        loading={isLoading}
        rows={table || []}
        rowMapper={tableRowMapper}
        pagination={{ enabled: false }}
      />
    </CardContent>
  );
};
