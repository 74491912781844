import { inputLabelClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiInputLabel: FuncOverrideComponent<'MuiInputLabel'> = theme => ({
  styleOverrides: {
    outlined: {
      transform: `translate(${theme.spacing(1.25)}, ${theme.spacing(0.75)}) scale(1)`,
      [`&.${inputLabelClasses.shrink}`]: {
        transform: `translate(${theme.spacing(1.5)}, -${theme.spacing(1.125)}) scale(0.75)`,
      },
      [`&.${inputLabelClasses.sizeSmall}`]: {
        transform: `translate(${theme.spacing(1.25)}, ${theme.spacing(0.2)}) scale(1)`,
      },
    },
  },
});
