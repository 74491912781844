import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Grid, Typography } from '@mui/material';

import type { Owner } from '../../../../../../types';

import { TranslationKeys } from '../../../../../../translations';

import { ActionLink } from '../../../../../../components';

import { GridStyled } from './OwnerSubOwners.styles';

type OwnerSubOwnersProps = {
  subOwners: Owner[];
};

export const OwnerSubOwners = ({ subOwners }: OwnerSubOwnersProps) => {
  const sortedSubOwners = React.useMemo(
    () => subOwners?.sort((a, b) => (a.ownerName < b.ownerName ? -1 : 0)),
    [subOwners],
  );

  return (
    <Grid sx={{ width: '100%' }} container spacing={2}>
      {!!sortedSubOwners?.length &&
        sortedSubOwners.map(subOwner => (
          <GridStyled item key={subOwner.ownerId} xs={12}>
            <Grid container>
              <Grid item xs={7}>
                <Typography variant="body1">{subOwner.ownerName}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Box textAlign="right">
                  <ActionLink to={`/sub-owners/${subOwner.ownerId}`}>
                    <Typography variant="body1">
                      <FormattedMessage id={TranslationKeys.owners_showDetailPage} />
                    </Typography>
                  </ActionLink>
                </Box>
              </Grid>
            </Grid>
          </GridStyled>
        ))}
    </Grid>
  );
};
