import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { Skeleton } from '@mui/material';

const ConnectionLogCountDataCell = ({ connectionId, logsCount }) => {
  const [logCount, setLogCount] = useState(null);

  useEffect(() => {
    (() => {
      setLogCount(logsCount[connectionId] || 0);
    })();
  }, [connectionId, logsCount]);

  if (logCount === null) {
    return <Skeleton variant="rect" width={50} />;
  }

  return <Chip label={logCount} color={logCount > 0 ? 'primary' : 'default'} size="small" />;
};

export default ConnectionLogCountDataCell;
