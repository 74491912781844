import React, { createRef, forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Formik, getIn, setIn } from 'formik';
import { useIntl } from 'react-intl';
import EmployeesListing from '../EmployeesListing';
import { mapSettingsField, SettingsFieldGroup } from 'app/components/FormikField/Factory/SettingsFieldFactory';
import { isNil } from 'app/utils';
import ErrorPanel from 'app/components/ErrorPanel';

const ListsStep = forwardRef(
  ({ sourcePackageSettings, connectionLists, onSubmit, stepState, loading = false }, ref) => {
    const formRef = createRef();
    const intl = useIntl();
    const [initialFiles, setInitialFiles] = useState(stepState.state.initialFiles || []);
    const [filesLog] = useState(connectionLists || []);

    useImperativeHandle(
      ref,
      () => ({
        submit() {
          if (!loading) {
            formRef.current.submitForm();
          }
        },
        getState() {
          return {
            state: {
              initialFiles,
            },
            form: formRef.current.values,
          };
        },
      }),
      [formRef, initialFiles, loading],
    );

    const listFields = useMemo(() => {
      const sortedByCreatedAt = JSON.parse(JSON.stringify(filesLog || []))
        .map(file => ({
          ...file,
          createdAt: new Date(file.createdAt),
        }))
        .sort((a, b) => Number(b.createdAt) - Number(a.createdAt));

      const mostRecentFile = (filesLog || []).find(
        file => file.listedEmployeeLogId === sortedByCreatedAt?.[0]?.listedEmployeeLogId,
      );

      return (sourcePackageSettings || []).reduce((result, settings) => {
        const settingsFields = mapSettingsField(
          intl,
          settings,
          'onboarding.settings.',
          loading,
          stepState.form,
          mostRecentFile || {},
          SettingsFieldGroup.EmployeeList,
        );

        return result.concat(settingsFields);
      }, []);
    }, [filesLog, intl, loading, sourcePackageSettings, stepState.form]);

    const typeField = listFields.find(f => f.name.endsWith('.listedEmployeesType'));
    const listField = listFields.find(f => f.name.endsWith('.listedEmployees'));

    const initialValues = useMemo(() => {
      let values = {
        uploadError: false,
      };
      [typeField, listField]
        .filter(f => !isNil(f))
        .forEach(field => {
          values = setIn(values, field.name, field.value ?? field.defaultValue ?? null);
        });
      return values;
    }, [listField, typeField]);

    const changeFiles = useCallback(value => {
      setInitialFiles(value);
    }, []);

    const changeList = useCallback(
      ({ value, setFieldValue, setFieldTouched }) => {
        setFieldTouched(listField.name, true, false);
        setFieldValue(listField.name, value, true);
      },
      [listField?.name],
    );

    const changeType = useCallback(
      ({ value, setFieldValue, setFieldTouched }) => {
        setFieldTouched(typeField.name, true, false);
        setFieldValue(typeField.name, value, true);
        if (value === getIn(initialValues, typeField.name)) {
          changeList({ value: getIn(initialValues, listField.name), setFieldValue, setFieldTouched });
        } else {
          changeList({ value: [], setFieldValue, setFieldTouched });
        }
      },
      [changeList, initialValues, listField, typeField],
    );

    const formikValidate = React.useCallback(values => {
      const errors = {};

      if (values.uploadError) {
        errors.uploadError = true;
      }

      return errors;
    }, []);

    const hasLists = !!(typeField && listField);

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formRef}
        validate={formikValidate}
      >
        {hasLists ? (
          <EmployeesListing
            typeField={typeField}
            listField={listField}
            initialFiles={initialFiles}
            filesLog={filesLog}
            onChangeType={changeType}
            onChangeList={changeList}
            onFilesChange={changeFiles}
            loading={loading}
          />
        ) : (
          <ErrorPanel body={'onboarding.list.unavailable'} />
        )}
      </Formik>
    );
  },
);

ListsStep.displayName = 'ListsStep';

export default ListsStep;
