import { styled } from '@mui/material/styles';
import PaperBackground from '../../PaperBackground';
import { Grid, Typography } from '@mui/material';

export const PaperBackgroundStyled = styled(PaperBackground, {
  name: 'PaperBackgroundStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
}));

export const TypographyContainerTitleStyled = styled(Typography, {
  name: 'TypographyContainerTitleStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textTransform: 'unset',
  fontSize: '1rem',
}));

export const DivRowsContainerStyled = styled('div', {
  name: 'DivRowsContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const TypographyRowLabelStyled = styled(Typography, {
  name: 'TypographyRowLabelStyled',
})(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: 500,
  lineHeight: '24px',
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  paddingRight: theme.spacing(0.5),
  overflowWrap: 'break-word',
})) as typeof Typography;

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
  shouldForwardProp: (propName: string) => propName !== 'highlighted',
})<{ highlighted: boolean }>(({ theme, highlighted }) => {
  if (highlighted) {
    return {
      borderRadius: theme.spacing(0.5),
      outline: `1px solid ${theme.palette.success.dark}`,
      backgroundColor: theme.palette.success.light,
    };
  }
  return {};
});

export const TypographyRowValueStyled = styled(Typography, {
  name: 'TypographyRowValueStyled',
})(({ theme }) => ({
  ...theme.typography.h6,
  lineHeight: '24px',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  overflowWrap: 'break-word',
})) as typeof Typography;
