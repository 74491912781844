import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle } from '@mui/material';

export const DialogStyled = styled(Dialog, {
  name: 'DialogStyled',
})(({ theme }) => ({
  padding: theme.spacing(5),
}));

export const DialogTitleStyled = styled(DialogTitle, {
  name: 'DialogTitleStyled',
})(({ theme }) => ({
  paddingBottom: theme.spacing(5),
}));
