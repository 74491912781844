import React from 'react';
import PropTypes from 'prop-types';

import { blue, red, yellow } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorIcon from '@mui/icons-material/Error';

const MessageTypeIcon = props => {
  const { messageType } = props;

  const icons = {
    info: <InfoIcon style={{ color: blue[500] }} />,
    warning: <WarningIcon style={{ color: yellow[500] }} />,
    error: <ErrorOutlineIcon style={{ color: red[500] }} />,
    emergency: <ErrorIcon style={{ color: red[500] }} />,
  };

  return icons[messageType] ?? messageType;
};

MessageTypeIcon.propTypes = {
  messageType: PropTypes.string,
};

export default MessageTypeIcon;
