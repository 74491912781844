import { alpha, styled } from '@mui/material/styles';

export const DivPanelBodyDetailsStyled = styled('div', {
  name: 'DivPanelBodyDetailsStyled',
})<{ isRegularConnection: boolean }>(({ theme, isRegularConnection }) => ({
  width: '100%',
  height: isRegularConnection ? '13em' : '16em',
  '& $element:not(:last-child)': {
    borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
  },
}));
