import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { CardContent, Grid, Link, Typography } from '@mui/material';

import { EmployersTable, EmployersToolbar } from '../../Components';
import { actions as EmployersActions } from '../../Ducks/Employers.duck';
import { ViewDialog } from 'app/components/ViewDialog';

import { faFileArrowUp, faHandPointer } from '@fortawesome/free-solid-svg-icons';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { CardStyled, DivContentStyled, FontAwesomeIconStyled, TypographyStyled } from './EmployerManagementPage.styles';
import { useStoreSelector } from 'app/store';
import { EmployerUploadFormDialog } from './EmployerUploadFormDialog';
import { useDialog } from 'app/hooks';

export const EmployerManagementPage = () => {
  const dispatch = useDispatch();
  const [createEmployerModalOpen, setCreateEmployerModalOpen] = useState(false);

  const {
    dialogState: showEmployerUploadFormDialog,
    openDialog: openEmployerUploadFormDialog,
    closeDialog: closeEmployerUploadFormDialog,
  } = useDialog();

  const afterSubmitEmployerUploadFormDialog = React.useCallback(() => {
    closeEmployerUploadFormDialog();
  }, [closeEmployerUploadFormDialog]);

  const {
    loading,
    items,
    page,
    pageSize,
    totalCount,
    searchQuery,
    unfilteredTotalCount: employersTotalCount,
  } = useStoreSelector(state => state.EmployersReducer);

  useEffect(() => {
    dispatch(EmployersActions.requestData());
    dispatch(EmployersActions.requestUnfilteredTotalCount());
    dispatch(EmployersActions.sortTable());
  }, [dispatch]);

  const handleSearch = React.useCallback(
    event => {
      dispatch(EmployersActions.search(event.target.value));
    },
    [dispatch],
  );

  const handlePageChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(EmployersActions.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(EmployersActions.changePage(page));
      }
    },
    [dispatch],
  );

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(EmployersActions.sortTable(sortBy));
    },
    [dispatch],
  );

  useBreadcrumb('menu.employers', {
    hideTrail: true,
    hideTitle: true,
    otherElements: (
      <EmployersToolbar
        handleClickAdd={useCallback(() => setCreateEmployerModalOpen(true), [])}
        handleSearch={handleSearch}
        totalCount={loading ? undefined : employersTotalCount?.total}
        initialSearchQuery={searchQuery}
      />
    ),
  });

  return (
    <div>
      <DivContentStyled>
        <EmployersTable
          onPaginationChange={handlePageChange}
          employers={items}
          totalCount={totalCount}
          page={page}
          rowsPerPage={pageSize}
          loading={loading}
          onSortChange={onSortChange}
        />
      </DivContentStyled>

      {createEmployerModalOpen && (
        <ViewDialog
          closeButton={false}
          open={createEmployerModalOpen}
          setOpen={setCreateEmployerModalOpen}
          title={<FormattedMessage id="employers.add" />}
          onClose={() => setCreateEmployerModalOpen(false)}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CardStyled>
                <CardContent>
                  <Link href={'/employers/add'}>
                    <FontAwesomeIconStyled icon={faHandPointer} />
                    <TypographyStyled variant="h5">
                      <FormattedMessage id="employers.add.manually" />
                    </TypographyStyled>
                    <Typography variant="body1">
                      <FormattedMessage id="employers.add.manually.description" />
                    </Typography>
                  </Link>
                </CardContent>
              </CardStyled>
            </Grid>
            <Grid item xs={6}>
              <CardStyled onClick={openEmployerUploadFormDialog}>
                <CardContent>
                  <Link href="#">
                    <FontAwesomeIconStyled icon={faFileArrowUp} />
                    <TypographyStyled variant="h5">
                      <FormattedMessage id="employers.add.fileUpload" />
                    </TypographyStyled>
                    <Typography variant="body1">
                      <FormattedMessage id="employers.add.fileUpload.description" />
                    </Typography>
                  </Link>
                </CardContent>
              </CardStyled>
            </Grid>
          </Grid>
        </ViewDialog>
      )}
      {showEmployerUploadFormDialog && (
        <EmployerUploadFormDialog
          show={showEmployerUploadFormDialog}
          onClose={closeEmployerUploadFormDialog}
          afterSubmit={afterSubmitEmployerUploadFormDialog}
        />
      )}
    </div>
  );
};
