import { styled } from '@mui/material/styles';

export const DivRowStyled = styled('div', {
  name: 'DivRowStyled',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));
