import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';

import type { Employee, EmployeeChild, UseQueryRefetch } from 'app/types';
import { useDialog, useEmployeeDeleteChildMutation, useGenderSelectItems, useSnakeBar } from 'app/hooks';

import format from 'app/utils/formatter';

import DetailsPanelRow from 'app/components/DetailsPanelRow';
import ActionButton from 'app/components/ActionButton';
import { ConfirmDialog } from 'app/components';
import DetailsPanel from 'app/components/DetailsPanel';

import { EmployeeDetailsPanelHeaderWithSyncToggle } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeDetailsPanelHeaderWithSyncToggle';

import {
  DivNoDataDetailsBodyStyled,
  DivPanelBodyDetailsStyled,
  SpanConfirmDialogBodyContainerStyled,
} from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/EmployeeInfoTab.styles';

import { ChildrenDetailsPanelFields } from './ChildrenDetailsPanel.types';
import { ChildFormDialog } from './components';

import { HasAccessTo } from 'app/components';
import { EMPLOYER, CONNECTION } from 'app/common/Authorization/entities';
import { EDIT, RUN } from 'app/common/Authorization/permissions';

type ChildrenDetailsPanelProps = {
  synchronizationStatus: boolean | undefined;
  refetchGetEmployeeSynchronizationQuery: UseQueryRefetch;
  refetchGetEmployeeQuery: UseQueryRefetch;
  disabledActionButtonsWhileLoading: boolean;
  employee: Employee | undefined;
  loading: boolean;
};

export const ChildrenDetailsPanel = ({
  synchronizationStatus,
  refetchGetEmployeeSynchronizationQuery,
  refetchGetEmployeeQuery,
  disabledActionButtonsWhileLoading,
  employee,
  loading,
}: ChildrenDetailsPanelProps) => {
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const intl = useIntl();
  const { employerId } = useParams();
  const { getGenderDisplayNameByValue } = useGenderSelectItems();
  const { mutate: deleteEmployeeChild } = useEmployeeDeleteChildMutation();
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);

  const {
    dialogState: childFormDialogState,
    openDialog: openChildFormDialog,
    closeDialog: closeChildFormDialog,
  } = useDialog();

  const children = React.useMemo(() => employee?.children ?? [], [employee?.children]);
  const [expanded, setExpanded] = React.useState(children.map(() => false));
  const [childToDelete, setChildToDelete] = React.useState<EmployeeChild | null>(null);
  const [childToEdit, setChildToEdit] = React.useState<EmployeeChild | null>(null);
  const hasChildren = children.length > 0;
  const hasActiveSynchronization = synchronizationStatus !== undefined ? synchronizationStatus : false;

  const toggleChildDetailsPanel = React.useCallback(
    ({ id, expanded: newExpanded }) => {
      const newState = new Array(expanded.length).fill(false);
      newState[id] = !newExpanded;
      setExpanded(newState);
    },
    [expanded],
  );

  const deleteChildHandler = React.useCallback(
    (childId: string) => {
      if (employee?.connectionId && employee?.employeeId) {
        const method = 'deleteEmployeeChild';
        deleteEmployeeChild(
          {
            connectionId: employee?.connectionId,
            employeeId: employee?.employeeId,
            childId,
          },
          {
            onSuccess: () => {
              refetchGetEmployeeQuery();
              showSuccessSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_successMessage }),
              });
            },
            onError: () => {
              showErrorSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_errorMessage }),
              });
            },
          },
        );
      }
    },
    [
      deleteEmployeeChild,
      employee?.connectionId,
      employee?.employeeId,
      intl,
      refetchGetEmployeeQuery,
      showErrorSnakeBar,
      showSuccessSnakeBar,
    ],
  );

  const onOpenChildFormDialog = React.useCallback(
    (child?: EmployeeChild) => {
      setChildToEdit(child || null);
      openChildFormDialog();
    },
    [openChildFormDialog],
  );

  const onCloseChildFormDialog = React.useCallback(() => {
    setChildToEdit(null);
    closeChildFormDialog();
  }, [closeChildFormDialog]);

  const onClickAddButton = React.useCallback(() => {
    onOpenChildFormDialog();
  }, [onOpenChildFormDialog]);

  const onClickEditButton = React.useCallback(
    (child: EmployeeChild) => () => {
      onOpenChildFormDialog(child);
    },
    [onOpenChildFormDialog],
  );

  const onClickDeleteButton = React.useCallback(
    (child: EmployeeChild) => () => {
      setChildToDelete(child);
    },
    [setChildToDelete],
  );

  const onCloseConfirmationDialog = React.useCallback(() => {
    setChildToDelete(null);
  }, []);

  const onClickConfirmDelete = React.useCallback(() => {
    if (childToDelete) {
      deleteChildHandler(childToDelete.childId);
    }
  }, [childToDelete, deleteChildHandler]);

  const LoadingDetailsBody = React.useMemo(() => {
    return (
      <>
        {new Array(5).fill(null).map((_, idx) => (
          <Skeleton key={idx} height={24} />
        ))}
      </>
    );
  }, []);

  const NoChildrenDetailsBody = React.useMemo(() => {
    return (
      <DivNoDataDetailsBodyStyled>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKeys.employees_detail_children_noChildren} />
        </Typography>
      </DivNoDataDetailsBodyStyled>
    );
  }, []);

  const ChildrenDetailsBody = React.useMemo(() => {
    return (children || []).map((child, idx) => {
      const expandedIfOnlyOneChild = children.length === 1;
      const isExpanded = typeof expanded[idx] !== 'undefined' ? expanded[idx] : false;

      const rows = {
        [ChildrenDetailsPanelFields.PersonName.Props.Initials.Label]: child?.personName?.initials,
        [ChildrenDetailsPanelFields.PersonName.Props.FirstName.Label]: child?.personName?.firstName,
        [ChildrenDetailsPanelFields.PersonName.Props.LastName.Label]: child?.personName?.lastName,
        [ChildrenDetailsPanelFields.PersonName.Props.LastNamePrefix.Label]: child.personName?.lastNamePrefix || '-',
        [ChildrenDetailsPanelFields.SocialSecurityNumber.Label]: child?.socialSecurityNumber || '-',
        [ChildrenDetailsPanelFields.Gender.Label]: getGenderDisplayNameByValue(child?.gender),
        [ChildrenDetailsPanelFields.DateOfBirth.Label]: child?.dateOfBirth ? format.date(child?.dateOfBirth) : '-',
      };

      return (
        <div key={idx}>
          <DetailsPanelRow
            title={<Typography variant="h6">{format.name(child.personName)}</Typography>}
            expanded={expandedIfOnlyOneChild || isExpanded}
            rows={rows}
            loading={loading}
            id={idx}
            onExpand={toggleChildDetailsPanel}
            onDeleteButtonClick={onClickDeleteButton(child)}
            onEditButtonClick={onClickEditButton(child)}
            disabledDeleteButton={
              disabledActionButtonsWhileLoading ||
              hasActiveSynchronization ||
              disabledIfNoAccessToConnectionRun ||
              disabledIfNoAccessToEmployerEdit
            }
            disabledEditButton={
              disabledActionButtonsWhileLoading ||
              hasActiveSynchronization ||
              disabledIfNoAccessToConnectionRun ||
              disabledIfNoAccessToEmployerEdit
            }
          />
        </div>
      );
    });
  }, [
    children,
    expanded,
    getGenderDisplayNameByValue,
    loading,
    toggleChildDetailsPanel,
    onClickDeleteButton,
    onClickEditButton,
    disabledActionButtonsWhileLoading,
    hasActiveSynchronization,
    disabledIfNoAccessToConnectionRun,
    disabledIfNoAccessToEmployerEdit,
  ]);

  const DetailsPanelBody = React.useMemo(() => {
    const finishedLoadingAndHasChildren = !loading && hasChildren;
    const finishedLoadingAndHasNoChildren = !loading && !hasChildren;

    return (
      <DivPanelBodyDetailsStyled>
        <>
          {loading && LoadingDetailsBody}
          {finishedLoadingAndHasChildren && ChildrenDetailsBody}
          {finishedLoadingAndHasNoChildren && NoChildrenDetailsBody}
        </>
      </DivPanelBodyDetailsStyled>
    );
  }, [loading, hasChildren, LoadingDetailsBody, ChildrenDetailsBody, NoChildrenDetailsBody]);

  const DetailsPanelActions = React.useMemo(() => {
    return (
      <>
        <ActionButton
          messageId={TranslationKeys.button_add}
          variant="text"
          icon={faPlus}
          onClick={onClickAddButton}
          disabled={
            disabledActionButtonsWhileLoading ||
            hasActiveSynchronization ||
            disabledIfNoAccessToEmployerEdit ||
            disabledIfNoAccessToConnectionRun
          }
        />
      </>
    );
  }, [
    disabledActionButtonsWhileLoading,
    disabledIfNoAccessToConnectionRun,
    disabledIfNoAccessToEmployerEdit,
    hasActiveSynchronization,
    onClickAddButton,
  ]);

  const DetailsPanelHeader = React.useMemo(() => {
    return (
      <EmployeeDetailsPanelHeaderWithSyncToggle
        field={'child'}
        loading={loading}
        synchronizationStatus={synchronizationStatus}
        refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
        disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
        messageId={TranslationKeys.employees_detail_children_title}
        employeeId={employee?.employeeId}
        connectionId={employee?.connectionId}
      />
    );
  }, [
    employee?.connectionId,
    employee?.employeeId,
    disabledActionButtonsWhileLoading,
    loading,
    refetchGetEmployeeSynchronizationQuery,
    synchronizationStatus,
  ]);

  return (
    <>
      <ConfirmDialog
        title={<FormattedMessage id={TranslationKeys.employees_detail_children_confirmDeleteTitle} />}
        open={childToDelete !== null}
        onClose={onCloseConfirmationDialog}
        onConfirm={onClickConfirmDelete}
      >
        <SpanConfirmDialogBodyContainerStyled>
          <span>{format.name(childToDelete?.personName)}</span>
          <FormattedMessage id={TranslationKeys.employees_detail_children_confirmDeleteMessage} />
        </SpanConfirmDialogBodyContainerStyled>
      </ConfirmDialog>

      {employerId && childFormDialogState && (
        <ChildFormDialog
          open={childFormDialogState}
          closeDialog={onCloseChildFormDialog}
          employee={employee}
          child={childToEdit}
          refetchGetEmployeeQuery={refetchGetEmployeeQuery}
        />
      )}

      <DetailsPanel
        header={DetailsPanelHeader}
        body={DetailsPanelBody}
        actions={DetailsPanelActions}
        scrollBody={true}
      />
    </>
  );
};
