import { useContext } from 'react';

import { selectors as appSelectors } from '../../Domain/App/Ducks/App.duck';

import { useStoreSelector } from '../../store';

import { SUPER_ADMIN_OWNER_ID } from '../../common';

import { AuthorizationContext } from './AuthorizationProvider';
import type { AuthorizationContextInterface } from './AuthorizationContextInterface';

import { IsSuperAdmin } from './IsSuperAdmin';

export const IsOwnerAdmin = () => {
  const ownerId = useStoreSelector(state => appSelectors.selectOwnerId(state.AppReducer));
  const superAdmin = IsSuperAdmin();
  const context: AuthorizationContextInterface = useContext(AuthorizationContext);

  const { roles } = context;
  if (!roles) {
    return false;
  }

  if (roles.includes('ROLE_OWNER')) {
    return true;
  }

  return superAdmin && ownerId !== SUPER_ADMIN_OWNER_ID;
};
