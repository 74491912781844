import { useMutation } from '@tanstack/react-query';

import type { CreateSummaryScaleArgs } from 'app/Domain/Settings/Services';

import { settingsServices } from 'app/Domain/Settings/Services';

const mutationFn = (data: CreateSummaryScaleArgs) => {
  return settingsServices.createSummaryScale(data);
};

export const useSettingsCreateSummaryScaleTableMutation = () => {
  return useMutation<unknown, unknown, CreateSummaryScaleArgs>(mutationFn);
};
