import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { put, select, takeLatest } from 'redux-saga/effects';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { getEventsForBatch, downloadEvents } from '../Services';
import { saveFileFromResponse } from 'app/utils';

export const actionTypes = {
  ClearEvents: '[ProcessEvents] Clear all data',
  RequestData: '[ProcessEvents] Request',
  FulfilledTable: '[ProcessEvents] Fulfilled',
  ChangePage: '[ProcessEvents] Change page',
  SetPageSize: '[ProcessEvents] Set page size',
  SetConnectionId: '[ProcessEvents] Set connection id',
  SetBatchId: '[ProcessEvents] Set batch id',
  Error: '[ProcessEvents] Error',
  DownloadBatchEvents: '[ProcessEvents] Download batch events',
};

const initialState = {
  ...tableInitialState,
  pageSize: 100,
  connectionId: null,
  batchId: null,
  batchMongoId: null,
};

export const reducer = persistReducer(
  { storage, key: 'batchEventsTable', whitelist: [] },
  (state = initialState, action) => {
    const newState = tableAction(actionTypes, state, action, initialState);

    switch (action.type) {
      case actionTypes.SetConnectionId: {
        return {
          ...state,
          connectionId: action.connectionId,
        };
      }
      case actionTypes.SetBatchId: {
        return {
          ...state,
          batchId: action.batchId,
          batchMongoId: action.batchMongoId,
        };
      }
      case actionTypes.ClearEvents: {
        return initialState;
      }
      default:
        return newState;
    }
  },
);

export const actions = {
  ...tableActions(actionTypes),
  clearEvents: () => ({
    type: actionTypes.ClearEvents,
  }),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
  setIds: (batchId, batchMongoId) => ({
    type: actionTypes.SetBatchId,
    batchId,
    batchMongoId,
  }),
  downloadEvents: () => ({
    type: actionTypes.DownloadBatchEvents,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.DownloadBatchEvents, function* downloadBatchEvents() {
    const currentState = yield select(state => {
      return state.ProcessEventsReducer;
    });
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      const response = yield downloadEvents(currentState.connectionId, {
        batchId: currentState.batchId,
      });
      if (response.status === 200) {
        saveFileFromResponse(response);
      } else if (response.status === 202) {
        yield put(AppActions.displaySnackBar(true));
      }
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'downloadEvents',
        severity,
      };

      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RequestData, function* requestEventsSaga() {
    try {
      const currentState = yield select(state => {
        return state.ProcessEventsReducer;
      });

      const response = yield getEventsForBatch(
        currentState.connectionId,
        currentState.batchMongoId,
        currentState.page,
        currentState.pageSize,
        currentState.sortBy,
        { ...currentState.filters, processed: false },
        currentState.searchQuery,
      );

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getEventsForBatch',
        severity,
      };
      yield put(actions.error(true));
      yield put(AppActions.displayMessage(details));
    }
  });
}
