import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { Outlet, useResolvedPath } from 'react-router';
import { useBreadcrumb } from 'app/components/Breadcrumbs';

const TabOutlet = ({ tabs, children, breadcrumb }) => {
  const rootPath = useResolvedPath('.').pathname || '';
  const fullPath = useLocation().pathname || '';

  const activeTab = useMemo(() => {
    const relativePath = (fullPath.split(rootPath).slice(-1)[0] || '').slice(1);
    const tabFindIndex = tabs.findIndex(data => data.path === relativePath);
    return tabFindIndex >= 0 ? tabFindIndex : -1;
  }, [tabs, fullPath, rootPath]);

  const showTabs = activeTab >= 0;

  const { title = null, data = null } = breadcrumb || {};
  useBreadcrumb(title, data);

  return (
    <div>
      {showTabs && (
        <Tabs value={activeTab}>
          {tabs.map(tab => (
            <Tab
              component={Link}
              key={tab.path}
              label={tab.label}
              to={tab.path}
              disabled={tab.disabled || false}
              style={{ display: tab.disabled ? 'none' : 'block' }}
            />
          ))}
        </Tabs>
      )}
      <Outlet />
      {children}
    </div>
  );
};

export default TabOutlet;
