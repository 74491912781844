import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import type { FormikConfig } from 'formik';
import * as Yup from 'yup';

import type { RateTable } from 'app/types';

import { useSettingsCreateRateTableMutation, useSnakeBar } from 'app/hooks';
import { TranslationKeys } from 'app/translations';
import { StoreSelectors, useStoreSelector } from 'app/store';

import type { RateTableFormValues } from './RateTableForm.types';

export const useRateTableForm = (rateTable?: RateTable) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: createRateTableMutation, isLoading: isCreatingTable } = useSettingsCreateRateTableMutation();

  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const isSubmitting = isCreatingTable;

  const formikInitialValues = React.useMemo<RateTableFormValues>(() => {
    return {
      uploadError: false,
      table: rateTable?.table || [],
      title: rateTable?.title || '',
      insurerId: rateTable?.product?.insurerId || '',
      productId: rateTable?.product?.productId || '',
      year: rateTable?.year ?? '',
      indexation: rateTable?.indexation ?? '',
      discount: rateTable?.discount ?? 100,
      unitRate: rateTable?.unitRate ?? 1000,
      tableType: rateTable?.tableType ?? 'PP',
    };
  }, [
    rateTable?.indexation,
    rateTable?.product?.insurerId,
    rateTable?.product?.productId,
    rateTable?.table,
    rateTable?.title,
    rateTable?.year,
    rateTable?.discount,
    rateTable?.tableType,
    rateTable?.unitRate,
  ]);

  const formikValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().max(75).required(),
        insurerId: Yup.string().required(),
        productId: Yup.string().required(),
        year: Yup.number().required(),
        indexation: Yup.number().required(),
        discount: Yup.number().min(0).required(),
        tableType: Yup.string().oneOf(['PP', 'WZP', 'ANW']).required(),
      }),
    [],
  );

  const formikOnSubmitCreate = React.useCallback<FormikConfig<RateTableFormValues>['onSubmit']>(
    (values, { setSubmitting, setErrors }) => {
      if (values.uploadError || values.table.length === 0) {
        setSubmitting(false);
        return;
      }

      const method = 'createRateTable';

      createRateTableMutation(
        {
          ownerId,
          title: values.title,
          productId: values.productId,
          indexation: values.indexation as number,
          discount: values.discount as number,
          unitRate: values.unitRate as number,
          tableType: values.tableType,
          year: values.year as number,
          table: values.table,
        },
        {
          onSuccess: () => {
            setSubmitting(false);
            showSuccessSnakeBar({
              method,
              message: intl.formatMessage({ id: TranslationKeys.global_update_successMessage }),
            });
            navigate('/settings/rate-tables');
          },
          onError: (error: any) => {
            setSubmitting(false);

            if (error.response?.data?.violations) {
              setErrors(error.response.data.violations);
            } else {
              showErrorSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_errorMessage }),
              });
            }
          },
        },
      );
    },
    [createRateTableMutation, intl, navigate, ownerId, showErrorSnakeBar, showSuccessSnakeBar],
  );

  return React.useMemo(
    () => ({
      formikInitialValues,
      formikValidationSchema,
      formikOnSubmitCreate,
      isSubmitting,
    }),
    [formikInitialValues, formikOnSubmitCreate, formikValidationSchema, isSubmitting],
  );
};
