import type { FormControlProps } from '@mui/material/FormControl';
import FormControl from '@mui/material/FormControl';
import type { FormHelperTextProps } from '@mui/material/FormHelperText';
import FormHelperText from '@mui/material/FormHelperText';
import type { InputLabelProps } from '@mui/material/InputLabel';
import InputLabel from '@mui/material/InputLabel';
import type { SelectProps as MuiSelectProps } from '@mui/material/Select';
import MuiSelect from '@mui/material/Select';
import type { FieldProps } from 'formik';
import { getIn } from 'formik';
import * as React from 'react';

export const SELECT_INPUT_DATA_TEST_ID = 'select-input';

export interface SelectProps extends FieldProps, Omit<MuiSelectProps, 'name' | 'value'> {
  formControl?: FormControlProps;
  formHelperText?: FormHelperTextProps;
  inputLabel?: InputLabelProps;
  valueType?: string;
}

export function fieldToSelect({
  disabled,
  field: { onBlur: _onBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldTouched },
  onClose,
  valueType,
  ...props
}: Omit<
  SelectProps,
  'formControl' | 'formHelperText' | 'inputLabel'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>): MuiSelectProps & { formError: any } {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    disabled: disabled ?? isSubmitting,
    error: showError,
    formError: showError ? fieldError : undefined,
    onBlur: () => {
      // no-op
    },
    onChange:
      fieldOnChange ??
      (() => {
        // no-op
      }),
    onClose:
      onClose ??
      (async (_e: React.ChangeEvent<any>) => {
        setFieldTouched(field.name, true, true);
      }),
    ...field,
    ...props,
  };
}

export function Select({ formControl, inputLabel, formHelperText, inputProps, ...selectProps }: SelectProps) {
  const { error, formError, disabled, ...selectFieldProps } = fieldToSelect({
    ...selectProps,
    inputProps: { 'data-testid': SELECT_INPUT_DATA_TEST_ID, ...(inputProps || {}) },
  });

  const { children: formHelperTextChildren, ...formHelperTextProps } = formHelperText || {};
  const shouldDisplayFormHelperText = error || formHelperTextChildren;

  return (
    <FormControl disabled={disabled} error={error} {...formControl}>
      <InputLabel id={selectFieldProps.labelId} {...inputLabel}>
        {selectFieldProps.label}
      </InputLabel>
      <MuiSelect {...selectFieldProps} />
      {shouldDisplayFormHelperText && (
        <FormHelperText {...formHelperTextProps}>{error ? formError : formHelperTextChildren}</FormHelperText>
      )}
    </FormControl>
  );
}

Select.displayName = 'FormikMaterialUISelect';
