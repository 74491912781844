import React from 'react';
import { NotesInput } from 'app/components';
import { useEmployerCreateNotesMutation, useEmployerGetNotesQuery, useEmployerUpdateNotesMutation } from 'app/hooks';
import type { Employer } from 'app/types';

type EmployerNotesProps = {
  employer: Employer | undefined;
  isLoading: boolean;
};

export const EmployerNotes = ({ employer, isLoading }: EmployerNotesProps) => {
  const { mutate: createNotesMutation, isLoading: isCreating } = useEmployerCreateNotesMutation();
  const { mutate: updateNotesMutation, isLoading: isUpdating } = useEmployerUpdateNotesMutation();

  const {
    data: notes,
    isLoading: getNotesQueryLoading,
    isFetching: getNotesQueryFetching,
    refetch: refetchGetNotesQuery,
  } = useEmployerGetNotesQuery({
    variables: {
      ownerId: employer?.ownerId,
      employerId: employer?.employerId,
    },
    options: {
      enabled: !!employer?.employerId && !!employer.ownerId,
    },
  });

  const note = notes?.data?.[0];
  const isGetNotesQueryLoading = getNotesQueryLoading || getNotesQueryFetching;
  const loading = isLoading || isGetNotesQueryLoading || isCreating || isUpdating;

  const onSaveNote = React.useCallback(
    (noteText: string) => {
      if (!employer?.ownerId || !employer?.employerId) {
        return;
      }

      const onSuccess = () => refetchGetNotesQuery();

      const commonData = {
        ownerId: employer?.ownerId,
        employerId: employer?.employerId,
        noteText,
      };

      if (note?.noteId) {
        updateNotesMutation(
          {
            ...commonData,
            noteId: note.noteId,
          },
          { onSuccess },
        );
      } else {
        createNotesMutation(commonData, { onSuccess });
      }
    },
    [createNotesMutation, employer?.employerId, employer?.ownerId, note, refetchGetNotesQuery, updateNotesMutation],
  );

  return <NotesInput isLoading={loading} inputValue={note?.text || ''} onSaveNote={onSaveNote} />;
};
