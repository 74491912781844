import { styled } from '@mui/material/styles';
import { buttonBaseClasses, formControlLabelClasses } from '@mui/material';
import { Checkbox } from 'app/components';

export const CheckboxStyled = styled(Checkbox, {
  name: 'CheckboxStyled',
})(() => ({
  [`& .${formControlLabelClasses.root}`]: {
    marginLeft: 0,
  },
  [`& .${buttonBaseClasses.root}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
