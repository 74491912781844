import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import { DivContainerStyled } from './EmployeePensionNotRegularMsg.styles';

export const EmployeePensionNotRegularMsg = () => {
  return (
    <DivContainerStyled>
      <Typography variant={'h5'}>
        <FormattedMessage id={TranslationKeys.employees_pension_notRegularMessage} />
      </Typography>
    </DivContainerStyled>
  );
};
