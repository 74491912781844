import React from 'react';

import { SidebarPageGroup } from '../SidebarPageGroup';

import type { Page } from '../../Sidebar.types';

import { DividerStyled, ListStyled } from './SidebarNav.styles';

type SidebarNavProps = {
  pages: Page[];
};

export const SidebarNav = ({ pages }: SidebarNavProps) => {
  return (
    <ListStyled>
      {pages.map((page, index) => {
        if (page.divider) {
          return <DividerStyled key={index} />;
        }

        return <SidebarPageGroup page={page} key={index} />;
      })}
    </ListStyled>
  );
};
