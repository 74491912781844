import { useContext } from 'react';

import { ROLE_SUPER_ADMIN } from '../../common';

import { AuthorizationContext } from './AuthorizationProvider';
import type { AuthorizationContextInterface } from './AuthorizationContextInterface';

export const IsSuperAdmin = () => {
  const context: AuthorizationContextInterface = useContext(AuthorizationContext);
  const { roles } = context;
  if (!roles) {
    return false;
  }

  return roles.includes(ROLE_SUPER_ADMIN);
};
