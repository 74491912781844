import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { isEmptyArray } from 'app/utils';

import type { Employer, EmployerConnection } from '../../../../types';

import { StoreActions, useStoreDispatch } from '../../../../store';

import { TranslationKeys } from '../../../../translations';

import { useSnakeBar } from '../../../../hooks';

import { CONNECTION } from '../../../../common/Authorization/entities';
import { DELETE, RUN, UPDATE, VIEW } from '../../../../common/Authorization/permissions';
import { ActionLink, ConfirmDialogWithTextInput, FilterableTable, HasAccessTo } from '../../../../components';
import KebabMenu from '../../../../components/KebabMenu';

import employerService from '../../Services/EmployerService';

import EmployerConnectionWizard from '../EmployerConnectionWizard';

import { useNavigate } from 'react-router-dom';

type EmployerConnectionsTableProps = {
  loading: boolean;
  onChange?: () => void;
  employer: Employer | undefined;
  connections: Array<EmployerConnection> | undefined;
};

export const EmployerConnectionsTable = ({
  employer,
  connections,
  loading,
  onChange,
}: EmployerConnectionsTableProps) => {
  const dispatch = useStoreDispatch();
  const { showErrorSnakeBar } = useSnakeBar();
  const navigate = useNavigate();

  const [connectionToDelete, setConnectionToDelete] = React.useState<EmployerConnection | null>(null);
  const [connectionToUpdate, setConnectionToUpdate] = React.useState<EmployerConnection | null>(null);

  const accessToConnectionView = HasAccessTo(CONNECTION, VIEW);
  const accessToConnectionRun = HasAccessTo(CONNECTION, RUN);
  const accessToConnectionUpdate = HasAccessTo(CONNECTION, UPDATE);
  const accessToConnectionDelete = HasAccessTo(CONNECTION, DELETE);

  const handleConnectionRun = React.useCallback(
    connection => {
      try {
        dispatch(StoreActions.ConnectionActions.forceRun({ connectionId: connection.connectionId }));
      } catch (error: any) {
        showErrorSnakeBar({ method: 'handleRunConnectionModule', message: error });
      }
    },
    [dispatch, showErrorSnakeBar],
  );

  const handleConnectionUpdate = React.useCallback(connection => {
    setConnectionToUpdate(connection);
  }, []);

  const closeEditModal = React.useCallback(() => {
    setConnectionToUpdate(null);
  }, []);

  const connectionUpdated = React.useCallback(() => {
    onChange && onChange();
  }, [onChange]);

  const handleConnectionDelete = React.useCallback(connection => {
    setConnectionToDelete(connection);
  }, []);

  const closeDeleteModal = React.useCallback(() => {
    setConnectionToDelete(null);
  }, []);

  const executeDeleteConnection = React.useCallback(async () => {
    if (connectionToDelete) {
      await employerService.deleteConnection({ connectionId: connectionToDelete.connectionId });
      onChange && onChange();
    }
  }, [connectionToDelete, onChange]);

  const headers = React.useMemo(() => {
    return [
      {
        name: 'title',
        title: <FormattedMessage id={TranslationKeys.insurers_title} />,
        sortable: true,
        sortProvider: (connection: EmployerConnection) =>
          (connection?.product?.insurer?.insurerName ?? '').toLocaleLowerCase(),
      },
      {
        name: 'product',
        title: <FormattedMessage id={TranslationKeys.insurers_products} />,
        sortable: true,
        sortProvider: (connection: EmployerConnection) => (connection?.product?.productName ?? '').toLocaleLowerCase(),
      },
      {
        name: 'connectionType',
        title: <FormattedMessage id={TranslationKeys.insurers_connectionType} />,
        sortable: true,
        sortProvider: (connection: EmployerConnection) => (connection?.connectionType ?? '').toLocaleLowerCase(),
      },
      {
        name: 'connection',
        title: <FormattedMessage id={TranslationKeys.insurers_connection} />,
        sortable: true,
        sortProvider: (connection: EmployerConnection) => (connection?.connectionName ?? '').toLocaleLowerCase(),
      },
    ];
  }, []);

  const rowMapper = React.useCallback(
    connection => {
      const menuItems = [];
      if (accessToConnectionRun) {
        menuItems.push({
          onClick: () => handleConnectionRun(connection),
          element: <FormattedMessage id={TranslationKeys.button_run} />,
        });
      }
      if (accessToConnectionUpdate) {
        menuItems.push({
          onClick: () => handleConnectionUpdate(connection),
          element: <FormattedMessage id={TranslationKeys.button_change} />,
        });
      }
      if (accessToConnectionDelete) {
        menuItems.push({
          onClick: () => handleConnectionDelete(connection),
          element: <FormattedMessage id={TranslationKeys.button_delete} />,
        });
      }
      if (accessToConnectionView) {
        menuItems.push({
          onClick: () => {
            navigate(`/employers/${employer?.employerId}/connections/${connection?.connectionId}/events`);
          },
          element: <FormattedMessage id={TranslationKeys.button_events} />,
        });
      }

      let connectionName = connection?.connectionName;

      if (accessToConnectionView) {
        connectionName = (
          <ActionLink to={`/employers/${employer?.employerId}/connections/${connection?.connectionId}/info`}>
            {connection?.connectionName}
          </ActionLink>
        );
      }

      if (!isEmptyArray(menuItems)) {
        connectionName = (
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>{connectionName}</Box>
            <Box>
              <KebabMenu items={menuItems} />
            </Box>
          </Box>
        );
      }

      return {
        data: {
          title: connection?.product?.insurer?.insurerName,
          product: connection?.product?.productName,
          connectionType: connection?.connectionType,
          connection: connectionName,
        },
      };
    },
    [
      accessToConnectionDelete,
      accessToConnectionRun,
      accessToConnectionUpdate,
      accessToConnectionView,
      employer?.employerId,
      handleConnectionDelete,
      handleConnectionRun,
      handleConnectionUpdate,
      navigate,
    ],
  );

  const pagination = React.useMemo(() => {
    return {
      enabled: false,
    };
  }, []);

  const editingConnection = !!(employer && connectionToUpdate);

  return (
    <>
      <FilterableTable
        columns={headers}
        loading={loading}
        rows={connections || []}
        rowMapper={rowMapper}
        pagination={pagination}
        defaultPageSize={7}
        inMemorySort={true}
      />
      {editingConnection && (
        <EmployerConnectionWizard
          employer={employer}
          // @ts-ignore
          connectionId={connectionToUpdate?.connectionId}
          onClose={closeEditModal}
          onUpserted={connectionUpdated}
        />
      )}
      {!!connectionToDelete && (
        <ConfirmDialogWithTextInput
          open={!!connectionToDelete}
          title={<FormattedMessage id={TranslationKeys.connections_confirmDeleteTitle} />}
          label={<FormattedMessage id={TranslationKeys.insurers_connectionName} />}
          errorMessage={<FormattedMessage id={TranslationKeys.connections_confirmDeleteMessage_invalidAnswer} />}
          solution={connectionToDelete?.connectionName}
          onClose={closeDeleteModal}
          onConfirm={executeDeleteConnection}
        >
          <Typography>
            <FormattedMessage id={TranslationKeys.connections_confirmDeleteMessage_paragraph1} />
          </Typography>

          <Typography lineHeight={2}>
            <FormattedMessage
              id={TranslationKeys.connections_confirmDeleteMessage_paragraph2}
              values={{ connectionName: connectionToDelete?.connectionName, b: chunks => <b>{chunks}</b> }}
            />
          </Typography>
        </ConfirmDialogWithTextInput>
      )}
    </>
  );
};
