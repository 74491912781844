import { useMutation } from '@tanstack/react-query';

import type { UpdateEmployeeEmploymentWageArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

const mutationFn = (data: UpdateEmployeeEmploymentWageArgs) => {
  return employeeService.updateEmployeeEmploymentWage(data);
};

export const useEmployeeUpdateEmploymentWageMutation = () => {
  return useMutation<unknown, unknown, UpdateEmployeeEmploymentWageArgs>(mutationFn);
};
