import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box } from '@mui/material';
import type {
  FilterableTableColumnType,
  FilterableTableFilters,
  FilterableTableOnFilterChangeFunc,
  FilterableTableOnSelectAllChangeFunc,
  FilterableTableOnSortChangeFunc,
  FilterableTablePaginationType,
  FilterableTableRowMapperFunc,
  FilterTableOnPaginationChangeFunc,
} from 'app/components';
import { ButtonDropdown, Checkbox, ConfirmDialog, FilterableTable, HasAccessTo, InfoDialog } from 'app/components';
import { TranslationKeys } from 'app/translations';
import type { ConnectionEvent } from 'app/types';
import { EVENT_TYPE_MAPPER } from 'app/types';
import ActionButton from 'app/components/ActionButton';
import { formatDate, formatDateTime, formatEvent } from 'app/utils/formatter/dateTimeFormatter';
import { formatYesNoEmpty } from 'app/utils/formatter/booleanFormatter';
import { ShowEventDetailsDialog } from 'app/Domain/Connections/Packages/InsuranceBrydge/Components/ShowEventDetailsDialog';
import { BATCH } from 'app/common/Authorization/entities';
import { EDIT } from 'app/common/Authorization/permissions';
import Typography from '@mui/material/Typography';
import { useConnectionEventTypeSelectItems } from '../../../../../../../../hooks/useConnectionEventTypeSelectItems';

enum TableColumns {
  SelectAll = 'col-selectAll',
  EventType = 'eventType',
  BatchTriggeredAt = 'batchTriggeredAt',
  EventDate = 'eventDate',
  State = 'state',
  CreatedAt = 'createdAt',
  PeriodStart = 'periodStart',
  PeriodEnd = 'periodEnd',
  Correction = 'correction',
}

type EmployeeEventsTableProps = {
  isLoading?: boolean;
  selectedRowsIds: string[];
  rows: ConnectionEvent[];
  pagination: FilterableTablePaginationType;
  onPaginationChange: FilterTableOnPaginationChangeFunc;
  onSortChange: FilterableTableOnSortChangeFunc;
  filters: Record<string, unknown>;
  onDownloadActionClick: (selectAll?: boolean) => void;
  onResendMutationsActionClick: (batches: Record<string, string[]>) => void;
  onFilterChange?: FilterableTableOnFilterChangeFunc;
  onSelectAllChange: FilterableTableOnSelectAllChangeFunc;
  onSelectRowChange: (event: any) => void;
};

export const EmployeeEventsTable = ({
  isLoading,
  selectedRowsIds,
  rows,
  pagination,
  filters,
  onDownloadActionClick,
  onResendMutationsActionClick,
  onPaginationChange,
  onSortChange,
  onFilterChange,
  onSelectAllChange,
  onSelectRowChange,
}: EmployeeEventsTableProps) => {
  const intl = useIntl();

  const hasPermissionToEditBatch = HasAccessTo(BATCH, EDIT);

  const [confirmDownloadDialogData, setConfirmDownloadDialogData] = React.useState<{
    message: string;
    selectAll: boolean;
  } | null>(null);

  const { connectionEventTypes } = useConnectionEventTypeSelectItems();

  const [showEventDetails, setShowEventDetails] = React.useState<ConnectionEvent | null>(null);
  const [showResendWarningDialog, setResendWarningDialog] = React.useState(false);
  const [batchesToBeResend, setBatchesToBeResend] = React.useState<Record<string, string[]> | null>(null);

  const onClickResendMutationsAction = React.useCallback(() => {
    const batches = selectedRowsIds.reduce((acc: Record<string, string[]>, mongoId: string) => {
      const eventData = rows.find((event: ConnectionEvent) => event.mongoId === mongoId);

      if (eventData && eventData.state === 'state.sent') {
        const batchId = eventData.batchId;

        if (!acc[batchId]) {
          acc[batchId] = [];
        }

        acc[batchId].push(eventData.externalId);
      }

      return acc;
    }, {} satisfies Record<string, string[]>);

    if (Object.keys(batches).length > 0) {
      setBatchesToBeResend(batches);
    } else {
      setResendWarningDialog(true);
    }
  }, [rows, selectedRowsIds]);

  const tableFilters = React.useMemo<FilterableTableFilters>(() => {
    return [
      {
        type: 'select',
        name: 'state',
        label: intl.formatMessage({ id: TranslationKeys.events_content_header_state }),
        items: [
          {
            element: intl.formatMessage({ id: TranslationKeys.all }),
            value: '',
            default: true,
          },
          {
            element: intl.formatMessage({ id: TranslationKeys.events_archived }),
            value: 'archived',
          },
          {
            element: intl.formatMessage({ id: TranslationKeys.state_new }),
            value: 'new',
          },
          {
            element: intl.formatMessage({ id: TranslationKeys.state_processing }),
            value: 'processing',
          },
          {
            element: intl.formatMessage({ id: TranslationKeys.state_sent }),
            value: 'sent',
          },
        ],
      },
      {
        type: 'multi-select',
        name: 'eventType',
        label: intl.formatMessage({ id: TranslationKeys.events_types }),
        items: connectionEventTypes,
      },
      {
        type: 'date',
        name: 'eventDate[after]',
        label: intl.formatMessage({ id: TranslationKeys.events_datePickerFrom }),
        value: filters['eventDate[after]'] ?? null,
        options: {
          startOfDay: true,
        },
      },
      {
        type: 'date',
        name: 'eventDate[before]',
        label: intl.formatMessage({ id: TranslationKeys.events_datePickerUntil }),
        value: filters['eventDate[before]'] ?? null,
        options: {
          endOfDay: true,
        },
      },
      {
        type: 'reset',
        name: 'reset',
        label: intl.formatMessage({ id: TranslationKeys.events_resetFilters }),
      },
    ];
  }, [connectionEventTypes, filters, intl]);

  const tableExtraFiltersContent = React.useMemo(() => {
    return (
      <Box
        display={'flex'}
        justifyContent={'end'}
        width={'100%'}
        gap={2}
        marginTop={1}
        marginRight={1}
        marginBottom={1}
      >
        <ButtonDropdown
          options={[
            {
              title: intl.formatMessage({ id: TranslationKeys.events_downloadAll }),
              disabled: rows?.length === 0,
              onClick: () =>
                setConfirmDownloadDialogData({
                  selectAll: true,
                  message: intl.formatMessage({ id: TranslationKeys.events_downloadAllConfirmMessage }),
                }),
            },
            {
              title: intl.formatMessage({ id: TranslationKeys.events_download }),
              disabled: selectedRowsIds.length === 0,
              onClick: () =>
                setConfirmDownloadDialogData({
                  selectAll: false,
                  message: intl.formatMessage({ id: TranslationKeys.events_downloadConfirmMessage }),
                }),
            },
            {
              title: intl.formatMessage({ id: TranslationKeys.events_resendSelected }),
              disabled: !hasPermissionToEditBatch || selectedRowsIds.length === 0,
              onClick: onClickResendMutationsAction,
            },
          ]}
        />
      </Box>
    );
  }, [hasPermissionToEditBatch, intl, onClickResendMutationsAction, rows?.length, selectedRowsIds.length]);

  const tableHeaders = React.useMemo<FilterableTableColumnType[]>(() => {
    return [
      {
        name: TableColumns.SelectAll,
        isSelectAllColumn: true,
      },
      {
        name: TableColumns.EventType,
        title: <FormattedMessage id={'events.content.header.@type'} />,
      },
      {
        name: TableColumns.BatchTriggeredAt,
        title: <FormattedMessage id={TranslationKeys.events_content_header_batchTriggeredAt} />,
      },
      {
        name: TableColumns.EventDate,
        title: <FormattedMessage id={TranslationKeys.events_content_header_eventDate} />,
      },
      {
        name: TableColumns.State,
        title: <FormattedMessage id={TranslationKeys.events_content_header_state} />,
      },
      {
        name: TableColumns.CreatedAt,
        title: <FormattedMessage id={TranslationKeys.events_content_header_createdAt} />,
        sortable: true,
      },
      {
        name: TableColumns.PeriodStart,
        title: <FormattedMessage id={TranslationKeys.events_content_header_periodStart} />,
        sortable: true,
      },
      {
        name: TableColumns.PeriodEnd,
        title: <FormattedMessage id={TranslationKeys.events_content_header_periodEnd} />,
        sortable: true,
      },
      {
        name: TableColumns.Correction,
        title: <FormattedMessage id={TranslationKeys.events_content_header_correction} />,
      },
    ];
  }, []);

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<ConnectionEvent>>(
    event => {
      const eventType = 'events.type.' + EVENT_TYPE_MAPPER[event.eventType];

      return {
        data: {
          [TableColumns.SelectAll]: (
            <Checkbox
              name={'employee-events-table-row-check'}
              checked={selectedRowsIds.includes(event.mongoId)}
              onChange={() => onSelectRowChange(event)}
            />
          ),
          [TableColumns.EventType]: (
            <ActionButton messageId={eventType} onClick={() => setShowEventDetails(event)} variant={'text'} />
          ),
          [TableColumns.BatchTriggeredAt]: event?.batch?.triggeredAt ? formatDateTime(event.batch.triggeredAt) : null,
          [TableColumns.EventDate]: formatEvent(event),
          [TableColumns.CreatedAt]: formatDateTime(event.createdAt),
          [TableColumns.PeriodStart]: formatDate(event.periodStart),
          [TableColumns.PeriodEnd]: formatDate(event.periodEnd),
          [TableColumns.Correction]: formatYesNoEmpty(event.correction),
          [TableColumns.State]: intl.formatMessage({ id: event.state }),
        },
      };
    },
    [intl, onSelectRowChange, selectedRowsIds],
  );

  return (
    <>
      <FilterableTable<ConnectionEvent>
        loading={isLoading}
        columns={tableHeaders}
        rows={rows || []}
        selectedRowsIds={selectedRowsIds}
        rowMapper={tableRowMapper}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        filters={tableFilters}
        onFilterChange={onFilterChange}
        onSortChange={onSortChange}
        onSelectAllChange={onSelectAllChange}
        extraFiltersContent={tableExtraFiltersContent}
        filterBackground={false}
      />
      {showEventDetails && (
        <ShowEventDetailsDialog
          open={!!showEventDetails}
          onClose={() => setShowEventDetails(null)}
          event={showEventDetails}
        />
      )}
      {!!confirmDownloadDialogData && (
        <ConfirmDialog
          open={!!confirmDownloadDialogData}
          title={<FormattedMessage id={TranslationKeys.events_downloadConfirmTitle} />}
          onConfirm={() => onDownloadActionClick(confirmDownloadDialogData.selectAll)}
          onClose={() => setConfirmDownloadDialogData(null)}
        >
          <span>{confirmDownloadDialogData.message}</span>
        </ConfirmDialog>
      )}
      {showResendWarningDialog && (
        <InfoDialog open={showResendWarningDialog} type="warning" onClose={() => setResendWarningDialog(false)}>
          <>
            <Typography>
              <FormattedMessage id={TranslationKeys.events_resendMutationWarningMessageLine1} />
            </Typography>
            <Typography marginTop={1}>
              <FormattedMessage id={TranslationKeys.events_resendMutationWarningMessageLine2} />
            </Typography>
          </>
        </InfoDialog>
      )}
      {!!batchesToBeResend && (
        <ConfirmDialog
          open={!!batchesToBeResend}
          title={<FormattedMessage id={TranslationKeys.events_resendMutationConfirmTitle} />}
          onConfirm={() => onResendMutationsActionClick(batchesToBeResend)}
          onClose={() => setBatchesToBeResend(null)}
        >
          <Box component={'span'} display={'flex'} flexDirection={'column'} gap={2}>
            <span>
              <FormattedMessage id={TranslationKeys.events_resendMutationConfirmMessage} />
            </span>
            <Box component={'span'} fontStyle={'italic'}>
              <FormattedMessage id={TranslationKeys.events_resendMutationConfirmMessageNote} />
            </Box>
          </Box>
        </ConfirmDialog>
      )}
    </>
  );
};
