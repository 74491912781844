import React from 'react';
import { useIntl } from 'react-intl';
import { EventFlowStatus } from '../types';

export const useEventFlowStatusSelectItems = () => {
  const intl = useIntl();

  const eventFlowStatusSelectItems = React.useMemo(() => {
    return [
      {
        value: EventFlowStatus.TODO,
        element: intl.formatMessage({ id: 'eventflow.category.todo' }),
      },
      {
        value: EventFlowStatus.IN_PROGRESS_CHECKED_EMPLOYER,
        element: intl.formatMessage({ id: 'eventflow.category.in-progress.checked-employer' }),
      },
      {
        value: EventFlowStatus.IN_PROGRESS_UNPROCESSED_BATCH,
        element: intl.formatMessage({ id: 'eventflow.category.in-progress.unprocessed-batch' }),
      },
      {
        value: EventFlowStatus.IN_PROGRESS_SENT,
        element: intl.formatMessage({ id: 'eventflow.category.in-progress.sent' }),
      },
      {
        value: EventFlowStatus.IN_PROGRESS_REJECTED,
        element: intl.formatMessage({ id: 'eventflow.category.in-progress.rejected' }),
      },
      {
        value: EventFlowStatus.IN_PROGRESS_CHECKED,
        element: intl.formatMessage({ id: 'eventflow.category.in-progress.checked' }),
      },
      {
        value: EventFlowStatus.COMPLETED_DONE,
        element: intl.formatMessage({ id: 'eventflow.category.completed.done' }),
      },
      {
        value: EventFlowStatus.COMPLETED_CHECKED,
        element: intl.formatMessage({ id: 'eventflow.category.completed.checked' }),
      },
    ];
  }, [intl]);

  return React.useMemo(() => ({ eventFlowStatusSelectItems }), [eventFlowStatusSelectItems]);
};
