import { styled } from '@mui/material/styles';
import { Chip, Grid, Typography } from '@mui/material';

import { ActionButton } from '../../../../../components/ActionButton';

export const TypographyStyled = styled(Typography, {
  name: 'TypographyStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 700,
}));

export const ChipStyled = styled(Chip, {
  name: 'ChipStyled',
})(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'uppercase',
  position: 'absolute',
  top: `-${theme.spacing(2)}px`,
  right: 0,
  opacity: 0.7,
}));

export const ActionButtonStyled = styled(ActionButton, {
  name: 'ActionButtonStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
