import React, { useMemo, useRef } from 'react';

import type { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';

import { CloseIconStyled, MuiDialogStyled } from './Dialog.styles';

export type DialogProps = {
  children: React.ReactNode;
  open: boolean;
  title: React.ReactElement | string;
  onClose?: () => void;
  onExit?: () => void;
  actions?: React.ReactNode;
  fullScreen?: boolean;
  maxWidth?: MuiDialogProps['maxWidth'];
  disableEnforceFocus?: boolean;
  fullWidth?: boolean;
};

export const Dialog = ({
  children,
  open,
  title,
  actions,
  fullScreen,
  onClose,
  onExit,
  disableEnforceFocus,
  maxWidth = 'sm',
  fullWidth = true,
}: DialogProps) => {
  const theme = useTheme();
  const fullScreenBreakpoint = useMediaQuery(theme.breakpoints.down('xs'));
  const headerRef = useRef<HTMLDivElement>(null);
  const actionRef = useRef<HTMLDivElement>(null);

  const isFullScreen = useMemo(() => fullScreen || fullScreenBreakpoint, [fullScreen, fullScreenBreakpoint]);

  return (
    <MuiDialogStyled
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      scroll="paper"
      fullWidth={fullWidth}
      fullScreen={isFullScreen}
      TransitionProps={{ onExit }}
      disableEnforceFocus={disableEnforceFocus}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <MuiDialogTitle variant={'h5'} ref={headerRef}>
          {title}
        </MuiDialogTitle>
        {(!!onClose || !!onExit) && <CloseIconStyled onClick={onClose || onExit} />}
      </Box>

      <MuiDialogContent>{children}</MuiDialogContent>
      {actions && <MuiDialogActions ref={actionRef}>{actions}</MuiDialogActions>}
    </MuiDialogStyled>
  );
};
