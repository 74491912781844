import React from 'react';

import { useDashboardGetUnprocessedEventsCountQuery, useDashboardGetUnprocessedEventsQuery } from '../../../../hooks';

import type { TableSelectedState } from '../../../../store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from '../../../../store';

import { TranslationKeys } from '../../../../translations';

import { useBreadcrumb } from '../../../../components';

import { UnprocessedTableFilters } from '../../Components';

import { UnprocessedEventsTable } from './components';

export const DashboardUnprocessedEventsPage = () => {
  const dispatch = useStoreDispatch();

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const { searchQuery, page, pageSize, filters, sortBy } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.DashboardSelector.selectUnprocessedEvents(state.DashboardReducer),
  );

  const {
    data: unprocessedEvents,
    isLoading: isLoadingUnprocessedEventsQuery,
    isFetching: isFetchingUnprocessedEventsQuery,
  } = useDashboardGetUnprocessedEventsQuery({
    variables: {
      userId,
      page,
      pageSize,
      searchQuery,
      filters,
      sortBy,
    },
    options: {
      enabled: !!userId,
    },
  });

  const {
    data: unprocessedEventsCount,
    isLoading: isLoadingUnprocessedEventsCountQuery,
    isFetching: isFetchingUnprocessedEventsCountQuery,
  } = useDashboardGetUnprocessedEventsCountQuery({
    variables: { userId, searchQuery, filters },
    options: {
      enabled: !!userId,
    },
  });

  const isUnprocessedEventsQueryLoading = isLoadingUnprocessedEventsQuery || isFetchingUnprocessedEventsQuery;

  const isUnprocessedEventsCountQueryLoading =
    isLoadingUnprocessedEventsCountQuery || isFetchingUnprocessedEventsCountQuery;

  const isLoading = isUnprocessedEventsQueryLoading || isUnprocessedEventsCountQueryLoading;

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.DashboardAction.unprocessedEvents.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.DashboardAction.unprocessedEvents.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.DashboardAction.unprocessedEvents.changePage(page));
      }
    },
    [dispatch],
  );

  useBreadcrumb(TranslationKeys.dashboard_category_dataProcessing, {
    customPathName: '/dashboard/data-processing',
  });
  useBreadcrumb(TranslationKeys.dashboard_subCategory_unprocessedEvents);

  return (
    <>
      <UnprocessedTableFilters
        isLoading={isLoading}
        reduxActionSearch={StoreActions.DashboardAction.unprocessedEvents.search}
        reduxActionApplyFilter={StoreActions.DashboardAction.unprocessedEvents.applyFilter}
        reduxActionRemoveFilter={StoreActions.DashboardAction.unprocessedEvents.removeFilter}
        reduxStateSelector={'selectUnprocessedEvents'}
      />

      <UnprocessedEventsTable
        isLoading={isLoading}
        unprocessedEvents={unprocessedEvents?.data || []}
        unprocessedEventsCount={unprocessedEventsCount}
        page={page}
        pageSize={pageSize}
        totalCount={unprocessedEvents?.totalCount ?? 0}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
      />
    </>
  );
};
