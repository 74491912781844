import { useMutation } from '@tanstack/react-query';

import type { User } from '../../../types';

import { userService } from '../../../Domain/Users/Services';

type Reset2FaTokenData = Pick<User, 'userId'>;

const mutationFn = (data: Reset2FaTokenData) => {
  return userService.resetUser2FaToken(data?.userId);
};

export const useUserReset2FaTokenMutation = () => {
  return useMutation<unknown, unknown, Reset2FaTokenData>(mutationFn);
};
