import { styled } from '@mui/material/styles';
import { buttonBaseClasses, checkboxClasses, InputAdornment, menuItemClasses } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export const InputAdornmentStyled = styled(InputAdornment, {
  name: 'InputAdornmentStyled',
  shouldForwardProp: (propName: string) => propName !== 'slim',
})<{ slim: boolean }>(({ theme, slim }) => ({
  marginRight: theme.spacing(slim ? 1 : 2),
  cursor: 'pointer',
}));

export const MenuItemStyled = styled(MenuItem, {
  name: 'MenuItemStyled',
})(({ theme }) => ({
  padding: theme.spacing(1),
  [`& .${buttonBaseClasses.root}.${menuItemClasses.root}`]: {
    padding: theme.spacing(1),
  },
  [`& .${buttonBaseClasses.root}.${checkboxClasses.root}`]: {
    padding: 0,
    paddingRight: theme.spacing(1),
  },
}));
