import type { EmployeeIncomePremiumSickLeave } from '../../../../../../../../hooks';
import React from 'react';
import { CalculationsCard } from '../../../EmployeePensionTab/components';
import { TranslationKeys } from '../../../../../../../../translations';
import type { CalculationsCardProps } from '../CalculationsCard';

type EmployeeIncomePremiumSickLeavePanelProps = {
  loading?: boolean;
  data: EmployeeIncomePremiumSickLeave | undefined;
};

export const EmployeeIncomePremiumSickLeavePanel = ({
  loading = false,
  data,
}: EmployeeIncomePremiumSickLeavePanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.employees_income_premiumPerYear,
        formulaId: TranslationKeys.employees_income_premiumPerYearFormula,
        value: {
          value: data?.premiumPerYear ?? 0,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonth,
        formulaId: TranslationKeys.employees_income_premiumPerMonthFormula,
        value: {
          value: data?.premiumPerMonth ?? 0,
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_premiumSickLeave}
      fields={cardFields}
      error={data?.message}
    />
  );
};
