import React from 'react';

import { CardContent } from '@mui/material';

import { CategoryCardActionAreaStyled, CategoryCardStyles } from './DashboardCategoryCard.styles';

export const DASHBOARD_CATEGORY_CARD_ACTION_AREA_BUTTON_DATA_TEST_ID = 'dashboard-category-card-action-button';

type DashboardCategoryCardProps = {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  style?: object;
};

export const DashboardCategoryCard = ({
  children,
  onClick,
  disabled = false,
  style = {},
}: DashboardCategoryCardProps) => {
  return (
    <CategoryCardStyles elevation={1} disabled={disabled} style={style}>
      <CategoryCardActionAreaStyled
        data-testid={DASHBOARD_CATEGORY_CARD_ACTION_AREA_BUTTON_DATA_TEST_ID}
        onClick={onClick}
        disabled={disabled}
      >
        <CardContent>{children}</CardContent>
      </CategoryCardActionAreaStyled>
    </CategoryCardStyles>
  );
};
