import type { Locale } from 'date-fns';

import type { NumericInputHelperOptions } from '../../components/FormikField/Text/NumericInputHelper';
import NumericInputHelper from '../../components/FormikField/Text/NumericInputHelper';
import { useLocale } from '../../components/Intl';

type FormatPercentageArgs = {
  locale: Locale;
  value: string | number;
  options?: NumericInputHelperOptions;
};

// To be used inside React hooks
export const formatPercentage = ({ locale, value, options = {} }: FormatPercentageArgs) => {
  // @ts-ignore
  const helper = new NumericInputHelper(locale.locale, {
    ...options,
    style: 'percent',
  });
  return helper.format(value);
};

/**
 * @warning
 * This method should not be used inside useEffect, useMemo or useCallback.
 * To use it inside react hooks, use the percentageFormat instead.
 */
export const FormattedPercentage = (
  value: FormatPercentageArgs['value'],
  options: FormatPercentageArgs['options'] = {},
) => {
  const { locale } = useLocale();
  return formatPercentage({ locale, value, options });
};
