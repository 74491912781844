import { styled } from '@mui/material/styles';

export const DivPanelsContainerStyled = styled('div', {
  name: 'DivPanelsContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
  },
}));
