import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes, useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import { useEmployeeGetEmployeeQuery } from 'app/hooks';

import { NotFoundView } from 'app/views';

import { EmployeeConnectionSelector } from './components';
import TabOutlet from 'app/components/TabOutlet';

import { TypographyStyled } from './EmployeeDetailPage.styles';

import { HasAccessTo } from 'app/components';
import { EVENT } from 'app/common/Authorization/entities';
import { VIEW } from 'app/common/Authorization/permissions';

export const EmployeeDetailPage = () => {
  const { employerId, employeeId } = useParams();

  const { data: employee, isLoading: isLoadingEmployer } = useEmployeeGetEmployeeQuery({
    variables: { employeeId, employerId },
    options: {
      enabled: !!employeeId && !!employerId,
    },
  });
  const disabledIfNoAccessToEventView = !HasAccessTo(EVENT, VIEW);

  const tabData = useMemo(() => {
    return [
      {
        path: 'info',
        label: <FormattedMessage id={TranslationKeys.employees_tab_general} />,
      },
      {
        path: 'employment',
        label: <FormattedMessage id={TranslationKeys.employees_tab_employments} />,
      },
      {
        path: 'income',
        label: <FormattedMessage id={TranslationKeys.employees_tab_income} />,
      },
      {
        path: 'pension',
        label: <FormattedMessage id={TranslationKeys.employees_tab_pension} />,
      },
      {
        path: 'health',
        label: <FormattedMessage id={TranslationKeys.employees_tab_health} />,
      },
      {
        path: 'events',
        label: <FormattedMessage id={TranslationKeys.connectionDetails_events} />,
        disabled: disabledIfNoAccessToEventView,
      },
    ];
  }, [disabledIfNoAccessToEventView]);

  const title = useMemo(() => {
    if (employee && !isLoadingEmployer) {
      return (
        <>
          <Typography variant="h5">{employee?.personName?.fullName}</Typography>
          <TypographyStyled variant="h6">
            <FormattedMessage
              id={
                employee.status === 'active'
                  ? TranslationKeys.employees_status_active
                  : TranslationKeys.employees_status_inactive
              }
            />
          </TypographyStyled>
        </>
      );
    }

    return (
      <>
        <Typography variant="h5">
          <Skeleton variant={'text'} width={200} />
        </Typography>
        <Typography variant="h6">
          <Skeleton variant={'text'} width={30} />
        </Typography>
      </>
    );
  }, [employee, isLoadingEmployer]);

  const breadcrumb = useMemo(() => {
    return {
      title: employee?.personName?.fullName,
      data: {
        titleElement: title,
      },
    };
  }, [employee?.personName?.fullName, title]);

  const exists = isLoadingEmployer || (!isLoadingEmployer && !!employee);

  return exists ? (
    <>
      <EmployeeConnectionSelector employerId={employerId} employeeId={employeeId} />
      {/*@ts-ignore*/}
      <TabOutlet tabs={tabData} breadcrumb={breadcrumb} />
    </>
  ) : (
    <Routes>
      <Route element={<NotFoundView />} path="*" />
    </Routes>
  );
};
