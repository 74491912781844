import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionButton from '../ActionButton';
import { Dialog } from '../Dialog';
import { TranslationKeys } from '../../translations';

type InfoDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  type: 'info' | 'warning';
  children: React.ReactElement;
};

const DIALOG_TITLE: Record<InfoDialogProps['type'], string> = {
  info: TranslationKeys.global_info,
  warning: TranslationKeys.global_warning,
};

export const InfoDialog = ({ open, type, onClose, onConfirm, children }: InfoDialogProps) => {
  const hasOnConfirmFunc = !!onConfirm;
  const closeButtonVariant = hasOnConfirmFunc ? 'outlined' : 'contained';

  const onClickConfirm = React.useCallback(() => {
    onConfirm && onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton messageId={TranslationKeys.close} onClick={onClose} variant={closeButtonVariant} />
        {hasOnConfirmFunc && <ActionButton messageId={TranslationKeys.yes} onClick={onClickConfirm} />}
      </>
    );
  }, [closeButtonVariant, hasOnConfirmFunc, onClickConfirm, onClose]);

  return (
    <Dialog open={open} fullWidth={false} title={<FormattedMessage id={DIALOG_TITLE[type]} />} actions={DialogActions}>
      {children}
    </Dialog>
  );
};
