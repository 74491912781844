import { createSelector } from 'reselect';

import { generateActionTypes, tableAction, tableActions, tableInitialState } from '../../App/Ducks/Table.duck';

const ActionTypePrefixes = {
  RateTables: 'Settings.RateTables',
  SummaryScales: 'Settings.SummaryScales',
} as const;

export const actionTypes = {
  RateTables: generateActionTypes(ActionTypePrefixes.RateTables),
  SummaryScales: generateActionTypes(ActionTypePrefixes.SummaryScales),
} as const;

export const settingsDuckInitialState = {
  rateTables: {
    ...tableInitialState,
  },
  summaryScales: {
    ...tableInitialState,
  },
};

export type SettingsDuckState = typeof settingsDuckInitialState;

export const reducer = (state = settingsDuckInitialState, action: Record<string, any>): SettingsDuckState => {
  if (action.type.startsWith(`[${ActionTypePrefixes.RateTables}]`)) {
    return {
      ...state,
      rateTables: tableAction(actionTypes.RateTables, state.rateTables, action, settingsDuckInitialState.rateTables),
    };
  }

  if (action.type.startsWith(`[${ActionTypePrefixes.SummaryScales}]`)) {
    return {
      ...state,
      summaryScales: tableAction(
        actionTypes.SummaryScales,
        state.summaryScales,
        action,
        settingsDuckInitialState.summaryScales,
      ),
    };
  }

  return state;
};

export const actions = {
  rateTables: tableActions(actionTypes.RateTables),
  summaryScales: tableActions(actionTypes.SummaryScales),
};

export const selectors = {
  selectRateTables: createSelector(
    (state: SettingsDuckState) => state.rateTables,
    rateTables => rateTables,
  ),
  selectSummaryScales: createSelector(
    (state: SettingsDuckState) => state.summaryScales,
    summaryScales => summaryScales,
  ),
};
