import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { DivContentStyled, DivRootStyled } from './PublicLayout.styles';

export const PUBLIC_LAYOUT_DATA_TEST_ID = 'public-layout-component';

type PublicLayoutProps = {
  titleId?: string;
  children: React.ReactNode;
  maxWidth?: number | string;
};

const MAX_WIDTH = 400;

export const PublicLayout = ({ titleId, maxWidth, children }: PublicLayoutProps) => {
  return (
    <DivRootStyled data-testid={PUBLIC_LAYOUT_DATA_TEST_ID}>
      <DivContentStyled>
        <Box width={'100%'} padding={5}>
          <Box display={'flex'} flexDirection={'column'} gap={2} maxWidth={MAX_WIDTH}>
            <img src="/images/logos/inlog-logo-IB.svg" alt={'logo'} />

            {!!titleId && (
              <Typography variant="h5">
                <FormattedMessage id={titleId} />
              </Typography>
            )}
          </Box>

          <Box maxWidth={maxWidth ?? MAX_WIDTH} marginTop={3}>
            {children}
          </Box>
        </Box>
      </DivContentStyled>
    </DivRootStyled>
  );
};
