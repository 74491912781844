import React from 'react';
import * as Yup from 'yup';
import type { FormikConfig } from 'formik';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

import { TranslationKeys } from '../../../../../../translations';

import { ActionButton, ActionLink, InputFieldFactory } from '../../../../../../components';

import type { SignInViewCredentialsFormValues } from '../../../../SignInView.types';
import { SIGN_IN_VIEW_CREDENTIALS_FORM_FIELDS } from '../../../../SignInView.types';

export const SIGN_IN_VIEW_CREDENTIALS_FORM_DATA_TEST_ID = 'sign-in-view-credentials-form-component';

type SignInViewCredentialsFormProps = {
  formikOnSubmit: FormikConfig<SignInViewCredentialsFormValues>['onSubmit'];
};

export const SignInViewCredentialsForm = ({ formikOnSubmit }: SignInViewCredentialsFormProps) => {
  const intl = useIntl();

  const formikInitialValues = React.useMemo<SignInViewCredentialsFormValues>(() => {
    return {
      email: '',
      password: '',
    };
  }, []);

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      password: Yup.string().max(50).required(),
      email: Yup.string().email().required(),
    });
  }, []);

  return (
    <Formik<SignInViewCredentialsFormValues>
      initialValues={formikInitialValues}
      validationSchema={formikValidationSchema}
      onSubmit={formikOnSubmit}
    >
      {({ status, submitForm }) => (
        <Form data-testid={SIGN_IN_VIEW_CREDENTIALS_FORM_DATA_TEST_ID}>
          <Box display={'flex'} flexDirection={'column'} gap={3}>
            {status && <Alert severity="error">{status}</Alert>}

            <InputFieldFactory
              field={{
                required: true,
                type: 'email',
                name: SIGN_IN_VIEW_CREDENTIALS_FORM_FIELDS.Email,
                header: intl.formatMessage({ id: TranslationKeys.global_email }),
              }}
            />

            <InputFieldFactory
              field={{
                required: true,
                type: 'password',
                name: SIGN_IN_VIEW_CREDENTIALS_FORM_FIELDS.Password,
                header: intl.formatMessage({ id: TranslationKeys.signIn_password }),
              }}
            />

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexWrap={'wrap-reverse'}
              gap={3}
            >
              <ActionButton messageId={TranslationKeys.signIn_button} type={'submit'} onClick={submitForm} />

              <ActionLink to="/forgot-password">
                <Typography variant={'subtitle2'}>
                  <FormattedMessage id={TranslationKeys.signIn_forgotPassword} />
                </Typography>
              </ActionLink>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
