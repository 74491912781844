export enum CivilStatusEnum {
  Single = 'single',
  Married = 'married',
  LivingTogether = 'living-together',
  LivingTogetherWithAgreement = 'living-together-with-agreement',
  LivingTogetherWithoutAgreement = 'living-together-without-agrement',
  Divorced = 'divorced',
  RegisteredPartnership = 'registered-partnership',
  WidowWidower = 'widower',
  Unknown = 'unknown',
}
