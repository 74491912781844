import {
  formatDate,
  formatDateTime,
  formatEvent,
  formatRelativeTime,
  formatTime,
  formatTimestamp,
  systemDate,
} from './dateTimeFormatter';
import { formatCurrency, FormattedCurrency } from './currencyFormatter';
import { formatPercentage, FormattedPercentage } from './percentageFormatter';
import { formatStreetName } from './streetFormatter';
import { formatOnOff, formatYes, formatYesNo, formatYesNoEmpty } from './booleanFormatter';
import { formatName } from './nameFormatter';

const format = {
  date: formatDate,
  time: formatTime,
  dateTime: formatDateTime,
  relativeTime: formatRelativeTime,
  timestamp: formatTimestamp,
  currency: FormattedCurrency,
  currencyFormat: formatCurrency,
  percentage: FormattedPercentage,
  percentageFormat: formatPercentage,
  event: formatEvent,
  street: formatStreetName,
  yes: formatYes,
  yesNo: formatYesNo,
  yesNoEmpty: formatYesNoEmpty,
  onOff: formatOnOff,
  name: formatName,
  systemDate: systemDate,
};

export default format;
