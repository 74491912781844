import type { Dictionary } from 'app/utils';
import type { LocaleObject } from 'yup';

export type YupLocaleObject = LocaleObject;

const validationMessages = (messages: Dictionary<string>): YupLocaleObject => ({
  mixed: {
    default: messages['validationMessage.mixed.default'],
    required: messages['validationMessage.mixed.required'],
    defined: messages['validationMessage.mixed.defined'],
    oneOf: messages['validationMessage.mixed.oneOf'],
    notOneOf: messages['validationMessage.mixed.notOneOf'],
    notType: messages['validationMessage.mixed.notType'],
  },
  string: {
    length: messages['validationMessage.string.length'],
    min: messages['validationMessage.string.min'],
    max: messages['validationMessage.string.max'],
    email: messages['validationMessage.string.email'],
    trim: messages['validationMessage.string.trim'],
    matches: messages['validationMessage.string.matches'],
    url: messages['validationMessage.string.url'],
  },
  number: {
    min: messages['validationMessage.number.min'],
    max: messages['validationMessage.number.max'],
    lessThan: messages['validationMessage.number.lessThan'],
    moreThan: messages['validationMessage.number.moreThan'],
    positive: messages['validationMessage.number.positive'],
    negative: messages['validationMessage.number.negative'],
    integer: messages['validationMessage.number.integer'],
  },
  date: {
    min: messages['validationMessage.date.min'],
    max: messages['validationMessage.date.max'],
  },
  boolean: {
    isValue: messages['validationMessage.boolean.isValue'],
  },
  object: {
    noUnknown: messages['validationMessage.object.noUnknown'],
  },
  array: {
    min: messages['validationMessage.array.min'],
    max: messages['validationMessage.array.max'],
    length: messages['validationMessage.array.length'],
  },
});

export default validationMessages;
