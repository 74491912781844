import { styled } from '@mui/material/styles';

export const DivContainer = styled('div', {
  name: 'DivContainer',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: theme.spacing(2),
  paddingTop: 0,
  borderRadius: theme.spacing(1),
}));
