import { userService } from '../../../Domain/Users/Services';
import type { User } from '../../../types';

type RequestVariablesType = Partial<Pick<User, 'userId'>>;

type RequestAPIKeyResultType = {
  apiKey: string;
};

export const useUserRequestAPIKeyQuery = () => {
  const requestAPIKey = async (variables: RequestVariablesType): Promise<RequestAPIKeyResultType | undefined> => {
    try {
      if (variables?.userId) {
        const apiKey = await userService.requestAPIKey(variables.userId);
        return Promise.resolve(apiKey.data);
      }
    } catch (err) {
      return Promise.reject(null);
    }
  };

  return { requestAPIKey };
};
