import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  autocompleteClasses,
  Button,
  Divider,
  FormLabel,
  Paper,
  svgIconClasses,
  Typography,
} from '@mui/material';

export const TypographyDescriptionStyled = styled(Typography, {
  name: 'TypographyDescriptionStyled',
})(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const AutocompleteStyled = styled(Autocomplete, {
  name: 'AutocompleteStyled',
})(({ theme }) => ({
  width: '100%',
  [`& .${autocompleteClasses.popupIndicator} .${svgIconClasses.root}`]: {
    color: theme.palette.primary.main,
  },
}));

export const PaperStyled = styled(Paper, {
  name: 'PaperStyled',
  shouldForwardProp: (propName: string) => !['isInfo'].includes(propName),
})<{ isInfo?: boolean }>(({ theme, isInfo = false }) => ({
  border: 'none',
  borderRadius: '10px',
  boxShadow: 'none',
  ...(isInfo
    ? {
        backgroundColor: theme.palette.common.white,
        paddingTop: theme.spacing(2),
      }
    : {
        backgroundColor: theme.palette.primary.light,
        height: '100%',
        padding: theme.spacing(2),
      }),
}));

export const TypographyQuestionStyled = styled(Typography, {
  name: 'TypographyQuestionStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textTransform: 'uppercase',
}));

export const TypographySubEmployerHeaderStyled = styled(Typography, {
  name: 'TypographySubEmployerHeaderStyled',
})(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
}));

export const DividerStyled = styled(Divider, {
  name: 'DividerStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  margin: theme.spacing(1),
  width: '100%',
}));

export const ButtonStyled = styled(Button, {
  name: 'ButtonStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
