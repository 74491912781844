import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as App from 'app/Domain/App/Ducks/App.duck';
import * as Credentials from 'app/Domain/Credentials/Ducks/Credentials.duck';
import * as Owners from 'app/Domain/Owners/Ducks/Owners.duck';
import * as Owner from 'app/Domain/Owners/Ducks/Owner.duck';
import * as Employers from 'app/Domain/Employers/Ducks/Employers.duck';
import * as Employer from 'app/Domain/Employers/Ducks/Employer.duck';
import * as Employee from 'app/Domain/Employees/Ducks/Employee.duck';
import * as Insurers from 'app/Domain/Insurers/Ducks/Insurers.duck';
import * as Insurer from 'app/Domain/Insurers/Ducks/Insurer.duck';
import * as Users from 'app/Domain/Users/Ducks/Users.duck';
import * as Roles from 'app/Domain/Users/Ducks/Roles.duck';
import * as Connections from 'app/Domain/Connections/Ducks/Connections.duck';
import * as Connection from 'app/Domain/Connections/Ducks/Connection.duck';
import * as Notes from 'app/Domain/Connections/Ducks/Notes.duck';
import * as ActivityLogs from 'app/Domain/Connections/Ducks/ActivityLogs.duck';
import * as LogEntries from 'app/Domain/Connections/Ducks/LogEntries.duck';
import * as Tasks from 'app/Domain/Connections/Ducks/Tasks.duck';
import * as Modules from 'app/Domain/Connections/Ducks/Modules.duck';
import * as Events from 'app/Domain/Connections/Ducks/Events.duck';
import * as Processes from 'app/Domain/Connections/Ducks/Processes.duck';
import * as ProcessEvents from 'app/Domain/Connections/Ducks/ProcessEvents.duck';
import * as Mutations from 'app/Domain/Connections/Ducks/Mutations.duck';
import * as CredentialRequests from 'app/Domain/Credentials/Ducks/CredentialRequests.duck';
import * as ReportsRequests from 'app/Domain/Reports/Ducks/Reports.duck';
import * as DownloadRequests from 'app/Domain/Connections/Ducks/Downloads.duck';
import * as Regulations from 'app/Domain/Regulations/Ducks/Regulations.duck';
import * as Dashboard from '../Domain/Dashboard/Ducks/Dashboard.duck';
import * as Settings from '../Domain/Settings/Ducks/Settings.duck';

export const StoreActions = {
  AppActions: App.actions,
  OwnersActions: Owners.actions,
  OwnerActions: Owner.actions,
  EmployersActions: Employers.actions,
  EmployerActions: Employer.actions,
  EmployeeActions: Employee.actions,
  InsurersActions: Insurers.actions,
  InsurerActions: Insurer.actions,
  UsersActions: Users.actions,
  RolesActions: Roles.actions,
  ConnectionActions: Connection.actions,
  NotesActions: Notes.actions,
  ConnectionsActions: Connections.actions,
  LogEntriesActions: LogEntries.actions,
  ActivityLogsActions: ActivityLogs.actions,
  TasksActions: Tasks.actions,
  EventsActions: Events.actions,
  ProcessesActions: Processes.actions,
  ProcessEventsActions: ProcessEvents.actions,
  CredentialsActions: Credentials.actions,
  ModulesActions: Modules.actions,
  CredentialRequestsActions: CredentialRequests.actions,
  ReportsActions: ReportsRequests.actions,
  DownloadsActions: DownloadRequests.actions,
  RegulationsActions: Regulations.actions,
  DashboardAction: Dashboard.actions,
  SettingsActions: Settings.actions,
};

export const StoreSelectors = {
  AppSelector: App.selectors,
  OwnerSelector: Owner.selectors,
  EmployerSelector: Employer.selectors,
  EmployeeSelector: Employee.selectors,
  InsurersSelector: Insurers.selectors,
  InsurerSelector: Insurer.selectors,
  ConnectionSelector: Connection.selectors,
  NotesSelector: Notes.selectors,
  ConnectionsSelector: Connections.selectors,
  CredentialsSelector: Credentials.selectors,
  RegulationsSelector: Regulations.selectors,
  DashboardSelector: Dashboard.selectors,
  SettingsSelector: Settings.selectors,
};

const appReducer = combineReducers({
  AppReducer: App.reducer,
  OwnersReducer: Owners.reducer,
  OwnerReducer: Owner.reducer,
  EmployersReducer: Employers.reducer,
  EmployerReducer: Employer.reducer,
  EmployeeReducer: Employee.reducer,
  InsurersReducer: Insurers.reducer,
  InsurerReducer: Insurer.reducer,
  UsersReducer: Users.reducer,
  RolesReducer: Roles.reducer,
  ConnectionReducer: Connection.reducer,
  NotesReducer: Notes.reducer,
  ConnectionsReducer: Connections.reducer,
  LogEntriesReducer: LogEntries.reducer,
  ActivityLogsReducer: ActivityLogs.reducer,
  TasksReducer: Tasks.reducer,
  EventsReducer: Events.reducer,
  ProcessesReducer: Processes.reducer,
  ProcessEventsReducer: ProcessEvents.reducer,
  MutationsReducer: Mutations.reducer,
  CredentialsReducer: Credentials.reducer,
  ModulesReducer: Modules.reducer,
  CredentialRequestsReducer: CredentialRequests.reducer,
  ReportsReducer: ReportsRequests.reducer,
  DownloadsReducer: DownloadRequests.reducer,
  RegulationsReducer: Regulations.reducer,
  DashboardReducer: Dashboard.reducer,
  SettingsReducer: Settings.reducer,
});

export const rootReducer: typeof appReducer = (state, action) => {
  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([
    Credentials.saga(),
    CredentialRequests.saga(),
    Connections.saga(),
    Connection.saga(),
    Notes.saga(),
    LogEntries.saga(),
    ActivityLogs.saga(),
    Tasks.saga(),
    Events.saga(),
    Processes.saga(),
    ProcessEvents.saga(),
    Mutations.saga(),
    Owners.saga(),
    Owner.saga(),
    Employers.saga(),
    Employer.saga(),
    Employee.saga(),
    Insurers.saga(),
    Insurer.saga(),
    Users.saga(),
    Roles.saga(),
    Modules.saga(),
    ReportsRequests.saga(),
    DownloadRequests.saga(),
    Regulations.saga(),
  ]);
}
