import axios from 'axios';
import { merge } from 'app/utils';

let getEventsToken;
export const getEvents = async (connectionId, page, perPage, sortBy, filters, searchQuery, useToken = true) => {
  try {
    if (useToken && typeof getEventsToken !== typeof undefined) {
      getEventsToken.cancel('Operation canceled due to new request.');
    }

    if (useToken) {
      getEventsToken = axios.CancelToken.source();
    }

    return axios.get(`/connections/${connectionId}/events`, {
      cancelToken: useToken ? getEventsToken.token : null,
      params: merge(
        {
          page,
          perPage,
          employee: searchQuery,
        },
        sortBy,
        filters,
      ),
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const processEvents = async ({ connectionId, eventIds, filters, searchQuery, selectAll, isDraft }) => {
  return axios.post(`/connections/${connectionId}/events/process`, {
    connectionId,
    eventIds,
    filters: merge(
      {
        employee: searchQuery,
      },
      filters,
    ),
    selectAll,
    isDraft,
  });
};

/**
 * @param {string} connectionId
 * @param {object} args
 * @param {Array.<string>} args.eventIds
 * @param {object} [args.filters]
 * @param {boolean} args.selectAll
 * @param {string} [args.batchId]
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const downloadEvents = async (connectionId, { eventIds, filters, selectAll, batchId }) => {
  return axios.post(`/connections/${connectionId}/events/download`, {
    connectionId,
    eventIds,
    filters,
    selectAll,
    batchId,
  });
};

export const archiveEvents = async (connectionId, eventIds, filters, searchQuery, selectAll) => {
  return axios.post(`/connections/${connectionId}/events/archive`, {
    connectionId,
    eventIds,
    filters: merge(
      {
        employee: searchQuery,
      },
      filters,
    ),
    selectAll,
  });
};

export const unArchiveEvents = async (connectionId, eventIds, filters, searchQuery, selectAll) => {
  return axios.post(`/connections/${connectionId}/events/unarchive`, {
    connectionId,
    eventIds,
    filters: merge({
      employee: searchQuery,
      filters,
    }),
    selectAll,
  });
};

export const archiveEventsWithSamePeriod = async connectionId => {
  return axios.post(`/connections/${connectionId}/events/archive-same-period`, {
    connectionId,
  });
};

const eventsService = { getEvents, archiveEvents, archiveEventsWithSamePeriod, downloadEvents };

export default eventsService;
