import React, { useCallback, useMemo } from 'react';
import { convertFileToBase64 } from 'app/utils';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { InputFieldFactory } from '../../../../../../components/FormikField';
import FileUploadArea from '../../../../../../components/FileUploadArea';
import { TranslationKeys } from '../../../../../../translations';

type EmployerFileUploadProps = {
  progressMsg: string | null;
  setProgressMsg: React.Dispatch<React.SetStateAction<string | null>>;
};

export const EmployerFileUpload = ({ setProgressMsg }: EmployerFileUploadProps) => {
  const intl = useIntl();
  const { isSubmitting, setFieldValue } = useFormikContext();

  const changeList = useCallback(
    async files => {
      const file = files.slice(0, 1)[0] ?? undefined;

      if (file) {
        const base64File = (await convertFileToBase64(file)) as string;

        setProgressMsg('employees.add.validateFile');
        setFieldValue('base64File', base64File);
        setProgressMsg('employees.add.validateFileFinish');
      }
    },
    [setFieldValue, setProgressMsg],
  );

  const fields = useMemo(
    () => [
      {
        type: 'text',
        placeholder: intl.formatMessage({ id: TranslationKeys.employers_tab_files_title }),
        required: true,
        name: 'title',
      },
    ],
    [intl],
  );

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        {fields.map((field, index) => {
          return (
            <Grid item xs={4} key={index}>
              <InputFieldFactory field={field} />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <FileUploadArea
            types={[]}
            onChange={changeList}
            disabled={false}
            showSuccessMessage={false}
            isSubmitting={isSubmitting}
          />
        </Grid>
      </Grid>
    </>
  );
};
