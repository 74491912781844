import React from 'react';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import CredentialConnectionsModal from 'app/Domain/Credentials/Components/CredentialConnectionsModal';
import { CredentialsTable, CredentialsToolbar } from 'app/Domain/Credentials/Components';
import FormikModal from 'app/components/FormikModal';
import { INSURANCE_BRYDGE } from 'app/Domain/Connections/Services/OnboardingService';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { useCredentialDeleteMutation, useSnakeBar } from 'app/hooks';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';
import { TranslationKeys } from 'app/translations';

import { DivStyled } from './CredentialsManagementPage.styles';
import { UpdateCredentialForm } from './components';

export const CredentialsManagementPage = () => {
  const dispatch = useStoreDispatch();
  const intl = useIntl();

  const { showSuccessSnakeBar, showWarningSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: deleteCredential } = useCredentialDeleteMutation();

  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const { loading, items, page, pageSize, totalCount, credential, packageType } = useStoreSelector(
    state => state.CredentialsReducer,
  );

  const { item: newCredential } = useStoreSelector(state =>
    StoreSelectors.CredentialsSelector.selectCredentialDetails(state.CredentialsReducer),
  );

  const [detailsVisible, setDetailsVisible] = React.useState(false);
  const [newCredentialForm, setNewCredentialForm] = React.useState<Record<string, any>>({});
  const [editCredentialModalOpen, setEditCredentialModalOpen] = React.useState(false);
  const [editCredentialInitialValues, setEditCredentialInitialValues] = React.useState<Record<string, any>>({});
  const [modalLoading, setModalLoading] = React.useState(false);

  React.useEffect(() => {
    dispatch(StoreActions.CredentialsActions.initializeCredentialsState());
    dispatch(StoreActions.CredentialsActions.requestData());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(StoreActions.CredentialsActions.availablePackages.requestData({ packages: [INSURANCE_BRYDGE] }));
  }, [dispatch]);

  React.useEffect(() => {
    if (credential) {
      setEditCredentialInitialValues({
        ...credential.data.extraData,
        ...credential.data,
      });
      if (packageType) {
        dispatch(StoreActions.CredentialsActions.credentialDetails.requestData({ packageType: packageType }));
      }
    }
  }, [credential, dispatch, packageType]);

  React.useEffect(() => {
    if (newCredential) {
      if (packageType in newCredentialForm) {
        setModalLoading(false);
        return;
      }
      setNewCredentialForm(prevState => {
        const currentState = Object.assign({}, prevState);
        currentState[packageType] = newCredential;
        return currentState;
      });
      setModalLoading(false);
    }
  }, [editCredentialModalOpen, newCredential, newCredentialForm, packageType]);

  const handleHideDetails = React.useCallback(() => {
    setDetailsVisible(false);
    dispatch(StoreActions.CredentialsActions.setCredentialId(null));
  }, [dispatch]);

  const handleClickEdit = React.useCallback(
    oldCredential => {
      dispatch(StoreActions.CredentialsActions.requestCredential(oldCredential));
      setEditCredentialModalOpen(true);
    },
    [dispatch],
  );

  const handleValues = React.useCallback(
    values => {
      return {
        ownerId,
        credentialId: values.credentialId,
        package: {
          packageType: values.packageType,
          credentialId: values.credentialId,
          credential: { ...values },
        },
      };
    },
    [ownerId],
  );

  const handleEditCredential = React.useCallback(
    async values => {
      dispatch(StoreActions.CredentialsActions.editCredential(handleValues(values)));
      setEditCredentialModalOpen(false);
    },
    [dispatch, handleValues],
  );

  const handleOnDeleteCredentialClick = React.useCallback(
    credentialId => {
      const method = 'deleteCredential';

      deleteCredential(
        { ownerId, credentialId },
        {
          onSuccess: () => {
            showSuccessSnakeBar({ method });
            dispatch(StoreActions.CredentialsActions.requestData());
          },
          onError: (error: any) => {
            const isInUseError = error?.response?.status === 409;

            if (isInUseError) {
              const message = intl.formatMessage({ id: error.response.data['hydra:description'] });
              showWarningSnakeBar({ method, message });
            } else {
              showErrorSnakeBar({ method });
            }
          },
        },
      );
    },
    [deleteCredential, dispatch, intl, ownerId, showErrorSnakeBar, showSuccessSnakeBar, showWarningSnakeBar],
  );

  const handleSearch = React.useCallback(
    event => {
      dispatch(StoreActions.CredentialsActions.search(event.target.value));
    },
    [dispatch],
  );

  const handlePageChange = React.useCallback(
    (event, newPage) => {
      dispatch(StoreActions.CredentialsActions.changePage(newPage + 1));
    },
    [dispatch],
  );

  const handleRowsPerPageChange = React.useCallback(
    event => {
      dispatch(StoreActions.CredentialsActions.setPageSize(event.target.value));
    },
    [dispatch],
  );

  const handleShowDetails = React.useCallback(
    credentialId => {
      dispatch(StoreActions.CredentialsActions.setCredentialId(credentialId));
      setDetailsVisible(true);
    },
    [dispatch],
  );

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      ownerId: Yup.string().required(),
      packageType: Yup.string().required(),
    });
  }, []);

  const formikCredentialInitialValues = React.useMemo(() => {
    return {
      ...editCredentialInitialValues,
      ownerId: editCredentialInitialValues.owner?.ownerId,
      ownerName: editCredentialInitialValues.owner?.ownerName,
    };
  }, [editCredentialInitialValues]);

  useBreadcrumb(TranslationKeys.menu_credentials, {
    hideTrail: true,
    hideTitle: true,
    otherElements: <CredentialsToolbar handleSearch={handleSearch} totalCount={loading ? undefined : totalCount} />,
  });

  const showEditCredentialModal = !modalLoading && !!newCredential && editCredentialModalOpen;

  return (
    <>
      <DivStyled>
        <CredentialsTable
          handleShowDetails={handleShowDetails}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handleClickChange={handleClickEdit}
          onDelete={handleOnDeleteCredentialClick}
          credentials={items}
          page={page - 1}
          rowsPerPage={pageSize}
          totalCount={totalCount}
          loading={loading}
        />
      </DivStyled>

      {showEditCredentialModal && (
        <Formik
          enableReinitialize
          initialValues={formikCredentialInitialValues}
          validationSchema={formikValidationSchema}
          onSubmit={handleEditCredential}
        >
          <FormikModal
            show={showEditCredentialModal}
            title={<FormattedMessage id={TranslationKeys.credentials_update} />}
            onHide={() => {
              setEditCredentialModalOpen(false);
              dispatch(StoreActions.CredentialsActions.clearAll());
            }}
          >
            <UpdateCredentialForm fields={newCredential.fields} />
          </FormikModal>
        </Formik>
      )}

      <CredentialConnectionsModal show={detailsVisible} hide={handleHideDetails} />
    </>
  );
};
