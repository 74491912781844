import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import { TranslationKeys } from 'app/translations';
import type { InputFactoryOnChangeFunc } from '../FormikField';
import { InputFieldFactory } from '../FormikField';
import { LinearProgressStyled } from './NotesInput.styles';
import { debounce } from '../../utils';

type NotesInputProps = {
  inputValue: string;
  onSaveNote: (inputValue: NotesInputProps['inputValue']) => void;
  isLoading?: boolean;
  isNotAllowed?: boolean;
  debounceTime?: number;
};

export const NotesInput = ({
  inputValue,
  onSaveNote,
  isLoading = false,
  isNotAllowed = false,
  debounceTime = 1000,
}: NotesInputProps) => {
  const onChange = React.useCallback<InputFactoryOnChangeFunc>(
    ({ value }) => {
      onSaveNote(value);
    },
    [onSaveNote],
  );

  const debouncedChangeHandler = React.useMemo(() => debounce(onChange, debounceTime), [onChange, debounceTime]);

  React.useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  return (
    <>
      <Typography variant="h6">
        <FormattedMessage id={TranslationKeys.notes} />
      </Typography>

      <InputFieldFactory
        onChange={debouncedChangeHandler}
        field={{
          name: 'notes',
          type: 'text',
          multiline: true,
          minRows: 5,
          defaultValue: inputValue,
          loading: isLoading,
          disabled: isNotAllowed,
        }}
      />

      {isLoading && <LinearProgressStyled color={'secondary'} />}
    </>
  );
};
