import axios from 'axios';

let getCredentialsToken;
export const getCredentials = async (page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getCredentialsToken !== typeof undefined) {
      getCredentialsToken.cancel('Operation canceled due to new request.');
    }

    getCredentialsToken = axios.CancelToken.source();

    return axios.get('credentials', {
      cancelToken: getCredentialsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getOwnerCredentialsToken;
export const getOwnerCredentials = async (page, perPage, sortBy, filters, globalFilter, ownerId) => {
  try {
    if (typeof getOwnerCredentialsToken !== typeof undefined) {
      getOwnerCredentialsToken.cancel('Operation canceled due to new request.');
    }

    getOwnerCredentialsToken = axios.CancelToken.source();

    return axios.get(`owners/${ownerId}/credentials`, {
      cancelToken: getOwnerCredentialsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getCredentialRequestsToken;
export const getCredentialRequests = async (page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getCredentialRequestsToken !== typeof undefined) {
      getCredentialRequestsToken.cancel('Operation canceled due to new request.');
    }

    getCredentialRequestsToken = axios.CancelToken.source();

    return axios.get('credential-requests', {
      cancelToken: getCredentialRequestsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getOwnerCredentialRequestsToken;
export const getOwnerCredentialRequests = async (page, perPage, sortBy, filters, globalFilter, ownerId) => {
  try {
    if (typeof getCredentialRequestsToken !== typeof undefined) {
      getOwnerCredentialRequestsToken.cancel('Operation canceled due to new request.');
    }

    getOwnerCredentialRequestsToken = axios.CancelToken.source();

    return axios.get(`owners/${ownerId}/credential-requests`, {
      cancelToken: getOwnerCredentialRequestsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const requestCredential = async values => {
  return axios.post('/credentials', { ...values });
};

let getCredentialConnectionsToken;
export const getCredentialConnections = async credentialId => {
  try {
    if (typeof getCredentialConnectionsToken !== typeof undefined) {
      getCredentialConnectionsToken.cancel('Operation canceled due to new request.');
    }

    getCredentialConnectionsToken = axios.CancelToken.source();

    return axios.get('connections', {
      cancelToken: getCredentialConnectionsToken.token,
      params: {
        credentialId,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const getOwnerCredentialConnections = async (credentialId, ownerId) => {
  try {
    if (typeof getCredentialConnectionsToken !== typeof undefined) {
      getCredentialConnectionsToken.cancel('Operation canceled due to new request.');
    }
    getCredentialConnectionsToken = axios.CancelToken.source();

    return axios.get(`owners/${ownerId}/connections`, {
      cancelToken: getCredentialConnectionsToken.token,
      params: {
        credentialId,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const createCredential = async values => {
  return axios.post(`/owners/${values.ownerId}/credentials`, { ...values });
};

export const getCredential = async credentialId => {
  return axios.get(`/credentials/${credentialId}`);
};

export const editCredential = async values => {
  return axios.put(`/owners/${values.ownerId}/credentials/${values.credentialId}`, { ...values });
};

export const deleteCredential = async ({ ownerId, credentialId }) => {
  return axios.delete(`/owners/${ownerId}/credentials/${credentialId}`);
};

const credentialService = {
  getCredentials,
  getCredentialRequests,
  requestCredential,
  getCredentialConnections,
  editCredential,
  getCredential,
  createCredential,
  getOwnerCredentials,
  getOwnerCredentialRequests,
  deleteCredential,
};

export default credentialService;
