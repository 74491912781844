import { styled } from '@mui/material/styles';
import { Button, Divider, FormLabel, outlinedInputClasses, Paper, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

export const PaperInfoStyled = styled(Paper, {
  name: 'PaperInfoStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: 'none',
  borderRadius: '10px',
  boxShadow: 'none',
  paddingTop: theme.spacing(2),
}));

export const PaperSubStyled = styled(Paper, {
  name: 'PaperSubStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  border: 'none',
  borderRadius: '10px',
  boxShadow: 'none',
  height: '100%',
  padding: theme.spacing(2),
}));

export const TypographyWithMarginBottomStyled = styled(Typography, {
  name: 'TypographyWithMarginBottomStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
})(({ theme }) => ({
  color: theme.palette.secondary.dark,
  textTransform: 'uppercase',
}));

export const TypographyTotalCountStyled = styled(Typography, {
  name: 'TypographyTotalCountStyled',
})(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
}));

export const DividerStyled = styled(Divider, {
  name: 'DividerStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  margin: theme.spacing(1),
  width: '100%',
}));

export const TextFieldStyled = styled(TextField, {
  name: 'TextFieldStyled',
})(({ theme }) => ({
  backgroundColor: 'white',
  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1.5),
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: '1px solid ' + theme.palette.secondary.main,
  },
}));

export const TypographyDescriptionStyled = styled(Typography, {
  name: 'TypographyDescriptionStyled',
})(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const ButtonStyled = styled(Button, {
  name: 'ButtonStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
