import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { FormikConfig } from 'formik';

import { normalizeError, normalizeFormError } from '../../utils';

import { useStoreDispatch, useStoreSelector } from '../../store';

import { appService } from '../../Domain/App/Services/AppServices';
import { actions as AppActions } from '../../Domain/App/Ducks/App.duck';

import { PublicLayout } from '../../layouts';

import { SignInViewContent } from './components';
import type { SignInViewCredentialsFormValues } from './SignInView.types';

export const SignInView = () => {
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();

  const initialPath = useStoreSelector(state => state.AppReducer.initialPath);

  const [loginValues, setLoginValues] = React.useState<SignInViewCredentialsFormValues | {}>({});
  const [qrInit, setQrInit] = React.useState(false);
  const [qrInitKey, setQrInitKey] = React.useState(1);
  const [qrCode, setQrCode] = React.useState<string | null>(null);
  const [twoFaInit, setTwoFaInit] = React.useState(false);
  const [twoFaInitKey, setTwoFaInitKey] = React.useState(1);
  const [twoFaFailed, setTwoFaFailed] = React.useState(false);

  const handleLogin = React.useCallback<FormikConfig<SignInViewCredentialsFormValues>['onSubmit']>(
    async (values, form) => {
      try {
        const data = await appService.login(values);

        if (data.status === 401) {
          if (!!data.data.message && !!data.data.message.includes('challenge code')) {
            setLoginValues(values);
            setQrInit(true);
            setTwoFaInit(true);
          } else {
            form.setStatus(data.data.message);
          }
        } else if (data.data.qr) {
          setLoginValues(values);
          setQrInit(true);
          setQrCode(`data:image/png;${data.data.qr}`);
        } else {
          const { data: user } = await appService.getUserWithToken(data.data.token, data.data.userId);
          dispatch(AppActions.login(data.data.token, data.data.refresh_token, '', user));
          navigate(initialPath);
        }
      } catch (error: any) {
        const errors = normalizeFormError(error, form);
        if (!errors.violations) {
          throw errors.error;
        }
      }
    },
    [dispatch, initialPath, navigate],
  );

  const handleLoginWithCode = React.useCallback(
    async (code: string) => {
      const submitValues = {
        ...loginValues,
        challengeCode: code,
      };

      try {
        const data = await appService.login(submitValues);

        if (data.data.code === 401) {
          setQrInitKey(qrInitKey + 1);
          setTwoFaInitKey(twoFaInitKey + 1);
          setTwoFaFailed(true);
        } else {
          const { data: user } = await appService.getUserWithToken(data.data.token, data.data.userId);
          dispatch(AppActions.login(data.data.token, data.data.refresh_token, '', user));
          navigate(initialPath);
        }
      } catch (error: any) {
        const errors = normalizeError(error);
        if (errors.violations) {
          setTwoFaFailed(true);
          return;
        }
        throw errors.error;
      }
    },
    [dispatch, initialPath, loginValues, navigate, qrInitKey, twoFaInitKey],
  );

  const showCredentialsForm = !qrInit && !twoFaInit;
  const showTwoFactorAuthSecurityCodeInput = qrInit && twoFaInit;
  const showTwoFactorAuthSetupSteps = qrInit && !twoFaInit;

  return (
    <PublicLayout maxWidth={showTwoFactorAuthSetupSteps ? '100%' : undefined}>
      <SignInViewContent
        showCredentialsForm={showCredentialsForm}
        showTwoFactorAuthSecurityCodeInput={showTwoFactorAuthSecurityCodeInput}
        showTwoFactorAuthSetupSteps={showTwoFactorAuthSetupSteps}
        twoFaFailed={twoFaFailed}
        qrCode={qrCode}
        handleLogin={handleLogin}
        handleLoginWithCode={handleLoginWithCode}
      />
    </PublicLayout>
  );
};
