import { call, put, select, takeLatest } from 'redux-saga/effects';
import { generateActionTypes, tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import insurerService from 'app/Domain/Insurers/Services/InsurerService/insurerService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { createSelector } from 'reselect';
import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload, normalizeError } from 'app/utils';
import {
  generateActionTypes as generateSingleActionTypes,
  singleObjectAction,
  singleObjectActions,
  singleObjectInitialState,
} from '../../App/Ducks/SingleObject.duck';

export const actionTypes = {
  Insurer: generateSingleActionTypes('Insurer'),
  Products: generateActionTypes('Insurer.Products'),
  Contacts: generateActionTypes('Insurer.Contacts'),
};

const initialState = {
  insurer: { ...singleObjectInitialState },
  contacts: { ...tableInitialState, pageSize: 100 },
  products: { ...tableInitialState, pageSize: 100 },
  legal: null,
};

export const reducer = (state = initialState, action) => {
  if (action.type.startsWith('[Insurer]')) {
    return {
      ...state,
      insurer: singleObjectAction(actionTypes.Insurer, state.insurer, action),
    };
  } else if (action.type.startsWith('[Insurer.Contacts]')) {
    return {
      ...state,
      contacts: tableAction(actionTypes.Contacts, state.contacts, action, initialState.contacts),
    };
  } else if (action.type.startsWith('[Insurer.Products]')) {
    return {
      ...state,
      products: tableAction(actionTypes.Products, state.products, action, initialState.products),
    };
  }
  return state;
};

export const actions = {
  insurer: singleObjectActions(actionTypes.Insurer),
  contacts: tableActions(actionTypes.Contacts),
  products: tableActions(actionTypes.Products),
};

export function* saga() {
  yield takeLatest(actionTypes.Insurer.RequestData, function* getInsurerSaga({ payload }) {
    const { insurerId, onError, onSuccess } = payload;
    try {
      const response = yield call(insurerService.getInsurer, insurerId);
      yield put(actions.insurer.fulfilledData(response.data));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.insurer.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getInsurer',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.Contacts.RequestData, function* getOwnerInsurerContactsSaga({ payload }) {
    const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(
      yield select(state => selectors.selectContacts(state.InsurerReducer)),
    );
    const { ownerId, ownerInsurerId, onError, onSuccess } = payload || {};
    try {
      const response = yield call(insurerService.getOwnerInsurerContacts, ownerId, ownerInsurerId, pagination);
      yield put(actions.contacts.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.contacts.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getInsurerContacts',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.Products.RequestData, function* getInsurerProductsSaga({ payload }) {
    const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(
      yield select(state => selectors.selectInsurerProducts(state.InsurerReducer)),
    );
    const { onError, onSuccess, insurerId, type } = payload || {};
    try {
      const response = yield call(insurerService.getInsurerProducts, insurerId, { type, ...pagination });
      yield put(actions.products.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.products.error(error));
      yield put(
        AppActions.displayMessage({
          method: 'getInsurerProducts',
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
      yield onError && onError(errorData);
    }
  });
}

export const selectors = {
  selectInsurer: createSelector(
    state => state.insurer,
    insurer => insurer,
  ),
  selectContacts: createSelector(
    state => state.contacts,
    contacts => contacts,
  ),
  selectInsurerProducts: createSelector(
    state => state.products,
    products => products,
  ),
};
