import React from 'react';

import { Grid, Typography } from '@mui/material';

import { DivContentStyled, DivRootStyled, ImgStyled } from './NotAllowedView.styles';

export const NotAllowedView = () => {
  return (
    <DivRootStyled>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <DivContentStyled>
            <Typography variant="h1">{'403: You aren’t allowed to be here'}</Typography>
            <Typography variant="subtitle2">
              {
                'You either tried some shady route, you came here by mistake or your user permissions have been changed.'
              }
              {'Whichever it is, try using the navigation'}
            </Typography>
            <ImgStyled alt="Under development" src="/images/not_found.png" />
          </DivContentStyled>
        </Grid>
      </Grid>
    </DivRootStyled>
  );
};
