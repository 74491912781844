import { styled } from '@mui/material/styles';

export const DivTooltipContentStyled = styled('div', {
  name: 'DivTooltipContentStyled',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
}));
