import type { EmployeeIncomePremiumWGAERD } from '../../../../../../../../hooks';
import React from 'react';
import type { CalculationsCardProps } from '../CalculationsCard';
import { CalculationsCard } from '../../../EmployeePensionTab/components';
import { TranslationKeys } from '../../../../../../../../translations';

type EmployeeIncomePremiumWGAPanelProps = {
  loading?: boolean;
  data: EmployeeIncomePremiumWGAERD | undefined;
};

export const EmployeeIncomePremiumsWGAERD = ({ loading = false, data }: EmployeeIncomePremiumWGAPanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.employees_income_premiumPerYearWGAERD,
        formulaId: TranslationKeys.employees_income_premiumPerYearWGAERDFormula,
        value: {
          value: data?.premiumPerYearWgaErd ?? 0,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonthWGAERD,
        formulaId: TranslationKeys.employees_income_premiumPerMonthWGAERDFormula,
        value: {
          value: data?.premiumPerMonthWgaErd ?? 0,
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_premiumWGAERD}
      fields={cardFields}
      error={data?.message}
    />
  );
};
