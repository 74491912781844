import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormControlLabel, Grid } from '@mui/material';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  CheckboxStyled,
  FontAwesomeIconStyled,
  FormLabelStyled,
  GridAddressContainerStyled,
  GridContactContainerStyled,
  GridItemStyled,
  LinkStyled,
  TextFieldStyled,
} from './CreateSubOwnerForm.styles';

const CreateSubOwnerForm = props => {
  // props
  const { owner, subOwner, onDelete } = props;

  // uses
  const intl = useIntl();

  // data
  const [ownerErrorField, setownerErrorField] = useState(null);
  const [ownerErrorMessage, setownerErrorMessage] = useState(null);
  const [sameAddressAsHolding, setSameAddressAsHolding] = useState(true);
  const [sameContactAsHolding, setSameContactAsHolding] = useState(true);

  const validationSchema = Yup.object().shape({
    ownerName: Yup.string().trim().strict().max(255).required(),
    ownerEmail: Yup.string().trim().strict().max(255).email(),
    ownerPhone: Yup.string().trim().strict().max(255),
    contactName: Yup.string().trim().strict().max(255),
    contactEmail: Yup.string().trim().strict().max(255).email(),
    contactPhone: Yup.string().trim().strict().max(255),
    address: Yup.string().trim().strict(),
    zipCode: Yup.string().trim().strict().max(255),
    city: Yup.string().trim().strict().max(255),
  });

  // effects
  useEffect(() => {
    subOwner.parentId = owner.ownerId;

    subOwner.address = owner.address;
    subOwner.zipCode = owner.zipCode;
    subOwner.city = owner.city;
    subOwner.contactName = owner.contactName;
    subOwner.contactEmail = owner.contactEmail;
    subOwner.contactPhone = owner.contactPhone;
  }, [owner, subOwner]);

  // events
  const handleChange = field => event => {
    subOwner[field] = event.target.value;

    Yup.reach(validationSchema, field)
      .validate(subOwner[field])
      .then(() => {
        setownerErrorField(null);
        setownerErrorMessage(null);
      })
      .catch(e => {
        setownerErrorField(field);
        setownerErrorMessage(e.message);
      });
  };

  const handleSameAddressAsHoldingChange = event => {
    setSameAddressAsHolding(event.target.checked);

    if (!event.target.checked) {
      subOwner.address = '';
      subOwner.zipCode = '';
      subOwner.city = '';
    } else {
      subOwner.address = owner.address;
      subOwner.zipCode = owner.zipCode;
      subOwner.city = owner.city;
    }
  };

  const handleSameContactAsHoldingChange = event => {
    setSameContactAsHolding(event.target.checked);

    if (!event.target.checked) {
      subOwner.contactName = '';
      subOwner.contactEmail = '';
      subOwner.contactPhone = '';
    } else {
      subOwner.contactName = owner.contactName;
      subOwner.contactEmail = owner.contactEmail;
      subOwner.contactPhone = owner.contactPhone;
    }
  };

  const handleDelete = id => event => {
    onDelete(id, event);
  };

  // template
  return (
    <Grid sx={{ width: '100%' }} container spacing={2}>
      {/* name */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="owners.subOwnerName" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          error={ownerErrorField === 'ownerName'}
          fullWidth
          helperText={ownerErrorField === 'ownerName' ? ownerErrorMessage : null}
          name="ownerName"
          placeholder={intl.formatMessage({ id: 'owners.subOwnerName' }).toUpperCase()}
          required
          variant="outlined"
          onChange={handleChange('ownerName')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <LinkStyled
          href="#"
          hasSubOwners={owner.subOwners.length > 0}
          variant="body1"
          onClick={handleDelete(subOwner.ownerId)}
        >
          <FontAwesomeIconStyled icon={faTrash} />
          <FormattedMessage id="owners.delete" />
        </LinkStyled>
      </GridItemStyled>

      {/* same address / contact */}
      <GridItemStyled item xs={6}>
        <FormControlLabel
          control={
            <CheckboxStyled
              color={'secondary'}
              checked={sameAddressAsHolding}
              onChange={handleSameAddressAsHoldingChange}
            />
          }
          label={<FormattedMessage id="owners.add.subOwners.sameAddress" />}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormControlLabel
          control={
            <CheckboxStyled
              color={'secondary'}
              checked={sameContactAsHolding}
              onChange={handleSameContactAsHoldingChange}
            />
          }
          label={<FormattedMessage id="owners.add.subOwners.sameContact" />}
        />
      </GridItemStyled>

      {/* address/city/zipCode */}
      {!sameAddressAsHolding && (
        <GridAddressContainerStyled container>
          <GridItemStyled item xs={12}>
            <FormLabelStyled>
              <FormattedMessage id="owners.address" />
            </FormLabelStyled>
            <TextFieldStyled
              color="secondary"
              fullWidth
              name="address"
              placeholder={intl.formatMessage({ id: 'owners.address' }).toUpperCase()}
              variant="outlined"
              onChange={handleChange('address')}
            />
          </GridItemStyled>

          <Grid container spacing={2}>
            <GridItemStyled item xs={6}>
              <FormLabelStyled>
                <FormattedMessage id="owners.zipCode" />
              </FormLabelStyled>
              <TextFieldStyled
                color="secondary"
                fullWidth
                name="zipCode"
                placeholder={intl.formatMessage({ id: 'owners.zipCode' }).toUpperCase()}
                variant="outlined"
                onChange={handleChange('zipCode')}
              />
            </GridItemStyled>
            <GridItemStyled item xs={6}>
              <FormLabelStyled>
                <FormattedMessage id="owners.city" />
              </FormLabelStyled>
              <TextFieldStyled
                color="secondary"
                fullWidth
                name="city"
                placeholder={intl.formatMessage({ id: 'owners.city' }).toUpperCase()}
                variant="outlined"
                onChange={handleChange('city')}
              />
            </GridItemStyled>
          </Grid>
        </GridAddressContainerStyled>
      )}

      {!sameContactAsHolding && (
        <GridContactContainerStyled container spacing={2}>
          <GridItemStyled item xs={4}>
            <FormLabelStyled>
              <FormattedMessage id="owners.contactName" />
            </FormLabelStyled>
            <TextFieldStyled
              color="secondary"
              fullWidth
              name="contactName"
              placeholder={intl.formatMessage({ id: 'owners.contactName' }).toUpperCase()}
              variant="outlined"
              onChange={handleChange('contactName')}
            />
          </GridItemStyled>
          <GridItemStyled item xs={4}>
            <FormLabelStyled>
              <FormattedMessage id="owners.contactEmail" />
            </FormLabelStyled>
            <TextFieldStyled
              color="secondary"
              fullWidth
              name="contactEmail"
              placeholder={intl.formatMessage({ id: 'owners.contactEmail' }).toUpperCase()}
              type="email"
              variant="outlined"
              onChange={handleChange('contactEmail')}
            />
          </GridItemStyled>
          <GridItemStyled item xs={4}>
            <FormLabelStyled>
              <FormattedMessage id="owners.contactPhone" />
            </FormLabelStyled>
            <TextFieldStyled
              color="secondary"
              fullWidth
              name="contactPhone"
              placeholder={intl.formatMessage({ id: 'owners.contactPhone' }).toUpperCase()}
              variant="outlined"
              onChange={handleChange('contactPhone')}
            />
          </GridItemStyled>
        </GridContactContainerStyled>
      )}
    </Grid>
  );
};

CreateSubOwnerForm.propTypes = {
  owner: PropTypes.object,
  subOwner: PropTypes.object,
  onDelete: PropTypes.func,
};

export default CreateSubOwnerForm;
