import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { EmployeeRelated, UseQueryWrapper } from 'app/types';

import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

import { useSnakeBar } from '../../useSnakeBar';

type GetRelatedQueryResultData = EmployeeRelated;

type GetRelatedQueryVariables = {
  employerId: string | undefined;
  employeeId: string | undefined;
};

const queryFn =
  ({ employeeId, employerId }: GetRelatedQueryVariables) =>
  async () => {
    if (employeeId && employerId) {
      const response = await employeeService.getEmployeeRelated(employerId, employeeId);
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployeeRelated';

export const useEmployeeGetRelatedQuery: UseQueryWrapper<GetRelatedQueryResultData, GetRelatedQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetRelatedQueryResultData>([QUERY_KEY, variables.employeeId], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
