export enum EventTypeEnum {
  NewEmployee = 'new-employee',
  ContractUpdated = 'contract-updated',
  EmployeeAddressUpdated = 'employee-address-updated',
  EmployeeInformationUpdated = 'employee-information-updated',
  EmployeePartTimePercentageUpdated = 'employee-part-time-percentage-updated',
  EmployeePartnerUpdated = 'employee-partner-updated',
  GrossWageUpdated = 'gross-wage-updated',
  EmployeeLeftCompany = 'employee-left-company',
  NewPeriodicWage = 'new-periodic-wage',
  ReEmployed = 're-employed',
}
