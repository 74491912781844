import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useDialog } from 'app/hooks';

import { TranslationKeys } from 'app/translations';

import { actions as OwnerActions, selectors as OwnerSelector } from '../../Ducks/Owner.duck';

import { ActionLink, FilterableTable, useBreadcrumb } from 'app/components';

import { CreateSubOwnerDialog } from 'app/Domain/Owners/Components/CreateSubOwnerDialog';
import { SubOwnersToolbar } from 'app/Domain/Owners/Components/SubOwnersToolbar';

export const SubOwnerOverviewPage = () => {
  const dispatch = useDispatch();

  const { dialogState: showCreateDialog, closeDialog: closeCreateDialog, openDialog: openCreateDialog } = useDialog();

  const {
    loading: subOwnersLoading,
    loaded: subOwnersLoaded,
    items: subOwners,
    page,
    pageSize,
    totalCount,
    searchQuery,
  } = useSelector<any, any>(state => OwnerSelector.selectSubOwners(state.OwnerReducer));

  useEffect(() => {
    dispatch(OwnerActions.subOwners.requestData());
  }, [dispatch]);

  const onCloseCreateDialog = React.useCallback(
    (reloadList = false) => {
      if (reloadList) {
        dispatch(OwnerActions.subOwners.requestData());
      }
      closeCreateDialog();
    },
    [closeCreateDialog, dispatch],
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(OwnerActions.subOwners.search(event.target.value));
    },
    [dispatch],
  );

  const onPaginationChange = useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(OwnerActions.subOwners.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(OwnerActions.subOwners.changePage(page));
      }
    },
    [dispatch],
  );

  const onSortChange = useCallback(
    sortBy => {
      dispatch(OwnerActions.subOwners.sortTable(sortBy));
    },
    [dispatch],
  );

  const headers = [
    {
      name: 'subOwnerName',
      title: <FormattedMessage id={TranslationKeys.subOwners_subOwnerName} />,
      sortable: false,
    },
    {
      name: 'ownerName',
      title: <FormattedMessage id={TranslationKeys.subOwners_ownerName} />,
      sortable: false,
    },
    {
      name: 'employers',
      title: <FormattedMessage id={TranslationKeys.subOwners_employers} />,
      sortable: false,
    },
    {
      name: 'contactName',
      title: <FormattedMessage id={TranslationKeys.subOwners_contactName} />,
    },
    {
      name: 'contactEmail',
      title: <FormattedMessage id={TranslationKeys.subOwners_contactEmail} />,
    },
    {
      name: 'contactPhone',
      title: <FormattedMessage id={TranslationKeys.subOwners_contactPhone} />,
    },
  ];

  const pagination = useMemo(() => {
    return {
      pageSize,
      totalCount,
      page,
    };
  }, [totalCount, page, pageSize]);

  const rowMapper = useCallback(subOwner => {
    return {
      data: {
        subOwnerName: subOwner && <ActionLink to={`/sub-owners/${subOwner.ownerId}`}>{subOwner.ownerName}</ActionLink>,
        ownerName: subOwner && <ActionLink to={`/owners/${subOwner.parentId}`}>{subOwner.parent.ownerName}</ActionLink>,
        employers: subOwner.employersCount,
        contactName: subOwner.contactName,
        contactEmail: subOwner.contactEmail,
        contactPhone: subOwner.contactPhone,
      },
    };
  }, []);

  useBreadcrumb(TranslationKeys.menu_subOwners, {
    hideTrail: true,
    hideTitle: true,
    otherElements: (
      <SubOwnersToolbar
        handleSearch={handleSearch}
        totalCount={totalCount}
        handleClickAdd={openCreateDialog}
        initialSearchQuery={searchQuery}
      />
    ),
  });

  return (
    <>
      {showCreateDialog && <CreateSubOwnerDialog open={showCreateDialog} closeDialog={onCloseCreateDialog} />}

      <FilterableTable
        columns={headers}
        loading={subOwnersLoading || !subOwnersLoaded}
        rows={subOwners || []}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        rowMapper={rowMapper}
      />
    </>
  );
};
