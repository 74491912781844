import type { CancelTokenSource } from 'axios';
import axios from 'axios';

import { merge } from '../../../../utils';

import type { Connection } from '../../../../types';

import type { TableCellHeaderSortOptions } from '../../../../components';

import { PROCESS_RESULTS } from '../../Pages/ConnectionDetailsPage/BatchesTab/constants';

let getProcessesToken: CancelTokenSource;

export type GetProcessesParams = {
  connectionId: Connection['connectionId'];
  page?: number;
  perPage?: number;
  searchQuery?: string;
  filters?: {
    batchStatus?: string[];
  };
  sortBy?: Record<string, TableCellHeaderSortOptions>;
};

const getProcesses = async ({ connectionId, page, perPage, sortBy, filters, searchQuery }: GetProcessesParams) => {
  try {
    if (typeof getProcessesToken !== typeof undefined) {
      getProcessesToken.cancel('Operation canceled due to new request.');
    }

    getProcessesToken = axios.CancelToken.source();

    return axios.get(`/connections/${connectionId}/batches`, {
      cancelToken: getProcessesToken.token,
      params: merge(
        {
          page,
          perPage,
          sortBy,
          searchQuery,
          'order[triggeredAt]': 'desc',
          batchStatus: !filters?.batchStatus || !filters?.batchStatus.length ? PROCESS_RESULTS : [],
        },
        filters,
      ),
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const getProcessItem = async (processUrl: string) => {
  return axios.get(processUrl.replace('/api', ''));
};

type RetryProcessRequestParams = {
  connectionId: Connection['connectionId'];
  batchId: string;
};

const retryProcessRequest = async ({ connectionId, batchId }: RetryProcessRequestParams) => {
  return axios.post(`/connections/${connectionId}/batches/retry`, {
    connectionId,
    batchIds: [batchId],
  });
};

export type ApproveProcessRequestParams = {
  connectionId: Connection['connectionId'];
  batchId: string;
};

const approveProcessRequest = async ({ connectionId, batchId }: ApproveProcessRequestParams) => {
  return axios.post(`/connections/${connectionId}/module/run`, {
    connectionId,
    moduleType: 'insurance_brydge_approve_batch_module',
    parameters: { batchId },
  });
};

export type DeclineProcessRequestParams = {
  connectionId: Connection['connectionId'];
  batchId: string;
};

const declineProcessRequest = async ({ connectionId, batchId }: DeclineProcessRequestParams) => {
  return axios.post(`/connections/${connectionId}/module/run`, {
    connectionId,
    moduleType: 'insurance_brydge_decline_batch_module',
    parameters: { batchId },
  });
};

export type ForceSendProcessRequestParams = {
  connectionId: Connection['connectionId'];
  batchId: string;
  withFailed: boolean;
};

export const forceSendProcessRequest = async ({ connectionId, batchId, withFailed }: ForceSendProcessRequestParams) => {
  return axios.post(`/connections/${connectionId}/module/run`, {
    connectionId,
    moduleType: 'insurance_brydge_export_batch_module',
    parameters: {
      batchId,
      force: true,
      withFailed,
    },
  });
};

export type ForceDeclineProcessRequestParams = {
  connectionId: Connection['connectionId'];
  batchId: string;
};

const forceDeclineProcessRequest = async ({ connectionId, batchId }: ForceDeclineProcessRequestParams) => {
  return axios.post(`/connections/${connectionId}/module/run`, {
    connectionId,
    moduleType: 'insurance_brydge_decline_batch_module',
    parameters: {
      batchId,
      force: true,
    },
  });
};

type DownloadProcessResultRequestParams = {
  connectionId: Connection['connectionId'];
  mongoId: string;
};

const downloadProcessResultRequest = async ({ connectionId, mongoId }: DownloadProcessResultRequestParams) => {
  return axios.get(`/connections/${connectionId}/batches/${mongoId}/download`);
};

type DownloadProcessResultRequestLogParams = {
  connectionId: Connection['connectionId'];
  mongoId: string;
};

const downloadProcessResultRequestLog = async ({ connectionId, mongoId }: DownloadProcessResultRequestLogParams) => {
  return axios.get(`/connections/${connectionId}/batches/${mongoId}/download/log`);
};

type GetMutationsStatusParams = {
  connectionId: Connection['connectionId'];
  mongoId: string;
};

const getMutationsStatus = async ({ connectionId, mongoId }: GetMutationsStatusParams) => {
  return axios.get(`/connections/${connectionId}/batches/${mongoId}/mutations/status`);
};

export const processesService = {
  getProcesses,
  getProcessItem,
  retryProcessRequest,
  approveProcessRequest,
  declineProcessRequest,
  forceSendProcessRequest,
  forceDeclineProcessRequest,
  downloadProcessResultRequest,
  downloadProcessResultRequestLog,
  getMutationsStatus,
};
