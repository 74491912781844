import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import type { EmployeeEmployment } from 'app/types';
import { ConnectionSourcePackageTypeEnum, ProductPensionSchemeEnum } from 'app/types';

import formatter from 'app/utils/formatter';

import { TranslationKeys } from 'app/translations';

import { InputFieldFactory } from 'app/components/FormikField';

import type { GetEmployeeEmploymentsQueryResultData, GetEmployeeSynchronizationQueryResultData } from 'app/hooks';
import {
  useConnectionGetConnectionQuery,
  useEmployeeGetEmployeeSynchronizationQuery,
  useEmployeeGetEmploymentsQuery,
} from 'app/hooks';

import { EmployeeEmploymentDetails, EmployeeEmploymentSchedules, EmployeeEmploymentWages } from './components';
import { FormattedMessage } from 'react-intl';
import {
  DivHeaderContentStyled,
  GridHeaderStyled,
  PaperBackgroundStyled,
} from '../../../../../../components/DetailsPanel/DetailsPanel.styles';

type SortEmploymentsByStartDate = (
  data: GetEmployeeEmploymentsQueryResultData | undefined,
  sort: 'ASC' | 'DESC',
) => GetEmployeeEmploymentsQueryResultData['data'];

const sortEmploymentsByStartDate: SortEmploymentsByStartDate = (data, sort) => {
  const newData: GetEmployeeEmploymentsQueryResultData = JSON.parse(JSON.stringify(data || {}));

  return (newData?.data || []).sort((a, b) => {
    const dateA = new Date(a.startDate).getTime();
    const dateB = new Date(b.startDate).getTime();

    if (sort === 'ASC') {
      return dateA - dateB;
    }

    return dateB - dateA;
  });
};

export const EmployeeEmploymentTab = () => {
  const { employerId, employeeId } = useParams();

  const [selectedEmployment, setSelectedEmployment] = React.useState<EmployeeEmployment | null>(null);
  const [connectionId, setConnectionId] = React.useState<string | undefined>(undefined);
  const [isConnectionWithSynchronization, setIsConnectionWithSynchronization] = React.useState(false);
  const [isRegularConnection, setIsRegularConnection] = React.useState(false);
  const [employeeSynchronization, setEmployeeSynchronization] =
    React.useState<GetEmployeeSynchronizationQueryResultData | null>(null);

  const {
    data,
    isLoading: isLoadingEmployments,
    isFetching: isFetchingGetEmploymentsQuery,
    refetch: refetchGetEmploymentsQuery,
  } = useEmployeeGetEmploymentsQuery({
    variables: { employerId, employeeId },
    options: {
      enabled: !!employerId && !!employeeId,
      onSuccess: data => {
        setSelectedEmployment(selectedEmploymentState => {
          if (selectedEmploymentState === null) {
            const sortedArray = sortEmploymentsByStartDate(data, 'DESC');
            return sortedArray?.[0] || null;
          }
          const employment = (data?.data || []).find(
            (employment: EmployeeEmployment) => employment.employmentId === selectedEmploymentState.employmentId,
          );
          return employment || null;
        });
        setConnectionId(connectionIdState => {
          if (connectionIdState === undefined) {
            return data?.data?.[0]?.connectionId;
          }
          return connectionIdState;
        });
      },
    },
  });

  const { isLoading: isLoadingConnection } = useConnectionGetConnectionQuery({
    variables: { connectionId },
    options: {
      enabled: !!connectionId,
      onSuccess: data => {
        const isManualInputOrFileUpload = data.packages.some(connectionPackage =>
          [
            ConnectionSourcePackageTypeEnum.Manual.toString(),
            ConnectionSourcePackageTypeEnum.FileUpload.toString(),
          ].includes(connectionPackage.packageType),
        );
        setIsConnectionWithSynchronization(!isManualInputOrFileUpload);
        setIsRegularConnection(data?.product?.pensionScheme === ProductPensionSchemeEnum.Regular);
      },
    },
  });

  const enableSynchronizationQuery = isConnectionWithSynchronization && !!employeeId && !!connectionId;
  const {
    isLoading: isLoadingEmployeeSynchronizationQuery,
    isFetching: isFetchingEmployeeSynchronizationQuery,
    refetch: refetchGetEmployeeSynchronizationQuery,
  } = useEmployeeGetEmployeeSynchronizationQuery({
    variables: { employeeId, connectionId },
    options: {
      enabled: enableSynchronizationQuery,
      onSuccess: data => setEmployeeSynchronization(data),
      onError: () => setEmployeeSynchronization(null),
    },
  });

  const isLoading =
    isLoadingEmployments ||
    isLoadingConnection ||
    (enableSynchronizationQuery && isLoadingEmployeeSynchronizationQuery);

  const disabledActionButtonsWhileLoading =
    isLoading || isFetchingGetEmploymentsQuery || isFetchingEmployeeSynchronizationQuery;

  const employmentId = selectedEmployment?.employmentId;
  const wages = selectedEmployment?.wages;
  const schedules = selectedEmployment?.schedules;
  const employments = React.useMemo(() => sortEmploymentsByStartDate(data, 'ASC'), [data]);

  const employmentsSelectItems = React.useMemo(() => {
    return (employments || []).map(employment => ({
      element: formatter.date(employment.startDate),
      value: employment.employmentId,
      default: employment.employmentId === selectedEmployment?.employmentId,
    }));
  }, [employments, selectedEmployment?.employmentId]);

  const onSelectEmploymentChange = React.useCallback(
    ({ value }) => {
      const selected = (employments || []).find(employment => employment.employmentId === value);
      setSelectedEmployment(selected || null);
    },
    [employments],
  );

  const NoEmploymentDetailsBody: React.FC<{ title: string; message: string }> = ({ title, message }) => (
    <PaperBackgroundStyled withScrollBody={false}>
      <Grid container spacing={2} direction="column" justifyContent="space-between" alignItems="stretch">
        <GridHeaderStyled item container xs="auto">
          <Grid item xs>
            <DivHeaderContentStyled withIcon={false}>
              <Typography variant="overline">
                <FormattedMessage id={title} />
              </Typography>
            </DivHeaderContentStyled>
          </Grid>
        </GridHeaderStyled>
        <Typography variant="body2">
          <Box sx={{ minHeight: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="body2">
              <FormattedMessage id={message} />
            </Typography>
          </Box>
        </Typography>
      </Grid>
    </PaperBackgroundStyled>
  );

  return (
    <Grid container spacing={2}>
      {employments.length !== 0 && (
        <Grid item container spacing={2}>
          <Grid item xs={4} md={2}>
            <InputFieldFactory
              onChange={onSelectEmploymentChange}
              field={{
                type: 'select',
                name: 'employment',
                header: TranslationKeys.employees_detail_employment,
                loading: isLoading,
                disabled: disabledActionButtonsWhileLoading,
                items: employmentsSelectItems,
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        {employments.length === 0 && !isLoadingEmployments ? (
          <NoEmploymentDetailsBody
            title={TranslationKeys.employees_detail_employment}
            message={TranslationKeys.employees_detail_employmentEmpty}
          />
        ) : (
          <EmployeeEmploymentDetails
            connectionId={connectionId}
            employeeId={employeeId}
            employment={selectedEmployment}
            loading={isLoading}
            isRegularConnection={isRegularConnection}
            refetchGetEmploymentsQuery={refetchGetEmploymentsQuery}
            synchronizationStatus={employeeSynchronization?.status?.employment}
            refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
            disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
          />
        )}
      </Grid>
      <Grid item container xs={12} md={4}>
        <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} width={'100%'}>
          <Box display={'flex'} paddingBottom={1}>
            {employments.length === 0 && !isLoadingEmployments ? (
              <NoEmploymentDetailsBody
                title={TranslationKeys.employees_detail_wages}
                message={TranslationKeys.employees_detail_wages_noWages}
              />
            ) : (
              <EmployeeEmploymentWages
                employerId={employerId}
                connectionId={connectionId}
                employeeId={employeeId}
                employeeStartDate={selectedEmployment?.startDate}
                employmentId={employmentId}
                wages={wages}
                loading={isLoading}
                isRegularConnection={isRegularConnection}
                refetchGetEmploymentsQuery={refetchGetEmploymentsQuery}
                synchronizationStatus={employeeSynchronization?.status?.wage}
                refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
                disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
              />
            )}
          </Box>
          <Box display={'flex'} paddingTop={1}>
            {employments.length === 0 && !isLoadingEmployments ? (
              <NoEmploymentDetailsBody
                title={TranslationKeys.employees_detail_schedules}
                message={TranslationKeys.employees_detail_schedules_noSchedules}
              />
            ) : (
              <EmployeeEmploymentSchedules
                connectionId={connectionId}
                employeeId={employeeId}
                employmentId={employmentId}
                schedules={schedules}
                loading={isLoading}
                isRegularConnection={isRegularConnection}
                refetchGetEmploymentsQuery={refetchGetEmploymentsQuery}
                synchronizationStatus={employeeSynchronization?.status?.schedule}
                refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
                disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
