import { styled } from '@mui/material/styles';
import { Card, CardActionArea } from '@mui/material';

export const DivContainerStyled = styled('div', {
  name: 'DivContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: theme.spacing(3),
  padding: theme.spacing(3, 0),
}));

export const CardStyled = styled(Card, {
  name: 'CardStyled',
})(() => ({
  width: '20em',
  height: '10em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    boxShadow: '0 0 11px rgba(33,33,33,.2)',
  },
}));

export const CardActionAreaStyled = styled(CardActionArea, {
  name: 'CardActionAreaStyled',
})(() => ({
  width: '100%',
  height: '100%',
  textAlign: 'center',
}));
