import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { Insurer, UseQueryWrapper } from 'app/types';

import { useSnakeBar } from 'app/hooks/useSnakeBar';

import insurerService from 'app/Domain/Insurers/Services/InsurerService/insurerService';

type GetInsurerQueryVariables = {
  insurerId: Insurer['insurerId'] | undefined;
};

type GetInsurerQueryResultData = Insurer;

const queryFn =
  ({ insurerId }: GetInsurerQueryVariables) =>
  async () => {
    if (insurerId) {
      const response = await insurerService.getInsurer(insurerId);
      return response.data;
    }
  };

const QUERY_KEY = 'getInsurer';

export const useInsurerGetInsurerQuery: UseQueryWrapper<GetInsurerQueryResultData, GetInsurerQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetInsurerQueryResultData>([QUERY_KEY, variables.insurerId], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
