import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const TypographySubTitleStyled = styled(Typography, {
  name: 'TypographySubTitleStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const GridStyled = styled(Grid, {
  name: 'GridStyled',
})(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
