import { alpha } from '@mui/material/styles';
import type { FuncOverrideComponent } from '../theme.types';
import ClearIcon from '@mui/icons-material/Clear';
import type { ChipProps } from '@mui/material';

export const MuiChip: FuncOverrideComponent<'MuiChip'> = theme => ({
  defaultProps: {
    deleteIcon: ClearIcon as unknown as ChipProps['deleteIcon'],
  },
  styleOverrides: {
    root: {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.secondary.main,
      height: '30px',
      lineHeight: '18px',
      fontWeight: 600,
      fontSize: '0.8125rem',
      textAlign: 'left',
    },
    label: {
      textTransform: 'uppercase',
      marginTop: '1px',
    },
    outlined: {
      border: `1px solid ${theme.palette.secondary.dark}`,
    },
    deleteIcon: {
      color: alpha(theme.palette.secondary.dark, 0.6),
      '&:hover': {
        color: theme.palette.secondary.dark,
      },
    },
    colorPrimary: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    deleteIconColorPrimary: {
      color: alpha(theme.palette.primary.main, 0.5),
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    colorSecondary: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    deleteIconColorSecondary: {
      color: alpha(theme.palette.common.white, 0.7),
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    outlinedSecondary: {
      color: theme.palette.success.main,
      border: `1px solid ${theme.palette.success.main}`,
    },
    deleteIconOutlinedColorSecondary: {
      color: alpha(theme.palette.success.main, 0.7),
      '&:hover': {
        color: theme.palette.success.main,
      },
    },
    // Negative margins as way to have a chip that goes into the padding box
    sizeSmall: {
      marginTop: '-4.5px',
      marginBottom: '-4.5px',
      height: '22px',
    },
  },
});
