import React from 'react';

import { isNil } from 'app/utils';
import type { Field } from '../../../types';

import type { InputFactoryOnChangeFunc } from './InputFieldFactory';
import InputFieldFactory from './InputFieldFactory';

export { InputFieldFactory };
export * from './InputFieldFactory';

export { default as RenderFieldFactory } from './RenderFieldFactory';

export * from './ValidationFactory';

export const buildInputFields = (fields: Array<Field>, onChange: InputFactoryOnChangeFunc, keyPrefix = 1) => {
  let fieldsToBuild = fields;
  if (!Array.isArray(fields) && !isNil(fields)) {
    fieldsToBuild = [fields];
  }
  return (fieldsToBuild || []).map((field: Field, index: number) => {
    const compoundKey = `${keyPrefix}-${index}`;

    return React.createElement(InputFieldFactory, {
      key: compoundKey,
      field: field,
      onChange: onChange,
    });
  });
};
