import { styled } from '@mui/material/styles';

export const DivEditButtonsContainer = styled('div', {
  name: 'DivEditButtonsContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));
