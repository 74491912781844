import type { Theme } from '@mui/material/styles';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiMenuItem: FuncOverrideComponent<'MuiMenuItem'> = (theme: Theme) => ({
  styleOverrides: {
    root: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
});
