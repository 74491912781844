import React from 'react';
import { useIntl } from 'react-intl';
import { DropzoneArea } from 'react-mui-dropzone';

import { convertBytesToMbsOrKbs, isEmpty } from '../../utils';

import { MESSAGE_SEVERITY_INFO, MESSAGE_SEVERITY_SUCCESS } from '../../common';

import { TranslationKeys } from '../../translations';

import { useSnakeBar } from '../../hooks';

import { FileUploadIcon } from './FileUploadIcons/FileUploadIcon';
import { FileUploadDisabledIcon } from './FileUploadIcons/FileUploadDisabledIcon';
import { FileUploadOnlyExcelIcon } from './FileUploadIcons/FileUploadOnlyExcelIcon';
import { FileUploadOnlyXmlIcon } from './FileUploadIcons/FileUploadOnlyXmlIcon';

import { FilesTypesToMime, FileType } from './FileUploadArea.types';
import { FileUploadDropzoneAreaContainer } from './FileUploadArea.styles';

type FileUploadAreaProps = {
  onChange: (files: Array<File>) => void;
  types: Array<FileType>;
  initialFiles?: Array<File | string>;
  disabled?: boolean;
  filesLimit?: number;
  maxSize?: number;
  showSuccessMessage?: boolean;
  isSubmitting?: boolean;
};

const MAX_FILE_SIZE_10_MB = 10485760;

export const FileUploadArea = ({
  onChange,
  types = [FileType.Excel],
  maxSize = MAX_FILE_SIZE_10_MB,
  initialFiles = [],
  filesLimit = 1,
  disabled = false,
  showSuccessMessage = true,
  isSubmitting = false,
}: FileUploadAreaProps) => {
  const intl = useIntl();
  const { showSnakeBar } = useSnakeBar();

  const [initialLoading, setInitialLoading] = React.useState(true);

  const actualDisabled = isSubmitting || disabled;

  const acceptedTypes = React.useMemo<Array<string>>(() => {
    return types.reduce<Array<string>>((result, type) => result.concat(FilesTypesToMime[type] || []), []);
  }, [types]);

  const alert = React.useCallback(
    (message, variant) => {
      const severity = variant === MESSAGE_SEVERITY_INFO ? MESSAGE_SEVERITY_SUCCESS : variant;
      const showSnackbar = !(severity === MESSAGE_SEVERITY_SUCCESS && !showSuccessMessage);

      if (showSnackbar) {
        showSnakeBar({ message, severity });
      }
    },
    [showSnakeBar, showSuccessMessage],
  );

  const internalOnChange = React.useCallback(
    (files: Array<File>) => {
      // fire initial on change only if we provide initial Files, otherwise don't trigger a change
      const isOnChangeMisfire = initialLoading && isEmpty(initialFiles) && isEmpty(files);
      if (isOnChangeMisfire) {
        setInitialLoading(false);
        return;
      }
      onChange && onChange(files);
    },
    [initialFiles, initialLoading, onChange],
  );

  const getFileLimitExceedMessage = React.useCallback(
    filesLimit => intl.formatMessage({ id: TranslationKeys.upload_fileLimitExceeded }, { filesLimit: filesLimit }),
    [intl],
  );

  const getFileAddedMessage = React.useCallback(
    fileName => intl.formatMessage({ id: TranslationKeys.upload_fileAdded }, { fileName: fileName }),
    [intl],
  );

  const getFileRemovedMessage = React.useCallback(
    fileName => intl.formatMessage({ id: TranslationKeys.upload_fileRemoved }, { fileName: fileName }),
    [intl],
  );

  const getDropRejectMessage = React.useCallback(
    (rejectedFile, acceptedFiles, maxFileSize) => {
      if (!acceptedFiles.includes(rejectedFile.type)) {
        return intl.formatMessage({ id: TranslationKeys.upload_fileWrongType }, { fileName: rejectedFile.name });
      }
      if (rejectedFile.size > maxFileSize) {
        return intl.formatMessage(
          { id: TranslationKeys.upload_fileTooBig },
          { fileName: rejectedFile.name, limit: convertBytesToMbsOrKbs(maxFileSize) },
        );
      }
      return intl.formatMessage({ id: TranslationKeys.upload_fileRejected }, { fileName: rejectedFile.name });
    },
    [intl],
  );

  const Icon = React.useMemo(() => {
    if (actualDisabled) {
      return FileUploadDisabledIcon;
    }
    if (types.length === 1 && types[0] === FileType.Excel) {
      return FileUploadOnlyExcelIcon;
    }
    if (types.length === 1 && types[0] === FileType.Xml) {
      return FileUploadOnlyXmlIcon;
    }

    return FileUploadIcon;
  }, [actualDisabled, types]);

  const dropzoneProps = React.useMemo(() => {
    return { disabled: actualDisabled };
  }, [actualDisabled]);

  const previewChipProps = React.useMemo(() => {
    return {
      disabled: actualDisabled,
      deleteIcon: actualDisabled ? <></> : undefined,
    };
  }, [actualDisabled]);

  return (
    <FileUploadDropzoneAreaContainer>
      <DropzoneArea
        dropzoneProps={dropzoneProps}
        previewChipProps={previewChipProps}
        useChipsForPreview
        initialFiles={initialFiles}
        filesLimit={filesLimit}
        maxFileSize={maxSize}
        acceptedFiles={acceptedTypes}
        Icon={Icon as unknown as React.ComponentType}
        onChange={internalOnChange}
        showAlerts={false}
        onAlert={alert}
        getFileLimitExceedMessage={getFileLimitExceedMessage}
        getFileAddedMessage={getFileAddedMessage}
        getFileRemovedMessage={getFileRemovedMessage}
        getDropRejectMessage={getDropRejectMessage}
      />
    </FileUploadDropzoneAreaContainer>
  );
};
