import { useMutation } from '@tanstack/react-query';

import type { DeleteEmployeeSicknessLeaveArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

const mutationFn = (data: DeleteEmployeeSicknessLeaveArgs) => {
  return employeeService.deleteEmployeeSicknessLeave(data);
};

export const useEmployeeEmploymentDeleteSicknessLeaveMutation = () => {
  return useMutation<unknown, unknown, DeleteEmployeeSicknessLeaveArgs>(mutationFn);
};
