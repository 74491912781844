import React, { useCallback, useEffect, useState } from 'react';
import { Box, Collapse, Fade } from '@mui/material';
import IntlMessage from 'app/components/Intl/IntlMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'app/utils';
import { FormLabelStyled, IconButtonStyled, LinearProgressStyled } from './CollapsableSection.styles';

const CollapsableSection = ({
  header,
  children,
  hidden = false,
  onExpand = null,
  expanded = false,
  loading = false,
  controllable = false,
  disabled = false,
}) => {
  const isExpandable = !isNil(expanded);
  const initialState = isExpandable ? expanded : true;
  const [visible, setVisible] = useState(initialState);

  useEffect(() => {
    setVisible(initialState);
  }, [initialState]);

  const onClick = useCallback(
    e => {
      e.stopPropagation();
      const newState = !visible;
      setVisible(newState);
      onExpand && onExpand({ expanded: visible });
    },
    [onExpand, visible],
  );

  return (
    <Fade in={!hidden}>
      <Box>
        {header && (
          <>
            <FormLabelStyled disabled={disabled}>
              <Box>
                <IntlMessage value={header} />
              </Box>
              {!controllable && isExpandable && (
                <Box>
                  <IconButtonStyled size={'large'} color="primary" onClick={onClick} disabled={loading || disabled}>
                    {visible ? <FontAwesomeIcon icon={faMinusSquare} /> : <FontAwesomeIcon icon={faPlusSquare} />}
                  </IconButtonStyled>
                </Box>
              )}
            </FormLabelStyled>
            <LinearProgressStyled variant={loading ? 'indeterminate' : 'determinate'} value={100} />
          </>
        )}
        <Collapse in={visible}>{children}</Collapse>
      </Box>
    </Fade>
  );
};

export default CollapsableSection;
