import { useMutation } from '@tanstack/react-query';

import type { DeleteRateTableArgs } from 'app/Domain/Settings/Services';
import { settingsServices } from 'app/Domain/Settings/Services';

type DeleteRateTableData = DeleteRateTableArgs;

const mutationFn = (data: DeleteRateTableData) => {
  return settingsServices.deleteRateTable(data);
};

export const useSettingsDeleteRateTableMutation = () => {
  return useMutation<unknown, unknown, DeleteRateTableData>(mutationFn);
};
