import { get } from '../Lodash';

type FormatStreetNameArg = {
  street?: string;
  houseNumber?: string;
  houseNumberSuffix?: string;
};

export const formatStreetName = (address: FormatStreetNameArg) => {
  return [get(address, 'street'), get(address, 'houseNumber'), get(address, 'houseNumberSuffix')]
    .filter(Boolean)
    .join(' ');
};
