import { styled } from '@mui/material/styles';
import { Avatar, Button } from '@mui/material';

export const DivDetailsStyled = styled('div', {
  name: 'DivDetailsStyled',
})(() => ({
  display: 'flex',
}));

export const AvatarStyled = styled(Avatar, {
  name: 'AvatarStyled',
})(() => ({
  marginLeft: 'auto',
  height: 110,
  width: 100,
  flexShrink: 0,
  flexGrow: 0,
}));

export const DivProgressStyled = styled('div', {
  name: 'DivProgressStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const ButtonUploadStyled = styled(Button, {
  name: 'ButtonUploadStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
