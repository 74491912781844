import type { SummaryScaleTableFields } from 'app/types';

export const SCALES_TABLE_TEMPLATE_FILE_NAME = 'Summary_Scales_Table_Template';

export const SCALES_TABLE_FILE_STRUCTURE: Array<{
  headerTitle: string;
  fieldName: SummaryScaleTableFields;
}> = [
  {
    headerTitle: 'AGE 15-20',
    fieldName: '15-20',
  },
  {
    headerTitle: 'AGE 20-25',
    fieldName: '20-25',
  },
  {
    headerTitle: 'AGE 25-30',
    fieldName: '25-30',
  },
  {
    headerTitle: 'AGE 30-35',
    fieldName: '30-35',
  },
  {
    headerTitle: 'AGE 35-40',
    fieldName: '35-40',
  },
  {
    headerTitle: 'AGE 40-45',
    fieldName: '40-45',
  },
  {
    headerTitle: 'AGE 45-50',
    fieldName: '45-50',
  },
  {
    headerTitle: 'AGE 50-55',
    fieldName: '50-55',
  },
  {
    headerTitle: 'AGE 55-60',
    fieldName: '55-60',
  },
  {
    headerTitle: 'AGE 60-65',
    fieldName: '60-65',
  },
  {
    headerTitle: 'AGE 65-68',
    fieldName: '65-68',
  },
];

export const SCALES_TABLE_FILE_COMPLETE_STRUCTURE: Array<{
  headerTitle: string;
  fieldName: SummaryScaleTableFields;
}> = [...SCALES_TABLE_FILE_STRUCTURE];

export const SCALES_TABLE_FILE_HEADERS = SCALES_TABLE_FILE_STRUCTURE.map(fileStructure => fileStructure.headerTitle);

export const SCALES_TABLE_DOWNLOAD_FILE_STRUCTURE = SCALES_TABLE_FILE_COMPLETE_STRUCTURE;

export const SCALES_TABLE_DOWNLOAD_FILE_HEADERS = SCALES_TABLE_FILE_COMPLETE_STRUCTURE.map(
  fileStructure => fileStructure.headerTitle,
);
