import React from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';

import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { HasAccessTo, SearchInput } from 'app/components';

import { ActionButtonStyled, DivRootStyled } from './SubOwnersToolbar.styles';
import { SUBOWNER } from '../../../../common/Authorization/entities';
import { EDIT } from '../../../../common/Authorization/permissions';

type SubOwnersToolbarProps = {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickAdd: () => void;
  totalCount?: number;
  initialSearchQuery?: string;
};

export const SubOwnersToolbar = ({
  handleSearch,
  handleClickAdd,
  totalCount,
  initialSearchQuery,
}: SubOwnersToolbarProps) => {
  const intl = useIntl();
  const disabledIfNoAccessToSubOwnersEdit = !HasAccessTo(SUBOWNER, EDIT);

  return (
    <DivRootStyled>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="h5">
            <FormattedMessage id="menu.subOwners" />
          </Typography>
          <Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }}>
            {typeof totalCount !== 'undefined' ? (
              <FormattedMessage id="subOwners.totalCount" values={{ totalCount: totalCount }} />
            ) : (
              <Skeleton width={200} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SearchInput
            onChange={handleSearch}
            // @ts-ignore
            placeholder={intl.formatMessage({ id: 'subOwners.search' }).toUpperCase()}
            initialSearchQuery={initialSearchQuery}
          />
        </Grid>
        <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
          <ActionButtonStyled
            messageId={'subOwners.add'}
            color="primary"
            icon={faPlus}
            onClick={handleClickAdd}
            disabled={disabledIfNoAccessToSubOwnersEdit}
          />
        </Grid>
      </Grid>
    </DivRootStyled>
  );
};
