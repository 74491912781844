import { debounce, put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import ownerService from '../Services/OwnerService';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload } from '../../../utils';

export const actionTypes = {
  InitializeOwnersState: '[Owners] Initialize state',
  RequestData: '[Owners] Request',
  RequestDataEnvironment: '[Owners] Request Environment',
  FulfilledTable: '[Owners] Fulfilled',
  SearchTable: '[Owners] Search',
  ChangePage: '[Owners] change page',
  SetPageSize: '[Owners] set page size',
  SortTable: '[Owners] Sort',
  ApplyFilter: '[Owners] Apply filter',
  RemoveFilter: '[Owners] Remove filter',
  SetSelectedOwner: '[Owners] Set selected owner',
  ClearAll: '[Owners] Clear all',
  CreateOwner: '[Owners] Create new owner',
  EditOwner: '[Owners] Edit new owner',
  DeleteOwner: '[Owners] Delete owner',
  RequestUnfilteredTotalCount: '[Owners] Request Total count',
  SetUnfilteredTotalCount: '[Owners] Set Total Count',
};

const initialState = {
  ...tableInitialState,
  selectedOwner: null,
  pageSize: 99,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.InitializeOwnersState: {
      return { ...initialState };
    }
    case actionTypes.SetSelectedOwner: {
      return {
        ...state,
        selectedOwner: action.selectedOwner,
      };
    }
    case actionTypes.CreateOwner: {
      return {
        ...state,
      };
    }
    case actionTypes.EditOwner: {
      return {
        ...state,
      };
    }
    case actionTypes.ClearAll: {
      return {
        ...state,
        selectedOwner: null,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initializeOwnersState: () => ({
    type: actionTypes.InitializeOwnersState,
  }),
  setSelectedOwner: selectedOwner => ({
    type: actionTypes.SetSelectedOwner,
    selectedOwner,
  }),
  clearAll: () => ({
    type: actionTypes.ClearAll,
  }),
  createOwner: ownerValues => ({
    type: actionTypes.CreateOwner,
    ownerValues,
  }),
  editOwner: ownerValues => ({
    type: actionTypes.EditOwner,
    ownerValues,
  }),
  deleteOwner: ownerId => ({
    type: actionTypes.DeleteOwner,
    ownerId,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.CreateOwner, function* createOwnerSaga(action) {
    yield ownerService.createOwner(action.ownerValues);
    yield reloadData();
  });

  yield takeLatest(actionTypes.EditOwner, function* editOwnerSaga(action) {
    yield ownerService.editOwner(action.ownerValues);
  });

  yield takeLatest(actionTypes.RequestData, function* requestOwners() {
    try {
      const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(
        yield select(state => {
          return state.OwnersReducer;
        }),
      );

      const userId = yield select(state => {
        return state.AppReducer.user.userId;
      });

      const response = yield ownerService.getUserOwners(userId, pagination);
      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getOwners',
        severity,
      };

      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RequestDataEnvironment, function* requestUserEnvironment() {
    try {
      const pagination = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(
        yield select(state => {
          return state.OwnersReducer;
        }),
      );

      const userId = yield select(state => {
        return state.AppReducer.user.userId;
      });

      const response = yield ownerService.getUserEnvironment(userId, pagination);
      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getOwners',
        severity,
      };

      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RequestData, function* requestUnfilteredTotalCount() {
    try {
      const userId = yield select(state => {
        return state.AppReducer.user.userId;
      });

      const response = yield ownerService.getOwnersTotalCount(userId);
      yield put(actions.setUnfilteredTotalCount(response.data));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getOwnersTotalCount',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });
  yield takeLatest(actionTypes.RequestDataEnvironment, function* getEnvironmentTotalCount() {
    try {
      const userId = yield select(state => {
        return state.AppReducer.user.userId;
      });

      const response = yield ownerService.getEnvironmentTotalCount(userId);
      yield put(actions.setEnvironmentTotalCount(response.data));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getOwnersTotalCount',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.DeleteOwner, function* deleteOwnerSaga(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;

    try {
      const response = yield ownerService.deleteOwner(action.ownerId);

      if (response.status === 204) {
        yield put(actions.requestData());
      }
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    const details = {
      method: 'deleteOwners',
      severity,
    };
    yield put(AppActions.displayMessage(details));
  });
}
