import { alpha, styled } from '@mui/material/styles';

const WIDTH = '250px';
const HEIGHT = '125px';

export const ImageUploadDropzoneAreaContainer = styled('div', {
  name: 'DropzoneAreaContainer',
  shouldForwardProp: (propName: string) => !['disabled', 'hasFile'].includes(propName),
})<{ disabled: boolean; hasFile: boolean }>(({ theme, disabled, hasFile }) => ({
  width: WIDTH,
  '& .MuiDropzoneArea-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: WIDTH,
    height: HEIGHT,
    minHeight: 'unset',
  },
  '& .MuiDropzoneArea-textContainer': {
    ...(hasFile ? { display: 'none' } : {}),
  },
  '& .MuiDropzoneArea-text': {
    marginBottom: theme.spacing(1),
    fontSize: '0.75rem',
    color: disabled ? theme.palette.secondary.dark : theme.palette.text.primary,
  },
  '& .MuiDropzoneArea-icon': {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: disabled ? theme.palette.secondary.dark : theme.palette.text.primary,
  },
  '& .MuiDropzonePreviewList-root': {
    width: WIDTH,
    height: HEIGHT,
    margin: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .MuiDropzonePreviewList-imageContainer': {
    maxWidth: 'unset',
    width: WIDTH,
    height: HEIGHT,
    padding: 0,
  },
  '& .MuiDropzonePreviewList-image': {
    width: WIDTH,
    height: HEIGHT,
    objectFit: 'contain',
  },
  '& .MuiDropzonePreviewList-removeButton': {
    display: disabled ? 'none' : 'inline-flex',
    width: theme.spacing(5),
    height: theme.spacing(5),
    top: '-56px',
    right: '-96px',
    backgroundColor: alpha(theme.palette.primary.light, 0.7),
    '&:hover, :focus, :active': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '50%',
    },
  },
  '& .MuiDropzoneSnackbar-icon, .MuiDropzoneSnackbar-closeButton': {
    color: 'unset',
  },
}));
