import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import type { PopperPlacementType } from '@mui/material';

type ButtonDropdownProps = {
  placement?: PopperPlacementType;
  options: Array<{
    title: string;
    disabled?: boolean;
    onClick: () => void;
  }>;
};

export const ButtonDropdown = ({ options, placement = 'bottom' }: ButtonDropdownProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (idx: number, onClick: () => void) => {
    setSelectedIndex(idx);
    setOpen(false);
    onClick();
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const normalizedOptions = React.useMemo(() => {
    return options.map(option => {
      const newOption = { ...option };
      newOption.disabled = option?.disabled || false;

      return newOption;
    });
  }, [options]);

  const allOptionsDisabled = React.useMemo(() => {
    return !normalizedOptions.some(option => option.disabled === false);
  }, [normalizedOptions]);

  React.useEffect(() => {
    const selected = normalizedOptions[selectedIndex];

    if (selected.disabled) {
      const firstNotDisabledIdx = normalizedOptions.findIndex(option => !option.disabled);

      if (firstNotDisabledIdx !== -1) {
        setSelectedIndex(firstNotDisabledIdx);
      }
    }
  }, [normalizedOptions, selectedIndex]);

  return (
    <React.Fragment>
      <ButtonGroup ref={anchorRef} aria-label="button dropdown">
        <Button
          sx={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            boxShadow: 'unset',
          }}
          variant={'contained'}
          onClick={normalizedOptions[selectedIndex].onClick}
          disabled={normalizedOptions[selectedIndex].disabled}
        >
          {normalizedOptions[selectedIndex].title}
        </Button>

        <Button
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          size="small"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          variant={allOptionsDisabled ? 'contained' : undefined}
          disabled={allOptionsDisabled}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon sx={theme => ({ color: allOptionsDisabled ? theme.palette.text.disabled : undefined })} />
        </Button>
      </ButtonGroup>

      <Popper sx={{ zIndex: 99 }} open={open} anchorEl={anchorRef.current} placement={placement} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={1} id="button-dropdown-menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {normalizedOptions.map((option, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        selected={idx === selectedIndex}
                        disabled={option.disabled}
                        onClick={() => handleMenuItemClick(idx, option.onClick)}
                      >
                        {option.title}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
