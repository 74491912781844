import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { TranslationKeys } from '../../../translations';

import ActionButton from '../../ActionButton';
import { Tooltip } from '../../Tooltip';

import { DivTooltipContentStyled } from './DetailsPanelRowValueWithReset.styles';

type DetailsPanelRowValueWithResetProps = {
  value: React.ReactElement;
  onReset: () => void;
};

export const DetailsPanelRowValueWithReset = ({ value, onReset }: DetailsPanelRowValueWithResetProps) => {
  const tooltipText = React.useMemo(() => {
    return (
      <DivTooltipContentStyled>
        <Typography variant="body1">
          <FormattedMessage id={TranslationKeys.resettableValue_tooltipText} />
        </Typography>
        <ActionButton variant={'text'} size={'small'} onClick={onReset} messageId={TranslationKeys.global_revert} />
      </DivTooltipContentStyled>
    );
  }, [onReset]);

  return (
    <Tooltip title={tooltipText} placement={'right-end'}>
      {value}
    </Tooltip>
  );
};
