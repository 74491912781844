import { useMutation } from '@tanstack/react-query';
import type { Connection } from 'app/types';
import mutationsService from '../../../Domain/Connections/Services/MutationsService';

type DeclineMutationsData = {
  connectionId: Connection['connectionId'];
  batchId: string;
  externalIds: string[];
};

const mutationFn = ({ connectionId, batchId, externalIds }: DeclineMutationsData) => {
  return mutationsService.declineMutationFromDraftBatch({ connectionId, batchId, externalIds });
};

export const useConnectionDeclineMutationsMutation = () => {
  return useMutation<unknown, unknown, DeclineMutationsData>(mutationFn);
};
