import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';

import Skeleton from '@mui/material/Skeleton';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import { formatDateTime } from '../../../../utils/formatter/dateTimeFormatter';

const ActivityLogsTable = props => {
  const {
    className,
    tasks,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    disableCard,
    paging,
  } = props;

  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="connections.activityLogs.user" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.activityLogs.action" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.activityLogs.date" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(rowsPerPage)
              .fill(index => index)
              .map((value, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
          {!loading && tasks.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <FormattedMessage id="connections.activityLogs.noLogsFound" />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            tasks.length > 0 &&
            tasks.map(log => (
              <TableRow key={log.mongoId}>
                <TableCell>{log.userEmail}</TableCell>
                <TableCell>
                  <FormattedMessage id={`connections.activityLogs.actions.${log.action}`} />
                </TableCell>
                <TableCell>{formatDateTime(log.createdAt)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );

  if (disableCard) {
    return table;
  }

  return (
    <Card className={className}>
      <CardContent sx={{ padding: 0 }}>
        <PerfectScrollbar>
          <Box sx={{ verticalAlign: 'middle' }}>{table}</Box>
        </PerfectScrollbar>
      </CardContent>
      {paging && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
          />
        </CardActions>
      )}
    </Card>
  );
};

ActivityLogsTable.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.bool,
  tasks: PropTypes.array.isRequired,
};

ActivityLogsTable.defaultProps = {
  paging: true,
  className: '',
};

export default ActivityLogsTable;
