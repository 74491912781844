import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from '../../../../translations';

import { HasAccessTo } from 'app/components/Authorization';
import { ROLE } from '../../../../common/Authorization/entities';
import { EDIT } from '../../../../common/Authorization/permissions';

import { DivRootStyled } from './RolesToolbar.styles';
import { ActionButton } from '../../../../components/ActionButton';

const RolesToolbar = props => {
  const { className, handleClickAdd, totalCount } = props;
  const disabledIfNoAccessToRoleEdit = !HasAccessTo(ROLE, EDIT);

  return (
    <DivRootStyled className={className}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="h5">
            <FormattedMessage id={TranslationKeys.menu_roles} />
          </Typography>
          <Typography variant="subtitle2" textTransform={'uppercase'}>
            <FormattedMessage id={TranslationKeys.roles_totalCount} values={{ totalCount: totalCount }} />
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ActionButton
            messageId={TranslationKeys.roles_add}
            icon={faPlus}
            onClick={handleClickAdd}
            disabled={disabledIfNoAccessToRoleEdit}
          />
        </Grid>
      </Grid>
    </DivRootStyled>
  );
};

RolesToolbar.propTypes = {
  className: PropTypes.string,
  handleClickAdd: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default RolesToolbar;
