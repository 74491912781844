import EN_Phrases from './en.json';

type Keys = keyof typeof EN_Phrases;

type ReplaceAll<
  Key extends string,
  ToReplace extends string,
  Replacement extends string,
> = Key extends `${infer Prefix}${ToReplace}${infer Suffix}`
  ? `${Prefix}${Replacement}${ReplaceAll<Suffix, ToReplace, Replacement>}`
  : Key;

type TranslationKeysProps = {
  [Key in Keys as ReplaceAll<Key, '.', '_'>]: Key;
};

export const TranslationKeys = Object.keys(EN_Phrases).reduce((acc, key) => {
  const keyWithoutDots = key.replaceAll('.', '_');
  return { ...acc, [keyWithoutDots]: key };
}, {} as TranslationKeysProps);

export { EN_Phrases };
