import type { CancelTokenSource } from 'axios';
import axios from 'axios';

import type { Owner, User } from '../../../../types';
import type { ReportEmployer, ReportSubOwner, ReportTypeEnum } from '../../../../types/report.types';

let getUsersToken: CancelTokenSource;

export type GetInvoicingReportsParams = {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  path?: string;
};

const getInvoicingReports = async ({ page, perPage, searchQuery, path }: GetInvoicingReportsParams) => {
  try {
    if (typeof getUsersToken !== typeof undefined) {
      getUsersToken.cancel('Operation canceled due to new request.');
    }

    getUsersToken = axios.CancelToken.source();

    return axios.get('reports', {
      cancelToken: getUsersToken.token,
      params: {
        page,
        perPage,
        user: searchQuery,
        path,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const downloadReport = async (filePath: string) => {
  return axios.post('/reports/download-invoicing-report/', {
    filePath,
  });
};

export type GetReportDataFilterParams = {
  userId: User['userId'];
};

const getReportDataFilter = async ({ userId }: GetReportDataFilterParams) => {
  return axios.get(`/users/${userId}/report/data-filter`);
};

export type GetReportDataFilterForSuperAdminParams = {
  ownerId: Owner['ownerId'];
};

const getReportDataFilterForSuperAdmin = async ({ ownerId }: GetReportDataFilterForSuperAdminParams) => {
  return axios.get(`/owners/${ownerId}/report/data-filter`);
};

export type GenerateReportParams = {
  ownerId: Owner['ownerId'];
  reportIdentifier: ReportTypeEnum;
  employers: Array<Omit<ReportEmployer, 'subEmployers'>>;
  subOwners: Array<Omit<ReportSubOwner, 'employers'>>;
  endDateFilter?: string | null;
};

const generateReport = async ({
  ownerId,
  reportIdentifier,
  employers,
  subOwners,
  endDateFilter,
}: GenerateReportParams) => {
  const data = {
    reportIdentifier,
    dataFilter: {
      employers,
      subOwners,
    },
    ...(endDateFilter && { endDateFilter }),
  };

  return axios.post(`/owners/${ownerId}/report`, data);
};

export const reportService = {
  getInvoicingReports,
  downloadReport,
  getReportDataFilter,
  generateReport,
  getReportDataFilterForSuperAdmin,
};
