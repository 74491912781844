import React from 'react';
import * as Yup from 'yup';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { normalizeFormError } from 'app/utils';

import { TranslationKeys } from '../../translations';

import { appService } from '../../Domain/App/Services/AppServices';

import { PublicLayout } from '../../layouts';

import { Forgot2FaCodeViewForm } from './components';
import type { Forgot2FaCodeViewFormValues } from './Forgot2FaCodeView.types';

export const Forgot2FaCodeView = () => {
  const navigate = useNavigate();

  const formikInitialValues = React.useMemo<Forgot2FaCodeViewFormValues>(() => {
    return {
      email: '',
    };
  }, []);

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      email: Yup.string().email().required(),
    });
  }, []);

  const formikOnSubmit = React.useCallback<FormikConfig<Forgot2FaCodeViewFormValues>['onSubmit']>(
    async (values, form) => {
      try {
        const data = await appService.forgot2FaCodeRequest(values.email);

        if (data.data.code === 401) {
          form.setStatus(data.data.message);
        } else {
          navigate('/sign-in');
        }
      } catch (error: any) {
        const errors = normalizeFormError(error, form);
        if (!errors.violations) {
          throw errors.error;
        }
      }
    },
    [navigate],
  );

  return (
    <PublicLayout titleId={TranslationKeys.signIn_twofa_lost2fa}>
      <Formik<Forgot2FaCodeViewFormValues>
        initialValues={formikInitialValues}
        validationSchema={formikValidationSchema}
        onSubmit={formikOnSubmit}
      >
        <Forgot2FaCodeViewForm />
      </Formik>
    </PublicLayout>
  );
};
