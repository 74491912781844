import { alpha } from '@mui/material/styles';
import { listItemClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiListItem: FuncOverrideComponent<'MuiListItem'> = theme => ({
  styleOverrides: {
    root: {
      [`&.${listItemClasses.selected}`]: {
        backgroundColor: alpha(theme.palette.primary.light, 0.3),
      },
      [`&.${listItemClasses.selected}:hover`]: {
        backgroundColor: alpha(theme.palette.primary.light, 0.6),
      },
    },
    button: {
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.6),
      },
    },
  },
});
