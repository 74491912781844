import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const PaperStyled = styled(Paper, {
  name: 'PaperStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  border: 'none',
  boxShadow: 'none',
  padding: theme.spacing(2),
  display: 'flex',
  height: '100%',
  width: '100%',
}));
