import React from 'react';

import { StoreSelectors, useStoreSelector } from '../../../store';

import type {
  GetOwnerQueryResultData,
  GetOwnerQueryVariables,
  GetOwnersQueryResultData,
  GetOwnersQueryVariables,
} from '../../../hooks';
import { useOwnerGetOwnerQuery, useOwnerGetOwnersQuery } from '../../../hooks';

type QueryVariables = GetOwnersQueryVariables | GetOwnerQueryVariables;

type QueryData = GetOwnersQueryResultData | { data: GetOwnerQueryResultData[]; totalCount: number } | undefined;

export const useOwnersQuery = (variables?: QueryVariables) => {
  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));
  const isSuperAdmin = !ownerId;

  const {
    data: superAdminOwnersData,
    isLoading: isLoadingGetOwnersQuery,
    isFetching: isFetchingGetOwnersQuery,
    refetch: refetchGetOwnersQuery,
  } = useOwnerGetOwnersQuery({
    variables: { page: 1, perPage: 999, ...(variables || {}) },
    options: { enabled: isSuperAdmin },
  });

  const {
    data: ownerData,
    isLoading: isLoadingGetOwnerQuery,
    isFetching: isFetchingGetOwnerQuery,
    refetch: refetchGetOwnerQuery,
  } = useOwnerGetOwnerQuery({
    variables: { ownerId, ...(variables || {}) },
    options: { enabled: !!ownerId },
  });

  const isGetOwnersQueryLoading = isSuperAdmin && (isLoadingGetOwnersQuery || isFetchingGetOwnersQuery);
  const isGetOwnerQueryLoading = !isSuperAdmin && (isLoadingGetOwnerQuery || isFetchingGetOwnerQuery);

  const isLoading = isGetOwnersQueryLoading || isGetOwnerQueryLoading;

  const data = React.useMemo<QueryData>(() => {
    if (isSuperAdmin) {
      return superAdminOwnersData;
    }
    if (ownerData) {
      return {
        data: [ownerData],
        totalCount: 1,
      };
    }

    return undefined;
  }, [isSuperAdmin, ownerData, superAdminOwnersData]);

  const refetch = React.useCallback(() => {
    if (isSuperAdmin) {
      refetchGetOwnersQuery();
    } else {
      refetchGetOwnerQuery();
    }
  }, [isSuperAdmin, refetchGetOwnerQuery, refetchGetOwnersQuery]);

  return React.useMemo(() => ({ data, isLoading, refetch }), [isLoading, refetch, data]);
};
