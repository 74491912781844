import { TranslationKeys } from 'app/translations';

import type { GenderStatusEnum } from 'app/types/gender.types';

import { normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc } from 'app/components/SubmitConfirmationDialog';

export const ChildrenDetailsPanelFields = {
  SocialSecurityNumber: {
    Label: TranslationKeys.employees_detail_socialSecurityNumber,
    InputName: 'socialSecurityNumber',
    Options: {
      style: 'text',
    },
  },
  DateOfBirth: {
    Label: TranslationKeys.employees_detail_dateOfBirth,
    InputName: 'dateOfBirth',
    Options: {
      style: 'date',
    },
  },
  Gender: {
    Label: TranslationKeys.global_gender,
    InputName: 'gender',
    Options: {
      style: 'select',
    },
  },
  PersonName: {
    FieldTypeName: 'personName',
    Props: {
      Initials: {
        Label: TranslationKeys.global_initials,
        FieldTypeName: 'initials',
        InputName: 'personName.initials',
        Options: {
          style: 'text',
        },
      },
      FirstName: {
        Label: TranslationKeys.global_firstName,
        FieldTypeName: 'firstName',
        InputName: 'personName.firstName',
        Options: {
          style: 'text',
        },
      },
      LastName: {
        Label: TranslationKeys.global_lastName,
        FieldTypeName: 'lastName',
        InputName: 'personName.lastName',
        Options: {
          style: 'text',
        },
      },
      LastNamePrefix: {
        Label: TranslationKeys.global_lastNamePrefix,
        FieldTypeName: 'lastNamePrefix',
        InputName: 'personName.lastNamePrefix',
        Options: {
          style: 'text',
        },
      },
    },
  },
} as const;

export const ChildrenDetailsPanelFieldOptionsAndLabel =
  normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc(ChildrenDetailsPanelFields);

export type EmployeeChildFormValues = {
  [ChildrenDetailsPanelFields.SocialSecurityNumber.InputName]: string;
  [ChildrenDetailsPanelFields.DateOfBirth.InputName]: string | null;
  [ChildrenDetailsPanelFields.Gender.InputName]: GenderStatusEnum;
  [ChildrenDetailsPanelFields.PersonName.FieldTypeName]: {
    [ChildrenDetailsPanelFields.PersonName.Props.Initials.FieldTypeName]: string;
    [ChildrenDetailsPanelFields.PersonName.Props.FirstName.FieldTypeName]: string;
    [ChildrenDetailsPanelFields.PersonName.Props.LastName.FieldTypeName]: string;
    [ChildrenDetailsPanelFields.PersonName.Props.LastNamePrefix.FieldTypeName]: string;
  };
};
