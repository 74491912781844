import React from 'react';

import { Typography } from '@mui/material';

import type { ReportEmployer, ReportEmployerConnection } from '../../../../../types';

import { ActionLink, ListCollapsibleRow, ListRow } from '../../../../../components';

import type { OnChangeSelected, ReportData } from '../ReportManagementPage.types';
import { ReportDataContainer } from '../ReportDataContainer';
import { ReportNoData } from '../ReportNoData';

type ReportDataTableWithConnectionsProps = {
  selectedData: ReportData;
  reportData: ReportData;
  setSelected: OnChangeSelected;
  disabledActionsWhileLoading?: boolean;
  searchQuery: string;
  onlyManual: boolean;
};

export const ReportDataTableWithConnections = ({
  selectedData,
  reportData,
  setSelected,
  searchQuery,
  disabledActionsWhileLoading = false,
  onlyManual = false,
}: ReportDataTableWithConnectionsProps) => {
  const reportDataForRender = React.useMemo(() => {
    if (!searchQuery) {
      return reportData;
    }

    const searchWords = searchQuery.toLowerCase().split(' ');
    const matchSearchQuery = (value: string) => searchWords.every(word => value.toLowerCase().includes(word));

    return reportData.reduce<ReportData>((acc, employer) => {
      const matchEmployer = matchSearchQuery(employer.name);
      const matchConnections = employer.connections.some(connection => matchSearchQuery(connection.name));
      const isAMatch = matchEmployer || matchConnections;

      if (isAMatch) {
        acc.push({
          ...employer,
          connections: matchConnections
            ? employer.connections.filter(connection => matchSearchQuery(connection.name))
            : employer.connections,
        });
      }

      return acc;
    }, []);
  }, [reportData, searchQuery]);

  const onSelect = React.useCallback(
    (employer: ReportEmployer) => () => {
      const newSelectedData = [...selectedData];
      const isSelected = selectedData.some(({ employerId }) => employerId === employer.employerId);

      if (isSelected) {
        setSelected(newSelectedData.filter(({ employerId }) => employerId !== employer.employerId));
      }

      if (!isSelected) {
        newSelectedData.push({ ...employer });
        setSelected(newSelectedData);
      }
    },
    [selectedData, setSelected],
  );

  const onSelectConnection = React.useCallback(
    (employer: ReportEmployer, connection: ReportEmployerConnection) => () => {
      const newSelectedData = [...selectedData];
      const selectedEmployerIdx = selectedData.findIndex(({ employerId }) => employerId === employer.employerId);
      const isEmployerSelected = selectedEmployerIdx !== -1;

      if (!isEmployerSelected) {
        newSelectedData.push({
          ...employer,
          connections: [connection],
        });
      }

      if (isEmployerSelected) {
        const isConnectionSelected = newSelectedData[selectedEmployerIdx].connections.some(
          ({ connectionId }) => connectionId === connection.connectionId,
        );

        if (!isConnectionSelected) {
          newSelectedData[selectedEmployerIdx].connections = [
            ...newSelectedData[selectedEmployerIdx].connections,
            connection,
          ];
        }

        if (isConnectionSelected) {
          newSelectedData[selectedEmployerIdx].connections = [
            ...newSelectedData[selectedEmployerIdx].connections.filter(
              ({ connectionId }) => connectionId !== connection.connectionId,
            ),
          ];

          const isEmployerWithoutConnectionsSelected = newSelectedData[selectedEmployerIdx].connections.length === 0;

          if (isEmployerWithoutConnectionsSelected) {
            newSelectedData.splice(selectedEmployerIdx, 1);
          }
        }
      }

      setSelected(newSelectedData);
    },
    [selectedData, setSelected],
  );

  const renderConnections = React.useCallback(
    (employer: ReportEmployer) => {
      const selectedEmployer = selectedData.find(({ employerId }) => employerId === employer.employerId);

      return employer.connections
        .filter(connection => !(onlyManual && !connection.manual))
        .map(connection => {
          const isSelected = (selectedEmployer?.connections || []).some(
            ({ connectionId }) => connectionId === connection.connectionId,
          );

          return (
            <ListRow
              key={`${employer.employerId}_${connection.connectionId}`}
              selected={isSelected}
              onSelect={onSelectConnection(employer, connection)}
              disabled={disabledActionsWhileLoading}
              innerRow
            >
              <Typography>
                <ActionLink to={`/employers/${employer.employerId}/connections/${connection.connectionId}`}>
                  {connection.name}
                </ActionLink>
              </Typography>
            </ListRow>
          );
        });
    },
    [disabledActionsWhileLoading, onSelectConnection, selectedData, onlyManual],
  );

  const hasData = reportDataForRender?.length > 0;

  return (
    <ReportDataContainer>
      {hasData &&
        reportDataForRender
          .filter(
            employer =>
              employer.connections?.length &&
              employer.connections.filter(connection => (connection.manual && onlyManual) || !onlyManual).length,
          )
          .map(employer => {
            const isSelected = selectedData.some(({ employerId }) => employerId === employer.employerId);

            return (
              <ListCollapsibleRow
                key={employer.employerId}
                selected={isSelected}
                onSelect={onSelect(employer)}
                disabled={disabledActionsWhileLoading}
                rowHeader={
                  <Typography>
                    <ActionLink to={`/employers/${employer.employerId}`}>{employer.name}</ActionLink>
                  </Typography>
                }
              >
                {renderConnections(employer)}
              </ListCollapsibleRow>
            );
          })}
      {!hasData && <ReportNoData searchQuery={searchQuery} />}
    </ReportDataContainer>
  );
};
