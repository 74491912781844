import React from 'react';
import { useIntl } from 'react-intl';

import { TranslationKeys } from 'app/translations';

import { Uuid } from 'app/utils';

import { useOwnerGetSubOwners, useProductPensionSchemeSelectItems } from 'app/hooks';

import type { TableActions, TableSelectedState } from 'app/store';
import { StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import { InputFieldFactory } from 'app/components/FormikField';
import { ActionButton, SearchInput } from 'app/components';

import { UnprocessedTableFiltersContainerStyled } from './UnprocessedTableFilters.styles';

export const UNPROCESSED_TABLE_FILTERS_FIELD_NAMES = {
  Status: 'employerStatus',
  SubOwners: 'subOwners',
  ProductPensionScheme: 'productPensionScheme',
} as const;

type UnprocessedTableFiltersProps = {
  isLoading?: boolean;
  reduxActionApplyFilter: TableActions['applyFilter'];
  reduxActionRemoveFilter: TableActions['removeFilter'];
  reduxActionSearch: TableActions['search'];
  reduxStateSelector: 'selectUnprocessedEvents' | 'selectUnprocessedBatches';
};

export const UnprocessedTableFilters = ({
  reduxActionSearch,
  reduxActionApplyFilter,
  reduxActionRemoveFilter,
  reduxStateSelector,
  isLoading = false,
}: UnprocessedTableFiltersProps) => {
  const intl = useIntl();
  const dispatch = useStoreDispatch();
  const { productPensionSchemeSelectItems } = useProductPensionSchemeSelectItems();

  const [key, setKey] = React.useState(Uuid.newV4);

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const { searchQuery, filters } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.DashboardSelector[reduxStateSelector](state.DashboardReducer),
  );

  const {
    data: subOwners,
    isLoading: isLoadingGetSubOwnersQuery,
    isFetching: isFetchingGetSubOwnersQuery,
  } = useOwnerGetSubOwners({
    variables: { userId, page: 1, perPage: 500 },
    options: {
      enabled: !!userId,
    },
  });

  const isGetSubOwnersQueryLoading = isLoadingGetSubOwnersQuery || isFetchingGetSubOwnersQuery;

  const productPensionSchemeSelectItemsWithAllOption = React.useMemo(() => {
    return [
      {
        value: '',
        element: intl.formatMessage({ id: TranslationKeys.all }),
        default: true,
      },
      ...productPensionSchemeSelectItems,
    ];
  }, [intl, productPensionSchemeSelectItems]);

  const subOwnersSelectItems = React.useMemo(() => {
    return (subOwners?.data || []).map(subOwner => ({
      value: subOwner.ownerId,
      element: subOwner.ownerName,
    }));
  }, [subOwners?.data]);

  const employerStatusSelectItems = React.useMemo(() => {
    return [
      {
        value: 'active',
        element: intl.formatMessage({ id: TranslationKeys.employers_active }),
      },
      {
        value: 'inactive',
        element: intl.formatMessage({ id: TranslationKeys.employers_inactive }),
      },
      {
        value: 'underConstruction',
        element: intl.formatMessage({ id: TranslationKeys.employers_underConstruction }),
      },
    ];
  }, [intl]);

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme && value === '') {
        dispatch(reduxActionRemoveFilter(UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme));
      } else {
        dispatch(reduxActionApplyFilter(name, value));
      }
    },
    [dispatch, reduxActionApplyFilter, reduxActionRemoveFilter],
  );

  const onSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(reduxActionSearch(event.target.value));
    },
    [dispatch, reduxActionSearch],
  );

  const onClickResetButtonHandler = React.useCallback(() => {
    dispatch(reduxActionRemoveFilter(UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.SubOwners));
    dispatch(reduxActionRemoveFilter(UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.Status));
    dispatch(reduxActionRemoveFilter(UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme));
    dispatch(reduxActionSearch(''));
    setKey(Uuid.newV4());
  }, [dispatch, reduxActionRemoveFilter, reduxActionSearch]);

  return (
    <UnprocessedTableFiltersContainerStyled>
      <InputFieldFactory
        key={`sub-owners-${key}`}
        disabled={isLoading || isGetSubOwnersQueryLoading}
        onChange={onFilterChange}
        field={{
          type: 'multi-select',
          name: UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.SubOwners,
          label: intl.formatMessage({ id: TranslationKeys.menu_subOwners }),
          fullWidth: false,
          value: filters.subOwners || null,
          items: subOwnersSelectItems,
          loading: isGetSubOwnersQueryLoading,
        }}
      />

      <InputFieldFactory
        key={`employerStatus-${key}`}
        disabled={isLoading || isGetSubOwnersQueryLoading}
        onChange={onFilterChange}
        field={{
          type: 'multi-select',
          name: UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.Status,
          label: intl.formatMessage({ id: TranslationKeys.menu_employers_status }),
          fullWidth: false,
          value: null,
          items: employerStatusSelectItems,
          loading: isGetSubOwnersQueryLoading,
        }}
      />

      <InputFieldFactory
        key={`product-pension-scheme-${key}`}
        disabled={isLoading}
        onChange={onFilterChange}
        field={{
          type: 'select',
          name: UNPROCESSED_TABLE_FILTERS_FIELD_NAMES.ProductPensionScheme,
          label: intl.formatMessage({ id: TranslationKeys.global_pensionScheme }),
          fullWidth: false,
          value: filters.productPensionScheme || null,
          items: productPensionSchemeSelectItemsWithAllOption,
        }}
      />

      <SearchInput
        key={`search-${key}`}
        disabled={isLoading}
        initialSearchQuery={searchQuery}
        onChange={onSearchChange}
        debounceTime={1000}
      />

      <ActionButton
        disabled={isLoading}
        messageId={TranslationKeys.global_reset}
        onClick={onClickResetButtonHandler}
        variant={'text'}
      />
    </UnprocessedTableFiltersContainerStyled>
  );
};
