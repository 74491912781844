import { TranslationKeys } from '../../../../../../translations';
import type { CreateUserArgs, EditUserArgs } from '../../../../Services';

export const UserDetailsPanelFields = {
  FirstName: {
    Label: TranslationKeys.users_firstName,
    InputName: 'firstName',
    schema: (schema: any) => schema.trim().min(2).max(50).required(),
  },
  MiddleName: {
    Label: TranslationKeys.users_middleName,
    InputName: 'middleName',
  },
  LastName: {
    Label: TranslationKeys.users_lastName,
    InputName: 'lastName',
    schema: (schema: any) => schema.trim().min(2).max(50).required(),
  },
  EmailAddress: {
    Label: TranslationKeys.users_email,
    InputName: 'emailAddress',
    schema: (schema: any) => schema.email().required(),
  },
  RoleId: {
    Label: TranslationKeys.users_role,
    InputName: 'roleId',
    schema: (schema: any) => schema.required(),
  },
  OwnerId: {
    Label: TranslationKeys.users_owner,
    InputName: 'ownerId',
    schema: (schema: any) => schema.required(),
  },
  Language: {
    Label: TranslationKeys.users_language,
    InputName: 'language',
    schema: (schema: any) => schema.required(),
  },
  CreatedAt: {
    Label: TranslationKeys.users_createdAt,
    InputName: 'createdAt',
  },
  Internal: {
    Label: TranslationKeys.users_internal,
    InputName: 'internal',
  },
} as const;

export type UserDetailsPanelFormValues = Omit<EditUserArgs, 'language' | 'accesses' | 'eventFlows'> & {
  language: CreateUserArgs['language'] | '';
  createdAt: string;
};
