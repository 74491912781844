import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import type { RateTable } from 'app/types';

import { TranslationKeys } from 'app/translations';

import { saveFile } from 'app/utils';

import type { TableSelectedState } from 'app/store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import { useSettingsDeleteRateTableMutation, useSettingsGetAllRateTablesQuery, useSnakeBar } from 'app/hooks';

import { ListFile } from 'app/components/FileUploadAreaWithTemplate/ListFile';
import { ConfirmDialog, useBreadcrumb } from 'app/components';

import {
  RATE_TABLE_DOWNLOAD_FILE_HEADERS,
  RATE_TABLE_DOWNLOAD_FILE_STRUCTURE,
  RateTableFileReadStrategy,
} from 'app/Domain/Settings/Components/RateTableForm/components/RateTableFileUpload';

import { SettingsRateTable, SettingsRateTablesToolbar } from './components';

export const SettingsRateTablesPage = () => {
  const dispatch = useStoreDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: deleteRateTableMutation, isLoading: isDeleting } = useSettingsDeleteRateTableMutation();

  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState(false);
  const [rateTableIdToDelete, setRateTableIdToDelete] = React.useState<RateTable['rateTableId'] | null>(null);

  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const { searchQuery, sortBy, page, pageSize } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.SettingsSelector.selectRateTables(state.SettingsReducer),
  );

  const {
    data: rateTables,
    isLoading: getAllRateTablesQueryLoading,
    isFetching: getAllRateTablesQueryFetching,
    refetch: refetchGetAllRateTablesQuery,
  } = useSettingsGetAllRateTablesQuery({
    variables: {
      ownerId,
      page,
      pageSize,
      searchQuery,
      sortBy,
    },
    options: {
      enabled: !!ownerId,
    },
  });

  const isLoading = getAllRateTablesQueryLoading || getAllRateTablesQueryFetching || isDeleting;

  const onSearchChange = React.useCallback(
    event => {
      dispatch(StoreActions.SettingsActions.rateTables.search(event.target.value, false));
    },
    [dispatch],
  );

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.SettingsActions.rateTables.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.SettingsActions.rateTables.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.SettingsActions.rateTables.changePage(page));
      }
    },
    [dispatch],
  );

  const onDeleteActionClick = React.useCallback(rateTableId => {
    setConfirmDeleteModal(true);
    setRateTableIdToDelete(rateTableId);
  }, []);

  const deleteRateTable = React.useCallback(() => {
    if (rateTableIdToDelete) {
      const method = 'deleteRateTable';

      deleteRateTableMutation(
        { ownerId, rateTableId: rateTableIdToDelete },
        {
          onSuccess: () => {
            refetchGetAllRateTablesQuery();
            setRateTableIdToDelete(null);
            showSuccessSnakeBar({ method });
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error.message });
          },
        },
      );
    }
  }, [
    deleteRateTableMutation,
    ownerId,
    rateTableIdToDelete,
    refetchGetAllRateTablesQuery,
    showErrorSnakeBar,
    showSuccessSnakeBar,
  ]);

  const onDownloadActionClick = React.useCallback(
    (rateTable: RateTable) => {
      const rateTableFileStrategy = new RateTableFileReadStrategy(intl);
      const listFile = new ListFile([...RATE_TABLE_DOWNLOAD_FILE_HEADERS], rateTableFileStrategy);

      const keys = RATE_TABLE_DOWNLOAD_FILE_STRUCTURE.map(fileStructure => fileStructure.fieldName);
      const sheet = listFile.generateDataSheet(rateTable.table || [], keys);
      const blob = listFile.sheetToCsv(sheet, { FS: ';' });
      const fileName = `${rateTable.title}_${new Date().getTime()}.csv`;

      saveFile(blob, fileName);
    },
    [intl],
  );

  useBreadcrumb(TranslationKeys.menu_settings_rateTables, {
    hideTrail: true,
    hideTitle: true,
    otherElements: (
      <SettingsRateTablesToolbar
        isLoading={isLoading}
        totalCount={rateTables?.totalCount ?? 0}
        onAddButtonClick={() => navigate('/settings/rate-tables/new')}
        onSearchChange={onSearchChange}
        initialSearchQuery={searchQuery}
      />
    ),
  });

  return (
    <>
      <Box marginTop={2}>
        <SettingsRateTable
          isLoading={isLoading}
          rateTables={rateTables?.data || []}
          onDeleteActionClick={onDeleteActionClick}
          onDownloadActionClick={onDownloadActionClick}
          onSortChange={onSortChange}
          onPaginationChange={onPaginationChange}
          pagination={{
            page,
            pageSize,
            totalCount: rateTables?.totalCount ?? 0,
          }}
        />
      </Box>

      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.settings_rateTable_confirmDeleteTitle} />}
          open={confirmDeleteModal}
          setOpen={setConfirmDeleteModal}
          onConfirm={deleteRateTable}
        >
          <FormattedMessage id={TranslationKeys.settings_rateTable_confirmDeleteMessage} />
        </ConfirmDialog>
      )}
    </>
  );
};
