import { TranslationKeys } from 'app/translations';
import type { SummaryScales } from 'app/types';

export const SummaryScalesTableFormFields = {
  Year: {
    Label: TranslationKeys.global_year,
    InputName: 'year',
  },
  Title: {
    Label: TranslationKeys.global_title,
    InputName: 'title',
  },
  Table: {
    InputName: 'table',
  },
  Code: {
    Label: TranslationKeys.global_code,
    InputName: 'code',
  },
  TaxLimitPercentage: {
    Label: TranslationKeys.global_interestRateScale,
    InputName: 'taxLimitPercentage',
  },
  AgePercentages: {
    InputName: 'agePercentages',
  },
} as const;

export type SummaryScalesFormValues = {
  uploadError: boolean;
  year: number;
  title: string;
  taxLimitPercentage: number;
  agePercentages: SummaryScales['agePercentages'];
};
