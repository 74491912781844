import type { GetActualValueParams } from '../Shared/formikFieldHelpers';
import { getSelectActualValue } from '../Select/formikFieldHelpers';
import { isArray } from '../../../utils/Lodash';

export const getMultiSelectActualValue = ({
  normalizedOptions,
  defaultValue,
  formikValue,
  value,
}: GetActualValueParams) => {
  if (isArray(formikValue)) {
    return formikValue.map(formikValueItem =>
      getSelectActualValue({ normalizedOptions, defaultValue, formikValue: formikValueItem, value }),
    );
  }

  if (isArray(value)) {
    return value.map(valueItem =>
      getSelectActualValue({ normalizedOptions, defaultValue, formikValue, value: valueItem }),
    );
  }

  if (defaultValue) {
    return [getSelectActualValue({ normalizedOptions, defaultValue, formikValue, value })];
  }

  return [];
};
