import { useMutation } from '@tanstack/react-query';

import type { GenerateReportParams } from '../../../Domain/Reports/Services/ReportService';
import { reportService } from '../../../Domain/Reports/Services/ReportService';

export type GenerateReportData = GenerateReportParams;

const mutationFn = (data: GenerateReportData) => {
  return reportService.generateReport(data);
};

export const useReportsGenerateReportMutation = () => {
  return useMutation<unknown, unknown, GenerateReportData>(mutationFn);
};
