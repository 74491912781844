import React, { useCallback, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { isNaN, isNil, isObject } from 'app/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { TypographyStyled } from './EmployeeCSCFileUpload.styles';
import { InputFieldFactory } from '../../../../../../components/FormikField';
import FileUploadArea, { FileType } from '../../../../../../components/FileUploadArea';
import type { Employer } from '../../../../../../types';

type EmployeeCSCFileUploadProps = {
  employerConnections: Employer['connections'] | null;
  progressMsg: string | null;
  setProgressMsg: React.Dispatch<React.SetStateAction<string | null>>;
};

type EmployeeType = {
  employeeNumber: string | null;
  bsn: string | null;
  wage: number | null;
  partTimePercentage: number | null;
  eventDate: string | null;
};

export const EmployeeCSCFileUpload = ({
  employerConnections,
  progressMsg,
  setProgressMsg,
}: EmployeeCSCFileUploadProps) => {
  const intl = useIntl();
  const [fileErrors, setFileErrors] = useState<any[]>([]);
  const { isSubmitting, setFieldValue, setFieldError } = useFormikContext();
  const [validating, setValidating] = useState(false);

  const partTimePercentageIsValid = useCallback(partTimePercentage => {
    return (
      partTimePercentage <= 100 && partTimePercentage > 0 && !isNil(partTimePercentage) && !isNaN(partTimePercentage)
    );
  }, []);

  const validateEmployees = useCallback(
    employees => {
      return new Promise((resolve, reject) => {
        try {
          const employeeErrors: any[] = [];
          employees.forEach((employee: EmployeeType, index: number) => {
            if (!partTimePercentageIsValid(employee.partTimePercentage)) {
              setFileErrors(prevArr => [
                ...prevArr,
                { message: 'employees.error.partTimePercentage', values: index + 2 },
              ]);
              employeeErrors.push({ message: 'employees.error.partTimePercentage', values: index + 3 });
            }
          });

          if (employeeErrors.length) {
            setFieldValue('hasErrors', true);
          } else {
            setFieldValue('hasErrors', false);
          }

          resolve(employeeErrors);
        } catch (e) {
          reject(e);
        }
      });
    },
    [setFieldValue, partTimePercentageIsValid],
  );

  const parseExcelFile = useCallback(file => {
    return new Promise((resolve, reject) => {
      setFileErrors([]);
      const reader = new FileReader();
      reader.onload = event => {
        try {
          const payload = event.target?.result;
          const workbook = XLSX.read(payload, { type: 'binary', cellDates: true, dateNF: 'dd-MM-yyyy' });

          const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

          const headers = [
            'employee.number',
            'employee.date_birth',
            'employee.gender',
            'employee.name_initials',
            'employee.name_first',
            'employee.name_prefix',
            'employee.name_last',
            'employee.bsn',
            'employment.start',
            'employment.end',
            'employment.wage',
            'employment.partTime',
            'employment.wage_new',
            'employment.partTime_new',
            'event_date',
          ];
          const data = XLSX.utils.sheet_to_json(sheet, {
            header: headers,
            blankrows: false,
            skipHidden: true,
            rawNumbers: true,
            raw: false,
            range: 1,
          });

          const employees: EmployeeType[] = [];

          data.forEach((row: any) => {
            const wage =
              parseFloat(row['employment.wage_new']?.toString().replace(',', '.')) ||
              parseFloat(row['employment.wage']?.toString().replace(',', '.'));
            const partTimePercentage =
              parseFloat(row['employment.partTime_new']?.toString().replace(',', '.')) ||
              parseFloat(row['employment.partTime']?.toString().replace(',', '.'));

            const employee: EmployeeType = {
              employeeNumber: row['employee.number'] + '',
              bsn: row['employee.bsn'] + '',
              wage: wage,
              partTimePercentage: partTimePercentage,
              eventDate: isObject(row['event_date'])
                ? row['event_date'].toLocaleDateString('nl-NL')
                : row['event_date'],
            };

            employees.push(employee);
          });

          resolve(employees);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = () => {
        reject(new Error(`Error occurred reading file: ${file.name}`));
      };
      reader.readAsBinaryString(file);
    });
  }, []);

  const changeList = useCallback(
    async files => {
      const file = files.slice(0, 1)[0] ?? undefined;

      if (file) {
        setProgressMsg('employees.add.readFile');
        setValidating(true);
        await parseExcelFile(file)
          .then(async employees => {
            setProgressMsg('employees.add.validateFile');
            await validateEmployees(employees);
            setFieldValue('employees', employees);
          })
          .catch(error => {
            setFileErrors([...fileErrors, error]);
            setFieldError('employees', error);
          })
          .finally(() => {
            setProgressMsg('employees.add.validateFileFinish');
            setValidating(false);
          });
      } else {
        setProgressMsg('employees.add.fileClear');
        setFileErrors([]);
        setFieldValue('employees', []);
      }
    },
    [fileErrors, parseExcelFile, setFieldError, setFieldValue, setProgressMsg, validateEmployees],
  );

  const fields = useMemo(
    () => [
      {
        name: 'connectionId',
        type: 'autocomplete',
        required: true,
        header: 'onboarding.connectionStep',
        placeholder: intl.formatMessage({ id: 'onboarding.connectionStep' }),
        loading: false,
        items: (employerConnections || []).map(connection => {
          return {
            element: connection.connectionName,
            value: connection.connectionId,
            default: employerConnections?.length === 1,
          };
        }),
      },
    ],
    [employerConnections, intl],
  );

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        {fields.map((field, index) => {
          return (
            <Grid item xs={4} key={index}>
              <InputFieldFactory field={field} />
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <FileUploadArea
            types={[FileType.Csv]}
            onChange={changeList}
            disabled={false}
            showSuccessMessage={false}
            isSubmitting={isSubmitting}
          />
          {fileErrors.length > 0 &&
            !validating &&
            fileErrors.map((fileError, index) => (
              <TypographyStyled key={index} color="error" variant="body1">
                <FormattedMessage
                  id={fileError.message}
                  values={{ row: fileError?.values, b: chunks => <b>{chunks}</b>, br: <br /> }}
                />
              </TypographyStyled>
            ))}
          {progressMsg && (
            <TypographyStyled color="primary" variant="body1">
              <FormattedMessage id={progressMsg} />
            </TypographyStyled>
          )}
        </Grid>
      </Grid>
    </>
  );
};
