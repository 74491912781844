import React, { memo } from 'react';

import FormikWrapper from '../FormikWrapper';
import type { InputFieldFactoryProps, InputFactoryOnChangeFunc } from '../Factory/InputFieldFactory';

import InternalTextField from './InternalTextField';
import type { useFormikContext } from 'formik';

export type TextProps = Pick<InputFieldFactoryProps, 'className' | 'field' | 'hideErrorText'> & {
  onChange?: InputFactoryOnChangeFunc;
  variant?: 'default' | 'slim' | string;
};

const Text = (props: TextProps) => {
  return (
    <FormikWrapper>
      {(formik: ReturnType<typeof useFormikContext>) => <InternalTextField formik={formik} {...props} />}
    </FormikWrapper>
  );
};

export default memo(Text);
