import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import { ActionButton, Dialog } from 'app/components';

type SettingsStepAnnualWageFactorWarningDialogProps = {
  show: boolean;
  currentValue: number;
  defaultValue: number;
  onClickKeepBoth: () => void;
  onClickSynchronize: () => void;
};

export const SettingsStepAnnualWageFactorWarningDialog = ({
  show,
  currentValue,
  defaultValue,
  onClickKeepBoth,
  onClickSynchronize,
}: SettingsStepAnnualWageFactorWarningDialogProps) => {
  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton
          messageId={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_button_synchronize}
          onClick={onClickSynchronize}
          variant={'outlined'}
        />
        <ActionButton
          messageId={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_button_keepBoth}
          onClick={onClickKeepBoth}
        />
      </>
    );
  }, [onClickSynchronize, onClickKeepBoth]);

  return (
    <Dialog
      open={show}
      fullWidth={false}
      title={<FormattedMessage id={TranslationKeys.global_warning} />}
      actions={DialogActions}
    >
      <Typography gutterBottom>
        <FormattedMessage id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_header} />
      </Typography>

      <Typography gutterBottom>
        <FormattedMessage id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_infoText} />
      </Typography>

      <Typography>
        <FormattedMessage id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_warningText} />
      </Typography>

      <Box marginTop={2}>
        <Typography fontWeight={'bold'}>
          <FormattedMessage
            id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_defaultValue}
            values={{
              defaultValue: (
                <Box component={'span'} fontWeight={'normal'}>
                  {defaultValue}
                </Box>
              ),
            }}
          />
        </Typography>

        <Typography fontWeight={'bold'}>
          <FormattedMessage
            id={TranslationKeys.onboarding_settings_annualWageFactor_warningDialog_currentValue}
            values={{
              currentValue: (
                <Box component={'span'} fontWeight={'normal'}>
                  {currentValue}
                </Box>
              ),
            }}
          />
        </Typography>
      </Box>
    </Dialog>
  );
};
