import { styled } from '@mui/material/styles';
import { InputAdornment, Tooltip } from '@mui/material';

export const InputAdornmentStyled = styled(InputAdornment, {
  name: 'InputAdornmentStyled',
  shouldForwardProp: (propName: string) => propName !== 'slim',
})<{ slim: boolean }>(({ theme, slim }) => ({
  marginRight: theme.spacing(slim ? 1 : 2),
  cursor: 'pointer',
}));

export const TooltipStyled = styled(Tooltip, {
  name: 'TooltipStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.info.main,
}));
