import { styled } from '@mui/material/styles';
import { Button, Tooltip } from '@mui/material';

export const ButtonStyled = styled(Button, {
  name: 'ButtonStyled',
  shouldForwardProp: (propName: string) => !['margin'].includes(propName),
})<{ margin?: 'left' | 'right' }>(({ theme, margin = 'right' }) => {
  if (margin === 'right') {
    return {
      marginRight: theme.spacing(2),
    };
  }

  return {
    marginLeft: theme.spacing(2),
  };
});

export const TooltipStyled = styled(Tooltip, {
  name: 'TooltipStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
