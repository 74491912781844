import React, { memo } from 'react';
import FormikWrapper from '../FormikWrapper';
import InternalDatePicker from './InternalDatePicker';
import type { InputFactoryOnChangeFunc, InputFieldFactoryProps } from '../Factory/InputFieldFactory';
import type { useFormikContext } from 'formik';

export type DatePickerProps = Pick<InputFieldFactoryProps, 'className' | 'field' | 'hideErrorText'> & {
  onChange?: InputFactoryOnChangeFunc;
  variant?: 'default' | 'slim' | string;
  debounceTime?: number;
};

const DatePicker = (props: DatePickerProps) => {
  return (
    <FormikWrapper>
      {(formik: ReturnType<typeof useFormikContext>) => <InternalDatePicker formik={formik} {...props} />}
    </FormikWrapper>
  );
};

export default memo(DatePicker);
