import { alpha, styled } from '@mui/material/styles';
import PaperBackground from '../../../../components/PaperBackground';

export const PaperBackgroundStyled = styled(PaperBackground, {
  name: 'PaperBackgroundStyled',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const DivHeaderStyled = styled('div', {
  name: 'DivHeaderStyled',
})(({ theme }) => ({
  padding: theme.spacing(0, 1, 1, 1),
  borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
  margin: `0 -${theme.spacing(1)}px 0 -${theme.spacing(1)}px`,
  width: `calc(100% + ${theme.spacing(2)}px)`,
}));
