import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as CredentialRequestsActions } from '../../Ducks/CredentialRequests.duck';
import { CredentialRequestsTable, CredentialRequestsToolbar } from '../../Components';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { DivStyled } from './CredentialRequestsManagementPage.styles';

const CredentialRequestsManagementPage = () => {
  const dispatch = useDispatch();
  const { loading, items, page, pageSize, totalCount } = useSelector(state => state.CredentialRequestsReducer);

  useEffect(() => {
    dispatch(CredentialRequestsActions.requestData());
  }, [dispatch]);

  const handleSearch = event => {
    dispatch(CredentialRequestsActions.search(event.target.value));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(CredentialRequestsActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(CredentialRequestsActions.setPageSize(event.target.value));
  };

  useBreadcrumb('menu.credentialsRequests', {
    hideTrail: true,
    hideTitle: true,
    otherElements: <CredentialRequestsToolbar handleSearch={handleSearch} />,
  });

  return (
    <DivStyled>
      <CredentialRequestsTable
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        credentialRequests={items}
        page={page - 1}
        rowsPerPage={pageSize}
        totalCount={totalCount}
        loading={loading}
      />
    </DivStyled>
  );
};

export default CredentialRequestsManagementPage;
