import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { actions as ProcessesActions } from '../../../Ducks/Processes.duck';
import { actions as ConnectionActions, selectors as connectionSelectors } from '../../../Ducks/Connection.duck';
import { processesService } from '../../../Services';
import { saveFileFromResponse } from 'app/utils';
import { ProcessesToolbar } from '../../../Packages/InsuranceBrydge/Components/ProcessesToolbar';
import { ProcessesTable } from '../../../Packages/InsuranceBrydge/Components/ProcessesTable';
import ProcessMessagesDialog from '../../../Packages/InsuranceBrydge/Components/ProcessMessagesDialog';
import ProcessEventsDialog from '../../../Packages/InsuranceBrydge/Components/ProcessEventsDialog';
import { SnackBar } from 'app/components';
import { FormattedMessage } from 'react-intl';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { MESSAGE_SEVERITY_ERROR } from 'app/common';
import { CONNECTION } from 'app/common/Authorization/entities';
import { NOTE } from 'app/common/Authorization/permissions';
import { HasAccessTo } from 'app/components/Authorization';
import { ConnectionNoteInput } from '../../../Components/ConnectionNoteInput';
import { actions as AppActions } from '../../../../App/Ducks/App.duck';
import MutationsDialog from '../../../Packages/InsuranceBrydge/Components/MutationsDialog';
import { Box } from '@mui/material';
import { TranslationKeys } from 'app/translations';

const BatchesTab = () => {
  const { employerId, connectionId } = useParams();
  const dispatch = useDispatch();

  const { items, page, pageSize, totalCount, filters, loading } = useSelector(state => state.ProcessesReducer);

  const { items: mutationTypes } = useSelector(state => connectionSelectors.mutationTypes(state.ConnectionReducer));
  const { showSnackBar } = useSelector(state => state.AppReducer);

  const [errorMessagesOpen, setErrorMessagesOpen] = useState(false);
  const [selectedErrorMessagesProcess, setSelectedErrorMessagesProcess] = useState(null);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [mutationsOpen, setMutationsOpen] = useState(false);
  const [batchIds, setBatchIds] = useState(null);
  const [mutationsStatus, setMutationsStatus] = useState(null);

  useEffect(() => {
    dispatch(ProcessesActions.setConnectionId(connectionId));
    dispatch(ConnectionActions.requestData(connectionId));
    dispatch(ProcessesActions.requestData());
    dispatch(ConnectionActions.mutationTypes.requestData({ connectionId }));
  }, [connectionId, dispatch]);

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (rowSize) {
        dispatch(ProcessesActions.setPageSize(rowSize));
      }
      if (page) {
        dispatch(ProcessesActions.changePage(page));
      }
    },
    [dispatch],
  );

  const applyFilter = (key, value) => {
    dispatch(ProcessesActions.applyFilter(key, value));
  };

  const resetFilters = useCallback(() => {
    dispatch(ProcessesActions.resetFilters());
  }, [dispatch]);

  const handleClickRetry = process => {
    dispatch(ProcessesActions.retryProcess(connectionId, process));
  };

  const handleClickApprove = process => {
    dispatch(ProcessesActions.approveProcess(connectionId, process));
  };

  const handleClickDecline = process => {
    dispatch(ProcessesActions.declineProcess(connectionId, process));
  };

  const handleClickForceSend = (process, withFailed) => {
    dispatch(ProcessesActions.forceSendProcess(connectionId, process, withFailed));
  };

  const handleClickForceDecline = useCallback(
    process => {
      dispatch(ProcessesActions.forceDeclineProcess(connectionId, process));
    },
    [dispatch, connectionId],
  );

  const handleClickDownload = async process => {
    try {
      const response = await processesService.downloadProcessResultRequest({
        connectionId,
        mongoId: process.mongoId,
      });
      saveFileFromResponse(response);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'handleClickDownload',
        message: error,
        severity,
      };

      dispatch(AppActions.displayMessage(details));
    }
  };

  const handleClickDownloadLog = async process => {
    try {
      const response = await processesService.downloadProcessResultRequestLog({
        connectionId,
        mongoId: process.mongoId,
      });
      saveFileFromResponse(response);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'handleClickDownloadLog',
        message: error,
        severity,
      };

      dispatch(AppActions.displayMessage(details));
    }
  };

  const handleErrorMessagesClick = processId => {
    setErrorMessagesOpen(true);
    setSelectedErrorMessagesProcess(processId);
  };

  const handleErrorMessagesDialogClose = () => {
    setErrorMessagesOpen(false);
    setSelectedErrorMessagesProcess(null);
  };

  const handleEventsClick = (newBatchId, newBatchMongoId) => {
    setEventsOpen(true);
    setBatchIds({ batchId: newBatchId, batchMongoId: newBatchMongoId });
  };

  const handleEventsDialogClose = () => {
    setEventsOpen(false);
    setBatchIds(null);
  };

  const handleMutationsClick = (newBatchId, newBatchMongoId) => {
    setMutationsOpen(true);
    setBatchIds({ batchId: newBatchId, batchMongoId: newBatchMongoId });
  };

  const handleMutationsDialogClose = () => {
    setMutationsOpen(false);
    setBatchIds(null);
  };

  const handleMutationStatusClick = async process => {
    try {
      const response = await processesService.getMutationsStatus({
        connectionId,
        mongoId: process.mongoId,
      });
      response.data.mongoId = process.mongoId;
      setMutationsStatus(response.data);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'handleMutationStatusClick',
        message: error,
        severity,
      };

      dispatch(AppActions.displayMessage(details));
    }
  };

  const handleClearAlertMutationsStatus = () => {
    setMutationsStatus(null);
  };

  useBreadcrumb('connectionDetails.batches');

  const enabledIfAccessToConnectionNote = HasAccessTo(CONNECTION, NOTE);

  return (
    <>
      <ProcessesToolbar
        isLoading={loading}
        connectionId={connectionId}
        filters={filters}
        applyFilter={applyFilter}
        resetFilters={resetFilters}
      />
      <ProcessesTable
        employerId={employerId}
        connectionId={connectionId}
        processes={items}
        mutationsStatus={mutationsStatus}
        page={page}
        rowsPerPage={pageSize}
        totalCount={totalCount}
        loading={loading}
        onPaginationChange={onPaginationChange}
        handleClickRetry={handleClickRetry}
        handleClickApprove={handleClickApprove}
        handleClickDecline={handleClickDecline}
        handleClickDownload={handleClickDownload}
        handleClickDownloadLog={handleClickDownloadLog}
        handleClickForceSend={handleClickForceSend}
        handleClickForceDecline={handleClickForceDecline}
        handleErrorMessagesClick={handleErrorMessagesClick}
        handleEventsClick={handleEventsClick}
        handleMutationsClick={handleMutationsClick}
        handleMutationStatusClick={handleMutationStatusClick}
        handleClearAlertMutationsStatus={handleClearAlertMutationsStatus}
      />
      {errorMessagesOpen && (
        <ProcessMessagesDialog
          open={errorMessagesOpen}
          batchId={selectedErrorMessagesProcess}
          handleClose={handleErrorMessagesDialogClose}
        />
      )}
      {eventsOpen && batchIds && (
        <ProcessEventsDialog
          open={eventsOpen}
          connectionId={connectionId}
          batchId={batchIds.batchId}
          batchMongoId={batchIds.batchMongoId}
          handleClose={handleEventsDialogClose}
        />
      )}
      {mutationsOpen && batchIds && (
        <MutationsDialog
          open={mutationsOpen}
          connectionId={connectionId}
          batchId={batchIds.batchId}
          batchMongoId={batchIds.batchMongoId}
          mutationTypes={mutationTypes}
          handleClose={handleMutationsDialogClose}
        />
      )}
      {enabledIfAccessToConnectionNote && (
        <Box marginTop={5}>
          <ConnectionNoteInput connectionId={connectionId} />
        </Box>
      )}
      <SnackBar message={<FormattedMessage id={TranslationKeys.events_tooManyEvents} />} open={showSnackBar} />
    </>
  );
};

export default BatchesTab;
