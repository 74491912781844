import { useMutation } from '@tanstack/react-query';

import type { DeleteEmployeePartnerArgs } from '../../../Domain/Employees/Services/EmployeeService';
import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

const mutationFn = (data: DeleteEmployeePartnerArgs) => {
  return employeeService.deleteEmployeePartner(data);
};

export const useEmployeeDeletePartnerMutation = () => {
  return useMutation<unknown, unknown, DeleteEmployeePartnerArgs>(mutationFn);
};
