import type { EmployeeIncomePremiumWIAExcedentIVAExcedent } from '../../../../../../../../hooks';
import React from 'react';
import type { CalculationsCardProps } from '../CalculationsCard';
import { CalculationsCard } from '../../../EmployeePensionTab/components';
import { TranslationKeys } from '../../../../../../../../translations';

type EmployeeIncomePremiumWIAPanelProps = {
  loading?: boolean;
  data: EmployeeIncomePremiumWIAExcedentIVAExcedent | undefined;
};

export const EmployeeIncomePremiumsWIA = ({ loading = false, data }: EmployeeIncomePremiumWIAPanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.employees_income_insuredAmountWia,
        formulaId: TranslationKeys.employees_income_insuredAmountWiaFormula,
        value: {
          value: data?.insuredAmountWia ?? 0,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerYearWIA,
        formulaId: TranslationKeys.employees_income_premiumPerYearWIAFormula,
        value: {
          value: data?.premiumPerYearWia ?? 0,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonthWIA,
        formulaId: TranslationKeys.employees_income_premiumPerMonthWIAFormula,
        value: {
          value: data?.premiumPerMonthWia ?? 0,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_insuredAmountIva,
        formulaId: TranslationKeys.employees_income_insuredAmountIvaFormula,
        value: {
          value: data?.insuredAmountIva ?? 0,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerYearIVA,
        formulaId: TranslationKeys.employees_income_premiumPerYearIVAFormula,
        value: {
          value: data?.premiumPerYearIva ?? 0,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_income_premiumPerMonthIVA,
        formulaId: TranslationKeys.employees_income_premiumPerMonthIVAFormula,
        value: {
          value: data?.premiumPerMonthIva ?? 0,
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_premiumWIAExcedent}
      fields={cardFields}
      error={data?.message}
    />
  );
};
