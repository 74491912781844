import React from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { TranslationKeys } from 'app/translations';

import type { Employee, Employer, UseQueryRefetch } from 'app/types';
import { CivilStatusEnum } from 'app/types';

import {
  useEmployeeGetEmployeeTypesQuery,
  useEmployeeUpdateEmployeeMutation,
  useEmployerGetEmployerQuery,
  useSnakeBar,
} from 'app/hooks';

import { genderMapper } from 'app/utils';

import { EmployeeDetailsPanelForm } from './components';
import type { EmployeeDetailsPanelFormValues } from './EmployeeDetailsPanel.types';

type EmployeeDetailsPanelProps = {
  synchronizationStatus: boolean | undefined;
  refetchGetEmployeeSynchronizationQuery: UseQueryRefetch;
  refetchGetEmployeeQuery: UseQueryRefetch;
  loading: boolean;
  disabledActionButtonsWhileLoading: boolean;
  employee: Employee | undefined;
  employerId: Employer['employerId'] | undefined;
};

const EmployeeDetailsPanel = ({
  synchronizationStatus,
  refetchGetEmployeeSynchronizationQuery,
  employee,
  employerId,
  loading,
  disabledActionButtonsWhileLoading,
  refetchGetEmployeeQuery,
}: EmployeeDetailsPanelProps) => {
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const intl = useIntl();
  const { mutate: updateEmployee, isLoading: isUpdatingEmployee } = useEmployeeUpdateEmployeeMutation();

  const { data: employer } = useEmployerGetEmployerQuery({
    variables: { employerId },
    options: {
      enabled: !!employerId,
    },
  });

  const { data: employeeTypesData, isLoading: isLoadingEmployeeTypes } = useEmployeeGetEmployeeTypesQuery({
    variables: { ownerId: employer?.ownerId },
    options: {
      enabled: !!employer?.ownerId,
    },
  });

  const employeeTypes = employeeTypesData?.data;
  const [isEdit, setIsEdit] = React.useState(false);

  const formikInitialValues = React.useMemo<EmployeeDetailsPanelFormValues>(() => {
    return {
      socialSecurityNumber: employee?.socialSecurityNumber || '',
      personnelNumber: employee?.personnelNumber || '',
      employeeTypeId: employee?.employeeTypeId || '',
      gender: genderMapper(employee?.gender),
      dateOfBirth: employee?.dateOfBirth || '',
      phoneNumber: employee?.phoneNumber || '',
      emailAddress: employee?.emailAddress || '',
      civilStatus: employee?.civilStatus || CivilStatusEnum.Unknown,
      costCenter: employee?.costCenter || '',
      personName: {
        initials: employee?.personName?.initials || '',
        firstName: employee?.personName?.firstName || '',
        lastName: employee?.personName?.lastName || '',
        lastNamePrefix: employee?.personName?.lastNamePrefix || '',
      },
      address: {
        street: employee?.address?.street || '',
        houseNumber: employee?.address?.houseNumber || '',
        houseNumberSuffix: employee?.address?.houseNumberSuffix || '',
        zipCode: employee?.address?.zipCode || '',
        city: employee?.address?.city || '',
        country: employee?.address?.country || '',
      },
      profession: employee?.profession || '',
      savingsPerMonth: employee?.savingsPerMonth || 0,
    };
  }, [
    employee?.socialSecurityNumber,
    employee?.personnelNumber,
    employee?.employeeTypeId,
    employee?.gender,
    employee?.dateOfBirth,
    employee?.phoneNumber,
    employee?.emailAddress,
    employee?.civilStatus,
    employee?.costCenter,
    employee?.personName?.initials,
    employee?.personName?.firstName,
    employee?.personName?.lastName,
    employee?.personName?.lastNamePrefix,
    employee?.address?.street,
    employee?.address?.houseNumber,
    employee?.address?.houseNumberSuffix,
    employee?.address?.zipCode,
    employee?.address?.city,
    employee?.address?.country,
    employee?.profession,
    employee?.savingsPerMonth,
  ]);

  const formikOnSubmit = React.useCallback<FormikConfig<EmployeeDetailsPanelFormValues>['onSubmit']>(
    (values, { setSubmitting, resetForm }) => {
      const method = 'updateEmployee';
      const { address: addressValues } = values;

      if (employee?.connectionId && employee?.employeeId) {
        let houseNumberSuffix;

        if (addressValues?.houseNumberSuffix === '' && employee?.address?.houseNumberSuffix === null) {
          houseNumberSuffix = null;
        } else if (addressValues?.houseNumberSuffix === '' && employee?.address?.houseNumberSuffix === '') {
          houseNumberSuffix = '';
        } else {
          houseNumberSuffix = addressValues?.houseNumberSuffix;
        }

        updateEmployee(
          {
            connectionId: employee?.connectionId,
            employeeId: employee?.employeeId,
            employee: {
              ...values,
              externalId: employee?.externalId,
              employeeTypeId: values.employeeTypeId || null,
              partners: employee?.partners || null,
              address: {
                ...addressValues,
                externalId: employee?.address?.externalId,
                houseNumberSuffix,
              },
              savingsPerMonth: values.savingsPerMonth || null,
            },
          },
          {
            onSuccess: () => {
              setSubmitting(false);
              resetForm();
              setIsEdit(false);
              refetchGetEmployeeQuery();
              showSuccessSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_successMessage }),
              });
            },
            onError: () => {
              setSubmitting(false);
              setIsEdit(false);
              showErrorSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_errorMessage }),
              });
            },
          },
        );
      }
    },
    [
      employee?.address?.externalId,
      employee?.address?.houseNumberSuffix,
      employee?.connectionId,
      employee?.employeeId,
      employee?.externalId,
      employee?.partners,
      intl,
      refetchGetEmployeeQuery,
      showErrorSnakeBar,
      showSuccessSnakeBar,
      updateEmployee,
    ],
  );

  return (
    <Formik<EmployeeDetailsPanelFormValues>
      enableReinitialize
      initialValues={formikInitialValues}
      onSubmit={formikOnSubmit}
    >
      <EmployeeDetailsPanelForm
        synchronizationStatus={synchronizationStatus}
        refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
        employeeId={employee?.employeeId}
        connectionId={employee?.connectionId}
        loading={loading}
        disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading || isUpdatingEmployee}
        isEdit={isEdit}
        employeeTypesLoading={isLoadingEmployeeTypes}
        employeeTypes={employeeTypes}
        setIsEdit={setIsEdit}
      />
    </Formik>
  );
};

export default React.memo(EmployeeDetailsPanel);
