import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Grid, Table, TableBody, TableHead, TableRow } from '@mui/material';

import { isInteger } from 'app/utils';

import type { FilterProps } from '../Filter';
import Filter from '../Filter';
import TableCellHeader from '../TableCellHeader';

import type { FilterableTableRowProps } from './components/FilterableTableRow';
import { FilterableTableRow } from './components/FilterableTableRow';

import { sortableReducer, sortRows } from './FilterableTable.utils';
import { filterableTableColumnActionsStyles, SkeletonStyled } from './FilterableTable.styles';
import type {
  FilterableTableColumnType,
  FilterableTableFilters,
  FilterableTableOnFilterChangeFunc,
  FilterableTableOnSelectAllChangeFunc,
  FilterableTableOnSortChangeFunc,
  FilterableTablePaginationType,
  FilterableTableRowChildMapperFunc,
  FilterableTableRowMapperFunc,
  FilterTableOnPaginationChangeFunc,
} from './FilterableTable.types';
import { Checkbox } from '../Checkbox';
import { Pagination } from '../Pagination';

type FilterableTableCommonProps<TRowData> = {
  rows: TRowData[];
  selectedRowsIds?: string[];
  columns: FilterableTableColumnType[];
  pagination?: FilterableTablePaginationType;
  onPaginationChange?: FilterTableOnPaginationChangeFunc;
  loading?: boolean;
  filters?: FilterableTableFilters;
  onFilterChange?: FilterableTableOnFilterChangeFunc;
  onSortChange?: FilterableTableOnSortChangeFunc;
  onSelectAllChange?: FilterableTableOnSelectAllChangeFunc;
  rowMapper: FilterableTableRowMapperFunc<TRowData>;
  rowChildMapper?: never;
  className?: string;
  minFillSize?: number;
  sort?: 'single' | 'multi';
  hover?: boolean;
  defaultPageSize?: number;
  inMemorySort?: boolean;
  extraFiltersContent?: FilterProps['extraFiltersContent'];
  filterBackground?: boolean;
};

type FilterableTableWithChildrenProps<TRowData, TChildData> = Omit<
  FilterableTableCommonProps<TRowData>,
  'rowMapper' | 'rowChildMapper'
> & {
  rowMapper: FilterableTableRowMapperFunc<TRowData, TChildData>;
  rowChildMapper: FilterableTableRowChildMapperFunc<TChildData>;
};

type FilterableTableProps<TRowData, TChildData = Record<string, any>> =
  | FilterableTableCommonProps<TRowData>
  | FilterableTableWithChildrenProps<TRowData, TChildData>;

export function FilterableTable<TRowData = Record<string, any>, TChildData = Record<string, any>>({
  columns,
  filters,
  pagination,
  rows,
  loading,
  onFilterChange,
  onSortChange,
  onSelectAllChange,
  onPaginationChange,
  rowMapper,
  rowChildMapper,
  className,
  minFillSize,
  extraFiltersContent,
  sort = 'single',
  hover = true,
  defaultPageSize = 10,
  inMemorySort = false,
  selectedRowsIds = [],
  filterBackground = true,
}: FilterableTableProps<TRowData, TChildData>) {
  const [sortOrders, setSortOrders] = React.useState(columns.reduce(sortableReducer, {}));

  const { rowsPerPageOptions, pageSize = defaultPageSize, enabled = true, totalCount = 0, page = 1 } = pagination || {};
  const normalizedRows = React.useMemo(() => Object.values(rows || []), [rows]);

  const renderCell = React.useCallback((column, row) => {
    let value = row.data[column.name] ?? null;
    if (typeof value === 'function') {
      value = value({ column, row: row.data });
    }
    return value;
  }, []);

  const fillData = React.useCallback((numRows, columns, filler) => {
    return new Array(numRows).fill(
      columns.reduce(
        (result: { data: Record<string, any> }, column: FilterableTableColumnType) => {
          if (typeof column.name !== 'undefined') {
            result.data[column.name] = filler;
          }
          return result;
        },
        { data: {} },
      ),
    );
  }, []);

  const rowsToRender = React.useMemo(() => {
    const targetFillSize =
      isInteger(minFillSize) && typeof minFillSize === 'number' ? Math.min(minFillSize, pageSize) : pageSize;

    if (loading) {
      return fillData(targetFillSize, columns, <SkeletonStyled />);
    }

    let rowsToRender = normalizedRows;
    if (inMemorySort) {
      rowsToRender = sortRows(columns, normalizedRows, sortOrders);
    }

    const mappedRows = rowsToRender.map(rowMapper);
    const missingRows = targetFillSize - mappedRows.length;
    if (missingRows > 0) {
      return mappedRows.concat(fillData(missingRows, columns, <SkeletonStyled width={0} />));
    }
    return mappedRows;
  }, [minFillSize, pageSize, loading, normalizedRows, inMemorySort, rowMapper, fillData, columns, sortOrders]);

  const changeSortOrder = React.useCallback(
    ({ name }) => {
      const currentOrder = sortOrders[name];

      if (sort === 'single') {
        Object.keys(sortOrders).forEach(key => (sortOrders[key] = null));
      }
      sortOrders[name] = currentOrder === null ? 'asc' : currentOrder === 'asc' ? 'desc' : null;
      setSortOrders({ ...sortOrders });
      onSortChange && onSortChange(sortOrders);
    },
    [sortOrders, sort, onSortChange],
  );

  const renderedRows = React.useMemo(() => {
    const handleRowClick: FilterableTableRowProps<TRowData, TChildData>['onRowClick'] = (event, row, data) => {
      const onClick = row?.events?.onClick;
      if (typeof onClick === 'function') {
        event.stopPropagation();
        onClick(event, data);
      }
    };

    return (rowsToRender || []).map((row, rowIndex) => (
      <FilterableTableRow
        key={rowIndex}
        hover={hover}
        rowIndex={rowIndex}
        row={row}
        normalizedRows={normalizedRows}
        onRowClick={handleRowClick}
        columns={columns}
        renderCell={renderCell}
        rowChildMapper={rowChildMapper}
      />
    ));
  }, [rowsToRender, hover, columns, normalizedRows, renderCell, rowChildMapper]);

  const onTableHeaderChange = React.useCallback(
    (tableHeaderColumn: FilterableTableColumnType) => {
      if (tableHeaderColumn.isSelectAllColumn && onSelectAllChange) {
        return onSelectAllChange;
      }
      if (tableHeaderColumn.sortable) {
        return changeSortOrder;
      }

      return undefined;
    },
    [changeSortOrder, onSelectAllChange],
  );

  return (
    <Grid container spacing={2} className={className}>
      {filters && !!onFilterChange && (
        <Filter
          onChange={onFilterChange}
          filters={filters}
          extraFiltersContent={extraFiltersContent}
          variant={filterBackground ? undefined : 'outlined'}
        />
      )}
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => {
                    const isSelectAllOrActionsColumn = column?.isActionsColumn || column?.isSelectAllColumn;

                    return (
                      <TableCellHeader
                        arrowPosition={column.sortable ? 'right' : undefined}
                        direction={sortOrders[column.name]}
                        key={index}
                        name={column.name}
                        onChange={onTableHeaderChange(column)}
                        textAlign={column.textAlign}
                        sx={
                          column.styles || (isSelectAllOrActionsColumn ? filterableTableColumnActionsStyles : undefined)
                        }
                      >
                        {column.isSelectAllColumn ? (
                          <Checkbox
                            name={column.name}
                            disabled={loading || rows.length === 0}
                            checked={rows.length > 0 && selectedRowsIds.length === rows.length}
                          />
                        ) : (
                          column.title
                        )}
                      </TableCellHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>{renderedRows}</TableBody>
            </Table>
          </PerfectScrollbar>
        </Grid>
        {enabled && (
          <Grid item xs={12}>
            <Pagination
              count={totalCount}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={rowsPerPageOptions}
              onPaginationChange={onPaginationChange}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
