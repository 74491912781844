import React from 'react';
import { Text } from 'app/components/FormikField';
import PropTypes from 'prop-types';
import { GridStyled } from './EmployeeTypes.styles';

const EmployeeTypeForm = props => {
  const { descriptionCharacterLimit } = props;

  return (
    <GridStyled container spacing={2}>
      <GridStyled item xs={6}>
        <Text
          field={{
            type: 'text',
            name: 'name',
            header: 'owners.settings.employeeType',
            required: true,
          }}
        />
      </GridStyled>
      <GridStyled item xs={12}>
        <Text
          field={{
            type: 'text',
            name: 'description',
            header: 'description',
            multiline: true,
            minRows: 4,
            characterCounterEnabled: true,
            options: {
              max: descriptionCharacterLimit,
            },
          }}
        />
      </GridStyled>
    </GridStyled>
  );
};

EmployeeTypeForm.propTypes = {
  initialValues: PropTypes.object,
};

export default EmployeeTypeForm;
