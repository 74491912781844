import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';

import { TranslationKeys } from '../../../translations';

import { DivRootStyled } from './ListRowNoData.styles';

export const LIST_ROW_NO_DATA_DATA_TEST_ID = 'list-row-no-data-component';

type ListRowNoDataProps = {
  searchQuery?: string;
  innerRow?: boolean;
};

export const ListRowNoData = ({ searchQuery, innerRow = false }: ListRowNoDataProps) => {
  return (
    <DivRootStyled data-testid={LIST_ROW_NO_DATA_DATA_TEST_ID} innerRow={innerRow}>
      <Typography variant={'h6'}>
        {!searchQuery && <FormattedMessage id={TranslationKeys.global_noData} />}
        {!!searchQuery && (
          <FormattedMessage id={TranslationKeys.global_searchNotFound_searchQuery} values={{ searchQuery }} />
        )}
      </Typography>
    </DivRootStyled>
  );
};
