import React from 'react';
import * as Yup from 'yup';
import type { FormikConfig } from 'formik';
import { useDispatch } from 'react-redux';

import type { Owner } from '../../../../types/owner.types';

import { convertFileToBase64 } from '../../../../utils';

import { useSnakeBar } from '../../../../hooks/useSnakeBar';

import { actions as OwnerActions } from '../../Ducks/Owner.duck';

import type { OwnerFormValues } from './OwnerForm.types';

type UseOwnerFormArgs = {
  owner?: Owner;
  afterSubmit?: () => void;
};

export const useOwnerForm = ({ owner, afterSubmit }: UseOwnerFormArgs) => {
  const dispatch = useDispatch();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const formikInitialValues = React.useMemo<OwnerFormValues>(
    () => ({
      ownerId: owner?.ownerId || null,
      ownerName: owner?.ownerName || '',
      ownerEmail: owner?.ownerEmail || '',
      ownerPhone: owner?.ownerPhone || '',
      contactName: owner?.contactName || '',
      contactEmail: owner?.contactEmail || '',
      contactPhone: owner?.contactPhone || '',
      address: owner?.address || '',
      zipCode: owner?.zipCode || '',
      city: owner?.city || '',
      logo: owner?.logo || '',
    }),
    [
      owner?.address,
      owner?.city,
      owner?.contactEmail,
      owner?.contactName,
      owner?.contactPhone,
      owner?.logo,
      owner?.ownerEmail,
      owner?.ownerId,
      owner?.ownerName,
      owner?.ownerPhone,
      owner?.zipCode,
    ],
  );

  const formikValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        ownerName: Yup.string().trim().min(2).max(50).required(),
        ownerEmail: Yup.string().trim().min(2).max(255).email().required(),
        ownerPhone: Yup.string().trim().min(2).max(50).required(),
        contactName: Yup.string().trim().min(2).max(50),
        contactEmail: Yup.string().trim().min(2).max(255).email(),
        contactPhone: Yup.string().trim().min(2).max(50),
        address: Yup.string().trim().max(255),
        zipCode: Yup.string().trim().max(255),
        city: Yup.string().trim().max(255),
      }),
    [],
  );

  const formikOnSubmitEdit = React.useCallback<FormikConfig<OwnerFormValues>['onSubmit']>(
    async (values, { setSubmitting, resetForm, setErrors, setStatus }) => {
      const onSuccess = () => {
        resetForm();
        if (afterSubmit) {
          afterSubmit();
        }
        showSuccessSnakeBar({ method: 'editOwner' });
      };

      const onError = (error: any) => {
        setSubmitting(false);
        if (error.violations) {
          setStatus(error.violations ? null : error.message);
          setErrors(error.violations);
        } else {
          showErrorSnakeBar({ method: 'editOwner' });
        }
      };

      const valuesToSubmit = { ...values };

      if (values.logo && typeof values.logo !== 'string') {
        valuesToSubmit.logo = (await convertFileToBase64(values.logo)) as string;
      }

      // @ts-ignore
      dispatch(OwnerActions.owner.setOwner(valuesToSubmit, onError, onSuccess));
    },
    [afterSubmit, dispatch, showErrorSnakeBar, showSuccessSnakeBar],
  );

  return React.useMemo(
    () => ({
      formikValidationSchema,
      formikInitialValues,
      formikOnSubmitEdit,
    }),
    [formikInitialValues, formikOnSubmitEdit, formikValidationSchema],
  );
};
