import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import endOfDay from 'date-fns/endOfDay';

import { Grid, Typography } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';

import { useDialog, useEmploymentTypeSelectItems, useYesOrNoSelectItems } from 'app/hooks';

import { InputFieldFactory } from 'app/components/FormikField';
import type { GetInputOptionsAndLabelFunc, GetSelectOrAutocompleteDisplayValueFunc } from 'app/components';
import { FormikModal, SubmitConfirmationDialog } from 'app/components';

import type { EmployeeEmploymentDetailsFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/components/EmployeeEmploymentDetails/EmployeeEmploymentDetails.types';
import {
  EmployeeEmploymentDetailsFields,
  EmployeeEmploymentDetailsFormFieldOptionsAndLabel,
} from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/components/EmployeeEmploymentDetails/EmployeeEmploymentDetails.types';

type EmployeeEmploymentFormDialogBodyProps = {
  open: boolean;
  isEdit: boolean;
  closeDialog: () => void;
};

export const EmployeeEmploymentFormDialogBody = ({
  open,
  isEdit,
  closeDialog,
}: EmployeeEmploymentFormDialogBodyProps) => {
  const intl = useIntl();

  const { yesOrNoSelectItems, getYesOrNoDisplayNameByValue } = useYesOrNoSelectItems();
  const { employmentTypeSelectItems, getEmploymentTypeDisplayNameByValue } = useEmploymentTypeSelectItems();
  const { initialValues, values, submitForm, resetForm } = useFormikContext<EmployeeEmploymentDetailsFormValues>();

  const {
    dialogState: submitConfirmationDialogState,
    openDialog: openSubmitConfirmationDialog,
    closeDialog: closeSubmitConfirmationDialog,
  } = useDialog();

  const onClickSubmit = React.useCallback(() => {
    const hasChangedValues = JSON.stringify(initialValues) !== JSON.stringify(values);

    if (!isEdit) {
      submitForm();
    } else if (hasChangedValues) {
      openSubmitConfirmationDialog();
    } else {
      closeDialog();
    }
  }, [closeDialog, initialValues, isEdit, openSubmitConfirmationDialog, submitForm, values]);

  const submitFormAfterConfirmation = React.useCallback(() => {
    closeSubmitConfirmationDialog();
    submitForm();
  }, [closeSubmitConfirmationDialog, submitForm]);

  const getInputOptionsAndLabel = React.useCallback<GetInputOptionsAndLabelFunc>(inputName => {
    return EmployeeEmploymentDetailsFormFieldOptionsAndLabel[inputName];
  }, []);

  const getSelectOrAutocompleteDisplayValue = React.useCallback<GetSelectOrAutocompleteDisplayValueFunc>(
    ({ inputName, inputValue }) => {
      if (inputName === EmployeeEmploymentDetailsFields.EmploymentType.InputName) {
        return getEmploymentTypeDisplayNameByValue(inputValue) || '';
      }
      if (
        [
          EmployeeEmploymentDetailsFields.VacationPay.InputName.toString(),
          EmployeeEmploymentDetailsFields.ExtraMonth.InputName.toString(),
          EmployeeEmploymentDetailsFields.OtherWageComponents.InputName.toString(),
        ].includes(inputName)
      ) {
        return getYesOrNoDisplayNameByValue(inputValue) || '';
      }
      return '';
    },
    [getEmploymentTypeDisplayNameByValue, getYesOrNoDisplayNameByValue],
  );

  return (
    <>
      {submitConfirmationDialogState && (
        <SubmitConfirmationDialog
          open={submitConfirmationDialogState}
          onClose={closeSubmitConfirmationDialog}
          onConfirm={submitFormAfterConfirmation}
          previousValues={initialValues}
          newValues={values}
          getSelectOrAutocompleteDisplayValue={getSelectOrAutocompleteDisplayValue}
          getInputOptionsAndLabel={getInputOptionsAndLabel}
        />
      )}

      {!submitConfirmationDialogState && (
        <FormikModal
          title={
            <FormattedMessage
              id={
                isEdit
                  ? TranslationKeys.employees_actions_editEmployment
                  : TranslationKeys.employees_actions_AddEmployment
              }
            />
          }
          show={open}
          maxWidth={'md'}
          onSubmit={onClickSubmit}
          onCancel={isEdit ? closeDialog : undefined}
          onHide={() => {
            closeDialog();
            resetForm();
          }}
          disableEnforceFocus
        >
          {isEdit && (
            <Typography variant="h6" paragraph>
              {format.date(initialValues.startDate)}
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'date',
                  header: EmployeeEmploymentDetailsFields.StartDate.Label,
                  name: EmployeeEmploymentDetailsFields.StartDate.InputName,
                  options: EmployeeEmploymentDetailsFields.StartDate.Options,
                  disabled: isEdit,
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'select',
                  header: EmployeeEmploymentDetailsFields.EmploymentType.Label,
                  name: EmployeeEmploymentDetailsFields.EmploymentType.InputName,
                  options: EmployeeEmploymentDetailsFields.EmploymentType.Options,
                  items: employmentTypeSelectItems,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'date',
                  header: EmployeeEmploymentDetailsFields.EndDate.Label,
                  name: EmployeeEmploymentDetailsFields.EndDate.InputName,
                  options: EmployeeEmploymentDetailsFields.EndDate.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'date',
                  header: EmployeeEmploymentDetailsFields.ProbationDate.Label,
                  name: EmployeeEmploymentDetailsFields.ProbationDate.InputName,
                  options: EmployeeEmploymentDetailsFields.ProbationDate.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'date',
                  header: EmployeeEmploymentDetailsFields.YearsFrom.Label,
                  name: EmployeeEmploymentDetailsFields.YearsFrom.InputName,
                  options: EmployeeEmploymentDetailsFields.YearsFrom.Options,
                  schema: (schema: any) =>
                    schema.max(
                      endOfDay(new Date()),
                      intl.formatMessage({
                        id: TranslationKeys.validationMessage_date_maxToday,
                      }),
                    ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'autocomplete',
                  header: EmployeeEmploymentDetailsFields.ExtraMonth.Label,
                  name: EmployeeEmploymentDetailsFields.ExtraMonth.InputName,
                  options: EmployeeEmploymentDetailsFields.ExtraMonth.Options,
                  items: yesOrNoSelectItems,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'autocomplete',
                  header: EmployeeEmploymentDetailsFields.VacationPay.Label,
                  name: EmployeeEmploymentDetailsFields.VacationPay.InputName,
                  options: EmployeeEmploymentDetailsFields.VacationPay.Options,
                  items: yesOrNoSelectItems,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'number',
                  header: EmployeeEmploymentDetailsFields.VacationPayPercentage.Label,
                  name: EmployeeEmploymentDetailsFields.VacationPayPercentage.InputName,
                  options: EmployeeEmploymentDetailsFields.VacationPayPercentage.Options,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'autocomplete',
                  header: EmployeeEmploymentDetailsFields.OtherWageComponents.Label,
                  name: EmployeeEmploymentDetailsFields.OtherWageComponents.InputName,
                  options: EmployeeEmploymentDetailsFields.OtherWageComponents.Options,
                  items: yesOrNoSelectItems,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'text',
                  header: EmployeeEmploymentDetailsFields.DeductionPlan.Label,
                  name: EmployeeEmploymentDetailsFields.DeductionPlan.InputName,
                  options: EmployeeEmploymentDetailsFields.DeductionPlan.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'text',
                  header: EmployeeEmploymentDetailsFields.JobDescription.Label,
                  name: EmployeeEmploymentDetailsFields.JobDescription.InputName,
                  options: EmployeeEmploymentDetailsFields.JobDescription.Options,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  type: 'number',
                  header: EmployeeEmploymentDetailsFields.Emoluments.Label,
                  name: EmployeeEmploymentDetailsFields.Emoluments.InputName,
                  options: EmployeeEmploymentDetailsFields.Emoluments.Options,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <InputFieldFactory
              field={{
                type: 'date',
                header: EmployeeEmploymentDetailsFields.CalculationStartDate.Label,
                name: EmployeeEmploymentDetailsFields.CalculationStartDate.InputName,
                options: EmployeeEmploymentDetailsFields.CalculationStartDate.Options,
              }}
            />
          </Grid>

          {!isEdit && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    type: 'number',
                    header: EmployeeEmploymentDetailsFields.GrossWage.Label,
                    name: EmployeeEmploymentDetailsFields.GrossWage.InputName,
                    options: EmployeeEmploymentDetailsFields.GrossWage.Options,
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={{
                    type: 'number',
                    header: EmployeeEmploymentDetailsFields.PartTimePercentage.Label,
                    name: EmployeeEmploymentDetailsFields.PartTimePercentage.InputName,
                    options: EmployeeEmploymentDetailsFields.PartTimePercentage.Options,
                    required: true,
                  }}
                />
              </Grid>
            </Grid>
          )}
        </FormikModal>
      )}
    </>
  );
};
