import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { UnprocessedEvent, UseQueryWrapper } from '../../../types';

import type { GetUnprocessedEventsParams } from '../../../Domain/Dashboard/Services';
import { dashboardService } from '../../../Domain/Dashboard/Services';

import { useSnakeBar } from '../../useSnakeBar';

type GetUnprocessedEventsQueryVariables = GetUnprocessedEventsParams;

type GetUnprocessedEventsQueryResultData = {
  data: Array<UnprocessedEvent>;
  totalCount: number;
};

const queryFn = (variables: GetUnprocessedEventsQueryVariables) => async () => {
  const response = await dashboardService.getUnprocessedEvents(variables);
  return response.data;
};

const QUERY_KEY = 'getUnprocessedEvents';

export const useDashboardGetUnprocessedEventsQuery: UseQueryWrapper<
  GetUnprocessedEventsQueryResultData,
  GetUnprocessedEventsQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetUnprocessedEventsQueryResultData>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
