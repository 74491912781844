import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { UseQueryWrapper } from '../../../types/useQuery.types';

import type { GetReportDataFilterParams } from '../../../Domain/Reports/Services/ReportService';
import { reportService } from '../../../Domain/Reports/Services/ReportService';

import { useSnakeBar } from '../../useSnakeBar';
import type { Report } from '../../../types/report.types';

type GetReportDataFilterQueryResultData = Report;

type GetReportDataFilterQueryVariables = {
  [Key in keyof GetReportDataFilterParams]: GetReportDataFilterParams[Key] | undefined;
};

const queryFn =
  ({ userId }: GetReportDataFilterQueryVariables) =>
  async () => {
    if (userId) {
      const response = await reportService.getReportDataFilter({ userId });
      return (response as AxiosResponse).data;
    }
  };

const QUERY_KEY = 'getReportDataFilter';

export const useReportsGetDataFilterQuery: UseQueryWrapper<
  GetReportDataFilterQueryResultData,
  GetReportDataFilterQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetReportDataFilterQueryResultData>([QUERY_KEY], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
