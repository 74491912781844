import { useMutation } from '@tanstack/react-query';

import type { CreateUserArgs } from '../../../Domain/Users/Services';
import { userService } from '../../../Domain/Users/Services';

const mutationFn = (data: CreateUserArgs) => {
  return userService.createUser(data);
};

export const useUserCreateMutation = () => {
  return useMutation<unknown, unknown, CreateUserArgs>(mutationFn);
};
