import { useMutation } from '@tanstack/react-query';
import type { Connection } from 'app/types';
import mutationsService from '../../../Domain/Connections/Services/MutationsService';

type ResendMutationsData = {
  connectionId: Connection['connectionId'];
  batchId: string;
  externalIds: string[];
};

const mutationFn = ({ connectionId, batchId, externalIds }: ResendMutationsData) => {
  return mutationsService.forceResendMutations(connectionId, batchId, externalIds);
};

export const useConnectionResendMutationsMutation = () => {
  return useMutation<unknown, unknown, ResendMutationsData>(mutationFn);
};
