import { alpha, styled } from '@mui/material/styles';
import { Checkbox as MuiCheckbox, checkboxClasses } from '@mui/material';

export const MuiCheckboxStyled = styled(MuiCheckbox, {
  name: 'MuiCheckboxStyled',
})<{ disabled: boolean }>(({ theme, disabled }) => ({
  [`&.${checkboxClasses.root}`]: {
    '& svg': {
      color: disabled ? alpha(theme.palette.common.black, 0.1) : theme.palette.primary.main,
    },
  },
}));
