import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@mui/material';

import {
  DivRowsContainerStyled,
  GridStyled,
  PaperBackgroundStyled,
  TypographyContainerTitleStyled,
  TypographyRowLabelStyled,
  TypographyRowValueStyled,
} from './SubmitConfirmationDialogDataPanel.styles';
import { RenderFieldFactory } from '../../FormikField';

import type { ChangedValuesData } from '../SubmitConfirmationDialog.types';

type SubmitConfirmationDialogDataPanelProps = {
  title: string;
  values: ChangedValuesData | undefined;
  highlighted?: boolean;
};

export const SubmitConfirmationDialogDataPanel = ({
  title,
  values,
  highlighted = false,
}: SubmitConfirmationDialogDataPanelProps) => {
  return (
    <>
      {values && (
        <PaperBackgroundStyled>
          <TypographyContainerTitleStyled variant="overline">
            <FormattedMessage id={title} />
          </TypographyContainerTitleStyled>

          <DivRowsContainerStyled>
            {Object.keys(values).map(key => {
              const field = values[key];

              return (
                <Grid container spacing={1} key={key}>
                  <Grid item xs={12} sm={5}>
                    <TypographyRowLabelStyled component="span" variant="h6">
                      <FormattedMessage id={field.label} />
                    </TypographyRowLabelStyled>
                  </Grid>

                  <GridStyled item xs={12} sm={7} highlighted={highlighted}>
                    <TypographyRowValueStyled component="span">
                      <RenderFieldFactory
                        field={{
                          variant: 'slim',
                          name: key,
                          value: field.value,
                          options: field.options,
                          type: ['currency', 'percent'].includes(field.options?.style || '') ? 'number' : undefined,
                        }}
                      />
                    </TypographyRowValueStyled>
                  </GridStyled>
                </Grid>
              );
            })}
          </DivRowsContainerStyled>
        </PaperBackgroundStyled>
      )}
    </>
  );
};
