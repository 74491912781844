import { useMutation } from '@tanstack/react-query';
import type { CreateEmployeeNotesParams } from '../../../Domain/Employees/Services/EmployeeService';
import EmployeeService from '../../../Domain/Employees/Services/EmployeeService';

type CreateEmployeeNotesData = CreateEmployeeNotesParams;

const mutationFn = (data: CreateEmployeeNotesData) => {
  return EmployeeService.createEmployeeNotes(data);
};

export const useEmployeeCreateNotesMutation = () => {
  return useMutation<unknown, unknown, CreateEmployeeNotesData>(mutationFn);
};
