import { styled } from '@mui/material/styles';
import { Card, CardActions, CardContent, Typography } from '@mui/material';

export const DivContainerStyled = styled('div', {
  name: 'DivContainerStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

export const CardStyled = styled(Card, {
  name: 'CardStyled',
})(({ theme }) => ({
  width: '345px',
  border: `1px solid ${theme.palette.secondary.main}`,
  transition: 'border 0.3s ease',
  '&:hover': {
    boxShadow: `0px 2px 8px rgba(0, 44, 114, 0.5)`,
  },
}));

export const CardHeaderStyled = styled('div', {
  name: 'CardHeaderStyled',
})(() => ({
  width: '100%',
  height: '120px',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    padding: 20,
  },
}));

export const CardContentStyled = styled(CardContent, {
  name: 'CardContentStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  height: '80px',
  textAlign: 'center',
}));

export const TypographyCardTitleStyled = styled(Typography, {
  name: 'TypographyCardTitleStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const CardActionsStyled = styled(CardActions, {
  name: 'CardActionsStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}));
