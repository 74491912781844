import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { normalizeError } from 'app/utils';
import credentialService, {
  editCredential,
  getCredential,
  getCredentialConnections,
  getCredentials,
  getOwnerCredentialConnections,
  getOwnerCredentials,
} from '../Services/CredentialService';
import { MESSAGE_SEVERITY_ERROR, SUPER_ADMIN_OWNER_ID } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import {
  generateActionTypes as generateSingleActionTypes,
  singleObjectAction,
  singleObjectActions,
  singleObjectInitialState,
} from '../../App/Ducks/SingleObject.duck';
import { createSelector } from 'reselect';
import { onboardingService } from '../../Connections/Services';

export const actionTypes = {
  InitializeCredentialState: '[Credentials] Initialize',
  RequestData: '[Credentials] Request',
  FulfilledTable: '[Credentials] Fulfilled',
  SearchTable: '[Credentials] Search',
  ChangePage: '[Credentials] change page',
  SetPageSize: '[Credentials] set page size',
  SortTable: '[Credentials] Sort',
  ApplyFilter: '[Credentials] Apply filter',
  RemoveFilter: '[Credentials] Remove filter',
  SetCredentialId: '[Credentials] Set credential Id',
  RequestConnections: '[Credentials] Request connections',
  SetConnections: '[Credentials] Set connections',
  OauthStatus: '[Credentials] Oauth status',
  RemoveOauthStatus: '[Credentials] Remove Oauth status',
  RequestCredential: '[Credentials] Request credential id',
  FulfilledCredential: '[Credentials] Fulfilled new credential',
  SetSelectedPackage: '[Credentials] Set Selected Package',
  CreateCredential: '[Credentials] Create credential',
  EditCredential: '[Credentials] Edit credential',
  ClearAll: '[Credentials] Clear all',
  CredentialDetails: generateSingleActionTypes('Credentials.CredentialDetails'),
  AvailablePackages: generateSingleActionTypes('Credentials.AvailablePackages'),
};

const initialState = {
  ...tableInitialState,
  credentialId: null,
  credentialAccess: null,
  oauthStatus: null,
  credential: null,
  packageType: null,
  loading: false,
  availablePackages: { ...singleObjectInitialState },
  credentialDetails: { ...singleObjectInitialState },
};

export const persistConfig = { storage, key: 'credential', whitelist: ['oauthStatus'] };

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
  if (action.type.startsWith('[Credentials.AvailablePackages]')) {
    return {
      ...state,
      availablePackages: singleObjectAction(actionTypes.AvailablePackages, state.availablePackages, action),
    };
  } else if (action.type.startsWith('[Credentials.CredentialDetails]')) {
    return {
      ...state,
      credentialDetails: singleObjectAction(actionTypes.CredentialDetails, state.credentialDetails, action),
    };
  }

  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.InitializeCredentialState: {
      return { ...initialState };
    }
    case actionTypes.SetCredentialId: {
      return {
        ...state,
        credentialId: action.credentialId,
        credentialAccess: null,
      };
    }
    case actionTypes.RequestCredential: {
      return {
        ...state,
      };
    }
    case actionTypes.FulfilledCredential: {
      return {
        ...state,
        credential: action.credential,
      };
    }
    case actionTypes.SetSelectedPackage: {
      return {
        ...state,
        packageType: action.packageType,
      };
    }
    case actionTypes.ClearAll: {
      return {
        ...state,
        packageType: null,
        newCredential: null,
      };
    }
    case actionTypes.RequestConnections: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.SetConnections: {
      return {
        ...state,
        credentialAccess: action.credentialAccess,
        loading: false,
      };
    }
    case actionTypes.OauthStatus: {
      const status = { ...state.oauthStatus };
      Object.keys(action.status).map(key => {
        status[key] = action.status[key];
        return null;
      });
      return {
        ...state,
        oauthStatus: status,
      };
    }
    case actionTypes.RemoveOauthStatus: {
      return {
        ...state,
        oauthStatus: {},
      };
    }
    case actionTypes.CreateCredential: {
      return {
        ...state,
      };
    }
    case actionTypes.EditCredential: {
      return {
        ...state,
      };
    }
    default:
      return newState;
  }
});

export const actions = {
  ...tableActions(actionTypes),
  availablePackages: singleObjectActions(actionTypes.AvailablePackages),
  credentialDetails: singleObjectActions(actionTypes.CredentialDetails),

  initializeCredentialsState: () => ({
    type: actionTypes.InitializeCredentialState,
  }),
  setCredentialId: credentialId => ({
    type: actionTypes.SetCredentialId,
    credentialId,
  }),
  requestCredential: oldCredential => ({
    type: actionTypes.RequestCredential,
    oldCredential,
  }),
  fulfilledCredential: credential => ({
    type: actionTypes.FulfilledCredential,
    credential,
  }),
  setSelectedPackage: packageType => ({
    type: actionTypes.SetSelectedPackage,
    packageType,
  }),
  clearAll: () => ({
    type: actionTypes.ClearAll,
  }),
  requestConnections: credentialId => ({
    type: actionTypes.RequestConnections,
    credentialId,
  }),
  setConnections: credentialAccess => ({
    type: actionTypes.SetConnections,
    credentialAccess,
  }),
  oauthStatus: status => ({
    type: actionTypes.OauthStatus,
    status,
  }),
  removeOauthStatus: () => ({
    type: actionTypes.RemoveOauthStatus,
  }),
  createCredential: values => ({
    type: actionTypes.CreateCredential,
    values,
  }),
  editCredential: values => ({
    type: actionTypes.EditCredential,
    values,
  }),
};

function* fetchCredentialConnections() {
  try {
    const currentState = yield select(state => {
      return state.CredentialsReducer;
    });

    const ownerId = yield select(state => {
      return state.AppReducer.environment.ownerId;
    });
    const response =
      ownerId === SUPER_ADMIN_OWNER_ID
        ? yield getCredentialConnections(currentState.credentialId)
        : yield getOwnerCredentialConnections(currentState.credentialId, ownerId);

    yield put(actions.setConnections(response.data['hydra:member']));
  } catch (error) {
    const severity = MESSAGE_SEVERITY_ERROR;
    const details = {
      method: 'getCredentialConnections',
      severity,
    };
    yield put(AppActions.displayMessage(details));
  }
}

export const selectors = {
  selectAvailablePackages: createSelector(
    state => state.availablePackages,
    availablePackages => availablePackages,
  ),
  selectCredentialDetails: createSelector(
    state => state.credentialDetails,
    credentialDetails => credentialDetails,
  ),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestCredential, function* requestCredentialSaga(action) {
    try {
      const response = yield getCredential(action.oldCredential.credentialId);
      yield put(actions.setSelectedPackage(action.oldCredential.packageType));
      yield put(actions.fulfilledCredential(response));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getCredential',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.CreateCredential, function* createCredentialSaga(action) {
    try {
      yield credentialService.createCredential(action.values);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'createCredential',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
    yield put(actions.requestData());
    yield put(actions.clearAll());
  });

  yield takeLatest(actionTypes.EditCredential, function* editCredentialSaga(action) {
    try {
      yield editCredential(action.values);
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'editCredential',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
    yield put(actions.requestData());
    yield put(actions.clearAll());
  });

  yield takeLatest(actionTypes.AvailablePackages.RequestData, function* requestAvailablePackagesSaga({ payload }) {
    const { packages, onError, onSuccess } = payload;
    try {
      const response = yield call(onboardingService.getAvailablePackages, packages);
      yield put(actions.availablePackages.fulfilledData(response.data['hydra:member']));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.availablePackages.error(error));
      yield put(
        AppActions.displayError({
          method: 'getAvailablePackages',
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.CredentialDetails.RequestData, function* requestCredentialDetailsSaga({ payload }) {
    const { packageType, onError, onSuccess } = payload;
    try {
      const response = yield call(onboardingService.getNewCredentialDetails, packageType);
      yield put(actions.credentialDetails.fulfilledData(response.data));
      yield onSuccess && onSuccess(response.data);
    } catch (error) {
      const errorData = normalizeError(error);
      yield put(actions.credentialDetails.error(error));
      yield put(
        AppActions.displayError({
          method: 'getCredentialDetails',
        }),
      );
      yield onError && onError(errorData);
    }
  });

  yield takeLatest(actionTypes.RequestData, function* requestCredentials() {
    try {
      const currentState = yield select(state => {
        return state.CredentialsReducer;
      });

      const ownerId = yield select(state => {
        return state.AppReducer.environment.ownerId;
      });
      const response =
        ownerId === SUPER_ADMIN_OWNER_ID
          ? yield getCredentials(
              currentState.page,
              currentState.pageSize,
              currentState.sortBy,
              currentState.filters,
              currentState.searchQuery,
            )
          : yield getOwnerCredentials(
              currentState.page,
              currentState.pageSize,
              currentState.sortBy,
              currentState.filters,
              currentState.searchQuery,
              ownerId,
            );

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getCredentials',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }

    yield takeLatest(actionTypes.RequestConnections, fetchCredentialConnections);
  });
}
