import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import type { SxProps } from '@mui/material';
import { Skeleton } from '@mui/material';

export const filterableTableColumnActionsStyles: SxProps<Theme> = theme => ({
  width: theme.spacing(5),
  paddingLeft: 0,
  paddingRight: 0,
});

export const SkeletonStyled = styled(Skeleton, {
  name: 'SkeletonStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));
