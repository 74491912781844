import { debounce, put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import employerService from '../Services/EmployerService';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload } from '../../../utils';

export const actionTypes = {
  InitializeEmployersState: '[Employers] Initialize state',
  RequestData: '[Employers] Request',
  FulfilledTable: '[Employers] Fulfilled',
  SearchTable: '[Employers] Search',
  ChangePage: '[Employers] change page',
  SetPageSize: '[Employers] set page size',
  SortTable: '[Employers] Sort',
  ApplyFilter: '[Employers] Apply filter',
  RemoveFilter: '[Employers] Remove filter',
  ToggleEmployer: '[Employers] Toggle employer',
  ToggleAllEmployers: '[Employers] Toggle all employers',
  SetSelectedEmployer: '[Employers] Set selected employer',
  ClearAll: '[Employers] Clear all',
  CreateEmployer: '[Employers] Create new employer',
  EditEmployer: '[Employers] Edit new employer',
  DeleteEmployer: '[Employers] Delete employer',
  RequestUnfilteredTotalCount: '[Employers] Request Total count',
  SetUnfilteredTotalCount: '[Employers] Set Total Count',
  SetEnvironmentTotalCount: '[Employers] Set Total Count',
};

const initialState = {
  ...tableInitialState,
  selectedEmployer: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.InitializeEmployersState: {
      return { ...initialState };
    }
    case actionTypes.ToggleEmployer: {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.employerId === action.event.employerId) {
            item.selectedRow = !item.selectedRow;
          }
          return item;
        }),
      };
    }
    case actionTypes.ToggleAllEmployers: {
      const isSelectAll = action.events.filter(item => !item.selectedRow).length;
      return {
        ...state,
        items: action.events.map(item => {
          item.selectedRow = !!isSelectAll;
          return item;
        }),
      };
    }
    case actionTypes.SetSelectedEmployer: {
      return {
        ...state,
        selectedEmployer: action.selectedEmployer,
      };
    }
    case actionTypes.CreateEmployer: {
      return {
        ...state,
      };
    }
    case actionTypes.EditEmployer: {
      return {
        ...state,
      };
    }
    case actionTypes.ClearAll: {
      return {
        ...state,
        selectedEmployer: null,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initializeEmployersState: () => ({
    type: actionTypes.InitializeEmployersState,
  }),
  toggleEmployer: event => {
    return {
      type: actionTypes.ToggleEmployer,
      event,
    };
  },
  toggleAllEmployers: events => ({
    type: actionTypes.ToggleAllEmployers,
    events,
  }),
  setSelectedEmployer: selectedEmployer => ({
    type: actionTypes.SetSelectedEmployer,
    selectedEmployer,
  }),
  clearAll: () => ({
    type: actionTypes.ClearAll,
  }),
  createEmployer: employerValues => ({
    type: actionTypes.CreateEmployer,
    employerValues,
  }),
  editEmployer: employerValues => ({
    type: actionTypes.EditEmployer,
    employerValues,
  }),
  deleteEmployer: employerId => ({
    type: actionTypes.DeleteEmployer,
    employerId,
  }),
  requestEmployersTotalCount: () => ({
    type: actionTypes.RequestEmployersTotalCount,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }
  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.ChangePage, function* changeEmployersPage(action) {
    if (action.reloadData) {
      yield reloadData();
    }
  });

  yield takeLatest(actionTypes.SetPageSize, function* setEmployersPageSize(action) {
    if (action.reloadData) {
      yield reloadData();
    }
  });

  yield takeLatest(actionTypes.CreateEmployer, function* createEmployerSaga(action) {
    yield employerService.createEmployer(action.employerValues);
    yield reloadData();
  });

  yield takeLatest(actionTypes.EditEmployer, function* editEmployerSaga(action) {
    yield employerService.editEmployer(action.employerValues);
  });

  yield takeLatest(actionTypes.RequestData, function* requestEmployers() {
    try {
      const currentState = yield select(state => {
        return state.EmployersReducer;
      });

      const userId = yield select(state => {
        return state.AppReducer.user.userId;
      });

      const params = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload({
        page: currentState.page,
        pageSize: currentState.pageSize,
        filters: { employer: currentState.searchQuery },
        sortBy: currentState.sortBy,
      });

      const response = yield employerService.getUserEmployers({ params, userId });

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getEmployers',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.RequestData, function* requestUnfilteredTotalCount() {
    try {
      const userId = yield select(state => {
        return state.AppReducer.user.userId;
      });

      const response = yield employerService.getOwnerEmployersTotalCount(userId);
      yield put(actions.setUnfilteredTotalCount(response.data));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getEmployersTotalCount',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.DeleteEmployer, function* deleteEmployerSaga(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;

    try {
      const response = yield employerService.deleteEmployer(action.employerId);

      if (response.status === 204) {
        yield put(actions.requestData());
      }
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    const details = {
      method: 'deleteEmployers',
      severity,
    };
    yield put(AppActions.displayMessage(details));
  });
}
