import type { Dictionary } from 'app/utils';
import { isInteger, isNil, isNumeric } from 'app/utils';

export type NumericInputHelperOptions = Intl.NumberFormatOptions & {
  min?: number | string;
  max?: number | string;
  openScale?: boolean;
  fixedDecimalScale?: boolean;
};

class NumericInputHelper {
  private readonly min: null | number;
  private readonly max: null | number;

  constructor(private readonly locale: string, private readonly options: NumericInputHelperOptions) {
    this.locale = locale;
    this.options = options || {};

    this.min = isNil(options?.min) ? null : parseFloat(`${options?.min}`) - Number.EPSILON;
    this.max = isNil(options?.max) ? null : parseFloat(`${options?.max}`) + Number.EPSILON;
    if (this.min != null && this.max != null && this.max < this.min) {
      const tmp = this.max;
      this.max = this.min;
      this.min = tmp;
    }
  }

  public format = (value: string | number): string => {
    if (!isNumeric(value)) {
      return '';
    }

    let parsedValue = this.getValue(value);
    if (typeof parsedValue === 'string') {
      return parsedValue;
    }

    if (this.isPercent()) {
      parsedValue = parsedValue / 100;
    }

    return parsedValue.toLocaleString(this.locale, {
      ...this.getStyleProps(),
      ...this.options,
    });
  };

  public getProps = (): Dictionary<any> => {
    const { displayBefore, symbol } = this.getSymbolAndPosition();
    return {
      prefix: displayBefore && symbol ? `${symbol} ` : '',
      suffix: !displayBefore && symbol ? ` ${symbol}` : '',
      decimalScale: this.getDecimalScale(),
      thousandSeparator: this.getSeparationCharacter(),
      decimalSeparator: this.getDecimalCharacter(),
      fixedDecimalScale: this.getFixedDecimalScale(),
      isAllowed: this.isAllowed,
      valueIsNumericString: true,
    };
  };

  public getValue = (value: string | number): string | number => {
    // we might receive as value a string representing a number that should be parsed if it's numeric
    let returnValue = value;
    if (typeof returnValue === 'string') {
      if (isNumeric(returnValue)) {
        if (isInteger(returnValue)) {
          returnValue = parseInt(returnValue);
        } else {
          returnValue = parseFloat(returnValue);
        }
      } else {
        returnValue = '';
      }
    }
    return returnValue;
  };

  private getSymbolAndPosition = (): Dictionary<any> => {
    const symbol = (0).toLocaleString(this.locale, {
      ...this.getStyleProps(),
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return {
      displayBefore: symbol.indexOf('0') !== 0,
      symbol: symbol.replaceAll(/\d/g, ''),
    };
  };

  private getDecimalScale = (): number | undefined => {
    if (!isNil(this.options?.openScale)) {
      return undefined;
    }

    if (!isNil(this.options?.maximumFractionDigits)) {
      return this.options.maximumFractionDigits;
    }

    if (!isNil(this.options?.minimumFractionDigits)) {
      return this.options.minimumFractionDigits;
    }

    const decimalTest = new Intl.NumberFormat(this.locale, {
      ...this.getStyleProps(),
      signDisplay: 'never',
      currencyDisplay: 'code',
    })
      .format(1)
      .substring(4);

    return (decimalTest.match(/(0)/g) || []).length;
  };

  private getDecimalCharacter = (): string => {
    return (0)
      .toLocaleString(this.locale, {
        ...this.getStyleProps(),
        currencyDisplay: 'code',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })
      .replace(this.getSeparationSearchValue(), '')
      .replaceAll(/(\d)*/g, '')
      .trim()[0];
  };

  private getSeparationCharacter = (): string => {
    const hasSeparation = this.isCurrency() || this.isPercent();
    if (!hasSeparation) {
      return '';
    }

    return (1000)
      .toLocaleString(this.locale, {
        ...this.getStyleProps(),
        currencyDisplay: 'code',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(this.getSeparationSearchValue(), '')
      .replaceAll(/(\d)*/g, '')
      .trim()[0];
  };

  private getSeparationSearchValue = () => {
    switch (this.getStyle()) {
      case 'currency':
        return this.getCurrency();
      case 'percent':
        return '%';
      default:
        return '';
    }
  };

  private getFixedDecimalScale = (): boolean => {
    if (!isNil(this.options.fixedDecimalScale)) {
      return this.options.fixedDecimalScale!;
    }
    return (this.getDecimalScale() ?? 0) > 0;
  };

  private getStyleProps = (): Dictionary<any> => {
    if (this.isPercent()) {
      return {
        style: this.getStyle(),
        maximumFractionDigits: this.options.maximumFractionDigits ?? 5,
      };
    }

    if (this.isCurrency()) {
      return {
        style: this.getStyle(),
        currency: this.getCurrency(),
        currencyDisplay: this.getCurrencyDisplay(),
      };
    }

    return {
      style: this.getStyle(),
    };
  };

  private getCurrency = (): string => {
    return this.options?.currency ?? 'EUR';
  };

  private getCurrencyDisplay = (): string => {
    return this.options?.currencyDisplay ?? 'symbol';
  };

  private isAllowed = ({ floatValue }: Dictionary<number>): any => {
    if (this.min !== null && floatValue < this.min) {
      return false;
    }

    return !(this.max !== null && floatValue > this.max);
  };

  private getStyle = (): string => {
    return this.options.style ?? 'decimal';
  };

  private isPercent = (): boolean => {
    return this.getStyle() === 'percent';
  };

  private isCurrency = (): boolean => {
    return this.getStyle() === 'currency';
  };
}

export default NumericInputHelper;
