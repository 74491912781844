import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DivContentStyled = styled('div', {
  name: 'DivContentStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const CardStyled = styled(Card, {
  name: 'CardStyled',
  shouldForwardProp: (propName: string) => !['disabled'].includes(propName),
})<{ disabled?: boolean }>(({ theme, disabled = false }) => ({
  backgroundColor: theme.palette.primary.light,
  marginBottom: theme.spacing(2),
  textAlign: 'center',

  opacity: disabled ? 0.5 : undefined,
  pointerEvents: disabled ? 'none' : undefined,

  '&:hover': {
    outline: disabled ? 'none' : `1px solid ${theme.palette.primary.main}`,
  },
}));

export const FontAwesomeIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconStyled',
})(({ theme }) => ({
  fontSize: '32px',
  marginBottom: theme.spacing(2),
}));

export const TypographyStyled = styled(Typography, {
  name: 'TypographyStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
