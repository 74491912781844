import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import MessageTypeIcon from '../../../../../../components/MessageTypeIcon';
import { processesService } from '../../../../Services';
import { IntlMessage } from '../../../../../../components/Intl';

const ProcessMessagesDialog = props => {
  const { open, batchId, handleClose } = props;

  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const getMessages = useCallback(async () => {
    const response = await processesService.getProcessItem(batchId);
    setMessages(response.data.messages);
    setLoading(false);
  }, [batchId]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="md" aria-labelledby="process-messages-dialog">
      <DialogTitle id="process-messages-dialog">
        <FormattedMessage id="connection.processes.errorMessages.dialogTitle" />
      </DialogTitle>
      <DialogContent>
        {loading && <LinearProgress />}
        <List>
          {(messages || []).map(message => (
            <ListItem key={message.description}>
              <ListItemIcon>
                <MessageTypeIcon messageType={message.messageType} />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id={`connection.processes.errorMessages.${message.messageType}`} />}
                secondary={message.description}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => handleClose()} color="secondary">
          <IntlMessage value={'close'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProcessMessagesDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  batchId: PropTypes.string,
};

export default ProcessMessagesDialog;
