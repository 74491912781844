import axios from 'axios';

import type { Insurer, Owner, RequestFiltersParams } from 'app/types';

import { backgroundAxios } from 'app/utils/api';
import { convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload } from 'app/utils';

export type GetInsurersArgs = RequestFiltersParams;

const getInsurers = async (args?: GetInsurersArgs) => {
  const { searchQuery, ...params } = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(args || {});

  return axios.get(`/insurers`, {
    params: {
      ...(params || {}),
      insurerName: searchQuery,
    },
  });
};

export type GetOwnerInsurersArgs = Pick<RequestFiltersParams, 'page' | 'pageSize'> & {
  ownerId: Owner['ownerId'];
};

const getOwnerInsurers = async ({ ownerId, ...args }: GetOwnerInsurersArgs) => {
  const params = convertPaginationFilterOrderAndSearchFromReduxStateToRequestPayload(args || {});

  return axios.get(`/owners/${ownerId}/insurers`, {
    params: {
      ...(params || {}),
    },
  });
};

const getInsurer = async (insurerId: Insurer['insurerId']) => {
  return axios.get(`/insurers/${insurerId}`);
};

export const getInsurersTotalCount = async () => {
  return axios.get('/insurers/total-count');
};

const getOwnerInsurerContacts = async (
  ownerId: Owner['ownerId'],
  insurerId: Insurer['insurerId'],
  values?: Record<string, unknown>,
) => {
  return axios.get(`owners/${ownerId}/insurers/${insurerId}/contacts`, { params: { ...(values || {}) } });
};

const getInsurerProducts = async (insurerId: Insurer['insurerId'], values?: Record<string, unknown>) => {
  return backgroundAxios.get(`/insurers/${insurerId}/products`, { params: { ...(values || {}) } });
};

const createInsurer = async (values?: Record<string, unknown>) => {
  return axios.post('/employers', { ...(values || {}) });
};

const editInsurer = async (values: Record<string, unknown>) => {
  return axios.put(`/insurers/${values.insurerId}`, { ...(values || {}) });
};

const editInsurerOwner = async (
  ownerId: Owner['ownerId'],
  insurerId: Insurer['insurerId'],
  values?: Record<string, unknown>,
) => {
  return axios.put(`/owners/${ownerId}/insurers/${insurerId}`, { ...values });
};

const deleteInsurer = async (insurerId: Insurer['insurerId']) => {
  return axios.delete(`/insurers/${insurerId}`);
};

const insurerService = {
  editInsurer,
  editInsurerOwner,
  getInsurer,
  getInsurers,
  getOwnerInsurers,
  createInsurer,
  getInsurerProducts,
  getOwnerInsurerContacts,
  deleteInsurer,
};

export default insurerService;
