import React from 'react';
import { FormattedDate, FormattedRelativeTime, FormattedTime } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';

import type { ConnectionEvent } from 'app/types';

export const formatDate = (value: any) => {
  return value && <FormattedDate value={value} />;
};

export const formatTime = (value: any) => {
  return value && <FormattedTime value={value} />;
};

export const formatDateTime = (value: any) => {
  return (
    value && (
      <>
        {formatDate(value)} {formatTime(value)}
      </>
    )
  );
};

export const formatTimestamp = (value: any) => {
  const date = new Date(value * 1000);

  return (
    value && (
      <>
        {formatDate(date)} {formatTime(date.getTime())}
      </>
    )
  );
};

export const formatRelativeTime = (date: any) => {
  if (!date) {
    return date;
  }
  const { value, unit } = selectUnit(new Date(date));
  return <FormattedRelativeTime value={value} unit={unit} />;
};

//get time without getting effected by timezone
export const systemDate = (value: any) => {
  return value && <>{value.split('-').reverse().join('/')}</>;
};

export const formatEvent = (event: ConnectionEvent) => {
  if (!event || !event.eventDate) {
    return '';
  }

  return formatDate(event.eventDate);
};
