import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ButtonStyled = styled(Button, {
  name: 'ButtonStyled',
  shouldForwardProp: (propName: string) => !['hasHeader'].includes(propName),
})<{ hasHeader: boolean }>(({ hasHeader }) => {
  if (hasHeader) {
    return { display: 'block' };
  }
  return {};
});

export const FontAwesomeIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
