import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

export const LinearProgressStyled = styled(LinearProgress, {
  name: 'LinearProgressStyled',
})(() => ({
  marginTop: '-6px',
  height: '2px',
  width: 'calc(100% - 6px)',
  marginLeft: '3px',
}));
