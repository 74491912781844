import { useEffect } from 'react';
import { useStoreSelector } from '../../store';

const FreshdeskWidgetComponent = () => {
  const loggedInUser = useStoreSelector(state => state.AppReducer.user);

  useEffect(() => {
    // Function to load the Freshworks widget script
    const loadScript = (src, position, id) => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.setAttribute('async', '');
        script.setAttribute('defer', '');
        script.id = id;
        position.appendChild(script);
      }
    };

    // Initialize Freshworks Widget
    window.fwSettings = {
      widget_id: 202000000921,
      locale: loggedInUser.language,
    };

    // eslint-disable-next-line no-unused-expressions
    !(function () {
      if ('function' !== typeof window.FreshworksWidget) {
        const n = function () {
          n.q.push(arguments);
        };
        n.q = [];
        window.FreshworksWidget = n;
      }
    })();

    // Load the Freshworks widget script
    loadScript('https://euc-widget.freshworks.com/widgets/202000000921.js', document.body, 'freshworks-widget-script');

    // Identify the user (this can be dynamic)
    window.FreshworksWidget('identify', 'ticketForm', {
      name: loggedInUser?.personName.fullName,
      email: loggedInUser?.emailAddress,
    });

    window.FreshworksWidget('disable', 'ticketForm', ['name', 'email']);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const script = document.getElementById('freshworks-widget-script');
      if (script) {
        script.remove();
      }
      // Optionally reset the FreshworksWidget variable
      window.FreshworksWidget = undefined;
    };
  }, [
    loggedInUser?.emailAddress,
    loggedInUser.fullName,
    loggedInUser.language,
    loggedInUser.name,
    loggedInUser?.personName.fullName,
  ]);

  return null; // This component doesn't render anything itself
};

export default FreshdeskWidgetComponent;
