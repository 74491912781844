const messages = {
  processSelectedEvents: {
    success: 'connection.event.processSelectedEvents.successMessage',
    error: 'connection.event.processSelectedEvents.errorMessage',
  },
  downloadSelectedEvents: {
    error: 'connection.event.downloadSelectedEvents.errorMessage',
  },
  downloadEvents: {
    error: 'connection.batch.downloadEvents.errorMessage',
  },
  unArchiveSelectedEvents: {
    success: 'connection.event.unArchiveSelectedEvents.successMessage',
    error: 'connection.event.unArchiveSelectedEvents.errorMessage',
  },
  archiveSelectedEvents: {
    success: 'connection.event.archiveSelectedEvents.successMessage',
    error: 'connection.event.archiveSelectedEvents.errorMessage',
  },
  archiveSamePeriodEvents: {
    success: 'connection.event.archiveSamePeriodEvents.successMessage',
    error: 'connection.event.archiveSamePeriodEvents.errorMessage',
  },
  approveProcess: {
    success: 'connection.batch.approveProcess.successMessage',
    error: 'connection.batch.approveProcess.errorMessage',
  },
  declineProcess: {
    success: 'connection.batch.declineProcess.successMessage',
    error: 'connection.batch.declineProcess.errorMessage',
  },
  forceRun: {
    success: 'connection.run.started',
    error: 'connection.run.failed',
    warning: 'connection.run.inProgress',
  },
  editUser: {
    success: 'users.update.successMessage',
    error: 'users.update.errorMessage',
  },
  editCredential: {
    success: 'credentials.update.successMessage',
    error: 'credentials.update.errorMessage',
  },
  getBatches: {
    error: 'connectionDetails.batches.failed',
  },
  getTasks: {
    error: 'connections.tasks.failed',
  },
  getEvents: {
    error: 'connection.event.failed',
  },
  getLogs: {
    error: 'connectionDetails.logs.failed',
  },
  getModules: {
    error: 'connections.tasks.module.failed',
  },
  getConnection: {
    error: 'connection.failed',
  },
  getConnections: {
    error: 'connections.failed',
  },
  getLogsAndEventsCount: {
    error: 'connections.logsAndEventCount.failed',
  },
  getAvailableCredentials: {
    error: 'onboarding.availableCredentials.failed',
  },
  getPackageTypes: {
    error: 'onboarding.packageTypes.failed',
  },
  getAvailablePackages: {
    error: 'onboarding.availablePackages.failed',
  },
  getPackageRelations: {
    error: 'onboarding.packageRelations.failed',
  },
  getCredentialForPackageType: {
    error: 'onboarding.credentialsForPackageType.failed',
  },
  getSettingsForPackageType: {
    error: 'onboarding.settingsForPackageType.failed',
  },
  getNewCredentialDetails: {
    error: 'onboarding.newCredentialDetails.failed',
  },
  getCredentialRequests: {
    error: 'credentialRequests.failed',
  },
  createCredential: {
    error: 'credential.addCredential.failed',
  },
  getCredential: {
    error: 'credential.failed',
  },
  getCredentials: {
    error: 'credentials.failed',
  },
  getCredentialConnections: {
    error: 'credential.connections.failed',
  },
  getUsers: {
    error: 'users.failed',
  },
  checkDownload: {
    error: 'connectionDetails.logEntries.download.failed',
  },
  getEventsForBatch: {
    error: 'connection.batch.getEvents.errorMessage',
  },
  unknown: {
    error: 'unknownErrorMessage',
  },
  clearTasks: {
    error: 'connections.tasks.clear.failed',
  },
  clearLogs: {
    error: 'connectionDetails.clearLogs.failed',
  },
  purgeConnection: {
    error: 'connection.purge.failed',
    success: 'connection.purge.success',
  },
  uploadFromFile: {
    error: 'upload.file.errorMessage',
    success: 'upload.file.successMessage',
  },
  access: {
    error: 'access.accessDenied',
  },
  deleteUsers: {
    error: 'users.delete.errorMessage',
    success: 'users.delete.successMessage',
  },
  createEmployer: {
    error: 'employers.add.failed',
  },
  editEmployer: {
    success: 'employers.update.successMessage',
    error: 'employers.update.errorMessage',
  },
  archiveNewEmployeeEvents: {
    success: 'global.update.successMessage',
    error: 'global.update.errorMessage',
  },
  deleteEmployer: {
    error: 'employers.delete.errorMessage',
    hasSubEmployers: 'employers.error.hasSubEmployers',
  },
  createOwner: {
    error: 'owners.add.failed',
  },
  editOwner: {
    success: 'owners.update.successMessage',
    error: 'owners.update.errorMessage',
  },
  deleteOwner: {
    error: 'owners.delete.errorMessage',
    hasSubOwners: 'owners.error.hasSubOwners',
    cannotDeleteSelf: 'owners.error.cannotDeleteSelf',
  },
  getOwnerEmployers: {
    error: 'owners.error.getEmployers',
  },
  createInsurer: {
    error: 'insurers.add.failed',
  },
  editInsurer: {
    success: 'insurers.update.successMessage',
    error: 'insurers.update.errorMessage',
  },
  deleteInsurer: {
    error: 'insurers.delete.errorMessage',
  },
  getInsurerContacts: {
    error: 'insurers.contactPersons.errorMessage',
  },
  getInsurerOwner: {
    error: 'insurers.owner.errorMessage',
  },
  deleteEmployeeType: {
    error: 'owners.settings.employeeTypes.delete.errorMessage',
    success: 'owners.settings.employeeTypes.delete.successMessage',
  },
  editEmployeeType: {
    error: 'owners.settings.employeeTypes.edit.errorMessage',
    success: 'owners.settings.employeeTypes.edit.successMessage',
  },
  addEmployeeType: {
    error: 'owners.settings.employeeTypes.add.errorMessage',
    success: 'owners.settings.employeeTypes.add.successMessage',
  },
  addEmployees: {
    error: 'employees.add.errorMessage',
    success: 'employees.add.successMessage',
  },
};

export type MessagesType = typeof messages;

export default messages;
