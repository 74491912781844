import { alpha, styled } from '@mui/material/styles';
import { FormLabel, IconButton, LinearProgress } from '@mui/material';

export const FormLabelStyled = styled(FormLabel, {
  name: 'FormLabelStyled',
  shouldForwardProp: (propName: string) => !['disabled'].includes(propName),
})<{ disabled: boolean }>(({ theme, disabled }) => ({
  display: 'flex',
  color: theme.palette.primary.main,
  ...(disabled ? { color: 'rgba(0, 0, 0, 0.26)' } : {}),
}));

export const IconButtonStyled = styled(IconButton, {
  name: 'IconButtonStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(1),
  padding: theme.spacing(0, 0, 0.25, 0),
  fontSize: '1rem',
}));

export const LinearProgressStyled = styled(LinearProgress, {
  name: 'LinearProgressStyled',
})(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.4),
  height: theme.spacing(0.125),
}));
