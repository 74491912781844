import { styled } from '@mui/material/styles';
import { Avatar, Typography } from '@mui/material';

export const TypographyStyled = styled(Typography, {
  name: 'TypographyStyled',
})(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '1rem',
  paddingLeft: theme.spacing(2),
}));

export const AvatarStyled = styled(Avatar, {
  name: 'AvatarStyled',
})(() => ({
  fontSize: '1rem',
  height: 30,
  width: 30,
}));
