import React from 'react';
import { Form, useFormikContext } from 'formik';

import { TranslationKeys } from '../../translations';

import ActionButton from '../ActionButton';

import { AlertStyled, SubmitActionButtonStyled } from './FormikModal.styles';
import type { DialogProps } from '../Dialog';
import { Dialog } from '../Dialog';

type FormikModalProps = {
  onHide: () => void;
  show: boolean;
  children: React.ReactNode;
  title: React.ReactElement | string;
  actions?: React.ReactNode | null;
  maxWidth?: DialogProps['maxWidth'];
  showActions?: boolean;
  buttonDisabled?: boolean;
  disableEnforceFocus?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
};

export const FormikModal = ({
  show,
  onHide,
  children,
  onSubmit,
  onCancel,
  title,
  actions = null,
  maxWidth = 'sm',
  showActions = true,
  buttonDisabled = false,
  disableEnforceFocus = false,
}: FormikModalProps) => {
  const { isSubmitting, submitForm, isValid, status } = useFormikContext();

  const DialogActions = React.useMemo(() => {
    if (!showActions) {
      return null;
    }

    if (actions) {
      return actions;
    }

    return (
      <>
        {!!onCancel && (
          <SubmitActionButtonStyled
            variant={'outlined'}
            messageId={TranslationKeys.button_cancel}
            onClick={onCancel}
            disabled={buttonDisabled || isSubmitting}
          />
        )}
        <ActionButton
          type={onSubmit ? undefined : 'submit'}
          messageId={TranslationKeys.button_submit}
          onClick={onSubmit ? onSubmit : submitForm}
          disabled={buttonDisabled || isSubmitting || !isValid}
        />
      </>
    );
  }, [actions, buttonDisabled, isSubmitting, isValid, onCancel, onSubmit, showActions, submitForm]);

  return (
    <Dialog
      open={show}
      title={title}
      onExit={onHide}
      maxWidth={maxWidth}
      actions={DialogActions}
      disableEnforceFocus={disableEnforceFocus}
      fullWidth
    >
      <Form>
        {status && <AlertStyled severity="warning">{status}</AlertStyled>}
        {children}
      </Form>
    </Dialog>
  );
};
