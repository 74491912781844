import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Check, Clear, Error, ErrorOutlined, Info, Warning } from '@mui/icons-material';
import { formatDateTime } from '../../../../utils/formatter/dateTimeFormatter';

const LogsTable = props => {
  const {
    intl,
    className,
    logs,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    disableCard,
    paging,
  } = props;

  const [openLog, setOpenLog] = useState(false);

  const getPackageName = useCallback(
    packageType => {
      const exists = !!intl.messages[`packages.type.${packageType}`];
      const type = exists ? packageType : 'insurance-brydge';
      return <FormattedMessage id={`packages.type.${type}`} />;
    },
    [intl],
  );

  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="connections.logs.logType" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.logs.message" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.logs.createdAt" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.logs.updatedAt" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(rowsPerPage)
              .fill(index => index)
              .map((value, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
          {!loading && logs.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>{'No logs found'}</TableCell>
            </TableRow>
          )}
          {!loading &&
            logs.length > 0 &&
            logs.map(log => (
              <TableRow key={log.mongoId}>
                <TableCell>
                  {log.logLevel === 100 && <Info color="disabled" />}
                  {log.logLevel > 100 && log.logLevel < 300 && <Info color="secondary" />}
                  {log.logLevel >= 300 && log.logLevel < 400 && <Warning color="primary" />}
                  {log.logLevel >= 400 && log.logLevel < 500 && <ErrorOutlined color="primary" />}
                  {log.logLevel >= 500 && <Error color="error" />}
                </TableCell>
                <TableCell>
                  <Button variant="text" onClick={() => setOpenLog(log)}>
                    <span>{getPackageName(log.packageType)}</span>
                    <span>{':'}&nbsp;</span>
                    <span>{log.message}</span>
                  </Button>
                </TableCell>
                <TableCell>{formatDateTime(log.createdAt)}</TableCell>
                <TableCell>{formatDateTime(log.updatedAt)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog open={Boolean(openLog)} onClose={() => setOpenLog(null)} maxWidth="md" fullWidth>
        {openLog && (
          <>
            <DialogTitle>
              <span>{getPackageName(openLog.packageType)}</span>
              <span>{':'}&nbsp;</span>
              <span>{openLog.message}</span>
            </DialogTitle>
            <DialogContent>
              <Table>
                {openLog.packageType && (
                  <TableRow>
                    <TableCell>{'Package'}</TableCell>
                    <TableCell>{getPackageName(openLog.packageType)}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>{'Message'}</TableCell>
                  <TableCell>{openLog.message}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{'First Logged at'}</TableCell>
                  <TableCell>{formatDateTime(openLog.createdAt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{'Latest Logged at'}</TableCell>
                  <TableCell>{formatDateTime(openLog.updatedAt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{'Notified'}</TableCell>
                  <TableCell>
                    {openLog.notified && <Check />}
                    {!openLog.notified && <Clear />}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{'Archived'}</TableCell>
                  <TableCell>
                    {openLog.archived && <Check />}
                    {!openLog.archived && <Clear />}
                  </TableCell>
                </TableRow>
                {openLog.solution && (
                  <TableRow>
                    <TableCell>{'Probable solution'}</TableCell>
                    <TableCell>{openLog.solution}</TableCell>
                  </TableRow>
                )}
                {openLog.wiki && (
                  <TableRow>
                    <TableCell>{'Wiki'}</TableCell>
                    <TableCell>
                      <a href={openLog.wiki} target="_blank" rel="noopener noreferrer">
                        {openLog.wiki}
                      </a>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.internal && (
                  <TableRow>
                    <TableCell>{'Internal log'}</TableCell>
                    <TableCell>
                      <pre>{JSON.stringify(openLog.internal, null, 2)}</pre>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.request && (
                  <TableRow>
                    <TableCell>{'Request'}</TableCell>
                    <TableCell>
                      <pre>{JSON.stringify(openLog.request, null, 2)}</pre>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.method && (
                  <TableRow>
                    <TableCell>
                      <p>{'Method'}</p>
                    </TableCell>
                    <TableCell>
                      <pre>{openLog.method}</pre>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.response && (
                  <TableRow>
                    <TableCell>{'Response'}</TableCell>
                    <TableCell>
                      <pre>{JSON.stringify(openLog.response, null, 2)}</pre>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.extraData && (
                  <TableRow>
                    <TableCell>{'Extra data'}</TableCell>
                    <TableCell>
                      <pre>{JSON.stringify(openLog.extraData, null, 2)}</pre>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );

  if (disableCard) {
    return table;
  }

  return (
    <Card className={className}>
      <CardContent sx={{ padding: 0 }}>
        <PerfectScrollbar>
          <div>{table}</div>
        </PerfectScrollbar>
      </CardContent>
      {paging && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
          />
        </CardActions>
      )}
    </Card>
  );
};

LogsTable.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.bool,
  logs: PropTypes.array.isRequired,
};

LogsTable.defaultProps = {
  paging: true,
};

export default injectIntl(LogsTable);
