import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { EmployerNote, UseQueryWrapper } from 'app/types';

import { useSnakeBar } from 'app/hooks/useSnakeBar';
import type { GetEmployerNotesParams } from 'app/Domain/Employers/Services/EmployerService';
import { employerService } from 'app/Domain/Employers/Services/EmployerService';

export type GetEmployerNotesQueryResultData = {
  data: EmployerNote[];
  totalCount: number;
};

export type GetEmployerNotesQueryVariables = Partial<GetEmployerNotesParams>;

const queryFn =
  ({ employerId, ownerId }: GetEmployerNotesQueryVariables) =>
  async () => {
    if (employerId && ownerId) {
      const response = await employerService.getEmployerNotes({ ownerId, employerId });
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployerNotes';

export const useEmployerGetNotesQuery: UseQueryWrapper<
  GetEmployerNotesQueryResultData,
  GetEmployerNotesQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetEmployerNotesQueryResultData>([QUERY_KEY, ...Object.values(variables || {})], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
