import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RestrictAccess, useBreadcrumb } from 'app/components';
import { TranslationKeys } from 'app/translations';
import { SETTINGS } from 'app/common/Authorization/entities';
import { VIEW } from 'app/common/Authorization/permissions';

import { SettingsRateTablesPage } from 'app/Domain/Settings/Pages/SettingsRateTablesPage';
import { SettingsRateTableCreatePage } from 'app/Domain/Settings/Pages/SettingsRateTableCreatePage';
import { SettingsSummaryScalesPage } from 'app/Domain/Settings/Pages/SettingsSummaryScalesPage';
import { SettingsSummaryScalesCreatePage } from './SettingsSummaryScalesCreatePage';

export const SettingsPages = () => {
  useBreadcrumb(TranslationKeys.menu_settings);

  return (
    <Routes>
      <Route path={'rate-tables/*'} element={<RestrictAccess entity={SETTINGS} permission={VIEW} />}>
        <Route index element={<SettingsRateTablesPage />} />
        <Route path={'new'} element={<SettingsRateTableCreatePage />} />
        <Route path="*" element={<Navigate to=".." replace />} />
      </Route>
      <Route path={'summary-scales/*'} element={<RestrictAccess entity={SETTINGS} permission={VIEW} />}>
        <Route index element={<SettingsSummaryScalesPage />} />
        <Route path="new" element={<SettingsSummaryScalesCreatePage />} />
        <Route path="*" element={<Navigate to=".." replace />} />
      </Route>
    </Routes>
  );
};
