import React from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { isNil } from 'app/utils';

import { TranslationKeys } from 'app/translations';

import type { EmployeeEmployment, UseQueryRefetch } from 'app/types';
import { YesNoEnum } from 'app/types';

import type { ShowSnakeBarParams } from 'app/hooks';
import { useEmployeeCreateEmploymentMutation, useEmployeeUpdateEmploymentMutation, useSnakeBar } from 'app/hooks';

import type {
  CreateEmployeeEmploymentArgs,
  UpdateEmployeeEmploymentArgs,
} from 'app/Domain/Employees/Services/EmployeeService';

import type { EmployeeEmploymentDetailsFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/components/EmployeeEmploymentDetails/EmployeeEmploymentDetails.types';

import { EmployeeEmploymentFormDialogBody } from './components';

type EmployeeEmploymentFormDialogProps = {
  open: boolean;
  closeDialog: () => void;
  connectionId: string;
  employeeId: string;
  employment: EmployeeEmployment | null;
  refetchGetEmploymentsQuery: UseQueryRefetch;
};

export const EmployeeEmploymentFormDialog = ({
  open,
  closeDialog,
  connectionId,
  employeeId,
  employment,
  refetchGetEmploymentsQuery,
}: EmployeeEmploymentFormDialogProps) => {
  const intl = useIntl();
  const { showSnakeBar } = useSnakeBar();
  const { mutate: createEmployment } = useEmployeeCreateEmploymentMutation();
  const { mutate: updateEmployment } = useEmployeeUpdateEmploymentMutation();

  const isEdit = employment !== null;

  const showSnackBarMessage = React.useCallback(
    ({ method, severity }: Omit<ShowSnakeBarParams, 'message'>) => {
      showSnakeBar({
        severity,
        method,
        message: intl.formatMessage({
          id:
            severity === 'success'
              ? TranslationKeys.global_update_successMessage
              : TranslationKeys.global_update_errorMessage,
        }),
      });
    },
    [intl, showSnakeBar],
  );

  const setYesNoFieldValue = React.useCallback((value: undefined | null | boolean) => {
    if (isNil(value)) {
      return '';
    }
    return !value ? YesNoEnum.No : YesNoEnum.Yes;
  }, []);

  const setYesNoPayloadValue = React.useCallback((value: YesNoEnum | '') => {
    if (value === '') {
      return null;
    }
    return value !== YesNoEnum.No;
  }, []);

  const formikInitialValues = React.useMemo<EmployeeEmploymentDetailsFormValues>(() => {
    return {
      sequenceEmploymentId: employment?.sequenceEmploymentId || null,
      startDate: employment?.startDate || null,
      yearsFrom: employment?.yearsFrom || null,
      endDate: employment?.endDate || null,
      probationDate: employment?.probationDate || null,
      employmentType: employment?.employmentType || '',
      jobDescription: employment?.jobDescription || '',
      emoluments: employment?.emoluments || '',
      deductionPlan: employment?.deductionPlan || '',
      vacationPay: setYesNoFieldValue(employment?.vacationPay),
      vacationPayPercentage: employment?.vacationPayPercentage ?? '',
      extraMonth: setYesNoFieldValue(employment?.extraMonth),
      otherWageComponents: setYesNoFieldValue(employment?.otherWageComponents),
      grossWage: null,
      partTimePercentage: null,
      calculationStartDate: employment?.calculationStartDate || null,
    };
  }, [
    employment?.sequenceEmploymentId,
    employment?.startDate,
    employment?.yearsFrom,
    employment?.endDate,
    employment?.probationDate,
    employment?.employmentType,
    employment?.jobDescription,
    employment?.emoluments,
    employment?.deductionPlan,
    employment?.vacationPay,
    employment?.vacationPayPercentage,
    employment?.extraMonth,
    employment?.otherWageComponents,
    employment?.calculationStartDate,
    setYesNoFieldValue,
  ]);

  const formikOnSubmit = React.useCallback<FormikConfig<EmployeeEmploymentDetailsFormValues>['onSubmit']>(
    (values, { setSubmitting, resetForm }) => {
      const onSuccess = (method: string) => () => {
        setSubmitting(false);
        resetForm();
        closeDialog();
        refetchGetEmploymentsQuery();
        showSnackBarMessage({ method, severity: 'success' });
      };

      const onError = (method: string) => () => {
        setSubmitting(false);
        showSnackBarMessage({ method, severity: 'error' });
      };

      const mutationData = {
        connectionId,
        employeeId,
        employment: {
          ...values,
          employmentId: employment?.employmentId,
          externalId: employment?.externalId,
          endDate: values.endDate || null,
          probationDate: values.probationDate || null,
          employmentType: values.employmentType === '' ? null : values.employmentType,
          jobDescription: values.jobDescription === '' ? null : values.jobDescription,
          emoluments: values.emoluments || null,
          deductionPlan: values.deductionPlan === '' ? null : values.deductionPlan,
          vacationPay: setYesNoPayloadValue(values.vacationPay),
          vacationPayPercentage: values.vacationPayPercentage === '' ? null : values.vacationPayPercentage,
          extraMonth: setYesNoPayloadValue(values.extraMonth),
          otherWageComponents: setYesNoPayloadValue(values.otherWageComponents),
          yearsFrom: values.yearsFrom || null,
          sicknessLeaves: employment?.sicknessLeaves || null,
        },
      } as unknown as CreateEmployeeEmploymentArgs | UpdateEmployeeEmploymentArgs;

      if (isEdit) {
        const method = 'updateEmployment';
        updateEmployment(mutationData as UpdateEmployeeEmploymentArgs, {
          onSuccess: onSuccess(method),
          onError: onError(method),
        });
      } else {
        const method = 'createEmployment';
        createEmployment(mutationData, {
          onSuccess: onSuccess(method),
          onError: onError(method),
        });
      }
    },
    [
      connectionId,
      employeeId,
      employment?.employmentId,
      employment?.externalId,
      employment?.sicknessLeaves,
      setYesNoPayloadValue,
      isEdit,
      closeDialog,
      refetchGetEmploymentsQuery,
      showSnackBarMessage,
      updateEmployment,
      createEmployment,
    ],
  );

  return (
    <Formik<EmployeeEmploymentDetailsFormValues> initialValues={formikInitialValues} onSubmit={formikOnSubmit}>
      <EmployeeEmploymentFormDialogBody open={open} isEdit={isEdit} closeDialog={closeDialog} />
    </Formik>
  );
};
