import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TranslationKeys } from '../../../../translations';

import { DATA_PROCESSING_COUNT_KEYS, useDashboardGetDataProcessingCountsQuery } from '../../../../hooks';

import { StoreSelectors, useStoreSelector } from '../../../../store';

import { IsSuperAdmin, useBreadcrumb } from '../../../../components';

import { DashboardCategoriesContainerStyled, DashboardCategoryCard, DashboardSubCategoryBody } from '../../Components';

export const DATA_PROCESSING_SUB_CATEGORIES = [
  {
    countKey: DATA_PROCESSING_COUNT_KEYS.UnprocessedEvents,
    label: TranslationKeys.dashboard_subCategory_unprocessedEvents,
    link: '/dashboard/data-processing/unprocessed-events',
  },
  {
    countKey: DATA_PROCESSING_COUNT_KEYS.UnprocessedBatches,
    label: TranslationKeys.dashboard_subCategory_unprocessedBatches,
    link: '/dashboard/data-processing/unprocessed-batches',
  },
  {
    countKey: DATA_PROCESSING_COUNT_KEYS.MissingDetails,
    label: TranslationKeys.dashboard_subCategory_missingDetails,
    link: '/dashboard/data-processing/missing-details',
  },
  {
    countKey: DATA_PROCESSING_COUNT_KEYS.EventFlow,
    label: TranslationKeys.dashboard_subCategory_eventFlow,
    link: '/dashboard/data-processing/event-flow',
  },
  {
    countKey: DATA_PROCESSING_COUNT_KEYS.ConnectionErrors,
    label: TranslationKeys.dashboard_subCategory_connectionErrors,
    link: '/dashboard/data-processing/connection-errors?status=failed',
  },
  {
    countKey: DATA_PROCESSING_COUNT_KEYS.BlackAndWhiteList,
    label: TranslationKeys.dashboard_subCategory_blackAndWhiteList,
    link: '/dashboard/data-processing/black-and-white-list',
  },
];

export const DashboardDataProcessingPage = () => {
  const navigate = useNavigate();

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));

  const [isSuperAdmin] = React.useState(IsSuperAdmin());

  const {
    data: dataProcessingCounts,
    isLoading: isLoadingGetDataProcessingCounts,
    isFetching: isFetchingGetDataProcessingCounts,
  } = useDashboardGetDataProcessingCountsQuery({
    variables: { userId },
    options: {
      enabled: !!userId,
    },
  });

  const isGetDataProcessingCountsLoading = isLoadingGetDataProcessingCounts || isFetchingGetDataProcessingCounts;

  const onCategoryCardClick = React.useCallback(
    (link: string) => () => {
      navigate(link);
    },
    [navigate],
  );

  useBreadcrumb(TranslationKeys.dashboard_category_dataProcessing);

  return (
    <DashboardCategoriesContainerStyled>
      {DATA_PROCESSING_SUB_CATEGORIES.map(subCategory => (
        <DashboardCategoryCard
          key={subCategory.link}
          onClick={onCategoryCardClick(subCategory.link)}
          disabled={isSuperAdmin || isGetDataProcessingCountsLoading}
        >
          <DashboardSubCategoryBody
            count={dataProcessingCounts?.[subCategory.countKey]}
            label={subCategory.label}
            isLoading={isGetDataProcessingCountsLoading}
          />
        </DashboardCategoryCard>
      ))}
    </DashboardCategoriesContainerStyled>
  );
};
