import React from 'react';
import { FormattedMessage } from 'react-intl';

import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import { StatusItemStyled } from './DashboardOverviewInfoStatusItem.styles';

export const DASHBOARD_OVERVIEW_INFO_STATUS_ITEM_ICONS_DATA_TEST_ID: Record<
  DashboardOverviewInfoStatusItemProps['type'],
  string
> = {
  warning: 'dashboard-overview-info-status-item-icon-warning',
  critical: 'dashboard-overview-info-status-item-icon-critical',
};

const icons: Record<DashboardOverviewInfoStatusItemProps['type'], React.ReactNode> = {
  warning: <HelpCenterIcon data-testid={DASHBOARD_OVERVIEW_INFO_STATUS_ITEM_ICONS_DATA_TEST_ID.warning} />,
  critical: <WarningIcon data-testid={DASHBOARD_OVERVIEW_INFO_STATUS_ITEM_ICONS_DATA_TEST_ID.critical} />,
};

export type DashboardOverviewInfoStatusItemProps = {
  type: 'warning' | 'critical';
  messageId: string;
  count: number;
};

export const DashboardOverviewInfoStatusItem = ({ type, messageId, count }: DashboardOverviewInfoStatusItemProps) => {
  return (
    <StatusItemStyled type={type}>
      <span>{icons[type]}</span>
      <Typography variant={'h5'}>
        <FormattedMessage id={messageId} values={{ count }} />
      </Typography>
    </StatusItemStyled>
  );
};
