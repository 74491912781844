import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GetAppIcon from '@mui/icons-material/GetApp';

export const DivImageContainerStyled = styled('div', {
  name: 'DivImageContainerStyled',
})(({ theme }) => ({
  height: 64,
  width: 64,
  margin: '0 auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ImgStyled = styled('img', {
  name: 'ImgStyled',
})(() => ({
  width: '100%',
}));

export const GridStatsItemStyled = styled(Grid, {
  name: 'GridStatsItemStyled',
})(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const iconCommonStyles = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
});

export const AccessTimeIconStyled = styled(AccessTimeIcon, {
  name: 'AccessTimeIconStyled',
})(({ theme }) => iconCommonStyles(theme));

export const GetAppIconStyled = styled(GetAppIcon, {
  name: 'GetAppIconStyled',
})(({ theme }) => iconCommonStyles(theme));
