import { TranslationKeys } from 'app/translations';

import { normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc } from 'app/components';

export const EmployeeEmploymentScheduleFields = {
  StartDate: {
    Label: TranslationKeys.global_startDate,
    InputName: 'startDate',
    Options: {
      style: 'date',
    },
  },
  EndDate: {
    Label: TranslationKeys.global_endDate,
    InputName: 'endDate',
    Options: {
      style: 'date',
    },
  },
  PartTimePercentage: {
    Label: TranslationKeys.global_partTimePercentage,
    InputName: 'partTimePercentage',
    Options: {
      style: 'percent',
      openScale: true,
      min: 0,
      max: 100,
    },
  },
  StartAveragePartTimePercentage: {
    Label: TranslationKeys.global_startAveragePartTimePercentage,
    InputName: 'startAveragePartTimePercentage',
    Options: {
      style: 'percent',
      openScale: true,
      min: 0,
      max: 100,
    },
  },
  HoursPerWeek: {
    Label: TranslationKeys.global_hoursPerWeek,
    InputName: 'hoursPerWeek',
    Options: {
      openScale: true,
      min: 0,
    },
  },
  FullTimeHoursPerWeek: {
    Label: TranslationKeys.global_fullTimeHoursPerWeek,
    InputName: 'fullTimeHoursPerWeek',
    Options: {
      openScale: true,
      min: 0,
    },
  },
} as const;

export const EmployeeEmploymentScheduleFieldOptionsAndLabel = normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc(
  EmployeeEmploymentScheduleFields,
);

export type EmployeeEmploymentScheduleFormValues = {
  [EmployeeEmploymentScheduleFields.StartDate.InputName]: string | null;
  [EmployeeEmploymentScheduleFields.EndDate.InputName]: string | null;
  [EmployeeEmploymentScheduleFields.PartTimePercentage.InputName]: number | string;
  [EmployeeEmploymentScheduleFields.HoursPerWeek.InputName]: number | string;
  [EmployeeEmploymentScheduleFields.FullTimeHoursPerWeek.InputName]: number | string;
  [EmployeeEmploymentScheduleFields.StartAveragePartTimePercentage.InputName]: number | string | null;
};
