import { styled } from '@mui/material/styles';

export const DivMessageContainer = styled('div', {
  name: 'DivMessageContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: theme.spacing(12),
}));
