import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton, Typography } from '@mui/material';
import { SearchInput } from 'app/components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DivRootStyled } from './ConnectionsToolbar.styles';

const ConnectionsToolbar = props => {
  const { className, handleSearch, intl, totalCount, initialSearchQuery } = props;

  return (
    <DivRootStyled className={className}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="h5">
            <FormattedMessage id="menu.connections" />
          </Typography>
          <Typography variant="subtitle2" textTransform={'uppercase'}>
            {typeof totalCount !== 'undefined' ? (
              <FormattedMessage id="connections.totalCount" values={{ totalCount: totalCount }} />
            ) : (
              <Skeleton width={200} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SearchInput
            onChange={handleSearch}
            placeholder={intl.formatMessage({ id: 'connections.search' }).toUpperCase()}
            initialSearchQuery={initialSearchQuery}
          />
        </Grid>
      </Grid>
    </DivRootStyled>
  );
};

ConnectionsToolbar.propTypes = {
  className: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
};

export default injectIntl(ConnectionsToolbar);
