import { put, select, takeLatest, debounce } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { getConnectionActivityLogs } from '../Services/ConnectionService';
import { MESSAGE_SEVERITY_ERROR } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  RequestData: '[ActivityLogs] Request',
  FulfilledTable: '[ActivityLogs] Fulfilled',
  SearchTable: '[ActivityLogs] Search',
  ChangePage: '[ActivityLogs] change page',
  SetPageSize: '[ActivityLogs] set page size',
  SortTable: '[ActivityLogs] Sort',
  ApplyFilter: '[ActivityLogs] Apply filter',
  RemoveFilter: '[ActivityLogs] Remove filter',
  SetConnectionId: '[ActivityLogs] Set connection Id',
};

const initialState = {
  ...tableInitialState,
  connectionId: null,
  pageSize: 5,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.SetConnectionId: {
      return {
        ...state,
        connectionId: action.connectionId,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestData, function* request() {
    try {
      const currentState = yield select(state => {
        return state.ActivityLogsReducer;
      });

      const response = yield getConnectionActivityLogs(
        currentState.connectionId,
        currentState.page,
        currentState.pageSize,
      );

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getActivityLogs',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });
}
