import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import { DivRootStyled, SearchInputStyled } from './CredentialRequestsToolbar.styles';

const CredentialRequestsToolbar = props => {
  const { className, handleSearch, intl } = props;

  return (
    <DivRootStyled className={className}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="h5">
            <FormattedMessage id="menu.credentialsRequests" />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SearchInputStyled
            onChange={handleSearch}
            placeholder={intl.formatMessage({ id: 'credentialRequests.search' }).toUpperCase()}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }} />
      </Grid>
    </DivRootStyled>
  );
};

CredentialRequestsToolbar.propTypes = {
  className: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
};

export default injectIntl(CredentialRequestsToolbar);
