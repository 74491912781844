import { alpha } from '@mui/material/styles';
import { checkboxClasses } from '@mui/material';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiTableCell: FuncOverrideComponent<'MuiTableCell'> = theme => ({
  styleOverrides: {
    head: {
      ...theme.typography.h6,
    },
    root: {
      ...theme.typography.body2,
      borderRight: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
      padding: theme.spacing(1.5, 2),
      color: theme.palette.primary.dark,
      [`& .${checkboxClasses.root}`]: {
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
    body: {
      color: theme.palette.primary.dark,
    },
  },
});
