import { isNil } from 'app/utils/Types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type IntlMessageProps = {
  value: string;
  fallback?: string;
};

const IntlMessage = ({ value, fallback }: IntlMessageProps) => {
  const intl = useIntl();

  if (intl.messages[`${value}`]) {
    return <FormattedMessage id={value} />;
  }

  if (isNil(fallback)) {
    return <>{value}</>;
  }

  return <IntlMessage value={fallback!} />;
};

export default IntlMessage;
