import React, { useCallback } from 'react';
import type { DropzoneAreaProps } from 'react-mui-dropzone';
import { DropzoneArea } from 'react-mui-dropzone';
import { useIntl } from 'react-intl';

import { FormLabel } from '@mui/material';

import { TranslationKeys } from '../../translations';

import { convertBytesToMbsOrKbs } from '../../utils';

import IntlMessage from '../Intl/IntlMessage';

import { ImageUploadDropzoneAreaContainer } from './ImageUploadArea.styles';

type ImageUploadAreaProps = {
  onChange: (file?: File) => void;
  label?: string;
  initialFile?: File | string;
  dropzoneText?: string;
  disabled?: boolean;
  acceptedTypes?: Array<'png' | 'jpeg' | 'svg' | 'webp' | 'bmp' | 'avif' | 'gif'>;
};

const MAX_FILE_SIZE_10_MB = 10485760;

export const ImageUploadArea = ({
  onChange,
  label,
  acceptedTypes,
  initialFile,
  dropzoneText = TranslationKeys.imageUploadArea_defaultDropzoneText,
  disabled = false,
}: ImageUploadAreaProps) => {
  const intl = useIntl();
  const [hasFile, setHasFile] = React.useState(false);

  const acceptedFiles = React.useMemo(() => {
    const prefix = 'image/';

    if (!acceptedTypes?.length) {
      return [prefix + '*'];
    }

    return acceptedTypes.map(type => {
      let ret = prefix + type;

      if (type === 'svg') {
        ret += '+xml';
      }

      return ret;
    });
  }, [acceptedTypes]);

  const initialFiles = React.useMemo(() => {
    if (initialFile) {
      return [initialFile];
    }
    return undefined;
  }, [initialFile]);

  const onChangeHandler = React.useCallback<NonNullable<DropzoneAreaProps['onChange']>>(
    (files: Array<File>) => {
      const droppedFile = files[0];
      setHasFile(!!droppedFile);
      onChange(droppedFile);
    },
    [onChange],
  );

  const getDropRejectMessage = useCallback<NonNullable<DropzoneAreaProps['getDropRejectMessage']>>(
    (rejectedFile, acceptedFiles, maxFileSize) => {
      if (!acceptedFiles.includes(rejectedFile.type)) {
        return intl.formatMessage({ id: TranslationKeys.upload_fileWrongType }, { fileName: rejectedFile.name });
      }
      if (rejectedFile.size > maxFileSize) {
        return intl.formatMessage(
          { id: TranslationKeys.upload_fileTooBig },
          { fileName: rejectedFile.name, limit: convertBytesToMbsOrKbs(maxFileSize) },
        );
      }
      return intl.formatMessage({ id: TranslationKeys.upload_fileRejected }, { fileName: rejectedFile.name });
    },
    [intl],
  );

  return (
    <ImageUploadDropzoneAreaContainer disabled={disabled} hasFile={hasFile}>
      {label && (
        <FormLabel>
          <IntlMessage value={label} />
        </FormLabel>
      )}
      <DropzoneArea
        acceptedFiles={acceptedFiles}
        dropzoneText={intl.formatMessage({ id: dropzoneText })}
        onChange={onChangeHandler}
        getDropRejectMessage={getDropRejectMessage}
        maxFileSize={MAX_FILE_SIZE_10_MB}
        initialFiles={initialFiles}
        filesLimit={1}
        showAlerts={['error']}
        dropzoneProps={{ disabled }}
      />
    </ImageUploadDropzoneAreaContainer>
  );
};
