import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CircularProgress, Typography } from '@mui/material';

import { DashboardSubCategoryBodyStyled, DashboardSubCategoryDividerStyled } from './DashboardSubCategoryBody.styles';

type DashboardSubCategoryBodyProps = {
  isLoading?: boolean;
  count: number | undefined;
  label: string;
};

export const DashboardSubCategoryBody = ({ label, count = 0, isLoading = false }: DashboardSubCategoryBodyProps) => {
  return (
    <DashboardSubCategoryBodyStyled>
      <Typography variant="h4" sx={{ fontSize: '1.875rem' }}>
        {isLoading ? <CircularProgress size={30} sx={{ marginTop: '7px' }} /> : count}
      </Typography>
      <DashboardSubCategoryDividerStyled />
      <Typography variant="h4">
        <FormattedMessage id={label} />
      </Typography>
    </DashboardSubCategoryBodyStyled>
  );
};
