import React, { useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import TabOutlet from 'app/components/TabOutlet';
import { NotFoundView } from '../../../../views';
import { actions as OwnerActions, selectors } from '../../Ducks/Owner.duck';
import { selectors as appSelectors } from '../../../App/Ducks/App.duck';

const OwnerSettingsPage = () => {
  const dispatch = useDispatch();

  const tabData = useMemo(() => {
    return [
      {
        path: 'employee-types',
        label: <FormattedMessage id="employees" />,
      },
      {
        path: 'employers',
        label: <FormattedMessage id="employers" />,
        disabled: true,
      },
      {
        path: 'users',
        label: <FormattedMessage id="users" />,
        disabled: true,
      },
      {
        path: 'credentials-requests',
        label: <FormattedMessage id="credentialRequests" />,
        disabled: true,
      },
    ];
  }, []);

  const title = useMemo(() => {
    return (
      <>
        <Typography variant="h5">
          <FormattedMessage id="menu.settings" />
        </Typography>
      </>
    );
  }, []);

  const breadcrumb = useMemo(() => {
    return {
      title: title,
      data: {
        otherElements: '',
        hideTrail: true,
      },
    };
  }, [title]);

  const ownerId = useSelector(state => appSelectors.selectOwnerId(state.AppReducer));

  const { item: owner, loaded, loading } = useSelector(state => selectors.selectOwner(state.OwnerReducer));

  useEffect(() => {
    if (!owner || owner.ownerId !== ownerId) {
      dispatch(OwnerActions.owner.requestData({ ownerId }));
    }
  }, [owner, dispatch, ownerId]);

  const exists = loading || (loaded && !!owner);

  return exists ? (
    <TabOutlet tabs={tabData} breadcrumb={breadcrumb} />
  ) : (
    <Routes>
      <Route element={<NotFoundView />} path="*" />
    </Routes>
  );
};

export default OwnerSettingsPage;
