import type { Theme } from '@mui/material/styles';
import type { FuncOverrideComponent } from '../theme.types';

export const MuiSelect: FuncOverrideComponent<'MuiSelect'> = (theme: Theme) => ({
  styleOverrides: {
    icon: {
      color: theme.palette.primary.main,
    },
    iconOutlined: {
      top: 'calc(50% - 16px)',
      width: '32px',
      height: '32px',
      right: '0px',
    },
    selectMenu: {
      height: '1em',
      minHeight: '1em',
    },
    select: {
      minHeight: '1em',
      '&:focus': {
        backgroundColor: 'unset',
      },
    },
  },
});
