import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

import { Grid, Typography } from '@mui/material';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';

import type { GenderStatusEnum } from 'app/types';

import { useDialog, useGenderSelectItems } from 'app/hooks';

import { InputFieldFactory } from 'app/components/FormikField';
import type { GetInputOptionsAndLabelFunc, GetSelectOrAutocompleteDisplayValueFunc } from 'app/components';
import { FormikModal, SubmitConfirmationDialog } from 'app/components';

import type { EmployeeChildFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/components/ChildrenDetailsPanel/ChildrenDetailsPanel.types';
import {
  ChildrenDetailsPanelFieldOptionsAndLabel,
  ChildrenDetailsPanelFields,
} from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/components/ChildrenDetailsPanel/ChildrenDetailsPanel.types';

type ChildFormDialogBodyProps = {
  open: boolean;
  isEdit: boolean;
  closeDialog: () => void;
};

export const ChildFormDialogBody = ({ open, isEdit, closeDialog }: ChildFormDialogBodyProps) => {
  const { initialValues, values, submitForm, resetForm } = useFormikContext<EmployeeChildFormValues>();
  const { genderSelectItems, getGenderDisplayNameByValue } = useGenderSelectItems();

  const {
    dialogState: submitConfirmationDialogState,
    openDialog: openSubmitConfirmationDialog,
    closeDialog: closeSubmitConfirmationDialog,
  } = useDialog();

  const onClickSubmit = React.useCallback(() => {
    const hasChangedValues = JSON.stringify(initialValues) !== JSON.stringify(values);

    if (!isEdit) {
      submitForm();
    } else if (hasChangedValues) {
      openSubmitConfirmationDialog();
    } else {
      closeDialog();
    }
  }, [closeDialog, initialValues, isEdit, openSubmitConfirmationDialog, submitForm, values]);

  const submitFormAfterConfirmation = React.useCallback(() => {
    closeSubmitConfirmationDialog();
    submitForm();
  }, [closeSubmitConfirmationDialog, submitForm]);

  const getInputOptionsAndLabel = React.useCallback<GetInputOptionsAndLabelFunc>(inputName => {
    return ChildrenDetailsPanelFieldOptionsAndLabel[inputName];
  }, []);

  const getSelectOrAutocompleteDisplayValue = React.useCallback<GetSelectOrAutocompleteDisplayValueFunc>(
    ({ inputName, inputValue }) => {
      if (inputName === ChildrenDetailsPanelFields.Gender.InputName) {
        return getGenderDisplayNameByValue(inputValue as GenderStatusEnum) || '';
      }
      return '';
    },
    [getGenderDisplayNameByValue],
  );

  return (
    <>
      {submitConfirmationDialogState && (
        <SubmitConfirmationDialog
          open={submitConfirmationDialogState}
          onClose={closeSubmitConfirmationDialog}
          onConfirm={submitFormAfterConfirmation}
          previousValues={initialValues}
          newValues={values}
          getSelectOrAutocompleteDisplayValue={getSelectOrAutocompleteDisplayValue}
          getInputOptionsAndLabel={getInputOptionsAndLabel}
        />
      )}

      {!submitConfirmationDialogState && (
        <FormikModal
          title={
            <FormattedMessage
              id={isEdit ? TranslationKeys.employees_actions_editChild : TranslationKeys.employees_actions_addChild}
            />
          }
          show={open}
          onSubmit={onClickSubmit}
          onCancel={isEdit ? closeDialog : undefined}
          onHide={() => {
            closeDialog();
            resetForm();
          }}
          disableEnforceFocus
        >
          {isEdit && (
            <Typography variant="h6" paragraph>
              {format.name(initialValues.personName)}
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: ChildrenDetailsPanelFields.PersonName.Props.Initials.Label,
                  name: ChildrenDetailsPanelFields.PersonName.Props.Initials.InputName,
                  type: 'text',
                  required: true,
                  schema: (schema: any) => schema.trim().max(255),
                  options: ChildrenDetailsPanelFields.PersonName.Props.Initials.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: ChildrenDetailsPanelFields.PersonName.Props.FirstName.Label,
                  name: ChildrenDetailsPanelFields.PersonName.Props.FirstName.InputName,
                  type: 'text',
                  schema: (schema: any) => schema.trim().max(255),
                  options: ChildrenDetailsPanelFields.PersonName.Props.FirstName.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: ChildrenDetailsPanelFields.PersonName.Props.LastNamePrefix.Label,
                  name: ChildrenDetailsPanelFields.PersonName.Props.LastNamePrefix.InputName,
                  type: 'text',
                  schema: (schema: any) => schema.trim().max(12),
                  options: ChildrenDetailsPanelFields.PersonName.Props.LastNamePrefix.Options,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: ChildrenDetailsPanelFields.PersonName.Props.LastName.Label,
                  name: ChildrenDetailsPanelFields.PersonName.Props.LastName.InputName,
                  type: 'text',
                  required: true,
                  schema: (schema: any) => schema.trim().max(255),
                  options: ChildrenDetailsPanelFields.PersonName.Props.LastName.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: ChildrenDetailsPanelFields.SocialSecurityNumber.Label,
                  name: ChildrenDetailsPanelFields.SocialSecurityNumber.InputName,
                  type: 'text',
                  schema: (schema: any) => schema.trim().max(12),
                  options: ChildrenDetailsPanelFields.SocialSecurityNumber.Options,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: ChildrenDetailsPanelFields.Gender.Label,
                  name: ChildrenDetailsPanelFields.Gender.InputName,
                  type: 'select',
                  required: true,
                  options: ChildrenDetailsPanelFields.Gender.Options,
                  items: genderSelectItems,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={{
                  header: ChildrenDetailsPanelFields.DateOfBirth.Label,
                  name: ChildrenDetailsPanelFields.DateOfBirth.InputName,
                  type: 'date',
                  options: ChildrenDetailsPanelFields.DateOfBirth.Options,
                }}
              />
            </Grid>
          </Grid>
        </FormikModal>
      )}
    </>
  );
};
