import type { Dictionary } from '../../utils';
import { isObject } from '../../utils';

import type { Field } from '../../types/field.types';

import type { GetInputOptionsAndLabelFuncReturnValue } from './SubmitConfirmationDialog.types';

type NormalField = {
  readonly Label: string;
  readonly InputName: string;
  readonly Options: Field['options'];
};

type NestedFieldProps = NormalField & {
  readonly FieldTypeName: string;
};

type NestedField = {
  readonly FieldTypeName: string;
  readonly Label?: string;
  readonly InputName?: string;
  readonly Props: Record<string, NestedFieldProps>;
};

export type FormFieldsSchema = Record<string, NormalField | NestedField>;

export const normalizeFormFieldsSchemaForGetInputOptionsAndLabelFunc = (formFieldsSchema: FormFieldsSchema) =>
  Object.keys(formFieldsSchema).reduce<Dictionary<GetInputOptionsAndLabelFuncReturnValue>>((acc, key) => {
    const field = formFieldsSchema[key as keyof typeof formFieldsSchema];

    if (!('Props' in field)) {
      return {
        ...acc,
        [field.InputName]: {
          label: field.Label,
          options: field.Options,
        },
      };
    }

    if ('Props' in field) {
      const nestedFields = Object.values(field.Props).filter(val => isObject(val));
      if (nestedFields) {
        return {
          ...acc,
          ...nestedFields.reduce((nestedAcc, nestedField) => {
            return {
              ...nestedAcc,
              [nestedField.FieldTypeName]: {
                label: nestedField.Label,
                options: nestedField.Options,
              },
            };
          }, {}),
        };
      }
      return acc;
    }

    return acc;
  }, {});
