import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper } from '@mui/material';
import { faAngleDown, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';

import { getInitials } from '../../../../../../utils';

import { useStoreDispatch, useStoreSelector } from '../../../../../../store';

import { actions as AppActions } from '../../../../../../Domain/App/Ducks/App.duck';

import {
  AvatarStyled,
  DivRootStyled,
  FontAwesomeIconMenuIconStyled,
  FontAwesomeIconStyled,
  PopperMenuStyled,
  TypographyNameStyled,
} from './PrefileMenu.styles';

import { useNavigate } from 'react-router-dom';

export const ProfileMenu = () => {
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);

  const { user } = useStoreSelector(state => state.AppReducer);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen?.current === true && !open) {
      anchorRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = React.useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, []);

  const handleClose = React.useCallback(event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleProfile = React.useCallback(
    event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      navigate(`/users/${user.userId}`);
      setOpen(false);
    },
    [navigate, user.userId],
  );

  const handleLogout = React.useCallback(
    event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      dispatch(AppActions.logout());
    },
    [dispatch],
  );

  const handleListKeyDown = React.useCallback(event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  return (
    <DivRootStyled>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant={'text'}
      >
        <AvatarStyled>{getInitials(user.personName.fullName)}</AvatarStyled>
        <TypographyNameStyled variant="h4">
          {user.personName.fullName}
          {user.ownerName ? ` (${user.ownerName})` : ''}
        </TypographyNameStyled>
        <FontAwesomeIconStyled icon={faAngleDown} />
      </Button>
      <PopperMenuStyled open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleProfile}>
                    <FontAwesomeIconMenuIconStyled icon={faUser} />
                    <FormattedMessage id="button.profile" />
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <FontAwesomeIconMenuIconStyled icon={faRightFromBracket} />
                    <FormattedMessage id="button.logout" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </PopperMenuStyled>
    </DivRootStyled>
  );
};
