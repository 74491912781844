import React, { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Formik, setIn } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputFieldFactory } from 'app/components/FormikField';
import { actions as InsurersActions, selectors as insurersSelector } from 'app/Domain/Insurers/Ducks/Insurers.duck';
import { actions as InsurerActions, selectors as insurerSelector } from 'app/Domain/Insurers/Ducks/Insurer.duck';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'app/utils';
import CollapsableSection from 'app/components/CollapsableSection';
import { actions as AppActions } from 'app/Domain/App/Ducks/App.duck';
import { mapSettingsField, SettingsFieldGroup } from 'app/components/FormikField/Factory/SettingsFieldFactory';
import { onboardingService } from '../../../../Connections/Services';
import { formatDateTime } from 'app/utils/formatter/dateTimeFormatter';
import { TranslationKeys } from '../../../../../translations';
import Typography from '@mui/material/Typography';
import { FormLabelStyled } from '../../../Pages/EmployerAddPage/EmployerAddPage.styles';

const ConnectionInfoStep = forwardRef(({ onSubmit, stepState, connection, loading = false }, ref) => {
  let connectionLatestRun = null;
  let connectionNextRun = null;

  if (connection && connection.latestRun !== undefined && connection.latestRun !== null) {
    connectionLatestRun = new Date(connection.latestRun);
  } else {
    connectionLatestRun = null;
  }

  if (connection && connection.latestRun !== undefined && connection.latestRun !== null) {
    connectionNextRun = new Date(connection.nextRun);
  } else {
    connectionNextRun = null;
  }

  const formRef = createRef();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [credentialSettingsLoading, setCredentialSettingsLoading] = useState(
    stepState.state.credentialSettingsLoading ?? false,
  );
  const [credentialSettings, setCredentialSettings] = useState(stepState.state.credentialSettings ?? null);
  const [selectedProductEntityId, setSelectedProductEntityId] = useState(
    stepState.state.selectedProductEntityId ?? (connection?.product && connection?.product['@id']) ?? null,
  );
  const [selectedProduct, setSelectedProduct] = useState(
    stepState.state.selectedProduct ?? connection?.product ?? null,
  );
  const [insurerId, setInsurerId] = useState(
    stepState.state.insurerId ?? connection?.product?.insurer?.insurerId ?? null,
  );
  const [connectionName, setConnectionName] = useState(
    stepState.state.connectionName ?? connection?.connectionName ?? null,
  );
  const [disabled, setDisabled] = useState(stepState.state.disabled ?? connection?.disabled ?? false);
  const { loading: insurersLoading, items: insurers } = useSelector(state =>
    insurersSelector.selectInsurers(state.InsurersReducer),
  );
  const { loading: productsLoading, items: products } = useSelector(state =>
    insurerSelector.selectInsurerProducts(state.InsurerReducer),
  );

  const insurerFieldLoading = loading || initialLoading || insurersLoading;
  const productsFieldLoading = insurerFieldLoading || productsLoading;
  const productHasSettings = !isEmpty(selectedProduct?.credential);

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        if (credentialSettingsLoading || productsFieldLoading) {
          return;
        }
        formRef.current.submitForm();
      },
      getState() {
        return {
          state: {
            credentialSettings,
            selectedProduct,
            selectedProductEntityId,
            insurerId,
            connectionName,
            disabled,
            credentialSettingsLoading,
          },
          form: formRef.current.values,
        };
      },
    }),
    [
      connectionName,
      disabled,
      credentialSettings,
      credentialSettingsLoading,
      formRef,
      insurerId,
      productsFieldLoading,
      selectedProduct,
      selectedProductEntityId,
    ],
  );

  useEffect(() => {
    if (initialLoading) {
      dispatch(InsurersActions.insurers.requestData());
      setInitialLoading(false);
    }
  }, [dispatch, initialLoading]);

  useEffect(() => {
    if (insurerId) {
      dispatch(InsurerActions.products.requestData({ insurerId }));
    }
  }, [dispatch, insurerId]);

  useEffect(() => {
    const product = (products || []).find(p => p['@id'] === selectedProductEntityId) ?? null;
    if (product !== selectedProduct) {
      setSelectedProduct(product);
    }
  }, [products, selectedProduct, selectedProductEntityId]);

  useEffect(() => {
    if (!selectedProduct || !productHasSettings) {
      setCredentialSettings(null);
      return;
    }
    if (!!credentialSettings && selectedProduct.credential.credentialId === credentialSettings.credentialId) {
      return;
    }
    setCredentialSettingsLoading(true);
    onboardingService
      .getCredentialSettings({ credentialId: selectedProduct.credential.credentialId })
      .then(result => {
        setCredentialSettings({
          credentialId: selectedProduct.credential.credentialId,
          data: result.data['hydra:member'],
        });
      })
      .catch(() => {
        return dispatch(AppActions.displayError({ method: 'getCredentialSettings' }));
      })
      .finally(() => {
        setCredentialSettingsLoading(false);
      });
  }, [credentialSettings, dispatch, productHasSettings, selectedProduct]);

  const changeConnectionName = useCallback(({ value: connectionName }) => {
    setConnectionName(connectionName);
  }, []);

  const changeDisabled = useCallback(({ value: disabled }) => {
    setDisabled(disabled);
  }, []);

  const changeInsurer = useCallback(({ value: insurerId }) => {
    setInsurerId(insurerId);
    setSelectedProduct(null);
  }, []);

  const changeProduct = useCallback(({ value: productEntityId }) => {
    setSelectedProductEntityId(productEntityId);
  }, []);

  const fields = useMemo(
    () => ({
      connectionName: {
        name: 'connectionName',
        type: 'text',
        required: true,
        header: 'products.connectionName',
        placeholder: intl.formatMessage({ id: 'products.connectionName' }),
        loading: loading,
        schema: schema => schema.trim().strict().max(255),
      },
      insurerId: {
        name: 'insurerId',
        type: 'autocomplete',
        required: true,
        header: 'products.selectInsurer',
        placeholder: intl.formatMessage({ id: 'products.insurerName' }),
        loading: insurerFieldLoading,
        items: (insurers || []).map(insurer => ({
          element: `${insurer.insurerName} [${insurer.type.toUpperCase()}]`,
          value: insurer.insurerId,
        })),
      },
      productEntityId: {
        name: 'productEntityId',
        type: 'autocomplete',
        required: true,
        header: 'products.selectProduct',
        placeholder: intl.formatMessage({ id: 'products.productName' }),
        loading: productsFieldLoading,
        items: productsFieldLoading
          ? []
          : (products || []).map(product => ({
              element: product.productName,
              value: product['@id'],
            })),
      },
      disabled: {
        name: 'disabled',
        type: 'boolean',
        required: true,
        header: 'products.disabled',
        placeholder: intl.formatMessage({ id: 'products.disabled' }),
        loading: loading,
      },
    }),
    [intl, loading, insurerFieldLoading, insurers, productsFieldLoading, products],
  );

  const settingsFields = useMemo(() => {
    if (!productHasSettings || isEmpty(credentialSettings)) {
      return [];
    }
    const credentialSettingsData = credentialSettings.data || [];

    return credentialSettingsData.reduce((result, settings) => {
      const currentSettings = connection?.packages?.find(p => p.packageType === settings.packageType)?.settings || {};
      const settingsFields = mapSettingsField(
        intl,
        settings,
        'onboarding.settings.',
        credentialSettingsLoading,
        stepState.form,
        currentSettings,
        SettingsFieldGroup.Insurer,
      );
      return result.concat(settingsFields);
    }, []);
  }, [productHasSettings, credentialSettings, connection?.packages, intl, credentialSettingsLoading, stepState.form]);

  const initialValues = useMemo(() => {
    let values = {
      connectionName: connectionName,
      insurerId: insurerId,
      productEntityId: isNil(selectedProduct) ? null : selectedProduct['@id'],
      disabled: disabled,
    };

    const credential = selectedProduct?.credential || null;
    if (credential) {
      values = setIn(values, `credentials.${credential.packageType}`, credential.credentialId);
    }

    settingsFields.forEach(field => {
      values = setIn(values, field.name, field.value ?? field.defaultValue ?? null);
    });
    return values;
  }, [connectionName, insurerId, selectedProduct, disabled, settingsFields]);

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} innerRef={formRef}>
      {({ values }) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory field={fields['connectionName']} onChange={changeConnectionName} />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory field={fields['insurerId']} onChange={changeInsurer} />
            </Grid>
            <Grid item xs={4}>
              <InputFieldFactory
                field={fields['productEntityId']}
                onChange={changeProduct}
                disabled={!insurers || isEmpty(values.insurerId)}
              />
            </Grid>
          </Grid>
          <Box p={1} />

          <CollapsableSection header={'onboarding.connectionRun'} hidden={false} expanded={true}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputFieldFactory field={fields['disabled']} onChange={changeDisabled} />
              </Grid>
              {connection && (
                <Grid item xs={4}>
                  <FormLabelStyled>
                    <FormattedMessage id={TranslationKeys.connection_latestRun} />
                  </FormLabelStyled>
                  <Typography variant="body1">{formatDateTime(connectionLatestRun)}</Typography>
                </Grid>
              )}
              {connection && (
                <Grid item xs={4}>
                  <FormLabelStyled>
                    <FormattedMessage id={TranslationKeys.connection_nextRun} />
                  </FormLabelStyled>
                  <Typography variant="body1">{formatDateTime(connectionNextRun)}</Typography>
                </Grid>
              )}
            </Grid>
          </CollapsableSection>
          <Box p={1} />
          <CollapsableSection
            header={'onboarding.insurerStep'}
            loading={credentialSettingsLoading}
            expanded={!credentialSettingsLoading && !isEmpty(settingsFields)}
            hidden={!productHasSettings}
            controllable
          >
            {productHasSettings && (
              <>
                <Box p={1} />
                <Grid container spacing={2}>
                  {settingsFields.map((field, index) => (
                    <Grid item key={index} xs={4}>
                      <InputFieldFactory field={field} />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </CollapsableSection>
        </>
      )}
    </Formik>
  );
});

ConnectionInfoStep.displayName = 'ConnectionInfoStep';

export default ConnectionInfoStep;
