import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { GridStyled } from './ViewSubEmployersForm.styles';

const ViewSubEmployersForm = props => {
  const { employer } = props;

  return (
    <Grid sx={{ width: '100%' }} container spacing={2}>
      {!!employer.subEmployers &&
        !!employer.subEmployers.length &&
        employer.subEmployers.map(subEmployer => (
          <GridStyled item key={subEmployer.employerId} xs={12}>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="body1">{subEmployer.employerName}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body1" fontWeight={employer.status ? 'bolder' : undefined}>
                  <FormattedMessage id={`employers.${subEmployer.status}`} />
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Box textAlign="right">
                  <Link href={'/employers/' + subEmployer['employerId']}>
                    <Typography variant="body1">
                      <FormattedMessage id="employers.showDetailPage" />
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </GridStyled>
        ))}
    </Grid>
  );
};

ViewSubEmployersForm.propTypes = {
  employer: PropTypes.object,
};

export default ViewSubEmployersForm;
