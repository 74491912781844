import React from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Typography } from '@mui/material';

import { TranslationKeys } from '../../../../../../../../translations';

import type { Report } from '../../../../../../../../types';

import { ActionButton, Dialog } from '../../../../../../../../components';

import type { UserAccessesListSelectedData } from '../../UserAccessesList.types';

import { ListItemStyled, UnorderedListStyled } from './UserAccessesListConfirmDialog.styles';

type UserAccessesListConfirmDialogProps = {
  show: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedData: UserAccessesListSelectedData;
  data: Report | undefined;
};

export const UserAccessesListConfirmDialog = ({
  show,
  isLoading,
  onConfirm,
  onClose,
  selectedData,
  data,
}: UserAccessesListConfirmDialogProps) => {
  const subOwnerAccesses = React.useMemo(() => {
    if (Object.keys(selectedData.subOwners || {}).length === 0) {
      return (
        <Typography variant={'caption'} textTransform={'initial'}>
          <FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_noAccesses} />
        </Typography>
      );
    }

    return Object.keys(selectedData?.subOwners || {}).map(subOwnerId => {
      const subOwner = data?.subOwners?.find(subOwner => subOwner.subOwnerId === subOwnerId);
      const selectedSubOwner = selectedData?.subOwners?.[subOwnerId as keyof typeof selectedData.subOwners];
      let prefix;

      if (selectedSubOwner === '*') {
        prefix = <FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_accessToAllEmployers} />;
      } else {
        prefix = (
          <FormattedMessage
            id={TranslationKeys.users_accessesList_confirmDialog_accessToCountEmployers}
            values={{ count: Object.keys(selectedSubOwner || {}).length }}
          />
        );
      }

      return (
        <ListItemStyled key={subOwnerId}>
          <Typography>{subOwner?.name}</Typography>
          <Typography variant={'caption'} textTransform={'initial'}>
            {prefix}
          </Typography>
        </ListItemStyled>
      );
    });
  }, [data?.subOwners, selectedData]);

  const employerAccesses = React.useMemo(() => {
    if (Object.keys(selectedData.employers || {}).length === 0) {
      return (
        <Typography variant={'caption'} textTransform={'initial'}>
          <FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_noAccesses} />
        </Typography>
      );
    }

    return Object.keys(selectedData?.employers || {}).map(employerId => {
      const employer = data?.employers?.find(employer => employer.employerId === employerId);

      return (
        <ListItemStyled key={employerId}>
          <Typography>{employer?.name}</Typography>
          <Typography variant={'caption'} textTransform={'initial'}>
            <FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_accessToAllSubEmployers} />
          </Typography>
        </ListItemStyled>
      );
    });
  }, [data?.employers, selectedData.employers]);

  const eventFlowsAccesses = React.useMemo(() => {
    if (Object.keys(selectedData.eventFlows || {}).length === 0) {
      return (
        <Typography variant={'caption'} textTransform={'initial'}>
          <FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_noAccesses} />
        </Typography>
      );
    }

    return (selectedData?.eventFlows || []).map(employerId => {
      const employer =
        data?.employers?.find(employer => employer.employerId === employerId) ??
        data?.subOwners
          ?.flatMap(subOwner => subOwner.employers.filter(employer => employer.employerId === employerId))
          .find(employer => employer);

      return (
        <ListItemStyled key={employerId}>
          <Typography>{employer?.name}</Typography>
        </ListItemStyled>
      );
    });
  }, [data?.employers, data?.subOwners, selectedData.eventFlows]);

  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton messageId={TranslationKeys.no} disabled={isLoading} onClick={onClose} variant={'outlined'} />
        <ActionButton messageId={TranslationKeys.yes} disabled={isLoading} onClick={onConfirm} />
      </>
    );
  }, [isLoading, onClose, onConfirm]);

  return (
    <Dialog
      open={show}
      maxWidth={'md'}
      title={<FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_title} />}
      onClose={onClose}
      actions={DialogActions}
      fullWidth
    >
      <>
        <Typography>
          <FormattedMessage id={TranslationKeys.users_accessesList_confirmDialog_header} />
        </Typography>

        <Typography variant={'h6'} marginTop={2} marginBottom={1}>
          <FormattedMessage
            id={TranslationKeys.users_accessesList_subOwners}
            values={{ count: Object.keys(selectedData.subOwners || {}).length }}
          />
        </Typography>

        <UnorderedListStyled>
          <PerfectScrollbar>{subOwnerAccesses}</PerfectScrollbar>
        </UnorderedListStyled>

        <Typography variant={'h6'} marginTop={2} marginBottom={1}>
          <FormattedMessage
            id={TranslationKeys.users_accessesList_employers}
            values={{ count: Object.keys(selectedData.employers || {}).length }}
          />
        </Typography>

        <UnorderedListStyled>
          <PerfectScrollbar>{employerAccesses}</PerfectScrollbar>
        </UnorderedListStyled>

        <Typography variant={'h6'} marginTop={2} marginBottom={1}>
          <FormattedMessage
            id={TranslationKeys.users_accessesList_dialog_title}
            values={{ count: Object.keys(selectedData.eventFlows || {}).length }}
          />
        </Typography>

        <UnorderedListStyled>
          <PerfectScrollbar>{eventFlowsAccesses}</PerfectScrollbar>
        </UnorderedListStyled>
      </>
    </Dialog>
  );
};
