import React from 'react';
import type { FieldProps } from 'formik';
import { getIn } from 'formik';

import type { TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import type { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { useLocale } from '../../Intl';

import { createErrorHandler } from './errorHandler';

type MuiDatePickerPropsHelper = MuiDatePickerProps<any>;

export interface DatePickerProps extends FieldProps, Omit<MuiDatePickerPropsHelper, 'name' | 'value' | 'error'> {
  textField?: TextFieldProps;
}
export function fieldToDatePicker({
  field: { onChange: _onChange, ...field },
  form: { isSubmitting, touched, errors, setFieldValue, setFieldError, setFieldTouched },
  textField: { helperText, onBlur, ...textField } = {},
  disabled,
  label,
  onChange,
  onError,
  slotProps,
  ...props
}: DatePickerProps): MuiDatePickerPropsHelper {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    slotProps: slotProps ?? {
      textField: {
        error: showError,
        helperText: showError ? fieldError : helperText,
        label,
        onBlur:
          onBlur ??
          function () {
            setFieldTouched(field.name, true, true);
          },
        ...textField,
      },
    },
    disabled: disabled ?? isSubmitting,
    onChange:
      onChange ??
      function (date) {
        // Do not switch this order, otherwise you might cause a race condition
        // See https://github.com/formium/formik/issues/2083#issuecomment-884831583
        setFieldTouched(field.name, true, false);
        setFieldValue(field.name, date, true);
      },
    onError: onError ?? createErrorHandler(fieldError, field.name, setFieldError),
    ...field,
    ...props,
  };
}

export function DatePicker({ children, ...props }: React.PropsWithChildren<DatePickerProps>) {
  const { locale } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <MuiDatePicker {...fieldToDatePicker(props)}>{children}</MuiDatePicker>
    </LocalizationProvider>
  );
}

DatePicker.displayName = 'FormikMUIDatePicker';
