import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { EventFlow } from 'app/types';

import { TranslationKeys } from 'app/translations';

import type {
  FilterableTableColumnType,
  FilterableTableOnSortChangeFunc,
  FilterableTablePaginationType,
  FilterableTableRowMapperFunc,
  FilterTableOnPaginationChangeFunc,
} from 'app/components';
import { InputFieldFactory, FilterableTable, Dialog, ActionButton, Checkbox } from 'app/components';
import { Uuid } from '../../../../../../utils';

import { differenceInDays, format } from 'date-fns';
import { useBulkUpdateEventFlow } from '../../../../../../hooks/api/dashboard/useBulkUpdateEventFlow';
import { EventFlowCommentForm } from '../EventFlowCommentForm';
import { CommentColumnStyled } from './EventFlowDetailsTable.styles';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from '../../../../../../store';
import { useEventTypeSelectItems } from '../../../../../../hooks';

import { ActionLink } from '../../../../../../components';

export const EVENT_FLOW_DETAILS_TABLE_DATA_TEST_ID = 'event-flow-details-table-component';

enum TableColumns {
  Checkbox = 'col_checkbox',
  Status = 'col_status',
  Employer = 'col_employer',
  Employee = 'col_employee',
  UserIB = 'col_userib',
  StartDate = 'col_startDate',
  Insurer = 'col_insurer',
  EventType = 'col_eventType',
  Product = 'col_product',
  Days = 'col_days',
  Comments = 'col_comments',
}

type EventFlowDetailsTableProps = {
  isLoading?: boolean;
  eventFlowDetails: EventFlow[];
  pagination: FilterableTablePaginationType;
  onPaginationChange: FilterTableOnPaginationChangeFunc;
  onSortChange: FilterableTableOnSortChangeFunc;
  refetchEventFlow: () => void;
  usersAsOptions: Array<{ value?: string; element?: string; default?: boolean }> | Array<[]>;
};

export const EventFlowDetailsTable = ({
  eventFlowDetails,
  pagination,
  onPaginationChange,
  onSortChange,
  refetchEventFlow,
  usersAsOptions,
  isLoading = false,
}: EventFlowDetailsTableProps) => {
  const intl = useIntl();
  const dispatch = useStoreDispatch();

  const { eventTypeSelectItems } = useEventTypeSelectItems();

  const { selectedRows } = useStoreSelector(state =>
    StoreSelectors.DashboardSelector.selectEventFlowDetails(state.DashboardReducer),
  );

  const [openCommentDialog, setOpenCommentDialog] = useState<EventFlow | null>(null);

  const { isLoading: isUpdatingEventFlowLoading, mutateAsync: updateEventFlowAsync } = useBulkUpdateEventFlow();

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.Checkbox,
        isSelectAllColumn: true,
      },
      {
        name: TableColumns.Status,
        title: <FormattedMessage id={TranslationKeys.global_status} />,
      },
      {
        name: TableColumns.Employer,
        title: <FormattedMessage id={TranslationKeys.global_employer} />,
      },
      {
        name: TableColumns.Employee,
        title: <FormattedMessage id={TranslationKeys.global_employee} />,
      },
      {
        name: TableColumns.UserIB,
        title: <FormattedMessage id={TranslationKeys.global_userIB} />,
      },
      {
        name: TableColumns.StartDate,
        title: <FormattedMessage id={TranslationKeys.global_startDate} />,
      },
      {
        name: TableColumns.Insurer,
        title: <FormattedMessage id={TranslationKeys.global_insurer} />,
      },
      {
        name: TableColumns.EventType,
        title: <FormattedMessage id={TranslationKeys.global_eventType} />,
      },
      {
        name: TableColumns.Product,
        title: <FormattedMessage id={TranslationKeys.global_product} />,
      },
      {
        name: TableColumns.Days,
        title: <FormattedMessage id={TranslationKeys.global_days} />,
      },
      {
        name: TableColumns.Comments,
        title: <FormattedMessage id={TranslationKeys.global_comments} />,
      },
    ];
  }, []);

  const [key] = React.useState(Uuid.newV4);

  const onToggleRow = React.useCallback(
    (eventId: string) => {
      dispatch(StoreActions.DashboardAction.eventFlowDetails.toggleRow(eventId));
    },
    [dispatch],
  );

  const onSelectAllChange = React.useCallback(() => {
    const areAllRowsSelected = selectedRows.length === eventFlowDetails.length;
    if (areAllRowsSelected) {
      dispatch(StoreActions.DashboardAction.eventFlowDetails.selectAllRows([]));
    } else {
      dispatch(StoreActions.DashboardAction.eventFlowDetails.selectAllRows(eventFlowDetails.map(e => e.mongoId)));
    }
  }, [dispatch, eventFlowDetails, selectedRows?.length]);

  const buildStatusColumn = React.useCallback((status: string) => {
    return <FormattedMessage id={`eventflow.category.${status}`} />;
  }, []);

  const buildCheckboxColumn = React.useCallback(
    (eventFlowRow: EventFlow) => {
      return (
        <Checkbox
          onChange={() => onToggleRow(eventFlowRow.mongoId)}
          name={'select-' + eventFlowRow?.eventId}
          checked={!!selectedRows.find(r => r === eventFlowRow.mongoId)}
        />
      );
    },
    [onToggleRow, selectedRows],
  );

  const onUserIdChange = React.useCallback(
    async (eventFlowRow: EventFlow, userId: string) => {
      await updateEventFlowAsync({
        ownerId: eventFlowRow.ownerId,
        eventFlowIds: [eventFlowRow.mongoId],
        userId: userId,
      });
    },
    [updateEventFlowAsync],
  );

  const buildUserName = React.useCallback(
    (eventFlowRow: EventFlow) => {
      //setDefaultValue(eventFlowRow.userId);

      const onChange = ({ value = '' }) => {
        onUserIdChange(eventFlowRow, value);
      };

      return (
        <InputFieldFactory
          key={`ib-user-select-${key}`}
          disabled={isLoading || isUpdatingEventFlowLoading}
          onChange={onChange}
          field={{
            type: 'select',
            name: 'userId',
            label: undefined,
            fullWidth: false,
            value: eventFlowRow.userId || null,
            items: usersAsOptions.slice(1, usersAsOptions.length),
          }}
        />
      );
    },
    [isLoading, key, onUserIdChange, usersAsOptions, isUpdatingEventFlowLoading],
  );

  // Get days from updated at date
  const buildDays = React.useCallback((date: string) => {
    const updatedAtDate = new Date(date);
    if (isNaN(updatedAtDate.getTime())) {
      return 0;
    }
    return differenceInDays(new Date(), updatedAtDate);
  }, []);

  const formatEventDate = React.useCallback((date: string) => {
    const eventDate: Date = new Date(date);
    if (isNaN(eventDate.getTime())) {
      return '-';
    }
    return format(eventDate, 'dd/MM/y');
  }, []);

  const formatComment = React.useCallback(
    (eventFlowRow: EventFlow) => {
      const truncateComment =
        eventFlowRow?.comment?.length > 25 ? eventFlowRow?.comment.slice(0, 25) + '...' : eventFlowRow?.comment;

      return eventFlowRow?.comment ? (
        <CommentColumnStyled onClick={() => setOpenCommentDialog(eventFlowRow)}>
          {truncateComment || intl.formatMessage({ id: TranslationKeys.eventflow_comment_placeholder })}
        </CommentColumnStyled>
      ) : (
        <ActionButton
          size={'small'}
          messageId={'eventflow.comment.placeholder'}
          onClick={() => setOpenCommentDialog(eventFlowRow)}
        />
      );
    },
    [intl],
  );

  const buildEventType = React.useCallback(
    (eventTypeRaw: string) => {
      return eventTypeSelectItems.find(item => item.value === eventTypeRaw)?.element;
    },
    [eventTypeSelectItems],
  );

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<EventFlow>>(
    data => {
      return {
        data: {
          [TableColumns.Checkbox]: buildCheckboxColumn(data),
          [TableColumns.Status]: buildStatusColumn(data.status),
          [TableColumns.Comments]: formatComment(data),
          [TableColumns.Employer]: (
            <ActionLink to={`/employers/${data.employerId}`} target={'_blank'}>
              {data.employerName}
            </ActionLink>
          ),
          [TableColumns.Employee]: (
            <ActionLink to={`/employers/${data.employerId}/employees/${data.employeeId}`} target={'_blank'}>
              {data.employeeName}
            </ActionLink>
          ),
          [TableColumns.UserIB]: buildUserName(data),
          [TableColumns.StartDate]: formatEventDate(data.eventDate),
          [TableColumns.Insurer]: data.insurerName,
          [TableColumns.EventType]: buildEventType(data.eventType),
          [TableColumns.Product]: data.productName,
          [TableColumns.Days]: buildDays(data.updatedAt),
        },
      };
    },
    [buildUserName, formatEventDate, buildDays, formatComment, buildCheckboxColumn, buildEventType, buildStatusColumn],
  );

  const onUpdateRowComment = React.useCallback(
    async (eventFlow: EventFlow, comment: string) => {
      if (!isUpdatingEventFlowLoading) {
        await updateEventFlowAsync({
          eventFlowIds: [eventFlow.mongoId],
          comment: comment,
          ownerId: eventFlow.ownerId,
        });
        setOpenCommentDialog(null);
        await refetchEventFlow();
      }
    },
    [refetchEventFlow, updateEventFlowAsync, isUpdatingEventFlowLoading],
  );

  return (
    <div data-testid={EVENT_FLOW_DETAILS_TABLE_DATA_TEST_ID}>
      <FilterableTable<EventFlow>
        loading={isLoading}
        columns={tableHeaders}
        rows={eventFlowDetails}
        rowMapper={tableRowMapper}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        selectedRowsIds={selectedRows}
        onSelectAllChange={onSelectAllChange}
      />
      <Dialog
        open={!!openCommentDialog}
        title={intl.formatMessage({ id: TranslationKeys.eventflow_comment_dialog_title })}
        onClose={() => setOpenCommentDialog(null)}
      >
        <EventFlowCommentForm
          eventFlow={openCommentDialog || null}
          onUpdateRowComment={onUpdateRowComment}
          isUpdatingEventFlowLoading={isUpdatingEventFlowLoading}
        />
      </Dialog>
    </div>
  );
};
