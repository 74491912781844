import React from 'react';
import { useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { Employer, UseQueryWrapper } from '../../../types';

import type { GetOwnerEmployersParams } from '../../../Domain/Employers/Services/EmployerService';
import { employerService } from '../../../Domain/Employers/Services/EmployerService';

import { useSnakeBar } from '../../useSnakeBar';

type GetOwnerEmployersQueryResultData = {
  data: Array<Employer>;
  totalCount: number;
};

const queryFn = (variables: GetOwnerEmployersParams) => async () => {
  const response = await employerService.getOwnerEmployers(variables);
  return (response as AxiosResponse).data;
};

const QUERY_KEY = 'getOwnerEmployers';

export const useOwnerGetEmployersQuery: UseQueryWrapper<GetOwnerEmployersQueryResultData, GetOwnerEmployersParams> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetOwnerEmployersQueryResultData>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
