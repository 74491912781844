import { put, select, takeLatest, debounce } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { getCredentialRequests, getOwnerCredentialRequests } from '../Services/CredentialService';
import { MESSAGE_SEVERITY_ERROR, SUPER_ADMIN_OWNER_ID } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  RequestData: '[CredentialRequests] Request',
  FulfilledTable: '[CredentialRequests] Fulfilled',
  SearchTable: '[CredentialRequests] Search',
  ChangePage: '[CredentialRequests] change page',
  SetPageSize: '[CredentialRequests] set page size',
  SortTable: '[CredentialRequests] Sort',
  ApplyFilter: '[CredentialRequests] Apply filter',
  RemoveFilter: '[CredentialRequests] Remove filter',
};

const initialState = {
  ...tableInitialState,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestCredentialRequests() {
    try {
      const currentState = yield select(state => {
        return state.CredentialRequestsReducer;
      });

      const ownerId = yield select(state => {
        return state.AppReducer.environment.ownerId;
      });

      const response =
        ownerId === SUPER_ADMIN_OWNER_ID
          ? yield getCredentialRequests(
              currentState.page,
              currentState.pageSize,
              currentState.sortBy,
              currentState.filters,
              currentState.searchQuery,
            )
          : yield getOwnerCredentialRequests(
              currentState.page,
              currentState.pageSize,
              currentState.sortBy,
              currentState.filters,
              currentState.searchQuery,
              ownerId,
            );

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getCredentialRequests',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });
}
