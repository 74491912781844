import { styled } from '@mui/material/styles';
import { Divider, List } from '@mui/material';

export const ListStyled = styled(List, {
  name: 'ListStyled',
})(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
}));

export const DividerStyled = styled(Divider, {
  name: 'DividerStyled',
})(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
