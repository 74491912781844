import { styled } from '@mui/material/styles';
import { buttonBaseClasses, formControlLabelClasses, gridClasses, Typography } from '@mui/material';

import { Checkbox } from '../../../../../components/Checkbox';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(1),
  [`& .${gridClasses['spacing-xs-3']} > .${gridClasses.item}`]: {
    padding: theme.spacing(0.5, 1.5),
  },
  [theme.breakpoints.down('sm')]: {
    [`& .${gridClasses['spacing-xs-3']}`]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      [`& > .${gridClasses.item}`]: {
        width: '100%',
      },
    },
  },
}));

export const TypographyCheckBoxHeaderStyled = styled(Typography, {
  name: 'TypographyCheckBoxHeaderStyled',
})(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

export const CheckboxStyled = styled(Checkbox, {
  name: 'CheckboxStyled',
})(({ theme }) => ({
  [`& .${formControlLabelClasses.root}`]: {
    marginLeft: theme.spacing(-1),
  },
  [`& .${buttonBaseClasses.root}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
