import React from 'react';

import type { SkeletonProps as MuiSkeletonProps } from '@mui/material/Skeleton';
import MuiSkeleton from '@mui/material/Skeleton';

export const SKELETON_DATA_TEST_ID = 'skeleton-component';

type SkeletonProps = {
  className?: string;
  width?: number | string;
  height?: number | string;
  variant?: MuiSkeletonProps['variant'];
};

export const Skeleton = ({ className, width, height, variant }: SkeletonProps) => {
  return (
    <MuiSkeleton
      data-testid={SKELETON_DATA_TEST_ID}
      className={className}
      width={width}
      height={height}
      variant={variant}
    />
  );
};
