import axios from 'axios';

const login = async (values: Record<string, any>) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.post('/auth/login', { ...values });
};

const tokenRefresh = async (values: Record<string, any>) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.post('/token/refresh', { ...values });
};

const getUserWithToken = async (token: string, userId: string) => {
  return axios.get(`/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export type ResetPasswordValues = {
  token: string;
  userId: string;
  password: string;
};

const resetPassword = async (values: ResetPasswordValues) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.put(`/auth/reset-password/${values.userId}`, { ...values });
};

const checkResetPasswordLink = async (token: string, userId: string) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.get(`/auth/check-reset-password-link/${userId}/${token}`);
};

const checkReset2FaLink = async (token: string, userId: string) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.get(`/auth/check-reset-2fa-link/${userId}/${token}`);
};

type Reset2FaCodeParams = {
  token: string;
  userId: string;
};

const reset2FaCode = async (params: Reset2FaCodeParams) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.put(`/auth/request-reset-2fa/${params.userId}`, { ...params });
};

const forgotPasswordRequest = async (email: string) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.post('/auth/request-reset-password-link', { email });
};

const forgot2FaCodeRequest = async (email: string) => {
  const instance = axios.create({
    validateStatus(status) {
      return status === 401 || (status >= 200 && status < 300);
    },
  });
  return instance.post('/auth/request-reset-2fa-link', { email });
};

export const appService = {
  login,
  tokenRefresh,
  getUserWithToken,
  resetPassword,
  checkResetPasswordLink,
  forgotPasswordRequest,
  forgot2FaCodeRequest,
  checkReset2FaLink,
  reset2FaCode,
};
