import type { FuncOverrideComponent } from '../theme.types';

export const MuiButtonGroup: FuncOverrideComponent<'MuiButtonGroup'> = () => ({
  styleOverrides: {
    contained: {
      boxShadow: 'none',
    },
    groupedContained: {
      borderRadius: '20px',
      boxShadow: 'inherited',
      textTransform: 'initial',
    },
    groupedContainedPrimary: {
      '&:not(:last-child)': {
        borderRight: 'none',
      },
    },
  },
});
