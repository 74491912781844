import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { Owner, UseQueryWrapper } from '../../../types';

import ownerService from '../../../Domain/Owners/Services/OwnerService';

import { useSnakeBar } from '../../useSnakeBar';

export type GetOwnerQueryVariables = Partial<Pick<Owner, 'ownerId'>>;

export type GetOwnerQueryResultData = Owner;

const queryFn =
  ({ ownerId }: GetOwnerQueryVariables) =>
  async () => {
    if (ownerId) {
      const response = await ownerService.getOwner(ownerId);
      return response.data;
    }
  };

const QUERY_KEY = 'getOwner';

export const useOwnerGetOwnerQuery: UseQueryWrapper<GetOwnerQueryResultData, GetOwnerQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetOwnerQueryResultData>([QUERY_KEY, variables.ownerId], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
