import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { ConnectionErrorsDetail, UseQueryWrapper } from '../../../types';

import type { GetConnectionErrorsDetailsParams } from '../../../Domain/Dashboard/Services';
import { dashboardService } from '../../../Domain/Dashboard/Services';

import { useSnakeBar } from '../../useSnakeBar';

type GetConnectionErrorsQueryVariables = GetConnectionErrorsDetailsParams;

type GetConnectionErrorsQueryResultData = {
  data: ConnectionErrorsDetail[];
  totalCount: number;
};

const queryFn = (variables: GetConnectionErrorsQueryVariables) => async () => {
  const response = await dashboardService.getConnectionErrorsDetails(variables);
  return response.data;
};

const QUERY_KEY = 'getConnectionErrorsDetails';

export const useDashboardGetConnectionErrorsDetailsQuery: UseQueryWrapper<
  GetConnectionErrorsQueryResultData,
  GetConnectionErrorsQueryVariables
> = ({ variables, options }) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  const select = React.useCallback(data => {
    return {
      data: data['hydra:member'],
      totalCount: data['hydra:totalItems'],
    };
  }, []);

  return useQuery<GetConnectionErrorsQueryResultData>([QUERY_KEY, ...Object.values(variables)], queryFn(variables), {
    select,
    onError,
    ...(options || {}),
  });
};
