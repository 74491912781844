import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import type { SummaryScales } from 'app/types';

import { TranslationKeys } from 'app/translations';

import { saveFile } from 'app/utils';

import type { TableSelectedState } from 'app/store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import { useSettingsGetAllSummaryScalesQuery, useSnakeBar } from 'app/hooks';

import { ListFile } from 'app/components/FileUploadAreaWithTemplate/ListFile';
import { ConfirmDialog, useBreadcrumb } from 'app/components';

import { RateTableFileReadStrategy } from 'app/Domain/Settings/Components/RateTableForm/components/RateTableFileUpload';

import { SettingsSummaryScales, SettingsSummaryScalesToolbar } from './components';
import { useSettingsSummaryScaleTableMutation } from '../../../../hooks/api/settings/useSettingsDeleteSummaryScaleMutation';
import {
  SCALES_TABLE_DOWNLOAD_FILE_HEADERS,
  SCALES_TABLE_DOWNLOAD_FILE_STRUCTURE,
} from '../../Components/ScalesTableForm/components';

export const SettingsSummaryScalesPage = () => {
  const dispatch = useStoreDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: deleteSummaryScaleMutation, isLoading: isDeleting } = useSettingsSummaryScaleTableMutation();

  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState(false);
  const [summaryScaleIdToDelete, setSummaryScaleIdToDelete] = React.useState<
    SummaryScales['summaryOfRegulationScaleId'] | null
  >(null);

  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const { searchQuery, sortBy, page, pageSize } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.SettingsSelector.selectSummaryScales(state.SettingsReducer),
  );

  const {
    data: summaryScales,
    isLoading: getAllSummaryScalesQueryLoading,
    isFetching: getAllSummaryScalesQueryFetching,
    refetch: refetchGetAllSummaryScalesQuery,
  } = useSettingsGetAllSummaryScalesQuery({
    variables: {
      ownerId,
      page,
      pageSize,
      searchQuery,
      sortBy,
    },
    options: {
      enabled: !!ownerId,
    },
  });

  const isLoading = getAllSummaryScalesQueryLoading || getAllSummaryScalesQueryFetching || isDeleting;

  const onSearchChange = React.useCallback(
    event => {
      dispatch(StoreActions.SettingsActions.summaryScales.search(event.target.value, false));
    },
    [dispatch],
  );

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.SettingsActions.summaryScales.sortTable(sortBy));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.SettingsActions.summaryScales.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.SettingsActions.summaryScales.changePage(page));
      }
    },
    [dispatch],
  );

  const onDeleteActionClick = React.useCallback(summaryScaleId => {
    setConfirmDeleteModal(true);
    setSummaryScaleIdToDelete(summaryScaleId);
  }, []);

  const deleteSummaryScale = React.useCallback(() => {
    if (summaryScaleIdToDelete) {
      const method = 'deleteSummaryScale';

      deleteSummaryScaleMutation(
        { ownerId, summaryScaleId: summaryScaleIdToDelete },
        {
          onSuccess: () => {
            refetchGetAllSummaryScalesQuery();
            setSummaryScaleIdToDelete(null);
            showSuccessSnakeBar({ method });
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error.message });
          },
        },
      );
    }
  }, [
    deleteSummaryScaleMutation,
    ownerId,
    summaryScaleIdToDelete,
    refetchGetAllSummaryScalesQuery,
    showErrorSnakeBar,
    showSuccessSnakeBar,
  ]);

  const onDownloadActionClick = React.useCallback(
    (table: SummaryScales) => {
      const strategy = new RateTableFileReadStrategy(intl);
      const listFile = new ListFile([...SCALES_TABLE_DOWNLOAD_FILE_HEADERS], strategy);

      const keys = SCALES_TABLE_DOWNLOAD_FILE_STRUCTURE.map(fileStructure => fileStructure.fieldName);
      const sheet = listFile.generateDataSheet([table.agePercentages] || [], keys);
      const blob = listFile.sheetToCsv(sheet, { FS: ';' });
      const fileName = `${table.title}_${new Date().getTime()}.csv`;

      saveFile(blob, fileName);
    },
    [intl],
  );

  useBreadcrumb(TranslationKeys.menu_settings_summaryScales, {
    hideTrail: true,
    hideTitle: true,
    otherElements: (
      <SettingsSummaryScalesToolbar
        isLoading={isLoading}
        totalCount={summaryScales?.totalCount ?? 0}
        onAddButtonClick={() => navigate('/settings/summary-scales/new')}
        onSearchChange={onSearchChange}
        initialSearchQuery={searchQuery}
      />
    ),
  });

  return (
    <>
      <Box marginTop={2}>
        <SettingsSummaryScales
          isLoading={isLoading}
          summaryScales={summaryScales?.data || []}
          onDeleteActionClick={onDeleteActionClick}
          onDownloadActionClick={onDownloadActionClick}
          onSortChange={onSortChange}
          onPaginationChange={onPaginationChange}
          pagination={{
            page,
            pageSize,
            totalCount: summaryScales?.totalCount ?? 0,
          }}
        />
      </Box>

      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.settings_summaryScales_confirmDeleteTitle} />}
          open={confirmDeleteModal}
          setOpen={setConfirmDeleteModal}
          onConfirm={deleteSummaryScale}
        >
          <FormattedMessage id={TranslationKeys.settings_summaryScales_confirmDeleteMessage} />
        </ConfirmDialog>
      )}
    </>
  );
};
