import React from 'react';

import { DialogContentText } from '@mui/material';

import { TranslationKeys } from '../../translations';

import type { SelectedAppState } from '../../store';
import { useStoreSelector } from '../../store';

import ActionButton from '../ActionButton';
import { Dialog } from '../Dialog';

type ConfirmDialogProps = {
  children: React.ReactElement;
  title: React.ReactElement | string;
  open: boolean;
  onClose?: () => void;
  setOpen?: (state: boolean) => void;
  onConfirm?: () => void;
};

export const ConfirmDialog = ({ title, children, open, setOpen, onConfirm, onClose }: ConfirmDialogProps) => {
  const { loading: appLoading } = useStoreSelector<SelectedAppState>(state => state.AppReducer);

  const [loading, setLoading] = React.useState(false);

  const closeDialog = React.useCallback(() => {
    onClose && onClose();
    setOpen && setOpen(false);
  }, [onClose, setOpen]);

  const onConfirmButtonClick = React.useCallback(() => {
    setLoading(true);
    onConfirm && onConfirm();
    closeDialog();
    setLoading(false);
  }, [closeDialog, onConfirm]);

  const DialogActions = React.useMemo(() => {
    return (
      <>
        <ActionButton messageId={TranslationKeys.no} onClick={closeDialog} variant="outlined" />
        <ActionButton messageId={TranslationKeys.yes} onClick={onConfirmButtonClick} disabled={loading || appLoading} />
      </>
    );
  }, [appLoading, closeDialog, loading, onConfirmButtonClick]);

  return (
    <Dialog open={open} title={title} onClose={closeDialog} actions={DialogActions}>
      <DialogContentText>{children}</DialogContentText>
    </Dialog>
  );
};
