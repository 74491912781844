import React, { useCallback, useMemo } from 'react';
import { Checkbox, InputLabel, Select as MuiSelect, Tooltip } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import { SearchInput } from 'app/components';
import Chip from '@mui/material/Chip';
import Dropdown from './Dropdown';
import { HasAccessTo } from 'app/components/Authorization';
import { CONNECTION, EVENT } from 'app/common/Authorization/entities';
import { DOWNLOAD, EDIT } from 'app/common/Authorization/permissions';

import {
  DatePickerStyled,
  DivRowStyled,
  DropdownStyled,
  FormControlStyled,
  ResetButtonStyled,
  SelectStyled,
  SpacerStyled,
} from './EventsToolbar.styles';
import { TranslationKeys } from '../../../../translations';
import { useConnectionEventTypeSelectItems } from '../../../../hooks/useConnectionEventTypeSelectItems';

const EventsToolbar = props => {
  const {
    isRegularConnection,
    handleSearch,
    filters,
    filtersCheck,
    applyFilter,
    createDraftBatch,
    processEvents,
    processAllEvents,
    archiveEvents,
    archiveAllEvents,
    unArchiveEvents,
    unArchiveAllEvents,
    hasSelectedItems,
    allEventsArchived,
    allEventsUnarchived,
    resetFilters,
    downloadEvents,
    downloadAllEvents,
    intl,
    archiveSamePeriodEvents,
    initialSearchQuery,
  } = props;

  const filterEventDateAfter = filters['eventDate[after]'];
  const filterEventDateBefore = filters['eventDate[before]'];

  const archiveTooltip = () =>
    !hasSelectedItems
      ? intl.formatMessage({ id: 'tooltip_events_nothing_selected' })
      : intl.formatMessage({ id: 'tooltip_events_already_archived' });

  const unArchiveTooltip = () =>
    !hasSelectedItems
      ? intl.formatMessage({ id: 'tooltip_events_nothing_selected' })
      : intl.formatMessage({ id: 'tooltip_events_already_unarchived' });

  const disabledIfNoAccessToEventEdit = !HasAccessTo(EVENT, EDIT);
  const disabledIfNoAccessToConnectionDownload = !HasAccessTo(CONNECTION, DOWNLOAD);

  const { connectionEventTypes } = useConnectionEventTypeSelectItems();

  const dropdownOptions = {
    draftBatch: [
      {
        title: intl.formatMessage({ id: TranslationKeys.events_draftBatch_createSelected }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: TranslationKeys.events_draftBatch_confirmDialog_title }),
          message: intl.formatMessage({ id: TranslationKeys.events_draftBatch_confirmDialog_message }),
        },
        action: () => createDraftBatch(false),
        disabled: disabledIfNoAccessToEventEdit || !hasSelectedItems,
      },
      {
        title: intl.formatMessage({ id: TranslationKeys.events_draftBatch_createAll }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: TranslationKeys.events_draftBatch_confirmDialog_title }),
          message: intl.formatMessage({ id: TranslationKeys.events_draftBatch_confirmDialog_message }),
        },
        action: () => createDraftBatch(true),
        disabled: disabledIfNoAccessToEventEdit,
      },
    ],
    process: [
      {
        title: intl.formatMessage({ id: 'events.processAction' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.processConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.processConfirmMessage' }),
        },
        action: processEvents,
        disabled: disabledIfNoAccessToEventEdit || !hasSelectedItems,
      },
      {
        title: intl.formatMessage({ id: 'events.processAllAction' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.processConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.processAllConfirmMessage' }),
        },
        action: processAllEvents,
        disabled: disabledIfNoAccessToEventEdit,
      },
    ],
    archive: [
      {
        title: intl.formatMessage({ id: 'events.archiveAction' }),
        tooltip: archiveTooltip(),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.archiveConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.archiveConfirmMessage' }),
        },
        action: archiveEvents,
        disabled: disabledIfNoAccessToEventEdit || !hasSelectedItems || !allEventsUnarchived,
      },
      {
        title: intl.formatMessage({ id: 'events.archiveAllAction' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.archiveConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.archiveAllConfirmMessage' }),
        },
        action: archiveAllEvents,
        disabled: disabledIfNoAccessToEventEdit,
      },
      {
        title: intl.formatMessage({ id: 'events.archiveAllSamePeriodAction' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.archiveSamePeriodConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.archiveSamePeriodConfirmMessage' }),
        },
        action: archiveSamePeriodEvents,
        disabled: disabledIfNoAccessToEventEdit,
      },
    ],
    unArchive: [
      {
        title: intl.formatMessage({ id: 'events.unArchiveAction' }),
        tooltip: unArchiveTooltip(),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.unArchiveConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.unArchiveConfirmMessage' }),
        },
        action: unArchiveEvents,
        disabled: disabledIfNoAccessToEventEdit || !hasSelectedItems || !allEventsArchived,
      },
      {
        title: intl.formatMessage({ id: 'events.unArchiveAllAction' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.unArchiveConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.unArchiveAllConfirmMessage' }),
        },
        action: unArchiveAllEvents,
        disabled: disabledIfNoAccessToEventEdit,
      },
    ],
    download: [
      {
        title: intl.formatMessage({ id: 'events.download' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.downloadConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.downloadConfirmMessage' }),
        },
        action: downloadEvents,
        disabled: disabledIfNoAccessToConnectionDownload || !hasSelectedItems,
      },
      {
        title: intl.formatMessage({ id: 'events.downloadAll' }),
        tooltip: intl.formatMessage({ id: 'tooltip_events_nothing_selected' }),
        confirmDialog: {
          title: intl.formatMessage({ id: 'events.downloadConfirmTitle' }),
          message: intl.formatMessage({ id: 'events.downloadAllConfirmMessage' }),
        },
        action: downloadAllEvents,
        disabled: disabledIfNoAccessToConnectionDownload,
      },
    ],
  };

  const handleFromDateChange = ({ value: date }) => {
    applyFilter('eventDate[after]', date);
  };

  const handleUntilDateChange = ({ value: date }) => {
    applyFilter('eventDate[before]', date);
  };

  const changeEventType = useCallback(
    event => {
      applyFilter('eventType', event.target.value ? event.target.value : undefined);
    },
    [applyFilter],
  );

  const changeArchived = useCallback(
    ({ value }) => {
      applyFilter('archived', value);
    },
    [applyFilter],
  );

  const changeCorrection = useCallback(
    ({ value }) => {
      applyFilter('correction', value);
    },
    [applyFilter],
  );

  const eventsFields = useMemo(() => {
    return {
      from: {
        type: 'date',
        name: 'from',
        label: intl.formatMessage({ id: 'events.datePickerFrom' }),
        value: filterEventDateAfter ?? null,
        fullWidth: false,
        options: {
          startOfDay: true,
        },
      },
      until: {
        type: 'date',
        name: 'until',
        label: intl.formatMessage({ id: 'events.datePickerUntil' }),
        value: filterEventDateBefore ?? null,
        fullWidth: false,
        options: {
          endOfDay: true,
        },
      },
      archived: {
        type: 'select',
        name: 'archived',
        label: intl.formatMessage({ id: 'events.archived' }),
        fullWidth: false,
        value: filters.archived,
        displayEmptyOption: true,
        items: [
          {
            element: 'all',
            value: '',
          },
          {
            element: 'yes',
            value: true,
          },
          {
            element: 'no',
            value: false,
          },
        ],
      },
      correction: {
        type: 'select',
        name: 'correction',
        label: intl.formatMessage({ id: 'events.correction' }),
        fullWidth: false,
        value: filters.correction,
        displayEmptyOption: true,
        items: [
          {
            element: 'all',
            value: null,
          },
          {
            element: 'yes',
            value: true,
          },
          {
            element: 'no',
            value: false,
          },
        ],
      },
    };
  }, [filterEventDateAfter, filterEventDateBefore, filters.archived, filters.correction, intl]);

  return (
    <>
      <DivRowStyled>
        {isRegularConnection && <DropdownStyled options={dropdownOptions.draftBatch} intl />}
        <DropdownStyled options={dropdownOptions.process} intl />
        <DropdownStyled options={dropdownOptions.archive} intl />
        <DropdownStyled options={dropdownOptions.unArchive} />
        <Tooltip title={filtersCheck ? intl.formatMessage({ id: 'tooltip_events_no_filters_applied' }) : ''}>
          <span>
            <ResetButtonStyled onClick={resetFilters} disabled={filtersCheck}>
              <FormattedMessage id="events.resetFilters" />
            </ResetButtonStyled>
          </span>
        </Tooltip>
        <SpacerStyled />
        <Dropdown options={dropdownOptions.download} />
      </DivRowStyled>
      <DivRowStyled>
        <FormControlStyled>
          <InputLabel>{intl.formatMessage({ id: TranslationKeys.global_eventType })}</InputLabel>
          <MuiSelect
            multiple
            value={filters.eventType ?? []}
            onChange={changeEventType}
            renderValue={selected => (
              <div>
                {selected.map(value => (
                  <Chip key={value} label={value} />
                ))}
              </div>
            )}
          >
            {connectionEventTypes.map(type => (
              <MenuItem
                key={type.value}
                value={type.value}
                selected={filters.eventType ? filters.eventType.includes(type.value) : false}
              >
                <Checkbox
                  color={'secondary'}
                  checked={filters.eventType ? filters.eventType.includes(type.value) : false}
                />
                {type.element}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControlStyled>
        <SelectStyled
          key={`archived_${eventsFields.archived.value}`}
          field={eventsFields.archived}
          onChange={changeArchived}
        />
        <DatePickerStyled
          key={`from_${eventsFields.from.value}`}
          field={eventsFields.from}
          onChange={handleFromDateChange}
        />
        <DatePickerStyled
          key={`until_${eventsFields.until.value}`}
          field={eventsFields.until}
          onChange={handleUntilDateChange}
        />
        <SelectStyled
          key={`archived_${eventsFields.correction.value}`}
          field={eventsFields.correction}
          onChange={changeCorrection}
        />
        <SpacerStyled />
        <SearchInput
          onChange={handleSearch}
          placeholder={intl.formatMessage({ id: 'events.search' })}
          initialSearchQuery={initialSearchQuery}
        />
      </DivRowStyled>
    </>
  );
};

export default injectIntl(EventsToolbar);
