import React from 'react';
import { useIntl } from 'react-intl';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { normalizeFormError } from '../../../../utils';

import { TranslationKeys } from '../../../../translations';

import { appService } from '../../../../Domain/App/Services/AppServices';

import { ResetPasswordViewForm, ResetPasswordViewInvalidLinkMsg } from './components';

import type { ResetPassViewFormValues } from './ResetPasswordViewContent.types';

export const RESET_PASSWORD_VIEW_CONTENT_DATA_TEST_ID = 'reset-password-view-content-component';

type ResetPasswordViewContentProps = {
  token: string | null;
  userId: string | null;
  isLinkValid: boolean;
};

export const ResetPasswordViewContent = ({ token, userId, isLinkValid }: ResetPasswordViewContentProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const formikInitialValues = React.useMemo<ResetPassViewFormValues>(() => {
    return {
      password: '',
      passwordConfirmation: '',
    };
  }, []);

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      password: Yup.string().matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])(?!.*\s).{10,}$/,
        intl.formatMessage({ id: TranslationKeys.resetPassword_passwordCriteriaError }),
      ),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref('password')],
        intl.formatMessage({ id: TranslationKeys.resetPassword_passwordMismatchError }),
      ),
    });
  }, [intl]);

  const formikOnSubmit = React.useCallback<FormikConfig<ResetPassViewFormValues>['onSubmit']>(
    async (values, form) => {
      try {
        if (userId && token) {
          const data = await appService.resetPassword({
            token,
            userId,
            password: values.password,
          });
          if (data.data.code === 401) {
            form.setStatus(data.data.message);
          } else {
            navigate('/sign-in');
          }
        }
      } catch (error: any) {
        const errors = normalizeFormError(error, form);
        if (!errors.violations) {
          throw errors.error;
        }
      }
    },
    [navigate, token, userId],
  );

  return (
    <div data-testid={RESET_PASSWORD_VIEW_CONTENT_DATA_TEST_ID}>
      {!isLinkValid && <ResetPasswordViewInvalidLinkMsg />}

      {isLinkValid && (
        <Formik<ResetPassViewFormValues>
          initialValues={formikInitialValues}
          validationSchema={formikValidationSchema}
          onSubmit={formikOnSubmit}
        >
          <ResetPasswordViewForm />
        </Formik>
      )}
    </div>
  );
};
