import React from 'react';
import { useIntl } from 'react-intl';
import { Form, useFormikContext } from 'formik';

import { Alert, Box } from '@mui/material';

import { TranslationKeys } from '../../../../translations';

import { ActionButton, InputFieldFactory } from '../../../../components';

import type { ForgotPassViewFormValues } from '../../ForgotPasswordView.types';
import { FORGOT_PASS_VIEW_FORM_FIELDS } from '../../ForgotPasswordView.types';

export const FORGOT_PASSWORD_VIEW_FORM_DATA_TEST_ID = 'forgot-password-view-form-component';

export const ForgotPasswordViewForm = () => {
  const intl = useIntl();

  const { submitForm, status, isValid, isSubmitting } = useFormikContext<ForgotPassViewFormValues>();

  return (
    <Form data-testid={FORGOT_PASSWORD_VIEW_FORM_DATA_TEST_ID}>
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        {status && <Alert severity="error">{status}</Alert>}

        <InputFieldFactory
          field={{
            required: true,
            type: 'email',
            name: FORGOT_PASS_VIEW_FORM_FIELDS.Email,
            header: intl.formatMessage({ id: TranslationKeys.global_email }),
          }}
        />

        <Box>
          <ActionButton
            messageId={TranslationKeys.forgotPassword_button}
            disabled={!isValid || isSubmitting}
            type={'submit'}
            onClick={submitForm}
          />
        </Box>
      </Box>
    </Form>
  );
};
