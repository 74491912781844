import React from 'react';
import { Form, useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box } from '@mui/material';

import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import type { Role, User } from '../../../../../../../../types';

import { TranslationKeys } from '../../../../../../../../translations';

import { enabledLocales } from '../../../../../../../../components/Intl';
import { ActionButton, DetailsPanel, IsOwnerAdmin, IsSuperAdmin } from '../../../../../../../../components';
import { EditableFieldFactory } from '../../../../../../../../components/FormikField/Factory/EditableFieldFactory';

import type { UserDetailsPanelFormValues } from '../../UserDetailsPanel.types';
import { UserDetailsPanelFields } from '../../UserDetailsPanel.types';
import { OWNER } from '../../../../../../../../common/Authorization/entities';

type UserDetailsPanelFormProps = {
  isLoading?: boolean;
  isLoggedInUser?: boolean;
  disabledActionButtonsWhileLoading?: boolean;
  isEdit: boolean;
  setIsEdit: (flag: boolean) => void;
  roles: Role[];
  user: User | undefined;
};

const FIELD_VARIANT = 'slim';

export const UserDetailsPanelForm = ({
  setIsEdit,
  isEdit,
  roles,
  user,
  disabledActionButtonsWhileLoading = false,
  isLoggedInUser = false,
  isLoading = false,
}: UserDetailsPanelFormProps) => {
  const intl = useIntl();

  const { initialValues, values, submitForm, resetForm } = useFormikContext<UserDetailsPanelFormValues>();

  const isSuperAdmin = IsSuperAdmin();
  const isOwnerAdmin = IsOwnerAdmin();

  const canEditRole = (isSuperAdmin || isOwnerAdmin) && !isLoggedInUser;

  const rolesSelectItems = React.useMemo(() => {
    return roles
      .filter(item => item.owner && item.owner.includes(values?.ownerId || ''))
      .map(role => ({
        element: role.name,
        value: role.roleId,
      }));
  }, [roles, values.ownerId]);

  const languagesSelectItems = React.useMemo(() => {
    return Object.keys(enabledLocales).map(language => ({
      element: intl.formatMessage({ id: `translations.${language}` }),
      value: language,
    }));
  }, [intl]);

  const onClickEditButton = React.useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const onClickCancelButton = React.useCallback(() => {
    resetForm();
    setIsEdit(false);
  }, [resetForm, setIsEdit]);

  const onClickSubmit = React.useCallback(() => {
    const hasChangedValues = JSON.stringify(initialValues) !== JSON.stringify(values);

    if (hasChangedValues) {
      submitForm();
    } else {
      setIsEdit(false);
    }
  }, [initialValues, setIsEdit, submitForm, values]);

  const DetailsPanelColumns = React.useMemo(() => {
    const personalRows = {
      [UserDetailsPanelFields.FirstName.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.firstName}
          field={{
            variant: FIELD_VARIANT,
            name: UserDetailsPanelFields.FirstName.InputName,
            schema: UserDetailsPanelFields.FirstName.schema,
            required: true,
          }}
        />
      ),
      [UserDetailsPanelFields.MiddleName.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.middleName}
          field={{
            variant: FIELD_VARIANT,
            name: UserDetailsPanelFields.MiddleName.InputName,
          }}
        />
      ),
      [UserDetailsPanelFields.LastName.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.lastName}
          field={{
            variant: FIELD_VARIANT,
            name: UserDetailsPanelFields.LastName.InputName,
            schema: UserDetailsPanelFields.LastName.schema,
            required: true,
          }}
        />
      ),
      [UserDetailsPanelFields.EmailAddress.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.emailAddress}
          field={{
            variant: FIELD_VARIANT,
            name: UserDetailsPanelFields.EmailAddress.InputName,
            schema: UserDetailsPanelFields.EmailAddress.schema,
            required: true,
          }}
        />
      ),
      [UserDetailsPanelFields.RoleId.Label]:
        user?.roleName.toUpperCase() !== OWNER ? (
          <EditableFieldFactory
            editing={isEdit}
            value={initialValues.roleId}
            field={{
              variant: FIELD_VARIANT,
              type: 'select',
              name: UserDetailsPanelFields.RoleId.InputName,
              items: rolesSelectItems,
              schema: UserDetailsPanelFields.RoleId.schema,
              disabled: !canEditRole,
              required: true,
            }}
          />
        ) : (
          user?.roleName
        ),
      [UserDetailsPanelFields.Language.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.language}
          field={{
            variant: FIELD_VARIANT,
            type: 'select',
            name: UserDetailsPanelFields.Language.InputName,
            items: languagesSelectItems,
            schema: UserDetailsPanelFields.Language.schema,
            required: true,
          }}
        />
      ),
      [UserDetailsPanelFields.CreatedAt.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          value={initialValues.createdAt}
          field={{
            variant: FIELD_VARIANT,
            type: 'date',
            name: UserDetailsPanelFields.CreatedAt.InputName,
            disabled: true,
          }}
        />
      ),
      [UserDetailsPanelFields.Internal.Label]: (
        <EditableFieldFactory
          editing={isEdit}
          field={{ type: 'checkbox', variant: FIELD_VARIANT, name: UserDetailsPanelFields.Internal.InputName }}
          value={initialValues.internal}
        />
      ),
    };

    return [{ rows: personalRows }];
  }, [
    isEdit,
    initialValues.firstName,
    initialValues.middleName,
    initialValues.lastName,
    initialValues.emailAddress,
    initialValues.roleId,
    initialValues.language,
    initialValues.createdAt,
    initialValues.internal,
    rolesSelectItems,
    canEditRole,
    languagesSelectItems,
    user,
  ]);

  const DetailsPanelActions = React.useMemo(() => {
    return (
      <>
        {!isEdit && (
          <ActionButton
            messageId={TranslationKeys.global_edit}
            variant={'text'}
            icon={faPenToSquare}
            onClick={onClickEditButton}
            disabled={disabledActionButtonsWhileLoading}
          />
        )}
        {isEdit && (
          <Box sx={theme => ({ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: theme.spacing(1) })}>
            <ActionButton
              messageId={TranslationKeys.button_cancel}
              onClick={onClickCancelButton}
              variant="outlined"
              disabled={disabledActionButtonsWhileLoading}
            />
            <ActionButton
              messageId={TranslationKeys.button_save}
              type="submit"
              onClick={onClickSubmit}
              disabled={disabledActionButtonsWhileLoading}
            />
          </Box>
        )}
      </>
    );
  }, [isEdit, onClickEditButton, disabledActionButtonsWhileLoading, onClickCancelButton, onClickSubmit]);

  return (
    <Form>
      <DetailsPanel
        loading={isLoading}
        header={<FormattedMessage id={TranslationKeys.users_detailInformation} />}
        columns={DetailsPanelColumns}
        actions={DetailsPanelActions}
      />
    </Form>
  );
};
