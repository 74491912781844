import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Skeleton from '@mui/material/Skeleton';
import { Check, Clear, Error, ErrorOutlined, Info } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { formatDate, formatDateTime } from '../../../../utils/formatter/dateTimeFormatter';
import { CheckIconStyled } from './TasksTable.styles';

const TasksTable = props => {
  const {
    className,
    tasks,
    handlePageChange,
    handleRowsPerPageChange,
    page,
    rowsPerPage,
    totalCount,
    loading,
    disableCard,
    paging,
    modules,
  } = props;

  const [openLog, setOpenLog] = useState(false);

  const getModuleName = searchModuleId => {
    const module = modules.find(({ moduleId }) => searchModuleId === moduleId);

    if (module) {
      return <FormattedMessage id={module.moduleName} />;
    }

    return searchModuleId;
  };

  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="connections.tasks.createdAt" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.tasks.status" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.tasks.module" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.tasks.startedAt" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.tasks.finishedAt" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(rowsPerPage)
              .fill(index => index)
              .map((value, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
          {!loading && tasks.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>{'No tasks found'}</TableCell>
            </TableRow>
          )}
          {!loading &&
            tasks.length > 0 &&
            tasks.map(task => (
              <TableRow key={task.taskId}>
                <TableCell>{formatDateTime(task.createdAt)}</TableCell>
                <TableCell>
                  <Grid container alignContent="center" spacing={1}>
                    <Grid item>
                      {task.taskStatus === 'pending' && <Info color="disabled" />}
                      {task.taskStatus === 'processing' && <Info color="secondary" />}
                      {task.taskStatus === 'complete' && <CheckIconStyled />}
                      {task.taskStatus === 'queued' && <Info color="primary" />}
                      {task.taskStatus === 'cancelled' && <ErrorOutlined color="primary" />}
                      {task.taskStatus === 'failed' && <Error color="error" />}
                    </Grid>
                    <Grid item>{task.taskStatus}</Grid>
                  </Grid>
                </TableCell>
                <TableCell>{getModuleName(task.moduleId)}</TableCell>
                <TableCell>{formatDateTime(task.startedAt)}</TableCell>
                <TableCell>{formatDateTime(task.finishedAt)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog open={Boolean(openLog)} onClose={() => setOpenLog(null)} maxWidth="md" fullWidth>
        {openLog && (
          <>
            <DialogTitle>
              <span>
                <FormattedMessage id={`packages.type.${openLog.packageType}`} />
              </span>
              <span>{':'}&nbsp;</span>
              <span>{openLog.message}</span>
            </DialogTitle>
            <DialogContent>
              <Table>
                {openLog.packageType && (
                  <TableRow>
                    <TableCell>{'Package'}</TableCell>
                    <TableCell>
                      <FormattedMessage id={`packages.type.${openLog.packageType}`} />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>{'Message'}</TableCell>
                  <TableCell>{openLog.message}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{'Logged at'}</TableCell>
                  <TableCell>{formatDate(openLog.createdAt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{'Notified'}</TableCell>
                  <TableCell>
                    {openLog.notified && <Check />}
                    {!openLog.notified && <Clear />}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{'Archived'}</TableCell>
                  <TableCell>
                    {openLog.archived && <Check />}
                    {!openLog.archived && <Clear />}
                  </TableCell>
                </TableRow>
                {openLog.solution && (
                  <TableRow>
                    <TableCell>{'Probable solution'}</TableCell>
                    <TableCell>{openLog.solution}</TableCell>
                  </TableRow>
                )}
                {openLog.wiki && (
                  <TableRow>
                    <TableCell>{'Wiki'}</TableCell>
                    <TableCell>
                      <a href={openLog.wiki} target="_blank" rel="noopener noreferrer">
                        {openLog.wiki}
                      </a>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.internal && (
                  <TableRow>
                    <TableCell>{'Internal log'}</TableCell>
                    <TableCell>
                      <pre>{JSON.stringify(openLog.internal, null, 2)}</pre>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.request && (
                  <TableRow>
                    <TableCell>{'Request'}</TableCell>
                    <TableCell>
                      <pre>{JSON.stringify(openLog.request, null, 2)}</pre>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.response && (
                  <TableRow>
                    <TableCell>{'Response'}</TableCell>
                    <TableCell>
                      <pre>{JSON.stringify(openLog.response, null, 2)}</pre>
                    </TableCell>
                  </TableRow>
                )}
                {openLog.extraData && (
                  <TableRow>
                    <TableCell>{'Extra data'}</TableCell>
                    <TableCell>
                      <pre>{JSON.stringify(openLog.extraData, null, 2)}</pre>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );

  if (disableCard) {
    return table;
  }

  return (
    <Card className={className}>
      <CardContent sx={{ padding: 0 }}>
        <PerfectScrollbar>
          <Box sx={{ verticalAlign: 'middle' }}>{table}</Box>
        </PerfectScrollbar>
      </CardContent>
      {paging && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
          />
        </CardActions>
      )}
    </Card>
  );
};

TasksTable.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.bool,
  tasks: PropTypes.array.isRequired,
};

TasksTable.defaultProps = {
  paging: true,
  className: '',
};

export default TasksTable;
