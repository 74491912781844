import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { FilterableTable } from 'app/components/FilterableTable';
import ActionButton from 'app/components/ActionButton';

const EmployeeTypesTable = ({ employeeTypes, loading, handleUpdate, handleDelete, editPermissions }) => {
  const headers = useMemo(() => {
    return [
      {
        name: 'name',
        title: <FormattedMessage id="owners.settings.employeeTypes" />,
      },
      {
        name: 'description',
        title: <FormattedMessage id="description" />,
      },
      {
        name: 'actions',
      },
    ];
  }, []);

  const rowMapper = useCallback(
    employeeType => {
      const menuItems = [];

      menuItems.push({
        onClick: () => handleUpdate(employeeType),
        id: 'button.change',
        icon: faEdit,
      });

      menuItems.push({
        onClick: () => handleDelete(employeeType),
        id: 'button.delete',
        icon: faTrashCan,
      });

      const employeeTypeName = employeeType?.name;

      const buttons = menuItems.map(b => {
        return (
          <ActionButton
            key={b.id}
            messageId={b.id}
            onClick={b.onClick}
            variant={'text'}
            icon={b.icon}
            disabled={editPermissions}
          />
        );
      });

      return {
        data: {
          name: employeeTypeName,
          description: employeeType.description,
          actions: buttons,
        },
      };
    },
    [editPermissions, handleDelete, handleUpdate],
  );

  const pagination = useMemo(() => {
    return {
      enabled: false,
    };
  }, []);

  return (
    <>
      <FilterableTable
        columns={headers}
        loading={loading}
        rows={employeeTypes || []}
        rowMapper={rowMapper}
        pagination={pagination}
        defaultPageSize={8}
        inMemorySort={false}
      />
    </>
  );
};

EmployeeTypesTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  employeeTypes: PropTypes.array,
};

export default EmployeeTypesTable;
