import { useMutation } from '@tanstack/react-query';

import type { EditRegulationsParams } from '../../../Domain/Regulations/Service/RegulationsService';
import RegulationsService from '../../../Domain/Regulations/Service/RegulationsService';

const mutationFn = (data: EditRegulationsParams) => {
  return RegulationsService.editRegulations(data);
};

export const useRegulationsEditMutation = () => {
  return useMutation<unknown, unknown, EditRegulationsParams>(mutationFn);
};
