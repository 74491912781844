import React, { createRef, useImperativeHandle } from 'react';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import type { Dictionary } from '../../../../../../utils';
import { isEmpty } from '../../../../../../utils';

import { CHILD_DEFAULT_VALUES, ChildrenStepFieldArray } from './components/ChildrenStepFieldArray';
import type { ChildrenStepFormValues } from './ChildrenStep.types';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';

type ChildrenStepProps = {
  onSubmit: () => void;
  stepState: Dictionary<any>;
  loading?: boolean;
};

const ChildrenStep = React.forwardRef<unknown, ChildrenStepProps>(({ onSubmit, stepState, loading = false }, ref) => {
  const formRef = createRef<any>();

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        formRef.current.submitForm();
      },
      getState() {
        return {
          form: formRef.current.values,
        };
      },
    }),
    [formRef],
  );

  const formikInitialValues = React.useMemo<ChildrenStepFormValues>(() => {
    return {
      employee: {
        children: [CHILD_DEFAULT_VALUES],
        ...(stepState.form.employee || {}),
      },
    };
  }, [stepState.form.employee]);

  const formikValidationSchema = React.useMemo(() => {
    return Yup.object().shape({
      employee: Yup.object().shape({
        children: Yup.array().of(
          Yup.object().shape({
            personName: Yup.object().shape(
              {
                lastName: Yup.string()
                  .nullable()
                  .optional()
                  .when('firstName', {
                    is: (val: string) => !isEmpty(val),
                    then: () => Yup.string().nullable().required(),
                  }),
              },
              [['lastName', 'firstName']],
            ),
            dateOfBirth: Yup.string()
              .nullable()
              .optional()
              .when('personName.firstName', {
                is: (val: string) => !isEmpty(val),
                then: () => Yup.string().nullable().required(),
              }),
            gender: Yup.string()
              .nullable()
              .optional()
              .when('personName.firstName', {
                is: (val: string) => !isEmpty(val),
                then: () => Yup.string().nullable().required(),
              }),
          }),
        ),
      }),
    });
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="body1">
            <FormattedMessage id={'employees.add.childrenUniqueInfo'} />
          </Typography>
        </Grid>
      </Grid>
      <Formik<ChildrenStepFormValues>
        enableReinitialize
        initialValues={formikInitialValues}
        validationSchema={formikValidationSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        <FieldArray name="employee.children">
          {arrayHelpers => <ChildrenStepFieldArray arrayHelpers={arrayHelpers} loading={loading} />}
        </FieldArray>
      </Formik>
    </>
  );
});

ChildrenStep.displayName = 'ChildrenStep';

export default ChildrenStep;
