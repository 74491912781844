import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box, Skeleton, Typography } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { TranslationKeys } from 'app/translations';

import format from 'app/utils/formatter';

import type { EmployeeEmploymentSchedule, UseQueryRefetch } from 'app/types';

import { useDialog } from 'app/hooks';

import {
  getDisplayDateValue,
  getDisplayOptionalValue,
  getDisplayPercentValue,
  getDisplayUTCDateValue,
} from 'app/utils';

import ActionButton from 'app/components/ActionButton';
import DetailsPanelRow from 'app/components/DetailsPanelRow';
import DetailsPanel from 'app/components/DetailsPanel';
import { useLocale } from 'app/components/Intl';

import { EmployeeDetailsPanelHeaderWithSyncToggle } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeDetailsPanelHeaderWithSyncToggle';

import { DivPanelBodyDetailsStyled } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeEmploymentTab/EmployeeEmploymentTab.styles';

import { EmployeeEmploymentScheduleFormDialog } from './components';
import { EmployeeEmploymentScheduleFields } from './EmployeeEmploymentSchedules.types';

import { EMPLOYER, CONNECTION } from 'app/common/Authorization/entities';
import { EDIT, RUN } from 'app/common/Authorization/permissions';
import { HasAccessTo } from 'app/components';

type EmployeeEmploymentSchedulesProps = {
  loading: boolean;
  isRegularConnection: boolean;
  refetchGetEmploymentsQuery: UseQueryRefetch;
  connectionId: string | undefined;
  employeeId: string | undefined;
  employmentId: string | undefined;
  schedules: Array<EmployeeEmploymentSchedule> | undefined;
  synchronizationStatus: boolean | undefined;
  refetchGetEmployeeSynchronizationQuery: UseQueryRefetch;
  disabledActionButtonsWhileLoading: boolean;
};

export const EmployeeEmploymentSchedules = ({
  connectionId,
  employeeId,
  employmentId,
  schedules = [],
  loading,
  isRegularConnection,
  refetchGetEmploymentsQuery,
  synchronizationStatus,
  refetchGetEmployeeSynchronizationQuery,
  disabledActionButtonsWhileLoading,
}: EmployeeEmploymentSchedulesProps) => {
  const { locale } = useLocale();
  const { employerId } = useParams();

  const {
    dialogState: scheduleFormDialogState,
    openDialog: openScheduleFormDialog,
    closeDialog: closeScheduleFormDialog,
  } = useDialog();

  const [expanded, setExpanded] = React.useState(schedules.map(() => false));
  const [scheduleToEdit, setScheduleToEdit] = React.useState<EmployeeEmploymentSchedule | null>(null);
  const hasSchedules = schedules.length > 0;
  const hasActiveSynchronization = synchronizationStatus !== undefined ? synchronizationStatus : false;
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);

  const shouldRenderEmployeeEmploymentScheduleFormDialog =
    !!employerId && !!connectionId && !!employeeId && !!employmentId && scheduleFormDialogState;

  const toggleScheduleDetailsPanel = React.useCallback(
    ({ id, expanded: newExpanded }) => {
      const newState = new Array(expanded.length).fill(false);
      newState[id] = !newExpanded;
      setExpanded(newState);
    },
    [expanded],
  );

  const onOpenSchedulesFormDialog = React.useCallback(
    (schedule?: EmployeeEmploymentSchedule) => {
      setScheduleToEdit(schedule || null);
      openScheduleFormDialog();
    },
    [openScheduleFormDialog],
  );

  const onCloseScheduleFormDialog = React.useCallback(() => {
    setScheduleToEdit(null);
    closeScheduleFormDialog();
  }, [closeScheduleFormDialog]);

  const onClickAddButton = React.useCallback(() => {
    onOpenSchedulesFormDialog();
  }, [onOpenSchedulesFormDialog]);

  const onClickEditButton = React.useCallback(
    (schedule: EmployeeEmploymentSchedule) => () => {
      onOpenSchedulesFormDialog(schedule);
    },
    [onOpenSchedulesFormDialog],
  );

  const LoadingDetailsBody = React.useMemo(() => {
    return (
      <>
        {new Array(5).fill(null).map((_, idx) => (
          <Skeleton key={idx} height={24} />
        ))}
      </>
    );
  }, []);

  const NoSchedulesDetailsBody = React.useMemo(() => {
    return (
      <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKeys.employees_detail_schedules_noSchedules} />
        </Typography>
      </Box>
    );
  }, []);

  const SchedulesDetailsBody = React.useMemo(() => {
    return (schedules || []).map((schedule, idx) => {
      const expandedIfOnlyOneSchedules = schedules.length === 1;
      const isExpanded = typeof expanded[idx] !== 'undefined' ? expanded[idx] : false;

      const rows = {
        [EmployeeEmploymentScheduleFields.StartDate.Label]: getDisplayDateValue(schedule?.startDate),
        [EmployeeEmploymentScheduleFields.EndDate.Label]: getDisplayUTCDateValue(schedule?.endDate),
        [EmployeeEmploymentScheduleFields.PartTimePercentage.Label]: getDisplayPercentValue({
          locale,
          value: schedule?.partTimePercentage,
          options: EmployeeEmploymentScheduleFields.PartTimePercentage.Options,
        }),
        [EmployeeEmploymentScheduleFields.HoursPerWeek.Label]: getDisplayOptionalValue(schedule?.hoursPerWeek),
        [EmployeeEmploymentScheduleFields.FullTimeHoursPerWeek.Label]: getDisplayOptionalValue(
          schedule?.fullTimeHoursPerWeek,
        ),
        [EmployeeEmploymentScheduleFields.StartAveragePartTimePercentage.Label]: getDisplayPercentValue({
          locale,
          value: schedule?.startAveragePartTimePercentage,
          options: EmployeeEmploymentScheduleFields.StartAveragePartTimePercentage.Options,
        }),
      };

      return (
        <div key={idx}>
          <DetailsPanelRow
            title={<Typography variant="h6">{format.date(schedule.startDate)}</Typography>}
            expanded={expandedIfOnlyOneSchedules || isExpanded}
            rows={rows}
            loading={loading}
            id={idx}
            onExpand={toggleScheduleDetailsPanel}
            onEditButtonClick={isRegularConnection ? onClickEditButton(schedule) : undefined}
            disabledEditButton={
              disabledActionButtonsWhileLoading ||
              hasActiveSynchronization ||
              disabledIfNoAccessToEmployerEdit ||
              disabledIfNoAccessToConnectionRun
            }
          />
        </div>
      );
    });
  }, [
    schedules,
    expanded,
    locale,
    loading,
    toggleScheduleDetailsPanel,
    isRegularConnection,
    onClickEditButton,
    disabledActionButtonsWhileLoading,
    hasActiveSynchronization,
    disabledIfNoAccessToEmployerEdit,
    disabledIfNoAccessToConnectionRun,
  ]);

  const DetailsPanelBody = React.useMemo(() => {
    const finishedLoadingAndHasSchedules = !loading && hasSchedules;
    const finishedLoadingAndHasNoSchedules = !loading && !hasSchedules;

    return (
      <DivPanelBodyDetailsStyled isRegularConnection={isRegularConnection}>
        <>
          {loading && LoadingDetailsBody}
          {finishedLoadingAndHasSchedules && SchedulesDetailsBody}
          {finishedLoadingAndHasNoSchedules && NoSchedulesDetailsBody}
        </>
      </DivPanelBodyDetailsStyled>
    );
  }, [loading, hasSchedules, isRegularConnection, LoadingDetailsBody, SchedulesDetailsBody, NoSchedulesDetailsBody]);

  const DetailsPanelActions = React.useMemo(() => {
    return (
      <>
        <ActionButton
          messageId={TranslationKeys.button_employmentSchedules_add}
          variant="text"
          icon={faPlus}
          onClick={onClickAddButton}
          disabled={
            disabledActionButtonsWhileLoading ||
            hasActiveSynchronization ||
            disabledIfNoAccessToEmployerEdit ||
            disabledIfNoAccessToConnectionRun
          }
        />
      </>
    );
  }, [
    disabledActionButtonsWhileLoading,
    hasActiveSynchronization,
    onClickAddButton,
    disabledIfNoAccessToEmployerEdit,
    disabledIfNoAccessToConnectionRun,
  ]);

  const DetailsPanelHeader = React.useMemo(() => {
    return (
      <EmployeeDetailsPanelHeaderWithSyncToggle
        field={'schedule'}
        loading={loading}
        synchronizationStatus={synchronizationStatus}
        refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
        disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
        messageId={TranslationKeys.employees_detail_schedules}
        employeeId={employeeId}
        connectionId={connectionId}
      />
    );
  }, [
    loading,
    synchronizationStatus,
    refetchGetEmployeeSynchronizationQuery,
    disabledActionButtonsWhileLoading,
    employeeId,
    connectionId,
  ]);

  return (
    <>
      {shouldRenderEmployeeEmploymentScheduleFormDialog && (
        <EmployeeEmploymentScheduleFormDialog
          open={scheduleFormDialogState}
          closeDialog={onCloseScheduleFormDialog}
          employerId={employerId}
          connectionId={connectionId}
          employeeId={employeeId}
          employmentId={employmentId}
          schedule={scheduleToEdit}
          refetchGetEmploymentsQuery={refetchGetEmploymentsQuery}
        />
      )}

      <DetailsPanel
        header={DetailsPanelHeader}
        body={DetailsPanelBody}
        actions={isRegularConnection ? DetailsPanelActions : undefined}
        scrollBody={true}
      />
    </>
  );
};
