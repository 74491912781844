import React from 'react';

import { TranslationKeys } from 'app/translations';

import type { EmployeeCalculationsPremiums } from 'app/hooks';
import type { CalculationsCardProps } from '../CalculationsCard';
import { CalculationsCard } from '../CalculationsCard';

type EmployeePensionPremiumsDetailsPanelProps = {
  loading?: boolean;
  data: EmployeeCalculationsPremiums | undefined;
};

export const EmployeePensionPremiumsDetailsPanel = ({
  loading = false,
  data,
}: EmployeePensionPremiumsDetailsPanelProps) => {
  const cardFields = React.useMemo<CalculationsCardProps['fields']>(() => {
    if (data && 'message' in data) {
      return [];
    }

    return [
      {
        labelId: TranslationKeys.employees_pension_partTimePensionBase,
        formulaId: TranslationKeys.employees_pension_calcFormula_partTimePensionBase,
        value: {
          value: data?.partTimePensionBase,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_fullTimePensionBase,
        formulaId: TranslationKeys.employees_pension_calcFormula_fullTimePensionBase,
        value: {
          value: data?.fullTimePensionBase,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_premiumPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_premiumPerYear,
        value: {
          value: data?.premiumPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_premiumPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_premiumPerMonth,
        value: {
          value: data?.premiumPerMonth,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_premiumPVIPerYear,
        formulaId: TranslationKeys.employees_pension_calcFormula_premiumPVIPerYear,
        value: {
          value: data?.premiumPVIPerYear,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_premiumPVIPerMonth,
        formulaId: TranslationKeys.employees_pension_calcFormula_premiumPVIPerMonth,
        value: {
          value: data?.premiumPVIPerMonth,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_administrationCost1Calculation,
        formulaId: TranslationKeys.employees_pension_calcFormula_administrationCost1,
        value: {
          value: data?.administrationCost1,
          type: 'currency',
        },
      },
      {
        labelId: TranslationKeys.employees_pension_administrationCost2Calculation,
        formulaId: TranslationKeys.employees_pension_calcFormula_administrationCost2,
        value: {
          value: data?.administrationCost2,
          type: 'currency',
        },
      },
    ];
  }, [data]);

  return (
    <CalculationsCard
      isLoading={loading}
      titleId={TranslationKeys.employees_detail_premiums}
      fields={cardFields}
      error={data?.message}
    />
  );
};
