import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField as BaseTextField } from '../base';
import type { useFormikContext } from 'formik';
import { Field, getIn } from 'formik';
import { FormLabel, IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { isNil } from 'app/utils';
import { NumericFormat } from 'react-number-format';
import NumericInputHelper from './NumericInputHelper';
import { IntlMessage } from 'app/components/Intl';
import { useFieldValidator } from '../Factory';
import { InputAdornmentStyled, SpanStyled } from './Text.styles';
import type { TextProps } from './Text';

type InternalTextFieldProps = TextProps & {
  formik: ReturnType<typeof useFormikContext>;
};

const InternalTextField = ({
  onChange,
  field,
  variant = 'default',
  formik,
  className,
  hideErrorText = false,
}: InternalTextFieldProps) => {
  const {
    label,
    name,
    value,
    defaultValue,
    header,
    options = {},
    type = 'text',
    required = false,
    loading = false,
    disabled = false,
    disableOnChange = false,
    fullWidth = true,
    characterCounterEnabled = false,
    multiline = false,
    ...rest
  } = field || {};

  // should be ignored!
  delete rest.schema;

  const intl = useIntl();

  const { initialValues, setFieldValue, setFieldTouched, isSubmitting, values: formikValues } = formik;
  const formikValue = getIn(formikValues, name);
  const isPassword = type === 'password';
  const isNumber = type === 'number';

  const actualDefaultValue = useMemo(() => {
    const initialValue = getIn(initialValues, name);
    return isNil(initialValue) ? (isNil(defaultValue) ? '' : defaultValue) : initialValue;
  }, [defaultValue, initialValues, name]);

  const actualValue = useMemo(() => {
    let selectedValue = (isNil(value) ? actualDefaultValue : value) ?? '';
    if (!isNil(formikValue)) {
      selectedValue = formikValue;
    }
    return selectedValue;
  }, [actualDefaultValue, formikValue, value]);

  const actualDisabled = loading || disabled || isSubmitting;
  const [showPassword, setShowPassword] = useState(false);
  const [currentValue, setCurrentValue] = useState(actualValue);
  const [fieldDisabled, setFieldDisabled] = useState(actualDisabled);
  const [inputType] = useState(isNumber ? 'text' : type);
  const validator = useFieldValidator(inputType);

  const inputHelper = useMemo(() => {
    return new NumericInputHelper(intl.locale, options);
  }, [intl.locale, options]);

  useEffect(() => {
    setCurrentValue(actualValue);
  }, [actualValue]);

  useEffect(() => {
    setFieldDisabled(actualDisabled);
  }, [actualDisabled]);

  const characterLimit = options?.max ?? null;
  const characterCount = currentValue?.length ?? 0;

  const change = useCallback(
    async event => {
      const value = isNumber ? event?.value : event?.target?.value;
      if (disableOnChange) {
        setFieldDisabled(true);
      }
      let newValue = isNil(value) ? '' : value;

      if (characterCounterEnabled && !!characterLimit) {
        newValue = newValue.slice(0, characterLimit);
      }

      if (isNumber) {
        newValue = inputHelper.getValue(newValue);
      }

      setCurrentValue(newValue);
      // needs to be performed on this order!
      setFieldTouched(name, true, false);
      await setFieldValue(name, newValue, true);
      onChange && onChange({ name, value: newValue, setFieldValue });
    },
    [
      isNumber,
      disableOnChange,
      characterCounterEnabled,
      characterLimit,
      setFieldTouched,
      name,
      setFieldValue,
      onChange,
      inputHelper,
    ],
  );

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleMouseDownPassword = useCallback(event => {
    event.preventDefault();
  }, []);

  const size = variant === 'slim' ? 'small' : undefined;

  const inputLabelProps = useMemo(() => {
    return {
      size,
    };
  }, [size]);

  const InputProps = useMemo(() => {
    let endAdornment;

    if (isPassword) {
      endAdornment = (
        <InputAdornment position="end">
          <IconButton
            size={'large'}
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    } else if (characterCounterEnabled) {
      const prefix = characterLimit ? `${characterCount}/${characterLimit}` : `${characterCount}`;
      endAdornment = (
        <InputAdornmentStyled multiline={multiline} position="end">
          <Typography variant={'caption'}>
            {prefix} <IntlMessage value={'charactersLimit'} />
          </Typography>
        </InputAdornmentStyled>
      );
    }

    return {
      type: showPassword ? 'text' : inputType,
      endAdornment: endAdornment,
    };
  }, [
    characterCount,
    characterCounterEnabled,
    characterLimit,
    handleClickShowPassword,
    handleMouseDownPassword,
    inputType,
    isPassword,
    multiline,
    showPassword,
  ]);

  const inputSpecificProps = useMemo(() => {
    if (isNumber) {
      return {
        component: NumericFormat,
        customInput: BaseTextField,
        type: 'number',
        onValueChange: change,
        value: inputHelper.getValue(currentValue),
        ...inputHelper.getProps(),
      };
    }

    return {
      component: BaseTextField,
      onChange: change,
      value: currentValue,
    };
  }, [change, currentValue, inputHelper, isNumber]);

  const validate = useCallback(
    value => {
      // make sure we feed the proper type to the validator
      let fieldValue = (isNumber ? inputHelper.getValue(value) : value) ?? '';
      fieldValue = isNumber && fieldValue === '' ? undefined : fieldValue;
      return validator(fieldValue, field);
    },
    [field, inputHelper, isNumber, validator],
  );

  return (
    <div className={className}>
      {header && (
        <FormLabel>
          <IntlMessage value={header} /> {required ? ' *' : ''}
        </FormLabel>
      )}
      <SpanStyled isOptionSizeSmall={options?.size === 'small'} textAlign={options?.textAlign}>
        <Field
          name={name}
          label={label}
          type={inputType}
          disabled={fieldDisabled}
          InputLabelProps={inputLabelProps}
          InputProps={InputProps}
          fullWidth={fullWidth}
          required={required}
          multiline={multiline}
          size={size}
          validate={validate}
          hideErrorText={hideErrorText}
          {...inputSpecificProps}
          {...rest}
        />
      </SpanStyled>
    </div>
  );
};

export default InternalTextField;
