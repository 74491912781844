import { useMutation } from '@tanstack/react-query';

import CredentialService from '../../../Domain/Credentials/Services/CredentialService';

type DeleteCredentialData = {
  ownerId: string;
  credentialId: string;
};

const mutationFn = (data: DeleteCredentialData) => {
  return CredentialService.deleteCredential(data);
};

export const useCredentialDeleteMutation = () => {
  return useMutation<unknown, unknown, DeleteCredentialData>(mutationFn);
};
