import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { ConnectionSourcePackageTypeEnum } from 'app/types';

import type { GetEmployeeSynchronizationQueryResultData } from 'app/hooks';
import {
  useConnectionGetConnectionQuery,
  useEmployeeGetEmployeeQuery,
  useEmployeeGetEmployeeSynchronizationQuery,
} from 'app/hooks';

import { ChildrenDetailsPanel, EmployeeDetailsPanel, EmployeeNotes, PartnerDetailsPanel } from './components';

export const EmployeeInfoTab = () => {
  const { employerId, employeeId } = useParams();

  const [isConnectionWithSynchronization, setIsConnectionWithSynchronization] = React.useState(false);
  const [employeeSynchronization, setEmployeeSynchronization] =
    React.useState<GetEmployeeSynchronizationQueryResultData | null>(null);

  const {
    data: employee,
    isLoading: isLoadingEmployer,
    refetch: refetchGetEmployeeQuery,
    isFetching: isFetchingGetEmployeeQuery,
  } = useEmployeeGetEmployeeQuery({
    variables: { employeeId, employerId },
    options: {
      enabled: !!employeeId && !!employerId,
    },
  });

  const { isLoading: isLoadingConnection } = useConnectionGetConnectionQuery({
    variables: { connectionId: employee?.connectionId },
    options: {
      enabled: !!employee?.connectionId,
      onSuccess: data => {
        const isManualInputOrFileUpload = data.packages.some(connectionPackage =>
          [
            ConnectionSourcePackageTypeEnum.Manual.toString(),
            ConnectionSourcePackageTypeEnum.FileUpload.toString(),
          ].includes(connectionPackage.packageType),
        );
        setIsConnectionWithSynchronization(!isManualInputOrFileUpload);
      },
    },
  });

  const enableSynchronizationQuery = isConnectionWithSynchronization && !!employeeId && !!employee?.connectionId;
  const {
    isLoading: isLoadingEmployeeSynchronizationQuery,
    isFetching: isFetchingEmployeeSynchronizationQuery,
    refetch: refetchGetEmployeeSynchronizationQuery,
  } = useEmployeeGetEmployeeSynchronizationQuery({
    variables: {
      employeeId,
      connectionId: employee?.connectionId,
    },
    options: {
      enabled: enableSynchronizationQuery,
      onSuccess: data => setEmployeeSynchronization(data),
      onError: () => setEmployeeSynchronization(null),
    },
  });

  const loading =
    isLoadingEmployer || isLoadingConnection || (enableSynchronizationQuery && isLoadingEmployeeSynchronizationQuery);

  const disabledActionButtonsWhileLoading =
    loading || isFetchingEmployeeSynchronizationQuery || isFetchingGetEmployeeQuery;

  return (
    <Grid container spacing={2}>
      <Grid item md={8} xs={12}>
        <EmployeeDetailsPanel
          employerId={employerId}
          employee={employee}
          synchronizationStatus={employeeSynchronization?.status?.employee}
          refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
          refetchGetEmployeeQuery={refetchGetEmployeeQuery}
          loading={loading}
          disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
        />
      </Grid>
      <Grid item container md={4} xs={12}>
        <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} width={'100%'}>
          <Box display={'flex'} paddingBottom={1}>
            <PartnerDetailsPanel
              synchronizationStatus={employeeSynchronization?.status?.partner}
              refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
              refetchGetEmployeeQuery={refetchGetEmployeeQuery}
              employee={employee}
              loading={loading}
              disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
            />
          </Box>
          <Box display={'flex'} paddingTop={1}>
            <ChildrenDetailsPanel
              synchronizationStatus={employeeSynchronization?.status?.child}
              refetchGetEmployeeSynchronizationQuery={refetchGetEmployeeSynchronizationQuery}
              refetchGetEmployeeQuery={refetchGetEmployeeQuery}
              employee={employee}
              loading={loading}
              disabledActionButtonsWhileLoading={disabledActionButtonsWhileLoading}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <EmployeeNotes isLoading={isLoadingEmployer} employerId={employerId} employeeId={employeeId} />
      </Grid>
    </Grid>
  );
};
