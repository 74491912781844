import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Chip, Typography } from '@mui/material';

import { TranslationKeys } from '../../../../translations';

import type { Owner } from '../../../../types';

import type { TableSelectedState } from '../../../../store';
import { StoreActions, useStoreDispatch, useStoreSelector } from '../../../../store';

import { ActionLink, HasRole, IsOwnerAdmin, IsSuperAdmin } from '../../../../components';
import { useBreadcrumb } from '../../../../components';

import { OwnersToolbar } from '../../Components';
import {
  CardActionsStyled,
  CardContentStyled,
  CardHeaderStyled,
  CardStyled,
  DivContainerStyled,
  TypographyCardTitleStyled,
} from './OwnerManagementPage.styles';

import { useSnakeBar, useUserUpdateOwnerIdMutation } from 'app/hooks/';

export const OwnerManagementPage = () => {
  const { mutate: updateUserOwnerIdMutation } = useUserUpdateOwnerIdMutation();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const intl = useIntl();
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const { user } = useStoreSelector(state => state.AppReducer);

  const { items: owners, unfilteredTotalCount: environmentTotalCount } = useStoreSelector<TableSelectedState<Owner>>(
    state => state.OwnersReducer,
  );

  const hasPermission = HasRole('ROLE_ENVIRONMENT_SWITCH') || IsOwnerAdmin() || IsSuperAdmin();

  React.useEffect(() => {
    dispatch(StoreActions.OwnersActions.initializeOwnersState());
    dispatch(StoreActions.OwnersActions.requestDataEnvironment());
    dispatch(StoreActions.OwnersActions.requestEnvironmentTotalCount());
  }, [dispatch]);

  const swicthUserOwnerIdOnClick = (values: any) => {
    const method = 'updateUserOwnerId';
    const { createdAt, ...otherValues } = values;

    const payload = {
      ...otherValues,
    };

    updateUserOwnerIdMutation(payload, {
      onSuccess: (data, variables) => {
        const regex = /(https:\/\/.*\/logos\/).*\.(\w+)$/;
        const match = user.ownerLogo.match(regex);

        let ownerLogo = user.ownerLogo;

        if (match) {
          const matchedUrl = match[1];
          const fileExtension = match[2];

          ownerLogo = `${matchedUrl}${variables.ownerId}.${fileExtension}`;
        }

        dispatch(
          StoreActions.AppActions.setUser({
            ...user,
            ownerId: variables.ownerId,
            ownerName: variables.ownerName,
            owner: `/api/owners/${variables.ownerId}`,
            ownerLogo,
          }),
        );

        dispatch(
          StoreActions.AppActions.setEnvironment({
            ownerId: variables.ownerId,
          }),
        );

        dispatch(StoreActions.OwnersActions.requestDataEnvironment());
        dispatch(StoreActions.OwnersActions.requestEnvironmentTotalCount());

        showSuccessSnakeBar({ method });
      },
      onError: (error: any) => {
        showErrorSnakeBar({ method, message: error?.message });
      },
    });
  };

  useBreadcrumb(TranslationKeys.menu_owners, {
    hideTrail: true,
    hideTitle: true,
    otherElements: (
      <OwnersToolbar
        handleClickAdd={React.useCallback(() => navigate('../add'), [navigate])}
        totalCount={environmentTotalCount.owners}
      />
    ),
  });

  return (
    <DivContainerStyled>
      {!!owners?.length &&
        owners.map((owner: Owner) => (
          <CardStyled
            key={owner.ownerId}
            sx={{
              boxShadow: owner.active === 1 ? '0px 2px 8px rgba(0, 44, 114, 0.5)' : 'none',
            }}
          >
            <CardHeaderStyled>
              {!!owner.logo && <img src={owner.logo} alt={intl.formatMessage({ id: TranslationKeys.global_logo })} />}
            </CardHeaderStyled>

            <CardContentStyled>
              <TypographyCardTitleStyled variant="h5">{owner.ownerName}</TypographyCardTitleStyled>
            </CardContentStyled>

            <CardActionsStyled>
              <Typography
                color="primary"
                sx={{
                  opacity: owner.active === 1 || !hasPermission ? 0.5 : 1,
                  cursor: owner.active === 1 || !hasPermission ? 'default' : 'pointer',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: owner.active === 1 || !hasPermission ? 'none' : 'underline',
                  },
                }}
                onClick={() =>
                  hasPermission && owner.active === 0
                    ? swicthUserOwnerIdOnClick({
                        ownerId: owner.ownerId,
                        userId: owner.userId,
                        ownerName: owner.ownerName,
                      })
                    : null
                }
              >
                <FormattedMessage id={TranslationKeys.owners_login} />
              </Typography>

              {owner.active === 1 && (
                <ActionLink to={'/owners/' + owner.ownerId + '/info'}>
                  <Typography color="primary">
                    <FormattedMessage id={TranslationKeys.owners_view} />
                  </Typography>
                </ActionLink>
              )}

              {owner.active === 0 && (
                <Typography color="primary" sx={{ opacity: 0.5 }}>
                  <FormattedMessage id={TranslationKeys.owners_view} />
                </Typography>
              )}
            </CardActionsStyled>

            {owner.active === 1 && (
              <CardActionsStyled sx={{ justifyContent: 'center' }}>
                <Chip
                  color={'secondary'}
                  size={'small'}
                  label={<FormattedMessage id={TranslationKeys.environment_active} />}
                  sx={{ fontWeight: 'bold', backgroundColor: '#1E88E5', padding: 1.5 }}
                />
              </CardActionsStyled>
            )}
            {owner.active === 0 && (
              <CardActionsStyled sx={{ justifyContent: 'center' }}>
                <Chip
                  size={'small'}
                  label={<FormattedMessage id={TranslationKeys.environment_inactive} />}
                  sx={{ fontWeight: 'bold', padding: 1.5 }}
                />
              </CardActionsStyled>
            )}
          </CardStyled>
        ))}
    </DivContainerStyled>
  );
};
