import React from 'react';
import { useIntl } from 'react-intl';
import { TranslationKeys } from '../translations';
import { EmployeesListTypeSchemeEnum } from '../types/employeesList.type';

export const useEmployeeListTypeSchemeSelectItems = () => {
  const intl = useIntl();

  const employeeListTypeSchemeSelectItems = React.useMemo(() => {
    return [
      {
        value: EmployeesListTypeSchemeEnum.Blacklisted,
        element: intl.formatMessage({ id: TranslationKeys.employee_list_type_blacklist }),
      },
      {
        value: EmployeesListTypeSchemeEnum.WhiteListed,
        element: intl.formatMessage({ id: TranslationKeys.employee_list_type_whitelist }),
      },
    ];
  }, [intl]);

  const getProductSchemeDisplayNameByValue = React.useCallback(
    (value: EmployeesListTypeSchemeEnum) => {
      return employeeListTypeSchemeSelectItems.find(employeeListTypeScheme => employeeListTypeScheme.value === value)
        ?.element;
    },
    [employeeListTypeSchemeSelectItems],
  );

  return React.useMemo(
    () => ({ employeeListTypeSchemeSelectItems, getProductSchemeDisplayNameByValue }),
    [employeeListTypeSchemeSelectItems, getProductSchemeDisplayNameByValue],
  );
};
