import { styled } from '@mui/material/styles';
import ActionButton from '../../../../components/ActionButton';

export const DivRootStyled = styled('div', {
  name: 'DivRootStyled',
})(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const ActionButtonStyled = styled(ActionButton, {
  name: 'ActionButtonStyled',
})(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
