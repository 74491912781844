import React from 'react';
import type { FormikConfig } from 'formik';

import type { SecurityInputProps } from '../../../../components';

import type { SignInViewCredentialsFormValues } from '../../SignInView.types';

import {
  SignInViewCredentialsForm,
  SignInViewTwoFactorAuthSecurityCodeInput,
  SignInViewTwoFactorAuthSetupSteps,
} from './components';

export const SIGN_IN_VIEW_CONTENT_DATA_TEST_ID = 'sign-in-view-content-component';

type SignInViewContentProps = {
  showCredentialsForm: boolean;
  showTwoFactorAuthSecurityCodeInput: boolean;
  showTwoFactorAuthSetupSteps: boolean;
  twoFaFailed: boolean;
  qrCode: string | null;
  handleLogin: FormikConfig<SignInViewCredentialsFormValues>['onSubmit'];
  handleLoginWithCode: SecurityInputProps['onCodeComplete'];
};

export const SignInViewContent = ({
  showCredentialsForm,
  showTwoFactorAuthSecurityCodeInput,
  showTwoFactorAuthSetupSteps,
  twoFaFailed,
  qrCode,
  handleLoginWithCode,
  handleLogin,
}: SignInViewContentProps) => {
  return (
    <div data-testid={SIGN_IN_VIEW_CONTENT_DATA_TEST_ID}>
      {showCredentialsForm && <SignInViewCredentialsForm formikOnSubmit={handleLogin} />}

      {showTwoFactorAuthSecurityCodeInput && (
        <SignInViewTwoFactorAuthSecurityCodeInput twoFaFailed={twoFaFailed} onCodeComplete={handleLoginWithCode} />
      )}

      {showTwoFactorAuthSetupSteps && (
        <SignInViewTwoFactorAuthSetupSteps
          twoFaFailed={twoFaFailed}
          qrCodeImgSrc={qrCode || ''}
          onCodeComplete={handleLoginWithCode}
        />
      )}
    </div>
  );
};
