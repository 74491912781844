import { styled } from '@mui/material/styles';
import { buttonBaseClasses, formControlLabelClasses } from '@mui/material';
import { Checkbox } from 'app/components';

export const SelectAllCheckboxStyled = styled(Checkbox, {
  name: 'SelectAllCheckboxStyled',
})(({ theme }) => ({
  [`& .${formControlLabelClasses.label}`]: {
    color: theme.palette.primary.dark,
    marginLeft: 1,
  },
  [`& .${formControlLabelClasses.root}`]: {
    marginLeft: 0,
  },
  [`& .${buttonBaseClasses.root}`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
