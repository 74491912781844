import React from 'react';

import { Box, Typography } from '@mui/material';

import type { UserOwner } from 'app/types';

import { ListRow, ListRowNoData, ListRowSkeleton } from 'app/components';

import type {
  UserEnvironmentsListOnChangeSelected,
  UserEnvironmentsListSelectedData,
} from '../UserEnvironmentsList.types';

import { UserAccessesListContainer } from 'app/Domain/Users/Pages/UserEditPage/components/UserAccessesList/components/UserAccessesListContainer';

type UserEnvironmentsListOwnersProps = {
  isLoading?: boolean;
  onChangeSelected: UserEnvironmentsListOnChangeSelected;
  selectedData: UserEnvironmentsListSelectedData | undefined;
  owners: Array<UserOwner> | undefined;
  openConfirmationDialog: (value: boolean) => void;
};

export const UserEnvironmentsListOwners = ({
  owners,
  selectedData,
  onChangeSelected,
  openConfirmationDialog,
  isLoading = false,
}: UserEnvironmentsListOwnersProps) => {
  const listData = React.useMemo(() => {
    return owners;
  }, [owners]);

  const selected: Array<UserOwner> = [];

  if (selectedData?.owners) {
    selectedData?.owners.forEach((value, index, array) => {
      selected.push(value);
    });
  }

  const disabledIfLoadingOrIsLoggedInUser = isLoading;
  const hasData = listData && listData?.length > 0;
  const showData = !isLoading && hasData;
  const showNoData = !isLoading && !showData;

  const onSelectHandler = React.useCallback(
    (currentOwner: UserOwner) => () => {
      const newSelectedData = JSON.parse(JSON.stringify(selectedData));
      newSelectedData.areAllOwnersSelected = false;

      let isSelected = false;
      newSelectedData?.owners.forEach((value: any, index: any, array: any) => {
        if (value.ownerId === currentOwner.ownerId) {
          isSelected = true;
          return;
        }
      });

      if (!isSelected) {
        onChangeSelected({
          ...newSelectedData,
          owners: [...(newSelectedData?.owners || {}), currentOwner],
        });
      }

      if (isSelected) {
        const index = newSelectedData.owners.findIndex((owner: UserOwner) => owner.ownerId === currentOwner.ownerId);
        if (index !== -1) {
          newSelectedData.owners.splice(index, 1);
        }

        onChangeSelected({
          ...newSelectedData,
          owners: [...(newSelectedData?.owners || {})],
        });
      }
    },
    [onChangeSelected, selectedData],
  );

  return (
    <Box sx={theme => ({ marginTop: theme.spacing(3) })}>
      <UserAccessesListContainer>
        {isLoading && <ListRowSkeleton />}

        {showData &&
          listData?.map(owner => {
            const isSelected = selected.some(userOwner => userOwner.ownerId === owner.ownerId);

            return (
              <ListRow
                key={owner.ownerId}
                selected={isSelected}
                onSelect={onSelectHandler(owner)}
                disabled={disabledIfLoadingOrIsLoggedInUser}
              >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
                  <Typography>{owner.ownerName}</Typography>
                </Box>
              </ListRow>
            );
          })}
        {showNoData && <ListRowNoData />}
      </UserAccessesListContainer>
    </Box>
  );
};
