import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { actions as ConnectionActions } from '../../../Ducks/Connection.duck';
import { actions as LogEntriesActions } from '../../../Ducks/LogEntries.duck';
import { actions as TasksActions } from '../../../Ducks/Tasks.duck';
import { actions as ModulesActions } from '../../../Ducks/Modules.duck';
import { actions as ActivityLogsActions } from '../../../Ducks/ActivityLogs.duck';
import { actions as DownloadsActions } from '../../../Ducks/Downloads.duck';
import { downloadMissingDetails } from '../../../Services';
import Grid from '@mui/material/Grid';
import { CardContent, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConnectionNoteInput } from '../../../Components/ConnectionNoteInput';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import TasksTable from '../../../Components/TasksTable';
import LogsTable from '../../../Components/LogsTable/LogsTable';
import ActivityLogsTable from '../../../Components/ActivityLogsTable';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikModal from 'app/components/FormikModal';
import ConnectionRunForm from '../../../Components/Forms/ConnectionRunForm';
import UploadFileDialog from '../../../Components/UploadFileDialog';
import { ConfirmDialog, ConfirmDialogWithTextInput } from 'app/components';
import { isLocalEnvironment } from '../../../../../utils';
import { MESSAGE_SEVERITY_ERROR } from '../../../../../common';
import { actions as AppActions } from '../../../../App/Ducks/App.duck';
import { HasAccessTo, IsOwnerAdmin, IsSuperAdmin } from 'app/components/Authorization';
import { CONNECTION } from '../../../../../common/Authorization/entities';
import { CLEAR, DOWNLOAD, NOTE, RUN, UPDATE } from '../../../../../common/Authorization/permissions';
import EmployerConnectionWizard from '../../../../Employers/Components/EmployerConnectionWizard';
import { selectors } from '../../../../Employers/Ducks/Employer.duck';
import { ButtonStyled, TooltipStyled } from './ConnectionInfoTab.styles';

const ConnectionInfoTab = ({ intl }) => {
  const dispatch = useDispatch();
  const { connectionId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const [confirmPurgeOpen, setConfirmPurgeOpen] = useState(false);

  const {
    item: connection,
    connectionAccess,
    purgingConnection,
    uploadingFile,
    runIsLocked,
  } = useSelector(state => state.ConnectionReducer);
  const {
    page: pageLogs,
    pageSize: pageSizeLogs,
    loading: loadingLogs,
    items: logs,
    totalCount: totalCountLogs,
    canDownload,
    checkingDownload,
    clearingLogs,
  } = useSelector(state => state.LogEntriesReducer);
  const {
    page,
    pageSize,
    loading: loadingTasks,
    items: tasks,
    totalCount,
    clearingTasks,
  } = useSelector(state => state.TasksReducer);
  const {
    page: pageActivityLogs,
    pageSize: pageSizeActivityLogs,
    loading: loadingActivityLogs,
    items: ActivityLogs,
    totalCount: totalCountActivityLogs,
  } = useSelector(state => state.ActivityLogsReducer);

  const { loading: loadingModules, items: modules } = useSelector(state => state.ModulesReducer);

  const { item: employer, loading: employerLoading } = useSelector(state =>
    selectors.selectEmployer(state.EmployerReducer),
  );

  useEffect(() => {
    if (connectionAccess) {
      dispatch(LogEntriesActions.setConnectionId(connectionId));
      dispatch(LogEntriesActions.requestData(connectionId));
      dispatch(LogEntriesActions.checkCanDownloadMissingDetails());
      dispatch(TasksActions.setConnectionId(connectionId));
      dispatch(TasksActions.requestData(connectionId));
      dispatch(ModulesActions.requestData(connectionId));
      dispatch(ActivityLogsActions.setConnectionId(connectionId));
      dispatch(ActivityLogsActions.requestData(connectionId));
      dispatch(DownloadsActions.setConnectionId(connectionId));
    }
  }, [connectionAccess, connectionId, dispatch]);

  const clearTasks = async force => {
    dispatch(TasksActions.clearTasks(connectionId, force));
  };

  const clearLogs = async old => {
    dispatch(LogEntriesActions.clearLogs(connectionId, old));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(TasksActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(TasksActions.setPageSize(event.target.value));
  };

  const handlePageChangeLogs = (event, newPage) => {
    dispatch(LogEntriesActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChangeLogs = event => {
    dispatch(LogEntriesActions.setPageSize(event.target.value));
  };

  const handlePageChangeActivityLogs = (event, newPage) => {
    dispatch(ActivityLogsActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChangeActivityLogs = event => {
    dispatch(ActivityLogsActions.setPageSize(event.target.value));
  };

  const handleClickChange = () => {
    setModal2Open(true);
  };

  const handleRunConnectionModule = async conn => {
    const payload = {
      connectionId: conn,
      parameters: {},
    };
    try {
      dispatch(ConnectionActions.forceRun(payload));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'handleRunConnectionModule',
        message: error,
        severity,
      };

      dispatch(AppActions.displayMessage(details));
    }
    dispatch(ConnectionActions.requestData(connectionId));
  };

  const handlePurgeConnectionModule = async () => {
    dispatch(ConnectionActions.purgeConnection({ connectionId }));
    dispatch(ConnectionActions.requestData(connectionId));
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleUploadFile = async files => {
    const payload = {
      connectionId,
      parameters: {},
      base64FileContent: await toBase64(files[0]),
    };

    try {
      dispatch(ConnectionActions.uploadFromFile(payload));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'handleRunConnectionModule',
        message: error,
        severity,
      };

      dispatch(AppActions.displayMessage(details));
    }
  };

  const handleChangeConnection = async () => {
    setModal2Open(false);
    dispatch(ConnectionActions.requestData(connectionId));
  };

  const downloadLogs = async () => {
    try {
      const response = await downloadMissingDetails(connectionId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers['x-filename']);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const details = {
        method: 'downloadLogs',
        message: error,
        MESSAGE_SEVERITY_ERROR,
      };

      dispatch(AppActions.displayMessage(details));
    }
  };

  const hasFileUploadPackage = useMemo(() => {
    return connection?.packages.some(e => e.packageType === 'file-upload') || false;
  }, [connection]);

  const disabledIfNoAccessToConnectionUpdate = !HasAccessTo(CONNECTION, UPDATE);
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);
  const disabledIfNoAccessToConnectionClear = !HasAccessTo(CONNECTION, CLEAR);
  const disabledIfNoAccessToConnectionDownload = !HasAccessTo(CONNECTION, DOWNLOAD);
  const enabledIfAccessToConnectionNote = HasAccessTo(CONNECTION, NOTE);
  const disabledIfNotAnOwnerOrSuperAdmin = !(IsSuperAdmin() || IsOwnerAdmin());

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <ButtonStyled
            variant={'contained'}
            onClick={() => handleClickChange(connection)}
            disabled={disabledIfNoAccessToConnectionUpdate || employerLoading}
          >
            <FormattedMessage id="button.change" />
          </ButtonStyled>
          <ButtonStyled
            variant={'contained'}
            disabled={disabledIfNoAccessToConnectionRun || purgingConnection || runIsLocked}
            onClick={() => handleRunConnectionModule(connectionId)}
          >
            <FormattedMessage id="button.run" />
          </ButtonStyled>
          <ButtonStyled
            variant={'contained'}
            disabled={disabledIfNotAnOwnerOrSuperAdmin || purgingConnection}
            onClick={() => setConfirmPurgeOpen(true)}
          >
            <FormattedMessage id={purgingConnection ? 'button.purging' : 'button.purge'} />
          </ButtonStyled>
          {hasFileUploadPackage && (
            <ButtonStyled
              variant="outlined"
              disabled={disabledIfNoAccessToConnectionRun || uploadingFile}
              onClick={() => setModalUploadOpen(true)}
            >
              <FormattedMessage id="button.upload" />
            </ButtonStyled>
          )}
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title={<FormattedMessage id="connections.tasks.title" />}
              action={
                <>
                  <ButtonStyled
                    margin={'left'}
                    variant="outlined"
                    onClick={() => clearTasks(false)}
                    disabled={disabledIfNoAccessToConnectionClear || clearingTasks}
                  >
                    <FormattedMessage id="connectionDetails.clearTasks" />
                  </ButtonStyled>
                  <ButtonStyled
                    margin={'left'}
                    variant="outlined"
                    onClick={() => clearTasks(true)}
                    disabled={disabledIfNoAccessToConnectionClear || clearingTasks}
                  >
                    <FormattedMessage id="connectionDetails.forceClearTasks" />
                  </ButtonStyled>
                </>
              }
            />
            <CardContent>
              <TasksTable
                tasks={tasks}
                modules={modules}
                loading={loadingTasks || loadingModules}
                paging
                page={page - 1}
                rowsPerPage={pageSize}
                totalCount={totalCount}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader
              title={<FormattedMessage id="connections.logs.title" />}
              action={
                <>
                  <ButtonStyled
                    margin={'left'}
                    variant="outlined"
                    onClick={() => clearLogs(true)}
                    disabled={disabledIfNoAccessToConnectionClear || clearingLogs}
                  >
                    <FormattedMessage id="connectionDetails.clearOldLogs" />
                  </ButtonStyled>
                  <ButtonStyled
                    margin={'left'}
                    variant="outlined"
                    onClick={() => clearLogs(false)}
                    disabled={disabledIfNoAccessToConnectionClear || clearingLogs}
                  >
                    <FormattedMessage id="connectionDetails.clearLogs" />
                  </ButtonStyled>
                  <TooltipStyled
                    disableHoverListener={checkingDownload || canDownload}
                    variant="outlined"
                    title={<FormattedMessage id="connectionDetails.logEntries.noMissingDetails" />}
                  >
                    <span>
                      <ButtonStyled
                        margin={'left'}
                        variant="outlined"
                        onClick={() => downloadLogs()}
                        disabled={disabledIfNoAccessToConnectionDownload || !canDownload}
                      >
                        <FormattedMessage id="connectionDetails.logEntries.download" />
                      </ButtonStyled>
                    </span>
                  </TooltipStyled>
                </>
              }
            />
            <CardContent>
              <LogsTable
                logs={logs}
                loading={loadingLogs}
                paging
                page={pageLogs - 1}
                rowsPerPage={pageSizeLogs}
                totalCount={totalCountLogs}
                handlePageChange={handlePageChangeLogs}
                handleRowsPerPageChange={handleRowsPerPageChangeLogs}
              />
            </CardContent>
          </Card>
        </Grid>
        {enabledIfAccessToConnectionNote && (
          <Grid item xs={6}>
            <ConnectionNoteInput connectionId={connectionId} />
          </Grid>
        )}
        <Grid item xs={6}>
          <Card>
            <CardHeader title={<FormattedMessage id="connections.activityLogs.title" />} />
            <CardContent>
              <ActivityLogsTable
                tasks={ActivityLogs}
                modules={modules}
                loading={loadingActivityLogs}
                paging
                page={pageActivityLogs - 1}
                rowsPerPage={pageSizeActivityLogs}
                totalCount={totalCountActivityLogs}
                handlePageChange={handlePageChangeActivityLogs}
                handleRowsPerPageChange={handleRowsPerPageChangeActivityLogs}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Formik
        validationSchema={Yup.object().shape({
          date: Yup.date().required(),
        })}
        enableReinitialize
        initialValues={{
          date: new Date(),
          tenants: [],
        }}
        onSubmit={handleRunConnectionModule}
      >
        <FormikModal
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          title={<FormattedMessage id="connections.run" />}
        >
          <ConnectionRunForm />
        </FormikModal>
      </Formik>

      {modal2Open && (
        <EmployerConnectionWizard
          onClose={() => setModal2Open(false)}
          onUpserted={handleChangeConnection}
          connectionId={connectionId}
          employer={employer}
        />
      )}

      {modalUploadOpen && hasFileUploadPackage && (
        <UploadFileDialog
          onClose={() => setModalUploadOpen(false)}
          open={modalUploadOpen}
          setOpen={setModalUploadOpen}
          onConfirm={handleUploadFile}
          connectionId={connectionId}
        />
      )}
      {isLocalEnvironment() && (
        <ConfirmDialog
          title={intl.formatMessage({ id: 'button.purge' })}
          open={confirmPurgeOpen}
          setOpen={setConfirmPurgeOpen}
          onConfirm={handlePurgeConnectionModule}
        >
          <FormattedMessage id="connections.confirmPurgeConnection" />
        </ConfirmDialog>
      )}
      {!isLocalEnvironment() && (
        <ConfirmDialogWithTextInput
          title={intl.formatMessage({ id: 'button.purge' })}
          label={intl.formatMessage({ id: 'connections.confirmPurgeConnection.withRiddle.helper' })}
          errorMessage={intl.formatMessage({ id: 'connections.confirmPurgeConnection.withRiddle.helper.wrong' })}
          solution={connection?.connectionName}
          open={confirmPurgeOpen}
          setOpen={setConfirmPurgeOpen}
          onConfirm={handlePurgeConnectionModule}
        >
          <Typography>
            <FormattedMessage id="connections.confirmPurgeConnection" />
          </Typography>
          <br />
          <Typography>
            <FormattedMessage
              id="connections.confirmPurgeConnection.withRiddle"
              values={{ connectionName: connection?.connectionName, b: chunks => <b>{chunks}</b> }}
            />
          </Typography>
        </ConfirmDialogWithTextInput>
      )}
    </>
  );
};

export default injectIntl(ConnectionInfoTab);
