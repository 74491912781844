import { styled } from '@mui/material/styles';
import { switchClasses } from '@mui/material';

export type SpanStyledProps = {
  fullWidth: boolean;
  withInnerLabel: boolean;
  innerLabelState: {
    on: string;
    off: string;
  } | null;
  checked: boolean;
};

export const SpanStyled = styled('span', {
  name: 'SpanStyled',
  shouldForwardProp: (propName: string) =>
    !['fullWidth', 'withInnerLabel', 'innerLabelState', 'checked'].includes(propName),
})<SpanStyledProps>(({ fullWidth, withInnerLabel, innerLabelState, checked }) => ({
  ...(fullWidth ? { display: 'block' } : {}),
  ...(withInnerLabel
    ? {
        [`& .${switchClasses.track}`]: {
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            padding: '6px 0',
          },
          '&:before': {
            content: `"${checked ? innerLabelState?.on : ''}"`,
            marginLeft: '8px',
          },
          '&:after': {
            content: `"${!checked ? innerLabelState?.off : ''}"`,
            marginLeft: '20px',
          },
        },
      }
    : {}),
}));
