import React from 'react';

import { COUNTRY_CODES } from '../common';
import { useLocale } from '../components/Intl';

export const useCountries = () => {
  const locale = useLocale();

  const countryAutocompleteItems = React.useMemo(() => {
    return COUNTRY_CODES;
  }, []);

  const getCountryDisplayNameByCode = React.useCallback(
    (code: (typeof COUNTRY_CODES)[number]) => {
      const intlRegionFactory = new Intl.DisplayNames(locale.locale.code, { type: 'region' });

      try {
        return intlRegionFactory.of(code);
      } catch (error) {
        return '';
      }
    },
    [locale.locale.code],
  );

  return React.useMemo(
    () => ({ countryAutocompleteItems, getCountryDisplayNameByCode }),
    [countryAutocompleteItems, getCountryDisplayNameByCode],
  );
};
