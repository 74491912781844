import type { RateTableTableFields } from 'app/types';

export const RATE_TABLE_TEMPLATE_FILE_NAME = 'Rate_Table_Template';

export const RATE_TABLE_FILE_STRUCTURE: Array<{
  headerTitle: string;
  fieldName: RateTableTableFields;
}> = [
  {
    headerTitle: 'AGE',
    fieldName: 'age',
  },
  {
    headerTitle: 'MALE RATE',
    fieldName: 'rateMale',
  },
  {
    headerTitle: 'FEMALE RATE',
    fieldName: 'rateFemale',
  },
];

export const RATE_TABLE_FILE_COMPLETE_STRUCTURE: Array<{
  headerTitle: string;
  fieldName: RateTableTableFields;
}> = [
  {
    headerTitle: 'AGE',
    fieldName: 'age',
  },
  {
    headerTitle: 'MALE RATE',
    fieldName: 'rateMale',
  },
  {
    headerTitle: 'MALE RATE MONTH 1',
    fieldName: 'rateMaleMonth1',
  },
  {
    headerTitle: 'MALE RATE MONTH 2',
    fieldName: 'rateMaleMonth2',
  },
  {
    headerTitle: 'MALE RATE MONTH 3',
    fieldName: 'rateMaleMonth3',
  },
  {
    headerTitle: 'MALE RATE MONTH 4',
    fieldName: 'rateMaleMonth4',
  },
  {
    headerTitle: 'MALE RATE MONTH 5',
    fieldName: 'rateMaleMonth5',
  },
  {
    headerTitle: 'MALE RATE MONTH 6',
    fieldName: 'rateMaleMonth6',
  },
  {
    headerTitle: 'MALE RATE MONTH 7',
    fieldName: 'rateMaleMonth7',
  },
  {
    headerTitle: 'MALE RATE MONTH 8',
    fieldName: 'rateMaleMonth8',
  },
  {
    headerTitle: 'MALE RATE MONTH 9',
    fieldName: 'rateMaleMonth9',
  },
  {
    headerTitle: 'MALE RATE MONTH 10',
    fieldName: 'rateMaleMonth10',
  },
  {
    headerTitle: 'MALE RATE MONTH 11',
    fieldName: 'rateMaleMonth11',
  },
  {
    headerTitle: 'FEMALE RATE',
    fieldName: 'rateFemale',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 1',
    fieldName: 'rateFemaleMonth1',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 2',
    fieldName: 'rateFemaleMonth2',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 3',
    fieldName: 'rateFemaleMonth3',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 4',
    fieldName: 'rateFemaleMonth4',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 5',
    fieldName: 'rateFemaleMonth5',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 6',
    fieldName: 'rateFemaleMonth6',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 7',
    fieldName: 'rateFemaleMonth7',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 8',
    fieldName: 'rateFemaleMonth8',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 9',
    fieldName: 'rateFemaleMonth9',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 10',
    fieldName: 'rateFemaleMonth10',
  },
  {
    headerTitle: 'FEMALE RATE MONTH 11',
    fieldName: 'rateFemaleMonth11',
  },
  {
    headerTitle: 'UNIT RATE',
    fieldName: 'rateUnit',
  },
];

export const RATE_TABLE_FILE_HEADERS = RATE_TABLE_FILE_STRUCTURE.map(fileStructure => fileStructure.headerTitle);

export const RATE_TABLE_DOWNLOAD_FILE_STRUCTURE = RATE_TABLE_FILE_COMPLETE_STRUCTURE.filter(
  fileStructure => fileStructure.fieldName !== 'rateUnit',
);

export const RATE_TABLE_DOWNLOAD_FILE_HEADERS = RATE_TABLE_FILE_COMPLETE_STRUCTURE.map(
  fileStructure => fileStructure.headerTitle,
);
