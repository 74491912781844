import React from 'react';

import { normalizeOptions } from '../Shared/formikFieldHelpers';

import { IntlMessage, useLocale } from '../../Intl';

import type { Field } from '../../../types';
import { isBoolean, isEqual, isInteger, isNil, isNumeric, isString } from '../../../utils';
import formatter from '../../../utils/formatter';

type RenderFieldFactoryProps = {
  field: Field;
};

const RenderFieldFactory = ({ field }: RenderFieldFactoryProps) => {
  const { locale } = useLocale();

  if (React.isValidElement(field)) {
    return field;
  }

  const isBool = isBoolean(field.value) || ['boolean', 'checkbox'].includes(field.type || '');

  if (isBool) {
    if (field?.innerLabel === 'onOff') {
      return formatter.onOff(field.value);
    }
    return formatter.yesNo(field.value);
  }

  const isMonth = field.type === 'month' && isInteger(field.value);
  if (isMonth) {
    return locale?.localize?.month(parseInt(field.value));
  }

  if (field.type === 'number') {
    const value = isNumeric(field.value) ? field.value : '-';

    if (field.options?.style === 'currency') {
      return value === '-' ? value : formatter.currency(value, field.options);
    }

    if (field.options?.style === 'percent') {
      return formatter.percentage(value, field.options);
    }
  }

  if (field.type === 'date') {
    const hasValue = !!field.value;
    const hasValueAndIsString = hasValue && isString(field.value);

    if (hasValueAndIsString) {
      return formatter.date(field.value);
    }
    if (hasValue) {
      return field.value;
    }

    return '-';
  }

  if (field.type === 'dayAndMonth') {
    const hasValue = !!field.value;

    if (hasValue) {
      const dateParse = new Date(Date.parse(field.value));
      return `${dateParse.getDate()}/${dateParse.getMonth() + 1}`;
    }

    return '-';
  }

  const isSelect =
    field.type === 'select' ||
    field.type === 'autocomplete' ||
    (field.type === 'text' && field.options?.style === 'select');

  if (isSelect) {
    const normalizedItems = normalizeOptions({ field });

    const selectedItem = normalizedItems.find(item => isEqual(item.value, field.value));
    if (!isNil(selectedItem)) {
      if (isString(selectedItem.element)) {
        return <IntlMessage value={selectedItem.element} />;
      }
      return <IntlMessage value={selectedItem.value + ''} />;
    }
  }

  if (field.type === 'text' && field.value.length === 0) {
    return '-';
  }

  return <IntlMessage value={field?.value} />;
};

export default RenderFieldFactory;
