import React, { useCallback, useState } from 'react';
import * as XLSX from 'xlsx';
import FileUploadArea, { FileType } from '../../../../components/FileUploadArea';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { TypographyStyled } from './EmployerFileUpload.styles';
import { StoreSelectors, useStoreSelector } from '../../../../store';
import { Uuid } from 'app/utils';

type EmployerFileUploadProps = {
  progressMsg: string | null;
  setProgressMsg: (progressMsg: string) => void;
};

type EmployerType = {
  employerId: string;
  owner: string;
  employerName: string | null;
  chamberOfCommerce: string | null;
  sectorCode: string | null;
  payrollTaxNumber: string | null;
  address: string;
  zipCode: string;
  city: string;
  employerEmail: string | null;
  employerPhone: string | null;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  internalNumber: string | null;
};

export const EmployerFileUpload = ({ progressMsg, setProgressMsg }: EmployerFileUploadProps) => {
  const [fileErrors, setFileErrors] = useState<any[]>([]);
  const { isSubmitting, setFieldValue, setFieldError } = useFormikContext();
  const [validating, setValidating] = useState(false);
  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const validateEmployers = useCallback(
    employers => {
      return new Promise((resolve, reject) => {
        try {
          setFieldValue('hasErrors', false);
          resolve([]);
        } catch (e) {
          reject(e);
        }
      });
    },
    [setFieldValue],
  );

  const parseExcelFile = useCallback(
    file => {
      return new Promise((resolve, reject) => {
        setFileErrors([]);
        const reader = new FileReader();
        reader.onload = event => {
          try {
            const payload = event.target?.result;
            const workbook = XLSX.read(payload, { type: 'binary', dateNF: 'dd/MM/yyyy' });
            const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
            const range = XLSX.utils.decode_range(sheet['!ref'] || '');

            range.s.r = 1;
            const headers = [
              'employerName',
              'chamberOfCommerce',
              'sectorCode',
              'payrollTaxNumber',
              'address',
              'zipCode',
              'city',
              'employerEmail',
              'employerPhone',
              'contactName',
              'contactEmail',
              'contactPhone',
              'internalNumber',
            ];
            const employers: EmployerType[] = XLSX.utils.sheet_to_json(sheet, {
              header: headers,
              blankrows: false,
              skipHidden: true,
              rawNumbers: true,
              raw: false,
              range: range,
            });

            employers.forEach((employer: EmployerType) => {
              employer.employerId = Uuid.newV4();
              employer.owner = `api/owners/${ownerId}`;
            });

            resolve(employers);
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = () => {
          reject(new Error(`Error occurred reading file: ${file.name}`));
        };
        reader.readAsBinaryString(file);
      });
    },
    [ownerId],
  );

  const changeList = useCallback(
    async files => {
      const file = files.slice(0, 1)[0] ?? undefined;

      if (file) {
        setProgressMsg('employees.add.readFile');
        setValidating(true);
        await parseExcelFile(file)
          .then(async employers => {
            setProgressMsg('employees.add.validateFile');
            await validateEmployers(employers);
            setFieldValue('employers', employers);
          })
          .catch(error => {
            setFileErrors([...fileErrors, error]);
            setFieldError('employers', error);
          })
          .finally(() => {
            setProgressMsg('employees.add.validateFileFinish');
            setValidating(false);
          });
      } else {
        setProgressMsg('employees.add.fileClear');
        setFileErrors([]);
        setFieldValue('employers', []);
      }
    },
    [fileErrors, parseExcelFile, setFieldError, setFieldValue, setProgressMsg, validateEmployers],
  );

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button href={require('./employers-template.xlsx')} download="employers-template">
            <FormattedMessage id={'employers.template.download'} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FileUploadArea
            types={[FileType.Excel]}
            onChange={changeList}
            disabled={false}
            showSuccessMessage={false}
            isSubmitting={isSubmitting}
          />
          {fileErrors.length > 0 &&
            !validating &&
            fileErrors.map((fileError, index) => (
              <TypographyStyled key={index} color="error" variant="body1">
                <FormattedMessage
                  id={fileError.message}
                  values={{ row: fileError?.values, b: chunks => <b>{chunks}</b>, br: <br /> }}
                />
              </TypographyStyled>
            ))}
          {progressMsg && (
            <TypographyStyled color="primary" variant="body1">
              <FormattedMessage id={progressMsg} />
            </TypographyStyled>
          )}
        </Grid>
      </Grid>
    </>
  );
};
