import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Typography } from '@mui/material';

import { FormattedMessage, injectIntl } from 'react-intl';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { IsSuperAdmin } from 'app/components/Authorization';

import { DivRootStyled, DivRowStyled, SpanSpacerStyled } from './OwnersToolbar.styles';
import { ActionButton } from '../../../../components';

const OwnersToolbar = props => {
  const { className, handleClickAdd, totalCount } = props;
  const isSuperAdmin = IsSuperAdmin();

  return (
    <DivRootStyled className={className}>
      <DivRowStyled>
        <div>
          <Typography variant="h5">
            <FormattedMessage id="menu.owners" />
          </Typography>
          <Typography variant="subtitle2" textTransform={'uppercase'}>
            {typeof totalCount !== 'undefined' ? (
              <FormattedMessage id="owners.totalCount" values={{ totalCount: totalCount }} />
            ) : (
              <Skeleton width={200} />
            )}
          </Typography>
        </div>

        <SpanSpacerStyled />
        {isSuperAdmin && <ActionButton messageId={'owners.add'} onClick={handleClickAdd} icon={faPlus} />}
      </DivRowStyled>
    </DivRootStyled>
  );
};

OwnersToolbar.propTypes = {
  className: PropTypes.string,
  handleClickAdd: PropTypes.func.isRequired,
};

export default injectIntl(OwnersToolbar);
