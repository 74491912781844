import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CardStyled = styled(Card, {
  name: 'CardStyled',
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  '&:hover': {
    border: '1px solid ' + theme.palette.primary.main,
  },
  cursor: 'pointer',
}));

export const FontAwesomeIconStyled = styled(FontAwesomeIcon, {
  name: 'FontAwesomeIconStyled',
})(({ theme }) => ({
  fontSize: '32px',
  marginBottom: theme.spacing(2),
}));

export const TypographyStyled = styled(Typography, {
  name: 'TypographyStyled',
})(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
