import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TranslationKeys } from 'app/translations';
import ActionButton from 'app/components/ActionButton';
import { InputFieldFactory } from 'app/components';
import { Uuid } from 'app/utils';
import { PROCESS_RESULTS } from '../../../../Pages/ConnectionDetailsPage/BatchesTab/constants';
import { DivRowStyled } from './ProcessesToolbar.styles';

type ProcessesToolbarProps = {
  isLoading?: boolean;
  connectionId: string;
  filters: Record<string, any>;
  applyFilter: (filterName: string, value: any) => void;
  resetFilters: () => void;
};

export const ProcessesToolbar = ({ filters, applyFilter, resetFilters, isLoading = false }: ProcessesToolbarProps) => {
  const intl = useIntl();

  const [key, setKey] = React.useState(Uuid.newV4);
  const [result, setResult] = useState([]);
  const hasActiveFilters = Object.keys(filters).length > 0;

  const onResetFiltersButtonClick = React.useCallback(() => {
    setKey(Uuid.newV4());
    resetFilters();
    setResult([]);
  }, [resetFilters]);

  const onStatusChange = React.useCallback(
    ({ value }) => {
      setResult(value);
      applyFilter('batchStatus', value ? value : undefined);
    },
    [applyFilter],
  );

  const batchStatusSelectItems = React.useMemo(() => {
    return (PROCESS_RESULTS || []).map(processResult => ({
      value: processResult,
      element: intl.formatMessage({ id: `connection.processes.result.${processResult}` }),
    }));
  }, [intl]);

  return (
    <DivRowStyled>
      <InputFieldFactory
        key={`batch-status-${key}`}
        disabled={isLoading}
        onChange={onStatusChange}
        field={{
          type: 'multi-select',
          name: 'batch-status-filter',
          label: intl.formatMessage({ id: TranslationKeys.connection_processes_result }),
          fullWidth: false,
          value: result || null,
          items: batchStatusSelectItems,
        }}
      />

      {hasActiveFilters && (
        <ActionButton
          messageId={TranslationKeys.events_resetFilters}
          onClick={onResetFiltersButtonClick}
          disabled={!hasActiveFilters}
          variant={'text'}
        />
      )}
    </DivRowStyled>
  );
};
