import React from 'react';
import { useIntl } from 'react-intl';

import { TranslationKeys } from '../translations';
import { EmploymentTypeEnum } from '../types/employee.types';

export const useEmploymentTypeSelectItems = () => {
  const intl = useIntl();

  const employmentTypeSelectItems = React.useMemo(() => {
    return [
      {
        element: intl.formatMessage({ id: TranslationKeys.global_employmentType_fullTimeIndefinite }),
        value: EmploymentTypeEnum.FullTimeIndefinite,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_employmentType_partTimeIndefinite }),
        value: EmploymentTypeEnum.PartTimeIndefinite,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_employmentType_fullTimeDetermined }),
        value: EmploymentTypeEnum.FullTimeDetermined,
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.global_employmentType_partTimeDetermined }),
        value: EmploymentTypeEnum.PartTimeDetermined,
      },
    ];
  }, [intl]);

  const getEmploymentTypeDisplayNameByValue = React.useCallback(
    (value: EmploymentTypeEnum) => {
      return employmentTypeSelectItems.find(employmentType => employmentType.value === value)?.element;
    },
    [employmentTypeSelectItems],
  );

  return React.useMemo(
    () => ({ employmentTypeSelectItems, getEmploymentTypeDisplayNameByValue }),
    [employmentTypeSelectItems, getEmploymentTypeDisplayNameByValue],
  );
};
