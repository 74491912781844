import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import type { FormikConfig } from 'formik';
import * as Yup from 'yup';

import type { SummaryScales } from 'app/types';

import { useSettingsCreateSummaryScaleTableMutation, useSnakeBar } from 'app/hooks';
import { TranslationKeys } from 'app/translations';
import { StoreSelectors, useStoreSelector } from 'app/store';
import type { SummaryScalesFormValues } from './ScalesTableForm.types';

export const useScalesTableForm = (summaryScales?: SummaryScales) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: createSummaryScaleMutation, isLoading: isCreatingTable } =
    useSettingsCreateSummaryScaleTableMutation();

  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const isSubmitting = isCreatingTable;

  const formikInitialValues = React.useMemo<SummaryScalesFormValues>(() => {
    return {
      uploadError: false,
      agePercentages: summaryScales?.agePercentages || [],
      taxLimitPercentage: summaryScales?.taxLimitPercentage || 3,
      title: summaryScales?.title || '',
      year: summaryScales?.year || new Date().getFullYear(),
    };
  }, [summaryScales?.agePercentages, summaryScales?.taxLimitPercentage, summaryScales?.title, summaryScales?.year]);

  const formikValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().max(75).required(),
        taxLimitPercentage: Yup.number().min(0).max(100).required(),
        year: Yup.number().required(),
      }),
    [],
  );

  const formikOnSubmitCreate = React.useCallback<FormikConfig<SummaryScalesFormValues>['onSubmit']>(
    (values, { setSubmitting, setErrors }) => {
      if (values.uploadError || values.agePercentages.length === 0) {
        setSubmitting(false);
        return;
      }

      const method = 'createSummaryScaleTable';

      createSummaryScaleMutation(
        {
          ownerId,
          title: values.title,
          year: values.year as number,
          agePercentages: values.agePercentages[0],
          taxLimitPercentage: parseFloat(values.taxLimitPercentage.toString()),
        },
        {
          onSuccess: () => {
            setSubmitting(false);
            showSuccessSnakeBar({
              method,
              message: intl.formatMessage({ id: TranslationKeys.global_update_successMessage }),
            });
            navigate('/settings/summary-scales');
          },
          onError: (error: any) => {
            setSubmitting(false);

            if (error.response?.data?.violations) {
              setErrors(error.response.data.violations);
            } else {
              showErrorSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_errorMessage }),
              });
            }
          },
        },
      );
    },
    [createSummaryScaleMutation, intl, navigate, ownerId, showErrorSnakeBar, showSuccessSnakeBar],
  );

  return React.useMemo(
    () => ({
      formikInitialValues,
      formikValidationSchema,
      formikOnSubmitCreate,
      isSubmitting,
    }),
    [formikInitialValues, formikOnSubmitCreate, formikValidationSchema, isSubmitting],
  );
};
