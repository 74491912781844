import { debounce, put, select, takeLatest } from 'redux-saga/effects';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import {
  archiveEvents,
  archiveEventsWithSamePeriod,
  downloadEvents,
  getEvents,
  processEvents,
  unArchiveEvents,
} from '../Services';
import { actions as AppActions } from '../../App/Ducks/App.duck';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from 'app/common/constants';
import { concat, getSelectedRowIds, saveFileFromResponse } from 'app/utils';

export const actionTypes = {
  RequestData: '[Events] Request',
  FulfilledTable: '[Events] Fulfilled',
  SearchTable: '[Events] Search',
  ChangePage: '[Events] change page',
  SetPageSize: '[Events] set page size',
  SortTable: '[Events] Sort',
  ApplyFilter: '[Events] Apply filter',
  RemoveFilter: '[Events] Remove filter',
  SetConnectionId: '[Events] Set connection id',
  ToggleEvent: '[Events] Toggle event',
  ToggleAllEvents: '[Events] Toggle all events as selected',
  ClearEvents: '[Events] Clear all selected events',
  ArchiveSelectedEvents: '[Events] Archive selected events',
  UnArchiveSelectedEvents: '[Events] Unarchive selected events',
  ProcessSelectedEvents: '[Events] Process selected events',
  DownloadSelectedEvents: '[Events] Download selected events',
  ResetFilters: '[Events] reset filters',
  ArchiveSamePeriodEvents: '[Events] Archive same period events',
};

const initialState = {
  ...tableInitialState,
  pageSize: 100,
  connectionId: null,
  selectedRowsIds: [],
  archivedRows: 0,
  unarchivedRows: 0,
  filters: {
    archived: false,
    processed: false,
    correction: null,
  },
  searchQuery: '',
};

export const reducer = persistReducer(
  { storage, key: 'eventsTable', whitelist: ['selectedRowsIds', 'filters', 'selectAll'] },
  (state = initialState, action) => {
    const newState = tableAction(actionTypes, state, action, initialState);

    switch (action.type) {
      case actionTypes.FulfilledTable: {
        return {
          ...state,
          loading: false,
          loaded: true,
          items: action.items,
          totalCount: action.totalCount,
          selectedRowsIds: state.selectedRowsIds,
        };
      }
      case actionTypes.SetConnectionId: {
        return {
          ...state,
          connectionId: action.connectionId,
        };
      }
      case actionTypes.SearchTable: {
        return {
          ...state,
          page: 1,
          searchQuery: action.searchQuery,
          selectedRowsIds: [],
          archivedRows: 0,
          unarchivedRows: 0,
        };
      }
      case actionTypes.ResetFilters: {
        return {
          ...state,
          filters: { ...initialState.filters },
          searchQuery: '',
          selectedRowsIds: [],
          archivedRows: 0,
          unarchivedRows: 0,
        };
      }
      case actionTypes.ApplyFilter: {
        return {
          ...state,
          page: 1,
          filters: {
            ...state.filters,
            [action.key]: action.value,
          },
          selectedRowsIds: [],
          archivedRows: 0,
          unarchivedRows: 0,
        };
      }
      case actionTypes.ToggleEvent: {
        let selectedIds;
        let archived = state.archivedRows;
        let unarchived = state.unarchivedRows;
        if (state.selectedRowsIds.includes(action.event.mongoId)) {
          selectedIds = state.selectedRowsIds.filter(id => id !== action.event.mongoId);
          if (action.event.archived === true) {
            archived -= 1;
          }
          if (action.event.archived === false) {
            unarchived -= 1;
          }
        } else {
          selectedIds = concat(state.selectedRowsIds, action.event.mongoId);
          if (action.event.archived === true) {
            archived += 1;
          }
          if (action.event.archived === false) {
            unarchived += 1;
          }
        }
        return {
          ...state,
          selectedRowsIds: selectedIds,
          archivedRows: archived,
          unarchivedRows: unarchived,
        };
      }
      case actionTypes.ToggleAllEvents: {
        const selectedRowsIds = action.events.filter(item => state.selectedRowsIds.includes(item.mongoId));
        let selectedIds;
        let archived = 0;
        let unarchived = 0;
        if (!selectedRowsIds.length && selectedRowsIds.length !== state.items.length) {
          selectedIds = action.events.map(item => item.mongoId);
          action.events.forEach(item => {
            if (item.archived === true) {
              archived += 1;
            }
            if (item.archived === false) {
              unarchived += 1;
            }
          });
        } else {
          selectedIds = [];
          action.events.forEach(item => {
            if (item.archived === true) {
              archived = 0;
            }
            if (item.archived === false) {
              unarchived = 0;
            }
          });
        }
        return {
          ...state,
          selectedRowsIds: selectedIds,
          archivedRows: archived,
          unarchivedRows: unarchived,
        };
      }
      case actionTypes.ClearEvents: {
        return {
          ...state,
          searchQuery: '',
          selectedRowsIds: [],
        };
      }
      case actionTypes.RequestData: {
        return {
          ...state,
          selectedRowsIds: [],
          loading: true,
          error: false,
        };
      }
      default:
        return newState;
    }
  },
);

export const actions = {
  ...tableActions(actionTypes),
  toggleEvent: event => {
    return {
      type: actionTypes.ToggleEvent,
      event,
    };
  },
  toggleAllEvents: events => ({
    type: actionTypes.ToggleAllEvents,
    events,
  }),
  clearEvents: () => ({
    type: actionTypes.ClearEvents,
  }),
  setConnectionId: connectionId => ({
    type: actionTypes.SetConnectionId,
    connectionId,
  }),
  archiveSelectedEvents: selectAll => ({
    type: actionTypes.ArchiveSelectedEvents,
    selectAll,
  }),
  resetFilters: () => ({
    type: actionTypes.ResetFilters,
  }),
  unArchiveSelectedEvents: selectAll => ({
    type: actionTypes.UnArchiveSelectedEvents,
    selectAll,
  }),
  processSelectedEvents: ({ selectAll, isDraft }) => ({
    type: actionTypes.ProcessSelectedEvents,
    selectAll,
    isDraft,
  }),
  downloadSelectedEvents: selectAll => ({
    type: actionTypes.DownloadSelectedEvents,
    selectAll,
  }),
  archiveSamePeriodEvents: () => ({
    type: actionTypes.ArchiveSamePeriodEvents,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  function* archiveSelectedEvents(action) {
    const currentState = yield select(state => {
      return state.EventsReducer;
    });

    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      const selectedRowsIds = getSelectedRowIds(currentState, action.selectAll);

      yield archiveEvents(
        currentState.connectionId,
        selectedRowsIds,
        { ...currentState.filters, processed: false },
        currentState.searchQuery,
        action.selectAll,
      );
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      method: 'archiveSelectedEvents',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }

  function* unArchiveSelectedEvents(action) {
    const currentState = yield select(state => {
      return state.EventsReducer;
    });

    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      const selectedRowsIds = getSelectedRowIds(currentState, action.selectAll);

      yield unArchiveEvents(
        currentState.connectionId,
        selectedRowsIds,
        { ...currentState.filters, processed: false },
        currentState.searchQuery,
        action.selectAll,
      );
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      method: 'unArchiveSelectedEvents',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }

  function* processSelectedEvents(action) {
    const currentState = yield select(state => {
      return state.EventsReducer;
    });

    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      const selectedRowsIds = getSelectedRowIds(currentState, action.selectAll);

      yield processEvents({
        connectionId: currentState.connectionId,
        eventIds: selectedRowsIds,
        filters: { ...currentState.filters, processed: false },
        searchQuery: currentState.searchQuery,
        selectAll: action.selectAll,
        isDraft: action.isDraft,
      });
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    const details = {
      method: 'processSelectedEvents',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }

  function* downloadSelectedEvents(action) {
    const currentState = yield select(state => {
      return state.EventsReducer;
    });
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      const selectedRowsIds = getSelectedRowIds(currentState, action.selectAll);

      const response = yield downloadEvents(currentState.connectionId, {
        eventIds: selectedRowsIds,
        filters: {
          ...currentState.filters,
          employee: currentState.searchQuery ?? '',
          processed: false,
        },
        selectAll: action.selectAll,
      });

      if (response.status === 200) {
        saveFileFromResponse(response);
      } else if (response.status === 202) {
        yield put(AppActions.displaySnackBar(true));
      }
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'downloadSelectedEvents',
        severity,
      };

      yield put(AppActions.displayMessage(details));
    }

    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }

  yield debounce(500, actionTypes.SearchTable, reloadData);
  yield takeLatest(actionTypes.ChangePage, reloadData);
  yield takeLatest(actionTypes.SetPageSize, reloadData);
  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);
  yield takeLatest(actionTypes.ResetFilters, reloadData);

  yield takeLatest(actionTypes.RequestData, function* requestEventsSaga() {
    try {
      const currentState = yield select(state => {
        return state.EventsReducer;
      });

      const response = yield getEvents(
        currentState.connectionId,
        currentState.page,
        currentState.pageSize,
        {
          ...Object.keys(currentState.sortBy).reduce((result, key) => {
            const direction = currentState.sortBy[key] || null;
            if (direction) {
              result[`order[${key}]`] = direction;
            }
            return result;
          }, {}),
        },
        currentState.filters,
        currentState.searchQuery,
      );

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getEvents',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.ArchiveSelectedEvents, archiveSelectedEvents);
  yield takeLatest(actionTypes.UnArchiveSelectedEvents, unArchiveSelectedEvents);
  yield takeLatest(actionTypes.ProcessSelectedEvents, processSelectedEvents);
  yield takeLatest(actionTypes.DownloadSelectedEvents, downloadSelectedEvents);

  function* archiveSamePeriodEvents() {
    const currentState = yield select(state => {
      return state.EventsReducer;
    });

    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield archiveEventsWithSamePeriod(currentState.connectionId);
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }

    const details = {
      method: 'archiveSamePeriodEvents',
      severity,
    };

    yield put(AppActions.displayMessage(details));
    yield put(actions.requestData());
    yield put(AppActions.setLoading(false));
  }
  yield takeLatest(actionTypes.ArchiveSamePeriodEvents, archiveSamePeriodEvents);
}
