import React from 'react';

import { CardContent } from '@mui/material';

import type { FilterableTableColumnType, FilterableTableRowMapperFunc } from 'app/components';
import { FilterableTable } from 'app/components';

import type { RateTableFormValues } from 'app/Domain/Settings/Components/RateTableForm/RateTableForm.types';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from 'app/translations';

export const RATE_TABLE_DATA_TEST_ID = 'rate-table-component';

enum TableColumns {
  Age = 'age',
  MaleRate = 'rateMale',
  MaleRateMonth1 = 'rateMaleMonth1',
  MaleRateMonth2 = 'rateMaleMonth2',
  MaleRateMonth3 = 'rateMaleMonth3',
  MaleRateMonth4 = 'rateMaleMonth4',
  MaleRateMonth5 = 'rateMaleMonth5',
  MaleRateMonth6 = 'rateMaleMonth6',
  MaleRateMonth7 = 'rateMaleMonth7',
  MaleRateMonth8 = 'rateMaleMonth8',
  MaleRateMonth9 = 'rateMaleMonth9',
  MaleRateMonth10 = 'rateMaleMonth10',
  MaleRateMonth11 = 'rateMaleMonth11',
  FemaleRate = 'rateFemale',
  FemaleRateMonth1 = 'rateFemaleMonth1',
  FemaleRateMonth2 = 'rateFemaleMonth2',
  FemaleRateMonth3 = 'rateFemaleMonth3',
  FemaleRateMonth4 = 'rateFemaleMonth4',
  FemaleRateMonth5 = 'rateFemaleMonth5',
  FemaleRateMonth6 = 'rateFemaleMonth6',
  FemaleRateMonth7 = 'rateFemaleMonth7',
  FemaleRateMonth8 = 'rateFemaleMonth8',
  FemaleRateMonth9 = 'rateFemaleMonth9',
  FemaleRateMonth10 = 'rateFemaleMonth10',
  FemaleRateMonth11 = 'rateFemaleMonth11',
  UnitRate = 'rateUnit',
}

type RateTableProps = {
  isLoading?: boolean;
  table: RateTableFormValues['table'];
};

export const RateTable = ({ table, isLoading = false }: RateTableProps) => {
  const tableColumns = React.useMemo<FilterableTableColumnType[]>(
    () => [
      {
        name: TableColumns.Age,
        title: <FormattedMessage id={TranslationKeys.age} />,
      },
      {
        name: TableColumns.MaleRate,
        title: <FormattedMessage id={TranslationKeys.settings_rateTables_rate_male} />,
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth1,
        title: '1',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth2,
        title: '2',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth3,
        title: '3',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth4,
        title: '4',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth5,
        title: '5',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth6,
        title: '6',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth7,
        title: '7',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth8,
        title: '8',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth9,
        title: '9',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth10,
        title: '10',
        textAlign: 'right',
      },
      {
        name: TableColumns.MaleRateMonth11,
        title: '11',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRate,
        title: <FormattedMessage id={TranslationKeys.settings_rateTables_rate_female} />,
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth1,
        title: '1',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth2,
        title: '2',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth3,
        title: '3',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth4,
        title: '4',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth5,
        title: '5',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth6,
        title: '6',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth7,
        title: '7',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth8,
        title: '8',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth9,
        title: '9',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth10,
        title: '10',
        textAlign: 'right',
      },
      {
        name: TableColumns.FemaleRateMonth11,
        title: '11',
        textAlign: 'right',
      },
    ],
    [],
  );

  const roundNumberToFiveDecimalPlaces = React.useCallback((value: number) => {
    const decimalPlaces = 100000;
    return Math.round(value * decimalPlaces) / decimalPlaces;
  }, []);

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<RateTableFormValues['table'][number]>>(
    table => {
      return {
        data: {
          [TableColumns.Age]: table.age,

          [TableColumns.MaleRate]: roundNumberToFiveDecimalPlaces(table.rateMale),
          [TableColumns.MaleRateMonth1]: table.rateMaleMonth1,
          [TableColumns.MaleRateMonth2]: table.rateMaleMonth2,
          [TableColumns.MaleRateMonth3]: table.rateMaleMonth3,
          [TableColumns.MaleRateMonth4]: table.rateMaleMonth4,
          [TableColumns.MaleRateMonth5]: table.rateMaleMonth5,
          [TableColumns.MaleRateMonth6]: table.rateMaleMonth6,
          [TableColumns.MaleRateMonth7]: table.rateMaleMonth7,
          [TableColumns.MaleRateMonth8]: table.rateMaleMonth8,
          [TableColumns.MaleRateMonth9]: table.rateMaleMonth9,
          [TableColumns.MaleRateMonth10]: table.rateMaleMonth10,
          [TableColumns.MaleRateMonth11]: table.rateMaleMonth11,

          [TableColumns.FemaleRate]: roundNumberToFiveDecimalPlaces(table.rateFemale),
          [TableColumns.FemaleRateMonth1]: table.rateFemaleMonth1,
          [TableColumns.FemaleRateMonth2]: table.rateFemaleMonth2,
          [TableColumns.FemaleRateMonth3]: table.rateFemaleMonth3,
          [TableColumns.FemaleRateMonth4]: table.rateFemaleMonth4,
          [TableColumns.FemaleRateMonth5]: table.rateFemaleMonth5,
          [TableColumns.FemaleRateMonth6]: table.rateFemaleMonth6,
          [TableColumns.FemaleRateMonth7]: table.rateFemaleMonth7,
          [TableColumns.FemaleRateMonth8]: table.rateFemaleMonth8,
          [TableColumns.FemaleRateMonth9]: table.rateFemaleMonth9,
          [TableColumns.FemaleRateMonth10]: table.rateFemaleMonth10,
          [TableColumns.FemaleRateMonth11]: table.rateFemaleMonth11,
        },
      };
    },
    [roundNumberToFiveDecimalPlaces],
  );

  return (
    <CardContent data-testid={RATE_TABLE_DATA_TEST_ID} sx={{ padding: 0 }}>
      <FilterableTable
        columns={tableColumns}
        loading={isLoading}
        rows={table || []}
        rowMapper={tableRowMapper}
        pagination={{ enabled: false }}
      />
    </CardContent>
  );
};
