import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton, Typography } from '@mui/material';

import { SearchInput } from 'app/components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { HasAccessTo } from 'app/components/Authorization';
import { EMPLOYER } from '../../../../common/Authorization/entities';
import { EDIT } from '../../../../common/Authorization/permissions';
import { ActionButton } from '../../../../components/ActionButton';

import { DivRootStyled } from './EmployersToolbar.styles';

const EmployersToolbar = props => {
  const { className, handleClickAdd, handleSearch, intl, totalCount, initialSearchQuery } = props;
  const disabledIfNoAccessToEmployerEdit = !HasAccessTo(EMPLOYER, EDIT);

  return (
    <DivRootStyled className={className}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography variant="h5">
            <FormattedMessage id="menu.employers" />
          </Typography>
          <Typography variant="subtitle2" textTransform={'uppercase'}>
            {typeof totalCount !== 'undefined' ? (
              <FormattedMessage id="employers.totalCount" values={{ totalCount: totalCount }} />
            ) : (
              <Skeleton width={200} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SearchInput
            onChange={handleSearch}
            placeholder={intl.formatMessage({ id: 'employers.search' }).toUpperCase()}
            initialSearchQuery={initialSearchQuery}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ActionButton
            messageId={'employers.add'}
            onClick={handleClickAdd}
            disabled={disabledIfNoAccessToEmployerEdit}
            icon={faPlus}
          />
        </Grid>
      </Grid>
    </DivRootStyled>
  );
};

EmployersToolbar.propTypes = {
  className: PropTypes.string,
  handleClickAdd: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default injectIntl(EmployersToolbar);
