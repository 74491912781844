import React from 'react';
import { useQuery } from '@tanstack/react-query';

import type { Employee, UseQueryWrapper } from '../../../types';

import { employeeService } from '../../../Domain/Employees/Services/EmployeeService';

import { useSnakeBar } from '../../useSnakeBar';

type GetEmployeeQueryResultData = Employee;

type GetEmployeeQueryVariables = {
  employerId: string | undefined;
  employeeId: string | undefined;
};

const queryFn =
  ({ employeeId, employerId }: GetEmployeeQueryVariables) =>
  async () => {
    if (employeeId && employerId) {
      const response = await employeeService.getEmployee(employerId, employeeId);
      return response.data;
    }
  };

const QUERY_KEY = 'getEmployee';

export const useEmployeeGetEmployeeQuery: UseQueryWrapper<GetEmployeeQueryResultData, GetEmployeeQueryVariables> = ({
  variables,
  options,
}) => {
  const { showErrorSnakeBar } = useSnakeBar();

  const onError = React.useCallback(() => {
    showErrorSnakeBar({ method: QUERY_KEY });
  }, [showErrorSnakeBar]);

  return useQuery<GetEmployeeQueryResultData>([QUERY_KEY, variables.employeeId], queryFn(variables), {
    onError,
    ...(options || {}),
  });
};
