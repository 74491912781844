import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { TranslationKeys } from 'app/translations';

import { useBreadcrumb } from 'app/components';

import { DownloadReportPage } from './DownloadReportPage';
import { InvoicingReportPage } from './InvoicingReportPage';
import { ReportManagementPage } from './ReportManagementPage';

export const ReportsPages = () => {
  useBreadcrumb(TranslationKeys.menu_reports);

  return (
    <Routes>
      <Route element={<DownloadReportPage />} path={':encodedFilename'} />
      <Route element={<ReportManagementPage />} path={'overview'} />
      <Route element={<InvoicingReportPage />} path={'invoicing'} />
      <Route index element={<Navigate to="overview" replace />} />
      <Route path="*" element={<Navigate to="overview" replace />} />
    </Routes>
  );
};
