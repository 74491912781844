import { debounce, put, select, takeLatest } from 'redux-saga/effects';
import { tableAction, tableActions, tableInitialState } from 'app/Domain/App/Ducks/Table.duck';
import { userService } from '../Services';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS, SUPER_ADMIN_OWNER_ID } from 'app/common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  InitializeUsersState: '[Users] Initialize state',
  RequestData: '[Users] Request',
  FulfilledTable: '[Users] Fulfilled',
  SearchTable: '[Users] Search',
  ChangePage: '[Users] change page',
  SetPageSize: '[Users] set page size',
  SortTable: '[Users] Sort',
  ApplyFilter: '[Users] Apply filter',
  RemoveFilter: '[Users] Remove filter',
  ToggleUser: '[Users] Toggle user',
  ToggleAllUsers: '[Users] Toggle all users',
  SetSelectedUser: '[Users] Set selected user',
  ClearAll: '[Users] Clear all',
  CreateUser: '[Users] Create new user',
  EditUser: '[Users] Edit new user',
  EditUserOwnerId: '[Users] Edit user owner ID',
  UpdateOwnerValues: '[Users] Update owner ID and Name',
  UserDelete: '[Users] Delete user',
};

const initialState = {
  ...tableInitialState,
  selectedUser: null,
};

export const reducer = (state = initialState, action) => {
  const newState = tableAction(actionTypes, state, action, initialState);

  switch (action.type) {
    case actionTypes.InitializeUsersState: {
      return { ...initialState };
    }
    case actionTypes.ToggleUser: {
      return {
        ...state,
        items: state.items.map(item => {
          if (item.userId === action.event.userId) {
            item.selectedRow = !item.selectedRow;
          }
          return item;
        }),
      };
    }
    case actionTypes.ToggleAllUsers: {
      const isSelectAll = action.events.filter(item => !item.selectedRow).length;
      return {
        ...state,
        items: action.events.map(item => {
          item.selectedRow = !!isSelectAll;
          return item;
        }),
      };
    }
    case actionTypes.SetSelectedUser: {
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    }
    case actionTypes.CreateUser: {
      return {
        ...state,
      };
    }
    case actionTypes.EditUser: {
      return {
        ...state,
      };
    }
    case actionTypes.EditUserOwnerId: {
      return {
        ...state,
      };
    }
    case actionTypes.ClearAll: {
      return {
        ...state,
        selectedUser: null,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...tableActions(actionTypes),
  initializeUsersState: () => ({
    type: actionTypes.InitializeUsersState,
  }),
  toggleUser: event => {
    return {
      type: actionTypes.ToggleUser,
      event,
    };
  },
  toggleAllUsers: events => ({
    type: actionTypes.ToggleAllUsers,
    events,
  }),
  setSelectedUser: selectedUser => ({
    type: actionTypes.SetSelectedUser,
    selectedUser,
  }),
  clearAll: () => ({
    type: actionTypes.ClearAll,
  }),
  createUser: userValues => ({
    type: actionTypes.CreateUser,
    userValues,
  }),
  editUser: userValues => ({
    type: actionTypes.EditUser,
    userValues,
  }),
  editUserOwner: userValues => ({
    type: actionTypes.EditUserOwnerId,
    userValues,
  }),
  userDelete: userId => ({
    type: actionTypes.UserDelete,
    userId,
  }),
};

export function* saga() {
  function* reloadData() {
    yield put(actions.requestData());
  }

  yield debounce(500, actionTypes.SearchTable, function* setUserSearchQuery(action) {
    if (action.reloadData) {
      yield reloadData();
    }
  });

  yield takeLatest(actionTypes.ChangePage, function* changeUserPage(action) {
    if (action.reloadData) {
      yield reloadData();
    }
  });

  yield takeLatest(actionTypes.SetPageSize, function* setUserPageSize(action) {
    if (action.reloadData) {
      yield reloadData();
    }
  });

  yield takeLatest(actionTypes.SortTable, reloadData);
  yield takeLatest(actionTypes.ApplyFilter, reloadData);
  yield takeLatest(actionTypes.RemoveFilter, reloadData);

  yield takeLatest(actionTypes.CreateUser, function* createUserSaga(action) {
    yield userService.createUser(action.userValues);
    yield reloadData();
  });

  yield takeLatest(actionTypes.EditUser, function* editUserSaga(action) {
    yield userService.editUser(action.userValues);
    yield reloadData();
  });

  yield takeLatest(actionTypes.EditUserOwnerId, function* editUserOwnerIdSaga(action) {
    yield userService.editUserOwnerId(action.userValues);
    yield reloadData();
  });

  yield takeLatest(actionTypes.RequestData, function* requestUsers() {
    try {
      const currentState = yield select(state => {
        return state.UsersReducer;
      });

      const ownerId = yield select(state => {
        return state.AppReducer.environment.ownerId;
      });

      const response =
        ownerId === SUPER_ADMIN_OWNER_ID
          ? yield userService.getUsers({
              page: currentState.page,
              perPage: currentState.pageSize,
              searchQuery: currentState.searchQuery,
            })
          : yield userService.getOwnerUsers({
              ownerId,
              page: currentState.page,
              perPage: currentState.pageSize,
              searchQuery: currentState.searchQuery,
            });

      yield put(actions.fulfilled(response.data['hydra:member'], response.data['hydra:totalItems']));
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        method: 'getUsers',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield takeLatest(actionTypes.UserDelete, function* deleteUserSaga(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;

    try {
      const response = yield userService.deleteUser(action.userId);

      if (response.status === 204) {
        yield put(actions.requestData());
      }
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    const details = {
      method: 'deleteUsers',
      severity,
    };
    yield put(AppActions.displayMessage(details));
  });
}
