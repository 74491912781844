import React from 'react';
import { useDispatch } from 'react-redux';

import type { SelectOwnerInsurersMapSelectedState, TableSelectedState } from '../../../../store';
import { StoreActions, StoreSelectors, useStoreSelector } from '../../../../store';

import { TranslationKeys } from '../../../../translations';

import type { Insurer } from '../../../../types/insurer.types';

import { useBreadcrumb } from '../../../../components/Breadcrumbs';

import { InsurersToolbar } from '../../Components';

import { InsurersTableStyled } from './InsurerManagementPage.styles';

export const InsurerManagementPage = () => {
  const dispatch = useDispatch();

  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const {
    loading: insurersLoading,
    items: insurers,
    page,
    pageSize,
    totalCount,
    filters,
  } = useStoreSelector<TableSelectedState<Insurer>>(state =>
    StoreSelectors.InsurersSelector.selectInsurers(state.InsurersReducer),
  );

  const {
    loading: ownerInsurersLoading,
    map: ownerInsurersMap,
    ownerId: mapOwnerId,
  } = useStoreSelector<SelectOwnerInsurersMapSelectedState>(state =>
    StoreSelectors.InsurersSelector.selectOwnerInsurersMap(state.InsurersReducer),
  );

  React.useEffect(() => {
    if (ownerId && ownerId !== mapOwnerId) {
      // @ts-ignore
      dispatch(StoreActions.InsurersActions.ownerInsurers.setOwnerId(ownerId));
    }
  }, [dispatch, mapOwnerId, ownerId]);

  React.useEffect(() => {
    dispatch(StoreActions.InsurersActions.insurers.requestData());
  }, [dispatch]);

  const handleSearch = React.useCallback(
    event => {
      dispatch(StoreActions.InsurersActions.insurers.applyFilter('insurerName', event.target.value));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.InsurersActions.insurers.setPageSize(rowSize));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.InsurersActions.insurers.changePage(page));
      }
    },
    [dispatch],
  );

  const onSortChange = React.useCallback(
    sortBy => {
      dispatch(StoreActions.InsurersActions.insurers.sortTable(sortBy));
    },
    [dispatch],
  );

  const searchQuery = filters.insurerName || null;

  useBreadcrumb(TranslationKeys.menu_insurers, {
    hideTrail: true,
    hideTitle: true,
    otherElements: (
      <InsurersToolbar handleSearch={handleSearch} totalCount={totalCount} initialSearchQuery={searchQuery} />
    ),
  });

  const insurersData = React.useMemo(
    () =>
      (insurers || []).map(insurer => ({
        ...insurer,
        agencyNumber: ownerInsurersMap?.[insurer?.insurerId]?.agencyNumber || null,
      })),
    [insurers, ownerInsurersMap],
  );

  return (
    <InsurersTableStyled
      insurers={insurersData || []}
      totalCount={totalCount}
      page={page}
      rowsPerPage={pageSize}
      onPaginationChange={onPaginationChange}
      onSortChange={onSortChange}
      loading={insurersLoading || ownerInsurersLoading}
    />
  );
};
