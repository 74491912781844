import { styled } from '@mui/material/styles';

export const UnprocessedTableFiltersContainerStyled = styled('div', {
  name: 'UnprocessedTableFiltersContainerStyled',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  margin: theme.spacing(2, 0),
}));
