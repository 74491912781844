import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';

import type { Breadcrumb } from '../../../../components';
import { useBreadcrumbs } from '../../../../components';
import { IntlMessage } from '../../../../components/Intl';

import { DivCrumbsStyled, DivHeaderStyled, NavLinkStyled } from './Header.styles';

const styleMainTitle = (crumb: Breadcrumb) => {
  const title = crumb?.data?.titleElement || crumb.title || null;
  if (title && typeof title === 'string') {
    return (
      <Typography variant="h5">
        <IntlMessage value={title} />
      </Typography>
    );
  }
  return title || <Skeleton width={100} />;
};

const styleCrumb = (title: Breadcrumb['title']) => {
  if (title && typeof title === 'string') {
    return (
      <Typography variant="body2">
        <IntlMessage value={title} />
      </Typography>
    );
  }
  return title || <Skeleton width={100} />;
};

export const Header = () => {
  const breadcrumbs = useBreadcrumbs();
  const latestCrumb = breadcrumbs.slice(-1)[0] || null;
  const previousCrumbs = breadcrumbs.slice(0, -1);

  const mainHeader = useMemo(() => {
    if (!latestCrumb || latestCrumb?.data?.hideTitle) {
      return null;
    }
    return (
      <div>
        <NavLinkStyled to={latestCrumb.to} end>
          {styleMainTitle(latestCrumb)}
        </NavLinkStyled>
      </div>
    );
  }, [latestCrumb]);

  const crumbs = useMemo(() => {
    if (latestCrumb?.data?.hideTrail) {
      return null;
    }

    const styledTitles = previousCrumbs.map((crumb, index) => {
      return (
        <div key={crumb.to.pathname}>
          <NavLinkStyled to={crumb.to} end>
            {styleCrumb(crumb.title)}
            {index !== previousCrumbs.length - 1 && styleCrumb('>')}
          </NavLinkStyled>
        </div>
      );
    });

    if (styledTitles.length) {
      return <DivCrumbsStyled>{styledTitles}</DivCrumbsStyled>;
    }
    return null;
  }, [latestCrumb, previousCrumbs]);

  const otherElements = useMemo(() => {
    return latestCrumb?.data?.otherElements;
  }, [latestCrumb]);

  const showHeader = !!(mainHeader || crumbs);

  return (
    <>
      {showHeader && (
        <DivHeaderStyled>
          {mainHeader}
          {crumbs}
        </DivHeaderStyled>
      )}
      {otherElements}
    </>
  );
};
