import { useMutation } from '@tanstack/react-query';
import type { UpdateEmployeeNotesParams } from '../../../Domain/Employees/Services/EmployeeService';
import EmployeeService from '../../../Domain/Employees/Services/EmployeeService';

type UpdateEmployeeNotesData = UpdateEmployeeNotesParams;

const mutationFn = (data: UpdateEmployeeNotesData) => {
  return EmployeeService.updateEmployeeNotes(data);
};

export const useEmployeeUpdateNotesMutation = () => {
  return useMutation<unknown, unknown, UpdateEmployeeNotesData>(mutationFn);
};
