import React from 'react';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { TranslationKeys } from 'app/translations';

import { genderMapper } from 'app/utils';

import type { Employee, EmployeeChild, UseQueryRefetch } from 'app/types';

import { useEmployeeUpdateChildrenMutation, useSnakeBar } from 'app/hooks';

import type { EmployeeChildFormValues } from 'app/Domain/Employees/Pages/EmployeeDetailPage/components/EmployeeInfoTab/components/ChildrenDetailsPanel/ChildrenDetailsPanel.types';

import { ChildFormDialogBody } from './components';

type ChildFormDialogProps = {
  open: boolean;
  closeDialog: () => void;
  employee: Employee | undefined;
  child: EmployeeChild | null;
  refetchGetEmployeeQuery: UseQueryRefetch;
};

export const ChildFormDialog = ({
  open,
  closeDialog,
  employee,
  child,
  refetchGetEmployeeQuery,
}: ChildFormDialogProps) => {
  const intl = useIntl();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: updateEmployeeChildren } = useEmployeeUpdateChildrenMutation();

  const isEdit = child !== null;

  const formikInitialValues = React.useMemo<EmployeeChildFormValues>(() => {
    return {
      socialSecurityNumber: child?.socialSecurityNumber || '',
      gender: genderMapper(child?.gender),
      dateOfBirth: child?.dateOfBirth || null,
      personName: {
        initials: child?.personName?.initials || '',
        firstName: child?.personName?.firstName || '',
        lastName: child?.personName?.lastName || '',
        lastNamePrefix: child?.personName?.lastNamePrefix || '',
      },
    };
  }, [
    child?.dateOfBirth,
    child?.gender,
    child?.personName?.firstName,
    child?.personName?.initials,
    child?.personName?.lastName,
    child?.personName?.lastNamePrefix,
    child?.socialSecurityNumber,
  ]);

  const setChildDataToSubmit = React.useCallback(childData => {
    return {
      externalId: childData?.externalId || null,
      socialSecurityNumber: childData.socialSecurityNumber || null,
      gender: genderMapper(childData?.gender),
      dateOfBirth: childData.dateOfBirth,
      personName: {
        initials: childData.personName.initials,
        firstName: childData.personName.firstName,
        lastName: childData.personName.lastName,
        lastNamePrefix: childData.personName.lastNamePrefix,
      },
    };
  }, []);

  const formikOnSubmit = React.useCallback<FormikConfig<EmployeeChildFormValues>['onSubmit']>(
    (values, { setSubmitting, resetForm }) => {
      const children = (employee?.children || []).map(employeeChild => {
        const childData =
          isEdit && employeeChild.externalId === child?.externalId
            ? { externalId: child?.externalId, ...values }
            : employeeChild;

        return setChildDataToSubmit(childData);
      });

      if (!isEdit) {
        children.push(setChildDataToSubmit(values));
      }

      if (employee?.connectionId && employee?.employeeId) {
        const method = 'updateEmployeeChildren';
        updateEmployeeChildren(
          {
            connectionId: employee?.connectionId,
            employeeId: employee?.employeeId,
            children,
          },
          {
            onSuccess: () => {
              setSubmitting(false);
              resetForm();
              closeDialog();
              refetchGetEmployeeQuery();
              showSuccessSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_successMessage }),
              });
            },
            onError: () => {
              setSubmitting(false);
              showErrorSnakeBar({
                method,
                message: intl.formatMessage({ id: TranslationKeys.global_update_errorMessage }),
              });
            },
          },
        );
      }
    },
    [
      child?.externalId,
      closeDialog,
      employee?.children,
      employee?.connectionId,
      employee?.employeeId,
      intl,
      isEdit,
      refetchGetEmployeeQuery,
      setChildDataToSubmit,
      showErrorSnakeBar,
      showSuccessSnakeBar,
      updateEmployeeChildren,
    ],
  );

  return (
    <Formik<EmployeeChildFormValues> initialValues={formikInitialValues} onSubmit={formikOnSubmit}>
      <ChildFormDialogBody open={open} isEdit={isEdit} closeDialog={closeDialog} />
    </Formik>
  );
};
